import { UUID } from '../react-app-env'
import { useGwtRestApi } from './useGwtRestApi'
import { useCallback, useState } from 'react'
export const useDownloadGalleryFilesHref = () => {
  const { proxyDownloadAssetFiles } = useGwtRestApi()

  const [isDownloading, setIsDownloading] = useState(false)

  const download = useCallback(
    (assetId: UUID, files: UUID[]) => {
      setIsDownloading(true)

      proxyDownloadAssetFiles(assetId, files)
        .then((res) => {
          window.location.href = res
        })
        .finally(() => {
          setIsDownloading(false)
        })
    },
    [proxyDownloadAssetFiles],
  )

  return { download, isDownloading }
}
