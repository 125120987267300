import { Grid, Divider } from '@enterprise-ui/canvas-ui-react'
import { StatusChip } from '../../Flows/StatusChip'
import { graphql, useFragment } from 'react-relay'
import { UpcomingModuleFragment$key } from './__generated__/UpcomingModuleFragment.graphql'

interface UpcomingModuleProps {
  moduleRef: UpcomingModuleFragment$key | null | undefined
}

export const UpcomingModule = ({ moduleRef }: UpcomingModuleProps) => {
  const moduleData = useFragment(
    graphql`
      fragment UpcomingModuleFragment on Module {
        flowId
        moduleGroup {
          flow {
            name
          }
        }
        groupModulePreviousModuleFlow {
          flow {
            name
          }
        }
        flow {
          ...StatusChip_flow
          name
        }
        attempts
      }
    `,
    moduleRef || null,
  )

  return (
    <div key={moduleData?.flowId}>
      <Grid.Container spacing="none" align="center" className="hc-pa-lg">
        <Grid.Item xs={8}>
          <div className="to-do-header-text hc-clr-grey02">
            {moduleData?.flow?.name}
          </div>
          <div className="to-do-card-text">
            <div className="hc-clr-grey02">
              {moduleData?.groupModulePreviousModuleFlow &&
                `After completing ${moduleData?.groupModulePreviousModuleFlow.flow?.name}`}
            </div>
            {moduleData?.moduleGroup && (
              <div className="hc-clr-grey02">{`Part of the ${moduleData?.moduleGroup.flow?.name}`}</div>
            )}
          </div>
        </Grid.Item>
        <Grid.Item xs={4} align="right" className="hc-pr-lg">
          <StatusChip flowRef={moduleData?.flow} className="hc-bg-grey04" />
        </Grid.Item>
      </Grid.Container>
      <Divider />
    </div>
  )
}
