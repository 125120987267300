import { useDroppable } from '@dnd-kit/core'
import React from 'react'

interface DroppableProps {
  id: string
  children: React.ReactNode
  disabled?: boolean
  data?: Record<string, any>
}

export const Droppable = ({ id, children, disabled, data }: DroppableProps) => {
  const { setNodeRef } = useDroppable({
    id,
    disabled,
    data,
  })

  return <div ref={setNodeRef}>{children}</div>
}
