import { Grid } from '@enterprise-ui/canvas-ui-react'
import { ControlledFormField } from '../../../controlledComponents/ControlledFormField'
import { ControlledRichTextEditor } from '../../../controlledComponents/ControlledRichTextEditor'

export const GroupTemplateDetails = () => {
  return (
    <Grid.Container className="details hc-pb-lg">
      <Grid.Item>
        <h1 className="hc-mt-md">Template details</h1>
      </Grid.Item>
      <Grid.Item xs={12}>
        <ControlledFormField
          required
          label="Name of group template"
          name="name"
        />
      </Grid.Item>
      <Grid.Item xs={12} className="hc-mb-lg">
        <ControlledRichTextEditor
          height="30vh"
          label="Description"
          required
          name="description"
          requiredMessage="Please enter a description."
        />
      </Grid.Item>
    </Grid.Container>
  )
}
