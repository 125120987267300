/**
 * @generated SignedSource<<7d87f64b37ec9067dd5644d6a8a8ac56>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type LinkedType = "MODULE" | "MODULE_GROUP" | "TASK" | "TEMPLATE" | "UNKNOWN" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type TemplateFavorite_flow$data = {
  readonly createdAt: Date | null;
  readonly durationInSeconds: number | null;
  readonly lastModifiedBy: {
    readonly fullName: string;
  } | null;
  readonly linkedType: LinkedType;
  readonly module: {
    readonly attempts: number;
    readonly flowId: UUID;
    readonly versionNumber: BigFloat;
    readonly " $fragmentSpreads": FragmentRefs<"TemplateOptions_module">;
  } | null;
  readonly moduleGroup: {
    readonly moduleGroupLinksByModuleGroupFlowId: {
      readonly nodes: ReadonlyArray<{
        readonly moduleFlow: {
          readonly flow: {
            readonly name: string;
            readonly rowId: UUID;
          } | null;
        } | null;
      }>;
    };
    readonly versionNumber: BigFloat;
    readonly " $fragmentSpreads": FragmentRefs<"GroupTemplateOptions_moduleGroup">;
  } | null;
  readonly name: string;
  readonly owner: {
    readonly fullName: string;
  } | null;
  readonly rowId: UUID;
  readonly updatedAt: Date | null;
  readonly " $fragmentType": "TemplateFavorite_flow";
};
export type TemplateFavorite_flow$key = {
  readonly " $data"?: TemplateFavorite_flow$data;
  readonly " $fragmentSpreads": FragmentRefs<"TemplateFavorite_flow">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "fullName",
    "storageKey": null
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "gwtUserId",
    "variableName": "gwtUserId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "versionNumber",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "gwtUserId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "TemplateFavorite_flow",
  "selections": [
    (v0/*: any*/),
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "durationInSeconds",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "updatedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "linkedType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GwtUser",
      "kind": "LinkedField",
      "name": "owner",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GwtUser",
      "kind": "LinkedField",
      "name": "lastModifiedBy",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Module",
      "kind": "LinkedField",
      "name": "module",
      "plural": false,
      "selections": [
        {
          "args": (v3/*: any*/),
          "kind": "FragmentSpread",
          "name": "TemplateOptions_module"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "flowId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "attempts",
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ModuleGroup",
      "kind": "LinkedField",
      "name": "moduleGroup",
      "plural": false,
      "selections": [
        (v4/*: any*/),
        {
          "args": (v3/*: any*/),
          "kind": "FragmentSpread",
          "name": "GroupTemplateOptions_moduleGroup"
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ModuleGroupLinksConnection",
          "kind": "LinkedField",
          "name": "moduleGroupLinksByModuleGroupFlowId",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ModuleGroupLink",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Module",
                  "kind": "LinkedField",
                  "name": "moduleFlow",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Flow",
                      "kind": "LinkedField",
                      "name": "flow",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        (v0/*: any*/)
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Flow",
  "abstractKey": null
};
})();

(node as any).hash = "ae08e383ace8f9fff26e2883620e8ce9";

export default node;
