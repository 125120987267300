import {
  Button,
  ButtonProps,
  Spinner,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { faSync } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import usePolUpload from '../../../hooks/usePolUpload'
import { UploadFile } from '../../../state/atoms'
import React from 'react'
import { useGwtUser } from '../../../hooks'

interface PolRetryButtonProps extends Omit<ButtonProps, 'onClick'> {
  file: UploadFile | undefined
  tooltipLocation?:
    | 'left'
    | 'none'
    | 'top'
    | 'bottom'
    | 'right'
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right'
    | undefined
}

export const PolRetryButton = ({
  file,
  tooltipLocation = 'left',
  ...props
}: PolRetryButtonProps) => {
  const { retryMutation, commitRetry } = usePolUpload()
  const user = useGwtUser()

  return (
    <Tooltip location={tooltipLocation} content="Retry upload">
      <Button
        disabled={file?.uploadStatus !== 'ERROR' || retryMutation.isInFlight}
        onClick={() => {
          commitRetry(
            { polFileUploadId: file?.rowId },
            {
              galleryAssetId: file?.galleryAssetId,
              gwtUserId: user.rowId,
            },
          )
        }}
        {...props}
      >
        {retryMutation.isInFlight ? (
          <Spinner size="dense" />
        ) : (
          <FontAwesomeIcon icon={faSync} />
        )}
      </Button>
    </Tooltip>
  )
}
