import {
  Button,
  ButtonProps,
  Spinner,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { graphql, useFragment } from 'react-relay'
import { useGwtMutation } from '../../../hooks'
import {
  getActivityInputObject,
  getNotificationInputObject,
} from '../../../util/relayUtils'
import {
  StartNewModuleAttemptInput,
  StartNextAttemptButtonMutation,
  StartNextAttemptButtonMutation$variables,
} from './__generated__/StartNextAttemptButtonMutation.graphql'
import { StartNextAttemptButton_Fragment$key } from './__generated__/StartNextAttemptButton_Fragment.graphql'
import { activityTypes } from '../../../constants'

interface StartNextAttemptButtonProps extends ButtonProps {
  moduleRef: StartNextAttemptButton_Fragment$key | null | undefined
}

export const StartNextAttemptButton = ({
  moduleRef = null,
  ...props
}: StartNextAttemptButtonProps) => {
  const makeToast = ToastProvider.useToaster()
  const data = useFragment(
    graphql`
      fragment StartNextAttemptButton_Fragment on Module {
        flowId
        currentAttempt
        attempts
        flow {
          name
          assignmentStatus
          assignedToId
        }
      }
    `,
    moduleRef,
  )

  const { commit, isInFlight } = useGwtMutation<StartNextAttemptButtonMutation>(
    graphql`
      mutation StartNextAttemptButtonMutation(
        $input: StartNewModuleAttemptInput!
        $activity: ActivityInput!
        $notificationInput: NotificationInput!
      ) {
        createNotification(input: { notification: $notificationInput }) {
          clientMutationId
        }
        createActivity(input: { activity: $activity }) {
          clientMutationId
        }
        startNewModuleAttempt(input: $input) {
          module {
            ...HistoryTab_module
            galleryAssetDeliverableId
            currentAttempt
            flow {
              assignmentStatus
              assignmentDueAtStatus
              startedAt
              dueAt
            }
            tasksByModuleFlowId {
              nodes {
                complete
              }
            }
          }
        }
      }
    `,
  )

  const submit = () => {
    if (!data || !data.flow || !canStartNextAttempt) return
    const mutation: StartNextAttemptButtonMutation$variables =
      {} as StartNextAttemptButtonMutation$variables

    mutation.input = {} as StartNewModuleAttemptInput
    mutation.input.moduleFlowId = data.flowId

    mutation.activity = getActivityInputObject(
      activityTypes.startedAttempt(data.currentAttempt),
      data.flow.name,
      data.flowId,
      `/reviewer/modules/${data.flowId}`,
    )

    mutation.notificationInput = getNotificationInputObject(
      'REVIEWER_MODULE_START_NEXT_ATTEMPT_NOTIFICATION',
    )

    commit(
      mutation,
      () => {
        makeToast({
          type: 'success',
          heading: `Module ${data?.flow?.name}`,
          message: 'Successfully started next attempt.',
        })
      },
      () => {
        makeToast({
          type: 'error',
          heading: 'Error',
          message: 'There was an error starting the next attempt.',
        })
      },
    )
  }

  if (isInFlight) {
    return <Spinner className="hc-ml-md hc-mr-md" />
  }

  const canStartNextAttempt =
    data?.flow?.assignmentStatus === 'REJECTED' &&
    data.currentAttempt < data.attempts

  return (
    <Button
      {...props}
      type="primary"
      disabled={!data?.flowId || isInFlight || !canStartNextAttempt}
      onClick={submit}
    >
      Start next attempt
    </Button>
  )
}
