import {
  Button,
  Grid,
  InputIconToggleProps,
  Modal,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { graphql, useFragment } from 'react-relay'
import { faStar } from '@fortawesome/free-regular-svg-icons'
import { faStar as faStarSolid } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGwtMutation, useGwtUser } from '../../../hooks'
import { TemplatesFavorite_flow$key } from './__generated__/TemplatesFavorite_flow.graphql'
import { TemplatesFavoriteCreateMutation } from './__generated__/TemplatesFavoriteCreateMutation.graphql'
import { TemplatesFavoriteDeleteMutation } from './__generated__/TemplatesFavoriteDeleteMutation.graphql'
import { usePublishGlobalNotification } from '../../../hooks/useNotifications'
import { useState } from 'react'

interface TemplatesFavoriteProps extends InputIconToggleProps {
  flowRef: TemplatesFavorite_flow$key | null | undefined
  disableConfirmationModal?: boolean
}

export const TemplatesFavorite = ({
  flowRef,
  ...props
}: TemplatesFavoriteProps) => {
  const user = useGwtUser()
  const { publishToLocal } = usePublishGlobalNotification()
  const [confimationModalOpen, setConfirmationModalOpen] = useState(false)

  const data = useFragment(
    graphql`
      fragment TemplatesFavorite_flow on Flow
      @argumentDefinitions(gwtUserId: { type: "UUID" }) {
        rowId
        flowFavorites(condition: { gwtUserId: $gwtUserId }) {
          nodes {
            flowId
          }
        }
      }
    `,
    flowRef || null,
  )

  const createMutation = useGwtMutation<TemplatesFavoriteCreateMutation>(
    graphql`
      mutation TemplatesFavoriteCreateMutation(
        $flowId: UUID!
        $gwtUserId: UUID!
      ) {
        createFlowFavorite(
          input: { flowFavorite: { flowId: $flowId, gwtUserId: $gwtUserId } }
        ) {
          clientMutationId
          flow {
            ...TemplatesFavorite_flow @arguments(gwtUserId: $gwtUserId)
          }
        }
      }
    `,
  )

  const deleteMutation = useGwtMutation<TemplatesFavoriteDeleteMutation>(
    graphql`
      mutation TemplatesFavoriteDeleteMutation(
        $flowId: UUID!
        $gwtUserId: UUID!
      ) {
        deleteFlowFavorite(input: { flowId: $flowId, gwtUserId: $gwtUserId }) {
          clientMutationId
          flow {
            ...TemplatesFavorite_flow @arguments(gwtUserId: $gwtUserId)
          }
        }
      }
    `,
  )

  const isFavorited = !!data?.flowFavorites.nodes.find(
    (f) => f.flowId === data.rowId,
  )

  const toggleFavorite = () => {
    if (!data) return
    if (isFavorited) {
      deleteMutation.commit(
        { flowId: data.rowId, gwtUserId: user.rowId },
        () => {
          publishToLocal('FAVORITE_TEMPLATE_NOTIFICATION')
          setConfirmationModalOpen(false)
        },
      )
    } else {
      // create
      createMutation.commit(
        { flowId: data.rowId, gwtUserId: user.rowId },
        () => {
          publishToLocal('FAVORITE_TEMPLATE_NOTIFICATION')
          setConfirmationModalOpen(false)
        },
      )
    }
  }

  return (
    <div className="templates-favorite">
      <Tooltip
        content={isFavorited ? 'Unfavorite' : 'Favorite'}
        location="left"
      >
        <Button
          {...props}
          display="star"
          type="ghost"
          iconOnly
          size="dense"
          aria-label="templates-favorite"
          checked={isFavorited}
          disabled={
            !data ||
            createMutation.isInFlight ||
            deleteMutation.isInFlight ||
            confimationModalOpen
          }
          onChange={() => {}}
          onClick={() => {
            if (props.disableConfirmationModal || !isFavorited) {
              toggleFavorite()
            } else {
              setConfirmationModalOpen(true)
            }
          }}
        >
          <FontAwesomeIcon
            icon={isFavorited ? faStarSolid : faStar}
            color="#DDBB40"
            fill="#DDBB40"
            size="lg"
          />
        </Button>
      </Tooltip>
      {confimationModalOpen && (
        <Modal
          isVisible={confimationModalOpen}
          size="dense"
          headingText="Remove template from favorited templates"
          className="align-left"
          onRefuse={() => setConfirmationModalOpen(false)}
        >
          <div className="hc-fs-md hc-ma-md">
            Removing this template from your list will not permanently delete
            it. You can still find the template on the Templates page.
            <Grid.Container>
              <Grid.Item xs={6} />
              <Grid.Item xs={6} className="hc-mt-lg hc-ta-right">
                <Button
                  type="secondary"
                  className="hc-mr-normal"
                  onClick={() => setConfirmationModalOpen(false)}
                >
                  Cancel
                </Button>
                <Button
                  type="primary"
                  disabled={
                    createMutation.isInFlight || deleteMutation.isInFlight
                  }
                  onClick={toggleFavorite}
                >
                  Remove
                </Button>
              </Grid.Item>
            </Grid.Container>
          </div>
        </Modal>
      )}
    </div>
  )
}
