import { Chip, ChipProps } from '@enterprise-ui/canvas-ui-react'
import { graphql, useFragment } from 'react-relay'
import classNames from 'classnames'
import {
  UserStatus,
  UserStatusChip_gwtUser$key,
} from './__generated__/UserStatusChip_gwtUser.graphql'

interface UserStatusChipProps extends ChipProps {
  userRef: UserStatusChip_gwtUser$key | null | undefined
}

const getChipColorCssClass = (status: UserStatus) => {
  if (status === 'ACTIVE') {
    return 'success'
  }

  if (status === 'DEACTIVATED') {
    return 'error'
  }

  if (status === 'SUSPENDED') {
    return 'alert'
  }
}

export const UserStatusChip = ({
  userRef = null,
  className,
  ...props
}: UserStatusChipProps) => {
  const data = useFragment(
    graphql`
      fragment UserStatusChip_gwtUser on GwtUser {
        status
      }
    `,
    userRef,
  )

  if (!data) {
    return <></>
  }

  const chipColorClass = getChipColorCssClass(data.status)

  return (
    <Chip
      type="subtle"
      color={chipColorClass}
      className={classNames(className, chipColorClass, 'capitalize')}
      size="dense"
      {...props}
    >
      {data.status}
    </Chip>
  )
}
