import { Card } from '@enterprise-ui/canvas-ui-react'
import { useNavigate } from 'react-router-dom'
import { graphql, useFragment } from 'react-relay'
import { UserAssignments$key } from './__generated__/UserAssignments.graphql'
import './UserAssignments.scss'
import { getModuleProgramTypeText } from '../../../util/moduleUtils'
import { StatusChip } from '../../Flows/StatusChip'
interface UserAssignmentsProps {
  userRef: UserAssignments$key | null | undefined
}

export const UserAssignments = ({ userRef }: UserAssignmentsProps) => {
  const navigate = useNavigate()
  const data = useFragment(
    graphql`
      fragment UserAssignments on GwtUser {
        flowsByAssignedToId(
          condition: { linkedType: MODULE }
          includeArchived: YES
        ) {
          nodes {
            ...StatusChip_flow
            rowId
            assignmentDueAtStatus
            assignmentStatus
            name
            module {
              flowId
              programType
            }
          }
        }
      }
    `,
    userRef || null,
  )

  if (!data) {
    return <></>
  }

  if (data.flowsByAssignedToId.nodes.length === 0) {
    return (
      <div className="user-assignments">
        <div className="container bold-text hc-ta-center">
          No assignments were found.
        </div>
      </div>
    )
  }

  return (
    <div className="user-assignments">
      <div className="container">
        {data.flowsByAssignedToId.nodes.map((flow) => {
          return (
            <Card
              className="card hc-mb-md"
              key={flow.module?.flowId}
              onClick={() =>
                navigate(`/reviewer/modules/${flow.module?.flowId}`)
              }
            >
              <div>
                <span className="hc-ml-sm bold-text">{flow.name}</span>
                <span className="hc-ml-sm">
                  {getModuleProgramTypeText(flow.module?.programType)}
                </span>
              </div>

              <StatusChip className="status-chip" flowRef={flow} />
            </Card>
          )
        })}
      </div>
    </div>
  )
}
