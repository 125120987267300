import { useEffect, useState } from 'react'
import { graphql, useFragment } from 'react-relay'
import { useGwtMutation } from '../../hooks'
import { DeliverableFileUploadTableMutation } from './__generated__/DeliverableFileUploadTableMutation.graphql'
import { DeliverableFileUploadTable_galleryAsset$key } from './__generated__/DeliverableFileUploadTable_galleryAsset.graphql'
import { FileUploadTable } from '../Files/FileUploadTable/FileUploadTable'
import { Spinner, ToastProvider } from '@enterprise-ui/canvas-ui-react'

interface DeliverableFileUploadTableProps {
  moduleRef: DeliverableFileUploadTable_galleryAsset$key | null
  onUpload?: () => void
}

export const DeliverableFileUploadTable = (
  props: DeliverableFileUploadTableProps,
) => {
  const [initAttempts, setInitAttempts] = useState(0)
  const makeToast = ToastProvider.useToaster()

  const data = useFragment(
    graphql`
      fragment DeliverableFileUploadTable_galleryAsset on Module {
        flowId
        galleryAssetDeliverableId
      }
    `,
    props.moduleRef || null,
  )
  const { commit, isInFlight } =
    useGwtMutation<DeliverableFileUploadTableMutation>(graphql`
      mutation DeliverableFileUploadTableMutation($moduleFlowId: UUID!) {
        initializeModuleDeliverableAsset(
          input: { moduleFlowId: $moduleFlowId }
        ) {
          module {
            flowId
            galleryAssetDeliverableId
          }
        }
      }
    `)

  const handleSuccessUpload = () => {
    if (props.onUpload) {
      props.onUpload()
    }
    makeToast({
      heading: 'Files uploading...',
      message: 'Please give a few minutes for the files to be uploaded',
    })
  }

  useEffect(() => {
    if (
      data &&
      !data.galleryAssetDeliverableId &&
      !isInFlight &&
      initAttempts < 3
    ) {
      commit({ moduleFlowId: data.flowId })
      setInitAttempts(initAttempts + 1)
    }
  }, [commit, data, initAttempts, setInitAttempts, isInFlight])

  if (data?.galleryAssetDeliverableId) {
    return (
      <FileUploadTable
        assetId={data.galleryAssetDeliverableId}
        onAllFilesUploaded={handleSuccessUpload}
      />
    )
  } else {
    if (isInFlight && initAttempts < 3) {
      return (
        <div>
          Setting up deliverable files for upload... <Spinner />
        </div>
      )
    }
    if (initAttempts >= 3) {
      return (
        <div style={{ textAlign: 'center' }}>
          <h1>-- PROBLEM SETTING UP DELIVERABLE FILES --</h1>
          <p style={{ color: 'red' }}>
            <br />
            You will be unable to set these up unless you are assigned to this
            Module.
          </p>
          <h1>
            ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ---- ----
            ---- ----
          </h1>
        </div>
      )
    }
  }
  return <></>
}
