export const setAssignmentStatus = (assignmentStatus: string) =>
  `set status to ${assignmentStatus} for`

export const startedAttempt = (currentAttempt: number) =>
  `STARTED ATTEMPT ${currentAttempt + 1} for`

export const MADE_A_COPY = 'MADE A COPY of'
export const RESPONDED_TO_A_COMMENT = 'RESPONDED to a comment in'
export const ADDED_A_COMMENT = 'ADDED A COMMENT to'
export const SUBMITTED_A_MODULE = 'SUBMITTED A MODULE for review:'
export const EDITED_A_MODULE = 'EDITED MODULE:'
export const SUBMITTED_FEEDBACK = 'SUBMITTED FEEDBACK:'
