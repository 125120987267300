import { Grid, Card, Divider } from '@enterprise-ui/canvas-ui-react'
import './Assignee.scss'
import { graphql } from 'react-relay'
import { FlowLink } from '../../Flows/FlowLink'
import { useGwtUser } from '../../../hooks'
import { useSubscriptionRefreshQuery } from '../../../hooks/useRefresh'
import { HistoryCardPaginationQuery } from './__generated__/HistoryCardPaginationQuery.graphql'
import { StatusChip } from '../../Flows/StatusChip'
import { getGwtFormattedDate } from '../../../util/dateUtils'

export const HistoryCard = () => {
  const { data } = useNavigateCardQuery()

  return (
    <Card className="cardLayout">
      <div className="hc-pa-none cardHeader flex hc-pa-lg">
        <Grid.Container spacing="none" align="center" className="flex-grow">
          <Grid.Item>
            <div className="card-header-text">History</div>
          </Grid.Item>
        </Grid.Container>
      </div>
      <Divider />
      <div className="hc-pa-none card-scroll flex-grow">
        {data &&
          data.modules?.nodes.map((module) => {
            return (
              <div key={module.flow?.rowId}>
                <FlowLink flowRef={module.flow}>
                  <Grid.Container
                    spacing="none"
                    align="center"
                    className="hc-pa-lg to-do-hover"
                  >
                    <Grid.Item xs={8}>
                      <div className="to-do-header-text">
                        {module.flow?.name}
                      </div>
                      <div className="to-do-card-text">
                        <div>
                          {module.flow?.completedAt &&
                            `Completed on ${getGwtFormattedDate(
                              module.flow.completedAt,
                            )}`}
                        </div>
                      </div>
                    </Grid.Item>
                    <Grid.Item xs={4}>
                      <StatusChip
                        className="status-chip"
                        flowRef={module.flow}
                      />
                    </Grid.Item>
                  </Grid.Container>
                </FlowLink>
                <Divider />
              </div>
            )
          })}
      </div>
    </Card>
  )
}

const useNavigateCardQuery = () => {
  const user = useGwtUser()

  const data = useSubscriptionRefreshQuery<HistoryCardPaginationQuery>(
    graphql`
      query HistoryCardPaginationQuery($userId: UUID!) {
        modules(
          orderBy: FLOW_BY_FLOW_ID__COMPLETED_AT_DESC
          filter: {
            flow: {
              assignmentStatus: { in: [ACCEPTED] }
              assignedToId: { equalTo: $userId }
            }
          }
        ) {
          nodes {
            flow {
              rowId
              completedAt
              name
              ...FlowLink_flow
              ...StatusChip_flow
            }
          }
        }
      }
    `,
    { userId: user.rowId },
    {
      component: 'ASSIGNEE_HISTORY_CARD',
      uniqueComponentId: '',
      onNotification: [
        'REVIEWER_MODULE_BUTTON_NOTIFICATION',
        'REVIEWER_MODULE_START_NEXT_ATTEMPT_NOTIFICATION',
      ],
      includeSelfNotifications: true,
    },
  )
  return { data }
}
