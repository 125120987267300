import React, { useState } from 'react'
import { ImageProps } from '@enterprise-ui/canvas-ui-react'
import { FileView } from '@galleryjs/api-digital-assets'
import { useEnv } from '@praxis/component-runtime-env'
import { useGlobalCarousel } from '../../hooks/useGlobalCarousel'

import aiFallbackSvgUrl from './fallback-images/ai-fallback.svg?url'
import docFallbackSvgUrl from './fallback-images/doc-fallback.svg?url'
import objFallbackSvgUrl from './fallback-images/obj-fallback.svg?url'
import pdfFallbackSvgUrl from './fallback-images/pdf-fallback.svg?url'
import pptFallbackSvgUrl from './fallback-images/ppt-fallback.svg?url'
import psdFallbackSvgUrl from './fallback-images/psd-fallback.svg?url'
import unknownFallbackSvgUrl from './fallback-images/unknown-fallback.svg?url'
import xlsFallbackSvgUrl from './fallback-images/xls-fallback.svg?url'
import glbFallbackSvgUrl from './fallback-images/glb-fallback.svg?url'

import './icons.scss'
import { useEffect } from 'react'

interface GalleryFileImageProps extends ImageProps {
  file: FileView
  enableLightbox?: boolean
}

const GalleryFileImage = ({
  file,
  enableLightbox,
  ...canvasImageProps
}: GalleryFileImageProps) => {
  const env = useEnv()
  const { showFullScreenImage } = useGlobalCarousel()
  const apiBaseUrl = env.baseGalleryApiUrl

  const [imageSrc, setImageSrc] = useState(
    [...file.fileSources]?.[0]?.accessUrl || `${apiBaseUrl}/files/${file.id}`,
  )
  const [fallbackImageSrc, setFallbackImageSrc] = useState<any>(undefined)

  const [objectFit, setObjectFit] = useState<'contain' | 'cover'>('cover')

  useEffect(() => {
    setImageSrc(
      [...file.fileSources]?.[0]?.accessUrl || `${apiBaseUrl}/files/${file.id}`,
    )
    setFallbackImageSrc(undefined)
  }, [apiBaseUrl, file])

  const canLightBox = enableLightbox && !fallbackImageSrc
  return (
    <div className={canLightBox ? 'magnifying-glass-hover' : ''}>
      <img
        {...canvasImageProps}
        style={{ objectFit }}
        src={fallbackImageSrc || imageSrc}
        onClick={() => {
          if (canLightBox) {
            showFullScreenImage({
              src: imageSrc,
              title: file.name,
            })
          }
        }}
        onLoad={() => {
          // finished new load, set the image fit if it is a loaded img or fallback
          if (fallbackImageSrc) {
            setObjectFit('contain')
          } else {
            setObjectFit('cover')
          }
        }}
        onError={() => {
          setFallbackImageSrc(
            getFallbackImageSrc(
              [...file.fileSources]?.[0]?.fileExtension,
            ).toString(),
          )
        }}
        alt=""
      />
    </div>
  )
}

const getFallbackImageSrc = (fileExtension: string | undefined) => {
  if (!fileExtension) {
    return unknownFallbackSvgUrl
  }
  const extension = fileExtension

  switch (extension) {
    case 'xls':
      return xlsFallbackSvgUrl
    case 'xlsx':
      return xlsFallbackSvgUrl
    case 'pdf':
      return pdfFallbackSvgUrl
    case 'ai':
      return aiFallbackSvgUrl
    case 'ppt':
      return pptFallbackSvgUrl
    case 'pptx':
      return pptFallbackSvgUrl
    case 'doc':
      return docFallbackSvgUrl
    case 'docx':
      return docFallbackSvgUrl
    case 'psd':
      return psdFallbackSvgUrl
    case 'obj':
      return objFallbackSvgUrl
    case 'glb':
      return glbFallbackSvgUrl
    default:
      return unknownFallbackSvgUrl
  }
}

export default GalleryFileImage
