/**
 * @generated SignedSource<<3a90d01c1e7c2ac7515b936ec22ae6b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TemplatesFavoriteDeleteMutation$variables = {
  flowId: UUID;
  gwtUserId: UUID;
};
export type TemplatesFavoriteDeleteMutation$data = {
  readonly deleteFlowFavorite: {
    readonly clientMutationId: string | null;
    readonly flow: {
      readonly " $fragmentSpreads": FragmentRefs<"TemplatesFavorite_flow">;
    } | null;
  } | null;
};
export type TemplatesFavoriteDeleteMutation = {
  response: TemplatesFavoriteDeleteMutation$data;
  variables: TemplatesFavoriteDeleteMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "flowId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "gwtUserId"
  }
],
v1 = {
  "kind": "Variable",
  "name": "gwtUserId",
  "variableName": "gwtUserId"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "flowId",
        "variableName": "flowId"
      },
      (v1/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v4 = [
  (v1/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TemplatesFavoriteDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "DeleteFlowFavoritePayload",
        "kind": "LinkedField",
        "name": "deleteFlowFavorite",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Flow",
            "kind": "LinkedField",
            "name": "flow",
            "plural": false,
            "selections": [
              {
                "args": (v4/*: any*/),
                "kind": "FragmentSpread",
                "name": "TemplatesFavorite_flow"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TemplatesFavoriteDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "DeleteFlowFavoritePayload",
        "kind": "LinkedField",
        "name": "deleteFlowFavorite",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Flow",
            "kind": "LinkedField",
            "name": "flow",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rowId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "fields": (v4/*: any*/),
                    "kind": "ObjectValue",
                    "name": "condition"
                  }
                ],
                "concreteType": "FlowFavoritesConnection",
                "kind": "LinkedField",
                "name": "flowFavorites",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FlowFavorite",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "flowId",
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0e4e5c852cdac5eb58bacab81f9efb7d",
    "id": null,
    "metadata": {},
    "name": "TemplatesFavoriteDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation TemplatesFavoriteDeleteMutation(\n  $flowId: UUID!\n  $gwtUserId: UUID!\n) {\n  deleteFlowFavorite(input: {flowId: $flowId, gwtUserId: $gwtUserId}) {\n    clientMutationId\n    flow {\n      ...TemplatesFavorite_flow_1AeAhh\n      id\n    }\n  }\n}\n\nfragment TemplatesFavorite_flow_1AeAhh on Flow {\n  rowId\n  flowFavorites(condition: {gwtUserId: $gwtUserId}) {\n    nodes {\n      flowId\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5d1939d6b68039f9c62859d7e921a90e";

export default node;
