/**
 * @generated SignedSource<<754c707ca38ae58a35dc8b127c536df0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserModal_user$data = {
  readonly fullName: string;
  readonly rowId: UUID;
  readonly " $fragmentSpreads": FragmentRefs<"UserAvatar_gwtUser" | "UserHistory" | "UserInfo">;
  readonly " $fragmentType": "UserModal_user";
};
export type UserModal_user$key = {
  readonly " $data"?: UserModal_user$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserModal_user">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserModal_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "fullName",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserAvatar_gwtUser"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserHistory"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserInfo"
    }
  ],
  "type": "GwtUser",
  "abstractKey": null
};

(node as any).hash = "8c4388fd0f67dcf6574dd36d8b453cbb";

export default node;
