import {
  Button,
  Card,
  Chip,
  Heading,
  Layout,
  Table,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { graphql, usePaginationFragment } from 'react-relay'
import { useSubscriptionRefreshQuery } from '../../hooks/useRefresh'
import {
  AssignmentsTablePaginationQuery,
  IncludeArchivedOption,
  ModulesOrderBy,
} from './__generated__/AssignmentsTablePaginationQuery.graphql'
import { StatusChip } from '../Flows/StatusChip'
import './AssignmentsTable.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import {
  DEFAULT_MODULE_FILTER,
  ModuleFilterChip,
  ModuleFilterDropdown,
} from '../../GenericComponents/ModuleFilter/ModuleFilterDropdown'
import { SyntheticEvent, useState } from 'react'
import { TextInputDebounce } from '../../GenericComponents/TextInputDebounce/TextInputDebounce'
import { useEffect } from 'react'
import { NOTIFICATIONS } from '../../hooks'
import { merge } from 'lodash'
import { usePaginateDataLength } from '../../hooks/usePaginateDataLength'
import { AssignmentsTablePaginationFragment_modules$key } from './__generated__/AssignmentsTablePaginationFragment_modules.graphql'
import { ModuleFilter } from './__generated__/AssignmentsTablePaginationQuery_Refetchable.graphql'
import { getModuleProgramTypeText } from '../../util/moduleUtils'
import { TableSortHeader } from '../../GenericComponents/TableSortHeader'
import { getGwtFormattedDate, getDistanceFromNow } from '../../util/dateUtils'
import { FlowLink } from '../Flows/FlowLink'
import { UserLink } from '../Users/UserLink'
import { UserModal } from '../Users/UserModal'
import { AssignmentOptions } from './AssignmentOptions/AssignmentOptions'
import { UserPriority } from '../Users/UserPriority/UserPriority'

const DEFAULT_SEARCH_FILTER: ModuleFilter = {
  flow: {
    or: [
      { name: { likeInsensitive: undefined } },
      {
        assignedTo: {
          fullName: { likeInsensitive: undefined },
        },
      },
      {
        flowReviewers: {
          some: {
            reviewer: {
              fullName: { likeInsensitive: undefined },
            },
          },
        },
      },
    ],
  },
}

const PAGE_SIZE = 20

export const AssignmentsTable = () => {
  const [moduleFilter, setModuleFilter] = useState<ModuleFilter>(
    DEFAULT_MODULE_FILTER,
  )
  const [moduleSearchFilter, setModuleSearchFilter] = useState<ModuleFilter>(
    DEFAULT_SEARCH_FILTER,
  )

  const [orderBy, setOrderBy] = useState<ModulesOrderBy>(
    'FLOW_BY_FLOW_ID__DUE_AT_DESC',
  )

  const [filterChips, setFilterChips] = useState<ModuleFilterChip[]>()

  const [includeArchived, setIncludeArchived] = useState<
    IncludeArchivedOption | undefined
  >(undefined)

  const [combinedFilter, setCombinedFilter] = useState<ModuleFilter>(
    merge({}, moduleFilter, moduleSearchFilter),
  )

  const { data, isLoadingNext, hasNext, loadNext } = useAssignmentsTableQuery(
    combinedFilter,
    [orderBy],
    includeArchived,
  )

  const scrollEvent = (e: SyntheticEvent) => {
    if (isLoadingNext || !hasNext) {
      return
    }
    const target = e.target as HTMLTextAreaElement
    const scrollLeft = target.scrollHeight - target.scrollTop
    if (scrollLeft / target.clientHeight < 2) {
      loadNext(PAGE_SIZE)
    }
  }

  const updateSort = (orderBy: ModulesOrderBy) => {
    setOrderBy(orderBy)
  }

  useEffect(() => {
    setCombinedFilter(merge({}, moduleFilter, moduleSearchFilter))
  }, [moduleFilter, moduleSearchFilter])

  return (
    <Layout.Body includeRail className="assignments-table">
      <Card>
        <Table name="Assignments" cellPadding="normal">
          <Table.Head>
            <Table.Row align="center">
              <Table.Header>
                <Heading size={5}>Assignments</Heading>
              </Table.Header>
              <Table.Header style={{ paddingLeft: '0px' }} xs={3}>
                <TextInputDebounce
                  dense
                  delay={500}
                  placeholder="Search"
                  onUpdate={(debouncedTerm) => {
                    if (debouncedTerm.length > 0) {
                      const update = `%${debouncedTerm}%`
                      setModuleSearchFilter({
                        flow: {
                          or: [
                            { name: { likeInsensitive: update } },
                            {
                              assignedTo: {
                                fullName: { likeInsensitive: update },
                              },
                            },
                            {
                              flowReviewers: {
                                some: {
                                  reviewer: {
                                    fullName: { likeInsensitive: update },
                                  },
                                },
                              },
                            },
                          ],
                        },
                      })
                    } else {
                      setModuleSearchFilter({
                        flow: {
                          or: [
                            { name: { likeInsensitive: undefined } },
                            {
                              assignedTo: {
                                fullName: { likeInsensitive: undefined },
                              },
                            },
                            {
                              flowReviewers: {
                                some: {
                                  reviewer: {
                                    fullName: { likeInsensitive: undefined },
                                  },
                                },
                              },
                            },
                          ],
                        },
                      })
                    }
                  }}
                />
              </Table.Header>
              <Table.Header sm={1} className="table-header-spacing">
                <ModuleFilterDropdown
                  onChipsUpdated={(chips) => setFilterChips(chips)}
                  onFilterUpdated={(filter) => setModuleFilter(filter)}
                  onArchiveUpdated={(archived) => setIncludeArchived(archived)}
                >
                  <Button
                    type="secondary"
                    size="dense"
                    className="table-header-spacing"
                  >
                    <FontAwesomeIcon icon={faFilter} className="hc-mr-xs" />
                    Add filters
                  </Button>
                </ModuleFilterDropdown>
              </Table.Header>
              <Table.Header xs className="chips">
                <div>
                  {filterChips?.map((filterChip) => {
                    return (
                      <Chip
                        size="dense"
                        onRequestDelete={filterChip.remove}
                        key={filterChip.key + filterChip.value}
                      >
                        {`${filterChip.key}: ${filterChip.value}`}
                      </Chip>
                    )
                  })}
                  {filterChips && filterChips.length > 0 && (
                    <div
                      className="clear-all"
                      onClick={() => {
                        filterChips?.forEach((chip) => chip.remove())
                      }}
                    >
                      {'Clear all'}
                    </div>
                  )}
                </div>
              </Table.Header>
            </Table.Row>
            <Table.Row align="center">
              <TableSortHeader
                xs={2}
                selectedOrderBy={orderBy}
                onSort={updateSort}
                sortUpFilter="FLOW_BY_FLOW_ID__NAME_ASC"
                sortDownFilter="FLOW_BY_FLOW_ID__NAME_DESC"
              >
                Assignment
              </TableSortHeader>
              <TableSortHeader
                xs={1}
                selectedOrderBy={orderBy}
                onSort={updateSort}
                sortUpFilter="FLOW_BY_FLOW_ID__UPDATED_AT_ASC"
                sortDownFilter="FLOW_BY_FLOW_ID__UPDATED_AT_DESC"
              >
                Last updated
              </TableSortHeader>
              <Table.Header xs={1}>Assignee</Table.Header>
              <Table.Header xs={1}>Reviewer</Table.Header>
              <TableSortHeader
                xs={1}
                selectedOrderBy={orderBy}
                onSort={updateSort}
                sortUpFilter="SOFTWARE_TYPE_ASC"
                sortDownFilter="SOFTWARE_TYPE_DESC"
              >
                Software
              </TableSortHeader>
              <TableSortHeader
                xs={1}
                selectedOrderBy={orderBy}
                onSort={updateSort}
                sortUpFilter="PROGRAM_TYPE_ASC"
                sortDownFilter="PROGRAM_TYPE_DESC"
              >
                Program
              </TableSortHeader>
              <TableSortHeader
                xs={1}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="FLOW_BY_ASSIGNED_TO_COMPANY_NAME_ASC"
                sortDownFilter="FLOW_BY_ASSIGNED_TO_COMPANY_NAME_DESC"
              >
                Company
              </TableSortHeader>
              <Table.Header xs={1}>Status</Table.Header>
              <TableSortHeader
                selectedOrderBy={orderBy}
                onSort={updateSort}
                sortUpFilter="FLOW_BY_FLOW_ID__STATUS_CHANGED_AT_ASC"
                sortDownFilter="FLOW_BY_FLOW_ID__STATUS_CHANGED_AT_DESC"
                xs={1}
              >
                Time in status
              </TableSortHeader>
              <TableSortHeader
                xs={1}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="FLOW_BY_ASSIGNED_TO_USER_PRIORITY_ASC"
                sortDownFilter="FLOW_BY_ASSIGNED_TO_USER_PRIORITY_DESC"
              >
                Priority
              </TableSortHeader>
              <Table.Header xs={1} />
            </Table.Row>
          </Table.Head>
          <Table.Body onScroll={scrollEvent} className="overflow">
            {data?.modules?.edges?.map(({ node }, i) => {
              return (
                <Table.Row align="center" key={node.flow?.rowId}>
                  <Table.Data xs={2}>
                    <FlowLink flowRef={node.flow}>
                      <p className="link-hover">{node.flow?.name}</p>
                    </FlowLink>
                  </Table.Data>
                  <Table.Data xs={1}>
                    {getGwtFormattedDate(node.flow?.updatedAt)}
                  </Table.Data>
                  <Table.Data xs={1}>
                    <UserModal userRef={node.flow?.assignedTo} />
                  </Table.Data>
                  <Table.Data xs={1}>
                    {node.flow?.flowReviewers.nodes.map((node) => {
                      return (
                        <>
                          <UserModal userRef={node.reviewer} />
                          <UserLink userRef={node.reviewer} />
                        </>
                      )
                    })}
                  </Table.Data>
                  <Table.Data xs={1}>{node.softwareType}</Table.Data>
                  <Table.Data xs={1}>
                    {getModuleProgramTypeText(node.programType)}
                  </Table.Data>
                  <Table.Data xs={1}>
                    {node.flow?.assignedTo?.company?.name}
                  </Table.Data>
                  <Table.Data xs={1}>
                    <StatusChip className="status-chip" flowRef={node.flow} />
                  </Table.Data>
                  <Table.Data xs={1}>
                    <Tooltip
                      content={getGwtFormattedDate(node.flow?.statusChangedAt)}
                      location="right"
                    >
                      {getDistanceFromNow(node.flow?.statusChangedAt)}
                    </Tooltip>
                  </Table.Data>
                  <Table.Data xs={1}>
                    <UserPriority userRef={node.flow?.assignedTo} />
                  </Table.Data>
                  <Table.Data align="flex-start" xs={1}>
                    <AssignmentOptions
                      moduleRef={node}
                      tableLocation={i}
                      status={node.flow?.assignmentStatus}
                    />
                  </Table.Data>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </Card>
    </Layout.Body>
  )
}

const useAssignmentsTableQuery = (
  moduleFilter: ModuleFilter | undefined = undefined,
  modulesOrderBy: [ModulesOrderBy] | undefined = undefined,
  includeArchived: IncludeArchivedOption = 'NO',
) => {
  const [dataLength, setDataLength] = useState(PAGE_SIZE)

  const pageData = useSubscriptionRefreshQuery<AssignmentsTablePaginationQuery>(
    graphql`
      query AssignmentsTablePaginationQuery(
        $moduleFilter: ModuleFilter
        $modulesOrderBy: [ModulesOrderBy!]
        $count: Int!
        $includeArchived: IncludeArchivedOption
      ) {
        ...AssignmentsTablePaginationFragment_modules
          @arguments(
            moduleFilter: $moduleFilter
            modulesOrderBy: $modulesOrderBy
            includeArchived: $includeArchived
            count: $count
          )
      }
    `,
    { moduleFilter, modulesOrderBy, count: dataLength, includeArchived },
    {
      component: 'ASSIGNMENTS_TABLE',
      uniqueComponentId: '',
      onNotification: [...NOTIFICATIONS],
      includeSelfNotifications: true,
    },
  )

  const paginationFragmentHook = usePaginationFragment<
    AssignmentsTablePaginationQuery,
    AssignmentsTablePaginationFragment_modules$key
  >(
    graphql`
      fragment AssignmentsTablePaginationFragment_modules on Query
      @argumentDefinitions(
        moduleFilter: { type: "ModuleFilter" }
        modulesOrderBy: { type: "[ModulesOrderBy!]" }
        includeArchived: { type: "IncludeArchivedOption" }
        count: { type: "Int" }
        cursor: { type: "Cursor" }
      )
      @refetchable(queryName: "AssignmentsTablePaginationQuery_Refetchable") {
        modules(
          includeArchived: $includeArchived
          filter: $moduleFilter
          orderBy: $modulesOrderBy
          first: $count
          after: $cursor
        ) @connection(key: "AssignmentsTable_modules") {
          edges {
            node {
              ...AssignmentOptions_module
              programType
              softwareType
              flow {
                ...FlowLink_flow
                updatedAt
                rowId
                completedAt
                dueAt
                name
                assignmentStatus
                statusChangedAt
                flowReviewers {
                  nodes {
                    reviewer {
                      fullName
                      ...UserModal_user
                      ...UserLink_user
                    }
                  }
                }
                assignedTo {
                  userPriority
                  ...UserPriority_gwtUser
                  company {
                    name
                    rowId
                  }
                  fullName
                  ...UserModal_user
                  ...UserLink_user
                }
                ...StatusChip_flow
              }
            }
          }
          totalCount
        }
      }
    `,
    pageData,
  )

  const paginateLength = usePaginateDataLength(
    paginationFragmentHook?.data?.modules?.edges,
    PAGE_SIZE,
  )

  useEffect(() => {
    if (paginateLength) {
      setDataLength(paginateLength)
    }
  }, [paginateLength])

  return { ...paginationFragmentHook, pageData }
}
