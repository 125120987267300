import { useLocation, useNavigate } from 'react-router-dom'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { useGwtMutation } from '../../../hooks'
import { UUID } from '../../../react-app-env'
import { ModuleEditForm } from '../ModuleEditForm'
import { getControlledUserSelectObjectOrDefault } from '../../../util/userUtils'
import { getFlowDuration } from '../../../util/flowUtils'
import { ModuleEditPageMutation } from './__generated__/ModuleEditPageMutation.graphql'
import { ModuleEditPageQuery } from './__generated__/ModuleEditPageQuery.graphql'

export interface EditAdminAssignmentCardDataProps {
  moduleFlowId: string | undefined
}

export const ModuleEditPage = () => {
  const location = useLocation()
  const navigate = useNavigate()

  //Information to get name and rowID of selected template
  const currentModule = location.state as EditAdminAssignmentCardDataProps
  const currentModuleRowId = currentModule?.moduleFlowId
  if (!currentModuleRowId) {
    // if we do not have state data for this page, abort!
    navigate('/')
  }

  const data = useModuleEditQuery(currentModuleRowId || '')

  const { commit, isInFlight } = useGwtMutation<ModuleEditPageMutation>(graphql`
    mutation ModuleEditPageMutation(
      $attempts: Int!
      $dueAt: Datetime
      $rowId: UUID!
      $activity: ActivityInput!
      $name: String!
      $description: String!
      $notificationInput: NotificationInput!
      $tasks: [TaskModuleFlowIdFkeyTaskCreateInput!]
      $reviewers: [FlowReviewerFlowIdFkeyFlowReviewerCreateInput!]
      $softwareType: SoftwareType
      $programType: ProgramType
      $versionNumber: BigFloat
      $durationSeconds: Int!
    ) {
      createActivity(input: { activity: $activity }) {
        clientMutationId
      }
      createNotification(input: { notification: $notificationInput }) {
        clientMutationId
      }
      updateModule(
        input: {
          patch: {
            versionNumber: $versionNumber
            attempts: $attempts
            programType: $programType
            softwareType: $softwareType
            tasksUsingFlowId: { deleteOthers: true, create: $tasks }
            flowToFlowId: {
              update: {
                name: $name
                description: $description
                dueAt: $dueAt
                durationSeconds: $durationSeconds
                flowReviewersUsingRowId: {
                  create: $reviewers
                  deleteOthers: true
                }
              }
            }
          }
          flowId: $rowId
        }
      ) {
        module {
          ...HistoryTab_module
          attempts
          softwareType
          programType
          flow {
            name
            description
            dueAt
            assignmentDueAtStatus
            durationSeconds
            flowReviewers(first: 1) {
              nodes {
                reviewer {
                  rowId
                }
              }
            }
          }
          tasksByModuleFlowId(orderBy: PRIORITY_ASC) {
            nodes {
              flow {
                name
                id
                rowId
              }
            }
          }
          galleryAssetComponentsId
        }
      }
    }
  `)

  let originalModuleDuration = getFlowDuration(
    data.module?.flow?.durationSeconds,
    data.module?.flow?.createdAt,
    data.module?.flow?.dueAt,
  )

  const defaultValues = {
    duration: `${originalModuleDuration}`,
    name: data.module?.flow?.name || '',
    attempts: data.module?.attempts,
    activity: {},
    softwareType: data.module?.softwareType,
    programType: data.module?.programType,
    description: {
      textContent: '',
      htmlContent: data.module?.flow?.description || '',
    },
    assignTo:
      data.module?.flow?.flowReviewers.nodes?.[0]?.reviewer?.rowId ||
      'unassigned',
    tasks:
      data.module?.tasksByModuleFlowId?.nodes?.map((currentTask: any) => {
        return {
          value: currentTask.flow?.name || '',
        }
      }) || [],
    galleryAssetComponentsId: data.module?.galleryAssetComponentsId,
    rowId: data.module?.flow?.rowId,
    versionNumber: parseFloat(data.module?.versionNumber),
  }

  return (
    <ModuleEditForm
      defaultValues={defaultValues}
      defaultReviewer={getControlledUserSelectObjectOrDefault(
        data.module?.flow?.flowReviewers.nodes?.[0]?.reviewer?.rowId,
        data.module?.flow?.flowReviewers.nodes?.[0]?.reviewer?.firstName,
        data.module?.flow?.flowReviewers.nodes?.[0]?.reviewer?.lastName,
        data.module?.flow?.flowReviewers.nodes?.[0]?.reviewer?.company?.name,
      )}
      commit={commit}
      isInFlight={isInFlight}
    />
  )
}

const useModuleEditQuery = (currentModuleRowId: UUID) => {
  //Query to get the rest of the data for the current template that is being edited
  const currentModuleData = useLazyLoadQuery<ModuleEditPageQuery>(
    graphql`
      query ModuleEditPageQuery($currentModuleRowId: UUID!) {
        module(flowId: $currentModuleRowId) {
          programType
          softwareType
          versionNumber
          flow {
            name
            rowId
            assignmentStatus
            workflowType
            description
            dueAt
            createdAt
            durationSeconds
            flowReviewers(first: 1) {
              nodes {
                reviewer {
                  rowId
                  firstName
                  lastName
                  company {
                    name
                  }
                }
              }
            }
          }
          tasksByModuleFlowId(orderBy: PRIORITY_ASC) {
            nodes {
              flow {
                name
                id
                rowId
              }
            }
          }
          galleryAssetComponentsId
          attempts
        }
      }
    `,
    { currentModuleRowId },
  )

  return currentModuleData
}
