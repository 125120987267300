/**
 * @generated SignedSource<<a3c2c8820c64dc03a90fee3a29d45610>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TemplatesFavoriteCreateMutation$variables = {
  flowId: UUID;
  gwtUserId: UUID;
};
export type TemplatesFavoriteCreateMutation$data = {
  readonly createFlowFavorite: {
    readonly clientMutationId: string | null;
    readonly flow: {
      readonly " $fragmentSpreads": FragmentRefs<"TemplatesFavorite_flow">;
    } | null;
  } | null;
};
export type TemplatesFavoriteCreateMutation = {
  response: TemplatesFavoriteCreateMutation$data;
  variables: TemplatesFavoriteCreateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "flowId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "gwtUserId"
  }
],
v1 = {
  "kind": "Variable",
  "name": "gwtUserId",
  "variableName": "gwtUserId"
},
v2 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "flowId",
            "variableName": "flowId"
          },
          (v1/*: any*/)
        ],
        "kind": "ObjectValue",
        "name": "flowFavorite"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v4 = [
  (v1/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TemplatesFavoriteCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CreateFlowFavoritePayload",
        "kind": "LinkedField",
        "name": "createFlowFavorite",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Flow",
            "kind": "LinkedField",
            "name": "flow",
            "plural": false,
            "selections": [
              {
                "args": (v4/*: any*/),
                "kind": "FragmentSpread",
                "name": "TemplatesFavorite_flow"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TemplatesFavoriteCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "CreateFlowFavoritePayload",
        "kind": "LinkedField",
        "name": "createFlowFavorite",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Flow",
            "kind": "LinkedField",
            "name": "flow",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "rowId",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "fields": (v4/*: any*/),
                    "kind": "ObjectValue",
                    "name": "condition"
                  }
                ],
                "concreteType": "FlowFavoritesConnection",
                "kind": "LinkedField",
                "name": "flowFavorites",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FlowFavorite",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "flowId",
                        "storageKey": null
                      },
                      (v5/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0279bfae7e33306b7ef7d54b4f9b6de9",
    "id": null,
    "metadata": {},
    "name": "TemplatesFavoriteCreateMutation",
    "operationKind": "mutation",
    "text": "mutation TemplatesFavoriteCreateMutation(\n  $flowId: UUID!\n  $gwtUserId: UUID!\n) {\n  createFlowFavorite(input: {flowFavorite: {flowId: $flowId, gwtUserId: $gwtUserId}}) {\n    clientMutationId\n    flow {\n      ...TemplatesFavorite_flow_1AeAhh\n      id\n    }\n  }\n}\n\nfragment TemplatesFavorite_flow_1AeAhh on Flow {\n  rowId\n  flowFavorites(condition: {gwtUserId: $gwtUserId}) {\n    nodes {\n      flowId\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "903b11f4ad3430862ca97fa7b1f7d45d";

export default node;
