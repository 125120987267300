import { Fragment, useState } from 'react'
import {
  SubmitHandler,
  useForm,
  FormProvider,
  useFieldArray,
} from 'react-hook-form'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import { graphql } from 'react-relay'
import {
  Button,
  Form,
  Modal,
  Spinner,
  ToastProvider,
  Grid,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import { FileUploadTable } from '../Files/FileUploadTable/FileUploadTable'
import { UUID } from '../../react-app-env'
import { ControlledFormField } from '../../controlledComponents/ControlledFormField'
import { ControlledUserSelect } from '../../controlledComponents/ControlledUserSelect'
import { useGwtMutation, useGwtUser } from '../../hooks'
import { getControlledUserSelectDefaultUnnassigned } from '../../util/userUtils'
import {
  NewModuleModalMutation,
  NewModuleModalMutation$variables,
  ProgramType,
  SoftwareType,
} from './__generated__/NewModuleModalMutation.graphql'
import './NewModuleModal.scss'
import { usePublishGlobalNotification } from '../../hooks/useNotifications'
import { getModuleProgramTypeText } from '../../util/moduleUtils'

interface FormValues {
  name: string
  assignTo?: string
  duration?: string
  tasks: {
    value: string
  }[]
  softwareType?: SoftwareType | null
  programType?: ProgramType | null
  priority?: number
  galleryAssetComponentsId: UUID
}

export const NewModuleModal = () => {
  const [isVisible, setIsVisible] = useState(false)
  const { publishToLocal } = usePublishGlobalNotification()

  const { commit, isInFlight } = useGwtMutation<NewModuleModalMutation>(graphql`
    mutation NewModuleModalMutation(
      $dueAt: Datetime
      $tasks: [TaskModuleFlowIdFkeyTaskCreateInput!]
      $name: String!
      $reviewers: [FlowReviewerFlowIdFkeyFlowReviewerCreateInput!]
      $softwareType: SoftwareType
      $programType: ProgramType
      $durationSeconds: Int!
    ) {
      createModule(
        input: {
          module: {
            flowToFlowId: {
              create: {
                name: $name
                durationSeconds: $durationSeconds
                dueAt: $dueAt
                flowType: BLUEPRINT
                flowReviewersUsingRowId: { create: $reviewers }
              }
            }
            softwareType: $softwareType
            programType: $programType
            tasksUsingFlowId: { create: $tasks }
          }
          initializeGalleryComponentsAsset: true
        }
      ) {
        module {
          galleryAssetComponentsId
          flowId
        }
      }
    }
  `)

  const [createdAssetId, setCreatedAssetId] = useState<UUID | undefined>()
  const [initiateFileUpload, setInitiateFileUpload] = useState(false)
  const [clearFiles, setClearFiles] = useState(false)
  const makeToast = ToastProvider.useToaster()
  const user = useGwtUser()

  const formMethods = useForm<FormValues>({
    defaultValues: {
      name: '',
      duration: '',
      softwareType: undefined,
      programType: undefined,
      galleryAssetComponentsId: undefined,
      assignTo: 'unassigned',
      tasks: [
        {
          value: '',
        },
      ],
    },
  })

  const { control, handleSubmit, reset } = formMethods

  const {
    fields: taskFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'tasks',
  })

  const clearModal = () => {
    setIsVisible(false)
    reset()
    setClearFiles(true)
  }

  const getProgramSelections = () => {
    const init = [{ value: '', label: '', disabled: false }]
    user.programs.forEach((program) => {
      init.push({
        value: program,
        label: getModuleProgramTypeText(program),
        disabled: false,
      })
    })
    return init
  }

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    let durationOfTemplate = data.duration
      ? parseInt(data.duration) * 604800
      : 0
    const mutation: NewModuleModalMutation$variables = {
      durationSeconds: durationOfTemplate,
      dueAt: data.duration
        ? dayjs.utc(Date()).add(parseInt(data.duration), 'week').toDate()
        : undefined,
      softwareType: data.softwareType,
      programType: data.programType,
      name: data.name,
      reviewers:
        data.assignTo !== 'unassigned'
          ? [{ reviewerId: data.assignTo }]
          : undefined,
      tasks: data.tasks
        .filter((f) => f.value)
        .map((field, index) => {
          return {
            flowToFlowId: { create: { name: field.value } },
            priority: index,
          }
        }),
    }
    commit?.(
      mutation,
      ({ createModule }) => {
        if (createModule) {
          setCreatedAssetId(createModule.module?.galleryAssetComponentsId)
        }
        setInitiateFileUpload(true)
        clearModal()
        publishToLocal('TEMPLATE_CREATE_NOTIFICATION')
      },
      () => {
        makeToast({
          type: 'error',
          heading: 'Module creation failed',
          message: 'Module was unsuccessfully created.',
        })
      },
    )
  }

  return (
    <Fragment>
      <Button type="secondary" onClick={() => setIsVisible(true)}>
        Add new module
      </Button>
      <Modal
        headingText="Add new module"
        isVisible={isVisible}
        onRefuse={clearModal}
        className="new-module-modal"
        onSubmit={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
      >
        <FormProvider {...formMethods}>
          <Form className="hc-pa-expanded" onSubmit={handleSubmit(onSubmit)}>
            <Grid.Container>
              <Grid.Item xs={12}>
                <ControlledFormField
                  required
                  label="Name of template"
                  name="name"
                />
              </Grid.Item>
              <Grid.Item xs={6}>
                <ControlledUserSelect
                  name="assignTo"
                  label="Assigned reviewer"
                  id="assign_to_select"
                  defaultValue={getControlledUserSelectDefaultUnnassigned()}
                  defaultUnassigned={getControlledUserSelectDefaultUnnassigned}
                  userTypes={{ in: ['ADMIN', 'REVIEWER'] }}
                />
              </Grid.Item>
              <Grid.Item xs={6}>
                <ControlledFormField
                  type="select"
                  label="Duration"
                  name="duration"
                  options={[
                    {
                      disabled: false,
                      label: '',
                      value: '',
                    },
                    {
                      disabled: false,
                      label: '1 week (7 calendar days)',
                      value: '1',
                    },
                    {
                      disabled: false,
                      label: '2 weeks (14 calendar days)',
                      value: '2',
                    },
                    {
                      disabled: false,
                      label: '3 weeks (21 calendar days)',
                      value: '3',
                    },
                    {
                      disabled: false,
                      label: '4 weeks (28 calendar days)',
                      value: '4',
                    },
                    {
                      disabled: false,
                      label: '5 weeks (35 calendar days)',
                      value: '5',
                    },
                    {
                      disabled: false,
                      label: '6 weeks (42 calendar days)',
                      value: '6',
                    },
                  ]}
                />
              </Grid.Item>
              <Grid.Item xs={6}>
                <ControlledFormField
                  required
                  type="select"
                  label="Software"
                  options={[
                    {
                      disabled: false,
                      label: '',
                      value: '',
                    },
                    {
                      disabled: false,
                      label: 'Browzwear',
                      value: 'BROWZWEAR',
                    },
                    {
                      disabled: false,
                      label: 'Clo',
                      value: 'CLO',
                    },
                  ]}
                  name="softwareType"
                />
              </Grid.Item>
              <Grid.Item xs={6} className="hc-pb-sm">
                <ControlledFormField
                  type="select"
                  label="Program"
                  required
                  requiredMessage="Please enter a program type."
                  options={getProgramSelections()}
                  name="programType"
                />
              </Grid.Item>

              <Grid.Item xs={12}>
                {taskFields.map((task: any, index) => (
                  <div key={task.id}>
                    <span
                      className={index === 0 ? 'displayTask' : 'displayNone'}
                    >
                      Tasks
                    </span>
                    <Grid.Container
                      justify="space-between"
                      align="center"
                      className="hc-ph-normal"
                    >
                      <Grid.Item xs={1}>
                        <span>{`${index + 1}.`}</span>
                      </Grid.Item>
                      <Grid.Item xs={10}>
                        <ControlledFormField
                          className="hc-mt-md"
                          type="text"
                          name={`tasks[${index}].value`}
                          defaultValue={task.value}
                        />
                      </Grid.Item>
                      <Grid.Item
                        xs={1}
                        className="remove-task-button hc-pa-none"
                      >
                        <Button
                          type="secondary"
                          iconOnly
                          aria-label="remove-task"
                          onClick={() => remove(index)}
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </Button>
                      </Grid.Item>
                    </Grid.Container>
                  </div>
                ))}
              </Grid.Item>

              <Grid.Item xs={3}>
                <Button
                  type="secondary"
                  onClick={() => append({ value: '' })}
                  className="add-task-button"
                >
                  <FontAwesomeIcon icon={faPlusCircle} />
                  &nbsp; Add task
                </Button>
              </Grid.Item>

              <Grid.Item xs={12}>
                <Input.Label className="hc-fs-md hc-mb-normal">
                  Components
                </Input.Label>
                <FileUploadTable
                  assetId={createdAssetId}
                  initiateUpload={initiateFileUpload}
                  showButtonsAfterInitiate
                  onAllFilesUploaded={() =>
                    makeToast({
                      type: 'success',
                      heading: 'Template created',
                      message: 'Template was successfully created.',
                    })
                  }
                  shouldClearFiles={clearFiles}
                  setShouldClearFiles={setClearFiles}
                />
              </Grid.Item>

              <Grid.Item xs={12} className="submit-button-row">
                <Button
                  type="secondary"
                  className="hc-mr-normal"
                  onClick={clearModal}
                >
                  Cancel
                </Button>
                {isInFlight ? (
                  <Button type="submit">
                    <Spinner size="inline" />
                  </Button>
                ) : (
                  <Button type="submit">Create</Button>
                )}
              </Grid.Item>
            </Grid.Container>
          </Form>
        </FormProvider>
      </Modal>
    </Fragment>
  )
}
