import { Button, Modal } from '@enterprise-ui/canvas-ui-react'
import { graphql, useFragment } from 'react-relay'
import { useState } from 'react'
import { GroupTemplateFinalization } from '../GroupTemplateFinalization/GroupTemplateFinalization'
import { GroupTemplatePreviewButton_Fragment$key } from './__generated__/GroupTemplatePreviewButton_Fragment.graphql'

interface GroupTemplatesPreviewButtonProps {
  moduleGroupRef: GroupTemplatePreviewButton_Fragment$key
}

export const GroupTemplatePreviewButton = ({
  moduleGroupRef,
}: GroupTemplatesPreviewButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const groupData = useFragment(
    graphql`
      fragment GroupTemplatePreviewButton_Fragment on ModuleGroup {
        reviewers {
          nodes {
            fullName
          }
        }
        moduleGroupLinksByModuleGroupFlowId {
          nodes {
            moduleFlow {
              softwareType
              programType
              flow {
                updatedAt
                name
                durationSeconds
              }
            }
          }
        }
        flow {
          name
          rowId
        }
      }
    `,
    moduleGroupRef,
  )

  const modules = groupData.moduleGroupLinksByModuleGroupFlowId.nodes.map(
    ({ moduleFlow }) => {
      return {
        name: moduleFlow?.flow?.name,
        softwareType: moduleFlow?.softwareType,
        programType: moduleFlow?.programType,
        updatedAt: moduleFlow?.flow?.updatedAt,
        reviewers: groupData.reviewers?.nodes?.map((v) => v.fullName),
        durationInSeconds: moduleFlow?.flow?.durationSeconds,
      }
    },
  )

  return (
    <>
      <Button
        className="hc-fs-xs"
        size="dense"
        onClick={setIsModalOpen}
        type="ghost"
      >
        Preview
      </Button>
      {isModalOpen && (
        <Modal
          headingText="Preview group template"
          isVisible={isModalOpen}
          onRefuse={() => setIsModalOpen(false)}
        >
          <GroupTemplateFinalization
            modules={modules}
            name={groupData.flow?.name}
            isPreview
          />
        </Modal>
      )}
    </>
  )
}
