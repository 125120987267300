// Hook to handle mutations with booleans returned for error / complete
// This hook also limits the number of COMMITS to one at a time

import { GraphQLTaggedNode, useMutation } from 'react-relay'
import {
  Disposable,
  IEnvironment,
  MutationConfig,
  MutationParameters,
  VariablesOf,
} from 'relay-runtime'

export interface GwtMutationResponse<TMutation extends MutationParameters> {
  commit: (
    variables: VariablesOf<TMutation>,
    onComplete?: (response: TMutation['response']) => void,
    onError?: () => void,
  ) => void
  isInFlight: boolean
}

export default function useGwtMutation<TMutation extends MutationParameters>(
  mutation: GraphQLTaggedNode,
  commitMutationFn?: (
    environment: IEnvironment,
    config: MutationConfig<TMutation>,
  ) => Disposable,
): GwtMutationResponse<TMutation> {
  const [_commit, isInFlight] = useMutation<TMutation>(
    mutation,
    commitMutationFn,
  )

  // commit function resets status and then commits
  const commit = (
    variables: VariablesOf<TMutation>,
    onCompleted?: (response: TMutation['response']) => void,
    onError?: () => void,
  ) => {
    if (isInFlight) {
      return
    }

    return _commit({
      variables,
      onError,
      onCompleted,
    })
  }

  return {
    commit,
    isInFlight,
  }
}
