import { Grid, Layout, Card, Heading } from '@enterprise-ui/canvas-ui-react'
import './ApiErrorPage.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFrown } from '@fortawesome/free-regular-svg-icons'

interface Error {
  name: string
  message: string
  stack?: string
}
interface ErrorInfo {
  componentStack: string
}

interface ErrorProps {
  error?: Error
  info?: ErrorInfo
}

export const ApiErrorPage = (props: ErrorProps) => (
  <>
    <Layout.Body>
      <Card className="error-card" justify="center" align="center">
        <div className="hc-pa-dense">
          <Grid.Container>
            <Grid.Item sm={12} align="center">
              <FontAwesomeIcon icon={faFrown} size="10x" />
              <Heading size={1}>Oh no!</Heading>
              <Heading size={3}>Our API server is down right now.</Heading>
              <Heading size={5}>
                We are very sorry for the inconvenience. <br />
                Please try refreshing the page and if that doesn't work, <br />
                we will have it up and running again as soon as possible. <br />
                <br />
                Thank you for your patience!
                <br /> <br />
                If you're experiencing this error often, please send us a report
                <br />
                using the feedback tool in the app & check our #assent_support
                slack channel! <br />
                <p className="hc-mt-lg">Error: {props.error?.message}</p>
              </Heading>
            </Grid.Item>
          </Grid.Container>
        </div>
      </Card>
    </Layout.Body>
  </>
)
