import { Chip, ChipProps } from '@enterprise-ui/canvas-ui-react'
import { graphql, useFragment } from 'react-relay'
import { getAssignmentStatusText } from '../../util/flowUtils'
import classNames from 'classnames'
import {
  StatusChip_flow$data,
  StatusChip_flow$key,
} from './__generated__/StatusChip_flow.graphql'
import styled from 'styled-components'

interface StatusChipProps extends ChipProps {
  flowRef: StatusChip_flow$key | null | undefined
}

const getChip = (flow: StatusChip_flow$data) => {
  if (!flow.dueAt) {
    return styled(Chip)`
      color: var(--canvas-font-color--default);
      border-color: var(--canvas-border-color--default);
    `
  }

  if (flow.assignmentStatus === 'READY_FOR_REVIEW') {
    return styled(Chip)`
      color: var(--canvas-font-color--alert);
      border-color: var(--canvas-border-color--alert);
    `
  }

  if (flow.assignmentStatus === 'ACCEPTED') {
    return styled(Chip)`
      color: var(--canvas-font-color--success);
      border-color: var(--canvas-border-color--success);
    `
  }

  if (flow.assignmentStatus === 'REJECTED') {
    return styled(Chip)`
      color: var(--canvas-font-color--error);
      border-color: var(--canvas-border-color--error);
    `
  }

  if (flow.assignmentDueAtStatus === 'OVERDUE') {
    return styled(Chip)`
      color: var(--canvas-font-color--error);
      border-color: var(--canvas-border-color--error);
    `
  }

  if (flow.assignmentDueAtStatus === 'DUE_SOON') {
    return styled(Chip)`
      color: var(--canvas-font-color--error);
      border-color: var(--canvas-border-color--error);
    `
  }

  // default return is normal status text
  return styled(Chip)`
    color: var(--canvas-font-color--default);
    border-color: var(--canvas-border-color--default);
  `
}

export const StatusChip = ({
  flowRef,
  className,
  ...props
}: StatusChipProps) => {
  const data = useFragment(
    graphql`
      fragment StatusChip_flow on Flow {
        dueAt
        assignmentStatus
        assignmentDueAtStatus
      }
    `,
    flowRef || null,
  )

  if (!data) {
    return <></>
  }

  const StyledChip = getChip(data)

  return (
    <StyledChip className={classNames(className)} size="dense" {...props}>
      {getAssignmentStatusText(
        data.assignmentStatus,
        data.assignmentDueAtStatus,
      )}
    </StyledChip>
  )
}
