import {
  Button,
  Dropdown,
  Grid,
  Layout,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { useAuth } from '@praxis/component-auth'
import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import './Header.scss'
import { useGwtMutation, useGwtUser } from '../../hooks'
import { useEnv } from '@praxis/component-runtime-env'
import headerlogo from '../../images/Assent-Logo-White.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { graphql } from 'relay-runtime'
import {
  GwtUserPatch,
  HeaderMutation,
  HeaderMutation$variables,
  UpdateGwtUserInput,
  UserType,
  UserStatus,
} from './__generated__/HeaderMutation.graphql'
import { GwtEnv } from '../../types'
import { ProtectedComponent } from '../../GenericComponents/ProtectedComponent'
import { faQuestion, faUser } from '@fortawesome/free-solid-svg-icons'
import { useAtomValue, useSetAtom } from 'jotai'
import {
  feedbackAtom,
  feedbackCurrentAtom,
  feedbackLastSeenAtom,
  helpAtom,
} from '../../state/atoms'
import { FeedbackModal } from '../Feedback/FeedbackModal'
import { HelpModal } from '../Help/HelpModal'
import { difference } from 'lodash'

export const Header = () => {
  const { logout } = useAuth()
  const user = useGwtUser()
  const env = useEnv<GwtEnv>()
  const navigate = useNavigate()
  const setFeedback = useSetAtom(feedbackAtom)
  const setHelp = useSetAtom(helpAtom)

  const currentFeedback = useAtomValue(feedbackCurrentAtom)
  const seenFeedback = useAtomValue(feedbackLastSeenAtom)
  const unseenCount = difference(currentFeedback, seenFeedback).length

  const { commit } = useGwtMutation<HeaderMutation>(graphql`
    mutation HeaderMutation($input: UpdateGwtUserInput!) {
      updateGwtUser(input: $input) {
        gwtUser {
          userType
          status
        }
      }
    }
  `)

  const updateMyUser = (userType: UserType, status: UserStatus = 'ACTIVE') => {
    if (user.userType === userType) {
      return
    }

    const mutation = {} as HeaderMutation$variables
    const variables = {} as UpdateGwtUserInput
    variables.rowId = user.rowId
    variables.patch = {} as GwtUserPatch
    variables.patch.userType = userType
    variables.patch.status = status

    mutation.input = variables

    commit(mutation, () => {
      window.location.href = '/'
    })
  }

  return (
    <>
      <Layout.Header className="header-style">
        <Grid.Container className="hc-pl-lg">
          <FeedbackModal />
          <HelpModal />
          <Grid.Item md={12}>
            <Grid.Container direction="row" align="center" className="hc-pv-sm">
              <Link to="/">
                <img src={headerlogo} alt="" className="logo" />
              </Link>
              <Grid.Item
                md={8}
                className="hc-mb-min"
                data-testid="admin-reviewer-nav"
              >
                <ProtectedComponent allowed={['REVIEWER', 'ADMIN']}>
                  <Link
                    to="/profiles"
                    className="hc-fs-md hc-pl-2x hc-clr-contrast-weak"
                    data-testid="Profiles-Link"
                  >
                    Profiles
                  </Link>
                </ProtectedComponent>
                <ProtectedComponent allowed={['REVIEWER', 'ADMIN']}>
                  <Link
                    to="/assignments"
                    className="hc-fs-md hc-pl-2x hc-clr-contrast-weak"
                    data-testid="Assignments-Link"
                  >
                    Assignments
                  </Link>
                </ProtectedComponent>
                <ProtectedComponent allowed={['REVIEWER', 'ADMIN']}>
                  <Link
                    to="/templates-table"
                    className="hc-fs-md hc-pl-2x hc-clr-contrast-weak"
                    data-testid="Templates-Link"
                  >
                    Templates
                  </Link>
                </ProtectedComponent>
                <ProtectedComponent allowed={['ADMIN']}>
                  <Link
                    to="/feedback"
                    className="hc-fs-md hc-pl-2x hc-clr-contrast-weak"
                    data-testid="Feedback-Link"
                  >
                    Feedback
                  </Link>
                  {!!unseenCount && (
                    <div
                      className="comment-notification-badge hc-ml-min"
                      data-testid="Notification-Badge"
                    >
                      {unseenCount}
                    </div>
                  )}
                </ProtectedComponent>
                <ProtectedComponent allowed={['ADMIN']}>
                  <Link
                    to="/insights-dashboard"
                    className="hc-fs-md hc-pv-none hc-pl-2x hc-clr-contrast-weak"
                    data-testid="Insights-Link"
                  >
                    Insights
                  </Link>
                </ProtectedComponent>
              </Grid.Item>
            </Grid.Container>
          </Grid.Item>
        </Grid.Container>
      </Layout.Header>
      <Layout.GlobalActions>
        <Grid.Container
          justify="flex-end"
          align="center"
          className="header-name"
        >
          <Grid.Item className="hc-mt-sm" justify="center">
            <div
              className="hc-clr-contrast-weak"
              style={{ fontWeight: 'bold' }}
            >
              {user.fullName}
            </div>
            <div className="hc-clr-contrast-weak">{user.companyName}</div>
          </Grid.Item>
          <Grid.Item className="hc-mt-sm hc-mr-sm" md={1}>
            <Dropdown size="dense" location="bottom-right">
              <Tooltip content="Support" location="bottom">
                <Button
                  type="destructive"
                  aria-label="support"
                  iconOnly
                  size="dense"
                  className="hc-mr-dense"
                  onClick={() => {}}
                >
                  <FontAwesomeIcon icon={faQuestion} />
                </Button>
              </Tooltip>
              <Dropdown.Menu>
                <Dropdown.MenuItem
                  onClick={() => setFeedback({ visible: true, type: 'ISSUE' })}
                >
                  Report issue
                </Dropdown.MenuItem>
                <Dropdown.MenuItem
                  onClick={() =>
                    setFeedback({ visible: true, type: 'FEEDBACK' })
                  }
                >
                  Give feedback
                </Dropdown.MenuItem>
                <Dropdown.MenuItem onClick={() => setHelp(true)}>
                  Contact us
                </Dropdown.MenuItem>
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Item>
          <Grid.Item className="hc-mt-sm" md={1}>
            <Dropdown size="dense" location="bottom-right" className="hc-mr-lg">
              <Tooltip content="My Account" location="bottom-right">
                <Button
                  aria-label="my-account"
                  iconOnly
                  data-testid="UserMenu"
                  size="dense"
                  type="destructive"
                >
                  <FontAwesomeIcon icon={faUser} />
                </Button>
              </Tooltip>
              <Dropdown.Menu>
                <Dropdown.MenuItem
                  onClick={() => navigate('/user-preferences')}
                >
                  Preferences
                </Dropdown.MenuItem>
                <Dropdown.MenuItem onClick={logout}>Log out</Dropdown.MenuItem>
                {env.environment !== 'prod' && (
                  <>
                    <Dropdown.MenuItem onClick={() => updateMyUser('ADMIN')}>
                      <div>
                        Admin
                        {user.userType === 'ADMIN' && (
                          <FontAwesomeIcon
                            className="hc-ml-sm"
                            icon="check"
                            color="green"
                            size="1x"
                          />
                        )}
                      </div>
                    </Dropdown.MenuItem>
                    <Dropdown.MenuItem onClick={() => updateMyUser('REVIEWER')}>
                      Reviewer
                      {user.userType === 'REVIEWER' && (
                        <FontAwesomeIcon
                          className="hc-ml-sm"
                          icon="check"
                          color="green"
                          size="1x"
                        />
                      )}
                    </Dropdown.MenuItem>
                    <Dropdown.MenuItem
                      onClick={() => updateMyUser('EXTERNAL_USER')}
                    >
                      External User
                      {user.userType === 'EXTERNAL_USER' &&
                        user.status === 'ACTIVE' && (
                          <FontAwesomeIcon
                            className="hc-ml-sm"
                            icon="check"
                            color="green"
                            size="1x"
                          />
                        )}
                    </Dropdown.MenuItem>
                    <Dropdown.MenuItem
                      onClick={() => updateMyUser('EXTERNAL_USER', 'SUSPENDED')}
                    >
                      External User (Suspended)
                      {user.userType === 'EXTERNAL_USER' &&
                        user.status === 'SUSPENDED' && (
                          <FontAwesomeIcon
                            className="hc-ml-sm"
                            icon="check"
                            color="green"
                            size="1x"
                          />
                        )}
                    </Dropdown.MenuItem>
                  </>
                )}
              </Dropdown.Menu>
            </Dropdown>
          </Grid.Item>
        </Grid.Container>
      </Layout.GlobalActions>
    </>
  )
}
