import { Routes, Route, useLocation, Navigate } from 'react-router-dom'
import { ErrorBoundary } from '@praxis/component-logging'
import { ErrorContent } from './ErrorContent'
import { ReviewerDashboard } from '../views/Reviewer/ReviewerDashboard'
import { AdminDashboard } from '../views/Admin/AdminDashboard'
import { AssigneeDashboard } from '../views/Assignee/Dashboard/AssigneeDashboard'
import { SuspenseRingLoader } from '../loadingComponents/SuspenseRingLoader'
import { TemplateCreatePage } from '../views/Admin/TemplateCreatePage'
import { useGwtUser } from '../hooks'
import { TemplateEditPage } from '../views/Admin/TemplateEditPage'
import { ModuleOverview } from '../views/Modules/ModuleOverview'
import { ReviewerOverview } from '../views/Reviewer/Overview/ReviewerOverview'
import { InsightsDashboard } from '../views/InsightsDashboard/InsightsDashboard'
import { AssignmentsTable } from '../views/AssignmentsTable/AssignmentsTable'
import { UserPreferences } from '../views/Preferences/UserPreferences'
import { TemplateTables } from '../views/TemplateTables/TemplateTables'
import { UserProfilePage } from '../views/Users/UserProfilePage/UserProfilePage'
import { CreateProfilePage } from '../views/Users/CreateProfilePage/CreateProfilePage'
import { ProfileTables } from '../views/ProfileTables/ProfileTables'
import { CompanyProfilePage } from '../views/Companies/CompanyProfilePage/CompanyProfilePage'
import { CompanyCreatePage } from '../views/Companies/CompanyCreatePage/CompanyCreatePage'
import { ProtectedComponent } from '../GenericComponents/ProtectedComponent'
import { FeedbackPage } from '../views/Feedback/FeedbackPage/FeedbackPage'
import { TemplateDuplicatePage } from '../views/Admin/TemplateDuplicatePage'
import { useAnalytics } from '@praxis/component-analytics'
import { useEffect } from 'react'
import { ModuleEditPage } from '../views/Reviewer/Overview/ModuleEditPage'
import { CreateGroupTemplate } from '../views/GroupTemplates/CreateGroupTemplate'

export const MainRouter = () => {
  const location = useLocation()

  const my_user = useGwtUser()

  let home_dashboard = 'assignee-dashboard'
  if (my_user.userType === 'ADMIN') {
    home_dashboard = 'admin-dashboard'
  }
  if (my_user.userType === 'REVIEWER') {
    home_dashboard = 'reviewer-dashboard'
  }

  const { trackView } = useAnalytics()

  useEffect(() => {
    trackView(location.pathname)
  }, [location.pathname, trackView])

  return (
    <ErrorBoundary key={location.pathname} FallbackComponent={ErrorContent}>
      <Routes>
        <Route path="/" element={<Navigate to={home_dashboard} replace />} />
        <Route
          path="/reviewer-dashboard"
          element={
            <ProtectedComponent allowed={['REVIEWER']} shouldRedirect>
              <ReviewerDashboard />{' '}
            </ProtectedComponent>
          }
        />
        <Route
          path="/admin-dashboard"
          element={
            <ProtectedComponent allowed={['ADMIN']} shouldRedirect>
              <AdminDashboard />
            </ProtectedComponent>
          }
        />
        <Route
          path="/assignee-dashboard"
          element={
            <ProtectedComponent allowed={['EXTERNAL_USER']} shouldRedirect>
              <AssigneeDashboard />
            </ProtectedComponent>
          }
        />
        <Route
          path="/insights-dashboard"
          element={
            <ProtectedComponent allowed={['ADMIN', 'REVIEWER']} shouldRedirect>
              <InsightsDashboard />
            </ProtectedComponent>
          }
        />
        <Route path="/user-preferences" element={<UserPreferences />} />
        <Route
          path="/assignments"
          element={
            <ProtectedComponent allowed={['ADMIN', 'REVIEWER']} shouldRedirect>
              <AssignmentsTable />
            </ProtectedComponent>
          }
        />
        <Route
          path="/templates-table"
          element={
            <ProtectedComponent allowed={['ADMIN', 'REVIEWER']} shouldRedirect>
              <TemplateTables />
            </ProtectedComponent>
          }
        />
        <Route
          path="/profiles"
          element={
            <ProtectedComponent allowed={['ADMIN', 'REVIEWER']} shouldRedirect>
              <ProfileTables />
            </ProtectedComponent>
          }
        />
        <Route
          path="/feedback"
          element={
            <ProtectedComponent allowed={['ADMIN']} shouldRedirect>
              <FeedbackPage />
            </ProtectedComponent>
          }
        />
        <Route
          path="/user/create"
          element={
            <ProtectedComponent allowed={['ADMIN', 'REVIEWER']} shouldRedirect>
              <CreateProfilePage />
            </ProtectedComponent>
          }
        />
        <Route
          path="/user/edit/:userId"
          element={
            <ProtectedComponent allowed={['ADMIN']} shouldRedirect>
              <CreateProfilePage />
            </ProtectedComponent>
          }
        />
        <Route
          path="/company/create"
          element={
            <ProtectedComponent allowed={['ADMIN', 'REVIEWER']} shouldRedirect>
              <CompanyCreatePage />
            </ProtectedComponent>
          }
        />
        <Route
          path="/company/edit/:userId"
          element={
            <ProtectedComponent allowed={['ADMIN', 'REVIEWER']} shouldRedirect>
              <CompanyCreatePage />
            </ProtectedComponent>
          }
        />
        <Route
          path="/users/:userId"
          element={
            <SuspenseRingLoader size={150} speedMultiplier={3}>
              <UserProfilePage />
            </SuspenseRingLoader>
          }
        />
        <Route
          path="/companies/:companyId"
          element={
            <ProtectedComponent allowed={['ADMIN', 'REVIEWER']} shouldRedirect>
              <SuspenseRingLoader size={150} speedMultiplier={3}>
                <CompanyProfilePage />
              </SuspenseRingLoader>
            </ProtectedComponent>
          }
        />
        <Route
          path="/reviewer/modules/:moduleId"
          element={
            <ProtectedComponent allowed={['ADMIN', 'REVIEWER']} shouldRedirect>
              <SuspenseRingLoader size={150} speedMultiplier={3}>
                <ReviewerOverview />
              </SuspenseRingLoader>
            </ProtectedComponent>
          }
        />
        <Route
          path="/assignee/modules/:moduleId"
          element={
            <ProtectedComponent
              allowed={['EXTERNAL_USER', 'REVIEWER', 'ADMIN']}
              shouldRedirect
            >
              <SuspenseRingLoader size={150} speedMultiplier={3}>
                <ModuleOverview />
              </SuspenseRingLoader>
            </ProtectedComponent>
          }
        />
        <Route
          path="/template/create"
          element={
            <ProtectedComponent allowed={['ADMIN', 'REVIEWER']} shouldRedirect>
              <TemplateCreatePage />
            </ProtectedComponent>
          }
        />
        <Route
          path="/template/edit"
          element={
            <ProtectedComponent allowed={['ADMIN', 'REVIEWER']} shouldRedirect>
              <TemplateEditPage />
            </ProtectedComponent>
          }
        />
        <Route
          path="/template/duplicate"
          element={
            <ProtectedComponent allowed={['ADMIN']} shouldRedirect>
              <TemplateDuplicatePage />
            </ProtectedComponent>
          }
        />
        <Route
          path="/template/groups/create"
          element={
            <ProtectedComponent allowed={['ADMIN', 'REVIEWER']} shouldRedirect>
              <CreateGroupTemplate />
            </ProtectedComponent>
          }
        />
        <Route
          path="/reviewer/modules/edit"
          element={
            <ProtectedComponent allowed={['ADMIN']} shouldRedirect>
              <ModuleEditPage />
            </ProtectedComponent>
          }
        />
      </Routes>
    </ErrorBoundary>
  )
}
