/**
 * @generated SignedSource<<e2e617c2d06dfcecf6c64a1df0124ba2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentOptions_comment$data = {
  readonly commentText: string;
  readonly gwtUserId: UUID;
  readonly responseTo: UUID | null;
  readonly rowId: UUID;
  readonly " $fragmentType": "CommentOptions_comment";
};
export type CommentOptions_comment$key = {
  readonly " $data"?: CommentOptions_comment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentOptions_comment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentOptions_comment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gwtUserId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commentText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "responseTo",
      "storageKey": null
    }
  ],
  "type": "Comment",
  "abstractKey": null
};

(node as any).hash = "96eb586dfbf62a50d0a3294f68259400";

export default node;
