import { useState } from 'react'
import {
  Button,
  Form,
  Grid,
  Modal,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { graphql, useFragment } from 'react-relay'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useGwtMutation } from '../../hooks'
import {
  ReassignReviewerPopupMutation,
  ReassignReviewerPopupMutation$variables,
} from './__generated__/ReassignReviewerPopupMutation.graphql'
import { useAtom } from 'jotai'
import { getNotificationInputObject } from '../../util/relayUtils'
import { globalNotificationAtom } from '../../state/atoms'
import {
  ReassignReviewerPopupUnassignMutation,
  ReassignReviewerPopupUnassignMutation$variables,
} from './__generated__/ReassignReviewerPopupUnassignMutation.graphql'
import {
  ReassignReviewerPopupDeleteMutation,
  ReassignReviewerPopupDeleteMutation$variables,
} from './__generated__/ReassignReviewerPopupDeleteMutation.graphql'
import { ReassignReviewerPopupFragment_ReviewerAvatar$key } from './__generated__/ReassignReviewerPopupFragment_ReviewerAvatar.graphql'
import { ReassignReviewerPopupFragment_AssignToAvatar$key } from './__generated__/ReassignReviewerPopupFragment_AssignToAvatar.graphql'
import { ControlledUserSelect } from '../../controlledComponents/ControlledUserSelect'
import './Admin.scss'
import {
  getControlledUserSelectDefaultUnnassigned,
  getControlledUserSelectObjectOrDefault,
} from '../../util/userUtils'
interface ReassignReviewerPopupProps {
  moduleFlowId: string
  isUnassigned?: boolean
  spanClassName?: string
  ReviewerUserRef:
    | ReassignReviewerPopupFragment_ReviewerAvatar$key
    | null
    | undefined
  AssignToUserRef:
    | ReassignReviewerPopupFragment_AssignToAvatar$key
    | null
    | undefined
}

export const ReassignReviewerPopup = ({
  moduleFlowId,
  isUnassigned = false,
  spanClassName = undefined,
  ReviewerUserRef,
  AssignToUserRef,
}: ReassignReviewerPopupProps) => {
  type Inputs = {
    add: string
  }

  const oldReviewerUserData = useFragment(
    graphql`
      fragment ReassignReviewerPopupFragment_ReviewerAvatar on GwtUser {
        firstName
        lastName
        rowId
        company {
          name
        }
      }
    `,
    ReviewerUserRef || null,
  )

  const [isModalOpen, setIsModalOpen] = useState(false)
  const formMethods = useForm({
    defaultValues: {
      assignTo: isUnassigned ? 'unassigned' : oldReviewerUserData?.rowId,
    },
  })
  const {
    register,
    setError,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = formMethods

  const assignToUserData = useFragment(
    graphql`
      fragment ReassignReviewerPopupFragment_AssignToAvatar on GwtUser {
        firstName
        lastName
        rowId
      }
    `,
    AssignToUserRef || null,
  )

  const clearModal = () => {
    setIsModalOpen(false)
  }

  const validate = (): boolean => {
    let validated = true
    if (!assignTo) {
      setError('assignTo', { message: 'Please select an assignee' })
      validated = false
    }
    return validated
  }

  const assignTo = watch('assignTo', '')
  const createReviewer = CreateReviewerAssignmentMutation()
  const updateReviewer = UpdateReviewerAssignmentMutation()
  const deleteReviewer = DeleteReviewerAssignmentMutation()

  const onSubmit: SubmitHandler<Inputs> = () => {
    if (!validate()) {
      return
    }

    if (isModalOpen && !isUnassigned) {
      if (assignTo === 'unassigned') {
        deleteReviewer(oldReviewerUserData?.rowId, moduleFlowId)
      } else {
        //Run Update Mutation
        updateReviewer(assignTo, oldReviewerUserData?.rowId, moduleFlowId)
      }
      setIsModalOpen(false)
      setValue('assignTo', assignTo)
    }
    if (isModalOpen && isUnassigned) {
      if (assignTo === 'unassigned') {
        return
      }
      //Run Create Mutation
      createReviewer(assignTo, moduleFlowId)
      setValue('assignTo', assignTo)
    }
  }

  const handleReviewerClick = (event) => {
    event.preventDefault()
    setIsModalOpen(true)
  }

  //If this assignment has a current reviewer show it
  if (!isModalOpen && !isUnassigned) {
    return (
      <div className="reviewer-box" onClick={handleReviewerClick}>
        <span className="narrow-text"> Reviewer </span>
        <span
          className={spanClassName || 'hc-ml-xs'}
          style={{ flexWrap: 'wrap' }}
        >
          {oldReviewerUserData?.firstName} {oldReviewerUserData?.lastName}
        </span>
      </div>
    )
  }

  //If this assignment does not have a reviewer, show as unassigned
  if (!isModalOpen && isUnassigned) {
    return (
      <div className="reviewer-box" onClick={handleReviewerClick}>
        <span className="narrow-text"> Reviewer </span>
        <span className={spanClassName || 'hc-ml-xs'}>Unassigned</span>
      </div>
    )
  }

  return (
    <>
      {isModalOpen && (
        <Modal
          headingText={`Assign reviewer for ${assignToUserData?.firstName} ${assignToUserData?.lastName}`}
          isVisible={isModalOpen}
          onRefuse={clearModal}
          onClick={(e) => e.stopPropagation()}
        >
          <FormProvider {...formMethods}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div className="hc-pa-normal">
                <Grid.Container spacing="none">
                  <Grid.Item xs={12} className="reassign-modal">
                    <ControlledUserSelect
                      error={!!errors.assignTo}
                      errorText={errors.assignTo?.message}
                      label="Assign to"
                      id="assign_to_select"
                      defaultValue={getControlledUserSelectObjectOrDefault(
                        oldReviewerUserData?.rowId,
                        oldReviewerUserData?.firstName,
                        oldReviewerUserData?.lastName,
                        oldReviewerUserData?.company?.name,
                      )}
                      defaultUnassigned={
                        getControlledUserSelectDefaultUnnassigned
                      }
                      {...register('assignTo')}
                      userTypes={{ in: ['ADMIN', 'REVIEWER'] }}
                    />
                  </Grid.Item>
                </Grid.Container>
              </div>
              <div className="hc-pt-dense hc-pb-normal hc-pr-normal">
                <Grid.Container spacing="dense" direction="row-reverse">
                  <Grid.Item>
                    <Button type="submit" disabled={!assignTo}>
                      Confirm
                    </Button>
                  </Grid.Item>
                  <Grid.Item>
                    <Button onClick={clearModal}>Cancel</Button>
                  </Grid.Item>
                </Grid.Container>
              </div>
            </Form>
          </FormProvider>
        </Modal>
      )}
    </>
  )
}

//Handles mutation for updating reviewer
const DeleteReviewerAssignmentMutation = () => {
  const [, publishNotification] = useAtom(globalNotificationAtom)
  const makeToast = ToastProvider.useToaster()

  const { commit } = useGwtMutation<ReassignReviewerPopupDeleteMutation>(
    graphql`
      mutation ReassignReviewerPopupDeleteMutation(
        $moduleFlowId: UUID!
        $oldReviewerId: UUID!
        $notificationInput: NotificationInput!
      ) {
        createNotification(input: { notification: $notificationInput }) {
          clientMutationId
        }
        deleteFlowReviewer(
          input: { flowId: $moduleFlowId, reviewerId: $oldReviewerId }
        ) {
          clientMutationId
        }
      }
    `,
  )

  return (
    oldReviewerId: string | undefined,
    moduleFlowId: string | undefined,
  ) => {
    const mutation = {} as ReassignReviewerPopupDeleteMutation$variables
    mutation.moduleFlowId = moduleFlowId
    mutation.oldReviewerId = oldReviewerId

    mutation.notificationInput = getNotificationInputObject(
      'ASSIGN_TEMPLATE_NOTIFICATION',
    )
    commit(
      mutation,
      () => {
        publishNotification({
          name: 'ASSIGN_TEMPLATE_NOTIFICATION',
          id: 'local',
          gwt_user_id: 'local',
          data: null,
        })
        makeToast({
          type: 'success',
          heading: 'Template assigned',
          message: 'Template was successfully assigned.',
        })
      },
      () => {
        makeToast({
          type: 'error',
          heading: 'Template assignment failed',
          message: 'Template was unsuccessfully assigned.',
        })
      },
    )
  }
}

//Handles mutation for updating reviewer
const UpdateReviewerAssignmentMutation = () => {
  const [, publishNotification] = useAtom(globalNotificationAtom)
  const makeToast = ToastProvider.useToaster()

  const { commit } = useGwtMutation<ReassignReviewerPopupMutation>(graphql`
    mutation ReassignReviewerPopupMutation(
      $moduleFlowId: UUID!
      $oldReviewerId: UUID!
      $newReviewerId: UUID!
      $notificationInput: NotificationInput!
    ) {
      createNotification(input: { notification: $notificationInput }) {
        clientMutationId
      }

      updateFlowReviewer(
        input: {
          patch: { reviewerId: $newReviewerId }
          flowId: $moduleFlowId
          reviewerId: $oldReviewerId
        }
      ) {
        clientMutationId
      }
    }
  `)

  return (
    newReviewerId: string | undefined,
    oldReviewerId: string | undefined,
    moduleFlowId: string | undefined,
  ) => {
    const mutation = {} as ReassignReviewerPopupMutation$variables
    mutation.moduleFlowId = moduleFlowId
    mutation.oldReviewerId = oldReviewerId
    mutation.newReviewerId = newReviewerId

    mutation.notificationInput = getNotificationInputObject(
      'ASSIGN_TEMPLATE_NOTIFICATION',
    )
    commit(
      mutation,
      () => {
        publishNotification({
          name: 'ASSIGN_TEMPLATE_NOTIFICATION',
          id: 'local',
          gwt_user_id: 'local',
          data: null,
        })
        makeToast({
          type: 'success',
          heading: 'Template assigned',
          message: 'Template was successfully assigned.',
        })
      },
      () => {
        makeToast({
          type: 'error',
          heading: 'Template assignment failed',
          message: 'Template was unsuccessfully assigned.',
        })
      },
    )
  }
}

//Handles mutation for creating new reviewer if assignment was previously unassigned
const CreateReviewerAssignmentMutation = () => {
  const [, publishNotification] = useAtom(globalNotificationAtom)
  const makeToast = ToastProvider.useToaster()

  const { commit } = useGwtMutation<ReassignReviewerPopupUnassignMutation>(
    graphql`
      mutation ReassignReviewerPopupUnassignMutation(
        $flowId: UUID!
        $reviewerId: UUID!
        $notificationInput: NotificationInput!
      ) {
        createNotification(input: { notification: $notificationInput }) {
          clientMutationId
        }
        createFlowReviewer(
          input: { flowReviewer: { flowId: $flowId, reviewerId: $reviewerId } }
        ) {
          clientMutationId
        }
      }
    `,
  )

  return (reviewerId: string | undefined, moduleFlowId: string | undefined) => {
    const mutation: ReassignReviewerPopupUnassignMutation$variables =
      {} as ReassignReviewerPopupUnassignMutation$variables
    mutation.reviewerId = reviewerId
    mutation.flowId = moduleFlowId
    mutation.notificationInput = getNotificationInputObject(
      'ASSIGN_TEMPLATE_NOTIFICATION',
    )
    commit(
      mutation,
      () => {
        publishNotification({
          name: 'ASSIGN_TEMPLATE_NOTIFICATION',
          id: 'local',
          gwt_user_id: 'local',
          data: null,
        })
        makeToast({
          type: 'success',
          heading: 'Template assigned',
          message: 'Template was successfully assigned.',
        })
      },
      () => {
        makeToast({
          type: 'error',
          heading: 'Template assignment failed',
          massage: 'Template was unsuccessfully assigned.',
        })
      },
    )
  }
}
