/**
 * @generated SignedSource<<6a2a2d0965313d65fc4e597e8b1c1ffd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssigneeDiscussionCardPaginationQuery$variables = {
  count: number;
  userId: UUID;
};
export type AssigneeDiscussionCardPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"AssigneeDiscussionCardPaginationFragment_flowComments">;
};
export type AssigneeDiscussionCardPaginationQuery = {
  response: AssigneeDiscussionCardPaginationQuery$data;
  variables: AssigneeDiscussionCardPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "equalTo",
                "variableName": "userId"
              }
            ],
            "kind": "ObjectValue",
            "name": "assignedToId"
          }
        ],
        "kind": "ObjectValue",
        "name": "flow"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "UNREAD_COMMENTS_CREATED_AT_DESC"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AssigneeDiscussionCardPaginationQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "userId",
            "variableName": "userId"
          }
        ],
        "kind": "FragmentSpread",
        "name": "AssigneeDiscussionCardPaginationFragment_flowComments"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AssigneeDiscussionCardPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "FlowCommentsConnection",
        "kind": "LinkedField",
        "name": "flowComments",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FlowCommentsEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FlowComment",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Comment",
                    "kind": "LinkedField",
                    "name": "comment",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "unread",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "commentText",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "createdAt",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "GwtUser",
                        "kind": "LinkedField",
                        "name": "gwtUser",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "fullName",
                            "storageKey": null
                          },
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "firstName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lastName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "lanId",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Flow",
                    "kind": "LinkedField",
                    "name": "flow",
                    "plural": false,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "linkedType",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v1/*: any*/),
        "filters": [
          "orderBy",
          "filter"
        ],
        "handle": "connection",
        "key": "AssigneeDiscussionCardPaginationFragment_flowComments",
        "kind": "LinkedHandle",
        "name": "flowComments"
      }
    ]
  },
  "params": {
    "cacheID": "e60c01d85bc422c243a5422db41f3563",
    "id": null,
    "metadata": {},
    "name": "AssigneeDiscussionCardPaginationQuery",
    "operationKind": "query",
    "text": "query AssigneeDiscussionCardPaginationQuery(\n  $count: Int!\n  $userId: UUID!\n) {\n  ...AssigneeDiscussionCardPaginationFragment_flowComments_4irCDR\n}\n\nfragment AssigneeDiscussionCardPaginationFragment_flowComments_4irCDR on Query {\n  flowComments(orderBy: UNREAD_COMMENTS_CREATED_AT_DESC, filter: {flow: {assignedToId: {equalTo: $userId}}}, first: $count) {\n    edges {\n      node {\n        comment {\n          rowId\n          unread\n          commentText\n          createdAt\n          gwtUser {\n            fullName\n            ...UserAvatar_gwtUser\n            id\n          }\n          id\n        }\n        flow {\n          rowId\n          name\n          ...FlowLink_flow\n          id\n        }\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment FlowLink_flow on Flow {\n  rowId\n  linkedType\n}\n\nfragment UserAvatar_gwtUser on GwtUser {\n  id\n  firstName\n  lastName\n  lanId\n}\n"
  }
};
})();

(node as any).hash = "703b909570812ae520b5f7b259360af8";

export default node;
