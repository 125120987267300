import { Avatar, AvatarProps } from '@enterprise-ui/canvas-ui-react'
import { graphql, useFragment } from 'react-relay'
import { hashString } from '../../util/userUtils'
import { UserAvatar_gwtUser$key } from './__generated__/UserAvatar_gwtUser.graphql'

interface UserAvatarProps extends AvatarProps {
  userRef?: UserAvatar_gwtUser$key | null
  showAvatar?: boolean
  showName?: boolean
  spanClassName?: string
  className?: string
}

export const UserAvatar = ({
  userRef = null,
  showAvatar = true,
  showName = true,
  spanClassName = undefined,
  ...props
}: UserAvatarProps) => {
  const data = useFragment(
    graphql`
      fragment UserAvatar_gwtUser on GwtUser {
        id
        firstName
        lastName
        lanId
      }
    `,
    userRef,
  )
  const AvatarColors: AvatarProps['color'][] = [
    'indigo',
    'lime',
    'mint',
    'pink',
    'red',
    'teal',
    'violet',
  ]

  const getColor: AvatarProps['color'] =
    AvatarColors[hashString(data?.lanId) % AvatarColors.length]

  return (
    <>
      {showAvatar && (
        <Avatar color={getColor} {...props} aria-label="avatar">
          {`${data?.firstName.charAt(0)}${data?.lastName.charAt(0)}`}
        </Avatar>
      )}
      {showName && (
        <span
          className={spanClassName || 'hc-ml-xs'}
          style={{ fontWeight: 'bold' }}
        >
          {data?.firstName} {data?.lastName}
        </span>
      )}
    </>
  )
}
