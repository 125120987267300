/**
 * @generated SignedSource<<e2d4b2128f43bcd6924bebf852b05541>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AssignmentStatus = "ACCEPTED" | "CANCELED" | "IN_PROGRESS" | "NONE" | "NOT_STARTED" | "READY_FOR_REVIEW" | "REJECTED" | "UNDER_REVIEW" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type StartNextAttemptButton_Fragment$data = {
  readonly attempts: number;
  readonly currentAttempt: number;
  readonly flow: {
    readonly assignedToId: UUID | null;
    readonly assignmentStatus: AssignmentStatus;
    readonly name: string;
  } | null;
  readonly flowId: UUID;
  readonly " $fragmentType": "StartNextAttemptButton_Fragment";
};
export type StartNextAttemptButton_Fragment$key = {
  readonly " $data"?: StartNextAttemptButton_Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"StartNextAttemptButton_Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StartNextAttemptButton_Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "flowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentAttempt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "attempts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Flow",
      "kind": "LinkedField",
      "name": "flow",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "assignmentStatus",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "assignedToId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Module",
  "abstractKey": null
};

(node as any).hash = "7e8cc654e62935e6a350ebb71c9c51bb";

export default node;
