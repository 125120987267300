import {
  Card,
  Divider,
  Grid,
  Heading,
  Layout,
  Table,
  Tabs,
} from '@enterprise-ui/canvas-ui-react'
import { useState } from 'react'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { useParams } from 'react-router-dom'
import { CompanyProfilePageQuery } from './__generated__/CompanyProfilePageQuery.graphql'
import './CompanyProfilePage.scss'
import { getGwtFormattedDate } from '../../../util/dateUtils'
import { CompanyUserTable } from '../CompanyUserTable/CompanyUserTable'
import { SuspenseRingLoader } from '../../../loadingComponents/SuspenseRingLoader'

export const CompanyProfilePage = () => {
  const [tab, setTab] = useState('All')

  const { companyId } = useParams()

  const { company } = useLazyLoadQuery<CompanyProfilePageQuery>(
    graphql`
      query CompanyProfilePageQuery($rowId: UUID!) {
        company(rowId: $rowId) {
          createdAt
          name
          numberOfCertifications
          numberOfUsers
          createdAt
        }
      }
    `,
    { rowId: companyId },
  )

  const TableComponent = (
    <Grid.Container justify="center" className="hc-pl-3x hc-pr-3x">
      <Grid.Item lg={8} md={8} sm={10}>
        <SuspenseRingLoader size={150} speedMultiplier={3}>
          <CompanyUserTable
            companyId={companyId || ''}
            onlyCertified={tab === 'Certified'}
          />
        </SuspenseRingLoader>
      </Grid.Item>
    </Grid.Container>
  )

  return (
    <Layout.Body includeRail className="company-profile-page hc-pa-none">
      <div>
        <div className="hc-mb-expanded small-width">
          <div className="hc-ta-center hc-mt-sm">
            <Heading size={3} className="hc-mt-sm hc-mb-sm">
              {company?.name}
            </Heading>
          </div>
          <div>
            <Card className="company-table">
              <Table cellPadding="expanded">
                <Table.Body>
                  <Table.Row justify="space-between">
                    <Table.Data>Date joined</Table.Data>
                    <Table.Data className="main-text capitalize">
                      {getGwtFormattedDate(company?.createdAt)}
                    </Table.Data>
                  </Table.Row>
                  <Table.Row justify="space-between">
                    <Table.Data># of profiles</Table.Data>
                    <Table.Data className="main-text">
                      {company?.numberOfUsers}
                    </Table.Data>
                  </Table.Row>
                  <Table.Row justify="space-between">
                    <Table.Data># of certifications</Table.Data>
                    <Table.Data className="main-text capitalize">
                      {company?.numberOfCertifications}
                    </Table.Data>
                  </Table.Row>
                </Table.Body>
              </Table>
            </Card>
          </div>
          <div>
            <Tabs
              size="expanded"
              disableAdaptive
              activeTab={tab}
              defaultTab="All"
              className="hc-mt-md tabs"
              onTabSelect={(event: any, tab: any) => {
                setTab(tab.name)
              }}
            >
              <Tabs.Item className="big-tab" name="All">
                All
              </Tabs.Item>
              <Tabs.Item className="big-tab" name="Certified">
                Certified
              </Tabs.Item>
            </Tabs>
          </div>

          <Divider className="hc-mb-xl" />
        </div>
        <div className="tab-content">
          {tab === 'All' && TableComponent}
          {tab === 'Certified' && TableComponent}
        </div>
      </div>
    </Layout.Body>
  )
}
