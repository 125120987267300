import { Card, Table } from '@enterprise-ui/canvas-ui-react'
import './GroupTemplateModules'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { getModuleProgramTypeText } from '../../../util/moduleUtils'
import { useAssentDnd } from '../../../hooks/useAssentDnd'
import { Draggable } from '../../../GenericComponents/Draggable/Draggable'
import { DragOverlay } from '@dnd-kit/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Droppable } from '../../../GenericComponents/Droppable/Droppable'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { TooltipButton } from '../../../GenericComponents/TooltipButton/TooltipButton'
import { noop } from 'lodash'

interface GroupTemplateModulesSelectedTableProps {
  onDeleteModule?: (module: any) => void
}

export const GroupTemplateModulesSelectedTable = ({
  onDeleteModule: handleDeleteModule = noop,
}: GroupTemplateModulesSelectedTableProps) => {
  const { watch, getValues, setValue } = useFormContext()
  const modules = watch('modules')

  const { isDragging, isOverDroppable } = useAssentDnd()

  useEffect(() => {
    if (isOverDroppable?.over?.id !== undefined) {
      const hoverId = Number.parseInt(isOverDroppable.over.id.toString())
      let values: any[] = getValues('modules')
      const selectedIndex = values.findIndex(
        (module) => module.rowId === isOverDroppable.active.id,
      )
      ;[values[hoverId], values[selectedIndex]] = [
        values[selectedIndex],
        values[hoverId],
      ]
      setValue('modules', values)
    }
  }, [
    getValues,
    isOverDroppable?.active?.id,
    isOverDroppable?.over?.id,
    setValue,
  ])

  return (
    <Card className="group-template-selected-table hc-pa-min">
      <Table name="Module" cellPadding="normal" height="47vh">
        <Table.Head className="sticky-header">
          <Table.Row align="center">
            <Table.Header xs={1} />
            <Table.Header xs={3}>Module</Table.Header>
            <Table.Header xs={3}>Software</Table.Header>
            <Table.Header xs={3}>Program</Table.Header>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {modules?.map((module, i) => {
            return (
              <Droppable key={module.rowId} id={i}>
                <Table.Row
                  align="center"
                  className={`${
                    isDragging?.active.id === module.rowId ? 'invisible' : ''
                  }`}
                >
                  <Table.Data xs={1}>
                    <Draggable id={module.rowId} key={Math.random()}>
                      <div className="grip-hover">
                        <FontAwesomeIcon icon="grip-vertical" size="2x" />
                      </div>
                    </Draggable>
                  </Table.Data>

                  <Table.Data xs={3}>{module.name}</Table.Data>
                  <Table.Data xs={3}>{module.softwareType}</Table.Data>
                  <Table.Data xs={4}>
                    {getModuleProgramTypeText(module.programType)}
                  </Table.Data>
                  <Table.Data xs={1}>
                    <TooltipButton
                      content="Remove module"
                      button={{ type: 'destructive' }}
                      location="left"
                      onClick={() => handleDeleteModule(module)}
                    >
                      <FontAwesomeIcon icon={faTrash} size="sm" />
                    </TooltipButton>
                  </Table.Data>
                </Table.Row>

                <DragOverlay dropAnimation={null}>
                  {isDragging && isDragging.active.id === module.rowId ? (
                    <Card className="dragging-table">
                      <Table name="Module" cellPadding="normal">
                        <Table.Body>
                          <Table.Row align="center">
                            <Table.Data xs={1}>
                              <div className="grip-hover">
                                <FontAwesomeIcon
                                  icon="grip-vertical"
                                  size="2x"
                                />
                              </div>
                            </Table.Data>
                            <Table.Data xs={3}>{module.name}</Table.Data>
                            <Table.Data xs={3}>
                              {module.softwareType}
                            </Table.Data>
                            <Table.Data xs={4}>
                              {getModuleProgramTypeText(module.programType)}
                            </Table.Data>
                          </Table.Row>
                        </Table.Body>
                      </Table>
                    </Card>
                  ) : null}
                </DragOverlay>
              </Droppable>
            )
          })}
        </Table.Body>
      </Table>
    </Card>
  )
}
