import { Grid, Card, Divider } from '@enterprise-ui/canvas-ui-react'
import { FlowLink } from '../../Flows/FlowLink'
import './Assignee.scss'
import { graphql } from 'react-relay'
import { ToDoCardQuery } from './__generated__/ToDoCardQuery.graphql'
import { StatusChip } from '../../Flows/StatusChip'
import { useSubscriptionRefreshQuery } from '../../../hooks/useRefresh'
import { getGwtFormattedDate } from '../../../util/dateUtils'
import { UpcomingCard } from './UpcomingCard'
import { useGwtUser } from '../../../hooks'

export const ToDoCard = () => {
  const user = useGwtUser()
  const data = useSubscriptionRefreshQuery<ToDoCardQuery>(
    graphql`
      query ToDoCardQuery($assignedToId: UUID!) {
        ...UpcomingCardFragment @arguments(assignedToId: $assignedToId)
        inProgressFlows: flows(
          condition: { assignedToId: $assignedToId }
          filter: { assignmentStatus: { notIn: [ACCEPTED, NOT_STARTED] } }
        ) {
          nodes {
            rowId
            module {
              moduleGroup {
                flow {
                  name
                }
              }
            }
            name
            assignedTo {
              id
              rowId
            }
            assignedAt
            dueAt
            linkedType
            assignmentStatus
            ...FlowLink_flow
            ...StatusChip_flow
          }
        }
      }
    `,
    {
      assignedToId: user.rowId,
    },
    {
      component: 'ASSIGNEE_TODO_CARD',
      uniqueComponentId: '',
      onNotification: [
        'ASSIGN_TEMPLATE_NOTIFICATION',
        'REVIEWER_MODULE_START_NEXT_ATTEMPT_NOTIFICATION',
        'REVIEWER_MODULE_BUTTON_NOTIFICATION',
      ],
      includeSelfNotifications: true,
    },
  )

  return (
    <>
      <Card className="to-do-card">
        <div className="hc-pa-none cardHeader header flex hc-pa-lg">
          <Grid.Container spacing="none" align="center" className="flex-grow">
            <Grid.Item xs={12}>
              <div className="card-header-text">To Do</div>
            </Grid.Item>
          </Grid.Container>
        </div>
        <Divider />
        <div className="hc-pa-none card-scroll to-do-scroll-area flex-grow">
          {data.inProgressFlows?.nodes.map((flow) => {
            return (
              <FlowLink flowRef={flow} key={flow.rowId}>
                <Grid.Container
                  spacing="none"
                  align="center"
                  className="hc-pa-lg card-hover"
                >
                  <Grid.Item xs={8}>
                    <div className="to-do-header-text">{flow.name}</div>
                    <div className="to-do-card-text">
                      <div>
                        {flow.assignedAt &&
                          `Assigned on ${getGwtFormattedDate(flow.assignedAt)}`}
                      </div>
                      <div>
                        {flow.dueAt &&
                          `Due on ${getGwtFormattedDate(flow.dueAt)}`}
                      </div>
                      {flow.module?.moduleGroup?.flow && (
                        <div>{`Part of the ${flow.module?.moduleGroup?.flow.name}`}</div>
                      )}
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={4} align="right" className="hc-pr-lg">
                    <StatusChip flowRef={flow} className="pointer" />
                  </Grid.Item>
                </Grid.Container>
                <Divider />
              </FlowLink>
            )
          })}
        </div>
      </Card>
      <UpcomingCard queryRef={data} />
    </>
  )
}
