import { graphql } from 'react-relay'
import { TemplateCreatePageMutation } from './__generated__/TemplateCreatePageMutation.graphql'
import { useGwtMutation } from '../../hooks'
import { TemplateForm } from './TemplateForm'

export const TemplateCreatePage = () => {
  const { commit, isInFlight } = useGwtMutation<TemplateCreatePageMutation>(
    graphql`
      mutation TemplateCreatePageMutation(
        $attempts: Int!
        $dueAt: Datetime
        $tasks: [TaskModuleFlowIdFkeyTaskCreateInput!]
        $name: String!
        $description: String!
        $notificationInput: NotificationInput!
        $reviewers: [FlowReviewerFlowIdFkeyFlowReviewerCreateInput!]
        $softwareType: SoftwareType
        $programType: ProgramType
        $durationSeconds: Int!
      ) {
        createNotification(input: { notification: $notificationInput }) {
          clientMutationId
        }
        createModule(
          input: {
            module: {
              flowToFlowId: {
                create: {
                  name: $name
                  description: $description
                  durationSeconds: $durationSeconds
                  dueAt: $dueAt
                  flowType: BLUEPRINT
                  flowReviewersUsingRowId: { create: $reviewers }
                }
              }
              attempts: $attempts
              programType: $programType
              softwareType: $softwareType
              tasksUsingFlowId: { create: $tasks }
            }
            initializeGalleryComponentsAsset: true
          }
        ) {
          module {
            galleryAssetComponentsId
            flowId
          }
        }
      }
    `,
  )

  return <TemplateForm commit={commit} isInFlight={isInFlight} />
}
