/**
 * @generated SignedSource<<54ba2f91b75531fb14e9f42ae084f85e>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type InsightsDashboardFragment_refetchable$variables = {};
export type InsightsDashboardFragment_refetchable$data = {
  readonly " $fragmentSpreads": FragmentRefs<"InsightsDashboardFragment_activities">;
};
export type InsightsDashboardFragment_refetchable = {
  response: InsightsDashboardFragment_refetchable$data;
  variables: InsightsDashboardFragment_refetchable$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "programType",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "softwareType",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assignedToId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "totalCount",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "filter",
    "value": {
      "flow": {
        "flowType": {
          "notEqualTo": "BLUEPRINT"
        }
      }
    }
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assignmentStatus",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assignmentDueAtStatus",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "InsightsDashboardFragment_refetchable",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "InsightsDashboardFragment_activities"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "InsightsDashboardFragment_refetchable",
    "selections": [
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "filter",
            "value": {
              "flow": {
                "assignmentStatus": {
                  "equalTo": "ACCEPTED"
                }
              }
            }
          }
        ],
        "concreteType": "ModulesConnection",
        "kind": "LinkedField",
        "name": "modules",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Module",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Flow",
                "kind": "LinkedField",
                "name": "flow",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": "modules(filter:{\"flow\":{\"assignmentStatus\":{\"equalTo\":\"ACCEPTED\"}}})"
      },
      {
        "alias": null,
        "args": [
          {
            "kind": "Literal",
            "name": "condition",
            "value": {
              "userType": "EXTERNAL_USER"
            }
          }
        ],
        "concreteType": "GwtUsersConnection",
        "kind": "LinkedField",
        "name": "gwtUsers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GwtUsersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "GwtUser",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": "gwtUsers(condition:{\"userType\":\"EXTERNAL_USER\"})"
      },
      {
        "alias": "statusPiechartData",
        "args": (v5/*: any*/),
        "concreteType": "ModulesConnection",
        "kind": "LinkedField",
        "name": "modules",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ModulesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Module",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Flow",
                    "kind": "LinkedField",
                    "name": "flow",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dueAt",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "modules(filter:{\"flow\":{\"flowType\":{\"notEqualTo\":\"BLUEPRINT\"}}})"
      },
      {
        "alias": "moduleCompletionData",
        "args": [
          {
            "kind": "Literal",
            "name": "filter",
            "value": {
              "flow": {
                "assignmentStatus": {}
              }
            }
          }
        ],
        "concreteType": "ModulesConnection",
        "kind": "LinkedField",
        "name": "modules",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Module",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v3/*: any*/),
              (v0/*: any*/),
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Flow",
                "kind": "LinkedField",
                "name": "flow",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "modules(filter:{\"flow\":{\"assignmentStatus\":{}}})"
      },
      {
        "alias": "moduleAssignedData",
        "args": [
          {
            "kind": "Literal",
            "name": "filter",
            "value": {
              "flow": {
                "assignedToExists": true
              }
            }
          }
        ],
        "concreteType": "ModulesConnection",
        "kind": "LinkedField",
        "name": "modules",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ModulesEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Module",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Flow",
                    "kind": "LinkedField",
                    "name": "flow",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "modules(filter:{\"flow\":{\"assignedToExists\":true}})"
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "FlowReviewersConnection",
        "kind": "LinkedField",
        "name": "flowReviewers",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FlowReviewersEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FlowReviewer",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GwtUser",
                    "kind": "LinkedField",
                    "name": "reviewer",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FlowsConnection",
                        "kind": "LinkedField",
                        "name": "flowsByAssignedToId",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fullName",
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "flowReviewers(filter:{\"flow\":{\"flowType\":{\"notEqualTo\":\"BLUEPRINT\"}}})"
      }
    ]
  },
  "params": {
    "cacheID": "7650364149a8728668fa1ada2aa18460",
    "id": null,
    "metadata": {},
    "name": "InsightsDashboardFragment_refetchable",
    "operationKind": "query",
    "text": "query InsightsDashboardFragment_refetchable {\n  ...InsightsDashboardFragment_activities\n}\n\nfragment AssentCertificationsBarChart on Query {\n  modules(filter: {flow: {assignmentStatus: {equalTo: ACCEPTED}}}) {\n    nodes {\n      programType\n      softwareType\n      flow {\n        assignedToId\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment InsightsDashboardFragment_activities on Query {\n  ...AssentCertificationsBarChart\n  gwtUsers(condition: {userType: EXTERNAL_USER}) {\n    edges {\n      node {\n        id\n      }\n    }\n    totalCount\n  }\n  statusPiechartData: modules(filter: {flow: {flowType: {notEqualTo: BLUEPRINT}}}) {\n    edges {\n      node {\n        id\n        softwareType\n        flow {\n          name\n          assignmentStatus\n          assignmentDueAtStatus\n          dueAt\n          id\n        }\n      }\n    }\n  }\n  moduleCompletionData: modules(filter: {flow: {assignmentStatus: {}}}) {\n    nodes {\n      id\n      programType\n      softwareType\n      flow {\n        name\n        assignmentStatus\n        assignmentDueAtStatus\n        assignedToId\n        id\n      }\n    }\n  }\n  moduleAssignedData: modules(filter: {flow: {assignedToExists: true}}) {\n    edges {\n      node {\n        id\n        flow {\n          name\n          id\n        }\n      }\n    }\n  }\n  flowReviewers(filter: {flow: {flowType: {notEqualTo: BLUEPRINT}}}) {\n    edges {\n      node {\n        id\n        reviewer {\n          flowsByAssignedToId {\n            totalCount\n          }\n          fullName\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "5cfb7ce40a6e4c833881b7ab37e89d9e";

export default node;
