import { Button, Form, Grid, Spinner } from '@enterprise-ui/canvas-ui-react'
import { useEffect } from 'react'
import { graphql, useFragment } from 'react-relay'
import './Comment.scss'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useGwtMutation, useGwtUser } from '../../hooks'
import { CommentAddFragment$key } from './__generated__/CommentAddFragment.graphql'
import {
  CommentAddMutation,
  CommentAddMutation$variables,
} from './__generated__/CommentAddMutation.graphql'
import { useLocation } from 'react-router-dom'
import {
  getActivityInputObject,
  getNotificationInputObject,
} from '../../util/relayUtils'
import { useAtom } from 'jotai'
import { commentBoxAtom, preloadedReviewerCommentAtom } from '../../state/atoms'
import { activityTypes } from '../../constants'

interface CommentAddProps {
  flowRef: CommentAddFragment$key | null | undefined
}

type Inputs = {
  add: string
}

export const CommentAdd = (props: CommentAddProps) => {
  const [commentBoxEnabled, setCommentBoxAtom] = useAtom(commentBoxAtom)
  const location = useLocation()
  const flowCommentData = useFragment(
    graphql`
      fragment CommentAddFragment on Flow {
        rowId
        name
        assignedToId
      }
    `,
    props.flowRef || null,
  )

  const { commit, isInFlight } = useGwtMutation<CommentAddMutation>(graphql`
    mutation CommentAddMutation(
      $commentText: String!
      $gwtUserId: UUID!
      $flowId: UUID!
      $activity: ActivityInput!
      $notificationInput: NotificationInput!
    ) {
      createActivity(input: { activity: $activity }) {
        clientMutationId
      }
      createNotification(input: { notification: $notificationInput }) {
        clientMutationId
      }
      createFlowComment(
        input: {
          flowComment: {
            commentToCommentId: {
              create: { commentText: $commentText, gwtUserId: $gwtUserId }
            }
            flowId: $flowId
          }
        }
      ) {
        clientMutationId
        flowComment {
          comment {
            rowId
            commentText
            createdAt
            gwtUserId
            gwtUser {
              ...UserAvatar_gwtUser
            }
          }
          flow {
            module {
              ...HistoryTab_module
            }
            flowComments(
              filter: { comment: { responseTo: { isNull: true } } }
              orderBy: COMMENT_BY_COMMENT_ID__CREATED_AT_DESC
            ) {
              nodes {
                flowId
                commentId
              }
            }
          }
        }
      }
    }
  `)

  const { register, handleSubmit, watch, reset, setValue } = useForm()
  const [preloadedComment, setPreloadedComment] = useAtom(
    preloadedReviewerCommentAtom,
  )

  const input: string | undefined = watch('add', '')

  useEffect(() => {
    if (preloadedComment && preloadedComment.length > 0) {
      setCommentBoxAtom(true)
      setValue('add', preloadedComment)
      setPreloadedComment('')
    }

    return () => {
      setCommentBoxAtom(false)
    }
  }, [preloadedComment, setCommentBoxAtom, setPreloadedComment, setValue])

  const user = useGwtUser()

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (!flowCommentData) {
      return
    }
    const mutation = {} as CommentAddMutation$variables
    mutation.commentText = data.add
    mutation.gwtUserId = user.rowId
    mutation.flowId = flowCommentData.rowId

    mutation.activity = getActivityInputObject(
      activityTypes.ADDED_A_COMMENT,
      flowCommentData.name,
      mutation.flowId,
      `${location.pathname}${location.hash}`,
    )

    mutation.notificationInput = getNotificationInputObject(
      'ASSIGNEE_MODULE_COMMENT_ADD_NOTIFICATION',
      {
        flowId: flowCommentData.rowId,
        assignedToId: flowCommentData.assignedToId,
      },
    )

    commit(mutation, () => {
      setCommentBoxAtom(false)
      reset()
    })
  }

  return (
    <Form
      className="Comment comment-reply-form hc-mt-md"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid.Container spacing="none">
        {!commentBoxEnabled && (
          <Grid.Item
            sm={12}
            id="newCommentButton"
            className="hc-ta-right hc-mb-xl"
          >
            <Button
              type="primary"
              onClick={() => setCommentBoxAtom(true)}
              disabled={user.isReadOnly}
            >
              New comment
            </Button>
          </Grid.Item>
        )}
        {commentBoxEnabled && (
          <>
            <Grid.Item xs={12} id="commentBox">
              <Form.Field
                type="textarea"
                value={input}
                disableFieldInfo
                errorText="Please enter some text"
                placeholder="Add new comment..."
                id="demo_01"
                data-testid="commentBox"
                {...register('add')}
                onChange={(e: any) => {
                  setValue('add', e.target.value)
                }}
              />
            </Grid.Item>
            <Grid.Item
              id="buttons"
              className="buttons hc-mt-xs display-flex"
              xs={12}
            >
              <Button
                type="secondary"
                className="hc-mr-sm"
                onClick={() => {
                  reset()
                  setCommentBoxAtom(false)
                }}
              >
                Cancel
              </Button>
              {!isInFlight && (
                <Button type="submit" disabled={isInFlight}>
                  Add comment
                </Button>
              )}
              {isInFlight && <Spinner />}
            </Grid.Item>
          </>
        )}
      </Grid.Container>
    </Form>
  )
}
