import { useRef } from 'react'
import SunEditorComponent from 'suneditor-react'
import SunEditorCore from 'suneditor/src/lib/core'
import 'suneditor/dist/css/suneditor.min.css' // Import Sun Editor's CSS File
import { Input } from '@enterprise-ui/canvas-ui-react'
import { useController } from 'react-hook-form'
import { get } from 'lodash'
import classNames from 'classnames'
import { ControlledErrorMessage } from './ControlledErrorMessage'
import { getRequiredMessage } from '../util/formUtils'
import './ControlledRichTextEditor.scss'

export type RichTextEditorContent = { textContent: string; htmlContent: string }

interface PropTypes {
  name: string
  label?: string
  required?: boolean
  requiredMessage?: string
  defaultValue?: string
  height?: string
}

export const ControlledRichTextEditor = ({
  name,
  label,
  required = false,
  requiredMessage,
  defaultValue,
  height,
}: PropTypes) => {
  const editor = useRef<SunEditorCore>()
  // The sunEditor parameter will be set to the core suneditor instance when this function is called
  const getSunEditorInstance = (sunEditor: SunEditorCore) => {
    editor.current = sunEditor
  }

  const {
    field: { onChange, value },
    formState: { errors },
  } = useController({
    name,
    rules: {
      required: getRequiredMessage({
        name,
        label,
        required,
        requiredMessage,
      }),
    },
    defaultValue,
  })
  const error = get(errors, name)

  const wrapperClassName = classNames('sun-wrapper', {
    'border-errored': !!error,
  })

  return (
    <>
      <Input.Label for={name} required={required} error={error}>
        {label}
      </Input.Label>
      <div className={wrapperClassName}>
        <SunEditorComponent
          name={name}
          autoFocus={false}
          setDefaultStyle="font-family: 'Helvetica For Target'; font-size: 14px; color: #333333;"
          setContents={value?.htmlContent}
          defaultValue={defaultValue}
          onChange={(htmlContent: string) => {
            const textContent = editor.current?.getText()
            onChange(
              textContent
                ? {
                    textContent,
                    htmlContent,
                  }
                : null,
            )
          }}
          getSunEditorInstance={getSunEditorInstance}
          setOptions={{
            resizingBar: false,
            height: height ?? '300px',
            stickyToolbar: -1,
            buttonList: [
              ['undo', 'redo'],
              ['font', 'fontSize', 'formatBlock'],
              // ['paragraphStyle', 'blockquote'],
              [
                'bold',
                'underline',
                'italic',
                'strike',
                'subscript',
                'superscript',
              ],
              ['outdent', 'indent'],

              ['fontColor', 'hiliteColor', 'textStyle'],
              ['align', 'horizontalRule', 'list', 'table'],
              ['removeFormat'],

              //['lineHeight', 'link', 'image', 'video', 'math'], // You must add the 'katex' library at options to use the 'math' plugin.
              // ['imageGallery'], // You must add the "imageGalleryUrl".
              //['fullScreen', 'showBlocks', 'codeView'],
              //['preview', 'print'],
              // ['save', 'template'],
              // '/', Line break]
            ],
          }}
        />
      </div>
      <ControlledErrorMessage name={name} />
    </>
  )
}
