import {
  Button,
  Grid,
  Modal,
  ToastProvider,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { graphql, useFragment } from 'react-relay'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGwtMutation } from '../../../hooks'
import { DropdownSelect } from '../../../GenericComponents/DropdownSelect/DropdownSelect'
import { useState } from 'react'
import { usePublishGlobalNotification } from '../../../hooks/useNotifications'
import './AssignmentOptions.scss'
import classNames from 'classnames'
import { useAuthHelper } from '../../../hooks/useAuthHelper'
import { AssignmentOptions_module$key } from './__generated__/AssignmentOptions_module.graphql'
import { useNavigate, useLocation } from 'react-router-dom'

interface AssignmentOptionsProps {
  moduleRef: AssignmentOptions_module$key
  className?: string
  tableLocation?: number
  status?: string
}

export const AssignmentOptions = ({
  moduleRef,
  className,
  tableLocation,
  status,
}: AssignmentOptionsProps) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [whichDeleteModalOpen, setWhichDeleteModalOpen] = useState(false)
  const [restoreModalOpen, setRestoreModalOpen] = useState(false)
  const makeToast = ToastProvider.useToaster()

  const { publishToLocal } = usePublishGlobalNotification()
  const { protectArray, protectStringArray } = useAuthHelper()

  const data = useFragment(
    graphql`
      fragment AssignmentOptions_module on Module {
        flow {
          rowId
          name
          archivedAt
        }
      }
    `,
    moduleRef || null,
  )

  const { commit } = useGwtMutation(graphql`
    mutation AssignmentOptionsMutation($input: ArchiveFlowInput!) {
      archiveFlow(input: $input) {
        clientMutationId
      }
    }
  `)

  const unarchive = useGwtMutation(graphql`
    mutation AssignmentOptionsUnarchiveMutation($input: ArchiveFlowInput!) {
      unArchiveFlow(input: $input) {
        clientMutationId
      }
    }
  `)

  let archived = data?.flow?.archivedAt ? true : false

  return (
    <div className={classNames('assignment-options', className)}>
      <div className="options hc-mr-md">
        <DropdownSelect
          location="bottom-right"
          shouldSelect={false}
          size="dense"
          options={
            status !== 'IN_PROGRESS'
              ? protectArray([
                  {
                    label: archived
                      ? 'Restore assignment'
                      : 'Archive assignment',
                    className: 'error-color hc-pa-min',
                    onSelect: () => {
                      archived
                        ? setRestoreModalOpen(true)
                        : setWhichDeleteModalOpen(true)
                    },
                    allowed: ['ADMIN', 'REVIEWER'],
                  },
                  {
                    label: 'Edit assignment',
                    className: 'hc-pa-min',
                    disabled: true,
                    onSelect: () => {
                      navigate('/reviewer/modules/edit', {
                        state: {
                          moduleName: data?.flow?.name,
                          moduleFlowId: data?.flow?.rowId,
                          prevUrl: location.pathname,
                        },
                      })
                    },
                    allowed: ['ADMIN'],
                  },
                ])
              : protectArray([
                  {
                    label: archived
                      ? 'Restore assignment'
                      : 'Archive assignment',
                    className: 'error-color hc-pa-min',
                    onSelect: () => {
                      archived
                        ? setRestoreModalOpen(true)
                        : setWhichDeleteModalOpen(true)
                    },
                    allowed: ['ADMIN', 'REVIEWER'],
                  },
                  {
                    label: 'Edit assignment',
                    className: 'hc-pa-min',
                    onSelect: () => {
                      navigate('/reviewer/modules/edit', {
                        state: {
                          moduleName: data?.flow?.name,
                          moduleFlowId: data?.flow?.rowId,
                          prevUrl: location.pathname,
                        },
                      })
                    },
                    allowed: ['ADMIN'],
                  },
                ])
          }
        >
          <Tooltip
            content={protectStringArray([
              {
                val: archived ? 'Restore' : 'Archive',
                allowed: ['ADMIN', 'REVIEWER'],
              },
              { val: 'Edit', allowed: ['ADMIN'] },
            ])}
            location="left"
          >
            <Button
              aria-label="assignment-options"
              iconOnly
              type="default"
              size="normal"
            >
              <FontAwesomeIcon icon={faEllipsisV} />
            </Button>
          </Tooltip>
        </DropdownSelect>
      </div>
      {whichDeleteModalOpen && (
        <Modal
          isVisible={whichDeleteModalOpen}
          headingText={`Archive ${data.flow?.name}`}
          className="align-left"
          onRefuse={() => setWhichDeleteModalOpen(false)}
        >
          <div className="hc-fs-md hc-ma-normal">
            This assignment will be archived. You can restore it by going to the
            Assignments table and filtering on archived assignments.
            <Grid.Container>
              <Grid.Item xs={6} />
              <Grid.Item xs={6} className="hc-ta-right hc-pt-expanded">
                <Button
                  type="secondary"
                  className="hc-mr-normal"
                  onClick={() => {
                    setWhichDeleteModalOpen(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="destructive"
                  onClick={() => {
                    commit(
                      {
                        input: {
                          flowId: data?.flow?.rowId,
                        },
                      },
                      () => {
                        makeToast({
                          type: 'success',
                          heading: 'Archived assignment',
                          message: 'Successfully archived the assignment.',
                        })
                        publishToLocal('ARCHIVE_ASSIGNMENT_NOTIFICATION', {
                          flowId: data?.flow?.rowId,
                        })
                      },
                      () => {
                        makeToast({
                          type: 'error',
                          heading: 'Failed to archive the assignment',
                          message:
                            'There was an error archiving the assignment.',
                        })
                      },
                    )
                    setWhichDeleteModalOpen(false)
                  }}
                >
                  Archive
                </Button>
              </Grid.Item>
            </Grid.Container>
          </div>
        </Modal>
      )}
      {restoreModalOpen && (
        <Modal
          isVisible={restoreModalOpen}
          headingText={`Restore ${data.flow?.name}`}
          className="align-left"
          onRefuse={() => setRestoreModalOpen(false)}
        >
          <div className="hc-fs-md hc-ma-normal">
            This assignment will be restored. The user/reviewer will be able to
            see this assignment on their dashboards again.
            <Grid.Container>
              <Grid.Item xs={6} />
              <Grid.Item xs={6} className="hc-ta-right hc-pt-expanded">
                <Button
                  type="secondary"
                  className="hc-mr-normal"
                  onClick={() => {
                    setRestoreModalOpen(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="destructive"
                  onClick={() => {
                    unarchive.commit(
                      {
                        input: {
                          flowId: data?.flow?.rowId,
                        },
                      },
                      () => {
                        makeToast({
                          type: 'success',
                          heading: 'Restored assignment',
                          message: 'Successfully restored the assignment.',
                        })
                        publishToLocal('UNARCHIVE_ASSIGNMENT_NOTIFICATION', {
                          flowId: data?.flow?.rowId,
                        })
                      },
                      () => {
                        makeToast({
                          type: 'error',
                          heading: 'Failed to restore assignment',
                          message:
                            'There was an error restoring the assignment.',
                        })
                      },
                    )
                    setRestoreModalOpen(false)
                  }}
                >
                  Restore
                </Button>
              </Grid.Item>
            </Grid.Container>
          </div>
        </Modal>
      )}
    </div>
  )
}
