import { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { useSubscribeToNotification } from '../hooks'

export const GwtGlobalNotificationsListener = () => {
  const queryClient = useQueryClient()

  const { subscription } = useSubscribeToNotification({
    component: 'GLOBAL_COMPONENT',
    uniqueComponentId: '',
    includeSelfNotifications: false,
    onNotification: ['GALLERY_ASSET_INVALIDATED_NOTIFICATION'],
  })

  useEffect(() => {
    if (subscription.response?.data?.assetId) {
      queryClient.invalidateQueries([
        'getAsset',
        subscription.response.data.assetId,
      ])
    }
  }, [subscription, queryClient])

  return <></>
}
