/**
 * @generated SignedSource<<82e28c565636d9ee7225e422886a7ee2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PolFileUploadStatus = "ERROR" | "NOT_STARTED" | "SCANNING" | "SUCCESS" | "WAITING_FOR_JOB" | "%future added value";
export type PolUploadInput = {
  clientMutationId?: string | null;
  polFileUploadId: UUID;
};
export type PolFileUploadCondition = {
  fileName?: string | null;
  fileSize?: BigInt | null;
  galleryAssetId?: UUID | null;
  gwtUserId?: UUID | null;
  rowId?: UUID | null;
  tossKey?: string | null;
  tossUrl?: string | null;
  uploadError?: string | null;
  uploadStatus?: PolFileUploadStatus | null;
};
export type usePolUpload_cancelMutation$variables = {
  condition?: PolFileUploadCondition | null;
  input: PolUploadInput;
};
export type usePolUpload_cancelMutation$data = {
  readonly cancelPolUpload: {
    readonly clientMutationId: string | null;
    readonly query: {
      readonly polFileUploads: {
        readonly nodes: ReadonlyArray<{
          readonly fileName: string;
          readonly galleryAssetId: UUID;
          readonly rowId: UUID;
          readonly tossUrl: string;
          readonly uploadError: string;
          readonly uploadStatus: PolFileUploadStatus;
        }>;
      } | null;
    } | null;
  } | null;
};
export type usePolUpload_cancelMutation = {
  response: usePolUpload_cancelMutation$data;
  variables: usePolUpload_cancelMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "condition"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v2 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v3 = [
  {
    "kind": "Variable",
    "name": "condition",
    "variableName": "condition"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "galleryAssetId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileName",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tossUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uploadError",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uploadStatus",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "clientMutationId",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "usePolUpload_cancelMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "PolUploadPayload",
        "kind": "LinkedField",
        "name": "cancelPolUpload",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Query",
            "kind": "LinkedField",
            "name": "query",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "PolFileUploadsConnection",
                "kind": "LinkedField",
                "name": "polFileUploads",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PolFileUpload",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "usePolUpload_cancelMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "PolUploadPayload",
        "kind": "LinkedField",
        "name": "cancelPolUpload",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Query",
            "kind": "LinkedField",
            "name": "query",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v3/*: any*/),
                "concreteType": "PolFileUploadsConnection",
                "kind": "LinkedField",
                "name": "polFileUploads",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PolFileUpload",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v11/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          },
          (v10/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f56d892a4a02f61e9d9251ec9b1aecfe",
    "id": null,
    "metadata": {},
    "name": "usePolUpload_cancelMutation",
    "operationKind": "mutation",
    "text": "mutation usePolUpload_cancelMutation(\n  $input: PolUploadInput!\n  $condition: PolFileUploadCondition\n) {\n  cancelPolUpload(input: $input) {\n    query {\n      polFileUploads(condition: $condition) {\n        nodes {\n          galleryAssetId\n          fileName\n          tossUrl\n          uploadError\n          uploadStatus\n          rowId\n          id\n        }\n      }\n      id\n    }\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "aa7e4d188f0bf2c9783c7124f2e975ce";

export default node;
