import {
  Button,
  Card,
  Heading,
  Layout,
  Table,
} from '@enterprise-ui/canvas-ui-react'
import { graphql, usePaginationFragment } from 'react-relay'
import { useSubscriptionRefreshQuery } from '../../hooks/useRefresh'
import './TemplatesTable.scss'
import React, { SyntheticEvent, useState } from 'react'
import { TextInputDebounce } from '../../GenericComponents/TextInputDebounce/TextInputDebounce'
import { useEffect } from 'react'
import { NOTIFICATIONS, useGwtUser } from '../../hooks'
import { usePaginateDataLength } from '../../hooks/usePaginateDataLength'
import { TemplatesTablePaginationFragment_modules$key } from './__generated__/TemplatesTablePaginationFragment_modules.graphql'
import { getGwtFormattedDate } from '../../util/dateUtils'
import { UserLink } from '../Users/UserLink'
import { UserModal } from '../Users/UserModal'
import { TableSortHeader } from '../../GenericComponents/TableSortHeader'
import {
  ModuleFilter,
  ModulesOrderBy,
  TemplatesTablePaginationQuery,
} from './__generated__/TemplatesTablePaginationQuery.graphql'
import {
  getFlowBlueprintDuration,
  getFlowDurationInWeeks,
} from '../../util/flowUtils'
import { DropdownSelect } from '../../GenericComponents/DropdownSelect/DropdownSelect'
import EnterpriseIcon, { ChevronDownIcon } from '@enterprise-ui/icons'
import { merge } from 'lodash'
import { TemplateOptions } from '../Templates/TemplateOptions/TemplateOptions'
import { useNavigate } from 'react-router-dom'
import { ProtectedComponent } from '../../GenericComponents/ProtectedComponent'

const PAGE_SIZE = 20
const BASE_FILTER: ModuleFilter = {
  flow: {
    flowType: { equalTo: 'BLUEPRINT' },
  },
}
export const TemplatesTable = () => {
  const navigate = useNavigate()

  const [selectedTemplatesFilter, setSelectedTemplatesFilter] =
    useState<ModuleFilter>({
      flow: {
        ownerId: null,
      },
    })
  const [searchFilter, setSearchFilter] = useState<ModuleFilter>({
    flow: {
      or: [
        { name: { likeInsensitive: null } },
        { owner: { fullName: { likeInsensitive: null } } },
      ],
    },
  })

  const [combinedFilter, setCombinedFilter] = useState<ModuleFilter>(
    merge({}, BASE_FILTER, selectedTemplatesFilter, searchFilter),
  )

  const user = useGwtUser()

  const [orderBy, setOrderBy] = useState<ModulesOrderBy>(
    'FLOW_BY_FLOW_ID__UPDATED_AT_DESC',
  )

  const [dropdownLabel, setDropdownLabel] = useState('All templates')

  const { data, isLoadingNext, hasNext, loadNext } = useTemplatesTableQuery(
    combinedFilter,
    [orderBy],
  )

  useEffect(() => {
    setCombinedFilter(
      merge({}, BASE_FILTER, selectedTemplatesFilter, searchFilter),
    )
  }, [selectedTemplatesFilter, searchFilter])

  const scrollEvent = (e: SyntheticEvent) => {
    if (isLoadingNext || !hasNext) {
      return
    }
    const target = e.target as HTMLTextAreaElement

    const scrollLeft = target.scrollHeight - target.scrollTop
    if (scrollLeft / target.clientHeight < 2) {
      loadNext(PAGE_SIZE)
    }
  }

  const updateSearchFilter = (debounced: string) => {
    if (debounced.length > 0) {
      const update = `%${debounced}%`
      setSearchFilter({
        flow: {
          or: [
            { name: { likeInsensitive: update } },
            {
              owner: { fullName: { likeInsensitive: update } },
            },
          ],
        },
      })
    } else {
      setSearchFilter({
        flow: {
          or: [
            { name: { likeInsensitive: null } },
            { owner: { fullName: { likeInsensitive: null } } },
          ],
        },
      })
    }
  }

  const dropdownOptions = [
    {
      label: 'All templates',
      onSelect: () => {
        setDropdownLabel('All templates')
        setSelectedTemplatesFilter({
          flow: {
            ownerId: null,
          },
        })
      },
    },
    {
      label: 'My templates',
      onSelect: () => {
        setDropdownLabel('My templates')
        setSelectedTemplatesFilter({
          flow: {
            ownerId: { equalTo: user.rowId },
          },
        })
      },
    },
    {
      label: 'Starred',
      onSelect: () => {
        setDropdownLabel('Starred')
        setSelectedTemplatesFilter({
          flow: {
            flowFavorites: {
              some: {
                gwtUserId: { equalTo: user.rowId },
              },
            },
          },
        })
      },
    },
  ]

  return (
    <Layout.Body includeRail className="templates-table">
      <Card className="hc-mt-sm">
        <Table name="Templates" cellPadding="normal">
          <Table.Head>
            <Table.Row align="center">
              <Table.Header xs={1}>
                <Heading size={5}>Templates</Heading>
              </Table.Header>
              <Table.Header style={{ paddingLeft: '0px' }} xs={3}>
                <TextInputDebounce
                  delay={500}
                  placeholder="Search"
                  onUpdate={updateSearchFilter}
                />
              </Table.Header>
              <Table.Header className="table-header-spacing" xs={2}>
                <DropdownSelect
                  defaultValue="All templates"
                  location="bottom-right"
                  options={dropdownOptions}
                >
                  <Button type="secondary" className="table-header-spacing">
                    {dropdownLabel}
                    <EnterpriseIcon
                      icon={ChevronDownIcon}
                      className="hc-ml-sm"
                    />
                  </Button>
                </DropdownSelect>
              </Table.Header>
              <Table.Header xs={4} />
              <Table.Header
                xs={2}
                justify="flex-end"
                align="flex-end"
                className="end"
              >
                <ProtectedComponent allowed={['ADMIN']}>
                  <Button
                    type="primary"
                    className="hc-ta-right"
                    onClick={() => navigate('/template/create')}
                  >
                    Create new template
                  </Button>
                </ProtectedComponent>
              </Table.Header>
            </Table.Row>
            <Table.Row align="center">
              <TableSortHeader
                xs={2}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="FLOW_BY_FLOW_ID__NAME_ASC"
                sortDownFilter="FLOW_BY_FLOW_ID__NAME_DESC"
              >
                Template name
              </TableSortHeader>
              <TableSortHeader
                xs={1}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="FLOW_BY_FLOW_ID__UPDATED_AT_ASC"
                sortDownFilter="FLOW_BY_FLOW_ID__UPDATED_AT_DESC"
              >
                Version
              </TableSortHeader>
              <TableSortHeader
                xs={1}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="FLOW_BY_FLOW_ID__CREATED_AT_ASC"
                sortDownFilter="FLOW_BY_FLOW_ID__CREATED_AT_DESC"
              >
                Date created
              </TableSortHeader>
              <TableSortHeader
                xs={2}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="FLOW_OWNER_FIRST_NAME_ASC"
                sortDownFilter="FLOW_OWNER_FIRST_NAME_DESC"
              >
                Created by
              </TableSortHeader>
              <TableSortHeader
                xs={1}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="FLOW_BY_FLOW_ID__UPDATED_AT_ASC"
                sortDownFilter="FLOW_BY_FLOW_ID__UPDATED_AT_DESC"
              >
                Date modified
              </TableSortHeader>
              <TableSortHeader
                xs={2}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="FLOW_BY_FLOW_ID__LAST_MODIFIED_BY_FIRST_NAME_ASC"
                sortDownFilter="FLOW_BY_FLOW_ID__LAST_MODIFIED_BY_FIRST_NAME_DESC"
              >
                Modified by
              </TableSortHeader>
              <TableSortHeader
                xs={1}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="FLOW_BY_FLOW_ID__DURATION_ASC"
                sortDownFilter="FLOW_BY_FLOW_ID__DURATION_DESC"
              >
                Duration
              </TableSortHeader>
              <TableSortHeader
                xs={1}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="ATTEMPTS_ASC"
                sortDownFilter="ATTEMPTS_DESC"
              >
                # of attempts
              </TableSortHeader>
              <Table.Header />
            </Table.Row>
          </Table.Head>
          <Table.Body onScroll={scrollEvent} className="overflow">
            {data?.modules?.edges?.map(
              ({ node: { flow, attempts, versionNumber, ...node } }, i) => {
                const duration = flow?.durationSeconds
                  ? getFlowDurationInWeeks(flow.durationSeconds)
                  : getFlowBlueprintDuration(flow?.createdAt, flow?.dueAt)
                return (
                  <Table.Row align="center" key={flow?.rowId}>
                    <Table.Data xs={2}>{flow?.name}</Table.Data>
                    <Table.Data xs={1}>
                      {parseFloat(versionNumber).toFixed(1)}
                    </Table.Data>
                    <Table.Data xs={1}>
                      {getGwtFormattedDate(flow?.createdAt)}
                    </Table.Data>
                    <Table.Data xs={2}>
                      <UserModal userRef={flow?.owner} />
                      <UserLink userRef={flow?.owner} />
                    </Table.Data>
                    <Table.Data xs={1}>
                      {getGwtFormattedDate(flow?.updatedAt)}
                    </Table.Data>
                    <Table.Data xs={2}>
                      <UserModal userRef={flow?.lastModifiedBy} />
                      <UserLink userRef={flow?.lastModifiedBy} />
                    </Table.Data>
                    <Table.Data xs={1}>
                      {`${duration} ${duration === 1 ? 'week' : 'weeks'}`}
                    </Table.Data>
                    <Table.Data xs={1}>{attempts}</Table.Data>
                    <Table.Data align="flex-end" xs={1}>
                      <TemplateOptions
                        disableFavoriteConfirmationModal
                        moduleRef={node}
                        tableLocation={i}
                      />
                    </Table.Data>
                  </Table.Row>
                )
              },
            )}
          </Table.Body>
        </Table>
      </Card>
    </Layout.Body>
  )
}

const useTemplatesTableQuery = (
  moduleFilter: ModuleFilter | undefined = undefined,
  modulesOrderBy: [ModulesOrderBy] | undefined = undefined,
) => {
  const [dataLength, setDataLength] = useState(PAGE_SIZE)
  const user = useGwtUser()

  const pageData = useSubscriptionRefreshQuery<TemplatesTablePaginationQuery>(
    graphql`
      query TemplatesTablePaginationQuery(
        $moduleFilter: ModuleFilter
        $modulesOrderBy: [ModulesOrderBy!]
        $count: Int!
        $gwtUserId: UUID!
      ) {
        ...TemplatesTablePaginationFragment_modules
          @arguments(
            moduleFilter: $moduleFilter
            modulesOrderBy: $modulesOrderBy
            count: $count
            gwtUserId: $gwtUserId
          )
      }
    `,
    { moduleFilter, modulesOrderBy, count: dataLength, gwtUserId: user.rowId },
    {
      component: 'TEMPLATES_TABLE',
      uniqueComponentId: '',
      onNotification: [...NOTIFICATIONS],
      includeSelfNotifications: true,
    },
  )

  const paginationFragmentHook = usePaginationFragment<
    TemplatesTablePaginationQuery,
    TemplatesTablePaginationFragment_modules$key
  >(
    graphql`
      fragment TemplatesTablePaginationFragment_modules on Query
      @argumentDefinitions(
        moduleFilter: { type: "ModuleFilter" }
        modulesOrderBy: { type: "[ModulesOrderBy!]" }
        count: { type: "Int" }
        gwtUserId: { type: "UUID" }
        cursor: { type: "Cursor" }
      )
      @refetchable(queryName: "TemplatesTablePaginationQuery_Refetchable") {
        modules(
          filter: $moduleFilter
          orderBy: $modulesOrderBy
          first: $count
          after: $cursor
        ) @connection(key: "TemplatesTable_modules") {
          edges {
            node {
              ...TemplateOptions_module @arguments(gwtUserId: $gwtUserId)
              attempts
              versionNumber
              flow {
                durationSeconds
                owner {
                  ...UserModal_user
                  ...UserLink_user
                }
                rowId
                updatedAt
                createdAt
                dueAt
                lastModifiedBy {
                  ...UserModal_user
                  ...UserLink_user
                }
                name
              }
            }
          }
          totalCount
        }
      }
    `,
    pageData,
  )

  const paginateLength = usePaginateDataLength(
    paginationFragmentHook?.data?.modules?.edges,
    PAGE_SIZE,
  )

  useEffect(() => {
    if (paginateLength) {
      setDataLength(paginateLength)
    }
  }, [paginateLength])

  return { ...paginationFragmentHook, pageData }
}
