import {
  Divider,
  Layout,
  Tabs,
  Button,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { useAtom } from 'jotai'
import { useEffect } from 'react'
import { graphql } from 'react-relay'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { useSubscriptionRefreshQuery } from '../../../hooks/useRefresh'
import { SuspenseRingLoader } from '../../../loadingComponents/SuspenseRingLoader'
import { UUID } from '../../../react-app-env'
import { titleAtom } from '../../../state/atoms'
import { CommentsTab } from '../../Modules/CommentsTab'
import { OverviewTab } from '../../Modules/OverviewTab'
import './ReviewerOverview.scss'
import { ReviewerTab } from './ReviewerTab'
import { ReviewerOverviewQuery } from './__generated__/ReviewerOverviewQuery.graphql'
import { HistoryTab } from '../../Modules/HistoryTab'
import { Link } from 'react-router-dom'
import { useGwtUser } from '../../../hooks'
import EnterpriseIcon, { PencilIcon } from '@enterprise-ui/icons'
import { SubmissionLock } from './SubmissionLock'

export const ReviewerOverview = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const user = useGwtUser()

  const [, setTitle] = useAtom(titleAtom)
  const { moduleId } = useParams()

  const data = useReviewerOverviewQuery(moduleId)

  useEffect(() => {
    setTitle('Reviewer')
  })

  useEffect(() => {
    if (!location.hash) {
      navigate(`${location.pathname}#review`, { replace: true })
    }
  }, [location, navigate])

  let assigneeOverview = `/assignee/modules/${moduleId}#overview`
  let editModule = '/reviewer/modules/edit'

  const tab = location.hash.replace('#', '')

  return (
    <Layout.Body
      className="hc-pa-none hc-ma-none reviewer-overview"
      includeRail
    >
      <div>
        <div className="small-width">
          <div className="hc-pt-dense">
            <Link
              to={assigneeOverview}
              style={{
                color: '#4974D4',
                fontSize: '14px',
                fontWeight: 'bold',
                paddingRight: '30%',
              }}
            >
              Assignee View
            </Link>
            <div className="lock-button">
              <Tooltip
                content={
                  data.module?.submissionLock
                    ? 'Unlock submissions'
                    : 'Lock submissions'
                }
                location="right"
              >
                <SubmissionLock
                  flowRef={moduleId}
                  lockRef={data.module?.submissionLock}
                />
              </Tooltip>
            </div>
            {user.userType === 'ADMIN' ? (
              <div className="edit-button">
                <Tooltip content="Edit assignment" location="left">
                  <Button
                    type="secondary"
                    size="normal"
                    iconOnly
                    onClick={() => {
                      navigate(editModule, {
                        state: {
                          moduleFlowId: moduleId,
                          prevUrl: location.pathname,
                        },
                      })
                    }}
                  >
                    <EnterpriseIcon size="lg" icon={PencilIcon} />
                  </Button>
                </Tooltip>
              </div>
            ) : (
              ''
            )}
          </div>
          <div className="module-title-text">
            {data.module?.flow && data.module.flow.name}
          </div>
          {data.module?.flow?.assignmentStatus === 'READY_FOR_REVIEW' ||
          data.module?.flow?.assignmentStatus === 'REJECTED' ||
          data.module?.flow?.assignmentStatus === 'ACCEPTED' ? (
            <div className="module-name-text">
              Submitted by {data?.module?.flow?.assignedTo?.fullName}
            </div>
          ) : (
            <div className="module-name-text">
              Assigned to {data?.module?.flow?.assignedTo?.fullName}
            </div>
          )}
          <div className="hc-mt-md">
            <Tabs
              size="dense"
              activeTab={tab}
              defaultTab="review"
              className="tabs"
              onTabSelect={(event: any, tab: any) => {
                navigate(`${location.pathname}#${tab.name}`)
              }}
            >
              <Tabs.Item name="review" className="big-tab">
                Review
              </Tabs.Item>
              <Tabs.Item name="overview" className="big-tab">
                Overview
              </Tabs.Item>
              <Tabs.Item name="comments" className="big-tab">
                Comments
              </Tabs.Item>
              <Tabs.Item name="history" className="big-tab">
                History
              </Tabs.Item>
            </Tabs>
          </div>
        </div>
        <Divider className="divider hc-mb-xl" />
        {tab === 'review' && (
          <SuspenseRingLoader>
            <ReviewerTab moduleRef={data.module} />
          </SuspenseRingLoader>
        )}
        {tab === 'overview' && (
          <SuspenseRingLoader>
            <OverviewTab moduleRef={data.module} />
          </SuspenseRingLoader>
        )}
        {tab === 'comments' && (
          <SuspenseRingLoader>
            <CommentsTab moduleRef={data.module} id={moduleId} />
          </SuspenseRingLoader>
        )}
        {tab === 'history' && (
          <SuspenseRingLoader>
            <HistoryTab moduleRef={data.module} id={moduleId} />
          </SuspenseRingLoader>
        )}
      </div>
    </Layout.Body>
  )
}

const useReviewerOverviewQuery = (moduleFlowId: UUID | undefined) =>
  useSubscriptionRefreshQuery<ReviewerOverviewQuery>(
    graphql`
      query ReviewerOverviewQuery($flowId: UUID!, $skip: Boolean!) {
        module(flowId: $flowId) @skip(if: $skip) {
          submissionLock
          flow {
            id
            name
            assignmentStatus
            assignedTo {
              fullName
            }
          }
          ...OverviewTab_module
          ...CommentsTab_moduleComments
          ...ReviewerTab_module
          ...HistoryTab_module
        }
      }
    `,
    { flowId: moduleFlowId || '', skip: !moduleFlowId },
    {
      component: 'REVIEWER_OVERVIEW_TAB',
      uniqueComponentId: `${moduleFlowId}`,
      onNotification: ({ name, data }) => {
        if (
          [
            'ASSIGNEE_MODULE_COMMENT_ADD_NOTIFICATION',
            'ASSIGNEE_MODULE_COMMENT_REPLY_NOTIFICATION',
            'ASSIGNEE_MODULE_SUBMIT_NOTIFICATION',
          ].includes(name)
        ) {
          return data?.flowId === moduleFlowId
        }
        if (
          name === 'ASSIGNEE_MODULE_COMMENT_EDIT_NOTIFICATION' ||
          name === 'ASSIGNEE_MODULE_COMMENT_DELETE_NOTIFICATION'
        ) {
          return true
        }

        return false
      },
      includeSelfNotifications: true,
    },
  )
