import { UserType } from '../hooks/__generated__/useGwtUser_userTypeQuery.graphql'
import { UserStatus } from '../views/Comments/__generated__/CommentAddMutation.graphql'

export type RoleType = 'Administrator' | 'Reviewer' | 'Assignee' | 'Unknown'
export const getUserRoleFromUserType = (
  userType: UserType | null | undefined,
): RoleType => {
  switch (userType) {
    case 'ADMIN':
      return 'Administrator'
    case 'REVIEWER':
      return 'Reviewer'
    case 'EXTERNAL_USER':
      return 'Assignee'
    default:
      return 'Unknown'
  }
}

export const getUserPriorityFromValue = (value: number | undefined): string => {
  switch (value) {
    case 0:
      return 'Lowest'
    case 1:
      return 'Low'
    case 2:
      return 'Medium'
    case 3:
      return 'High'
    case 4:
      return 'Critical'
    default:
      return 'Unknown'
  }
}

export const getUserStatusFromStatus = (
  userStatus: UserStatus | null | undefined,
): string => {
  switch (userStatus) {
    case 'ACTIVE':
      return 'Active'
    case 'DEACTIVATED':
      return 'Deactivated'
    case 'SUSPENDED':
      return 'Suspended'
    default:
      return 'Unknown'
  }
}

export const getControlledUserSelectObject = (
  rowId: string,
  firstName: string,
  lastName: string,
  companyName: string | undefined,
): {
  value: string
  label: string
} => {
  return {
    value: rowId,
    label: `${firstName} ${lastName} | ${companyName || 'No company'}`,
  }
}

export const getControlledUserSelectDefaultUnnassigned = (): {
  value: string
  label: string
} => {
  return { value: 'unassigned', label: 'Unassigned' }
}

export const getControlledUserSelectDefaultGroupTemplate = (): {
  value: string
  label: string
} => {
  return { value: 'unassigned', label: 'Default to module reviewer' }
}

export const getControlledUserSelectObjectOrDefault = (
  rowId?: string,
  firstName?: string,
  lastName?: string,
  companyName?: string | undefined,
): {
  value: string
  label: string
} => {
  if (!rowId || !firstName) {
    return getControlledUserSelectDefaultUnnassigned()
  }
  return {
    value: rowId,
    label: `${firstName} ${lastName} | ${companyName || 'No company'}`,
  }
}

export const hashString = (s?: string): number => {
  if (s === undefined) return 0
  return s.split('').reduce(function (a, b) {
    a = (a << 5) - a + b.charCodeAt(0)
    return a & a
  }, 0)
}
