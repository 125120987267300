import { Card, Heading, Table } from '@enterprise-ui/canvas-ui-react'
import { graphql, usePaginationFragment } from 'react-relay'
import './GroupTemplateModules.scss'
import React, { SyntheticEvent, useEffect, useState } from 'react'

import { merge } from 'lodash'
import { TableSortHeader } from '../../../GenericComponents/TableSortHeader'
import { TextInputDebounce } from '../../../GenericComponents/TextInputDebounce/TextInputDebounce'
import { NOTIFICATIONS } from '../../../hooks'
import { usePaginateDataLength } from '../../../hooks/usePaginateDataLength'
import { useSubscriptionRefreshQuery } from '../../../hooks/useRefresh'
import { getGwtFormattedDate } from '../../../util/dateUtils'
import { ModulesOrderBy } from '../../AssignmentsTable/__generated__/AssignmentsTablePaginationQuery.graphql'
import { ModuleFilter } from '../../Reviewer/__generated__/ReviewerStatusCardPaginationQuery.graphql'
import { GroupTemplateModulesTablePaginationFragment_modules$key } from './__generated__/GroupTemplateModulesTablePaginationFragment_modules.graphql'
import { GroupTemplateModulesTablePaginationQuery } from './__generated__/GroupTemplateModulesTablePaginationQuery.graphql'
import { getModuleProgramTypeText } from '../../../util/moduleUtils'
import { Input } from '@enterprise-ui/canvas-ui-react'
import { useFormContext } from 'react-hook-form'

const PAGE_SIZE = 20
const BASE_FILTER: ModuleFilter = {
  flow: {
    flowType: { equalTo: 'BLUEPRINT' },
  },
}
export const GroupTemplateModulesTable = () => {
  const [searchFilter, setSearchFilter] = useState<ModuleFilter>({
    flow: {
      or: [
        { name: { likeInsensitive: null } },
        { owner: { fullName: { likeInsensitive: null } } },
      ],
    },
  })

  const [combinedFilter, setCombinedFilter] = useState<ModuleFilter>(
    merge({}, BASE_FILTER, searchFilter),
  )

  const [orderBy, setOrderBy] = useState<ModulesOrderBy>(
    'FLOW_BY_FLOW_ID__UPDATED_AT_DESC',
  )

  const { data, isLoadingNext, hasNext, loadNext } =
    useGroupTemplateModulesTableQuery(combinedFilter, [orderBy])

  const { setValue, watch } = useFormContext()

  const modules = watch('modules')

  const updateModules = (event: any, rowId: any, data: {}) => {
    let values: any = []
    if (modules) {
      values = [...modules]
    }
    const foundValue = !!values.find((obj: any) => obj.rowId === rowId)
    if (event.target.checked && !foundValue) {
      values.push(data)
    } else if (foundValue) {
      let index = values.findIndex((obj: any) => obj.rowId === rowId)
      if (index !== -1) {
        values.splice(index, 1)
      }
    }
    setValue('modules', values, { shouldTouch: true })
  }

  useEffect(() => {
    setCombinedFilter(merge({}, BASE_FILTER, searchFilter))
  }, [searchFilter])

  const scrollEvent = (e: SyntheticEvent) => {
    if (isLoadingNext || !hasNext) {
      return
    }
    const target = e.target as HTMLTextAreaElement

    const scrollLeft = target.scrollHeight - target.scrollTop
    if (scrollLeft / target.clientHeight < 2) {
      loadNext(PAGE_SIZE)
    }
  }

  const updateSearchFilter = (debounced: string) => {
    if (debounced.length > 0) {
      const update = `%${debounced}%`
      setSearchFilter({
        flow: {
          or: [
            { name: { likeInsensitive: update } },
            {
              owner: { fullName: { likeInsensitive: update } },
            },
          ],
        },
      })
    } else {
      setSearchFilter({
        flow: {
          or: [
            { name: { likeInsensitive: null } },
            { owner: { fullName: { likeInsensitive: null } } },
          ],
        },
      })
    }
  }

  return (
    <Card className="hc-pa-min">
      <Table name="Templates" cellPadding="normal" height="47vh">
        <Table.Head className="sticky-header">
          <Table.Row align="center">
            <Table.Header className="hc-mb-min" xs={7}>
              <Heading size={5}>Modules</Heading>
            </Table.Header>
            <Table.Header xs={5}>
              <TextInputDebounce
                delay={500}
                placeholder="Search"
                onUpdate={updateSearchFilter}
              />
            </Table.Header>
          </Table.Row>
          <Table.Row align="center">
            <Table.Header xs={1} />
            <TableSortHeader
              xs={4}
              selectedOrderBy={orderBy}
              onSort={setOrderBy}
              sortUpFilter="FLOW_BY_FLOW_ID__NAME_ASC"
              sortDownFilter="FLOW_BY_FLOW_ID__NAME_DESC"
            >
              Template name
            </TableSortHeader>
            <TableSortHeader
              xs={2}
              selectedOrderBy={orderBy}
              onSort={setOrderBy}
              sortUpFilter="FLOW_BY_FLOW_ID__UPDATED_AT_ASC"
              sortDownFilter="FLOW_BY_FLOW_ID__UPDATED_AT_DESC"
            >
              Last updated
            </TableSortHeader>
            <TableSortHeader
              xs={2}
              selectedOrderBy={orderBy}
              onSort={setOrderBy}
              sortUpFilter="SOFTWARE_TYPE_ASC"
              sortDownFilter="SOFTWARE_TYPE_DESC"
            >
              Software
            </TableSortHeader>
            <TableSortHeader
              xs={3}
              selectedOrderBy={orderBy}
              onSort={setOrderBy}
              sortUpFilter="PROGRAM_TYPE_ASC"
              sortDownFilter="PROGRAM_TYPE_DESC"
            >
              Program
            </TableSortHeader>
            <Table.Header />
          </Table.Row>
        </Table.Head>
        <Table.Body onScroll={scrollEvent}>
          {data?.modules?.edges?.map(
            ({ node: { flow, softwareType, programType } }) => {
              return (
                <div key={flow?.rowId}>
                  <Table.Row align="center" key={flow?.rowId}>
                    <Table.Data xs={1}>
                      <Input.Checkbox
                        id={flow?.rowId}
                        checked={
                          !!modules?.find(
                            (obj: any) => obj.rowId === flow?.rowId,
                          )
                        }
                        onChange={(event: any) => {
                          let data = {
                            softwareType,
                            programType,
                            name: flow?.name,
                            updatedAt: flow?.updatedAt,
                            rowId: flow?.rowId,
                            reviewers:
                              flow?.flowReviewers.nodes.map(
                                (rev) => rev.reviewer?.fullName,
                              ) || [],
                            durationInSeconds: flow?.durationInSeconds,
                          }
                          updateModules(event, flow?.rowId, data)
                        }}
                      />
                    </Table.Data>
                    <Table.Data xs={4}>{flow?.name}</Table.Data>

                    <Table.Data xs={2}>
                      {getGwtFormattedDate(flow?.updatedAt)}
                    </Table.Data>
                    <Table.Data xs={2}>{softwareType}</Table.Data>
                    <Table.Data xs={3}>
                      {getModuleProgramTypeText(programType)}
                    </Table.Data>
                  </Table.Row>
                </div>
              )
            },
          )}
        </Table.Body>
      </Table>
    </Card>
  )
}

const useGroupTemplateModulesTableQuery = (
  moduleFilter: ModuleFilter | undefined = undefined,
  modulesOrderBy: [ModulesOrderBy] | undefined = undefined,
) => {
  const [dataLength, setDataLength] = useState(PAGE_SIZE)

  const pageData =
    useSubscriptionRefreshQuery<GroupTemplateModulesTablePaginationQuery>(
      graphql`
        query GroupTemplateModulesTablePaginationQuery(
          $moduleFilter: ModuleFilter
          $modulesOrderBy: [ModulesOrderBy!]
          $count: Int!
        ) {
          ...GroupTemplateModulesTablePaginationFragment_modules
            @arguments(
              moduleFilter: $moduleFilter
              modulesOrderBy: $modulesOrderBy
              count: $count
            )
        }
      `,
      {
        moduleFilter,
        modulesOrderBy,
        count: dataLength,
      },
      {
        component: 'GROUP_TEMPLATES_MODULES_TABLE',
        uniqueComponentId: '',
        onNotification: [...NOTIFICATIONS],
        includeSelfNotifications: true,
      },
    )

  const paginationFragmentHook = usePaginationFragment<
    GroupTemplateModulesTablePaginationQuery,
    GroupTemplateModulesTablePaginationFragment_modules$key
  >(
    graphql`
      fragment GroupTemplateModulesTablePaginationFragment_modules on Query
      @argumentDefinitions(
        moduleFilter: { type: "ModuleFilter" }
        modulesOrderBy: { type: "[ModulesOrderBy!]" }
        count: { type: "Int" }
        cursor: { type: "Cursor" }
      )
      @refetchable(
        queryName: "GroupTemplateModulesTablePaginationQuery_Refetchable"
      ) {
        modules(
          filter: $moduleFilter
          orderBy: $modulesOrderBy
          first: $count
          after: $cursor
        ) @connection(key: "GroupTemplateModulesTable_modules") {
          edges {
            node {
              programType
              softwareType
              flow {
                rowId
                updatedAt
                name
                durationInSeconds
                flowReviewers {
                  nodes {
                    reviewer {
                      fullName
                    }
                  }
                }
              }
            }
          }
          totalCount
        }
      }
    `,
    pageData,
  )

  const paginateLength = usePaginateDataLength(
    paginationFragmentHook?.data?.modules?.edges,
    PAGE_SIZE,
  )

  useEffect(() => {
    if (paginateLength) {
      setDataLength(paginateLength)
    }
  }, [paginateLength])

  return { ...paginationFragmentHook, pageData }
}
