import {
  Button,
  Card,
  Chip,
  Heading,
  Layout,
  Table,
} from '@enterprise-ui/canvas-ui-react'
import { graphql, usePaginationFragment } from 'react-relay'
import { useSubscriptionRefreshQuery } from '../../hooks/useRefresh'
import './UserProfileTable.scss'
import React, { SyntheticEvent, useState } from 'react'
import { TextInputDebounce } from '../../GenericComponents/TextInputDebounce/TextInputDebounce'
import { useEffect } from 'react'
import { NOTIFICATIONS } from '../../hooks'
import { usePaginateDataLength } from '../../hooks/usePaginateDataLength'
import { UserProfileTablePaginationFragment_gwtUsers$key } from './__generated__/UserProfileTablePaginationFragment_gwtUsers.graphql'
import { getGwtFormattedDate } from '../../util/dateUtils'
import { TableSortHeader } from '../../GenericComponents/TableSortHeader'
import {
  GwtUserFilter,
  GwtUsersOrderBy,
  UserProfileTablePaginationQuery,
} from './__generated__/UserProfileTablePaginationQuery.graphql'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { merge } from 'lodash'
import { Link } from 'react-router-dom'
import {
  getUserRoleFromUserType,
  getUserStatusFromStatus,
} from '../../util/userUtils'
import { UserAvatar } from '../Users/UserAvatar'
import { UserOptions } from '../Users/UserOptions/UserOptions'
import { UserProgramsDisplay } from '../Users/UserProgramsDisplay/UserProgramsDisplay'
import {
  GwtUserFilterChip,
  GwtUserFilterDropdown,
} from './GwtUserFilterDropdown'
import { UserPriority } from '../Users/UserPriority/UserPriority'

const PAGE_SIZE = 20

export const UserProfileTable = () => {
  const [dropdownFilter, setDropdownFilter] = useState<GwtUserFilter>({})

  const [searchFilter, setSearchFilter] = useState<GwtUserFilter>({
    or: [
      { firstName: { likeInsensitive: undefined } },
      {
        lastName: { likeInsensitive: undefined },
      },
      { company: { name: { likeInsensitive: undefined } } },
      { jobTitle: { likeInsensitive: undefined } },
    ],
  })

  const [filterChips, setFilterChips] = useState<GwtUserFilterChip[]>()

  const combinedFilter = merge({}, dropdownFilter, searchFilter)

  const [orderBy, setOrderBy] = useState<GwtUsersOrderBy>('FIRST_NAME_DESC')

  const { data, isLoadingNext, hasNext, loadNext, pageData } =
    useUserProfileTableQuery(combinedFilter, [orderBy])

  const scrollEvent = (e: SyntheticEvent) => {
    if (isLoadingNext || !hasNext) {
      return
    }
    const target = e.target as HTMLTextAreaElement
    const scrollLeft = target.scrollHeight - target.scrollTop
    if (scrollLeft / target.clientHeight < 2) {
      loadNext(PAGE_SIZE)
    }
  }

  const updateSearchFilter = (debounced: string) => {
    if (debounced.length > 0) {
      const update = `%${debounced}%`
      setSearchFilter({
        or: [
          { firstName: { likeInsensitive: update } },
          {
            lastName: { likeInsensitive: update },
          },
          {
            company: {
              name: {
                likeInsensitive: update,
              },
            },
          },
          { jobTitle: { likeInsensitive: update } },
        ],
      })
    } else {
      setSearchFilter({
        or: [
          { firstName: { likeInsensitive: undefined } },
          {
            lastName: { likeInsensitive: undefined },
          },
          {
            company: {
              name: {
                likeInsensitive: undefined,
              },
            },
          },
          { jobTitle: { likeInsensitive: undefined } },
        ],
      })
    }
  }
  return (
    <Layout.Body includeRail className="user-profile-table">
      <Card className="card">
        <Table name="Profiles" cellPadding="normal">
          <Table.Head>
            <Table.Row align="center">
              <Table.Header xs={1}>
                <Heading size={5}>Profiles</Heading>
              </Table.Header>
              <Table.Header style={{ paddingLeft: '0px' }} xs={3}>
                <TextInputDebounce
                  delay={500}
                  placeholder="Search"
                  onUpdate={updateSearchFilter}
                />
              </Table.Header>
              <Table.Header className="table-header-spacing" xs={1}>
                <GwtUserFilterDropdown
                  queryRef={pageData}
                  onChipsUpdated={(chips) => setFilterChips(chips)}
                  onFilterUpdated={(filter) => setDropdownFilter(filter)}
                >
                  <Button
                    className="table-header-spacing"
                    type="secondary"
                    size="dense"
                  >
                    <FontAwesomeIcon icon={faFilter} className="hc-mr-xs" />
                    Add filters
                  </Button>
                </GwtUserFilterDropdown>
              </Table.Header>
              <Table.Header xs className="chips">
                <div>
                  {filterChips?.map((filterChip) => {
                    return (
                      <Chip
                        size="dense"
                        onRequestDelete={filterChip.remove}
                        key={filterChip.key + filterChip.value}
                      >
                        {`${filterChip.key}: ${filterChip.value}`}
                      </Chip>
                    )
                  })}
                  {filterChips && filterChips.length > 0 && (
                    <div
                      className="clear-all"
                      onClick={() => {
                        filterChips?.forEach((chip) => chip.remove())
                      }}
                    >
                      Clear all
                    </div>
                  )}
                </div>
              </Table.Header>
            </Table.Row>
            <Table.Row align="center">
              <Table.Header xs={1} />
              <TableSortHeader
                xs={1}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="FIRST_NAME_ASC"
                sortDownFilter="FIRST_NAME_DESC"
              >
                First name
              </TableSortHeader>
              <TableSortHeader
                xs={1}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="LAST_NAME_ASC"
                sortDownFilter="LAST_NAME_DESC"
              >
                Last name
              </TableSortHeader>
              <TableSortHeader
                xs={2}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="COMPANY_BY_COMPANY_ID__NAME_ASC"
                sortDownFilter="COMPANY_BY_COMPANY_ID__NAME_DESC"
              >
                Company
              </TableSortHeader>
              <TableSortHeader
                xs={1}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="USER_PRIORITY_ASC"
                sortDownFilter="USER_PRIORITY_DESC"
              >
                Priority
              </TableSortHeader>
              <TableSortHeader
                xs={1}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="JOB_TITLE_ASC"
                sortDownFilter="JOB_TITLE_DESC"
              >
                Job title
              </TableSortHeader>
              <TableSortHeader
                xs={1}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="PROGRAMS_ASC"
                sortDownFilter="PROGRAMS_DESC"
              >
                Program
              </TableSortHeader>
              <TableSortHeader
                xs={1}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="USER_TYPE_ASC"
                sortDownFilter="USER_TYPE_DESC"
              >
                Role
              </TableSortHeader>
              <TableSortHeader
                xs={1}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="CREATED_AT_ASC"
                sortDownFilter="CREATED_AT_DESC"
              >
                Date joined
              </TableSortHeader>
              <TableSortHeader
                xs={1}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="STATUS_ASC"
                sortDownFilter="STATUS_DESC"
              >
                Status
              </TableSortHeader>

              <Table.Header />
            </Table.Row>
          </Table.Head>
          <Table.Body onScroll={scrollEvent} className="overflow">
            {data?.gwtUsers?.edges?.map(
              ({
                node: {
                  firstName,
                  lastName,
                  userType,
                  createdAt,
                  rowId,
                  company,
                  programs,
                  jobTitle,
                  status,
                  userPriority,
                  ...node
                },
              }) => {
                return (
                  <Table.Row align="center" key={rowId}>
                    <Table.Data xs={1}>
                      <UserAvatar
                        userRef={node}
                        showName={false}
                        aria-label={''}
                        children={undefined}
                      />
                    </Table.Data>
                    <Table.Data xs={1}>
                      <Link className="link-hover" to={`/users/${rowId}`}>
                        {firstName}
                      </Link>
                    </Table.Data>
                    <Table.Data xs={1}>
                      <Link className="link-hover" to={`/users/${rowId}`}>
                        {lastName}
                      </Link>
                    </Table.Data>
                    <Table.Data className="capitalize" xs={2}>
                      {company?.name && (
                        <Link
                          className="link-hover"
                          to={`/companies/${company.rowId}`}
                        >
                          {company.name}
                        </Link>
                      )}
                      {!company?.name && 'None set'}
                    </Table.Data>
                    <Table.Data xs={1}>
                      <UserPriority className="hc-ml-sm" userRef={node} />
                    </Table.Data>
                    <Table.Data className="capitalize" xs={1}>
                      {jobTitle || 'None set'}
                    </Table.Data>
                    <Table.Data className="capitalize" xs={1}>
                      <UserProgramsDisplay userRef={node} />
                    </Table.Data>
                    <Table.Data xs={1}>
                      {getUserRoleFromUserType(userType)}
                    </Table.Data>
                    <Table.Data xs={1}>
                      {getGwtFormattedDate(createdAt)}
                    </Table.Data>
                    <Table.Data className="capitalize" xs={1}>
                      {getUserStatusFromStatus(status)}
                    </Table.Data>
                    <Table.Data className="end">
                      <UserOptions userRef={node} />
                    </Table.Data>
                  </Table.Row>
                )
              },
            )}
          </Table.Body>
        </Table>
      </Card>
    </Layout.Body>
  )
}

const useUserProfileTableQuery = (
  gwtUserFilter: GwtUserFilter | undefined = undefined,
  gwtUsersOrderBy: [GwtUsersOrderBy] | undefined = undefined,
) => {
  const [dataLength, setDataLength] = useState(PAGE_SIZE)

  const pageData = useSubscriptionRefreshQuery<UserProfileTablePaginationQuery>(
    graphql`
      query UserProfileTablePaginationQuery(
        $gwtUserFilter: GwtUserFilter
        $gwtUsersOrderBy: [GwtUsersOrderBy!]
        $count: Int!
      ) {
        ...GwtUserFilterDropdown
        ...UserProfileTablePaginationFragment_gwtUsers
          @arguments(
            gwtUserFilter: $gwtUserFilter
            gwtUsersOrderBy: $gwtUsersOrderBy
            count: $count
          )
      }
    `,
    {
      gwtUserFilter,
      gwtUsersOrderBy,
      count: dataLength,
    },
    {
      component: 'PROFILES_TABLE',
      uniqueComponentId: '',
      onNotification: [...NOTIFICATIONS],
      includeSelfNotifications: true,
    },
  )

  const paginationFragmentHook = usePaginationFragment<
    UserProfileTablePaginationQuery,
    UserProfileTablePaginationFragment_gwtUsers$key
  >(
    graphql`
      fragment UserProfileTablePaginationFragment_gwtUsers on Query
      @argumentDefinitions(
        gwtUserFilter: { type: "GwtUserFilter" }
        gwtUsersOrderBy: { type: "[GwtUsersOrderBy!]" }
        count: { type: "Int" }
        cursor: { type: "Cursor" }
      )
      @refetchable(queryName: "UserProfileTablePaginationQuery_Refetchable") {
        gwtUsers(
          filter: $gwtUserFilter
          orderBy: $gwtUsersOrderBy
          first: $count
          after: $cursor
        ) @connection(key: "UserProfileTable_gwtUsers") {
          edges {
            node {
              rowId
              firstName
              lastName
              userType
              createdAt
              programs
              userPriority
              company {
                name
                rowId
              }
              jobTitle
              status
              ...UserPriority_gwtUser
              ...UserAvatar_gwtUser
              ...UserOptions_gwtUser
              ...UserProgramsDisplay
            }
          }
          totalCount
        }
      }
    `,
    pageData,
  )

  const paginateLength = usePaginateDataLength(
    paginationFragmentHook?.data?.gwtUsers?.edges,
    PAGE_SIZE,
  )

  useEffect(() => {
    if (paginateLength) {
      setDataLength(paginateLength)
    }
  }, [paginateLength])

  return { ...paginationFragmentHook, pageData }
}
