/**
 * @generated SignedSource<<4d1f5ac1fd7fac7f76c16b6d59a9bdb0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProgramType = "ACCESSORIES_3_D_ACCREDITATION" | "A_A_3_D_ACCREDITATION" | "NONE" | "SOFT_HOME_3_D_ACCREDITATION" | "_3_D_STYLING" | "%future added value";
export type SoftwareType = "BROWZWEAR" | "CLO" | "NONE" | "OPTITEX" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type GroupTemplatePreviewButton_Fragment$data = {
  readonly flow: {
    readonly name: string;
    readonly rowId: UUID;
  } | null;
  readonly moduleGroupLinksByModuleGroupFlowId: {
    readonly nodes: ReadonlyArray<{
      readonly moduleFlow: {
        readonly flow: {
          readonly durationSeconds: number;
          readonly name: string;
          readonly updatedAt: Date | null;
        } | null;
        readonly programType: ProgramType | null;
        readonly softwareType: SoftwareType | null;
      } | null;
    }>;
  };
  readonly reviewers: {
    readonly nodes: ReadonlyArray<{
      readonly fullName: string;
    }>;
  };
  readonly " $fragmentType": "GroupTemplatePreviewButton_Fragment";
};
export type GroupTemplatePreviewButton_Fragment$key = {
  readonly " $data"?: GroupTemplatePreviewButton_Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"GroupTemplatePreviewButton_Fragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GroupTemplatePreviewButton_Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "GwtUsersConnection",
      "kind": "LinkedField",
      "name": "reviewers",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GwtUser",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fullName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ModuleGroupLinksConnection",
      "kind": "LinkedField",
      "name": "moduleGroupLinksByModuleGroupFlowId",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ModuleGroupLink",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Module",
              "kind": "LinkedField",
              "name": "moduleFlow",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "softwareType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "programType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Flow",
                  "kind": "LinkedField",
                  "name": "flow",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "updatedAt",
                      "storageKey": null
                    },
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "durationSeconds",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Flow",
      "kind": "LinkedField",
      "name": "flow",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rowId",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ModuleGroup",
  "abstractKey": null
};
})();

(node as any).hash = "5bf41729deb18426790f8a36a23f96a8";

export default node;
