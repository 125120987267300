import { Card, ExpandableSection } from '@enterprise-ui/canvas-ui-react'
import { graphql, useFragment } from 'react-relay'
import { getGwtFormattedDate } from '../../util/dateUtils'

import { GalleryAssetFilesDisplay } from './GalleryAssetFilesDisplay'
import { SubmittedAssetFilesDisplay_Fragment$key } from './__generated__/SubmittedAssetFilesDisplay_Fragment.graphql'

interface SubmittedAssetFilesDisplayProps {
  moduleRef: SubmittedAssetFilesDisplay_Fragment$key | null | undefined
  title?: string
  enableDeletes?: boolean
  firstExpanded?: boolean
}

export const SubmittedAssetFilesDisplay = ({
  enableDeletes = true,
  firstExpanded = true,
  ...props
}: SubmittedAssetFilesDisplayProps) => {
  const data = useFragment(
    graphql`
      fragment SubmittedAssetFilesDisplay_Fragment on Module {
        flow {
          assignedTo {
            fullName
          }
        }
        moduleSubmissionsByModuleFlowId(orderBy: ATTEMPT_DESC) {
          nodes {
            attempt
            galleryAssetSubmissionId
            createdAt
          }
        }
      }
    `,
    props.moduleRef || null,
  )

  if (!data) {
    return <></>
  }

  if (data?.moduleSubmissionsByModuleFlowId?.nodes?.length === 0) {
    return <div className="error-color"> No submissions found. </div>
  }

  return (
    <>
      {data?.moduleSubmissionsByModuleFlowId?.nodes?.map((submission, i) => {
        return (
          <Card className="submitted-files hc-mb-lg" key={i}>
            <ExpandableSection
              className="expandable"
              padding="none"
              expanded={firstExpanded && i === 0}
            >
              <p className="submitted-text">
                <span className="hc-mr-dense attempt-text">
                  Attempt #{submission.attempt}
                </span>
                {`Submitted by ${data.flow?.assignedTo
                  ?.fullName} on ${getGwtFormattedDate(submission.createdAt)}`}
              </p>
              <ExpandableSection.Content className="expandable-content hc-pa-sm hc-mb-xs">
                <GalleryAssetFilesDisplay
                  assetId={submission.galleryAssetSubmissionId}
                  enableDeletes={false}
                  displayCreatedBy={false}
                  displayFileNameBelowImage={false}
                  displayFileNameInChip={false}
                  enableSelect={true}
                  enableLightbox={false}
                  showcase
                  thumbnails
                  justify="center"
                  attempt={submission?.attempt}
                />
              </ExpandableSection.Content>
            </ExpandableSection>
          </Card>
        )
      })}
    </>
  )
}
