import {
  Dropdown,
  ExpandableSection,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import { ReactNode, useState } from 'react'
import { GwtUserFilter } from '../Admin/__generated__/StatusCardPaginationQuery.graphql'
import './GwtUserFilterDropdown.scss'

import { useEffect } from 'react'
import { UserType } from '../Users/CreateProfilePage/__generated__/CreateProfilePageQuery.graphql'
import { UserStatus } from '../Users/CreateProfilePage/__generated__/CreateProfilePageUpdateProfileMutation.graphql'
import {
  getUserRoleFromUserType,
  getUserStatusFromStatus,
} from '../../util/userUtils'
import { isEqual } from 'lodash'
import { graphql, useFragment } from 'react-relay'
import { GwtUserFilterDropdown$key } from './__generated__/GwtUserFilterDropdown.graphql'

export type GwtUserFilterChip = {
  key: string
  value: string
  displayOverride?: string
  remove: () => void
}
interface GwtUserFilterProps {
  queryRef: GwtUserFilterDropdown$key | null
  children?: ReactNode
  hideCompany?: boolean
  onFilterUpdated?: (filter: GwtUserFilter) => void
  onChipsUpdated?: (chips: GwtUserFilterChip[]) => void
}

const USER_ROLES: UserType[] = ['ADMIN', 'EXTERNAL_USER', 'REVIEWER']
const USER_STATUSES: UserStatus[] = ['ACTIVE', 'DEACTIVATED', 'SUSPENDED']

export const DEFAULT_USER_FILTER: GwtUserFilter = {
  userType: {
    in: undefined,
  },
  companyId: {
    in: undefined,
  },
  status: {
    in: undefined,
  },
}

export const GwtUserFilterDropdown = ({
  queryRef = null,
  hideCompany = false,
  ...props
}: GwtUserFilterProps) => {
  const data = useFragment(
    graphql`
      fragment GwtUserFilterDropdown on Query {
        companies(orderBy: NAME_ASC) {
          nodes {
            rowId
            name
          }
        }
      }
    `,
    queryRef,
  )
  const [gwtUserFilter, setGwtUserFilter] =
    useState<GwtUserFilter>(DEFAULT_USER_FILTER)
  const [userChips, setUserChips] = useState<GwtUserFilterChip[]>([])

  const [roles, setRoles] = useState<UserType[] | undefined>(undefined)
  const [statuses, setStatuses] = useState<UserStatus[] | undefined>(undefined)
  const [companies, setCompanies] = useState<string[] | undefined>(undefined)

  useEffect(() => {
    const filter: GwtUserFilter = {
      userType: {
        in: roles,
      },
      companyId: {
        in: companies,
      },
      status: {
        in: statuses,
      },
    }
    if (!isEqual(filter, gwtUserFilter)) {
      setGwtUserFilter(filter)
      props.onFilterUpdated?.(filter || {})
    }
  }, [roles, statuses, companies, gwtUserFilter, props])

  // update the user chips
  useEffect(() => {
    const chips: GwtUserFilterChip[] = []

    roles?.forEach((role) => {
      chips.push({
        key: 'Role',
        value: getUserRoleFromUserType(role),
        remove: () => setRoles((arr) => removeChip(arr, role)),
      })
    })

    statuses?.forEach((status) => {
      chips.push({
        key: 'Status',
        value: getUserStatusFromStatus(status),
        remove: () => setStatuses((arr) => removeChip(arr, status)),
      })
    })

    companies?.forEach((company) => {
      chips.push({
        key: 'Company',
        value:
          data?.companies?.nodes.find((n) => n.rowId === company)?.name ||
          'Unknown',
        remove: () => setCompanies((arr) => removeChip(arr, company)),
      })
    })

    if (!areChipsEqual(chips, userChips)) {
      setUserChips(chips)
      props.onChipsUpdated?.(chips || {})
    }
  }, [roles, statuses, companies, userChips, props, data?.companies?.nodes])

  const uniqueCompanyNames = data?.companies?.nodes.map((c) => c.rowId) || []
  return (
    <Dropdown size="normal" location="bottom-right">
      {props.children}
      <Dropdown.Menu className="user-dropdown">
        <Dropdown.MenuItem className="selected expandable-hover">
          <ExpandableSection
            padding="dense"
            onClick={(e: any) => {
              e.stopPropagation()
            }}
          >
            <div className="normal-text">
              <span className="hc-mr-dense">Role</span>
              {roles && roles.length > 0 && (
                <div className="filter-notification-badge">
                  {roles?.length}{' '}
                </div>
              )}
            </div>
            <ExpandableSection.Content>
              <Input.Checkboxes
                id="roleSelect"
                value={roles || []}
                onUpdate={(id: any, value: any) => {
                  setRoles(updateVal(value))
                }}
                options={USER_ROLES.map((role: UserType) => {
                  return {
                    value: role,
                    label: getUserRoleFromUserType(role),
                  }
                })}
              />
            </ExpandableSection.Content>
          </ExpandableSection>
        </Dropdown.MenuItem>

        <Dropdown.MenuItem className="selected expandable-hover">
          <ExpandableSection
            padding="dense"
            onClick={(e: any) => {
              e.stopPropagation()
            }}
          >
            <div className="normal-text">
              <span className="hc-mr-dense">Status</span>
              {statuses && statuses.length > 0 && (
                <div className="filter-notification-badge">
                  {statuses.length}
                </div>
              )}
            </div>
            <ExpandableSection.Content>
              <Input.Checkboxes
                id="statusSelect"
                value={statuses || []}
                onUpdate={(id: any, value: any) => {
                  setStatuses(updateVal(value))
                }}
                options={USER_STATUSES.map((status: UserStatus) => {
                  return {
                    value: status,
                    label: getUserStatusFromStatus(status),
                  }
                })}
              />
            </ExpandableSection.Content>
          </ExpandableSection>
        </Dropdown.MenuItem>

        {!hideCompany && (
          <Dropdown.MenuItem className="selected expandable-hover">
            <ExpandableSection
              padding="dense"
              onClick={(e: any) => {
                e.stopPropagation()
              }}
            >
              <div className="normal-text">
                <span className="hc-mr-dense">Company</span>
                {companies && companies.length > 0 && (
                  <div className="filter-notification-badge">
                    {companies.length}
                  </div>
                )}
              </div>
              <ExpandableSection.Content>
                <Input.Checkboxes
                  className="company-checkboxes"
                  id="companySelect"
                  value={companies || []}
                  onUpdate={(id: any, value: any) => {
                    setCompanies(updateVal(value))
                  }}
                  options={uniqueCompanyNames.map((company: any) => {
                    return {
                      value: company,
                      label:
                        data?.companies?.nodes.find((n) => n.rowId === company)
                          ?.name || 'Unknown',
                    }
                  })}
                />
              </ExpandableSection.Content>
            </ExpandableSection>
          </Dropdown.MenuItem>
        )}
      </Dropdown.Menu>
    </Dropdown>
  )
}

const updateVal = (arr: any) => {
  return arr.length === 0 ? undefined : arr
}

const removeChip = (arr: any, value: any) => {
  const updatedArr = [...arr]
  const index = updatedArr.indexOf(value)
  if (index !== undefined && index > -1) {
    updatedArr.splice(index, 1)
  }
  return updatedArr.length > 0 ? updatedArr : undefined
}

const areChipsEqual = (
  chipsA: GwtUserFilterChip[],
  chipsB: GwtUserFilterChip[],
) => {
  let equal = true
  if (chipsA.length !== chipsB.length) {
    return false
  } else {
    chipsA.forEach((e) => {
      if (
        !chipsB.find((f) => {
          return f.key === e.key && f.value === e.value
        })
      ) {
        equal = false
      }
    })
  }
  return equal
}
