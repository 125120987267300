import {
  Layout,
  Stepper,
  Avatar,
  Form,
  Divider,
  Button,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { useEffect, useState } from 'react'
import { graphql } from 'react-relay'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { UUID } from '../../react-app-env'
import { RichTextEditorContent } from '../../controlledComponents/ControlledRichTextEditor'
import { AssentStickyFooter } from '../../GenericComponents/AssentStickyFooter/AssentStickyFooter'
import { GroupTemplateDetails } from './GroupTemplateDetails/GroupTemplateDetails'
import { GroupTemplateModules } from './GroupTemplateModules/GroupTemplateModules'
import { DndContext } from '@dnd-kit/core'
import { GroupTemplateFinalization } from './GroupTemplateFinalization/GroupTemplateFinalization'
import { useGwtMutation, useGwtUser } from '../../hooks'
import {
  CreateGroupTemplateMutation,
  CreateGroupTemplateMutation$variables,
} from './__generated__/CreateGroupTemplateMutation.graphql'
import './CreateGroupTemplate.scss'
import { SuspenseRingLoader } from '../../loadingComponents/SuspenseRingLoader'
import { useNavigate } from 'react-router-dom'
import { usePublishGlobalNotification } from '../../hooks/useNotifications'

interface CreateGroupTemplateFormValues {
  name: string
  description: RichTextEditorContent
  assignTo: string
  randomize: boolean
  modules: { rowId: UUID }[]
  rowId: UUID
}

type Step = 'details' | 'modules' | 'complete'

export const CreateGroupTemplate = () => {
  const [step, setStep] = useState<Step>('details')
  const formMethods = useForm<CreateGroupTemplateFormValues>({
    defaultValues: {
      modules: [],
      name: '',
      description: { textContent: '', htmlContent: '' },
      assignTo: 'unassigned',
      randomize: false,
    },
  })
  const { handleSubmit, register, unregister, getValues, setValue, watch } =
    formMethods
  const user = useGwtUser()
  const navigate = useNavigate()

  const { publishToLocal } = usePublishGlobalNotification()

  useEffect(() => {
    register('modules')
    return () => unregister('modules')
  }, [register, unregister])

  const goToNextStep = () => {
    if (step === 'details') {
      setStep('modules')
    }
    if (step === 'modules') {
      setStep('complete')
    }
  }

  const goToPreviousStep = () => {
    if (step === 'details') {
      navigate(-1)
    }
    if (step === 'complete') {
      setStep('modules')
    }
    if (step === 'modules') {
      setStep('details')
    }
  }

  const { commit, isInFlight } = useGwtMutation<CreateGroupTemplateMutation>(
    graphql`
      mutation CreateGroupTemplateMutation($input: CreateModuleGroupInput!) {
        createModuleGroup(input: $input) {
          moduleGroup {
            flow {
              name
              description
            }
            moduleGroupLinksByModuleGroupFlowId {
              nodes {
                groupIndex
                id
                moduleFlowId
                moduleGroupFlowId
              }
            }
            shuffle
          }
        }
      }
    `,
  )

  const makeToast = ToastProvider.useToaster()

  const onSubmit: SubmitHandler<CreateGroupTemplateFormValues> = (data) => {
    const links = data?.modules?.map((module, i) => {
      return { groupIndex: i, moduleFlowId: module.rowId }
    })
    const mutation: CreateGroupTemplateMutation$variables = {
      input: {
        moduleGroup: {
          shuffle: false,
          flowToFlowId: {
            create: {
              description: data.description.htmlContent,
              name: data.name,
              flowType: 'BLUEPRINT',
              lastModifiedById: user.rowId,
            },
          },
          moduleGroupLinksUsingFlowId: {
            create: links,
          },
        },
      },
    }
    commit?.(
      mutation,
      () => {
        makeToast({
          type: 'success',
          heading: 'Group Template was created successfully',
          message: 'Group Template was created successfully.',
        })
        publishToLocal('TEMPLATE_CREATE_NOTIFICATION')
        navigate(-1)
      },
      () => {
        makeToast({
          type: 'error',
          heading: 'Group Template creation failed',
          message: 'Group Template creation was unsuccessfully.',
        })
      },
    )
  }

  const modules = getValues('modules')
  const name = watch('name')
  const handleDeleteModule = (module: any) => {
    let values: any = []
    if (modules) {
      values = [...modules]
    }
    const foundValue = !!values.find((obj: any) => obj.rowId === module.rowId)
    if (foundValue) {
      let index = values.findIndex((obj: any) => obj.rowId === module.rowId)
      if (index !== -1) {
        values.splice(index, 1)
      }
    }
    setValue('modules', values)
  }

  return (
    <Layout.Body includeRail className="create-group-template hc-pa-none">
      <DndContext>
        <div className="small-width hc-mt-xl">
          <Stepper activeStep="Ordered">
            <Stepper.Item
              as={'div'}
              index={
                <Avatar
                  color={step === 'details' ? 'violet' : 'default'}
                  aria-label="one"
                >
                  1
                </Avatar>
              }
              name="ordered"
              className="steps"
              completed={step === 'details'}
              onClick={() => setStep('details')}
            >
              <div className="hc-mt-xs">Group template details</div>
            </Stepper.Item>
            <Stepper.Item
              as={'div'}
              index={
                <Avatar
                  color={step === 'modules' ? 'violet' : 'default'}
                  aria-label="two"
                >
                  2
                </Avatar>
              }
              name="ordered"
              className="steps"
              completed={step === 'modules'}
              onClick={() => setStep('modules')}
            >
              <div className="hc-mt-xs">Group template modules</div>
            </Stepper.Item>
            <Stepper.Item
              as={'div'}
              index={
                <Avatar
                  color={step === 'complete' ? 'violet' : 'default'}
                  aria-label="three"
                >
                  3
                </Avatar>
              }
              name="ordered"
              className="steps"
              completed={step === 'complete'}
              onClick={() => setStep('complete')}
            >
              <div className="hc-mt-xs">Review & Create</div>
            </Stepper.Item>
          </Stepper>
        </div>
        <Divider className="hc-mt-xs" />
        <FormProvider {...formMethods}>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className="formLayout">
              {step === 'details' && <GroupTemplateDetails />}
            </div>
            {step === 'complete' && (
              <GroupTemplateFinalization
                modules={modules}
                name={name}
                onDeleteModule={handleDeleteModule}
              />
            )}
            {step === 'modules' && (
              <SuspenseRingLoader size={150} speedMultiplier={3}>
                <GroupTemplateModules onDeleteModule={handleDeleteModule} />
              </SuspenseRingLoader>
            )}
            <AssentStickyFooter className="footer">
              <Button type="secondary" onClick={() => goToPreviousStep()}>
                {step === 'details' ? 'Cancel' : 'Go back'}
              </Button>
              {(step === 'details' || step === 'modules') && (
                <Button type="secondary" onClick={() => goToNextStep()}>
                  Continue
                </Button>
              )}
              {step === 'complete' && (
                <Button
                  type="submit"
                  className="hc-ml-normal"
                  disabled={isInFlight}
                >
                  Create template
                </Button>
              )}
            </AssentStickyFooter>
          </Form>
        </FormProvider>
      </DndContext>
    </Layout.Body>
  )
}
