import { useAuth } from '@praxis/component-auth'
import { useEnv } from '@praxis/component-runtime-env'
import { clearLocalStorage } from '../util/authUtil'

// Our hook
export default function useFetchGraphQL() {
  const auth = useAuth()
  const env = useEnv()

  const apiBaseUrl = env.baseGwtApiUrl
  const accessToken = auth.session?.accessToken
  let idToken = ''

  if (localStorage.getItem('id_token')) {
    idToken = `${localStorage.getItem('id_token')}`
  }

  return async (params: any, variables: any) => {
    console.debug(
      `fetching query ${params.name} with ${JSON.stringify(variables)}`,
    )
    let response: Response

    try {
      response = await fetch(`${apiBaseUrl}/graphql`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json',
          idToken,
        },
        body: JSON.stringify({
          operationName: params.name,
          query: params.text,
          variables,
        }),
      })
    } catch (e) {
      console.error(e)
      throw e
    }

    const data = await response?.json()

    if (response.status === 401) {
      if (data.errors?.[0].resource === 'Go-Proxy') {
        window.location.href = '/'
      } else {
        // This error should be a login issue, clear the user data and logout
        clearLocalStorage()
        auth.logout()
        window.location.href =
          'https://logonservices.iam.target.com/login/responses/accessdenied.html'
      }
    }

    if (response.status !== 200) {
      console.error(`Request failed with: ${response.status}`)
      return {}
    }

    if (data.errors) {
      throw new Error(data.errors[0])
    }

    return data
  }
}
