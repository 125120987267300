import { graphql, useFragment } from 'react-relay'
import './TemplateFavorite.scss'
import { Grid, Popover } from '@enterprise-ui/canvas-ui-react'
import { TemplateOptions } from '../../Templates/TemplateOptions/TemplateOptions'
import { getGwtFormattedDate } from '../../../util/dateUtils'
import { getFlowDurationInWeeks } from '../../../util/flowUtils'
import { TemplateFavorite_flow$key } from './__generated__/TemplateFavorite_flow.graphql'
import { GroupTemplateOptions } from '../../Templates/GroupTemplateOptions/GroupTemplateOptions'
import EnterpriseIcon, { FileIcon, FilesIcon } from '@enterprise-ui/icons'

interface TemplateFavoriteProps {
  flowRef: TemplateFavorite_flow$key | null
}

export const TemplateFavorite = ({ flowRef }: TemplateFavoriteProps) => {
  const data = useFragment(
    graphql`
      fragment TemplateFavorite_flow on Flow
      @argumentDefinitions(gwtUserId: { type: "UUID" }) {
        name
        rowId
        createdAt
        durationInSeconds
        updatedAt
        linkedType
        owner {
          fullName
        }
        lastModifiedBy {
          fullName
        }
        module {
          ...TemplateOptions_module @arguments(gwtUserId: $gwtUserId)
          flowId
          attempts
          versionNumber
        }
        moduleGroup {
          versionNumber
          ...GroupTemplateOptions_moduleGroup @arguments(gwtUserId: $gwtUserId)
          moduleGroupLinksByModuleGroupFlowId {
            nodes {
              moduleFlow {
                flow {
                  rowId
                  name
                }
              }
            }
          }
        }
      }
    `,
    flowRef || null,
  )

  const duration = getFlowDurationInWeeks(data?.durationInSeconds)
  const createdDate = getGwtFormattedDate(data?.createdAt)
  const updatedDate = getGwtFormattedDate(data?.updatedAt)
  const versionNumber = parseFloat(data?.module?.versionNumber).toFixed(1)

  if (data?.linkedType === 'MODULE_GROUP') {
    return (
      <>
        <Grid.Item xs={2}>
          <EnterpriseIcon icon={FilesIcon} size="xl" />
        </Grid.Item>
        <Grid.Item xs={8} className="hc-pl-none">
          <Popover
            className="hc-bg-black hc-clr-white"
            content={
              <div>
                <div>Modules:</div>
                {data.moduleGroup?.moduleGroupLinksByModuleGroupFlowId.nodes.map(
                  (module) => {
                    return (
                      <div key={module.moduleFlow?.flow?.rowId}>
                        {module.moduleFlow?.flow?.name}
                      </div>
                    )
                  },
                )}
              </div>
            }
            location="bottom-left"
          >
            <div>
              <div className="bold-text">{data?.name}</div>
              <div className="sub-text">
                {' '}
                {`Version ${data?.moduleGroup?.versionNumber} - ${updatedDate}`}
              </div>
              <div className="sub-text">
                {`Last edited: ${data?.lastModifiedBy?.fullName}`}
              </div>
            </div>
          </Popover>
        </Grid.Item>
        <Grid.Item xs={2}>
          <GroupTemplateOptions
            groupModuleRef={data?.moduleGroup}
            reverseIconDirection
            tooltipLocation="left"
          />
        </Grid.Item>
      </>
    )
  }
  return (
    <>
      <Grid.Item xs={2}>
        <EnterpriseIcon icon={FileIcon} size="xl" />
      </Grid.Item>
      <Grid.Item xs={8} className="hc-pl-none">
        <Popover
          className="hc-bg-black hc-clr-white"
          content={
            <div>
              <div>
                {`Duration:
            ${duration}
            week(s)`}
              </div>
              <div>{`Number of attempts: ${data?.module?.attempts}`}</div>
              <div>{`Date created: ${createdDate}`}</div>
              <div>
                {`Created by:
            ${data?.owner?.fullName}`}
              </div>
            </div>
          }
          location="bottom-left"
        >
          <div>
            <div className="bold-text">{data?.name}</div>
            <div className="sub-text">
              {`Version ${versionNumber} - ${updatedDate}`}
            </div>
            <div className="sub-text">
              {`Last edited: ${data?.lastModifiedBy?.fullName}`}
            </div>
          </div>
        </Popover>
      </Grid.Item>
      <Grid.Item xs={2}>
        <TemplateOptions moduleRef={data?.module} />
      </Grid.Item>
    </>
  )
}
