import { Button, Spinner, ToastProvider } from '@enterprise-ui/canvas-ui-react'
import { graphql, useFragment } from 'react-relay'
import { SubmitButton_Fragment$key } from './__generated__/SubmitButton_Fragment.graphql'
import { useGwtMutation } from '../../hooks'
import {
  getActivityInputObject,
  getNotificationInputObject,
} from '../../util/relayUtils'
import { activityTypes } from '../../constants'
import { noop } from 'lodash'
import {
  SubmitButtonMutation,
  SubmitButtonMutation$variables,
  SubmitModuleForReviewInput,
} from './__generated__/SubmitButtonMutation.graphql'

interface SubmitButtonProps {
  moduleRef: SubmitButton_Fragment$key | null | undefined
  disabled?: boolean
  onComplete?: () => void
  type?: string
}

export const SubmitButton = ({
  moduleRef = null,
  onComplete = noop,
  disabled = false,
}: SubmitButtonProps) => {
  const makeToast = ToastProvider.useToaster()
  const data = useFragment(
    graphql`
      fragment SubmitButton_Fragment on Module {
        flowId
        flow {
          name
        }
      }
    `,
    moduleRef,
  )

  const { commit, isInFlight } = useGwtMutation<SubmitButtonMutation>(graphql`
    mutation SubmitButtonMutation(
      $input: SubmitModuleForReviewInput!
      $activity: ActivityInput!
      $notificationInput: NotificationInput!
    ) {
      createNotification(input: { notification: $notificationInput }) {
        clientMutationId
      }
      createActivity(input: { activity: $activity }) {
        clientMutationId
      }
      submitModuleForReview(input: $input) {
        module {
          flow {
            id
            assignmentStatus
          }
          ...HistoryTab_module
          moduleSubmissionsByModuleFlowId(orderBy: ATTEMPT_DESC) {
            nodes {
              id
              attempt
              submissionStatus
              galleryAssetSubmissionId
              createdAt
            }
          }
        }
      }
    }
  `)

  const handleComplete = () => {
    makeToast({
      type: 'success',
      heading: 'Module submitted',
      message: 'Successfully submitted module for review.',
      onComplete: () => onComplete(),
    })
  }

  const handleError = () =>
    makeToast({
      type: 'error',
      heading: 'Error',
      message: 'There was an error submitting your module.  Please try again.',
    })

  const submit = () => {
    if (!data || !data.flow) return
    const mutation = {} as SubmitButtonMutation$variables

    mutation.input = {} as SubmitModuleForReviewInput
    mutation.input.moduleFlowId = data.flowId

    mutation.activity = getActivityInputObject(
      activityTypes.SUBMITTED_A_MODULE,
      data.flow.name,
      data.flowId,
      `/modules/${data.flowId}`,
    )

    mutation.notificationInput = getNotificationInputObject(
      'ASSIGNEE_MODULE_SUBMIT_NOTIFICATION',
      { flowId: data.flowId },
    )

    commit(mutation, handleComplete, handleError)
  }

  if (isInFlight) {
    return <Spinner />
  }

  return (
    <Button type="submit" disabled={!data?.flowId || disabled} onClick={submit}>
      Submit
    </Button>
  )
}
