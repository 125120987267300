import { useAtom } from 'jotai'
import { useCallback } from 'react'
import { graphql } from 'relay-runtime'
import { NOTIFICATION, useGwtMutation } from '.'
import { globalNotificationAtom } from '../state/atoms'
import { getNotificationInputObject } from '../util/relayUtils'
import {
  useNotificationsMutation,
  useNotificationsMutation$variables,
} from './__generated__/useNotificationsMutation.graphql'

export const usePublishGlobalNotification = () => {
  const [, publishNotification] = useAtom(globalNotificationAtom)

  const { commit } = useGwtMutation<useNotificationsMutation>(graphql`
    mutation useNotificationsMutation($notificationInput: NotificationInput!) {
      createNotification(input: { notification: $notificationInput }) {
        clientMutationId
      }
    }
  `)

  const publishToNetwork = useCallback(
    (name: NOTIFICATION, data?: Record<string, unknown>) => {
      const mutation: useNotificationsMutation$variables =
        {} as useNotificationsMutation$variables
      mutation.notificationInput = getNotificationInputObject(name, data)

      // commit the mutation, fire and forget this one
      commit(mutation)
    },
    [commit],
  )

  const publishToLocal = useCallback(
    (name: NOTIFICATION, data?: Record<string, unknown>) => {
      publishNotification({
        name,
        id: 'local',
        gwt_user_id: 'local',
        data,
      })
    },
    [publishNotification],
  )

  return { publishToNetwork, publishToLocal }
}
