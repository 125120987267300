import { useAtom } from 'jotai'
import { globalImagesAtom, ImageInfo } from '../state/atoms'

export function useGlobalCarousel() {
  const [imageAtom, setImageAtom] = useAtom(globalImagesAtom)

  const setImages = (images: ImageInfo[]) => {
    setImageAtom({ enabled: imageAtom.enabled, images })
  }

  const showCarousel = () => {
    setImageAtom({ ...imageAtom, enabled: true })
  }

  const hideCarousel = () => {
    setImageAtom({ ...imageAtom, enabled: false })
  }

  const addImage = (image: ImageInfo) => {
    const updated_images = [...imageAtom.images, image]
    setImageAtom({ ...imageAtom, images: updated_images })
  }

  const showFullScreenImage = (image: ImageInfo) => {
    setImageAtom({ enabled: true, images: [image] })
  }

  return {
    imageAtom,
    setImages,
    addImage,
    showCarousel,
    hideCarousel,
    showFullScreenImage,
  }
}
