/**
 * @generated SignedSource<<0a41c35498f47195fbcd9a5908756f2f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type TaskCompleteCheckboxMutation$variables = {
  complete: boolean;
  flowId: UUID;
};
export type TaskCompleteCheckboxMutation$data = {
  readonly updateTask: {
    readonly task: {
      readonly complete: boolean;
    } | null;
  } | null;
};
export type TaskCompleteCheckboxMutation = {
  response: TaskCompleteCheckboxMutation$data;
  variables: TaskCompleteCheckboxMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "complete"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "flowId"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "flowId",
        "variableName": "flowId"
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "complete",
            "variableName": "complete"
          }
        ],
        "kind": "ObjectValue",
        "name": "patch"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "complete",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "TaskCompleteCheckboxMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UpdateTaskPayload",
        "kind": "LinkedField",
        "name": "updateTask",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "TaskCompleteCheckboxMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UpdateTaskPayload",
        "kind": "LinkedField",
        "name": "updateTask",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Task",
            "kind": "LinkedField",
            "name": "task",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3e581e7dcf85d2f01c84b33792b445d4",
    "id": null,
    "metadata": {},
    "name": "TaskCompleteCheckboxMutation",
    "operationKind": "mutation",
    "text": "mutation TaskCompleteCheckboxMutation(\n  $flowId: UUID!\n  $complete: Boolean!\n) {\n  updateTask(input: {patch: {complete: $complete}, flowId: $flowId}) {\n    task {\n      complete\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ddba38f0949d88167124fabcc3c4ca2e";

export default node;
