import { Card, Chip, Input, Tooltip } from '@enterprise-ui/canvas-ui-react'
import React, { useState } from 'react'
import GalleryFileImage from './GalleryFileImage'
import './Gallery.scss'
import { FileView } from '@galleryjs/api-digital-assets'
import { faDownload } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getExtionsionlessGalleryName } from '../../util/galleryUtil'

interface GalleryAssetFileDisplayProps {
  file: FileView | undefined
  enableCheckBox?: boolean
  enableSelect?: boolean
  checked?: boolean
  selected?: boolean
  onCheck?: (file: FileView) => void
  onClick?: (file: FileView) => void
  children?: React.ReactNode
  attempt?: number | undefined
  thumbnail?: boolean
  showcase?: boolean
  displayFileNameBelowImage?: boolean
  displayFileNameInChip?: boolean
  enableLightbox?: boolean
  center?: boolean
}

export const GalleryAssetFileDisplay = ({
  file,
  enableCheckBox,
  enableSelect,
  checked,
  selected,
  onCheck,
  onClick,
  children,
  attempt,
  showcase,
  thumbnail = false,
  enableLightbox = false,
  displayFileNameBelowImage = false,
  displayFileNameInChip = true,
  center = false,
}: GalleryAssetFileDisplayProps) => {
  const [showFullName, setShowFullName] = useState(false)

  if (file === undefined) {
    return <></>
  }

  let assetClassNameBase = thumbnail ? 'asset-thumbnail' : 'asset'

  if (showcase) {
    assetClassNameBase = 'asset-showcase'
  }

  // do not allow enabling fullscreen on the images if selecting images is enabled
  if (enableLightbox && enableSelect) {
    enableLightbox = false
  }

  let cardCssClass = selected ? 'result selected' : 'result'
  if (center) {
    cardCssClass = `${cardCssClass} asset-center`
  }

  const fullFileName = `${getExtionsionlessGalleryName(file.name)}.${[
    ...file.fileSources,
  ]?.[0]?.fileExtension}`

  return (
    <div
      className={`${assetClassNameBase} hc-pa-xs`}
      data-test-id="asset-result"
    >
      <Card elevation={1} className={cardCssClass}>
        {/* {attempt && (
          <span className="label hc-ml-sm hc-mt-dense">{`attempt ${attempt}`}</span>
        )} */}
        {enableCheckBox && (
          <div
            className={`${assetClassNameBase}__checkbox`}
            data-test-id="asset-checkbox"
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
              e.preventDefault()
              e.stopPropagation()
              if (onCheck) {
                onCheck(file)
              }
            }}
          >
            <Input.Checkbox
              id="asset-checkbox"
              checked={checked}
              className="hc-mt-dense hc-ml-dense"
            />
          </div>
        )}
        <div
          onClick={() => {
            setShowFullName(!showFullName)
            if (onClick && enableSelect) {
              onClick(file)
            }
          }}
        >
          <GalleryFileImage
            alt={file.name}
            file={file}
            enableLightbox={enableLightbox}
            data-test-id="asset-result-image"
            className={`${assetClassNameBase}__thumbnail`}
            width="100%"
            size="cover"
          />
        </div>
        <div className={`${assetClassNameBase}__download`}>
          <Tooltip content="Download" location="left">
            <FontAwesomeIcon
              size="2x"
              color="#000000a9"
              icon={faDownload}
              onClick={() => {
                window.location.href = [...file.fileSources]?.[0]?.accessUrl
              }}
            />
          </Tooltip>
        </div>
        {displayFileNameInChip && (
          <div className="chip-container hc-pl-min hc-pr-min">
            <Chip size="dense" className="chip-display">
              {fullFileName}
            </Chip>
          </div>
        )}
      </Card>
      <div data-test-id="asset-result-name">
        {displayFileNameBelowImage && (
          <div className="file-name-text line-limit-2">{fullFileName}</div>
        )}
        {children && children}
      </div>
    </div>
  )
}
