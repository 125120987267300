import {
  Button,
  Grid,
  GridContainer,
  GridItem,
  Modal,
  Tooltip,
  TooltipProps,
} from '@enterprise-ui/canvas-ui-react'
import { graphql, useFragment } from 'react-relay'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGwtMutation } from '../../../hooks'
import { DropdownSelect } from '../../../GenericComponents/DropdownSelect/DropdownSelect'
import { TemplatesFavorite } from '../TemplatesFavorite/TemplatesFavorite'
import { useState } from 'react'
import { usePublishGlobalNotification } from '../../../hooks/useNotifications'
import { useAuthHelper } from '../../../hooks/useAuthHelper'
import { GroupTemplateOptions_moduleGroup$key } from './__generated__/GroupTemplateOptions_moduleGroup.graphql'
import { AssignGroupTemplate } from '../../GroupTemplates/AssignGroupTemplate/AssignGroupTemplate'

interface GroupTemplateOptionsProps {
  groupModuleRef: GroupTemplateOptions_moduleGroup$key | null
  className?: string
  disableFavoriteConfirmationModal?: boolean
  reverseIconDirection?: boolean
  tooltipLocation?: TooltipProps['location']
}

export const GroupTemplateOptions = ({
  groupModuleRef,
  className,
  reverseIconDirection = false,
  disableFavoriteConfirmationModal,
  tooltipLocation = 'top',
}: GroupTemplateOptionsProps) => {
  const [whichAssignModalOpen, setWhichAssignModalOpen] = useState(false)
  const [whichDeleteModalOpen, setWhichDeleteModalOpen] = useState(false)

  const { publishToLocal } = usePublishGlobalNotification()
  const { protectArray, protectStringArray } = useAuthHelper()

  const data = useFragment(
    graphql`
      fragment GroupTemplateOptions_moduleGroup on ModuleGroup
      @argumentDefinitions(gwtUserId: { type: "UUID" }) {
        ...AssignGroupTemplate
        flow {
          rowId
          name
          ...TemplatesFavorite_flow @arguments(gwtUserId: $gwtUserId)
        }
      }
    `,
    groupModuleRef || null,
  )

  const { commit } = useGwtMutation(graphql`
    mutation GroupTemplateOptionsMutation($input: ArchiveFlowInput!) {
      archiveFlow(input: $input) {
        clientMutationId
      }
    }
  `)

  const DropDownDiv = (
    <DropdownSelect
      location="bottom-right"
      shouldSelect={false}
      options={protectArray([
        {
          label: 'Assign to user',
          onSelect: () => {
            setWhichAssignModalOpen(true)
          },
          allowed: ['ADMIN', 'REVIEWER'],
        },
        {
          label: 'Delete template',
          className: 'error-color',
          onSelect: () => {
            setWhichDeleteModalOpen(true)
          },
          allowed: ['ADMIN'],
        },
      ])}
    >
      <Tooltip
        content={protectStringArray([
          { val: 'Assign', allowed: ['ADMIN', 'REVIEWER'] },
          { val: 'Delete', allowed: ['ADMIN'] },
        ])}
        location={tooltipLocation}
      >
        <Button
          aria-label="template-options"
          iconOnly
          type="default"
          size="normal"
        >
          <FontAwesomeIcon icon={faEllipsisV} />
        </Button>
      </Tooltip>
    </DropdownSelect>
  )

  const Favorites = (
    <TemplatesFavorite
      className="hc-mt-min"
      disableConfirmationModal={disableFavoriteConfirmationModal}
      flowRef={data?.flow}
      id={data?.flow?.rowId}
    />
  )
  return (
    <>
      <GridContainer spacing="none" justify="flex-end" align="center">
        <GridItem className="hc-pr-sm">
          {reverseIconDirection ? Favorites : DropDownDiv}
        </GridItem>
        <GridItem>{reverseIconDirection ? DropDownDiv : Favorites}</GridItem>
      </GridContainer>
      {whichAssignModalOpen && (
        <AssignGroupTemplate
          moduleGroupRef={data}
          isModalOpen={whichAssignModalOpen}
          closeModal={() => setWhichAssignModalOpen(false)}
        />
      )}
      {whichDeleteModalOpen && (
        <Modal
          isVisible={whichDeleteModalOpen}
          headingText="Delete template"
          onRefuse={() => setWhichDeleteModalOpen(false)}
        >
          <div className="hc-fs-md hc-ma-lg">
            This group template will be permanently deleted. You will no longer
            be able to view it on your list or access it from the Group Template
            page
            <Grid.Container justify="flex-end" align="center">
              <Grid.Item xs={8} />
              <Grid.Item xs={4} className="hc-pt-expanded">
                <Button
                  type="secondary"
                  className="hc-mr-normal"
                  onClick={() => {
                    setWhichDeleteModalOpen(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="destructive"
                  onClick={() => {
                    commit(
                      {
                        input: {
                          flowId: data?.flow?.rowId,
                        },
                      },
                      () => {
                        publishToLocal('ARCHIVE_TEMPLATE_NOTIFICATION', {
                          flowId: data?.flow?.rowId,
                        })
                      },
                    )
                    setWhichDeleteModalOpen(false)
                  }}
                >
                  Delete
                </Button>
              </Grid.Item>
            </Grid.Container>
          </div>
        </Modal>
      )}
    </>
  )
}
