import {
  Button,
  Card,
  Chip,
  Heading,
  Table,
} from '@enterprise-ui/canvas-ui-react'
import { graphql, usePaginationFragment } from 'react-relay'
import './CompanyUserTable.scss'
import React, { FC, SyntheticEvent, useState } from 'react'
import { useEffect } from 'react'
import {
  GwtUserFilter,
  CompanyUserTablePaginationQuery,
} from './__generated__/CompanyUserTablePaginationQuery.graphql'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter } from '@fortawesome/free-solid-svg-icons'
import { merge } from 'lodash'
import { TextInputDebounce } from '../../../GenericComponents/TextInputDebounce/TextInputDebounce'
import { NOTIFICATIONS } from '../../../hooks'
import { usePaginateDataLength } from '../../../hooks/usePaginateDataLength'
import { useSubscriptionRefreshQuery } from '../../../hooks/useRefresh'
import {
  getUserRoleFromUserType,
  getUserStatusFromStatus,
} from '../../../util/userUtils'
import {
  GwtUserFilterChip,
  GwtUserFilterDropdown,
} from '../../UserProfileTable/GwtUserFilterDropdown'
import { GwtUsersOrderBy } from '../../UserProfileTable/__generated__/UserProfileTablePaginationQuery.graphql'
import { UUID } from '../../../react-app-env'
import { TableSortHeader } from '../../../GenericComponents/TableSortHeader'
import { CompanyUserTablePaginationFragment_gwtUsers$key } from './__generated__/CompanyUserTablePaginationFragment_gwtUsers.graphql'
import { UserCertificationsDisplay } from '../../Users/UserCertificationsDisplay/UserCertificationsDisplay'

const PAGE_SIZE = 20

interface CompanyUserTableProps {
  companyId: UUID
  onlyCertified?: boolean
}

export const CompanyUserTable: FC<CompanyUserTableProps> = ({
  companyId,
  onlyCertified = false,
}) => {
  let baseFilter: GwtUserFilter
  if (onlyCertified) {
    baseFilter = {
      companyId: { equalTo: companyId },
      allCertifications: { isNull: false },
    }
  } else {
    baseFilter = {
      companyId: { equalTo: companyId },
    }
  }

  const [dropdownFilter, setDropdownFilter] = useState<GwtUserFilter>({})

  const [searchFilter, setSearchFilter] = useState<GwtUserFilter>({
    or: [
      { firstName: { likeInsensitive: undefined } },
      {
        lastName: { likeInsensitive: undefined },
      },
      { jobTitle: { likeInsensitive: undefined } },
    ],
  })

  const [filterChips, setFilterChips] = useState<GwtUserFilterChip[]>()

  const combinedFilter = merge(baseFilter, dropdownFilter, searchFilter)

  const [orderBy, setOrderBy] = useState<GwtUsersOrderBy>('FIRST_NAME_DESC')

  const { data, isLoadingNext, hasNext, loadNext, pageData } =
    useCompanyUserTableQuery(combinedFilter, [orderBy])

  const scrollEvent = (e: SyntheticEvent) => {
    if (isLoadingNext || !hasNext) {
      return
    }
    const target = e.target as HTMLTextAreaElement
    const scrollLeft = target.scrollHeight - target.scrollTop
    if (scrollLeft / target.clientHeight < 2) {
      loadNext(PAGE_SIZE)
    }
  }

  const updateSearchFilter = (debounced: string) => {
    if (debounced.length > 0) {
      const update = `%${debounced}%`
      setSearchFilter({
        or: [
          { firstName: { likeInsensitive: update } },
          {
            lastName: { likeInsensitive: update },
          },
          { jobTitle: { likeInsensitive: update } },
        ],
      })
    } else {
      setSearchFilter({
        or: [
          { firstName: { likeInsensitive: undefined } },
          {
            lastName: { likeInsensitive: undefined },
          },
          { jobTitle: { likeInsensitive: undefined } },
        ],
      })
    }
  }
  return (
    <div className="company-user-table">
      <Card className="card">
        <Table name="Profiles" cellPadding="normal">
          <Table.Head>
            <Table.Row align="center">
              <Table.Header style={{ paddingRight: '0px' }} xs={1}>
                <Heading size={6}>Profiles</Heading>
              </Table.Header>
              <Table.Header xs={2}>
                <TextInputDebounce
                  delay={500}
                  placeholder="Search"
                  onUpdate={updateSearchFilter}
                />
              </Table.Header>
              <Table.Header className="table-header-spacing" xs={2}>
                <GwtUserFilterDropdown
                  hideCompany
                  queryRef={pageData}
                  onChipsUpdated={(chips) => setFilterChips(chips)}
                  onFilterUpdated={(filter) => setDropdownFilter(filter)}
                >
                  <Button
                    className="table-header-spacing"
                    type="secondary"
                    fullWidth
                  >
                    <FontAwesomeIcon icon={faFilter} className="hc-mr-xs" />
                    Add filters
                  </Button>
                </GwtUserFilterDropdown>
              </Table.Header>
              <Table.Header
                xs
                className="chips"
                style={{ margin: '0px', paddingLeft: '0px' }}
              >
                <div>
                  {filterChips?.map((filterChip) => {
                    return (
                      <Chip
                        size="dense"
                        onRequestDelete={filterChip.remove}
                        key={filterChip.key + filterChip.value}
                      >
                        {`${filterChip.key}: ${filterChip.value}`}
                      </Chip>
                    )
                  })}
                  {filterChips && filterChips.length > 0 && (
                    <div
                      className="clear-all"
                      onClick={() => {
                        filterChips?.forEach((chip) => chip.remove())
                      }}
                    >
                      Clear all
                    </div>
                  )}
                </div>
              </Table.Header>
            </Table.Row>
            <Table.Row align="center">
              <TableSortHeader
                xs={2}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="FIRST_NAME_ASC"
                sortDownFilter="FIRST_NAME_DESC"
              >
                First name
              </TableSortHeader>
              <TableSortHeader
                xs={2}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="LAST_NAME_ASC"
                sortDownFilter="LAST_NAME_DESC"
              >
                Last name
              </TableSortHeader>
              <TableSortHeader
                xs={2}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="JOB_TITLE_ASC"
                sortDownFilter="JOB_TITLE_DESC"
              >
                Job title
              </TableSortHeader>
              <TableSortHeader
                xs={2}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="USER_TYPE_ASC"
                sortDownFilter="USER_TYPE_DESC"
              >
                Role
              </TableSortHeader>

              <Table.Header xs={2}>Certifications</Table.Header>
              <TableSortHeader
                xs={2}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="STATUS_ASC"
                sortDownFilter="STATUS_DESC"
              >
                Status
              </TableSortHeader>
              <Table.Header />
            </Table.Row>
          </Table.Head>
          <Table.Body onScroll={scrollEvent} className="overflow">
            {data?.gwtUsers?.edges?.map(
              ({
                node: {
                  firstName,
                  lastName,
                  userType,
                  rowId,
                  jobTitle,
                  status,
                  ...node
                },
              }) => {
                return (
                  <Table.Row align="center" key={rowId}>
                    <Table.Data xs={2}>{firstName}</Table.Data>
                    <Table.Data xs={2}>{lastName}</Table.Data>
                    <Table.Data className="capitalize" xs={2}>
                      {jobTitle || 'None set'}
                    </Table.Data>
                    <Table.Data xs={2}>
                      {getUserRoleFromUserType(userType)}
                    </Table.Data>
                    <Table.Data className="capitalize" xs={2}>
                      <UserCertificationsDisplay userRef={node} />
                    </Table.Data>
                    <Table.Data className="capitalize" xs={2}>
                      {getUserStatusFromStatus(status)}
                    </Table.Data>
                  </Table.Row>
                )
              },
            )}
          </Table.Body>
        </Table>
      </Card>
    </div>
  )
}

const useCompanyUserTableQuery = (
  gwtUserFilter: GwtUserFilter | undefined = undefined,
  gwtUsersOrderBy: [GwtUsersOrderBy] | undefined = undefined,
) => {
  const [dataLength, setDataLength] = useState(PAGE_SIZE)

  const pageData = useSubscriptionRefreshQuery<CompanyUserTablePaginationQuery>(
    graphql`
      query CompanyUserTablePaginationQuery(
        $gwtUserFilter: GwtUserFilter
        $gwtUsersOrderBy: [GwtUsersOrderBy!]
        $count: Int!
      ) {
        ...GwtUserFilterDropdown
        ...CompanyUserTablePaginationFragment_gwtUsers
          @arguments(
            gwtUserFilter: $gwtUserFilter
            gwtUsersOrderBy: $gwtUsersOrderBy
            count: $count
          )
      }
    `,
    {
      gwtUserFilter,
      gwtUsersOrderBy,
      count: dataLength,
    },
    {
      component: 'COMPANY_USER_TABLE',
      uniqueComponentId: '',
      onNotification: [...NOTIFICATIONS],
      includeSelfNotifications: true,
    },
  )

  const paginationFragmentHook = usePaginationFragment<
    CompanyUserTablePaginationQuery,
    CompanyUserTablePaginationFragment_gwtUsers$key
  >(
    graphql`
      fragment CompanyUserTablePaginationFragment_gwtUsers on Query
      @argumentDefinitions(
        gwtUserFilter: { type: "GwtUserFilter" }
        gwtUsersOrderBy: { type: "[GwtUsersOrderBy!]" }
        count: { type: "Int" }
        cursor: { type: "Cursor" }
      )
      @refetchable(queryName: "CompanyUserTablePaginationQuery_Refetchable") {
        gwtUsers(
          filter: $gwtUserFilter
          orderBy: $gwtUsersOrderBy
          first: $count
          after: $cursor
        ) @connection(key: "CompanyUserTable_gwtUsers") {
          edges {
            node {
              rowId
              firstName
              lastName
              userType
              ...UserCertificationsDisplay
              jobTitle
              status
            }
          }
          totalCount
        }
      }
    `,
    pageData,
  )

  const paginateLength = usePaginateDataLength(
    paginationFragmentHook?.data?.gwtUsers?.edges,
    PAGE_SIZE,
  )

  useEffect(() => {
    if (paginateLength) {
      setDataLength(paginateLength)
    }
  }, [paginateLength])

  return { ...paginationFragmentHook, pageData }
}
