import {
  Button,
  ButtonProps,
  Grid,
  Modal,
  Spinner,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { graphql, useFragment } from 'react-relay'
import {
  AssignmentStatus,
  FlowPatch,
  ReviewerButtonMutation,
  ReviewerButtonMutation$variables,
  UpdateFlowInput,
  UpdateModuleSubmissionByIdInput,
} from './__generated__/ReviewerButtonMutation.graphql'
import { ReviewerButton_Fragment$key } from './__generated__/ReviewerButton_Fragment.graphql'
import { useGwtMutation } from '../../../hooks'
import {
  getActivityInputObject,
  getNotificationInputObject,
} from '../../../util/relayUtils'
import dayjs from 'dayjs'
import { activityTypes } from '../../../constants'
import { useState } from 'react'

interface ReviewerButtonProps extends ButtonProps {
  moduleRef: ReviewerButton_Fragment$key | null | undefined
  assignmentStatus: AssignmentStatus
  onComplete: () => void
}

export const ReviewerButton = ({
  moduleRef = null,
  assignmentStatus,
  isRejected = false, // reject completely rejects the assignement; decline just rejects the current submission
  onComplete,
  ...props
}: ReviewerButtonProps) => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const makeToast = ToastProvider.useToaster()
  const data = useFragment(
    graphql`
      fragment ReviewerButton_Fragment on Module {
        flowId
        currentAttempt
        attempts
        flow {
          name
          assignmentStatus
        }
        moduleSubmissionsByModuleFlowId(orderBy: ATTEMPT_DESC) {
          nodes {
            id
            submissionStatus
            attempt
          }
        }
      }
    `,
    moduleRef,
  )

  const { commit, isInFlight } = useGwtMutation<ReviewerButtonMutation>(graphql`
    mutation ReviewerButtonMutation(
      $input: UpdateFlowInput!
      $activity: ActivityInput!
      $notificationInput: NotificationInput!
      $submissionInput: UpdateModuleSubmissionByIdInput!
      $emailCertificationInput: EmailCertificationInput
    ) {
      createNotification(input: { notification: $notificationInput }) {
        clientMutationId
      }
      createActivity(input: { activity: $activity }) {
        clientMutationId
      }
      sendCertificationEmailAsync(input: $emailCertificationInput) {
        clientMutationId
      }
      updateFlow(input: $input) {
        flow {
          assignmentStatus
          completedAt
          module {
            currentAttempt
            attempts
            ...HistoryTab_module
          }
        }
      }
      updateModuleSubmissionById(input: $submissionInput) {
        moduleFlow {
          moduleSubmissionsByModuleFlowId {
            nodes {
              attempt
              galleryAssetSubmissionId
              submissionStatus
            }
          }
        }
      }
    }
  `)

  const handleComplete = () => {
    makeToast({
      type: 'success',
      heading: `Module ${assignmentStatus.toLowerCase()}`,
      message: `Successfully ${assignmentStatus.toLowerCase()} module.`,
      onComplete: () => onComplete(),
    })
  }

  const handleError = () => {
    console.error()
    makeToast({
      heading: 'Error',
      message: 'There was an error submitting your request.  Please try again.',
    })
  }

  const submit = () => {
    if (!data || !data.flow || !submissionId) return
    const mutation = {} as ReviewerButtonMutation$variables

    mutation.input = {} as UpdateFlowInput
    mutation.input.rowId = data.flowId

    mutation.submissionInput = {} as UpdateModuleSubmissionByIdInput
    mutation.submissionInput.id = submissionId
    mutation.submissionInput.patch = {}
    mutation.submissionInput.patch.submissionStatus =
      assignmentStatus === 'REJECTED' ? 'REJECTED' : 'ACCEPTED'

    mutation.input.patch = {} as FlowPatch
    mutation.input.patch.assignmentStatus = assignmentStatus
    mutation.input.patch.completedAt = dayjs.utc(Date()).toDate()

    mutation.activity = getActivityInputObject(
      activityTypes.setAssignmentStatus(assignmentStatus),
      data.flow.name,
      data.flowId,
      `/modules/${data.flowId}`,
    )

    mutation.notificationInput = getNotificationInputObject(
      'REVIEWER_MODULE_BUTTON_NOTIFICATION',
      { flowId: data.flowId },
    )
    if (assignmentStatus === 'ACCEPTED') {
      mutation.emailCertificationInput = { flowId: data.flowId }
    }
    commit(mutation, handleComplete, handleError)
  }

  const reject = () => {
    if (!data || !data.flow || !submissionId) return
    const mutation = {} as ReviewerButtonMutation$variables

    mutation.input = {} as UpdateFlowInput
    mutation.input.rowId = data.flowId

    mutation.submissionInput = {} as UpdateModuleSubmissionByIdInput
    mutation.submissionInput.id = submissionId
    mutation.submissionInput.patch = {}
    mutation.submissionInput.patch.submissionStatus = 'REJECTED'

    mutation.input.patch = {} as FlowPatch
    mutation.input.patch.assignmentStatus = 'REJECTED'
    mutation.input.patch.completedAt = dayjs.utc(Date()).toDate()

    mutation.input.patch.moduleUsingRowId = {}
    mutation.input.patch.moduleUsingRowId.updateByFlowId = {
      flowId: data.flowId,
      patch: { currentAttempt: data.attempts },
    }

    mutation.activity = getActivityInputObject(
      activityTypes.setAssignmentStatus(assignmentStatus),
      data.flow.name,
      data.flowId,
      `/modules/${data.flowId}`,
    )

    mutation.notificationInput = getNotificationInputObject(
      'REVIEWER_MODULE_BUTTON_NOTIFICATION',
      { flowId: data.flowId },
    )
    if (assignmentStatus === 'ACCEPTED') {
      mutation.emailCertificationInput = { flowId: data.flowId }
    }
    commit(mutation, handleComplete, handleError)
  }

  const submissionId = data?.moduleSubmissionsByModuleFlowId.nodes.find(
    (val) =>
      val.attempt === data.currentAttempt &&
      val.submissionStatus === 'READY_FOR_REVIEW',
  )?.id

  const inReview = data?.flow?.assignmentStatus === 'UNDER_REVIEW'

  if (isInFlight) {
    return <Spinner className="hc-ml-md hc-mr-md" />
  }

  return (
    <>
      <Button
        {...props}
        disabled={
          !data?.flowId ||
          isInFlight ||
          !submissionId ||
          (!inReview && !isRejected)
        }
        onClick={setIsModalOpen}
      />
      <Modal
        isVisible={isModalOpen}
        headingText={
          assignmentStatus === 'ACCEPTED'
            ? 'Approve submission'
            : isRejected
            ? 'Reject submission'
            : 'Decline submission'
        }
        onRefuse={() => setIsModalOpen(false)}
      >
        <div className="hc-fs-md hc-ma-normal hc-pl-sm hc-pr-sm">
          Confirm{' '}
          {assignmentStatus === 'ACCEPTED'
            ? " accept the artist's submission? If so, the proof of approval will come in the form of an email, and the accreditation updated in their profile."
            : isRejected
            ? ' completely reject the assignment? If so, the artist will get an email relaying that message and will not have any further attempts'
            : " reject the artist's submission? If so, the artist will get an email relaying that message. If more attempts are available, they can start again."}
          <Grid.Container>
            <Grid.Item xs={6} />
            <Grid.Item
              xs={6}
              className="hc-ta-right hc-pt-expanded hc-pb-expanded hc-pr-md"
            >
              <Button
                type="secondary"
                className="hc-mr-normal"
                onClick={() => {
                  setIsModalOpen(false)
                }}
              >
                Cancel
              </Button>
              <Button
                id="confirm-button"
                onClick={() => {
                  isRejected ? reject() : submit()
                  setIsModalOpen(false)
                }}
                type={
                  assignmentStatus === 'REJECTED' ? 'destructive' : 'primary'
                }
              >
                {assignmentStatus === 'REJECTED'
                  ? isRejected
                    ? 'Reject'
                    : 'Decline'
                  : 'Approve'}
              </Button>
            </Grid.Item>
          </Grid.Container>
        </div>
      </Modal>
    </>
  )
}
