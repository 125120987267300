/**
 * @generated SignedSource<<2e01323a8a51b094d69af5d6a764c2f4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ReassignReviewerPopupFragment_ReviewerAvatar$data = {
  readonly company: {
    readonly name: string;
  } | null;
  readonly firstName: string;
  readonly lastName: string;
  readonly rowId: UUID;
  readonly " $fragmentType": "ReassignReviewerPopupFragment_ReviewerAvatar";
};
export type ReassignReviewerPopupFragment_ReviewerAvatar$key = {
  readonly " $data"?: ReassignReviewerPopupFragment_ReviewerAvatar$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReassignReviewerPopupFragment_ReviewerAvatar">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReassignReviewerPopupFragment_ReviewerAvatar",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GwtUser",
  "abstractKey": null
};

(node as any).hash = "251960df409c352849bf0ac3f41f1370";

export default node;
