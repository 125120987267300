import { Layout, Tabs } from '@enterprise-ui/canvas-ui-react'
import { useLocation, useNavigate } from 'react-router-dom'
import { SuspenseRingLoader } from '../../loadingComponents/SuspenseRingLoader'
import { CompanyProfileTable } from '../CompanyProfileTable/CompanyProfileTable'
import { UserProfileTable } from '../UserProfileTable/UserProfileTable'
import { useEffect } from 'react'

export const ProfileTables = () => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!location.hash) {
      navigate(`${location.pathname}#users`, { replace: true })
    }
  }, [location, navigate])

  const tab = location.hash.replace('#', '')

  return (
    <Layout.Body includeRail>
      <div>
        <Tabs
          activeTab={tab}
          defaultTab="users"
          className="hc-pl-sm hc-pt-md"
          onTabSelect={(event: any, tab: any) => {
            navigate(`${location.pathname}#${tab.name}`)
          }}
        >
          <Tabs.Item name="users">User profiles</Tabs.Item>
          <Tabs.Item name="companies">Company profiles</Tabs.Item>
        </Tabs>
      </div>
      {tab === 'users' && (
        <SuspenseRingLoader>
          <UserProfileTable />
        </SuspenseRingLoader>
      )}
      {tab === 'companies' && (
        <SuspenseRingLoader>
          <CompanyProfileTable />
        </SuspenseRingLoader>
      )}
    </Layout.Body>
  )
}
