import { useEffect, useState } from 'react'

export const usePaginateDataLength = (
  data: readonly any[] | undefined | null,
  defaultValue: number,
) => {
  const [dataLength, setDataLength] = useState(defaultValue)

  useEffect(() => {
    if (data && data.length > defaultValue) {
      setDataLength(data.length)
    } else {
      setDataLength(defaultValue)
    }
  }, [data, defaultValue])

  return dataLength
}
