/**
 * @generated SignedSource<<676ef1e743e923fcc6f397a2c764518c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type GroupTemplateCard_moduleGroup$data = {
  readonly flow: {
    readonly durationSeconds: number;
    readonly name: string;
    readonly rowId: UUID;
    readonly updatedAt: Date | null;
  } | null;
  readonly moduleGroupLinksByModuleGroupFlowId: {
    readonly nodes: ReadonlyArray<{
      readonly moduleFlow: {
        readonly flow: {
          readonly durationSeconds: number;
        } | null;
      } | null;
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"GroupTemplateOptions_moduleGroup" | "GroupTemplatePreviewButton_Fragment">;
  readonly " $fragmentType": "GroupTemplateCard_moduleGroup";
};
export type GroupTemplateCard_moduleGroup$key = {
  readonly " $data"?: GroupTemplateCard_moduleGroup$data;
  readonly " $fragmentSpreads": FragmentRefs<"GroupTemplateCard_moduleGroup">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "durationSeconds",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GroupTemplateCard_moduleGroup",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GroupTemplateOptions_moduleGroup"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "GroupTemplatePreviewButton_Fragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Flow",
      "kind": "LinkedField",
      "name": "flow",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rowId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "updatedAt",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ModuleGroupLinksConnection",
      "kind": "LinkedField",
      "name": "moduleGroupLinksByModuleGroupFlowId",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ModuleGroupLink",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Module",
              "kind": "LinkedField",
              "name": "moduleFlow",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Flow",
                  "kind": "LinkedField",
                  "name": "flow",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ModuleGroup",
  "abstractKey": null
};
})();

(node as any).hash = "7af6e53418b5a4ed63a20cd83bb9b720";

export default node;
