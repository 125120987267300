import { Layout, Tabs } from '@enterprise-ui/canvas-ui-react'
import { useLocation, useNavigate } from 'react-router-dom'
import './FeedbackPage.scss'
import { useEffect } from 'react'
import { SuspenseRingLoader } from '../../../loadingComponents/SuspenseRingLoader'
import { FeedbackTable } from '../FeedbackTable/FeedbackTable'

export const FeedbackPage = () => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!location.hash) {
      navigate(`${location.pathname}#issues`, { replace: true })
    }
  }, [location, navigate])

  const tab = location.hash.replace('#', '')

  return (
    <Layout.Body className="hc-pa-none feedback-page" includeRail>
      <Tabs
        activeTab={tab}
        defaultTab="issues"
        className="hc-mt-mds"
        onTabSelect={(event: any, tab: any) => {
          navigate(`${location.pathname}#${tab.name}`)
        }}
      >
        <Tabs.Item name="issues">Issues</Tabs.Item>
        <Tabs.Item name="suggestions">Suggestions</Tabs.Item>
        <Tabs.Item name="resolved">Resolved</Tabs.Item>
        <Tabs.Item name="archived">Archived</Tabs.Item>
      </Tabs>

      {tab === 'issues' && (
        <SuspenseRingLoader>
          <FeedbackTable tab="ISSUES" />
        </SuspenseRingLoader>
      )}
      {tab === 'suggestions' && (
        <SuspenseRingLoader>
          <FeedbackTable tab="SUGGESTIONS" />
        </SuspenseRingLoader>
      )}
      {tab === 'resolved' && (
        <SuspenseRingLoader>
          <FeedbackTable tab="RESOLVED" />
        </SuspenseRingLoader>
      )}
      {tab === 'archived' && (
        <SuspenseRingLoader>
          <FeedbackTable tab="ARCHIVED" />
        </SuspenseRingLoader>
      )}
    </Layout.Body>
  )
}
