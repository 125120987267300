import { Card, Heading, Layout, Table } from '@enterprise-ui/canvas-ui-react'
import { graphql, usePaginationFragment } from 'react-relay'
import { useSubscriptionRefreshQuery } from '../../hooks/useRefresh'
import './CompanyProfileTable.scss'
import React, { SyntheticEvent, useState } from 'react'
import { Link } from 'react-router-dom'
import { TextInputDebounce } from '../../GenericComponents/TextInputDebounce/TextInputDebounce'
import { useEffect } from 'react'
import { NOTIFICATIONS } from '../../hooks'
import { usePaginateDataLength } from '../../hooks/usePaginateDataLength'
import { getGwtFormattedDate } from '../../util/dateUtils'
import { TableSortHeader } from '../../GenericComponents/TableSortHeader'
import {
  CompanyProfileTablePaginationQuery,
  CompanyFilter,
  CompaniesOrderBy,
} from './__generated__/CompanyProfileTablePaginationQuery.graphql'

import { CompanyProfileTablePaginationFragment_companies$key } from './__generated__/CompanyProfileTablePaginationFragment_companies.graphql'

const PAGE_SIZE = 20

export const CompanyProfileTable = () => {
  const [searchFilter, setSearchFilter] = useState<CompanyFilter>({
    name: { likeInsensitive: undefined },
  })

  const [orderBy, setOrderBy] = useState<CompaniesOrderBy>('CREATED_AT_ASC')

  const { data, isLoadingNext, hasNext, loadNext } =
    useCompanyProfileTableQuery(searchFilter, [orderBy])

  const scrollEvent = (e: SyntheticEvent) => {
    if (isLoadingNext || !hasNext) {
      return
    }
    const target = e.target as HTMLTextAreaElement
    const scrollLeft = target.scrollHeight - target.scrollTop
    if (scrollLeft / target.clientHeight < 2) {
      loadNext(PAGE_SIZE)
    }
  }

  const updateSearchFilter = (debounced: string) => {
    if (debounced.length > 0) {
      const update = `%${debounced}%`
      setSearchFilter({
        name: { likeInsensitive: update },
      })
    } else {
      setSearchFilter({
        name: { likeInsensitive: undefined },
      })
    }
  }
  return (
    <Layout.Body includeRail className="company-profile-table">
      <Card className="card">
        <Table name="Company Profiles" cellPadding="normal">
          <Table.Head>
            <Table.Row align="center">
              <Table.Header xs={2}>
                <Heading size={5}>Company Profiles</Heading>
              </Table.Header>
              <Table.Header xs={4}>
                <TextInputDebounce
                  delay={500}
                  placeholder="Search"
                  onUpdate={updateSearchFilter}
                />
              </Table.Header>
              <Table.Header xs={4} />
              <Table.Header xs={2}>
                {/* <Button
                  type="primary"
                  fullWidth
                  onClick={() => navigate('/company/create')}
                >
                  Create new company
                </Button> */}
              </Table.Header>
            </Table.Row>
            <Table.Row align="center">
              <TableSortHeader
                xs={2}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="NAME_ASC"
                sortDownFilter="NAME_DESC"
              >
                Company
              </TableSortHeader>
              <TableSortHeader
                xs={1}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="CREATED_AT_ASC"
                sortDownFilter="CREATED_AT_DESC"
              >
                Date joined
              </TableSortHeader>
              <TableSortHeader
                xs={1}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="GWT_USER_BY_OWNER_ID__FULL_NAME_ASC"
                sortDownFilter="GWT_USER_BY_OWNER_ID__FULL_NAME_DESC"
              >
                Created by
              </TableSortHeader>
              <TableSortHeader
                xs={1}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="GWT_USERS_BY_COMPANY_ID__COUNT_ASC"
                sortDownFilter="GWT_USERS_BY_COMPANY_ID__COUNT_DESC"
              >
                # of users
              </TableSortHeader>
              <Table.Header xs={1}># of certifications</Table.Header>
            </Table.Row>
          </Table.Head>
          <Table.Body onScroll={scrollEvent} className="overflow">
            {data?.companies?.edges?.map(
              ({
                node: {
                  rowId,
                  name,
                  numberOfCertifications,
                  numberOfUsers,
                  createdAt,
                  owner,
                },
              }) => {
                return (
                  <Table.Row align="center" key={rowId}>
                    <Table.Data xs={2}>
                      <Link className="link-hover" to={`/companies/${rowId}`}>
                        {name}
                      </Link>
                    </Table.Data>
                    <Table.Data xs={1}>
                      {getGwtFormattedDate(createdAt)}
                    </Table.Data>
                    <Table.Data xs={1}>
                      <Link
                        className="link-hover"
                        to={`/users/${owner?.rowId}`}
                      >
                        {owner?.fullName}
                      </Link>
                    </Table.Data>
                    <Table.Data xs={1}>{numberOfUsers}</Table.Data>
                    <Table.Data xs={1}>{numberOfCertifications}</Table.Data>
                  </Table.Row>
                )
              },
            )}
          </Table.Body>
        </Table>
      </Card>
    </Layout.Body>
  )
}

const useCompanyProfileTableQuery = (
  companyFilter: CompanyFilter | undefined = undefined,
  companiesOrderBy: [CompaniesOrderBy] | undefined = undefined,
) => {
  const [dataLength, setDataLength] = useState(PAGE_SIZE)

  const pageData =
    useSubscriptionRefreshQuery<CompanyProfileTablePaginationQuery>(
      graphql`
        query CompanyProfileTablePaginationQuery(
          $companyFilter: CompanyFilter
          $companiesOrderBy: [CompaniesOrderBy!]
          $count: Int!
        ) {
          ...CompanyProfileTablePaginationFragment_companies
            @arguments(
              companyFilter: $companyFilter
              companiesOrderBy: $companiesOrderBy
              count: $count
            )
        }
      `,
      {
        companyFilter,
        companiesOrderBy,
        count: dataLength,
      },
      {
        component: 'COMPANIES_PROFILES_TABLE',
        uniqueComponentId: '',
        onNotification: [...NOTIFICATIONS],
        includeSelfNotifications: true,
      },
    )

  const paginationFragmentHook = usePaginationFragment<
    CompanyProfileTablePaginationQuery,
    CompanyProfileTablePaginationFragment_companies$key
  >(
    graphql`
      fragment CompanyProfileTablePaginationFragment_companies on Query
      @argumentDefinitions(
        companyFilter: { type: "CompanyFilter" }
        companiesOrderBy: { type: "[CompaniesOrderBy!]" }
        count: { type: "Int" }
        cursor: { type: "Cursor" }
      )
      @refetchable(
        queryName: "CompanyProfileTablePaginationQuery_Refetchable"
      ) {
        companies(
          filter: $companyFilter
          orderBy: $companiesOrderBy
          first: $count
          after: $cursor
        ) @connection(key: "CompanyProfileTable_companies") {
          edges {
            node {
              rowId
              name
              numberOfCertifications
              numberOfUsers
              createdAt
              owner {
                rowId
                fullName
              }
            }
          }
          totalCount
        }
      }
    `,
    pageData,
  )

  const paginateLength = usePaginateDataLength(
    paginationFragmentHook?.data?.companies?.edges,
    PAGE_SIZE,
  )

  useEffect(() => {
    if (paginateLength) {
      setDataLength(paginateLength)
    }
  }, [paginateLength])

  return { ...paginationFragmentHook, pageData }
}
