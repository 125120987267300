import { Card, Table } from '@enterprise-ui/canvas-ui-react'
import { graphql, useFragment } from 'react-relay'
import { UserInfo$key } from './__generated__/UserInfo.graphql'
import { UserProgramsDisplay } from './UserProgramsDisplay/UserProgramsDisplay'

interface UserInfoProps {
  userRef: UserInfo$key | null | undefined
}

export const UserInfo = ({ userRef = null }: UserInfoProps) => {
  const data = useFragment(
    graphql`
      fragment UserInfo on GwtUser {
        userType
        company {
          rowId
          name
        }
        jobTitle
        ...UserProgramsDisplay
      }
    `,
    userRef,
  )

  if (!data) {
    return <></>
  }

  return (
    <div>
      <Card className="user-table hc-pl-md hc-pr-md hc-pb-md">
        <Table cellPadding="expanded">
          <Table.Body>
            <Table.Row justify="space-between">
              <Table.Data>Job title</Table.Data>
              <Table.Data className="main-text capitalize">
                {data.jobTitle || 'None set'}
              </Table.Data>
            </Table.Row>
            <Table.Row justify="space-between">
              <Table.Data>Company Name</Table.Data>
              <Table.Data className="main-text capitalize">
                {data.company?.name || 'None set'}
              </Table.Data>
            </Table.Row>
            <Table.Row justify="space-between">
              <Table.Data>Programs</Table.Data>
              <Table.Data className="main-text">
                <UserProgramsDisplay userRef={data} />
              </Table.Data>
            </Table.Row>
            <Table.Row justify="space-between">
              <Table.Data>Role</Table.Data>
              <Table.Data className="main-text capitalize">
                {data.userType}
              </Table.Data>
            </Table.Row>
          </Table.Body>
        </Table>
      </Card>
    </div>
  )
}
