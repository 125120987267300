import {
  Button,
  Divider,
  Heading,
  Layout,
  Tabs,
  Tooltip,
  Grid,
} from '@enterprise-ui/canvas-ui-react'
import { useState } from 'react'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { useNavigate, useParams } from 'react-router-dom'
import { UserAvatar } from '../UserAvatar'
import { UserHistory } from '../UserHistory'
import { UserInfo } from '../UserInfo'
import { UserProfilePageQuery } from './__generated__/UserProfilePageQuery.graphql'
import './UserProfilePage.scss'
import { getGwtFormattedDate } from '../../../util/dateUtils'
import { UserStatusChip } from '../UserStatusChip'
import { UserAssignments } from '../UserAssignments/UserAssignments'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { ProtectedComponent } from '../../../GenericComponents/ProtectedComponent'
import { UserPriority } from '../UserPriority/UserPriority'
import { UserCertifications } from '../UserCertifications'

export const UserProfilePage = () => {
  const [tab, setTab] = useState('Assignments')
  const navigate = useNavigate()
  const { userId } = useParams()

  const { gwtUser } = useLazyLoadQuery<UserProfilePageQuery>(
    graphql`
      query UserProfilePageQuery($rowId: UUID!) {
        gwtUser(rowId: $rowId) {
          createdAt
          email
          fullName
          pronouns
          ...UserAvatar_gwtUser
          ...UserHistory
          ...UserInfo
          ...UserStatusChip_gwtUser
          ...UserAssignments
          ...UserCertifications
          ...UserPriority_gwtUser
        }
      }
    `,
    { rowId: userId },
  )

  return (
    <Layout.Body includeRail>
      <div className="user-profile-page">
        <div className="hc-mb-expanded small-width">
          <Grid.Container className="hc-ta-center hc-mt-sm">
            <Grid.Item xs={6}>
              <UserAvatar
                size="expanded"
                userRef={gwtUser}
                showName={false}
                aria-label={''}
                children={undefined}
                className="hc-ml-2x"
              />
              <ProtectedComponent allowed={['ADMIN']}>
                <Tooltip content="Edit" location="right" className="edit-right">
                  <Button
                    onClick={() => navigate(`/user/edit/${userId}`)}
                    size="dense"
                    aria-label="user-profile"
                    iconOnly
                    type="primary"
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </Button>
                </Tooltip>
              </ProtectedComponent>

              <Heading size={3} className="hc-mt-sm hc-mb-sm">
                {gwtUser?.fullName}
              </Heading>
              {gwtUser?.pronouns && (
                <div className="hc-mb-min">{gwtUser.pronouns}</div>
              )}
              <div className="hc-mb-min">
                Joined on {getGwtFormattedDate(gwtUser?.createdAt)}
              </div>
              <div className="hc-mb-min">{gwtUser?.email}</div>
              <UserStatusChip className="hc-mb-dense" userRef={gwtUser} />
              <span className="template-name-text hc-ml-sm">
                Priority: <UserPriority userRef={gwtUser} />
              </span>
            </Grid.Item>

            <Grid.Item xs={6}>
              <UserInfo userRef={gwtUser} />
            </Grid.Item>
          </Grid.Container>
          <div>
            <Tabs
              activeTab={tab}
              disableAdaptive
              defaultTab="Assignments"
              className="hc-mt-md tabs"
              onTabSelect={(event: any, tab: any) => {
                setTab(tab.name)
              }}
            >
              <Tabs.Item className="big-tab" name="Assignments">
                Assignments
              </Tabs.Item>
              <Tabs.Item className="big-tab" name="History">
                History
              </Tabs.Item>
              <Tabs.Item className="big-tab" name="Certifications">
                Certifications
              </Tabs.Item>
            </Tabs>
          </div>
          <Divider className="hc-mb-xl" />
          <div className="tab-content">
            {tab === 'Assignments' && <UserAssignments userRef={gwtUser} />}
            {tab === 'History' && <UserHistory userRef={gwtUser} />}
            {tab === 'Certifications' && (
              <UserCertifications userRef={gwtUser} />
            )}
          </div>
        </div>
      </div>
    </Layout.Body>
  )
}
