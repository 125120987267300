import { Heading, Layout, ToastProvider } from '@enterprise-ui/canvas-ui-react'
import { useAuth } from '@praxis/component-auth'
import { MainRouter } from '../globalComponents/MainRouter'
import { Header } from '../globalComponents/Header/Header'
import './Main.scss'
import { Suspense, useEffect } from 'react'
import { RingLoader } from 'react-spinners'
import { BannerNotification } from '../globalComponents/Banner/BannerNotification'
import { GlobalImageGallery } from '../globalComponents/GlobalImageGallery'
import { RequestAdGroups } from '../globalComponents/RequestAdGroups/RequestAdGroups'
import { Route, Routes } from 'react-router-dom'
import { NotificationSubscriptionComponent } from '../globalComponents/NotificationSubscription'
import { GwtGlobalNotificationsListener } from '../globalComponents/GwtGlobalNotificationsListener'
import { GlobalFeedbackUpdater } from '../globalComponents/GlobalFeedbackUpdater'
import { ProtectedComponent } from '../GenericComponents/ProtectedComponent'
import { useAnalytics } from '@praxis/component-analytics'
import { browserName } from 'react-device-detect'
import { useSetAtom } from 'jotai'
import { documentScrollPercentAtom } from '../state/atoms'

const RedirectLogin = ({
  login,
}: {
  login: ({ redirect }: { redirect: string }) => void
}) => {
  login({ redirect: window.location.href })
  return null
}

export const Main = () => {
  const auth = useAuth()
  const { isAuthenticated } = auth
  const { login } = auth

  const { trackCustomEvent } = useAnalytics()
  const setScrollPercent = useSetAtom(documentScrollPercentAtom)

  const scrollEvent = () => {
    setScrollPercent(
      (window.scrollY /
        (document.documentElement.scrollHeight - window.innerHeight)) *
        100,
    )
  }

  useEffect(() => {
    // track browser information on login
    trackCustomEvent('browser_name', browserName, browserName)
  })

  useEffect(() => {
    document.addEventListener('scroll', scrollEvent)

    return () => document.removeEventListener('scroll', scrollEvent)
  })

  if (isAuthenticated()) {
    return (
      <>
        <Suspense fallback={<></>}>
          <NotificationSubscriptionComponent />
          <GwtGlobalNotificationsListener />
          <ProtectedComponent allowed={['ADMIN']}>
            <GlobalFeedbackUpdater />
          </ProtectedComponent>
        </Suspense>
        <Layout darkMode="false" theme="default" fullWidth>
          <Suspense
            fallback={
              <Layout.Body includeRail className="center-loading-spinner">
                <RingLoader size={150} />
              </Layout.Body>
            }
          >
            <ToastProvider>
              <BannerNotification />
              <Header />
              <GlobalImageGallery />
              <RequestAdGroups>
                <MainRouter />
              </RequestAdGroups>
            </ToastProvider>
          </Suspense>
        </Layout>
      </>
    )
  }
  return (
    <Routes>
      <Route path="/" element={<RedirectLogin login={login} />} />
      <Route
        path="/cypress"
        element={<Heading>Cypress landing page</Heading>}
      />
    </Routes>
  )
}
