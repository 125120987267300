/**
 * @generated SignedSource<<f89df7388afd5c75d5144a2b68843a06>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type CompanyProfilePageQuery$variables = {
  rowId: UUID;
};
export type CompanyProfilePageQuery$data = {
  readonly company: {
    readonly createdAt: Date | null;
    readonly name: string;
    readonly numberOfCertifications: number | null;
    readonly numberOfUsers: number | null;
  } | null;
};
export type CompanyProfilePageQuery = {
  response: CompanyProfilePageQuery$data;
  variables: CompanyProfilePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "rowId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "rowId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberOfCertifications",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "numberOfUsers",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompanyProfilePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "company",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompanyProfilePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Company",
        "kind": "LinkedField",
        "name": "company",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3b9f5a5b6c0f07be9d5f8db8d1276062",
    "id": null,
    "metadata": {},
    "name": "CompanyProfilePageQuery",
    "operationKind": "query",
    "text": "query CompanyProfilePageQuery(\n  $rowId: UUID!\n) {\n  company(rowId: $rowId) {\n    createdAt\n    name\n    numberOfCertifications\n    numberOfUsers\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "cf1fa706061e12ca3678975d76147e56";

export default node;
