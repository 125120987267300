/**
 * @generated SignedSource<<a2edec629403815f276c99e422fd74fb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AssignmentDueAtStatus = "DUE_SOON" | "NONE" | "OVERDUE" | "%future added value";
export type AssignmentStatus = "ACCEPTED" | "CANCELED" | "IN_PROGRESS" | "NONE" | "NOT_STARTED" | "READY_FOR_REVIEW" | "REJECTED" | "UNDER_REVIEW" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type StatusChip_flow$data = {
  readonly assignmentDueAtStatus: AssignmentDueAtStatus | null;
  readonly assignmentStatus: AssignmentStatus;
  readonly dueAt: Date | null;
  readonly " $fragmentType": "StatusChip_flow";
};
export type StatusChip_flow$key = {
  readonly " $data"?: StatusChip_flow$data;
  readonly " $fragmentSpreads": FragmentRefs<"StatusChip_flow">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StatusChip_flow",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "dueAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "assignmentStatus",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "assignmentDueAtStatus",
      "storageKey": null
    }
  ],
  "type": "Flow",
  "abstractKey": null
};

(node as any).hash = "cd39961b08a8813a3676444ddd4ece95";

export default node;
