import { Grid, Layout } from '@enterprise-ui/canvas-ui-react'
import { useEffect } from 'react'
import './Reviewer.scss'
import { SuspenseRingLoader } from '../../loadingComponents/SuspenseRingLoader'
import { useAtom } from 'jotai'
import { titleAtom } from '../../state/atoms'
import { ReviewerStatusCard } from './ReviewerStatusCard'
import { ReviewerDiscussionCard } from './ReviewerDiscussionCard'
import { ReviewerTemplateCard } from './ReviewerTemplateCard'

// The reviewer dashboard uses some of the components from the admin dashboard
export const ReviewerDashboard = () => {
  const [, setTitle] = useAtom(titleAtom)

  useEffect(() => {
    setTitle('Reviewer')
  })

  return (
    <Layout.Body includeRail>
      <Grid.Container className="reviewer hc-pl-expanded hc-pr-expanded hc-pt-2x hc-pb-2x">
        <Grid.Item xs={4} className="hc-pr-lg">
          <SuspenseRingLoader>
            <ReviewerStatusCard />
          </SuspenseRingLoader>
        </Grid.Item>
        <Grid.Item xs={4} className="hc-pr-lg">
          <SuspenseRingLoader>
            <ReviewerDiscussionCard />
          </SuspenseRingLoader>
        </Grid.Item>
        <Grid.Item xs={4}>
          <SuspenseRingLoader>
            <ReviewerTemplateCard className="cardLayout template-card" />
          </SuspenseRingLoader>
        </Grid.Item>
      </Grid.Container>
    </Layout.Body>
  )
}
