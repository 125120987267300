import React from 'react'
import { Modal, Heading, Button, Grid } from '@enterprise-ui/canvas-ui-react'
import './HelpModal.scss'
import { useEnv } from '@praxis/component-runtime-env'
import { GwtEnv } from '../../types'
import { useAtom } from 'jotai'
import { helpAtom } from '../../state/atoms'

export const HelpModal = () => {
  const env = useEnv<GwtEnv>()
  const [help, setHelp] = useAtom(helpAtom)

  return (
    <>
      {help && (
        <Modal
          className="modal-parent-container"
          isVisible={help}
          onRefuse={() => setHelp(false)}
        >
          <Grid.Container
            align="center"
            justify="center"
            direction="column"
            spacing="expanded"
            className="hc-pa-xl"
          >
            <Heading size={4} className="hc-pv-normal">
              Please contact us with any concerns!
            </Heading>
            <Button
              size="expanded"
              as="a"
              href={env.slackUrl}
              target="_blank"
              className="help-button hc-ma-normal"
              data-testid="help-modal-slack-button"
            >
              <div className="slack-image hc-mr-normal" />
              <Heading size={6}>#assent_support</Heading>
            </Button>
          </Grid.Container>
        </Modal>
      )}
    </>
  )
}
