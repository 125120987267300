import { useLocation, useNavigate } from 'react-router-dom'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { useGwtMutation } from '../../hooks'
import { UUID } from '../../react-app-env'
import { TemplateForm } from './TemplateForm'
import { EditAdminTemplateCardDataProps } from './AdminTemplateCard'
import { getControlledUserSelectObjectOrDefault } from '../../util/userUtils'
import { getFlowDuration } from '../../util/flowUtils'
import { TemplateDuplicatePageQuery } from './__generated__/TemplateDuplicatePageQuery.graphql'
import { TemplateDuplicatePageMutation } from './__generated__/TemplateDuplicatePageMutation.graphql'

export const TemplateDuplicatePage = () => {
  const location = useLocation()
  const navigate = useNavigate()

  //Information to get name and rowID of selected template
  const currentTemplate = location.state as EditAdminTemplateCardDataProps
  const currentTemplateRowId = currentTemplate?.moduleFlowId
  if (!currentTemplateRowId) {
    // if we do not have state data for this page, abort!
    navigate('/')
  }

  const data = useTemplateDuplicateQuery(currentTemplateRowId || '')

  const { commit, isInFlight } = useGwtMutation<TemplateDuplicatePageMutation>(
    graphql`
      mutation TemplateDuplicatePageMutation(
        $attempts: Int!
        $dueAt: Datetime
        $tasks: [TaskModuleFlowIdFkeyTaskCreateInput!]
        $name: String!
        $description: String!
        $notificationInput: NotificationInput!
        $reviewers: [FlowReviewerFlowIdFkeyFlowReviewerCreateInput!]
        $softwareType: SoftwareType
        $programType: ProgramType
        $durationSeconds: Int!
      ) {
        createNotification(input: { notification: $notificationInput }) {
          clientMutationId
        }
        createModule(
          input: {
            module: {
              flowToFlowId: {
                create: {
                  name: $name
                  description: $description
                  durationSeconds: $durationSeconds
                  dueAt: $dueAt
                  flowType: BLUEPRINT
                  flowReviewersUsingRowId: { create: $reviewers }
                }
              }
              attempts: $attempts
              programType: $programType
              softwareType: $softwareType
              tasksUsingFlowId: { create: $tasks }
            }
            initializeGalleryComponentsAsset: true
          }
        ) {
          module {
            galleryAssetComponentsId
            flowId
          }
        }
      }
    `,
  )

  let originalModuleDuration = getFlowDuration(
    data.module?.flow?.durationSeconds,
    data.module?.flow?.createdAt,
    data.module?.flow?.dueAt,
  )

  const defaultValues = {
    duration: `${originalModuleDuration}`,
    name: `${data.module?.flow?.name}_Copy`,
    attempts: data.module?.attempts,
    softwareType: data.module?.softwareType,
    programType: data.module?.programType,
    description: {
      textContent: '',
      htmlContent: data.module?.flow?.description || '',
    },
    assignTo:
      data.module?.flow?.flowReviewers.nodes?.[0]?.reviewer?.rowId ||
      'unassigned',
    tasks:
      data.module?.tasksByModuleFlowId?.nodes?.map((currentTask) => {
        return {
          value: currentTask.flow?.name || '',
        }
      }) || [],
    galleryAssetComponentsId: data.module?.galleryAssetComponentsId,
    rowId: data.module?.flow?.rowId,
    versionNumber: parseFloat(data.module?.versionNumber),
  }

  return (
    <TemplateForm
      defaultValues={defaultValues}
      defaultReviewer={getControlledUserSelectObjectOrDefault(
        data.module?.flow?.flowReviewers.nodes?.[0]?.reviewer?.rowId,
        data.module?.flow?.flowReviewers.nodes?.[0]?.reviewer?.firstName,
        data.module?.flow?.flowReviewers.nodes?.[0]?.reviewer?.lastName,
        data.module?.flow?.flowReviewers.nodes?.[0]?.reviewer?.company?.name,
      )}
      commit={commit}
      isInFlight={isInFlight}
    />
  )
}

const useTemplateDuplicateQuery = (currentTemplateRowId: UUID) => {
  //Query to get the rest of the data for the current template that is being edited
  const currentTemplateData = useLazyLoadQuery<TemplateDuplicatePageQuery>(
    graphql`
      query TemplateDuplicatePageQuery($currentTemplateRowId: UUID!) {
        module(flowId: $currentTemplateRowId) {
          programType
          softwareType
          versionNumber
          flow {
            name
            rowId
            assignmentStatus
            workflowType
            description
            dueAt
            createdAt
            durationSeconds
            flowReviewers(first: 1) {
              nodes {
                reviewer {
                  rowId
                  firstName
                  lastName
                  company {
                    name
                  }
                }
              }
            }
          }
          tasksByModuleFlowId(orderBy: PRIORITY_ASC) {
            nodes {
              flow {
                name
                id
                rowId
              }
            }
          }
          galleryAssetComponentsId
          attempts
        }
      }
    `,
    { currentTemplateRowId },
  )

  return currentTemplateData
}
