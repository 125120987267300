import { graphql, useFragment } from 'react-relay'
import { UserModal_user$key } from './__generated__/UserModal_user.graphql'
import { useState } from 'react'
import { Modal } from '@enterprise-ui/canvas-ui-react'
import { UserHistory } from './UserHistory'
import { UserAvatar } from './UserAvatar'
import { UserInfo } from './UserInfo'
import './UserModal.scss'

interface UserModalProps {
  userRef: UserModal_user$key | null | undefined
}

export const UserModal = ({ userRef }: UserModalProps) => {
  const data = useFragment(
    graphql`
      fragment UserModal_user on GwtUser {
        rowId
        fullName
        ...UserAvatar_gwtUser
        ...UserHistory
        ...UserInfo
      }
    `,
    userRef || null,
  )

  const [isVisible, setIsVisible] = useState(false)
  const handleRefuseModal = () => setIsVisible(false)
  const handleClick = () => setIsVisible(true)

  return (
    <>
      <div role="button" className="link-hover" onClick={handleClick}>
        {data?.fullName}
      </div>
      {isVisible && (
        <Modal
          className="user-modal"
          isVisible={isVisible}
          onRefuse={handleRefuseModal}
        >
          <div className="hc-pa-normal">
            <UserAvatar
              userRef={data}
              showName={false}
              aria-label={''}
              children={undefined}
            />
            <UserInfo userRef={data} />
            <UserHistory userRef={data} />
          </div>
        </Modal>
      )}
    </>
  )
}
