import { useAtom } from 'jotai'
import { useFragment } from 'react-relay'
import { useNavigate } from 'react-router-dom'
import { graphql } from 'relay-runtime'
import { preloadedReviewerCommentAtom } from '../../../state/atoms'
import { SubmittedAssetFilesDisplay } from '../../Gallery/SubmittedAssetFilesDisplay'
import { ReviewerButton } from './ReviewerButton'
import './ReviewerOverview.scss'
import { StartNextAttemptButton } from './StartNextAttemptButton'
import { StartReviewingButton } from './StartReviewingButton'
import { ReviewerTab_module$key } from './__generated__/ReviewerTab_module.graphql'

interface ReviewerTabProps {
  moduleRef: ReviewerTab_module$key | null | undefined
}

export const ReviewerTab = ({ moduleRef = null }: ReviewerTabProps) => {
  const navigate = useNavigate()
  const [, setPreloadedComment] = useAtom(preloadedReviewerCommentAtom)
  const data = useFragment(
    graphql`
      fragment ReviewerTab_module on Module {
        ...SubmittedAssetFilesDisplay_Fragment
        ...ReviewerButton_Fragment
        ...StartReviewingButton_Fragment
        ...StartNextAttemptButton_Fragment
        flow {
          ...StatusChip_flow
          rowId
          name
          description
          assignedAt
          assignedBy {
            firstName
            lastName
          }
          assignedTo {
            firstName
            lastName
          }
          flowReviewers {
            nodes {
              reviewer {
                firstName
                lastName
              }
            }
          }
          dueAt
          assignmentStatus
        }
        tasksByModuleFlowId(orderBy: PRIORITY_ASC) {
          nodes {
            flow {
              name
            }
          }
        }
        moduleSubmissionsByModuleFlowId(orderBy: ATTEMPT_DESC) {
          nodes {
            submissionStatus
            id
          }
        }
        galleryAssetComponentsId
        attempts
      }
    `,
    moduleRef,
  )

  if (data?.moduleSubmissionsByModuleFlowId.nodes.length === 0) {
    return (
      <div className="small-width hc-mb-xl hc-ta-center zero-state-text hc-pt-xl">
        There are currently no submissions for review
      </div>
    )
  }

  const submissionExists = !!data?.moduleSubmissionsByModuleFlowId.nodes.find(
    (e) => e.submissionStatus === 'READY_FOR_REVIEW',
  )

  const canStartNextAttempt =
    !submissionExists &&
    data?.moduleSubmissionsByModuleFlowId.nodes.length &&
    data.flow?.assignmentStatus === 'REJECTED'

  return (
    <>
      <div className="small-width hc-mb-md">
        {canStartNextAttempt && (
          <div className="deliver-button-container">
            <StartNextAttemptButton
              size="expanded"
              className="status-button"
              moduleRef={data}
            />
          </div>
        )}
        {submissionExists && (
          <div className="deliver-button-container">
            <StartReviewingButton
              size="expanded"
              className="status-button"
              moduleRef={data}
            />
            <ReviewerButton
              moduleRef={data}
              assignmentStatus="REJECTED"
              size="expanded"
              type="destructive"
              className="hc-ml-sm reject"
              isRejected
              onComplete={() => {
                setPreloadedComment(
                  'Unfortunately, this module has been rejected and there are no further attempts',
                )
                navigate(`/reviewer/modules/${data?.flow?.rowId}#comments`)
              }}
            >
              Reject
            </ReviewerButton>
            <div className="flex-grow" />
            <ReviewerButton
              moduleRef={data}
              assignmentStatus="REJECTED"
              className="hc-mr-sm decline"
              size="expanded"
              type="destructive"
              onComplete={() => {
                setPreloadedComment(
                  'Unfortunately, this module still does not meet our expectations in this software',
                )
                navigate(`/reviewer/modules/${data?.flow?.rowId}#comments`)
              }}
            >
              Decline
            </ReviewerButton>
            <ReviewerButton
              moduleRef={data}
              assignmentStatus="ACCEPTED"
              size="expanded"
              type="primary"
              className="approve"
              onComplete={() => {
                setPreloadedComment(
                  'Congratulations!  You have passed the accreditation process for this module!',
                )
                navigate(`/reviewer/modules/${data?.flow?.rowId}#comments`)
              }}
            >
              Approve
            </ReviewerButton>
          </div>
        )}
        {!submissionExists && (
          <div className="hc-ta-center zero-state-text hc-pt-xl hc-pb-xl">
            There are currently no new submissions
          </div>
        )}
      </div>
      <div className="small-width hc-mb-xl">
        <SubmittedAssetFilesDisplay
          moduleRef={data}
          firstExpanded={submissionExists}
        />
      </div>
    </>
  )
}
