/**
 * @generated SignedSource<<8ca60eb07c3182b44fce7d32d88a5c83>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpcomingModuleFragment$data = {
  readonly attempts: number;
  readonly flow: {
    readonly name: string;
    readonly " $fragmentSpreads": FragmentRefs<"StatusChip_flow">;
  } | null;
  readonly flowId: UUID;
  readonly groupModulePreviousModuleFlow: {
    readonly flow: {
      readonly name: string;
    } | null;
  } | null;
  readonly moduleGroup: {
    readonly flow: {
      readonly name: string;
    } | null;
  } | null;
  readonly " $fragmentType": "UpcomingModuleFragment";
};
export type UpcomingModuleFragment$key = {
  readonly " $data"?: UpcomingModuleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpcomingModuleFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Flow",
    "kind": "LinkedField",
    "name": "flow",
    "plural": false,
    "selections": [
      (v0/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpcomingModuleFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "flowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ModuleGroup",
      "kind": "LinkedField",
      "name": "moduleGroup",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Module",
      "kind": "LinkedField",
      "name": "groupModulePreviousModuleFlow",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Flow",
      "kind": "LinkedField",
      "name": "flow",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "StatusChip_flow"
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "attempts",
      "storageKey": null
    }
  ],
  "type": "Module",
  "abstractKey": null
};
})();

(node as any).hash = "d44ab290129b14c41650ae68eeb97956";

export default node;
