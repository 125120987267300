import { Grid } from '@enterprise-ui/canvas-ui-react'
import { graphql, useFragment } from 'react-relay'
import { getTimeFromCreatedAtString } from '../../util/dateUtils'
import { UserAvatar } from '../Users/UserAvatar'
import './Comment.scss'
import { CommentOptions } from './CommentOptions'
import { EditableComment } from './EditableComment'
import { CommentCardResponseFragment$key } from './__generated__/CommentCardResponseFragment.graphql'

interface CommentCardResponseProps {
  commentRef: CommentCardResponseFragment$key | null
  flowId: string
}

export const CommentCardResponse = (props: CommentCardResponseProps) => {
  const data = useFragment(
    graphql`
      fragment CommentCardResponseFragment on Comment {
        ...CommentOptions_comment
        ...EditableComment_editable
        createdAt
        rowId
        gwtUser {
          ...UserAvatar_gwtUser
        }
      }
    `,
    props.commentRef || null,
  )

  if (!data) {
    return <></>
  }
  return (
    <div className="Comment" id={data.rowId}>
      <div className="hc-ma-none hc-pt-sm hc-pb-sm">
        <div className="comment-card-response-text hc-pt-xs hc-pb-xs">
          <Grid.Container spacing="none">
            <Grid.Item xs={9}>
              <UserAvatar
                showAvatar={false}
                userRef={data.gwtUser}
                spanClassName="response-name-text hc-pl-xs"
                aria-label={''}
                children={undefined}
              />
            </Grid.Item>
            <Grid.Item className="rightText hc-mt-xs hc-pr-md" xs={2}>
              {getTimeFromCreatedAtString(data.createdAt)}
            </Grid.Item>
            <Grid.Item className="rightText hc-pr-sm" xs={1}>
              <CommentOptions commentRef={data} flowId={props.flowId} />
            </Grid.Item>
            <Grid.Item xs={12} className="hc-pa-xs">
              <EditableComment
                classNames="hc-pa-xs newline"
                commentRef={data}
              />
            </Grid.Item>
          </Grid.Container>
        </div>
      </div>
    </div>
  )
}
