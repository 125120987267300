import {
  Button,
  GridContainer,
  GridItem,
  Layout,
  Tabs,
} from '@enterprise-ui/canvas-ui-react'
import { useLocation, useNavigate } from 'react-router-dom'
import { SuspenseRingLoader } from '../../loadingComponents/SuspenseRingLoader'
import { TemplatesTable } from '../TemplatesTable/TemplatesTable'
import { useEffect } from 'react'
import './TemplateTables.scss'
import { ProtectedComponent } from '../../GenericComponents/ProtectedComponent'
import { GroupTemplatesSearch } from '../GroupTemplateTable/GroupTemplatesSearch'
import { GroupTemplatesTable } from '../GroupTemplateTable/GroupTemplatesTable'
export const TemplateTables = () => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (!location.hash) {
      navigate(`${location.pathname}#individual`, { replace: true })
    }
  }, [location, navigate])

  const tab = location.hash.replace('#', '')

  return (
    <Layout.Body includeRail className="hc-pa-lg">
      <GridContainer
        spacing="none"
        justify="space-between"
        className="hc-pl-sm hc-pt-md hc-pb-md"
      >
        <GridItem xs={4}>
          <Tabs
            activeTab={tab}
            defaultTab="individual"
            onTabSelect={(event: any, tab: any) => {
              navigate(`${location.pathname}#${tab.name}`)
            }}
          >
            <Tabs.Item name="individual">Individual templates</Tabs.Item>
            <Tabs.Item name="group">Group templates</Tabs.Item>
          </Tabs>
        </GridItem>
        {tab === 'group' && (
          <>
            <GridItem xs={2} />
            <GridItem xs={4} className="hc-pr-lg module-group-search">
              <GroupTemplatesSearch />
            </GridItem>
            <GridItem xs={2} className="hc-ta-right hc-pr-xl">
              <ProtectedComponent allowed={['ADMIN']}>
                <Button
                  type="primary"
                  onClick={() => navigate('/template/groups/create')}
                >
                  Create new group template
                </Button>
              </ProtectedComponent>
            </GridItem>
          </>
        )}
      </GridContainer>
      {tab === 'individual' && (
        <SuspenseRingLoader>
          <TemplatesTable />
        </SuspenseRingLoader>
      )}
      {
        // TODO: switch once we are ready
      }
      {tab === 'group' && (
        <SuspenseRingLoader>
          <GroupTemplatesTable />
        </SuspenseRingLoader>
      )}
    </Layout.Body>
  )
}
