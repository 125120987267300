import { Button, ButtonProps, Spinner } from '@enterprise-ui/canvas-ui-react'
import { useState } from 'react'

interface TimedSpinButtonProps extends ButtonProps {
  onClick: () => void
  durationInMs?: number
  denseSpinner?: boolean
}

export const TimedSpinButton = ({
  onClick,
  durationInMs = 5000,
  denseSpinner = true,
  ...props
}: TimedSpinButtonProps) => {
  const [spin, setSpin] = useState(false)

  const newClick = () => {
    onClick()
    setSpin(true)

    setTimeout(() => {
      setSpin(false)
    }, durationInMs)
  }

  if (spin) {
    return <Spinner size="dense" />
  }

  return <Button {...props} onClick={newClick} />
}
