/**
 * @generated SignedSource<<fdc3ac0664aaf4a9f4f490f3aa8cdd80>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ProgramType = "ACCESSORIES_3_D_ACCREDITATION" | "A_A_3_D_ACCREDITATION" | "NONE" | "SOFT_HOME_3_D_ACCREDITATION" | "_3_D_STYLING" | "%future added value";
export type UserStatus = "ACTIVE" | "DEACTIVATED" | "SUSPENDED" | "%future added value";
export type UserType = "ADMIN" | "BLOCKED" | "EXTERNAL_USER" | "INTERNAL_USER" | "REVIEWER" | "%future added value";
export type CreateProfilePageQuery$variables = {
  rowId: UUID;
  skip: boolean;
};
export type CreateProfilePageQuery$data = {
  readonly companies: {
    readonly nodes: ReadonlyArray<{
      readonly name: string;
      readonly rowId: UUID;
    }>;
  } | null;
  readonly gwtUser?: {
    readonly company: {
      readonly name: string;
      readonly rowId: UUID;
    } | null;
    readonly email: string;
    readonly firstName: string;
    readonly jobTitle: string | null;
    readonly lastName: string;
    readonly programs: ReadonlyArray<ProgramType | null>;
    readonly pronouns: string | null;
    readonly status: UserStatus;
    readonly userPriority: number;
    readonly userType: UserType;
  } | null;
};
export type CreateProfilePageQuery = {
  response: CreateProfilePageQuery$data;
  variables: CreateProfilePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "rowId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "rowId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userType",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jobTitle",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userPriority",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = [
  (v7/*: any*/),
  (v8/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pronouns",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "programs",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v15 = [
  (v7/*: any*/),
  (v8/*: any*/),
  (v14/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateProfilePageQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "GwtUser",
            "kind": "LinkedField",
            "name": "gwtUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": (v9/*: any*/),
                "storageKey": null
              },
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/)
            ],
            "storageKey": null
          }
        ]
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CompaniesConnection",
        "kind": "LinkedField",
        "name": "companies",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": (v9/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateProfilePageQuery",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "GwtUser",
            "kind": "LinkedField",
            "name": "gwtUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": (v15/*: any*/),
                "storageKey": null
              },
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          }
        ]
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "CompaniesConnection",
        "kind": "LinkedField",
        "name": "companies",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": (v15/*: any*/),
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "552a14b69420c8d55bda578da42a9925",
    "id": null,
    "metadata": {},
    "name": "CreateProfilePageQuery",
    "operationKind": "query",
    "text": "query CreateProfilePageQuery(\n  $rowId: UUID!\n  $skip: Boolean!\n) {\n  gwtUser(rowId: $rowId) @skip(if: $skip) {\n    firstName\n    lastName\n    userType\n    jobTitle\n    userPriority\n    company {\n      rowId\n      name\n      id\n    }\n    pronouns\n    email\n    programs\n    status\n    id\n  }\n  companies {\n    nodes {\n      rowId\n      name\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a5b0e57b2ecd5ce655975f11e4ebc9ee";

export default node;
