import { graphql, usePaginationFragment } from 'react-relay'
import './ControlledUserSelect.scss'
import React, { SyntheticEvent, useCallback, useState } from 'react'
import { useEffect } from 'react'
import { usePaginateDataLength } from '../hooks/usePaginateDataLength'
import { useSubscriptionRefreshQueryWithIsRefreshing } from '../hooks/useRefresh'
import Select, { GroupBase, SingleValue } from 'react-select'
import { UUID } from '../react-app-env'
import { useDebouncedCallback } from 'use-debounce'
import { Input, InputProps } from '@enterprise-ui/canvas-ui-react'
import { useController } from 'react-hook-form'
import { get } from 'lodash'
import { getRequiredMessage } from '../util/formUtils'
import { ControlledErrorMessage } from './ControlledErrorMessage'
import { components, MenuListProps } from 'react-select'
import { ControlledUserSelectPaginationFragment_users$key } from './__generated__/ControlledUserSelectPaginationFragment_users.graphql'
import {
  ControlledUserSelectPaginationQuery,
  GwtUserFilter,
  GwtUsersOrderBy,
  UserTypeFilter,
} from './__generated__/ControlledUserSelectPaginationQuery.graphql'
import { getControlledUserSelectObject } from '../util/userUtils'
import { getUniqueId } from '../util/idUtil'

const PAGE_SIZE = 20

interface PropTypes extends InputProps {
  name: string
  label?: string
  id?: string
  required?: boolean
  requiredMessage?: string
  isDisabled?: boolean
  defaultUnassigned?: () => {
    value: string
    label: string
  }
  defaultValue: SingleValue<{
    value: string
    label: string
  }>
  userTypes?: UserTypeFilter | null
}

export const ControlledUserSelect = ({
  name,
  label,
  id,
  required = false,
  requiredMessage,
  defaultValue,
  defaultUnassigned,
  userTypes = null,
  isDisabled = false,
}: PropTypes) => {
  const [searchFilter, setSearchFilter] = useState<GwtUserFilter>({
    userType: userTypes,
    or: [
      { fullName: { likeInsensitive: null } },
      { company: { name: { likeInsensitive: null } } },
    ],
  })

  const [loading, setLoading] = useState(false)

  const [previousSearch, setPreviousSearch] = useState('')

  // This state variable is used to trigger the next load so the menulist component doesn't trigger a re-render
  const [loadNextPage, setLoadNextPage] = useState(false)

  const {
    field: { onChange, value },
    formState: { errors },
  } = useController({
    name,
    rules: {
      required: getRequiredMessage({
        name,
        label,
        required,
        requiredMessage,
      }),
    },
    defaultValue,
  })
  const error = get(errors, name)

  // Keeping this in-case we want to change the search to allow ordering by later
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [orderBy, setOrderBy] = useState<GwtUsersOrderBy>('FULL_NAME_ASC')

  const debounceSearchInput = useDebouncedCallback((search) => {
    setSearchFilter({
      or: [
        { fullName: { likeInsensitive: `%${search}%` } },
        { company: { name: { likeInsensitive: `%${search}%` } } },
      ],
    })

    setLoading(false)
  }, 500)

  const search = useCallback(
    (search: string) => {
      if (previousSearch === search) {
        // don't search for what we just searched for
        return
      }
      if (debounceSearchInput.isPending()) {
        debounceSearchInput.cancel()
      }
      setLoading(true)
      debounceSearchInput(search)
      setPreviousSearch(search)
    },
    [debounceSearchInput, previousSearch],
  )

  const [val, setVal] = useState<
    | {
        value: UUID
        label: string
      }
    | undefined
    | null
  >()
  const [options, setOptions] = useState<
    {
      value: UUID
      label: string
    }[]
  >([])

  useEffect(() => {
    // Reset fix
    if (value === 'unassigned' && val?.value !== 'unassigned') {
      setVal(defaultValue)
    }
  }, [value, val, defaultValue])

  const { data, isLoadingNext, hasNext, loadNext, isRefreshing } =
    useUserSelectQuery(searchFilter, [orderBy])

  useEffect(() => {
    if (isLoadingNext || !hasNext) {
      return
    }

    if (loadNextPage) {
      loadNext(PAGE_SIZE)
    }

    setLoadNextPage(false)
  }, [hasNext, isLoadingNext, loadNext, loadNextPage])

  const scrollEvent = useCallback((e: SyntheticEvent) => {
    const target = e.target as HTMLTextAreaElement
    const scrollLeft = target.scrollHeight - target.scrollTop
    if (scrollLeft / target.clientHeight < 2) {
      setLoadNextPage(true)
    }
  }, [])

  const MenuList = useCallback(
    (
      props: MenuListProps<
        {
          value: string
          label: string
        },
        false,
        GroupBase<{
          value: string
          label: string
        }>
      >,
    ) => {
      return (
        <components.MenuList
          {...props}
          innerProps={{ ...props.innerProps, onScroll: scrollEvent }}
        >
          {props.children}
        </components.MenuList>
      )
    },
    [scrollEvent],
  )

  const formatUsers = useCallback(() => {
    if (!data.gwtUsers) return []

    return data.gwtUsers?.edges.map((user) => {
      return getControlledUserSelectObject(
        user.node.rowId,
        user.node.firstName,
        user.node.lastName,
        user.node.company?.name,
      )
    })
  }, [data.gwtUsers])

  useEffect(() => {
    setOptions(formatUsers())
  }, [data, formatUsers])

  const isLoading = loading || isRefreshing

  return (
    <div className="user-select-paginate">
      <Input.Label
        id={getUniqueId()}
        for={name}
        required={required}
        error={error}
      >
        {label}
      </Input.Label>
      <Select
        isDisabled={isDisabled}
        id={id}
        menuPosition={'fixed'}
        styles={{
          // CSSObjectWithLabel isn't working with this zIndex + typescript.  This follows the examples online for styling the portal.
          menuPortal: (base: any) => ({ ...base, zIndex: 10 }),
        }}
        value={val}
        options={isLoading ? [] : options}
        isLoading={isLoading}
        onChange={(newVal) => {
          if (!newVal && defaultValue) {
            if (defaultUnassigned) {
              newVal = defaultUnassigned()
            } else {
              newVal = defaultValue
            }
          }
          setVal(newVal)
          onChange(newVal?.value)
        }}
        components={{ MenuList }}
        onInputChange={search}
        isClearable
        defaultValue={defaultValue}
        required
      />
      <ControlledErrorMessage name={name} />
    </div>
  )
}

const useUserSelectQuery = (
  userFilter: GwtUserFilter | undefined = undefined,
  usersOrderBy: [GwtUsersOrderBy] | undefined = undefined,
) => {
  const [dataLength, setDataLength] = useState(PAGE_SIZE)

  const { data, isRefreshing } =
    useSubscriptionRefreshQueryWithIsRefreshing<ControlledUserSelectPaginationQuery>(
      graphql`
        query ControlledUserSelectPaginationQuery(
          $userFilter: GwtUserFilter
          $usersOrderBy: [GwtUsersOrderBy!]
          $count: Int!
        ) {
          ...ControlledUserSelectPaginationFragment_users
            @arguments(
              userFilter: $userFilter
              usersOrderBy: $usersOrderBy
              count: $count
            )
        }
      `,
      { userFilter, usersOrderBy, count: dataLength },
      {
        component: 'USER_SELECT',
        uniqueComponentId: '',
        onNotification: [
          'USER_PROFILE_CREATE_NOTIFICATION',
          'USER_PROFILE_EDIT_NOTIFICATION',
        ],
      },
    )

  const paginationFragmentHook = usePaginationFragment<
    ControlledUserSelectPaginationQuery,
    ControlledUserSelectPaginationFragment_users$key
  >(
    graphql`
      fragment ControlledUserSelectPaginationFragment_users on Query
      @argumentDefinitions(
        userFilter: { type: "GwtUserFilter" }
        usersOrderBy: { type: "[GwtUsersOrderBy!]" }
        count: { type: "Int" }
        cursor: { type: "Cursor" }
      )
      @refetchable(
        queryName: "ControlledUserSelectPaginationQuery_Refetchable"
      ) {
        gwtUsers(
          filter: $userFilter
          orderBy: $usersOrderBy
          first: $count
          after: $cursor
        ) @connection(key: "ControlledUserSelect_gwtUsers") {
          edges {
            node {
              rowId
              firstName
              lastName
              company {
                name
              }
            }
          }
          totalCount
        }
      }
    `,
    data,
  )

  const paginateLength = usePaginateDataLength(
    paginationFragmentHook?.data?.gwtUsers?.edges,
    PAGE_SIZE,
  )

  useEffect(() => {
    if (paginateLength) {
      setDataLength(paginateLength)
    }
  }, [paginateLength])

  return { ...paginationFragmentHook, pageData: data, isRefreshing }
}
