import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  EnterpriseIcon,
  GridIcon,
  ListUnorderedIcon,
} from '@enterprise-ui/icons'
import {
  Button,
  Card,
  Grid,
  Heading,
  Spinner,
  Table,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { FileView } from '@galleryjs/api-digital-assets'
import { xor } from 'lodash'
import { useMemo } from 'react'
import { useEffect, useState } from 'react'
import {
  useDeleteFilesFromAsset,
  useDownloadGalleryFilesHref,
  useGetAssetById,
  useGwtUser,
} from '../../hooks'
import { UUID } from '../../react-app-env'
import { getGwtFormattedDate } from '../../util/dateUtils'
import { GalleryAssetFileDisplay } from './GalleryAssetFileDisplay'
import { GalleryShowcaseDisplay } from './GalleryShowcaseDisplay'
import { faFileDownload, faSync } from '@fortawesome/free-solid-svg-icons'
import { UploadFile, uploadsAtom } from '../../state/atoms'
import { useAtomValue } from 'jotai'
import { GalleryAssetVirusScanFileDisplay } from './GalleryAssetVirusScanFileDisplay'
import { getFormattedUploadStatus } from '../../util/uploadUtil'
import { GalleryAssetListItem } from './GalleryAssetListItem'
import { PolCancelButton } from './PolButtons/PolCancelButton'
import { PolRetryButton } from './PolButtons/PolRetryButton'

interface GalleryAssetFilesDisplayProps {
  assetId: UUID | undefined
  title?: string
  enableDeletes?: boolean
  enableLightbox?: boolean
  enableSelect?: boolean
  displayCreatedBy: boolean
  displayFileNameBelowImage?: boolean
  displayFileNameInChip?: boolean
  createdBy?: string
  createdAt?: Date
  attempt?: number | undefined
  justify?: 'center' | 'left' | 'right'
  showcase?: boolean
  thumbnails?: boolean
  refetchInterval?: number | undefined
  showUploadingFiles?: boolean
}

const shouldShowFile = (
  assetName: string | undefined,
  fileName: string | undefined,
) => {
  return (
    assetName !== undefined &&
    fileName !== undefined &&
    fileName !== assetName &&
    fileName !== 'no_image_thumbnail'
  )
}

export const GalleryAssetFilesDisplay = ({
  enableDeletes = true,
  displayCreatedBy = false,
  showcase = false,
  displayFileNameBelowImage = false,
  displayFileNameInChip = true,
  enableLightbox = true,
  enableSelect = false,
  thumbnails = false,
  justify = 'left',
  showUploadingFiles = false,
  createdBy,
  attempt,
  ...props
}: GalleryAssetFilesDisplayProps) => {
  const assetData = useGetAssetById(props.assetId || '', {
    enabled: !!props.assetId,
    refetchInterval: props.refetchInterval,
    refetchIntervalInBackground: true,
  })

  const { download, isDownloading } = useDownloadGalleryFilesHref()
  const deleteFilesMutation = useDeleteFilesFromAsset(props.assetId || '')
  const [checkedItems, setCheckedItems] = useState<UUID[]>([])
  const [selectedFile, setSelectedFile] = useState<FileView | undefined>(
    undefined,
  )
  const uploadingData = useAtomValue(uploadsAtom)
  const [validUploads, setValidUploads] = useState<UploadFile[]>([])
  const user = useGwtUser()
  const [gridView, setGridView] = useState(
    user.userType === 'EXTERNAL_USER' ? false : true,
  )
  const [listView, setListView] = useState(
    user.userType === 'EXTERNAL_USER' ? true : false,
  )

  const validFiles = useMemo(() => {
    const files = Array.from(assetData?.data?.data?.files || [])
    return (
      files
        .map((file: FileView) => file)
        .filter(
          (f: FileView) =>
            f.id && shouldShowFile(assetData.data?.data.name, f.name),
        ) || []
    )
  }, [assetData])

  useEffect(() => {
    let data = uploadingData.filter((e) => e.galleryAssetId === props.assetId)
    setValidUploads(data)
  }, [props.assetId, uploadingData])

  useEffect(() => {
    setCheckedItems([])
  }, [deleteFilesMutation.isSuccess])

  useEffect(() => {
    if (!selectedFile) {
      setSelectedFile(validFiles[0])
    }
  }, [validFiles, selectedFile])

  if (assetData.isLoading || assetData.isFetching) {
    return <Spinner />
  }

  if (assetData.isError) {
    return <div>Failed to fetch files. Please try refreshing the page.</div>
  }

  if (validFiles.length === 0 && validUploads.length === 0) {
    return (
      <Grid.Container>
        <Grid.Item xs={2}>
          <Button iconOnly onClick={() => assetData.refetch()}>
            <FontAwesomeIcon icon={faSync} />
          </Button>
        </Grid.Item>
        <Grid.Item xs={10}>
          <div className="error-color hc-mt-xs"> No Files. </div>
        </Grid.Item>
      </Grid.Container>
    )
  }

  const gridCssClass = thumbnails
    ? 'files-container-thumbnails'
    : 'files-container-regular'

  return (
    <>
      {props.title && <h2 className="hc-mb-sm"> {props.title} </h2>}
      <Grid.Container>
        <Grid.Item xs={2}>
          <Button type="secondary" iconOnly onClick={() => assetData.refetch()}>
            <FontAwesomeIcon icon={faSync} />
          </Button>
        </Grid.Item>
        <Grid.Item xs={10} className="align-right">
          {enableDeletes && !deleteFilesMutation.isLoading && (
            <Button
              className="hc-mr-md"
              type="destructive"
              data-test-id="delete-files-button"
              disabled={checkedItems.length === 0}
              onClick={() => {
                if (!assetData.data?.data.id) return
                deleteFilesMutation.mutate({
                  assetId: assetData.data?.data.id,
                  files: checkedItems,
                })
              }}
            >
              <FontAwesomeIcon icon="trash" size="xs" />
              <div className="hc-ml-md">Delete selected file(s)</div>
            </Button>
          )}
          {deleteFilesMutation.isLoading && <Spinner size="dense" />}
          <Button
            type="secondary"
            className="hc-mr-md"
            data-test-id="add-seclected-files-button"
            disabled={checkedItems.length === 0 || isDownloading}
            onClick={() => {
              if (props.assetId) {
                if (checkedItems.length === 1) {
                  // single download
                  let f = validFiles.find((f) => f.id === checkedItems[0])
                  if (f) {
                    window.location.href = [...f.fileSources][0].accessUrl
                  } else {
                    download(props.assetId, checkedItems)
                  }
                } else {
                  download(props.assetId, checkedItems)
                }
              }
            }}
          >
            Download selected{' '}
            {isDownloading && <Spinner size="dense" className="hc-ml-xs" />}
          </Button>
          <Button
            type="primary"
            disabled={isDownloading}
            onClick={() => {
              if (props.assetId) {
                download(props.assetId, ['all'])
              }
            }}
          >
            Download all{' '}
            {isDownloading && <Spinner size="dense" className="hc-ml-xs" />}
          </Button>
        </Grid.Item>
        {showcase && (
          <Grid.Item xs={12}>
            <GalleryShowcaseDisplay file={selectedFile} />
          </Grid.Item>
        )}
        <Grid.Item xs={12} className="align-right">
          <Button
            className="hc-mr-xs"
            iconOnly
            type={gridView ? 'primary' : 'secondary'}
            onClick={() => {
              setGridView(true)
              setListView(false)
            }}
          >
            <EnterpriseIcon icon={GridIcon} />
          </Button>
          <Button
            iconOnly
            type={listView ? 'primary' : 'secondary'}
            onClick={() => {
              setGridView(false)
              setListView(true)
            }}
          >
            <EnterpriseIcon icon={ListUnorderedIcon} />
          </Button>
        </Grid.Item>

        <div className={gridCssClass} style={{ justifyContent: justify }}>
          {showUploadingFiles &&
            gridView &&
            validUploads.map((uploadingFile: UploadFile, index: number) => {
              return (
                <GalleryAssetVirusScanFileDisplay
                  key={index}
                  file={uploadingFile}
                  attempt={attempt}
                  enableSelect={enableSelect}
                  enableLightbox={enableLightbox}
                  displayFileNameBelowImage={displayFileNameBelowImage}
                  displayFileNameInChip={displayFileNameInChip}
                  thumbnail={thumbnails}
                  onClick={(file) => {}}
                >
                  {displayCreatedBy && (
                    <div className="date-text">
                      {`Status: ${getFormattedUploadStatus(
                        uploadingFile.uploadStatus,
                      )}`}
                    </div>
                  )}
                  {uploadingFile.uploadStatus === 'ERROR' && (
                    <div className="date-text">
                      {`Error: ${uploadingFile.uploadError}`}
                    </div>
                  )}
                  {uploadingFile.uploadStatus === 'SUCCESS' && (
                    <div className="date-text">
                      {
                        'Please click on the trash can to remove this file from the uploads as it has successfully uploaded.'
                      }
                    </div>
                  )}
                </GalleryAssetVirusScanFileDisplay>
              )
            })}
          {gridView &&
            validFiles.map((file: FileView, index: number) => {
              if (!file.id) {
                return <></>
              }
              return (
                <GalleryAssetFileDisplay
                  key={index}
                  file={file}
                  attempt={attempt}
                  enableCheckBox
                  enableSelect={enableSelect}
                  enableLightbox={enableLightbox}
                  displayFileNameBelowImage={displayFileNameBelowImage}
                  displayFileNameInChip={displayFileNameInChip}
                  thumbnail={thumbnails}
                  onCheck={(file) => {
                    if (file.id) {
                      setCheckedItems(xor(checkedItems, [file.id]))
                    }
                  }}
                  onClick={(file) => {
                    if (file) {
                      setSelectedFile(file)
                    }
                  }}
                  checked={checkedItems.includes(file.id)}
                  selected={selectedFile?.id === file.id}
                >
                  {displayCreatedBy && (
                    <div className="date-text">
                      {`By ${createdBy || 'unknown'} on ${getGwtFormattedDate(
                        file.createdAt,
                      )}`}
                    </div>
                  )}
                </GalleryAssetFileDisplay>
              )
            })}
        </div>
        {listView && (
          <Grid.Item xs={12}>
            <Card>
              <Table name="Assets" width="100%" cellPadding="normal">
                <Table.Head>
                  <Table.Row align="center">
                    <Table.Header xs={12}>
                      <Heading size={5}>Assets</Heading>
                    </Table.Header>
                  </Table.Row>
                  <Table.Row align="center">
                    <Table.Header xs={5}>Items</Table.Header>
                    <Table.Header xs={3}>Status</Table.Header>
                    <Table.Header xs={2}>Uploaded</Table.Header>
                    <Table.Header xs={2}>Actions</Table.Header>
                  </Table.Row>
                </Table.Head>
                <Table.Body
                  style={{
                    display: 'block',
                    maxHeight: '580px',
                    overflowY: 'auto',
                  }}
                >
                  {validUploads.map(
                    (uploadingFile: UploadFile, index: number) => {
                      if (!uploadingFile.galleryAssetId) {
                        return <></>
                      }
                      return (
                        <Table.Row align="center" key={uploadingFile.rowId}>
                          <Table.Data xs={5}>
                            {uploadingFile.fileName}
                          </Table.Data>
                          <Table.Data xs={3}>
                            {getFormattedUploadStatus(
                              uploadingFile.uploadStatus,
                            )}
                            {uploadingFile.uploadError
                              ? `: ${uploadingFile.uploadError}`
                              : ''}
                          </Table.Data>
                          <Table.Data xs={2}>-</Table.Data>
                          <Table.Data align="flex-end" xs={2}>
                            <div className="flex-row">
                              <PolRetryButton
                                file={uploadingFile}
                                className="hc-mr-md"
                              />
                              <PolCancelButton file={uploadingFile} />
                            </div>
                          </Table.Data>
                        </Table.Row>
                      )
                    },
                  )}
                  {validFiles.map((file: FileView, index: number) => {
                    if (!file.id) {
                      return <></>
                    }
                    return (
                      <Table.Row align="center" key={file.id}>
                        <Table.Data xs={5}>
                          <GalleryAssetListItem
                            key={index}
                            file={file}
                            enableCheckBox
                            enableSelect={enableSelect}
                            enableLightbox={enableLightbox}
                            onCheck={(file) => {
                              if (file.id) {
                                setCheckedItems(xor(checkedItems, [file.id]))
                              }
                            }}
                            onClick={(file) => {
                              if (file) {
                                setSelectedFile(file)
                              }
                            }}
                            checked={checkedItems.includes(file.id)}
                            selected={selectedFile?.id === file.id}
                          />
                        </Table.Data>
                        <Table.Data xs={3}>uploaded</Table.Data>
                        <Table.Data xs={2}>
                          {getGwtFormattedDate(file.createdAt)}
                        </Table.Data>
                        <Table.Data align="flex-end" xs={2}>
                          <Tooltip location="left" content="Download file">
                            <Button
                              iconOnly
                              onClick={() => {
                                window.location.href = [
                                  ...file.fileSources,
                                ]?.[0]?.accessUrl
                              }}
                            >
                              <FontAwesomeIcon icon={faFileDownload} />
                            </Button>
                          </Tooltip>
                        </Table.Data>
                      </Table.Row>
                    )
                  })}
                </Table.Body>
              </Table>
            </Card>
          </Grid.Item>
        )}
      </Grid.Container>
    </>
  )
}
