import { Grid, Layout, List } from '@enterprise-ui/canvas-ui-react'
import React, { ReactElement } from 'react'
import './requestadgroups.scss'
import { useEnv } from '@praxis/component-runtime-env'
import { useGwtUser } from '../../hooks'
import { GwtEnv } from '../../types'

interface RequestAdGroupsProps {
  children: ReactElement
}

// The reviewer dashboard uses some of the components from the admin dashboard
export const RequestAdGroups = (props: RequestAdGroupsProps) => {
  const myUser = useGwtUser()
  const env = useEnv<GwtEnv>()

  const neededAdGroups = env.requiredAdGroups.filter(
    (adGroup) => !myUser.memberOf.includes(adGroup),
  )

  if (neededAdGroups.length !== 0) {
    return (
      <Layout.Body includeRail className="request-ad-groups">
        <Grid.Container className="reviewer hc-pl-expanded hc-pr-expanded hc-pt-2x hc-pb-expanded request-header">
          <Grid.Item xs={12}>
            <h1>Request Entitlements</h1>
          </Grid.Item>
          <Grid.Item xs={12}>
            <div className="subtext">
              You will not be able to use Assent until you request the following
              Entitlement(s)
            </div>
          </Grid.Item>
          <Grid.Item xs={12} className="ad-text">
            {neededAdGroups.map((adGroup: string, i) => (
              <div key={i}>{adGroup}</div>
            ))}
          </Grid.Item>
          <Grid.Item xs={4} />
          <Grid.Item>
            <List>
              <List.Item />
              <List.Item className="bold">Steps</List.Item>
              <List.Item>
                <div>
                  1. Go{'  '}
                  <a
                    className="link"
                    rel="noreferrer"
                    href="https://myaccess.target.com/request-access"
                    target="_blank"
                  >
                    here
                  </a>{' '}
                  and log in.
                </div>
              </List.Item>
              <List.Item>
                2. Go to Request New Access in the left navigation bar.
              </List.Item>
              <List.Item>3. Make sure User / NUIDs is set to Self.</List.Item>
              <List.Item>
                4. Make sure Access Type is set to 'All Access Type' or
                'Entitlement'.
              </List.Item>
              <List.Item>5. Search for access in red text above.</List.Item>
              <List.Item>6. Click on the access once it shows up.</List.Item>
              <List.Item>
                7. Give a justification of: "Assent user" and click submit.
              </List.Item>
              <List.Item className="bold">
                Repeat steps 5-7 for each entitlement that you need above.
              </List.Item>
              <List.Item />
            </List>
          </Grid.Item>
          <Grid.Item xs={12}>
            <div className="subtext">
              Please give at least one work day for your request to be
              processed.
            </div>
          </Grid.Item>
        </Grid.Container>
      </Layout.Body>
    )
  } else {
    return props.children
  }
}
