/**
 * @generated SignedSource<<5fdaebc2f3005741c9feedfb15165630>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type ProgramType = "ACCESSORIES_3_D_ACCREDITATION" | "A_A_3_D_ACCREDITATION" | "NONE" | "SOFT_HOME_3_D_ACCREDITATION" | "_3_D_STYLING" | "%future added value";
export type UserStatus = "ACTIVE" | "DEACTIVATED" | "SUSPENDED" | "%future added value";
export type UserType = "ADMIN" | "BLOCKED" | "EXTERNAL_USER" | "INTERNAL_USER" | "REVIEWER" | "%future added value";
export type useGwtRestApi_userUpdateQuery$variables = {};
export type useGwtRestApi_userUpdateQuery$data = {
  readonly myUser: {
    readonly company: {
      readonly name: string;
    } | null;
    readonly emailBulk: boolean;
    readonly emailBulkTime: Date;
    readonly emailModuleNotifications: ReadonlyArray<string | null>;
    readonly emailOnAllAssignmentStatusChanges: boolean;
    readonly firstName: string;
    readonly fullName: string;
    readonly id: string;
    readonly lastName: string;
    readonly partnersonline: boolean;
    readonly programs: ReadonlyArray<ProgramType | null>;
    readonly rowId: UUID;
    readonly status: UserStatus;
    readonly userType: UserType;
  } | null;
};
export type useGwtRestApi_userUpdateQuery = {
  response: useGwtRestApi_userUpdateQuery$data;
  variables: useGwtRestApi_userUpdateQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userType",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailOnAllAssignmentStatusChanges",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailBulk",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailBulkTime",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emailModuleNotifications",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "programs",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "partnersonline",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "useGwtRestApi_userUpdateQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GwtUser",
        "kind": "LinkedField",
        "name": "myUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v13/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "useGwtRestApi_userUpdateQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "GwtUser",
        "kind": "LinkedField",
        "name": "myUser",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          (v1/*: any*/),
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v12/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v13/*: any*/),
              (v0/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8d24aaf4b9c038bff2e670ecd4cc617a",
    "id": null,
    "metadata": {},
    "name": "useGwtRestApi_userUpdateQuery",
    "operationKind": "query",
    "text": "query useGwtRestApi_userUpdateQuery {\n  myUser {\n    id\n    rowId\n    userType\n    emailOnAllAssignmentStatusChanges\n    emailBulk\n    emailBulkTime\n    emailModuleNotifications\n    status\n    fullName\n    firstName\n    lastName\n    programs\n    partnersonline\n    company {\n      name\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0bb294675de3e96d9e970188ba81b2d2";

export default node;
