import { Grid } from '@enterprise-ui/canvas-ui-react'
import './GroupTemplateModules.scss'
import { GroupTemplateModulesTable } from './GroupTemplateModulesTable'
import { useFormContext } from 'react-hook-form'
import React from 'react'
import { GroupTemplateModulesSelectedTable } from './GroupTemplateModulesSelectedTable'
import { GroupTemplateName } from './GroupTemplateName'
import { NewModuleModal } from '../../Admin/NewModuleModal'
import { noop } from 'lodash'

interface GroupTemplateModulesProps {
  onDeleteModule?: (module: any) => void
}

export const GroupTemplateModules = ({
  onDeleteModule: handleDeleteModule = noop,
}: GroupTemplateModulesProps) => {
  const { watch } = useFormContext()

  const modules = watch('modules')
  const name = watch('name')

  return (
    <div className="group-template-modules">
      <GroupTemplateName modules={modules} name={name} />
      <Grid.Container className="hc-pb-lg">
        <Grid.Item xs={7} className="hc-mt-md">
          <div className="hc-fs-lg hc-mb-md">
            Add modules to the group template
          </div>
          <GroupTemplateModulesTable />
        </Grid.Item>
        <Grid.Item xs={5} className="hc-mt-md">
          <div className="hc-mb-md hc-ta-right">
            <NewModuleModal />
          </div>
          <GroupTemplateModulesSelectedTable
            onDeleteModule={handleDeleteModule}
          />
        </Grid.Item>
      </Grid.Container>
    </div>
  )
}
