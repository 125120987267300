import { useController } from 'react-hook-form'
import { Form } from '@enterprise-ui/canvas-ui-react'
import { get, noop } from 'lodash'
import { getRequiredMessage } from '../util/formUtils'

interface PropTypes {
  name: string
  label?: string
  required?: boolean
  requiredMessage?: string
  className?: string
  onChange?: typeof noop
  disabled?: boolean
  rules?: {
    value: any
    message: string
  }
  defaultValue?: any
  type?: any
  placeholder?: string
  options?: {
    value: string
    label: string
    hidden?: boolean
    disabled?: boolean
  }[]
}

const onUpdateFieldTypes = ['select', 'radio', 'checkboxes']

export const ControlledFormField = ({
  label,
  name,
  className,
  required = false,
  requiredMessage,
  disabled = false,
  onChange: onChangeProp,
  defaultValue,
  rules = {} as PropTypes['rules'],
  type = 'text',
  options: optionsProp = [],
  ...props
}: PropTypes) => {
  const {
    field: {
      ref,
      onChange,
      value = type === 'checkboxes' ? [] : '',
      ...inputProps
    },
    formState: { errors },
  } = useController({
    name,
    rules: {
      required: getRequiredMessage({
        name,
        label,
        required,
        requiredMessage,
      }),
      ...rules,
    },
    defaultValue,
  })
  const error = get(errors, name)

  const onChangeProps = {
    onUpdate: noop,
    onChange: noop,
  }
  if (onUpdateFieldTypes.includes(type)) {
    onChangeProps.onUpdate = (id, fieldValue) => {
      onChangeProp?.(fieldValue)
      onChange(fieldValue)
    }
  } else {
    onChangeProps.onChange = ({ target: { value: fieldValue, checked } }) => {
      if (['toggle', 'checkbox'].includes(type)) {
        onChangeProp?.(checked)
        onChange(checked)
      } else if (type === 'number') {
        onChangeProp?.(parseFloat(fieldValue))
        onChange(parseFloat(fieldValue))
      } else {
        onChangeProp?.(fieldValue)
        onChange(fieldValue)
      }
    }
  }
  let options = optionsProp
  if (type === 'select' && props.placeholder) {
    options = [
      { value: '', label: props.placeholder, hidden: true, disabled: true },
      ...optionsProp,
    ]
  }

  return (
    <Form.Field
      id={name}
      className={className}
      label={label}
      error={!!error}
      errorText={error?.message}
      required={required}
      value={value}
      checked={value}
      disabled={disabled}
      type={type}
      {...props}
      options={options}
      {...onChangeProps}
      {...inputProps}
    />
  )
}
