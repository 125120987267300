import {
  SingleResponseAssetView,
  SingleResponseBoolean,
  SingleResponseEmtFileSignResponse,
} from '@galleryjs/api-digital-assets'
import { AxiosResponse } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import {
  useMutation,
  UseMutationOptions,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query'
import { UUID } from '../react-app-env'
import { useBanner } from './useBanner'
import { useGwtRestApi } from './useGwtRestApi'
import { usePublishGlobalNotification } from './useNotifications'
import { useUpdateAssetTimestamp } from './useUpdateAssetTimestamp'

const throwErrorOnInvalidStatus = (response: AxiosResponse) => {
  if (!response.status) {
    throw Error('Api Request Failed without Status Code')
  }
  if (response.status !== 200) {
    throw Error(`Api Request Failed with: ${response.status}`)
  }
}

const fetch = async (promise: Promise<any>) => {
  const res = await promise
  throwErrorOnInvalidStatus(res)
  return res.data
}

const useRaiseGalleryErrorBannerIfIssue = (query: UseQueryResult<any, any>) => {
  const { setBanner } = useBanner()

  const [startTime, setStartTime] = useState(Date.now())

  const [fetchingStatus, setFetchingStatus] = useState(false)

  const [timerTrigger, setTimerTrigger] = useState(false)
  const [isWaitingForTimeout, setIsWaitingForTimeout] = useState(false)

  // callback to check and display banner on slow network call
  const displayBannerIfSlowNetworkCall = useCallback(() => {
    if (Date.now() - startTime >= 10000) {
      setBanner(
        "Experiencing degraded performance connecting to external API's.  Expect slower performance.",
        'INFO',
      )
    }
  }, [setBanner, startTime])

  const toggle = useCallback(() => {
    setTimerTrigger(true)
    setIsWaitingForTimeout(false)
  }, [])

  // toggle for checking if a query is timed out every 3 seconds
  useEffect(() => {
    if (query.isFetching && !isWaitingForTimeout) {
      setIsWaitingForTimeout(true)
      setTimerTrigger(false)
      setTimeout(() => {
        toggle()
      }, 3000)
    }
  }, [isWaitingForTimeout, query, toggle])

  useEffect(() => {
    if (query.isFetching && !fetchingStatus) {
      setFetchingStatus(true)
      setStartTime(Date.now())
    }

    if (!query.isFetching && fetchingStatus) {
      //query finished fetching, check the time
      displayBannerIfSlowNetworkCall()
      setFetchingStatus(false)
    }

    if (query.isFetching && fetchingStatus && timerTrigger) {
      displayBannerIfSlowNetworkCall()
    }
  }, [
    displayBannerIfSlowNetworkCall,
    fetchingStatus,
    query.isFetching,
    timerTrigger,
  ])

  // trigger setting the banner on api error
  useEffect(() => {
    if (query.isError) {
      setBanner(
        "Failed to reach external API's.  Please try refreshing the page.  If there are still issues, please report an error.",
        'IMPORTANT',
      )
    }
  }, [query.isError, setBanner])
}

export const useGetAssetById = (
  assetId: UUID,
  options?: UseQueryOptions<SingleResponseAssetView>,
) => {
  const { proxyGetAsset } = useGwtRestApi()
  const query = useQuery<SingleResponseAssetView>(
    ['getAsset', assetId],
    () => fetch(proxyGetAsset(assetId)),
    options,
  )

  useRaiseGalleryErrorBannerIfIssue(query)

  return query
}

export const useDownloadAssetFiles = (
  assetId: UUID,
  files: UUID[],
  options?: UseQueryOptions<SingleResponseEmtFileSignResponse>,
) => {
  const { proxyDownloadAssetFiles } = useGwtRestApi()
  const query = useQuery<SingleResponseEmtFileSignResponse>(
    ['downloadAssetFiles', assetId],
    () => fetch(proxyDownloadAssetFiles(assetId, files)),
    options,
  )

  useRaiseGalleryErrorBannerIfIssue(query)

  return query
}

export const useDeleteFilesFromAsset = (
  assetId: UUID,
  options?: UseMutationOptions<
    SingleResponseBoolean,
    unknown,
    { assetId: UUID; files: UUID[] }
  >,
) => {
  const { proxyDeleteFiles } = useGwtRestApi()
  const queryClient = useQueryClient()
  const { publishToNetwork } = usePublishGlobalNotification()
  const { publishAssetUpdate } = useUpdateAssetTimestamp()

  return useMutation<
    SingleResponseBoolean,
    unknown,
    { assetId: string; files: string[] }
  >(
    ['deleteAssetFiles'],
    (data) => {
      return fetch(proxyDeleteFiles(data.assetId, data.files))
    },
    {
      ...options,
      onSuccess: async (data) => {
        if (data.data) {
          publishToNetwork('GALLERY_ASSET_INVALIDATED_NOTIFICATION', {
            assetId,
          })
          publishAssetUpdate(assetId)
          await queryClient.invalidateQueries(['getAsset', assetId])
        }
      },
    },
  )
}
