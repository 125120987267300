/**
 * @generated SignedSource<<78089585a1879043d31c2b8d63da2e26>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssignTemplateCardPopupModuleFragment$data = {
  readonly attempts: number;
  readonly flow: {
    readonly createdAt: Date | null;
    readonly dueAt: Date | null;
    readonly durationSeconds: number;
    readonly flowReviewers: {
      readonly nodes: ReadonlyArray<{
        readonly reviewer: {
          readonly company: {
            readonly name: string;
          } | null;
          readonly firstName: string;
          readonly lastName: string;
          readonly rowId: UUID;
        } | null;
      }>;
    };
    readonly name: string;
    readonly rowId: UUID;
  } | null;
  readonly " $fragmentType": "AssignTemplateCardPopupModuleFragment";
};
export type AssignTemplateCardPopupModuleFragment$key = {
  readonly " $data"?: AssignTemplateCardPopupModuleFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignTemplateCardPopupModuleFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssignTemplateCardPopupModuleFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Flow",
      "kind": "LinkedField",
      "name": "flow",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dueAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "durationSeconds",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1
            }
          ],
          "concreteType": "FlowReviewersConnection",
          "kind": "LinkedField",
          "name": "flowReviewers",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "FlowReviewer",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GwtUser",
                  "kind": "LinkedField",
                  "name": "reviewer",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "Company",
                      "kind": "LinkedField",
                      "name": "company",
                      "plural": false,
                      "selections": [
                        (v0/*: any*/)
                      ],
                      "storageKey": null
                    },
                    (v1/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "flowReviewers(first:1)"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "attempts",
      "storageKey": null
    }
  ],
  "type": "Module",
  "abstractKey": null
};
})();

(node as any).hash = "f640924e0f032aaae7fde0af9e29b808";

export default node;
