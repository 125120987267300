/**
 * @generated SignedSource<<c45c53a37217b1f5da42c563bf581e58>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AssignGroupTemplateMutation$variables = {
  assignTo: UUID;
  moduleGroupFlowId: UUID;
};
export type AssignGroupTemplateMutation$data = {
  readonly assignModuleGroup: {
    readonly clientMutationId: string | null;
  } | null;
};
export type AssignGroupTemplateMutation = {
  response: AssignGroupTemplateMutation$data;
  variables: AssignGroupTemplateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "assignTo"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "moduleGroupFlowId"
},
v2 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "assignedTo",
            "variableName": "assignTo"
          },
          {
            "kind": "Variable",
            "name": "moduleGroupToAssignFlowId",
            "variableName": "moduleGroupFlowId"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "AssignModuleGroupPayload",
    "kind": "LinkedField",
    "name": "assignModuleGroup",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssignGroupTemplateMutation",
    "selections": (v2/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "AssignGroupTemplateMutation",
    "selections": (v2/*: any*/)
  },
  "params": {
    "cacheID": "aed863e59153f246ee938c6624456781",
    "id": null,
    "metadata": {},
    "name": "AssignGroupTemplateMutation",
    "operationKind": "mutation",
    "text": "mutation AssignGroupTemplateMutation(\n  $moduleGroupFlowId: UUID!\n  $assignTo: UUID!\n) {\n  assignModuleGroup(input: {moduleGroupToAssignFlowId: $moduleGroupFlowId, assignedTo: $assignTo}) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "94de2e786ce834baf5a9faafb20997f5";

export default node;
