/**
 * @generated SignedSource<<bf381894e79dbd47bfa7a1262c68efaf>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type ProgramType = "ACCESSORIES_3_D_ACCREDITATION" | "A_A_3_D_ACCREDITATION" | "NONE" | "SOFT_HOME_3_D_ACCREDITATION" | "_3_D_STYLING" | "%future added value";
export type SoftwareType = "BROWZWEAR" | "CLO" | "NONE" | "OPTITEX" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UserCertifications$data = {
  readonly certifications: {
    readonly nodes: ReadonlyArray<{
      readonly assignedTo: {
        readonly fullName: string;
      } | null;
      readonly completedAt: Date | null;
      readonly module: {
        readonly programType: ProgramType | null;
        readonly softwareType: SoftwareType | null;
      } | null;
      readonly name: string;
    }>;
  };
  readonly fullName: string;
  readonly " $fragmentType": "UserCertifications";
};
export type UserCertifications$key = {
  readonly " $data"?: UserCertifications$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserCertifications">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserCertifications",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "FlowsConnection",
      "kind": "LinkedField",
      "name": "certifications",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Flow",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "GwtUser",
              "kind": "LinkedField",
              "name": "assignedTo",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "completedAt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Module",
              "kind": "LinkedField",
              "name": "module",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "programType",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "softwareType",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "GwtUser",
  "abstractKey": null
};
})();

(node as any).hash = "bcb6afbbab06e33def37c811b1abac08";

export default node;
