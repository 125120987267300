/**
 * @generated SignedSource<<743c33a09d20ea91dd20e991b2af5a18>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserStatus = "ACTIVE" | "DEACTIVATED" | "SUSPENDED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UserStatusChip_gwtUser$data = {
  readonly status: UserStatus;
  readonly " $fragmentType": "UserStatusChip_gwtUser";
};
export type UserStatusChip_gwtUser$key = {
  readonly " $data"?: UserStatusChip_gwtUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserStatusChip_gwtUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserStatusChip_gwtUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    }
  ],
  "type": "GwtUser",
  "abstractKey": null
};

(node as any).hash = "c23f1a35b67548ae6dd7f13f165d7b29";

export default node;
