/**
 * @generated SignedSource<<f80f6c16dfbe8efa7ae44069451c96bb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssignmentStatus = "ACCEPTED" | "CANCELED" | "IN_PROGRESS" | "NONE" | "NOT_STARTED" | "READY_FOR_REVIEW" | "REJECTED" | "UNDER_REVIEW" | "%future added value";
export type LinkedType = "MODULE" | "MODULE_GROUP" | "TASK" | "TEMPLATE" | "UNKNOWN" | "%future added value";
export type ToDoCardQuery$variables = {
  assignedToId: UUID;
};
export type ToDoCardQuery$data = {
  readonly inProgressFlows: {
    readonly nodes: ReadonlyArray<{
      readonly assignedAt: Date | null;
      readonly assignedTo: {
        readonly id: string;
        readonly rowId: UUID;
      } | null;
      readonly assignmentStatus: AssignmentStatus;
      readonly dueAt: Date | null;
      readonly linkedType: LinkedType;
      readonly module: {
        readonly moduleGroup: {
          readonly flow: {
            readonly name: string;
          } | null;
        } | null;
      } | null;
      readonly name: string;
      readonly rowId: UUID;
      readonly " $fragmentSpreads": FragmentRefs<"FlowLink_flow" | "StatusChip_flow">;
    }>;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"UpcomingCardFragment">;
};
export type ToDoCardQuery = {
  response: ToDoCardQuery$data;
  variables: ToDoCardQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "assignedToId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "assignedToId",
    "variableName": "assignedToId"
  }
],
v2 = {
  "fields": (v1/*: any*/),
  "kind": "ObjectValue",
  "name": "condition"
},
v3 = [
  (v2/*: any*/),
  {
    "kind": "Literal",
    "name": "filter",
    "value": {
      "assignmentStatus": {
        "notIn": [
          "ACCEPTED",
          "NOT_STARTED"
        ]
      }
    }
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "GwtUser",
  "kind": "LinkedField",
  "name": "assignedTo",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    (v4/*: any*/)
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assignedAt",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "dueAt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "linkedType",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assignmentStatus",
  "storageKey": null
},
v12 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "Flow",
    "kind": "LinkedField",
    "name": "flow",
    "plural": false,
    "selections": [
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "storageKey": null
  },
  (v6/*: any*/)
],
v13 = {
  "alias": null,
  "args": null,
  "concreteType": "ModuleGroup",
  "kind": "LinkedField",
  "name": "moduleGroup",
  "plural": false,
  "selections": (v12/*: any*/),
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assignmentDueAtStatus",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ToDoCardQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "UpcomingCardFragment"
      },
      {
        "alias": "inProgressFlows",
        "args": (v3/*: any*/),
        "concreteType": "FlowsConnection",
        "kind": "LinkedField",
        "name": "flows",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Flow",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Module",
                "kind": "LinkedField",
                "name": "module",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ModuleGroup",
                    "kind": "LinkedField",
                    "name": "moduleGroup",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Flow",
                        "kind": "LinkedField",
                        "name": "flow",
                        "plural": false,
                        "selections": [
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "FlowLink_flow"
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "StatusChip_flow"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ToDoCardQuery",
    "selections": [
      {
        "alias": "upcomingFlows",
        "args": [
          (v2/*: any*/),
          {
            "kind": "Literal",
            "name": "filter",
            "value": {
              "assignmentStatus": {
                "equalTo": "NOT_STARTED"
              }
            }
          }
        ],
        "concreteType": "FlowsConnection",
        "kind": "LinkedField",
        "name": "flows",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Flow",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Module",
                "kind": "LinkedField",
                "name": "module",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "flowId",
                    "storageKey": null
                  },
                  (v13/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Module",
                    "kind": "LinkedField",
                    "name": "groupModulePreviousModuleFlow",
                    "plural": false,
                    "selections": (v12/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Flow",
                    "kind": "LinkedField",
                    "name": "flow",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      (v11/*: any*/),
                      (v14/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "attempts",
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "inProgressFlows",
        "args": (v3/*: any*/),
        "concreteType": "FlowsConnection",
        "kind": "LinkedField",
        "name": "flows",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Flow",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Module",
                "kind": "LinkedField",
                "name": "module",
                "plural": false,
                "selections": [
                  (v13/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v14/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "008b7dd9a58ba290870be298af771052",
    "id": null,
    "metadata": {},
    "name": "ToDoCardQuery",
    "operationKind": "query",
    "text": "query ToDoCardQuery(\n  $assignedToId: UUID!\n) {\n  ...UpcomingCardFragment_1s8zP6\n  inProgressFlows: flows(condition: {assignedToId: $assignedToId}, filter: {assignmentStatus: {notIn: [ACCEPTED, NOT_STARTED]}}) {\n    nodes {\n      rowId\n      module {\n        moduleGroup {\n          flow {\n            name\n            id\n          }\n          id\n        }\n        id\n      }\n      name\n      assignedTo {\n        id\n        rowId\n      }\n      assignedAt\n      dueAt\n      linkedType\n      assignmentStatus\n      ...FlowLink_flow\n      ...StatusChip_flow\n      id\n    }\n  }\n}\n\nfragment FlowLink_flow on Flow {\n  rowId\n  linkedType\n}\n\nfragment StatusChip_flow on Flow {\n  dueAt\n  assignmentStatus\n  assignmentDueAtStatus\n}\n\nfragment UpcomingCardFragment_1s8zP6 on Query {\n  upcomingFlows: flows(condition: {assignedToId: $assignedToId}, filter: {assignmentStatus: {equalTo: NOT_STARTED}}) {\n    nodes {\n      rowId\n      module {\n        flowId\n        ...UpcomingModuleFragment\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment UpcomingModuleFragment on Module {\n  flowId\n  moduleGroup {\n    flow {\n      name\n      id\n    }\n    id\n  }\n  groupModulePreviousModuleFlow {\n    flow {\n      name\n      id\n    }\n    id\n  }\n  flow {\n    ...StatusChip_flow\n    name\n    id\n  }\n  attempts\n}\n"
  }
};
})();

(node as any).hash = "7dd0c079f7be6a732ffddc9f79110bfa";

export default node;
