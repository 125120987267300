/**
 * @generated SignedSource<<7c6de9c1b3a9d4b1e96cef46b75e6d9f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserCertificationsDisplay$data = {
  readonly allCertifications: ReadonlyArray<string | null> | null;
  readonly " $fragmentType": "UserCertificationsDisplay";
};
export type UserCertificationsDisplay$key = {
  readonly " $data"?: UserCertificationsDisplay$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserCertificationsDisplay">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserCertificationsDisplay",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allCertifications",
      "storageKey": null
    }
  ],
  "type": "GwtUser",
  "abstractKey": null
};

(node as any).hash = "b311b7ee34d612d2582fec4f0052121f";

export default node;
