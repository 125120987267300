import { Card, Grid, Divider } from '@enterprise-ui/canvas-ui-react'
import { graphql, useFragment } from 'react-relay'
import { UpcomingCardFragment$key } from './__generated__/UpcomingCardFragment.graphql'
import { UpcomingModule } from './UpcomingModule'

interface UpcomingCardProps {
  queryRef: UpcomingCardFragment$key | null | undefined
}

export const UpcomingCard = ({ queryRef }: UpcomingCardProps) => {
  const data = useFragment(
    graphql`
      fragment UpcomingCardFragment on Query
      @argumentDefinitions(assignedToId: { type: "UUID" }) {
        upcomingFlows: flows(
          condition: { assignedToId: $assignedToId }
          filter: { assignmentStatus: { equalTo: NOT_STARTED } }
        ) {
          nodes {
            rowId
            module {
              flowId
              ...UpcomingModuleFragment
            }
          }
        }
      }
    `,
    queryRef || null,
  )

  return (
    <Card className="hc-mt-expanded upcoming-card">
      <div className="hc-pa-none cardHeader flex header hc-pa-lg">
        <Grid.Container spacing="none" align="center" className="flex-grow">
          <Grid.Item xs={12}>
            <div className="card-header-text">Upcoming</div>
          </Grid.Item>
        </Grid.Container>
      </div>
      <Divider />
      <div className="hc-pa-none card-scroll upcoming-scroll-area flex-grow">
        {data?.upcomingFlows?.nodes?.map((flow) => {
          return <UpcomingModule key={flow.rowId} moduleRef={flow?.module} />
        })}
        <Divider />
      </div>
    </Card>
  )
}
