import SunEditorComponent from 'suneditor-react'
import 'suneditor/dist/css/suneditor.min.css' // Import Sun Editor's CSS File
import './SunEditor.scss'

interface PropTypes {
  initialValue?: string
  height?: string
}

export const SunEditorDisplay = ({ height, initialValue }: PropTypes) => (
  <div className="sun-wrapper-display" spellCheck="false">
    <SunEditorComponent
      setDefaultStyle="font-family: 'Helvetica For Target'; font-size: 14px; color: #333333;"
      disableToolbar
      hideToolbar
      readOnly
      setContents={initialValue || ''}
      setOptions={{
        resizingBar: false,
      }}
      height={height ?? '100%'}
    />
  </div>
)
