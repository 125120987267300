import { Card, Grid } from '@enterprise-ui/canvas-ui-react'
import { useEffect } from 'react'
import { graphql, useFragment, useMutation } from 'react-relay'
import { UserAvatar } from '../Users/UserAvatar'
import { CommentCardFragment$key } from './__generated__/CommentCardFragment.graphql'
import './Comment.scss'
import { CommentCardResponse } from './CommentCardResponse'
import { CommentReply } from './CommentReply'
import { getTimeFromCreatedAtString } from '../../util/dateUtils'
import { SuspenseRingLoader } from '../../loadingComponents/SuspenseRingLoader'
import { CommentCardMutation } from './__generated__/CommentCardMutation.graphql'
import { usePublishGlobalNotification } from '../../hooks/useNotifications'
import { CommentOptions } from './CommentOptions'
import { EditableComment } from './EditableComment'

interface CommentCardProps {
  flowCommentRef: CommentCardFragment$key | null
}

export const CommentCard = (props: CommentCardProps) => {
  const data = useFragment(
    graphql`
      fragment CommentCardFragment on FlowComment {
        ...CommentReplyFragment
        flowId
        comment {
          ...EditableComment_editable
          ...CommentOptions_comment
          rowId
          commentsByResponseTo(orderBy: CREATED_AT_ASC) {
            nodes {
              ...CommentCardResponseFragment
            }
          }
          createdAt
          commentText
          gwtUser {
            ...UserAvatar_gwtUser
          }
        }
      }
    `,
    props.flowCommentRef || null,
  )

  const { publishToLocal } = usePublishGlobalNotification()

  const [commit] = useMutation<CommentCardMutation>(graphql`
    mutation CommentCardMutation($flowId: UUID!) {
      updateViewedComments(input: { flowId: $flowId }) {
        clientMutationId
      }
    }
  `)

  useEffect(() => {
    if (data?.flowId) {
      commit({
        variables: { flowId: data.flowId },
        onCompleted: () =>
          publishToLocal('LAST_SEEN_COMMENT_UPDATED_NOTIFICATION'),
      })
    }
  }, [commit, publishToLocal, data?.flowId])

  if (!data?.comment) {
    return <></>
  }
  return (
    <div className="Comment hc-pb-sm hc-pt-sm" id={data.comment.rowId}>
      <Card className="comment-card">
        <div className="hc-pt-md hc-pb-md hc-pr-lg hc-pl-lg">
          <Grid.Container spacing="none">
            <Grid.Item xs={8}>
              <UserAvatar
                userRef={data.comment.gwtUser}
                aria-label={''}
                children={undefined}
              />
            </Grid.Item>
            <Grid.Item className="rightText hc-pt-xs" xs={3}>
              {getTimeFromCreatedAtString(data.comment.createdAt)}
            </Grid.Item>
            <Grid.Item className="rightText" xs={1}>
              <CommentOptions commentRef={data.comment} flowId={data.flowId} />
            </Grid.Item>
          </Grid.Container>
          <SuspenseRingLoader>
            <div className="comment-card-comment-text hc-pt-md hc-pb-md">
              <EditableComment
                commentRef={data.comment}
                classNames="hc-mb-sm newline"
              />
              {data.comment.commentsByResponseTo?.nodes.map(
                (responseComment, index) => {
                  return (
                    <CommentCardResponse
                      commentRef={responseComment}
                      flowId={data.flowId}
                      key={index}
                    />
                  )
                },
              )}
              <CommentReply flowCommentRef={data} />
            </div>
          </SuspenseRingLoader>
        </div>
      </Card>
    </div>
  )
}
