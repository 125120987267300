import { useEffect } from 'react'
import {
  Button,
  Form,
  Grid,
  Spinner,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { useGwtMutation } from '../../hooks'
import { graphql } from 'react-relay'
import { FormProvider, useForm } from 'react-hook-form'
import { ControlledFormField } from '../../controlledComponents/ControlledFormField'
import {
  CreateFeedbackInput,
  FeedbackMutation,
  FeedbackMutation$variables,
} from './__generated__/FeedbackMutation.graphql'
import { getFeedbackLevelDescription } from '../../util/feedbackUtils'
import { useAtom } from 'jotai'
import { feedbackAtom } from '../../state/atoms'
import { usePublishGlobalNotification } from '../../hooks/useNotifications'
import { getActivityInputObject } from '../../util/relayUtils'
import { activityTypes } from '../../constants'

// The reviewer dashboard uses some of the components from the admin dashboard
export const Feedback = () => {
  const [feedback, setFeedback] = useAtom(feedbackAtom)
  const { publishToLocal } = usePublishGlobalNotification()

  const formMethods = useForm({
    defaultValues: {
      feedback: '',
      feedbackLevel: '',
    },
  })

  const feedbackText = feedback.type === 'FEEDBACK' ? 'feedback' : 'issue'

  const {
    handleSubmit,
    reset,
    formState: { isDirty },
  } = formMethods

  const makeToast = ToastProvider.useToaster()

  const { commit, isInFlight } = useGwtMutation<FeedbackMutation>(graphql`
    mutation FeedbackMutation(
      $input: CreateFeedbackInput!
      $activity: ActivityInput!
    ) {
      createActivity(input: { activity: $activity }) {
        clientMutationId
      }
      createFeedback(input: $input) {
        feedback {
          id
          feedback
          resolved
        }
      }
    }
  `)

  useEffect(() => {
    if (feedback.visible) {
      reset({ feedback: '', feedbackLevel: '' })
    }
  }, [feedback, reset])

  const submitFeedback = (data: any) => {
    if (isInFlight || !isDirty) return
    const mutation = {} as FeedbackMutation$variables
    mutation.input = {} as CreateFeedbackInput
    mutation.input.feedback = {
      ...data,
      feedbackLevel:
        feedback.type === 'FEEDBACK' ? 'SUGGESTION' : data.feedbackLevel,
    }
    mutation.activity = getActivityInputObject(
      activityTypes.SUBMITTED_FEEDBACK,
      data.feedback,
    )
    commit(
      mutation,
      () => {
        reset({ feedback: '', feedbackLevel: '' })
        setFeedback({ visible: false, type: feedback.type })
        publishToLocal('CREATE_FEEDBACK_NOTIFICATION')
        makeToast({
          autoHideDuration: 10000,
          type: 'success',
          heading: feedback.type === 'FEEDBACK' ? 'Feedback' : 'Issue',
          message: `Successfully submitted ${feedbackText}. Thank you!`,
        })
      },
      () => {
        makeToast({
          autoHideDuration: 10000,
          type: 'success',
          heading: feedback.type === 'FEEDBACK' ? 'Feedback' : 'Issue',
          message: `There was an issue sending your ${feedbackText}. Please try again.`,
        })
      },
    )
  }

  return (
    <FormProvider {...formMethods}>
      <Form onSubmit={handleSubmit(submitFeedback)}>
        <Grid.Container className="hc-pl-expanded hc-pr-expanded hc-pb-expanded">
          <Grid.Item xs={2} className="hc-pr-lg" />
          <Grid.Item xs={8} className="hc-pr-lg">
            <div className="hc-mt-xl">
              <ControlledFormField
                required
                name="feedback"
                type="textarea"
                placeholder={
                  feedback.type === 'ISSUE'
                    ? 'Please describe your issue here...'
                    : 'Write your feedback here...'
                }
                className="hc-mb-xs"
                data-testid="Feedback-Form"
              />
              {feedback.type === 'ISSUE' && (
                <ControlledFormField
                  required
                  name="feedbackLevel"
                  type="select"
                  placeholder="Select how severe the issue is..."
                  options={[
                    {
                      value: 'MINOR',
                      label: getFeedbackLevelDescription('MINOR'),
                    },
                    {
                      value: 'MAJOR',
                      label: getFeedbackLevelDescription('MAJOR'),
                    },
                    {
                      value: 'CRITICAL',
                      label: getFeedbackLevelDescription('CRITICAL'),
                    },
                  ]}
                />
              )}
            </div>
            <div className="flex flex-row hc-mt-md hc-ml-sm hc-mr-sm justify-end">
              <Button
                className="hc-mr-sm"
                disabled={isInFlight || !isDirty}
                onClick={() => {
                  reset({ feedback: '', feedbackLevel: '' })
                }}
              >
                Clear
              </Button>
              <Button type="submit" disabled={isInFlight || !isDirty}>
                Submit
                {isInFlight && <Spinner className="hc-ml-sm" />}
              </Button>
            </div>
          </Grid.Item>
          <Grid.Item xs={2} />
        </Grid.Container>
      </Form>
    </FormProvider>
  )
}
