import { useAuth } from '@praxis/component-auth'
import { useEnv } from '@praxis/component-runtime-env'

import axios from 'axios'
import { UUID } from '../react-app-env'
import { GwtEnv } from '../types'
import useGwtUser from './useGwtUser'

type AssentUploadResponse = {
  success: boolean
  errors: string[]
}
export const useAssentFileUpload = () => {
  const auth = useAuth()
  const env = useEnv<GwtEnv>()
  const accessToken = auth.session?.accessToken

  const user = useGwtUser()

  let url = '/pol/upload'

  if (user.userType === 'ADMIN' || user.userType === 'REVIEWER') {
    url = '/gallery/upload'
  }

  const axiosClient = axios.create({
    baseURL: env.baseGwtApiUrl,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
  })

  axiosClient.interceptors.request.use((config) => ({
    ...config,
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
  }))

  const uploadFileToAsset = async (
    file: File,
    assetId: UUID,
    name: string,
    progressCallback?: (progressUpdate: number) => void,
  ) => {
    // Derive classification.
    const fileExt: string = (file.name.split('.').pop() || '').toLowerCase()
    let classification: string
    switch (fileExt) {
      case 'glb':
      case 'fbx': // classification = 'SOURCE_3D'
      case 'png':
      case 'jpg':
      case 'gif':
        classification = 'NONE'
        break
      default:
        classification = 'NONE'
    }
    const formData = new FormData()
    formData.append('file', file)
    formData.append('name', name)
    formData.append('assetId', assetId)
    formData.append('classification', classification)
    formData.append('call_transcoding', JSON.stringify(false))
    formData.append('source', 'Assent')

    let success: AssentUploadResponse = { success: true, errors: [] }
    try {
      const response = await axiosClient.post(`${url}/${assetId}`, formData, {
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable
            ? progressEvent.total
            : progressEvent.target.getResponseHeader('content-length') ||
              progressEvent.target.getResponseHeader(
                'x-decompressed-content-length',
              )
          if (totalLength !== null) {
            if (progressCallback) {
              progressCallback(
                Math.round((progressEvent.loaded * 100) / totalLength),
              )
            }
          }
        },
      })
      if (response.status === 200) {
        success = {
          success: response.data.success,
          errors: response.data.errors,
        }
      }
    } catch (e) {
      success = { success: false, errors: ['Failed to send file to assent'] }
    }

    return success
  }

  return { uploadFileToAsset }
}
