import { Popover } from '@enterprise-ui/canvas-ui-react'
import { graphql, useFragment } from 'react-relay'
import { UserProgramsDisplay$key } from './__generated__/UserProgramsDisplay.graphql'
import { getModuleProgramTypeText } from '../../../util/moduleUtils'

interface UserProgramsDisplayProps {
  userRef?: UserProgramsDisplay$key | null
}

export const UserProgramsDisplay = ({
  userRef = null,
}: UserProgramsDisplayProps) => {
  const data = useFragment(
    graphql`
      fragment UserProgramsDisplay on GwtUser {
        programs
      }
    `,
    userRef,
  )

  if (!data) {
    return <></>
  }

  const programs = data.programs

  if (programs.length === 0) {
    return <>No programs</>
  }

  return (
    <div>
      <span>{getModuleProgramTypeText(programs[0])}</span>
      {programs.length > 1 && (
        <Popover
          className="hc-bg-black hc-clr-white"
          size="dense"
          content={programs.map((program, i) => {
            if (i !== 0) {
              return (
                <div key={program}>{getModuleProgramTypeText(program)}</div>
              )
            } else {
              return <></>
            }
          })}
          location="bottom-right"
        >
          <span className="blue-interactive-text hc-ml-xs">
            {`+ ${programs.length - 1} more`}
          </span>
        </Popover>
      )}
    </div>
  )
}
