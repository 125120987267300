import {
  Button,
  Divider,
  Form,
  Grid,
  Layout,
  Spinner,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { useGwtMutation, useGwtUser } from '../../hooks'
import { getAssignmentStatusTextNoDate } from '../../util/flowUtils'
import { graphql } from 'react-relay'
import {
  AssignmentDueAtStatus,
  AssignmentStatus,
  UserPreferencesMutation,
} from './__generated__/UserPreferencesMutation.graphql'
import dayjs from 'dayjs'
import { FormProvider, useForm } from 'react-hook-form'
import { ControlledFormField } from '../../controlledComponents/ControlledFormField'
import { ProtectedComponent } from '../../GenericComponents/ProtectedComponent'

// The reviewer dashboard uses some of the components from the admin dashboard
export const UserPreferences = () => {
  const user = useGwtUser()

  const formMethods = useForm({
    defaultValues: {
      emailModuleNotifications: user.emailModuleNotifications,
      emailOnAllAssignmentStatusChanges: user.emailOnAllAssignmentStatusChanges,
      emailBulk: user.emailBulk,
      emailBulkTime: `${dayjs(user.emailBulkTime).format('HH:mm')}`,
      emailOnCommentAdded: user.emailOnCommentAdded,
      emailOnFeedbackProvided: user.emailOnFeedbackProvided,
    },
  })

  const {
    handleSubmit,
    watch,
    reset,
    formState: { isDirty },
  } = formMethods

  const [emailBulk] = watch(['emailBulk'])

  const makeToast = ToastProvider.useToaster()

  const { commit, isInFlight } = useGwtMutation<UserPreferencesMutation>(
    graphql`
      mutation UserPreferencesMutation($input: UpdateGwtUserInput!) {
        updateGwtUser(input: $input) {
          gwtUser {
            email
            emailBulk
            emailBulkTime
            emailModuleNotifications
            emailOnAllAssignmentStatusChanges
            emailOnCommentAdded
          }
        }
      }
    `,
  )

  const submitChanges = ({
    emailBulkTime,
    ...data
  }: {
    emailBulkTime: string
  }) => {
    if (isInFlight || !isDirty) return

    const current = new Date()
    const emailBulkDateTime = dayjs(
      `${current.getFullYear()}-${
        current.getMonth() + 1
      }-${current.getDate()} ${emailBulkTime}:00`,
    ).toDate()
    commit(
      {
        input: {
          patch: {
            ...data,
            emailBulkTime: emailBulkDateTime,
          },
          rowId: user.rowId,
        },
      },
      (response) => {
        makeToast({
          type: 'success',
          heading: 'User preferences',
          message: 'Successfully update user preferences.',
        })

        const emailModuleNotifications =
          response?.updateGwtUser?.gwtUser?.emailModuleNotifications || []

        const emailBulkTime = response.updateGwtUser?.gwtUser?.emailBulkTime
          ? `${dayjs(response.updateGwtUser?.gwtUser?.emailBulkTime).format(
              'HH:mm',
            )}`
          : undefined

        reset({
          ...response.updateGwtUser?.gwtUser,
          emailModuleNotifications: [...emailModuleNotifications] as (
            | AssignmentStatus
            | AssignmentDueAtStatus
            | null
          )[],
          emailBulkTime,
        })
      },
      () => {
        makeToast({
          type: 'error',
          heading: 'User preferences',
          message:
            'There was an issue saving user preferences, please try again.',
        })
      },
    )
  }

  return (
    <Layout.Body includeRail>
      <FormProvider {...formMethods}>
        <Form onSubmit={handleSubmit(submitChanges)}>
          <Grid.Container className="hc-pl-expanded hc-pr-expanded hc-pt-2x hc-pb-expanded">
            <Grid.Item xs={2} className="hc-pr-lg" />
            <Grid.Item xs={8} className="hc-pr-lg">
              <div className="hc-ta-center">
                <h1>{user.fullName}</h1>
              </div>
              <div className="hc-mt-xl hc-bg-grey06 hc-pa-expanded">
                <h2>Email preferences</h2>
                <Divider />
                <div className="hc-mt-lg">
                  <ControlledFormField
                    name="emailOnAllAssignmentStatusChanges"
                    label="Get email notification on every module status update"
                    type="checkbox"
                  />
                  <div>
                    <ControlledFormField
                      name="emailBulk"
                      label="Get bulk email notification once a day"
                      type="checkbox"
                    />
                    {emailBulk && (
                      <Grid.Container>
                        <Grid.Item>
                          <ControlledFormField
                            name="emailBulkTime"
                            type="time"
                            className="hc-ml-xl"
                            required
                          />
                        </Grid.Item>
                      </Grid.Container>
                    )}
                  </div>
                  <h2 className="hc-mt-lg">Module notifications</h2>
                  <Divider className="hc-mb-lg" />
                  <ControlledFormField
                    name="emailModuleNotifications"
                    type="checkboxes"
                    options={[
                      {
                        value: 'UNDER_REVIEW',
                        label: getAssignmentStatusTextNoDate('UNDER_REVIEW'),
                      },
                      {
                        value: 'READY_FOR_REVIEW',
                        label:
                          getAssignmentStatusTextNoDate('READY_FOR_REVIEW'),
                      },
                      {
                        value: 'IN_PROGRESS',
                        label: getAssignmentStatusTextNoDate('IN_PROGRESS'),
                      },
                      {
                        value: 'REJECTED',
                        label: getAssignmentStatusTextNoDate('REJECTED'),
                      },
                      {
                        value: 'ACCEPTED',
                        label: getAssignmentStatusTextNoDate('ACCEPTED'),
                      },
                      {
                        value: 'DUE_SOON',
                        label: getAssignmentStatusTextNoDate('DUE_SOON'),
                      },
                      {
                        value: 'OVERDUE',
                        label: getAssignmentStatusTextNoDate('OVERDUE'),
                      },
                      {
                        value: 'CANCELED',
                        label: getAssignmentStatusTextNoDate('CANCELED'),
                      },
                    ]}
                  />

                  <ProtectedComponent allowed={['ADMIN', 'REVIEWER']}>
                    <h2 className="hc-mt-lg">Reviewer preferences</h2>
                    <Divider />
                    <div className="hc-mt-lg">
                      <ControlledFormField
                        name="emailOnCommentAdded"
                        label="Email notifications for comments added to modules"
                        type="checkbox"
                      />
                    </div>
                  </ProtectedComponent>
                  <ProtectedComponent allowed={['ADMIN']}>
                    <h2 className="hc-mt-lg">Administrator preferences</h2>
                    <Divider />
                    <div className="hc-mt-lg">
                      <ControlledFormField
                        name="emailOnFeedbackProvided"
                        label="Email notifications for feedback submitted by users"
                        type="checkbox"
                      />
                    </div>
                  </ProtectedComponent>
                </div>
              </div>
              <div className="flex hc-mt-md">
                <Button
                  className="flex-end"
                  type="submit"
                  disabled={isInFlight || !isDirty}
                >
                  Save changes
                  {isInFlight && <Spinner className="hc-ml-sm" />}
                </Button>
              </div>
            </Grid.Item>
            <Grid.Item xs={2} />
          </Grid.Container>
        </Form>
      </FormProvider>
    </Layout.Body>
  )
}
