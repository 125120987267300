import {
  Button,
  InputDropArea,
  Grid,
  ProgressBar,
  Spinner,
  Divider,
} from '@enterprise-ui/canvas-ui-react'
import { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useFilesUpload } from '../../../hooks'
import './Progress.scss'
import { UUID } from '../../../react-app-env'
import { useAtom } from 'jotai'
import { dropFilesAtom } from '../../../state/atoms'
import classNames from 'classnames'

interface FileUploadTableProps {
  assetId?: UUID
  initiateUpload?: boolean
  showButtonsAfterInitiate?: boolean
  onAllFilesUploaded?: () => void
  shouldClearFiles?: boolean
  setShouldClearFiles?: React.Dispatch<React.SetStateAction<boolean>>
}

export const FileUploadTable = ({
  showButtonsAfterInitiate = false,
  onAllFilesUploaded,
  shouldClearFiles = false,
  setShouldClearFiles,
  ...props
}: FileUploadTableProps) => {
  const {
    files,
    deleteFile,
    clearFiles,
    addFiles,
    uploadFilesToAsset,
    isInFlight,
    isAllFilesUploaded,
  } = useFilesUpload()

  const [attemptedUpload, setAttemptedUpload] = useState(false)
  const [, setDropFilesAtom] = useAtom(dropFilesAtom)

  useEffect(() => {
    if (shouldClearFiles && setShouldClearFiles) {
      clearFiles()
      setShouldClearFiles(false)
    }
  }, [clearFiles, setShouldClearFiles, shouldClearFiles])

  useEffect(() => {
    if (props.initiateUpload && !isInFlight && !attemptedUpload) {
      setAttemptedUpload(true)
      uploadFilesToAsset(props.assetId)
    }
  }, [
    attemptedUpload,
    isInFlight,
    props.assetId,
    props.initiateUpload,
    uploadFilesToAsset,
  ])

  useEffect(() => {
    if (isAllFilesUploaded) {
      if (onAllFilesUploaded) {
        onAllFilesUploaded()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAllFilesUploaded])

  useEffect(() => {
    if (isAllFilesUploaded) {
      setDropFilesAtom(false)
    }
    //sets atom to true to disable assignee submit button if un-uploaded files exist in drop file area
    if (!isAllFilesUploaded && files.length !== 0) {
      setDropFilesAtom(true)
    }
  }, [isAllFilesUploaded, files.length, setDropFilesAtom])

  return (
    <>
      <InputDropArea
        fullwidth
        multiple
        instructionText="Drop file(s) here to upload"
        id="drop_area"
        value={[]}
        onUpdate={(e: any) => {
          const dropped: FileList =
            e.dataTransfer?.files || e.target?.files || new FileList()
          const droppedFiles = Array.from(dropped)
          addFiles(droppedFiles)
        }}
      />
      <Grid.Container className={classNames('upload-table', 'hc-pv-normal')}>
        {files.map((uFile) => (
          <Grid.Item key={uFile.id} xs={12}>
            <Grid.Container align="center" justify="center">
              <Grid.Item xs={5} data-test-id="file-ready-for-upload">
                {uFile.file.name}
              </Grid.Item>
              <Grid.Item
                xs={6}
                className={classNames('hc-pr-none')}
                data-test-id="file-upload-status"
              >
                {uFile.status === 'INIT' && (
                  <ProgressBar className="hc-mr-sm" percentComplete={0} />
                )}
                {uFile.status === 'UPLOADING' && (
                  <ProgressBar
                    className="hc-mr-sm"
                    percentComplete={uFile.progress}
                  />
                )}
                {uFile.status === 'UPLOADED' && (
                  <ProgressBar className="hc-mr-sm" percentComplete={100} />
                )}
                {uFile.status === 'ERROR' && (
                  <ProgressBar
                    className="hc-mr-sm error-bar"
                    percentComplete={5}
                  />
                )}
                {uFile.status === 'ERROR' && (
                  <FontAwesomeIcon
                    icon="times"
                    className="error-icon"
                    size="2x"
                  />
                )}
              </Grid.Item>
              <Grid.Item
                xs={1}
                className={classNames('hc-ta-right', 'hc-pl-none')}
              >
                {uFile.status === 'UPLOADED' && (
                  <FontAwesomeIcon icon="check" color="green" size="2x" />
                )}
                {(uFile.status === 'INIT' || uFile.status === 'ERROR') && (
                  <Button
                    iconOnly
                    aria-label="remove file button"
                    size="normal"
                    type="secondary"
                    data-test-id="remove-file-button"
                    onClick={() => {
                      deleteFile(uFile.id)
                    }}
                  >
                    <FontAwesomeIcon icon="trash" className="trash-icon" />
                  </Button>
                )}
              </Grid.Item>
              {uFile.status === 'ERROR' && (
                <Grid.Item
                  xs={12}
                  className={classNames('hc-ta-center', 'hc-pt-none')}
                >
                  <div className="sub-text status-text-errored">
                    Error: {uFile.uploadError}
                  </div>
                </Grid.Item>
              )}
            </Grid.Container>
          </Grid.Item>
        ))}
        <Divider className="hc-ph-normal" />
      </Grid.Container>
      {files.length > 0 &&
        !!props.assetId &&
        (!showButtonsAfterInitiate || props.initiateUpload) && (
          <Grid.Container
            className="hc-mt-normal"
            spacing="none"
            align="center"
            justify="flex-start"
          >
            <Grid.Item>
              <Button
                type="secondary"
                disabled={isInFlight}
                onClick={() => clearFiles()}
              >
                Clear
              </Button>
            </Grid.Item>
            <Grid.Item>
              <Button
                type="primary"
                onClick={() => {
                  uploadFilesToAsset(props.assetId)
                }}
                disabled={isInFlight}
                className="hc-ml-xs"
              >
                {isInFlight && <Spinner size="inline" />}

                {!isInFlight &&
                  (files.find((f) => f.status === 'ERROR')
                    ? 'Retry'
                    : 'Upload')}
              </Button>
            </Grid.Item>
          </Grid.Container>
        )}
    </>
  )
}
