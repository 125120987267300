import { useAuth, UserInfo } from '@praxis/component-auth'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { UUID } from '../react-app-env'
import { AssignmentDueAtStatus } from '../views/Preferences/__generated__/UserPreferencesMutation.graphql'
import {
  useGwtUser_userTypeQuery,
  UserType,
  UserStatus,
  ProgramType,
} from './__generated__/useGwtUser_userTypeQuery.graphql'
import { AssignmentStatus } from './__generated__/useNotificationsMutation.graphql'

export interface GwtUser {
  firstName: string
  lastName: string
  fullName: string
  companyName: string
  memberOf: string[]
  rowId: UUID
  userType: UserType
  emailOnAllAssignmentStatusChanges: boolean
  emailBulk: boolean
  emailBulkTime: Date
  emailOnCommentAdded: boolean
  emailOnFeedbackProvided: boolean
  emailModuleNotifications?: (AssignmentStatus | AssignmentDueAtStatus | null)[]
  programs: ProgramType[]
  isReadOnly: boolean
  allUsers: Array<{ rowId: string; firstName: string; lastName: string }>
  status: UserStatus
  partnersonline: boolean
}

export default function useGwtUser(): GwtUser {
  const auth = useAuth()

  const data = useLazyLoadQuery<useGwtUser_userTypeQuery>(
    graphql`
      query useGwtUser_userTypeQuery {
        myUser {
          id
          rowId
          userType
          emailOnAllAssignmentStatusChanges
          emailBulk
          emailBulkTime
          emailModuleNotifications
          emailOnCommentAdded
          emailOnFeedbackProvided
          status
          fullName
          firstName
          lastName
          programs
          partnersonline
          company {
            name
          }
        }
        gwtUsers(orderBy: FIRST_NAME_ASC) {
          nodes {
            rowId
            firstName
            lastName
          }
        }
      }
    `,
    {},
    { fetchPolicy: 'store-or-network' },
  )

  if (!auth.session?.userInfo) {
    throw new Error('Not logged in')
  }

  const { myUser = {} as any } = data

  const userInfo: UserInfo = auth.session.userInfo

  return {
    rowId: data.myUser?.rowId,
    allUsers: data.gwtUsers?.nodes.map((u) => u) || [],
    userType: 'LOADING',
    emailOnAllAssignmentStatusChanges: true,
    emailBulk: true,
    emailBulkTime: new Date(),
    emailOnCommentAdded: true,
    emailOnFeedbackProvided: data.myUser?.emailOnFeedbackProvided,
    isReadOnly: myUser?.status !== 'ACTIVE',
    programs: data.myUser?.programs,
    companyName: data.myUser?.company?.name,
    ...userInfo,
    ...myUser,
  }
}
