import { Popover } from '@enterprise-ui/canvas-ui-react'
import { graphql, useFragment } from 'react-relay'
import { FC } from 'react'
import { UserCertificationsDisplay$key } from './__generated__/UserCertificationsDisplay.graphql'

interface UserCertificationsDisplayProps {
  userRef?: UserCertificationsDisplay$key | null
}

export const UserCertificationsDisplay: FC<UserCertificationsDisplayProps> = ({
  userRef = null,
}) => {
  const data = useFragment(
    graphql`
      fragment UserCertificationsDisplay on GwtUser {
        allCertifications
      }
    `,
    userRef,
  )

  if (!data) {
    return <></>
  }

  const certificationNames = data.allCertifications

  if (!certificationNames || certificationNames.length === 0) {
    return <>None</>
  }

  return (
    <div>
      <span>{certificationNames[0]}</span>
      {certificationNames.length > 1 && (
        <Popover
          className="hc-bg-black hc-clr-white"
          size="dense"
          content={certificationNames.map((certificationName, i) => {
            if (i !== 0) {
              return <div key={certificationName}>{certificationName}</div>
            } else {
              return <></>
            }
          })}
          location="bottom-right"
        >
          <span className="blue-interactive-text hc-ml-xs">
            {`+ ${certificationNames.length - 1} more`}
          </span>
        </Popover>
      )}
    </div>
  )
}
