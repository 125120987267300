/**
 * @generated SignedSource<<067f94b868c65c17f5f5422e4920cd16>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AssignmentStatus = "ACCEPTED" | "CANCELED" | "IN_PROGRESS" | "NONE" | "NOT_STARTED" | "READY_FOR_REVIEW" | "REJECTED" | "UNDER_REVIEW" | "%future added value";
export type SubmissionStatus = "ACCEPTED" | "READY_FOR_REVIEW" | "REJECTED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ReviewerButton_Fragment$data = {
  readonly attempts: number;
  readonly currentAttempt: number;
  readonly flow: {
    readonly assignmentStatus: AssignmentStatus;
    readonly name: string;
  } | null;
  readonly flowId: UUID;
  readonly moduleSubmissionsByModuleFlowId: {
    readonly nodes: ReadonlyArray<{
      readonly attempt: number;
      readonly id: string;
      readonly submissionStatus: SubmissionStatus;
    }>;
  };
  readonly " $fragmentType": "ReviewerButton_Fragment";
};
export type ReviewerButton_Fragment$key = {
  readonly " $data"?: ReviewerButton_Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReviewerButton_Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewerButton_Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "flowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "currentAttempt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "attempts",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Flow",
      "kind": "LinkedField",
      "name": "flow",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "assignmentStatus",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "ATTEMPT_DESC"
        }
      ],
      "concreteType": "ModuleSubmissionsConnection",
      "kind": "LinkedField",
      "name": "moduleSubmissionsByModuleFlowId",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ModuleSubmission",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "submissionStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "attempt",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "moduleSubmissionsByModuleFlowId(orderBy:\"ATTEMPT_DESC\")"
    }
  ],
  "type": "Module",
  "abstractKey": null
};

(node as any).hash = "1162032a22b104e4933454c447f41537";

export default node;
