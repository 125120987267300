import {
  Button,
  Grid,
  GridContainer,
  GridItem,
  Modal,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { graphql, useFragment } from 'react-relay'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGwtMutation } from '../../../hooks'
import { DropdownSelect } from '../../../GenericComponents/DropdownSelect/DropdownSelect'
import { AssignTemplateCardPopup } from '../../Admin/AssignTemplateCardPopup'
import { TemplatesFavorite } from '../TemplatesFavorite/TemplatesFavorite'
import { useState } from 'react'
import { usePublishGlobalNotification } from '../../../hooks/useNotifications'
import { useNavigate, useLocation } from 'react-router-dom'
import { TemplateOptions_module$key } from './__generated__/TemplateOptions_module.graphql'
import { useAuthHelper } from '../../../hooks/useAuthHelper'

interface TemplateOptionsProps {
  moduleRef: TemplateOptions_module$key | null | undefined
  className?: string
  disableFavoriteConfirmationModal?: boolean
  tableLocation?: number
}

export const TemplateOptions = ({
  moduleRef,
  className,
  tableLocation,
  disableFavoriteConfirmationModal,
}: TemplateOptionsProps) => {
  const navigate = useNavigate()
  const location = useLocation()

  const [whichAssignModalOpen, setWhichAssignModalOpen] = useState(false)
  const [whichDeleteModalOpen, setWhichDeleteModalOpen] = useState(false)

  const { publishToLocal } = usePublishGlobalNotification()
  const { protectArray, protectStringArray } = useAuthHelper()

  const data = useFragment(
    graphql`
      fragment TemplateOptions_module on Module
      @argumentDefinitions(gwtUserId: { type: "UUID" }) {
        ...AssignTemplateCardPopupModuleFragment
        flow {
          rowId
          name
          ...TemplatesFavorite_flow @arguments(gwtUserId: $gwtUserId)
        }
      }
    `,
    moduleRef || null,
  )

  const { commit } = useGwtMutation(graphql`
    mutation TemplateOptionsMutation($input: ArchiveFlowInput!) {
      archiveFlow(input: $input) {
        clientMutationId
      }
    }
  `)

  return (
    <GridContainer spacing="none" justify="flex-end" align="center">
      <GridItem className="hc-pr-sm">
        <TemplatesFavorite
          disableConfirmationModal={disableFavoriteConfirmationModal}
          flowRef={data?.flow}
          id={data?.flow?.rowId}
        />
      </GridItem>
      <GridItem>
        <DropdownSelect
          location="bottom-right"
          shouldSelect={false}
          options={protectArray([
            {
              label: 'Assign to user',
              onSelect: () => {
                setWhichAssignModalOpen(true)
              },
              allowed: ['ADMIN', 'REVIEWER'],
            },
            {
              label: 'Duplicate template',
              onSelect: () => {
                navigate('/template/duplicate', {
                  state: {
                    templateName: data?.flow?.name,
                    moduleFlowId: data?.flow?.rowId,
                    prevUrl: location.pathname,
                  },
                })
              },
              allowed: ['ADMIN'],
            },
            {
              label: 'Edit template',
              onSelect: () => {
                navigate('/template/edit', {
                  state: {
                    templateName: data?.flow?.name,
                    moduleFlowId: data?.flow?.rowId,
                    prevUrl: location.pathname,
                  },
                })
              },
              allowed: ['ADMIN'],
            },
            {
              label: 'Delete template',
              className: 'error-color',
              onSelect: () => {
                setWhichDeleteModalOpen(true)
              },
              allowed: ['ADMIN'],
            },
          ])}
        >
          <Tooltip
            content={protectStringArray([
              { val: 'Assign', allowed: ['ADMIN', 'REVIEWER'] },
              { val: 'Edit', allowed: ['ADMIN'] },
              { val: 'Delete', allowed: ['ADMIN'] },
            ])}
            location="left"
          >
            <Button
              aria-label="template-options"
              iconOnly
              type="default"
              size="normal"
            >
              <FontAwesomeIcon icon={faEllipsisV} />
            </Button>
          </Tooltip>
        </DropdownSelect>
      </GridItem>
      {whichAssignModalOpen && (
        <AssignTemplateCardPopup
          moduleRef={data}
          isModalOpen={whichAssignModalOpen}
          closeModal={() => setWhichAssignModalOpen(false)}
        />
      )}
      {whichDeleteModalOpen && (
        <Modal
          isVisible={whichDeleteModalOpen}
          headingText="Delete template"
          className="align-left"
          onRefuse={() => setWhichDeleteModalOpen(false)}
        >
          <div className="hc-fs-md hc-ma-normal">
            This template will be permanently deleted. You will no longer be
            able to view it on your list or access it from the Templates page
            <Grid.Container>
              <Grid.Item xs={6} />
              <Grid.Item xs={6} className="hc-pt-expanded">
                <Button
                  type="secondary"
                  className="hc-mr-normal"
                  onClick={() => {
                    setWhichDeleteModalOpen(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="destructive"
                  onClick={() => {
                    commit(
                      {
                        input: {
                          flowId: data?.flow?.rowId,
                        },
                      },
                      () => {
                        publishToLocal('ARCHIVE_TEMPLATE_NOTIFICATION', {
                          flowId: data?.flow?.rowId,
                        })
                      },
                    )
                    setWhichDeleteModalOpen(false)
                  }}
                >
                  Delete
                </Button>
              </Grid.Item>
            </Grid.Container>
          </div>
        </Modal>
      )}
    </GridContainer>
  )
}
