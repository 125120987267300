import { Card, Grid } from '@enterprise-ui/canvas-ui-react'
import './GroupTemplateModules'
import { useState, useEffect } from 'react'
import { getFlowDurationInWeeks } from '../../../util/flowUtils'

interface GroupTemplateNameProps {
  modules: any[]
  name: string | undefined
  isPreview?: boolean
}

export const GroupTemplateName = ({
  modules = [],
  name = undefined,
  isPreview = false,
}: GroupTemplateNameProps) => {
  const [reviewers, setReviewers] = useState<string>('')
  const [softwares, setSoftwares] = useState<string>('')
  const [durationInSeconds, setDurationInSeconds] = useState(0)

  useEffect(() => {
    if (modules) {
      let reviewerList: string[] = []
      let softwareList: string[] = []
      let totalDurationSeconds = 0

      modules.forEach((module: any) => {
        if (module.reviewers.length === 0) {
          reviewerList.push('Unassigned')
        } else {
          reviewerList.push(...module.reviewers)
        }
        softwareList.push(module.softwareType)
        totalDurationSeconds += module.durationInSeconds
      })

      setReviewers([...new Set(reviewerList)].join(', '))
      setSoftwares([...new Set(softwareList)].join(', '))
      setDurationInSeconds(totalDurationSeconds)
    }
  }, [modules, modules?.length])

  return (
    <Card className="card hc-pa-md hc-mt-sm">
      <div className="hc-fs-lg">{name || 'No name set'}</div>
      {isPreview ? (
        <Grid.Container
          className="hc-fs-xs"
          spacing="dense"
          justify="space-between"
        >
          <Grid.Item>
            Assigned reviewer(s): {reviewers ? reviewers : 'No reviewers set'}
          </Grid.Item>
          <Grid.Item>
            Software: {softwares ? softwares : 'No software set'}
          </Grid.Item>
          <Grid.Item>
            Duration:
            {getFlowDurationInWeeks(durationInSeconds)} weeks
          </Grid.Item>
        </Grid.Container>
      ) : (
        <div className="hc-fs-sm">
          <span>
            Assigned reviewer(s): {reviewers ? reviewers : 'No reviewers set'}
          </span>
          <span className="hc-ml-sm">
            - Duration: {getFlowDurationInWeeks(durationInSeconds)} weeks -
          </span>
          <span className="hc-ml-sm">
            Software: {softwares ? softwares : 'No software set'}
          </span>
        </div>
      )}
    </Card>
  )
}
