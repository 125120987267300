import {
  Avatar,
  Divider,
  Grid,
  GridContainer,
  GridItem,
  Layout,
} from '@enterprise-ui/canvas-ui-react'
import { useState } from 'react'
import './Insights.scss'
import { SuspenseRingLoader } from '../../loadingComponents/SuspenseRingLoader'
import { useSubscriptionRefreshQuery } from '../../hooks/useRefresh'
import { graphql, useFragment } from 'react-relay'
import { InsightsDashboardQuery } from './__generated__/InsightsDashboardQuery.graphql'
import { InsightsDashboardFragment_activities$key } from './__generated__/InsightsDashboardFragment_activities.graphql'
import { ReviewerAssignmentsPieChart } from './ReviewerAssignmentsPieChart'
import { SoftwaresPieChart } from './SoftwaresPieChart'
import { TemplatesPieChart } from './TemplatesPieChart'
import { getAssignmentStatusText } from '../../util/flowUtils'
import { StatusPieChart } from './StatusPieChart'
import { CompletionRatePieChart } from './CompletionRatePieChart'
import { ModulesProgressBarChart } from './ModulesProgressBarChart'
import { SoftwaresCompletedBarChart } from './SoftwaresCompletedBarChart'
import GreenfieldVisualizations from 'greenfield-visualizations'
import { GwtEnv } from '../../types'
import { useEnv } from '@praxis/component-runtime-env'
import { AssentCertificationsBarChart } from './AssentCertificationsBarChart'

export const InsightsDashboard = () => {
  const { data } = useGetInsightsData()

  const env = useEnv<GwtEnv>()

  const createCompletionRatePieChartData = () => {
    //Get List of Module Names that are currently assigned
    const listOfCompletion = data?.moduleCompletionData?.nodes?.map(
      (flowStatus) => {
        return getAssignmentStatusText(
          flowStatus.flow?.assignmentStatus,
          flowStatus.flow?.assignmentDueAtStatus,
        )
      },
    )
    //Counts the number of times each module appears in listOfModules and separates values into two arrays
    const counts = {}
    listOfCompletion?.forEach(function (x) {
      if (x) {
        counts[x] = (counts[x] || 0) + 1
      }
    })
    const completionRateNames = Object.keys(counts)
    const completionRateKeys = Object.values(counts)
    // Create a new array of key:value pairs where key is name of reviewer and value is number of modules that have that reviewer
    const finalData = completionRateNames.map((name, i) => ({
      name,
      value: completionRateKeys[i],
    }))
    return finalData
  }
  const completionRatePieChartData = createCompletionRatePieChartData()

  const createModulesBarChartData = () => {
    //Get Total Number of Modules that exist
    const listOfModules = data?.statusPiechartData?.edges?.map((module) => {
      return {
        name: module?.node?.flow?.name || '',
        assignmentStatus: module?.node?.flow?.assignmentStatus || '',
      }
    })
    return listOfModules
  }
  const modulesBarChartData = createModulesBarChartData()

  const createSoftwaresCompletedBarChartData = () => {
    //Get list of softwares used by all the modules
    const listOfSoftwares = data?.statusPiechartData?.edges?.map((module) => {
      return {
        name: module?.node?.softwareType || 'NONE',
        assignmentStatus: module?.node?.flow?.assignmentStatus || '',
      }
    })
    return listOfSoftwares
  }
  const softwaresCompletedBarChartData = createSoftwaresCompletedBarChartData()

  const createSoftwaresPieChartData = () => {
    //Get a list of software types from the status pie chart query data
    const listOfSoftwares = data?.statusPiechartData?.edges?.map((module) => {
      return module?.node?.softwareType || 'NONE'
    })
    //Counts the number of times each software type appears in listOfSoftwares and separates values into two arrays
    const counts = {}
    listOfSoftwares?.forEach(function (x) {
      if (x) {
        counts[x] = (counts[x] || 0) + 1
      }
    })
    const softwareNames = Object.keys(counts)
    const softwaresKeys = Object.values(counts)
    // Create a new array of key:value pairs where the key is the software type and value is number of modules that have that software type
    const finalData = softwareNames.map((name, i) => ({
      name,
      value: softwaresKeys[i],
    }))
    return finalData
  }
  const softwaresPieChartData = createSoftwaresPieChartData()

  const createStatusPieChartData = () => {
    //Get list of status
    const listOfStatus = data?.statusPiechartData?.edges?.map((module) => {
      return getAssignmentStatusText(
        module?.node?.flow?.assignmentStatus,
        module?.node?.flow?.assignmentDueAtStatus,
      )
    })
    //Counts the number of times each status appears in listOfStatus and separates values into two arrays
    const counts = {}
    listOfStatus?.forEach(function (x) {
      if (x) {
        counts[x] = (counts[x] || 0) + 1
      }
    })
    const moduleNames = Object.keys(counts)
    const moduleKeys = Object.values(counts)
    // Create a new array of key:value pairs where key is type of status and value is number of modules that have that status
    const finalData = moduleNames.map((name, i) => ({
      name,
      value: moduleKeys[i],
    }))
    return finalData
  }
  const statusPieChartData = createStatusPieChartData()

  const createModulesPieChartData = () => {
    //Get List of Module Names that are currently assigned
    const listOfModules = data?.moduleAssignedData?.edges?.map((module) => {
      return module.node?.flow?.name
    })

    //Counts the number of times each module appears in listOfModules and separates values into two arrays
    const counts = {}
    listOfModules?.forEach(function (x) {
      if (x) {
        counts[x] = (counts[x] || 0) + 1
      }
    })
    const moduleNames = Object.keys(counts)
    const moduleKeys = Object.values(counts)
    // Create a new array of key:value pairs where key is name of reviewer and value is number of modules reviewer is assigned to review
    const finalData = moduleNames.map((name, i) => ({
      name,
      value: moduleKeys[i],
    }))
    return finalData
  }
  const modulesPieChartData = createModulesPieChartData()

  const createReviewerAssignmentsPieChartData = () => {
    //Get List of Reviewers
    const listOfReviewers = data.flowReviewers?.edges?.map((flowReviewer) => {
      return flowReviewer?.node?.reviewer?.fullName
    })

    //Counts the number of times each reviewer appears in listOfReviewers and separates values into two arrays
    const counts = {}
    listOfReviewers?.forEach(function (x) {
      if (x) {
        counts[x] = (counts[x] || 0) + 1
      }
    })
    const reviewerNames = Object.keys(counts)
    const reviewersKeys = Object.values(counts)
    // Create a new array of key:value pairs where key is name of reviewer and value is number of modules reviewer is assigned to review
    const finalData = reviewerNames.map((name, i) => ({
      name,
      value: reviewersKeys[i],
    }))
    return finalData
  }
  const reviewerAssignmentsPieChartData =
    createReviewerAssignmentsPieChartData()
  return (
    <Layout.Body includeRail>
      <div className="hc-pa-expanded hc-ta-center">
        <h1>Insights</h1>
      </div>
      <Grid.Container className="hc-pl-expanded hc-pr-expanded">
        {env.metrics.cards.map((card) => (
          <Grid.Item xs={card.xs} key={card.id} className="hc-pa-xl">
            <div className="hc-pa-normal cardHeader headerText">
              <div className="hc-pl-xs hc-pr-xs">
                <h3>{card.title}</h3>
              </div>
              <Divider />
            </div>
            <GreenfieldVisualizations
              apiKey={env.metrics.apiKey}
              environment={env.metrics.environment}
              fixedCard={{
                id: card.id,
              }}
              cardStyles={{
                height: `${card.height}px`,
              }}
            />
          </Grid.Item>
        ))}
      </Grid.Container>
      <Grid.Container className="hc-pl-expanded hc-pr-expanded">
        <GridItem className="bold-text hc-pt-expanded" xs={12}>
          Certifications
        </GridItem>
        <GridItem xs={12}>
          <AssentCertificationsBarChart moduleRef={data} />
        </GridItem>
        <Grid.Item xs={2} />
        <Grid.Item xs={8} className="hc-pt-xl">
          <SuspenseRingLoader size={150} speedMultiplier={3}>
            <div className="hc-pa-none flex">
              <Grid.Container
                align="center"
                className="hc-ta-center"
                spacing="none"
                justify="center"
              >
                <Grid.Item
                  xs={6}
                  className="hc-ta-center bold-text hc-pt-expanded"
                >
                  Enrollment
                </Grid.Item>
                <Grid.Item
                  xs={6}
                  className="hc-ta-center bold-text hc-pt-expanded"
                >
                  Reviewers
                </Grid.Item>
              </Grid.Container>
              <Grid.Container
                align="center"
                className="hc-ta-center"
                spacing="none"
                justify="center"
              >
                <Grid.Item xs={6}>
                  <Avatar
                    color="indigo"
                    size="expanded"
                    className="enrollment-avatar"
                    style={{ width: '50px', height: '50px' }}
                    aria-label={''}
                  >
                    {data.gwtUsers?.totalCount}
                  </Avatar>
                </Grid.Item>
                <Grid.Item xs={6}>
                  <ReviewerAssignmentsPieChart
                    data={reviewerAssignmentsPieChartData}
                  />
                </Grid.Item>
              </Grid.Container>
            </div>
            <div className="hc-pa-none flex">
              <Grid.Container
                align="center"
                className="hc-ta-center"
                spacing="none"
                justify="center"
              >
                <Grid.Item
                  xs={6}
                  className="hc-ta-center bold-text hc-pt-expanded"
                >
                  Total Approval Rate
                </Grid.Item>
                <Grid.Item
                  xs={6}
                  className="hc-ta-center bold-text hc-pt-expanded"
                >
                  Softwares
                </Grid.Item>
              </Grid.Container>
              <Grid.Container
                align="center"
                className="hc-ta-center"
                spacing="none"
                justify="center"
              >
                <Grid.Item xs={6}>
                  <CompletionRatePieChart data={completionRatePieChartData} />
                </Grid.Item>
                <Grid.Item xs={6}>
                  <SoftwaresPieChart data={softwaresPieChartData} />
                </Grid.Item>
              </Grid.Container>
              <Grid.Container
                align="center"
                className="hc-ta-center"
                spacing="none"
                justify="left"
              >
                <Grid.Item className="hc-ta-center bold-text hc-pt-expanded hc-pl-sm">
                  Softwares Progress List
                </Grid.Item>
              </Grid.Container>
              <GridContainer>
                <GridItem xs={12}>
                  <SoftwaresCompletedBarChart
                    data={softwaresCompletedBarChartData}
                  />
                </GridItem>
              </GridContainer>
              <Grid.Container
                align="center"
                className="hc-ta-center"
                spacing="none"
                justify="center"
              >
                <Grid.Item
                  xs={6}
                  className="hc-ta-center bold-text hc-pt-expanded"
                >
                  Templates
                </Grid.Item>
                <Grid.Item
                  xs={6}
                  className="hc-ta-center bold-text hc-pt-expanded"
                >
                  Status
                </Grid.Item>
              </Grid.Container>
              <GridContainer
                align="center"
                className="hc-ta-center"
                spacing="none"
                justify="center"
              >
                <GridItem xs={6}>
                  <TemplatesPieChart data={modulesPieChartData} />
                </GridItem>
                <GridItem xs={6}>
                  <StatusPieChart data={statusPieChartData} />
                </GridItem>
              </GridContainer>
              <Grid.Container
                align="center"
                className="hc-ta-center"
                spacing="none"
                justify="left"
              >
                <Grid.Item className="hc-ta-center bold-text hc-pt-expanded hc-pl-sm">
                  Templates Progress List
                </Grid.Item>
              </Grid.Container>
              <GridContainer>
                <GridItem xs={12}>
                  <ModulesProgressBarChart data={modulesBarChartData} />
                </GridItem>
              </GridContainer>
            </div>
          </SuspenseRingLoader>
        </Grid.Item>
      </Grid.Container>
    </Layout.Body>
  )
}

const useGetInsightsData = () => {
  const [dataLength] = useState(20)

  const pageData = useSubscriptionRefreshQuery<InsightsDashboardQuery>(
    graphql`
      query InsightsDashboardQuery {
        ...InsightsDashboardFragment_activities
      }
    `,
    { count: dataLength || 20 },
    {
      component: 'INSIGHTS_DASHBOARD',
      uniqueComponentId: '',
      onNotification: [
        'TEMPLATE_CREATE_NOTIFICATION',
        'ASSIGN_TEMPLATE_NOTIFICATION',
        'ADMIN_DASHBOARD_FLOW_COMMENT_NOTIFICATION',
        'ASSIGNEE_MODULE_COMMENT_ADD_NOTIFICATION',
        'ASSIGNEE_MODULE_COMMENT_REPLY_NOTIFICATION',
        'COPY_TEMPLATE_NOTIFICATION',
        'REVIEWER_MODULE_BUTTON_NOTIFICATION',
        'ASSIGNEE_MODULE_SUBMIT_NOTIFICATION',
      ],
      includeSelfNotifications: true,
    },
  )

  const data = useFragment<InsightsDashboardFragment_activities$key>(
    graphql`
      fragment InsightsDashboardFragment_activities on Query
      @refetchable(queryName: "InsightsDashboardFragment_refetchable") {
        ...AssentCertificationsBarChart
        gwtUsers(condition: { userType: EXTERNAL_USER }) {
          edges {
            node {
              id
            }
          }
          totalCount
        }
        statusPiechartData: modules(
          filter: { flow: { flowType: { notEqualTo: BLUEPRINT } } }
        ) {
          edges {
            node {
              id
              softwareType
              flow {
                name
                assignmentStatus
                assignmentDueAtStatus
                dueAt
              }
            }
          }
        }
        moduleCompletionData: modules(
          filter: { flow: { assignmentStatus: {} } }
        ) {
          nodes {
            id
            programType
            softwareType
            flow {
              name
              assignmentStatus
              assignmentDueAtStatus
              assignedToId
            }
          }
        }
        moduleAssignedData: modules(
          filter: { flow: { assignedToExists: true } }
        ) {
          edges {
            node {
              id
              flow {
                name
              }
            }
          }
        }
        flowReviewers(
          filter: { flow: { flowType: { notEqualTo: BLUEPRINT } } }
        ) {
          edges {
            node {
              id
              reviewer {
                flowsByAssignedToId {
                  totalCount
                }
                fullName
              }
            }
          }
        }
      }
    `,
    pageData || null,
  )

  return { data }
}
