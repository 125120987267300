import { Dropdown, DropdownProps } from '@enterprise-ui/canvas-ui-react'
import { useState } from 'react'
import './Dropdown.scss'
import classNames from 'classnames'

interface DropdownSelectProps extends DropdownProps {
  options: Array<{
    label: string
    onSelect: () => void
    component?: React.ReactNode
    disabled?: boolean
    className?: string
  }>
  defaultValue?: string
  children?: React.ReactNode
  shouldSelect?: boolean
}

export const DropdownSelect = ({
  options,
  defaultValue,
  children,
  shouldSelect = true,
  ...props
}: DropdownSelectProps) => {
  const [selected, setSelected] = useState<string>(defaultValue || '')

  return (
    <div className="dropdown">
      <Dropdown size="dense" location="bottom-right" {...props}>
        {children}
        <Dropdown.Menu className="hc-bg-grey07">
          {options.map((option) => {
            return (
              <Dropdown.MenuItem
                key={option.label}
                disabled={option.disabled}
                className={classNames(
                  {
                    selected: selected === option.label,
                  },
                  option.className,
                )}
                onClick={() => {
                  option.onSelect()
                  shouldSelect && setSelected(option.label)
                }}
              >
                {option.component ? option.component : option.label}
              </Dropdown.MenuItem>
            )
          })}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  )
}
