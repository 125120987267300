import { Grid, Card, Divider } from '@enterprise-ui/canvas-ui-react'
import { SyntheticEvent, useEffect, useState } from 'react'
import { graphql, usePaginationFragment } from 'react-relay'
import { AssigneeDiscussionCardPaginationQuery } from './__generated__/AssigneeDiscussionCardPaginationQuery.graphql'
import { useGwtUser } from '../../../hooks'
import { AssigneeDiscussionCardPaginationFragment_flowComments$key } from './__generated__/AssigneeDiscussionCardPaginationFragment_flowComments.graphql'
import { usePaginateDataLength } from '../../../hooks/usePaginateDataLength'
import { useSubscriptionRefreshQuery } from '../../../hooks/useRefresh'
import { FlowLink } from '../../Flows/FlowLink'
import { UserAvatar } from '../../Users/UserAvatar'
import { getTimeFromCreatedAtString } from '../../../util/dateUtils'
import classNames from 'classnames'

export const AssigneeDiscussionCard = () => {
  const { data, loadNext, hasNext, isLoadingNext } =
    useAssigneeDiscussionCardQuery()

  const scrollEvent = (e: SyntheticEvent) => {
    if (isLoadingNext || !hasNext) {
      return
    }
    const target = e.target as HTMLTextAreaElement
    const scrollLeft = target.scrollHeight - target.scrollTop
    if (scrollLeft / target.clientHeight < 2) {
      loadNext(20)
    }
  }

  const notificationCount = data?.flowComments?.edges.filter(
    (comment) => comment.node.comment?.unread,
  ).length

  return (
    <Card className="cardLayout">
      <div className="cardHeader flex hc-pa-lg">
        <Grid.Container spacing="none" align="center" className="flex-grow">
          <Grid.Item>
            <div className="card-header-text">Discussion</div>
          </Grid.Item>
          <Grid.Item className="hc-ml-sm">
            {!!notificationCount && (
              <div className="comment-notification-badge">
                {notificationCount}{' '}
              </div>
            )}
          </Grid.Item>
        </Grid.Container>
      </div>
      <Divider />
      <div onScroll={scrollEvent} className="card-scroll flex-grow">
        {data &&
          data.flowComments?.edges.map((page) => {
            const flow_comment = page.node
            return (
              <div key={flow_comment.comment?.rowId}>
                <div
                  className={classNames(
                    'hc-pt-lg',
                    'hc-pl-lg',
                    'hc-pb-lg',
                    'hc-pr-md',
                    {
                      'comment-notification-border':
                        flow_comment.comment?.unread,
                    },
                  )}
                >
                  <Grid.Container spacing="none">
                    <Grid.Item xs={1}>
                      <UserAvatar
                        userRef={flow_comment.comment?.gwtUser}
                        showName={false}
                        aria-label={''}
                        children={undefined}
                      />
                    </Grid.Item>
                    <Grid.Item className="comment-container" xs={10}>
                      <div className="hc-ml-xs">
                        <span className="user-name-text">
                          {flow_comment.comment?.gwtUser?.fullName}
                        </span>
                        <span className="sub-text hc-ml-xs">
                          {getTimeFromCreatedAtString(
                            flow_comment.comment?.createdAt,
                          )}
                        </span>

                        {
                          <FlowLink isComment flowRef={flow_comment.flow}>
                            <p className="newline comment-hover dashboard-comment">
                              {flow_comment.comment?.commentText}
                            </p>
                          </FlowLink>
                        }
                      </div>
                    </Grid.Item>
                  </Grid.Container>
                </div>
                <Divider />
              </div>
            )
          })}
      </div>
    </Card>
  )
}

export const useAssigneeDiscussionCardQuery = () => {
  const [dataLength, setDataLength] = useState(20)
  const user = useGwtUser()

  const pageData =
    useSubscriptionRefreshQuery<AssigneeDiscussionCardPaginationQuery>(
      graphql`
        query AssigneeDiscussionCardPaginationQuery(
          $count: Int!
          $userId: UUID!
        ) {
          ...AssigneeDiscussionCardPaginationFragment_flowComments
            @arguments(count: $count, userId: $userId)
        }
      `,
      { count: dataLength || 20, userId: user.rowId },
      {
        component: 'ASSIGNEE_DISCUSSION_CARD',
        uniqueComponentId: '',
        onNotification: (noti) => {
          if (
            noti.name === 'ASSIGNEE_MODULE_COMMENT_ADD_NOTIFICATION' ||
            noti.name === 'ASSIGNEE_MODULE_COMMENT_REPLY_NOTIFICATION' ||
            noti.name === 'LAST_SEEN_COMMENT_UPDATED_NOTIFICATION'
          ) {
            return noti.data?.assignedToId === user.rowId
          }
          return false
        },
        includeSelfNotifications: true,
      },
    )

  const paginationFragment = usePaginationFragment<
    AssigneeDiscussionCardPaginationQuery,
    AssigneeDiscussionCardPaginationFragment_flowComments$key
  >(
    graphql`
      fragment AssigneeDiscussionCardPaginationFragment_flowComments on Query
      @argumentDefinitions(
        count: { type: "Int" }
        cursor: { type: "Cursor" }
        userId: { type: UUID }
      )
      @refetchable(
        queryName: "AssigneeDiscussionCardFlowCommentsPaginationQuery"
      ) {
        flowComments(
          orderBy: UNREAD_COMMENTS_CREATED_AT_DESC
          filter: { flow: { assignedToId: { equalTo: $userId } } }
          first: $count
          after: $cursor
        )
          @connection(
            key: "AssigneeDiscussionCardPaginationFragment_flowComments"
          ) {
          edges {
            node {
              comment {
                rowId
                unread
                commentText
                createdAt
                gwtUser {
                  fullName
                  ...UserAvatar_gwtUser
                }
              }
              flow {
                rowId
                name
                ...FlowLink_flow
              }
            }
          }
        }
      }
    `,
    pageData,
  )

  const paginateLength = usePaginateDataLength(
    paginationFragment?.data?.flowComments?.edges,
    20,
  )

  useEffect(() => {
    setDataLength(paginateLength)
  }, [paginateLength])

  return { ...paginationFragment, pageData }
}
