import { Card, Grid, Heading } from '@enterprise-ui/canvas-ui-react'
import {
  getGwtFormattedDate,
  getGwtFormattedTime,
} from '../../../util/dateUtils'
import { getFlowDurationInWeeks } from '../../../util/flowUtils'
import { GroupTemplateOptions } from '../GroupTemplateOptions/GroupTemplateOptions'
import { graphql, useFragment } from 'react-relay'
import { GroupTemplateCard_moduleGroup$key } from './__generated__/GroupTemplateCard_moduleGroup.graphql'
import { GroupTemplatePreviewButton } from '../../GroupTemplates/GroupTemplatePreviewButton/GroupTemplatePreviewButton'

interface GroupTemplateCardProps {
  groupModuleRef: GroupTemplateCard_moduleGroup$key
}

export const GroupTemplateCard = ({
  groupModuleRef,
}: GroupTemplateCardProps) => {
  const data = useFragment(
    graphql`
      fragment GroupTemplateCard_moduleGroup on ModuleGroup {
        ...GroupTemplateOptions_moduleGroup
        ...GroupTemplatePreviewButton_Fragment
        flow {
          rowId
          name
          updatedAt
          durationSeconds
        }
        moduleGroupLinksByModuleGroupFlowId {
          nodes {
            moduleFlow {
              flow {
                durationSeconds
              }
            }
          }
        }
      }
    `,
    groupModuleRef || null,
  )

  let totalDuration = 0
  data.moduleGroupLinksByModuleGroupFlowId.nodes.forEach((flow) => {
    totalDuration += flow?.moduleFlow?.flow?.durationSeconds || 0
  })
  const duration = getFlowDurationInWeeks(totalDuration)

  return (
    <Card key={data?.flow?.rowId} className="hc-pa-expanded">
      <Grid.Container spacing="none">
        <Grid.Item xs={9}>
          <Heading size={4} className="hc-ma-none">
            {data.flow?.name}
          </Heading>
        </Grid.Item>
        <Grid.Item xs={3}>
          <GroupTemplateOptions
            groupModuleRef={data}
            disableFavoriteConfirmationModal
          />
        </Grid.Item>
        <Grid.Item xs={12} className="hc-pb-lg">
          <p className="hc-mb-none">
            {`Last updated: ${getGwtFormattedDate(
              data.flow?.updatedAt,
            )} at ${getGwtFormattedTime(data.flow?.updatedAt)}`}
          </p>
          <p className="hc-mb-none">
            {`Duration: ${duration} ${duration === 1 ? 'week' : 'weeks'}`}
          </p>
        </Grid.Item>
        {
          // TODO: NEED TO ADD SOFTWARE FIELD TO DATA
        }
        {/* <p>Software: Clo</p> */}
        <Grid.Container justify="space-between" align="center">
          <Grid.Item />
          <Grid.Item>
            <GroupTemplatePreviewButton moduleGroupRef={data} />
          </Grid.Item>
        </Grid.Container>
      </Grid.Container>
    </Card>
  )
}
