import { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import { UUID } from '../react-app-env'
import { useQueryClient } from 'react-query'
import { usePublishGlobalNotification } from './useNotifications'
import { useUpdateAssetTimestamp } from './useUpdateAssetTimestamp'
import { useFileUpload } from './useFileUpload'
import useGwtUser from './useGwtUser'

export interface UploadFile {
  file: File
  id: UUID
  status: 'INIT' | 'UPLOADING' | 'UPLOADED' | 'ERROR'
  progress: number
  uploadError: string
}

export const useFilesUpload = () => {
  const [files, setFiles] = useState<UploadFile[]>([])
  const [isInFlight, setIsInFlight] = useState<boolean>(false)
  const { uploadFileToAsset } = useFileUpload()
  const queryClient = useQueryClient()
  const [isAllFilesUploaded, setIsAllFilesUploaded] = useState(false)
  const { publishToNetwork } = usePublishGlobalNotification()
  const { publishAssetUpdate } = useUpdateAssetTimestamp()
  const user = useGwtUser()

  const fileExists = (file: File, files: UploadFile[]) => {
    return files.find(
      (fileInList) =>
        fileInList.file.name === file.name &&
        fileInList.file.size === file.size &&
        fileInList.file.lastModified === file.lastModified,
    )
  }

  const addFile = (file: File) => {
    if (!fileExists(file, files)) {
      setFiles([
        ...files,
        { file, id: uuidv4(), status: 'INIT', progress: 0, uploadError: '' },
      ])
    }
  }

  const addFileTemp = (file: File, files: UploadFile[]) => {
    if (!fileExists(file, files)) {
      files.push({
        file,
        id: uuidv4(),
        status: 'INIT',
        progress: 0,
        uploadError: '',
      })
    }
  }

  const addFiles = (filesToAdd: File[]) => {
    const tempFiles: UploadFile[] = [...files]
    filesToAdd.forEach((file) => addFileTemp(file, tempFiles))
    setFiles(tempFiles)
  }

  const deleteFile = (id: UUID) => {
    const index = files.findIndex((uFile) => uFile.id === id)
    if (index !== -1) {
      files.splice(index, 1)
      setFiles([...files])
    }
  }

  const updateFile = (file: UploadFile) => {
    const index = files.findIndex((uFile) => uFile.id === file.id)
    const updatedFiles = [...files]
    updatedFiles[index] = file
    setFiles(updatedFiles)
  }

  const uploadUFileToAsset = async (file: UploadFile, assetId: UUID) => {
    if (file.status === 'UPLOADED' || file.status === 'UPLOADING') {
      return
    }

    const uploadPromise = uploadFileToAsset(
      file.file,
      assetId,
      file.file.name,
      (progress) => {
        file.progress = progress
        updateFile(file)
      },
    )

    file.status = 'UPLOADING'
    updateFile(file)
    const response = await uploadPromise
    const uploaded = response.success

    file.status = uploaded ? 'UPLOADED' : 'ERROR'
    file.uploadError = uploaded ? '' : response.errors[0]
    if (!uploaded) {
      file.progress = 0
    }

    updateFile(file)
    return
  }

  const uploadFilesToAsset = async (assetId: UUID | undefined) => {
    if (!assetId) {
      // can't upload to an undefined asset so don't do anything
      return
    }
    setIsInFlight(true)
    const promises = files.map((file) => uploadUFileToAsset(file, assetId))
    await Promise.all(promises)
    setIsInFlight(false)
    setIsAllFilesUploaded(!files.find((e) => e.status !== 'UPLOADED'))

    // invalidate the current asset query data after upload if not an external user
    if (user.userType !== 'EXTERNAL_USER') {
      queryClient.invalidateQueries(['getAsset', assetId])
      publishToNetwork('GALLERY_ASSET_INVALIDATED_NOTIFICATION', {
        assetId,
      })
    }

    if (promises.length > 0) {
      // fire a request that the asset has been updated to the api so that the timestamps are updated
      publishAssetUpdate(assetId)
    }

    return
  }

  const clearFiles = () => {
    setFiles([])
    setIsAllFilesUploaded(false)
  }

  return {
    files,
    isInFlight,
    addFile,
    addFiles,
    deleteFile,
    clearFiles,
    updateFile,
    uploadFilesToAsset,
    isAllFilesUploaded,
  }
}
