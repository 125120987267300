/**
 * @generated SignedSource<<52ba63bab1b68588c3c48086c55e6621>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeedbackOptions_feedback$data = {
  readonly archivedAt: Date | null;
  readonly claimedBy: {
    readonly fullName: string;
    readonly rowId: UUID;
  } | null;
  readonly resolved: boolean;
  readonly rowId: UUID;
  readonly " $fragmentType": "FeedbackOptions_feedback";
};
export type FeedbackOptions_feedback$key = {
  readonly " $data"?: FeedbackOptions_feedback$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedbackOptions_feedback">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedbackOptions_feedback",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "archivedAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GwtUser",
      "kind": "LinkedField",
      "name": "claimedBy",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "resolved",
      "storageKey": null
    }
  ],
  "type": "Feedback",
  "abstractKey": null
};
})();

(node as any).hash = "494d259f2c34d3e48576885dad9e668b";

export default node;
