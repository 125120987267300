import React from 'react'
import { useEnv } from '@praxis/component-runtime-env'

import './Gallery.scss'
import { FileView } from '@galleryjs/api-digital-assets'
import { GalleryAssetFileDisplay } from './GalleryAssetFileDisplay'
import Showdog from 'showdog'
import { IURLLoaderAsset } from 'showdog/plugins'

// This is a component for displaying either showdog or an asset image
interface GalleryShowcaseDisplayProps {
  file: FileView | undefined
}

const SHOWDOG_TYPES: string[] = ['glb']

export const GalleryShowcaseDisplay = ({
  file,
}: GalleryShowcaseDisplayProps) => {
  const env = useEnv()
  const apiBaseUrl = env.baseGalleryApiUrl

  let fileSources

  if (file?.fileSources) {
    fileSources = [...file.fileSources]
  }

  const fileExtension = fileSources?.[0]?.fileExtension
    ?.replace(/[^[^a-z]+/g, '')
    .toLowerCase()

  const isShowdogType = fileExtension && SHOWDOG_TYPES.includes(fileExtension)

  const urlLoaderFile: IURLLoaderAsset = {
    fileName: file?.name || '',
    url: fileSources?.[0]?.accessUrl || `${apiBaseUrl}/files/${file?.id}`,
  }

  return (
    <div>
      {isShowdogType && (
        <div className="showcase-container asset-center hc-mt-xs hc-mb-xs">
          <Showdog
            setShowdogFunctions={() => 0}
            modelUrlsToShow={[urlLoaderFile]}
          />
        </div>
      )}
      {!isShowdogType && (
        <GalleryAssetFileDisplay
          file={file}
          showcase
          displayFileNameBelowImage={false}
          displayFileNameInChip
          center
          enableLightbox
        />
      )}
    </div>
  )
}
