import { useState } from 'react'
import {
  useDndMonitor,
  DragEndEvent,
  DragStartEvent,
  DragOverEvent,
} from '@dnd-kit/core'

export const useAssentDnd = (onDrop?: (event: DragEndEvent) => void) => {
  const [isDragging, setIsDragging] = useState<DragStartEvent | undefined>(
    undefined,
  )
  const [isOverDroppable, setIsOverDroppable] = useState<
    DragOverEvent | undefined
  >(undefined)

  useDndMonitor({
    onDragStart(event) {
      setIsDragging(event)
    },
    onDragEnd(event) {
      setIsDragging(undefined)
      setIsOverDroppable(undefined)
      if (event.over && onDrop) {
        onDrop(event)
      }
    },
    onDragCancel(event) {
      setIsDragging(undefined)
      setIsOverDroppable(undefined)
    },
    onDragOver(event) {
      setIsOverDroppable(event)
    },
    onDragMove(event) {
      if (!event.over && isOverDroppable) {
        setIsOverDroppable(undefined)
      }
    },
  })

  return {
    isDragging,
    isOverDroppable,
  }
}
