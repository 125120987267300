/**
 * @generated SignedSource<<5f70f404c703d40e715e569830405972>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SubmittedAssetFilesDisplay_Fragment$data = {
  readonly flow: {
    readonly assignedTo: {
      readonly fullName: string;
    } | null;
  } | null;
  readonly moduleSubmissionsByModuleFlowId: {
    readonly nodes: ReadonlyArray<{
      readonly attempt: number;
      readonly createdAt: Date | null;
      readonly galleryAssetSubmissionId: UUID;
    }>;
  };
  readonly " $fragmentType": "SubmittedAssetFilesDisplay_Fragment";
};
export type SubmittedAssetFilesDisplay_Fragment$key = {
  readonly " $data"?: SubmittedAssetFilesDisplay_Fragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"SubmittedAssetFilesDisplay_Fragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SubmittedAssetFilesDisplay_Fragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Flow",
      "kind": "LinkedField",
      "name": "flow",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "GwtUser",
          "kind": "LinkedField",
          "name": "assignedTo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "fullName",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "ATTEMPT_DESC"
        }
      ],
      "concreteType": "ModuleSubmissionsConnection",
      "kind": "LinkedField",
      "name": "moduleSubmissionsByModuleFlowId",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ModuleSubmission",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "attempt",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "galleryAssetSubmissionId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "createdAt",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "moduleSubmissionsByModuleFlowId(orderBy:\"ATTEMPT_DESC\")"
    }
  ],
  "type": "Module",
  "abstractKey": null
};

(node as any).hash = "e2579a11114cde275ef29aae4932bb3d";

export default node;
