import {
  SingleResponseAssetViewFromJSON,
  SingleResponseEmtFileSignResponseFromJSON,
  SingleResponseUnitToJSON,
} from '@galleryjs/api-digital-assets'
import { useAuth } from '@praxis/component-auth'
import { useEnv } from '@praxis/component-runtime-env'
import axios from 'axios'
import { useCallback } from 'react'
import { graphql, fetchQuery } from 'react-relay'
import { UUID } from '../react-app-env'
import { GwtEnv } from '../types'
import useGwtRelayEnvironment from './useGwtRelayEnvironment'

export const useGwtRestApi = () => {
  const auth = useAuth()
  const env = useEnv<GwtEnv>()
  const environment = useGwtRelayEnvironment()

  const accessToken = auth.session?.accessToken
  let idToken = ''
  if (localStorage.getItem('id_token')) {
    idToken = `${localStorage.getItem('id_token')}`
  }

  const axiosClient = axios.create({
    baseURL: env.baseGwtApiUrl,
    maxContentLength: Infinity,
    maxBodyLength: Infinity,
    withCredentials: true,
  })
  axiosClient.interceptors.request.use((config) => ({
    ...config,
    headers: {
      Authorization: `Bearer ${accessToken}`,
      idToken,
    },
  }))

  const login = useCallback(() => {
    axiosClient
      .get('/login')
      .catch(() => {
        if (window.location.pathname !== '/') {
          window.location.href = '/'
        }
      })
      .then(() => {
        fetchQuery(
          environment,
          graphql`
            query useGwtRestApi_userUpdateQuery {
              myUser {
                id
                rowId
                userType
                emailOnAllAssignmentStatusChanges
                emailBulk
                emailBulkTime
                emailModuleNotifications
                status
                fullName
                firstName
                lastName
                programs
                partnersonline
                company {
                  name
                }
              }
            }
          `,
          {},
        ).toPromise()
      })
  }, [axiosClient, environment])

  const proxyGetAsset = useCallback(
    async (assetId: string) => {
      let response = await axiosClient.get(`/gallery/asset?assetId=${assetId}`)

      // deserialize the custom json since we arn't going through the package anymore
      response.data = SingleResponseAssetViewFromJSON(response.data)

      return response
    },
    [axiosClient],
  )

  const proxyDownloadAssetFiles = useCallback(
    async (assetId: UUID, fileIds: UUID[]): Promise<string> => {
      const formattedFileIds = fileIds.reduce(
        (fileIds: string, currentFileId: string) =>
          `${fileIds},${currentFileId}`,
      )
      // Unlikely scenario but didn't want to hit the API in case this happened.
      let response = await axiosClient.get(
        `/gallery/asset/${assetId}/files/download?file_id=${formattedFileIds}`,
      )

      return SingleResponseEmtFileSignResponseFromJSON(response.data).data
        .preSignedUrl
    },
    [axiosClient],
  )

  const proxyDeleteFiles: any = useCallback(
    async (assetId: string, files: string[]) => {
      let response = await axiosClient.delete(
        `/gallery/asset?assetId=${assetId}&files=${files.join(',')}`,
      )

      response.data = SingleResponseUnitToJSON(response.data)

      return response
    },
    [axiosClient],
  )

  return {
    login,
    proxyGetAsset,
    proxyDownloadAssetFiles,
    proxyDeleteFiles,
  }
}
