import {
  Dropdown,
  ExpandableSection,
  Input,
} from '@enterprise-ui/canvas-ui-react'
import { useState } from 'react'
import { getGwtFormattedDate } from '../../util/dateUtils'
import { getAssignmentStatusTextNoDate } from '../../util/flowUtils'
import {
  AssignmentStatus,
  ModuleFilter,
} from '../../views/Admin/__generated__/StatusCardPaginationQuery.graphql'
import { SoftwareType } from '../../views/Admin/__generated__/TemplateEditPageQuery.graphql'
import { ProgramType } from '../../views/Reviewer/__generated__/ReviewerStatusCardPaginationQuery_Refetchable.graphql'
import './ModuleFilterDropdown.scss'
import { DatePicker } from '@enterprise-ui/canvas-ui-react-datepicker'
import {
  getModuleProgramTypeText,
  getModuleSoftwareTypeText,
} from '../../util/moduleUtils'
import { useEffect } from 'react'
import {
  FlowFilter,
  IncludeArchivedOption,
} from '../../views/AssignmentsTable/__generated__/AssignmentsTablePaginationQuery.graphql'
import { cloneDeep, isEqual } from 'lodash'
type DateRangeOption = 'Single Date' | 'Date Range'

export type ModuleFilterChip = {
  key: string
  value: string
  remove: () => void
}
interface ModuleFilterProps {
  children?: React.ReactNode
  onFilterUpdated?: (filter: ModuleFilter) => void
  onChipsUpdated?: (chips: ModuleFilterChip[]) => void
  onArchiveUpdated?: (
    includeArchived: IncludeArchivedOption | undefined,
  ) => void
}

export const DEFAULT_MODULE_FILTER: ModuleFilter = {
  softwareType: {
    equalTo: undefined,
  },
  programType: {
    equalTo: undefined,
  },
  flow: {
    assignmentStatus: {
      notEqualTo: 'NONE',
    },
    or: undefined,
    completedAt: {
      lessThanOrEqualTo: undefined,
      greaterThanOrEqualTo: undefined,
    },
  },
}

export const ModuleFilterDropdown = (props: ModuleFilterProps) => {
  const [moduleFilter, setModuleFilter] = useState<ModuleFilter>(
    DEFAULT_MODULE_FILTER,
  )
  const [moduleChips, setModuleChips] = useState<ModuleFilterChip[]>([])

  const [assignmentStatuses, setAssignmentStatuses] = useState<
    ModuleFilterStatuses[]
  >([])
  const [softwareType, setSoftwareType] = useState<SoftwareType | undefined>(
    undefined,
  )
  const [programType, setProgramType] = useState<ProgramType | undefined>(
    undefined,
  )
  const [lowerCompletedDateBound, setLowerCompletedDateBound] = useState<
    Date | undefined
  >(undefined)
  const [upperCompletedDateBound, setUpperCompletedDateBound] = useState<
    Date | undefined
  >(undefined)
  const [dateRangeOption, setDateRangeOption] =
    useState<DateRangeOption>('Single Date')

  const [includeArchived, setIncludeArchived] = useState<
    IncludeArchivedOption | undefined
  >(undefined)

  type ModuleFilterStatuses =
    | AssignmentStatus
    | 'OVERDUE'
    | 'DUE_SOON'
    | 'UNASSIGNED'
  const statuses: ModuleFilterStatuses[] = [
    'ACCEPTED',
    'IN_PROGRESS',
    'READY_FOR_REVIEW',
    'REJECTED',
    'UNDER_REVIEW',
    'OVERDUE',
    'DUE_SOON',
    'UNASSIGNED',
  ]

  const programTypes: ProgramType[] = ['A_A_3_D_ACCREDITATION', '_3_D_STYLING']
  const softwareTypes: SoftwareType[] = ['BROWZWEAR', 'CLO', 'OPTITEX']

  useEffect(() => {
    const assignmentStatusFilters: FlowFilter[] = assignmentStatuses.map(
      (status) => {
        if (status === 'OVERDUE') {
          return {
            assignmentDueAtStatus: { equalTo: 'OVERDUE' },
            assignmentStatus: { equalTo: 'IN_PROGRESS' },
          }
        }

        if (status === 'DUE_SOON') {
          return {
            assignmentStatus: { equalTo: 'IN_PROGRESS' },
            assignmentDueAtStatus: { equalTo: 'DUE_SOON' },
          }
        }

        if (status === 'UNASSIGNED') {
          return {
            flowReviewersExist: false,
          }
        }

        return { assignmentStatus: { equalTo: status } }
      },
    )

    const filter: ModuleFilter = {
      softwareType: {
        equalTo: softwareType,
      },
      programType: {
        equalTo: programType,
      },
      flow: {
        assignmentStatus: {
          notEqualTo: 'NONE',
        },
        or:
          assignmentStatusFilters.length > 0
            ? assignmentStatusFilters
            : undefined,
        completedAt: {
          lessThanOrEqualTo: upperCompletedDateBound,
          greaterThanOrEqualTo: lowerCompletedDateBound,
        },
      },
    }
    if (
      !isEqual(
        cloneFilterAndRemoveDueAt(filter),
        cloneFilterAndRemoveDueAt(moduleFilter),
      )
    ) {
      setModuleFilter(filter)
    }
  }, [
    assignmentStatuses,
    lowerCompletedDateBound,
    moduleFilter,
    programType,
    softwareType,
    upperCompletedDateBound,
  ])

  // update the module chips
  useEffect(() => {
    const chips: ModuleFilterChip[] = []

    assignmentStatuses?.forEach((status) => {
      chips.push({
        key: 'Status',
        value: getAssignmentStatusTextNoDate(status),
        remove: () => {
          setAssignmentStatuses((arr) => {
            const updatedArr = [...arr]
            const index = updatedArr.indexOf(status)
            if (index !== undefined && index > -1) {
              updatedArr.splice(index, 1)
            }
            return updatedArr
          })
        },
      })
    })

    if (lowerCompletedDateBound) {
      chips.push({
        key: 'Date from',
        value: getGwtFormattedDate(lowerCompletedDateBound),
        remove: () => {
          setLowerCompletedDateBound(undefined)
        },
      })
    }

    if (upperCompletedDateBound) {
      chips.push({
        key: 'Date to',
        value: getGwtFormattedDate(upperCompletedDateBound),
        remove: () => {
          setUpperCompletedDateBound(undefined)
        },
      })
    }

    if (programType) {
      chips.push({
        key: 'Program',
        value: getModuleProgramTypeText(programType),
        remove: () => {
          setProgramType(undefined)
        },
      })
    }

    if (softwareType) {
      chips.push({
        key: 'Software',
        value: getModuleSoftwareTypeText(softwareType),
        remove: () => {
          setSoftwareType(undefined)
        },
      })
    }

    if (includeArchived) {
      chips.push({
        key: 'Only show',
        value: 'Archived',
        remove: () => {
          setIncludeArchived(undefined)
        },
      })
    }

    if (!areChipsEqual(chips, moduleChips)) {
      setModuleChips(chips)
    }
  }, [
    assignmentStatuses,
    includeArchived,
    lowerCompletedDateBound,
    moduleChips,
    programType,
    softwareType,
    upperCompletedDateBound,
  ])

  useEffect(() => {
    if (props.onChipsUpdated) {
      props.onChipsUpdated(moduleChips)
    }
  }, [moduleChips, props])

  useEffect(() => {
    if (props.onFilterUpdated) {
      props.onFilterUpdated(moduleFilter || {})
    }
  }, [moduleFilter, props])

  useEffect(() => {
    if (props.onArchiveUpdated) {
      props.onArchiveUpdated(includeArchived)
    }
  }, [includeArchived, props])

  return (
    <Dropdown location="bottom-right" className="module-dropdown">
      {props.children}
      <Dropdown.Menu>
        <Dropdown.MenuItem className="selected expandable-hover">
          <ExpandableSection
            padding="none"
            onClick={(e: any) => {
              e.stopPropagation()
            }}
          >
            <div className="normal-text">
              {'Archived'}{' '}
              {includeArchived && (
                <div className="filter-notification-badge">{1}</div>
              )}
            </div>
            <ExpandableSection.Content>
              <Input.Checkbox
                id="archived"
                label="Only show archived"
                checked={includeArchived === 'EXCLUSIVELY'}
                onChange={(e: any) => {
                  setIncludeArchived(
                    e.target.checked ? 'EXCLUSIVELY' : undefined,
                  )
                }}
              />
            </ExpandableSection.Content>
          </ExpandableSection>
        </Dropdown.MenuItem>

        <Dropdown.MenuItem className="selected expandable-hover">
          <ExpandableSection
            padding="none"
            onClick={(e: any) => {
              e.stopPropagation()
            }}
          >
            <div className="normal-text">
              {'Status'}{' '}
              {assignmentStatuses && assignmentStatuses.length > 0 && (
                <div className="filter-notification-badge">
                  {assignmentStatuses.length}{' '}
                </div>
              )}
            </div>
            <ExpandableSection.Content>
              <Input.Checkboxes
                id="demo_01"
                value={assignmentStatuses}
                onUpdate={(id: any, value: any) => {
                  setAssignmentStatuses(value)
                }}
                options={statuses.map((status: ModuleFilterStatuses) => {
                  return {
                    value: status,
                    label: getAssignmentStatusTextNoDate(status),
                  }
                })}
              />
            </ExpandableSection.Content>
          </ExpandableSection>
        </Dropdown.MenuItem>

        <Dropdown.MenuItem className="selected expandable-hover">
          <ExpandableSection
            padding="none"
            onClick={(e: any) => {
              e.stopPropagation()
            }}
          >
            <div className="normal-text">
              {'Program'}{' '}
              {programType && (
                <div className="filter-notification-badge">{1} </div>
              )}
            </div>
            <ExpandableSection.Content>
              {programTypes.map((type) => {
                return (
                  <Input.Checkbox
                    id={type}
                    key={type}
                    checked={programType === type}
                    label={getModuleProgramTypeText(type)}
                    onChange={(event: any) => {
                      if (event.target.checked) {
                        setProgramType(type)
                      } else {
                        setProgramType(undefined)
                      }
                    }}
                  />
                )
              })}
            </ExpandableSection.Content>
          </ExpandableSection>
        </Dropdown.MenuItem>

        <Dropdown.MenuItem className="selected expandable-hover">
          <ExpandableSection
            padding="none"
            onClick={(e: any) => {
              e.stopPropagation()
            }}
          >
            <div className="normal-text">
              {'Software'}{' '}
              {softwareType && (
                <div className="filter-notification-badge">{1} </div>
              )}
            </div>
            <ExpandableSection.Content>
              {softwareTypes.map((type) => {
                return (
                  <Input.Checkbox
                    id={type}
                    key={type}
                    checked={softwareType === type}
                    label={getModuleSoftwareTypeText(type)}
                    onChange={(event: any) => {
                      if (event.target.checked) {
                        setSoftwareType(type)
                      } else {
                        setSoftwareType(undefined)
                      }
                    }}
                  />
                )
              })}
            </ExpandableSection.Content>
          </ExpandableSection>
        </Dropdown.MenuItem>

        <Dropdown.MenuItem className="selected expandable-hover">
          <ExpandableSection
            padding="none"
            style={{ width: '100%' }}
            onClick={(e: any) => {
              e.stopPropagation()
            }}
          >
            <div className="normal-text">
              {'Date Completed'}{' '}
              {dateRangeOption === 'Single Date' && lowerCompletedDateBound && (
                <div className="filter-notification-badge">{1} </div>
              )}
              {dateRangeOption === 'Date Range' &&
                lowerCompletedDateBound &&
                upperCompletedDateBound && (
                  <div className="filter-notification-badge">{1} </div>
                )}
            </div>
            <ExpandableSection.Content className="overflow">
              <Input.Radio
                className="hc-mb-lg"
                id="date-complete-radio"
                value={dateRangeOption}
                options={[
                  { value: 'Single Date', label: 'Single date' },
                  { value: 'Date Range', label: 'Date range' },
                ]}
                onUpdate={(id: any, value: any) => {
                  //also reset the upper/lower bounds
                  setLowerCompletedDateBound(undefined)
                  setUpperCompletedDateBound(undefined)
                  setDateRangeOption(value)
                }}
              />
              {dateRangeOption === 'Date Range' && (
                <>
                  <DatePicker
                    id="start-date"
                    value={lowerCompletedDateBound}
                    label="Start Date"
                    onUpdate={(id: any, date: any) => {
                      setLowerCompletedDateBound(date)
                    }}
                    alignCalendarDropdown="right"
                    location="top-right"
                    type="gregorian"
                  />
                  <DatePicker
                    id="end-date"
                    value={upperCompletedDateBound}
                    label="End Date"
                    onUpdate={(id: any, date: any) => {
                      setUpperCompletedDateBound(date)
                    }}
                    alignCalendarDropdown="right"
                    location="top-right"
                    type="gregorian"
                  />
                </>
              )}
              {dateRangeOption === 'Single Date' && (
                <>
                  <DatePicker
                    className="date-picker"
                    id="single-date"
                    label="Date"
                    value={lowerCompletedDateBound}
                    onUpdate={(id: any, date: any) =>
                      setLowerCompletedDateBound(date)
                    }
                    alignCalendarDropdown="right"
                    location="top-right"
                    type="gregorian"
                  />
                </>
              )}
            </ExpandableSection.Content>
          </ExpandableSection>
        </Dropdown.MenuItem>
      </Dropdown.Menu>
    </Dropdown>
  )
}

const areChipsEqual = (
  chipsA: ModuleFilterChip[],
  chipsB: ModuleFilterChip[],
) => {
  let equal = true
  if (chipsA.length !== chipsB.length) {
    return false
  } else {
    chipsA.forEach((e) => {
      if (
        !chipsB.find((f) => {
          return f.key === e.key && f.value === e.value
        })
      ) {
        equal = false
      }
    })
  }
  return equal
}

const cloneFilterAndRemoveDueAt = (filter: ModuleFilter) => {
  const clonedFilter = cloneDeep(filter)

  const iterate = (obj: any) => {
    Object.keys(obj).forEach((key) => {
      if (key === 'dueAt') {
        obj[key] = undefined
      }

      if (typeof obj[key] === 'object') {
        iterate(obj[key])
      }
    })
  }

  iterate(clonedFilter)

  return clonedFilter
}
