import {
  Tooltip,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts'
import { map } from 'lodash'

interface ModulesProgressBarChartProps {
  data: { name: string; assignmentStatus: string }[] | undefined
}

export const ModulesProgressBarChart = ({
  data,
}: ModulesProgressBarChartProps) => {
  // Counts names in data and returns it in typescript object of form {NAME: COUNT, NAME2: COUNT, etc...}
  const countNamesInData = (
    data: { name: string; assignmentStatus: string }[] | undefined,
  ) => {
    const objectNames = data?.map((module) => {
      return module.name
    })
    const objectNameCounts = {}
    objectNames?.forEach(function (x) {
      objectNameCounts[x] = (objectNameCounts[x] || 0) + 1
    })
    return objectNameCounts
  }

  const createModulesProgressBarData = () => {
    //Count duplicate names in data that has all modules
    const moduleNameCounts = countNamesInData(data)

    //Count duplicate names in data that only has accepted modules
    const acceptedModulesData = data?.filter(
      (module) => module.assignmentStatus === 'ACCEPTED',
    )
    const acceptedModuleNameCounts = countNamesInData(acceptedModulesData)

    // Creates list of objects that contain name of module
    // # of this type of module that have been accepted
    // # of accepted type of module that have not been accepted
    return map(moduleNameCounts, (count, softwareName) => {
      const acceptedModules = acceptedModuleNameCounts[softwareName] || 0
      return {
        name: softwareName,
        acceptedModules,
        notAcceptedModules: count - acceptedModules,
      }
    })
  }

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-bar-graph-tooltip">
          <div>{`${payload[0].payload.name}`}</div>
          <div>{`Accepted Templates: ${payload[0].value} `}</div>
          <div>{`Not Accepted Templates: ${payload[1].value} `}</div>
        </div>
      )
    }

    return null
  }

  const ModulesProgressBarData = createModulesProgressBarData()
  const chartHeight = ModulesProgressBarData.length * 80

  return (
    <div>
      <ResponsiveContainer height={chartHeight} width="100%">
        <BarChart
          layout="vertical"
          width={600}
          height={250}
          data={ModulesProgressBarData}
          margin={{
            top: 5,
            right: 5,
            left: 27,
            bottom: 5,
          }}
          maxBarSize={40}
        >
          <CartesianGrid horizontal={false} />
          <XAxis
            type="number"
            axisLine={false}
            allowDecimals={false}
            tickCount={3}
          />
          <YAxis
            type="category"
            tickLine={true}
            axisLine={false}
            dataKey="name"
            tick={{ fontSize: 12 }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="acceptedModules" stackId="a" fill="#42ff94" />
          <Bar dataKey="notAcceptedModules" stackId="a" fill="#ff424f" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}
