import {
  Tooltip,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts'
import { useFragment } from 'react-relay'
import { graphql } from 'relay-runtime'
import { AssentCertificationsBarChart$key } from './__generated__/AssentCertificationsBarChart.graphql'

interface AssentCertificationsBarChartProps {
  moduleRef: AssentCertificationsBarChart$key | null | undefined
}

export const AssentCertificationsBarChart = ({
  moduleRef,
}: AssentCertificationsBarChartProps) => {
  const data = useFragment(
    graphql`
      fragment AssentCertificationsBarChart on Query {
        modules(filter: { flow: { assignmentStatus: { equalTo: ACCEPTED } } }) {
          nodes {
            programType
            softwareType
            flow {
              assignedToId
            }
          }
        }
      }
    `,
    moduleRef || null,
  )

  const createAssentCertificationsBarData = () => {
    let uniqueIds = new Set<string>()
    data?.modules?.nodes.forEach((node: any) =>
      uniqueIds.add(node.flow?.assignedToId),
    )
    return [
      {
        name: 'Clo',
        completed: data?.modules?.nodes.filter(
          (node: any) => node.softwareType === 'CLO',
        ).length,
      },
      {
        name: 'Browzwear',
        completed: data?.modules?.nodes.filter(
          (node: any) => node.softwareType === 'BROWZWEAR',
        ).length,
      },
      {
        name: 'Unique Users with at least one module completed',
        completed: uniqueIds.size,
      },
    ]
  }

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-bar-graph-tooltip">
          <div>{`Certified users: ${payload[0].value} `}</div>
        </div>
      )
    }

    return null
  }

  const AssentCertificationsBarData = createAssentCertificationsBarData()
  const chartHeight = AssentCertificationsBarData.length * 80

  return (
    <div>
      <ResponsiveContainer height={chartHeight} width="100%">
        <BarChart
          layout="vertical"
          width={600}
          height={250}
          data={AssentCertificationsBarData}
          margin={{
            top: 5,
            right: 5,
            left: 27,
            bottom: 5,
          }}
          maxBarSize={40}
        >
          <CartesianGrid horizontal={false} />
          <XAxis
            type="number"
            axisLine={false}
            allowDecimals={false}
            tickCount={3}
          />
          <YAxis
            type="category"
            tickLine={true}
            axisLine={false}
            dataKey="name"
            tick={{ fontSize: 12 }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="completed" stackId="a" fill="#42ff94" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}
