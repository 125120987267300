import { FeedbackLevel } from '../globalComponents/Feedback/__generated__/FeedbackMutation.graphql'

export const getFeedbackLevelText = (
  feedbackLevel: FeedbackLevel | undefined,
) => {
  switch (feedbackLevel) {
    case 'SUGGESTION':
      return 'Suggestion'
    case 'MINOR':
      return 'Issue'
    case 'MAJOR':
      return 'Bug'
    case 'CRITICAL':
      return 'Critical'
    default:
      return 'Unknown'
  }
}

export const getFeedbackLevelDescription = (
  feedbackLevel: FeedbackLevel | undefined,
) => {
  switch (feedbackLevel) {
    case 'SUGGESTION':
      return 'A change that would make working with the application easier for you.'
    case 'MINOR':
      return 'The desired functionality is not working however you can work around the issue.'
    case 'MAJOR':
      return 'The desired functionality is not working and it is significantly impacting you.'
    case 'CRITICAL':
      return 'The desired functionality is not working and you cannot work.'
    default:
      return 'Unknown'
  }
}
