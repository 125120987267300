import { Card, Chip } from '@enterprise-ui/canvas-ui-react'
import React, { useState } from 'react'
import './Gallery.scss'
import { UploadFile } from '../../state/atoms'
import GalleryFileVirusScanImage from './GalleryFileVirusScanImage'
import { PolRetryButton } from './PolButtons/PolRetryButton'
import { PolCancelButton } from './PolButtons/PolCancelButton'

interface GalleryAssetVirusScanFileDisplayProps {
  file: UploadFile | undefined
  enableSelect?: boolean
  checked?: boolean
  selected?: boolean
  onClick?: (file: UploadFile) => void
  children?: React.ReactNode
  attempt?: number | undefined
  thumbnail?: boolean
  showcase?: boolean
  displayFileNameBelowImage?: boolean
  displayFileNameInChip?: boolean
  enableLightbox?: boolean
  center?: boolean
}

export const GalleryAssetVirusScanFileDisplay = ({
  file,
  enableSelect,
  checked,
  selected,
  onClick,
  children,
  attempt,
  showcase,
  thumbnail = false,
  enableLightbox = false,
  displayFileNameBelowImage = false,
  displayFileNameInChip = true,
  center = false,
}: GalleryAssetVirusScanFileDisplayProps) => {
  const [showFullName, setShowFullName] = useState(false)

  if (file === undefined) {
    return <></>
  }

  let assetClassNameBase = thumbnail ? 'asset-thumbnail' : 'asset'

  if (showcase) {
    assetClassNameBase = 'asset-showcase'
  }

  // do not allow enabling fullscreen on the images if selecting images is enabled
  if (enableLightbox && enableSelect) {
    enableLightbox = false
  }

  let cardCssClass = selected ? 'result selected' : 'result'
  if (center) {
    cardCssClass = `${cardCssClass} asset-center`
  }

  const fullFileName = file.fileName

  return (
    <div
      className={`${assetClassNameBase} hc-pa-xs`}
      data-test-id="asset-result"
    >
      <Card elevation={1} className={cardCssClass}>
        {/* {attempt && (
          <span className="label hc-ml-sm hc-mt-dense">{`attempt ${attempt}`}</span>
        )} */}
        <div
          className={`${assetClassNameBase}__checkbox`}
          data-test-id="asset-checkbox"
          onClick={(e: React.MouseEvent<HTMLDivElement>) => {
            e.preventDefault()
            e.stopPropagation()
          }}
        >
          <PolCancelButton file={file} tooltipLocation="right" />
        </div>
        <div
          onClick={() => {
            setShowFullName(!showFullName)
            if (onClick && enableSelect) {
              onClick(file)
            }
          }}
        >
          <GalleryFileVirusScanImage
            alt={file.fileName}
            file={file}
            className={`${assetClassNameBase}__thumbnail`}
            width="100%"
            size="cover"
          />
        </div>
        <div className={`${assetClassNameBase}__download hc-mr-xs`}>
          <PolRetryButton file={file} />
        </div>
        {displayFileNameInChip && (
          <div className="chip-container hc-pl-min hc-pr-min">
            <Chip size="dense" className="chip-display">
              {fullFileName}
            </Chip>
          </div>
        )}
      </Card>
      <div data-test-id="asset-result-name">
        {displayFileNameBelowImage && (
          <div className="file-name-text line-limit-2">{fullFileName}</div>
        )}
        {children && children}
      </div>
    </div>
  )
}
