import { Button, Heading, Table, Tooltip } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, { MinusCircleIcon } from '@enterprise-ui/icons'
import './GroupTemplateFinalization.scss'
import { GroupTemplateName } from '../GroupTemplateModules/GroupTemplateName'
import { getGwtFormattedDate } from '../../../util/dateUtils'
import { getModuleProgramTypeText } from '../../../util/moduleUtils'
import { noop } from 'lodash'
import classNames from 'classnames'

interface GroupTemplateFinalizationProps {
  modules: any[]
  name: string | undefined
  onDeleteModule?: (module: any) => void
  isPreview?: boolean
}

export const GroupTemplateFinalization = ({
  modules = [],
  onDeleteModule: handleDeleteModule = noop,
  name = undefined,
  isPreview = false,
}: GroupTemplateFinalizationProps) => {
  const columnHeaderSize = isPreview ? 3 : 2
  return (
    <div
      className={classNames('group-template-finalization', {
        'group-template-finalization--isPreview': isPreview,
      })}
    >
      <GroupTemplateName modules={modules} name={name} isPreview={isPreview} />
      <Heading size={4} className="hc-mt-expanded hc-mb-normal hc-ml-none">
        Modules
      </Heading>
      <div className="group-template-finalization-table">
        <Table name="group-template-modules" cellPadding="normal">
          <Table.Head>
            <Table.Row>
              <Table.Header xs={4}>Module</Table.Header>
              <Table.Header xs={2}>Last updated</Table.Header>
              <Table.Header xs={columnHeaderSize}>Software</Table.Header>
              <Table.Header xs={columnHeaderSize}>Program</Table.Header>
              {!isPreview && <Table.Header xs={2}>Actions</Table.Header>}
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {modules &&
              modules.map((module) => {
                return (
                  <Table.Row align="center" key={module.rowId}>
                    <Table.Data xs={4}>{module.name}</Table.Data>
                    <Table.Data xs={2}>
                      {getGwtFormattedDate(module.updatedAt)}
                    </Table.Data>
                    <Table.Data xs={columnHeaderSize}>
                      {module.softwareType}
                    </Table.Data>
                    <Table.Data xs={columnHeaderSize}>
                      {getModuleProgramTypeText(module.programType)}
                    </Table.Data>
                    {!isPreview && (
                      <Table.Data xs={2}>
                        <Tooltip
                          className="hc-ml-min"
                          location="left"
                          content="Remove module from selection"
                        >
                          <Button
                            iconOnly
                            type="ghost"
                            aria-label="Remove Module"
                            onClick={() => handleDeleteModule(module)}
                          >
                            <EnterpriseIcon
                              icon={MinusCircleIcon}
                              className="hc-clr-contrast"
                            />
                          </Button>
                        </Tooltip>
                      </Table.Data>
                    )}
                  </Table.Row>
                )
              })}
          </Table.Body>
        </Table>
      </div>
    </div>
  )
}
