import {
  Card,
  Heading,
  Modal,
  Table,
  Divider,
} from '@enterprise-ui/canvas-ui-react'
import { graphql, usePaginationFragment } from 'react-relay'
import './FeedbackTable.scss'
import React, { FC, SyntheticEvent, useState } from 'react'
import { useEffect } from 'react'
import { usePaginateDataLength } from '../../../hooks/usePaginateDataLength'
import { useSubscriptionRefreshQuery } from '../../../hooks/useRefresh'
import { TableSortHeader } from '../../../GenericComponents/TableSortHeader'
import { UserAvatar } from '../../Users/UserAvatar'
import { FeedbackTablePaginationFragment_feedback$key } from './__generated__/FeedbackTablePaginationFragment_feedback.graphql'
import {
  FeedbackFilter,
  FeedbacksOrderBy,
  FeedbackTablePaginationQuery,
} from './__generated__/FeedbackTablePaginationQuery.graphql'
import { getFeedbackLevelText } from '../../../util/feedbackUtils'
import { Link } from 'react-router-dom'
import { getGwtFormattedDate } from '../../../util/dateUtils'
import { IncludeArchivedOption } from './__generated__/FeedbackTablePaginationQuery_Refetchable.graphql'
import { FeedbackOptions } from '../FeedbackOptions/FeedbackOptions'
import { feedbackCurrentAtom, feedbackLastSeenAtom } from '../../../state/atoms'
import { useAtom, useAtomValue } from 'jotai'

const PAGE_SIZE = 20

interface FeedbackTableProps {
  resolved?: boolean
  tab: 'ISSUES' | 'SUGGESTIONS' | 'RESOLVED' | 'ARCHIVED'
}

export const FeedbackTable: FC<FeedbackTableProps> = ({
  resolved = false,
  tab,
}) => {
  const [orderBy, setOrderBy] = useState<FeedbacksOrderBy>('CREATED_AT_DESC')
  const [visible, setVisible] = useState(false)
  const [text, setText] = useState('')
  const [user, setUser] = useState()
  const [, setSeenFeedback] = useAtom(feedbackLastSeenAtom)
  const currentFeedback = useAtomValue(feedbackCurrentAtom)

  const getFilter = (): FeedbackFilter => {
    if (tab === 'ISSUES') {
      return {
        resolved: { equalTo: false },
        feedbackLevel: { in: ['MINOR', 'MAJOR', 'CRITICAL'] },
      }
    } else if (tab === 'RESOLVED') {
      return {
        resolved: { equalTo: true },
      }
    } else if (tab === 'SUGGESTIONS') {
      return {
        feedbackLevel: { equalTo: 'SUGGESTION' },
        resolved: { equalTo: false },
      }
    } else {
      return {}
    }
  }

  const tableHeader = () => {
    if (tab === 'ISSUES') {
      return 'Issues'
    } else if (tab === 'RESOLVED') {
      return 'Resolved'
    } else if (tab === 'SUGGESTIONS') {
      return 'Suggestions'
    } else if (tab === 'ARCHIVED') {
      return 'Archived'
    } else {
      return 'Feedback'
    }
  }

  const { data, isLoadingNext, hasNext, loadNext } = useFeedbackTableQuery(
    getFilter(),
    [orderBy],
    tab === 'ARCHIVED' ? 'EXCLUSIVELY' : 'NO',
    tab,
  )

  useEffect(() => {
    setSeenFeedback(currentFeedback)
  }, [currentFeedback, setSeenFeedback])

  const scrollEvent = (e: SyntheticEvent) => {
    if (isLoadingNext || !hasNext) {
      return
    }
    const target = e.target as HTMLTextAreaElement
    const scrollLeft = target.scrollHeight - target.scrollTop
    if (scrollLeft / target.clientHeight < 2) {
      loadNext(PAGE_SIZE)
    }
  }

  const openTextModal = (text: string, user?: any) => {
    setVisible(true)
    setText(text)
    setUser(user)
  }

  return (
    <div className="hc-pa-normal feedback-table">
      {visible && (
        <Modal isVisible={visible} onRefuse={() => setVisible(false)}>
          <div className="hc-pa-expanded text-container">
            <div className="text-modal text-container-dotted hc-bg-white">
              <UserAvatar
                userRef={user}
                showName={true}
                aria-label={''}
                children={undefined}
              />
              <Divider className="hc-pt-dense hc-pb-dense" />
              {text}
            </div>
          </div>
        </Modal>
      )}
      <Card className="card">
        <Table name="Feedback" cellPadding="normal">
          <Table.Head>
            <Table.Row align="center">
              <Table.Header xs={2}>
                <Heading size={5}>{tableHeader()}</Heading>
              </Table.Header>
            </Table.Row>
            <Table.Row align="center">
              <TableSortHeader
                xs={2}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="GWT_USER_BY_GWT_USER_ID__FULL_NAME_ASC"
                sortDownFilter="GWT_USER_BY_CLAIMED_BY_ID__FULL_NAME_DESC"
              >
                Submitted by
              </TableSortHeader>
              <TableSortHeader
                xs={2}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="FEEDBACK_LEVEL_ASC"
                sortDownFilter="FEEDBACK_LEVEL_DESC"
              >
                Severity
              </TableSortHeader>
              <Table.Header xs={2}>Feedback</Table.Header>

              <TableSortHeader
                xs={2}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="CREATED_AT_ASC"
                sortDownFilter="CREATED_AT_DESC"
              >
                Created
              </TableSortHeader>

              <TableSortHeader
                xs={2}
                selectedOrderBy={orderBy}
                onSort={setOrderBy}
                sortUpFilter="GWT_USER_BY_CLAIMED_BY_ID__FULL_NAME_ASC"
                sortDownFilter="GWT_USER_BY_CLAIMED_BY_ID__FULL_NAME_DESC"
              >
                Claimed by
              </TableSortHeader>

              <Table.Header />
            </Table.Row>
          </Table.Head>
          <Table.Body onScroll={scrollEvent} className="overflow">
            {data?.feedbacks?.edges?.map((node) => {
              let data = node.node
              return (
                <Table.Row align="center" key={data.rowId}>
                  <Table.Data xs={2}>
                    <Link
                      className="link-hover"
                      to={`/users/${data.gwtUser?.rowId}`}
                    >
                      {data.gwtUser?.fullName}
                    </Link>
                  </Table.Data>
                  <Table.Data xs={2}>
                    {getFeedbackLevelText(data.feedbackLevel)}
                  </Table.Data>
                  <Table.Data xs={2}>
                    <span
                      onClick={() => openTextModal(data.feedback, data.gwtUser)}
                      className="link-hover ellipsis"
                    >
                      {data.feedback}
                    </span>
                  </Table.Data>
                  <Table.Data xs={2}>
                    {getGwtFormattedDate(data.createdAt)}
                  </Table.Data>
                  <Table.Data xs={2}>
                    <Link
                      className="link-hover"
                      to={`/users/${data.claimedBy?.rowId}`}
                    >
                      {data.claimedBy?.fullName}
                    </Link>
                  </Table.Data>
                  <Table.Data className="end hc-mr-md">
                    <FeedbackOptions feedbackRef={data} />
                  </Table.Data>
                </Table.Row>
              )
            })}
          </Table.Body>
        </Table>
      </Card>
    </div>
  )
}

const useFeedbackTableQuery = (
  feedbackFilter: FeedbackFilter | undefined = undefined,
  feedbacksOrderBy: [FeedbacksOrderBy] | undefined = undefined,
  includeArchived: IncludeArchivedOption | undefined = undefined,
  componentId: string,
) => {
  const [dataLength, setDataLength] = useState(PAGE_SIZE)

  const pageData = useSubscriptionRefreshQuery<FeedbackTablePaginationQuery>(
    graphql`
      query FeedbackTablePaginationQuery(
        $feedbackFilter: FeedbackFilter
        $feedbacksOrderBy: [FeedbacksOrderBy!]
        $includeArchived: IncludeArchivedOption
        $count: Int!
      ) {
        ...FeedbackTablePaginationFragment_feedback
          @arguments(
            feedbackFilter: $feedbackFilter
            feedbacksOrderBy: $feedbacksOrderBy
            includeArchived: $includeArchived
            count: $count
          )
      }
    `,
    {
      feedbackFilter,
      feedbacksOrderBy,
      includeArchived,
      count: dataLength,
    },
    {
      component: 'FEEDBACK_TABLE',
      uniqueComponentId: componentId,
      onNotification: [
        'CREATE_FEEDBACK_NOTIFICATION',
        'FEEDBACK_OPTIONS_NOTIFICATION',
      ],
      includeSelfNotifications: true,
    },
  )

  const paginationFragmentHook = usePaginationFragment<
    FeedbackTablePaginationQuery,
    FeedbackTablePaginationFragment_feedback$key
  >(
    graphql`
      fragment FeedbackTablePaginationFragment_feedback on Query
      @argumentDefinitions(
        feedbackFilter: { type: "FeedbackFilter" }
        feedbacksOrderBy: { type: "[FeedbacksOrderBy!]" }
        includeArchived: { type: "IncludeArchivedOption" }
        count: { type: "Int" }
        cursor: { type: "Cursor" }
      )
      @refetchable(queryName: "FeedbackTablePaginationQuery_Refetchable") {
        feedbacks(
          filter: $feedbackFilter
          orderBy: $feedbacksOrderBy
          includeArchived: $includeArchived
          first: $count
          after: $cursor
        ) @connection(key: "FeedbackTable_feedbacks") {
          edges {
            node {
              ...FeedbackOptions_feedback
              resolved
              gwtUser {
                fullName
                rowId
                ...UserAvatar_gwtUser
              }
              claimedBy {
                fullName
                rowId
              }
              createdAt
              feedback
              feedbackLevel
              rowId
            }
          }
          totalCount
        }
      }
    `,
    pageData,
  )

  const paginateLength = usePaginateDataLength(
    paginationFragmentHook?.data?.feedbacks?.edges,
    PAGE_SIZE,
  )

  useEffect(() => {
    if (paginateLength) {
      setDataLength(paginateLength)
    }
  }, [paginateLength])

  return { ...paginationFragmentHook, pageData }
}
