import { List } from '@enterprise-ui/canvas-ui-react'
import { useAtom, useAtomValue } from 'jotai'
import { useFragment, graphql } from 'react-relay'
import { useGetAssetById, useGwtUser } from '../../hooks'
import { dropFilesAtom, uploadsAtom } from '../../state/atoms'
import { SubmitButton } from './SubmitButton'
import { GalleryAssetFilesDisplay } from '../Gallery/GalleryAssetFilesDisplay'
import { SubmittedAssetFilesDisplay } from '../Gallery/SubmittedAssetFilesDisplay'
import { TaskCompleteCheckbox } from '../Tasks/TaskCompleteCheckbox'
import { DeliverableFileUploadTable } from './DeliverableFileUploadTable'
import { DeliverTab_moduleDeliverableAsset$key } from './__generated__/DeliverTab_moduleDeliverableAsset.graphql'
import './Module.scss'
import useUploadCheck from '../../hooks/useUploadCheck'

interface DeliverTabProps {
  moduleRef: DeliverTab_moduleDeliverableAsset$key | null | undefined
}

export const DeliverTab = ({ moduleRef = null }: DeliverTabProps) => {
  const user = useGwtUser()

  const [areDropFilesPresent] = useAtom(dropFilesAtom)
  const data = useFragment(
    graphql`
      fragment DeliverTab_moduleDeliverableAsset on Module {
        flow {
          assignmentStatus
          assignedTo {
            firstName
            lastName
          }
        }
        submissionLock
        ...DeliverableFileUploadTable_galleryAsset
        ...SubmitButton_Fragment
        ...SubmittedAssetFilesDisplay_Fragment
        galleryAssetDeliverableId
        tasksByModuleFlowId(orderBy: PRIORITY_ASC) {
          nodes {
            flowId
            complete
            ...TaskCompleteCheckbox_task
            flow {
              name
            }
          }
        }
        moduleSubmissionsByModuleFlowId(orderBy: ATTEMPT_DESC) {
          nodes {
            submissionStatus
            id
          }
        }
      }
    `,
    moduleRef,
  )

  const { isFetching } = useGetAssetById(
    data?.galleryAssetDeliverableId || '',
    {
      enabled: !!data?.galleryAssetDeliverableId,
    },
  )

  const { refreshUploadCheck } = useUploadCheck(data?.galleryAssetDeliverableId)

  const uploadingFiles = useAtomValue(uploadsAtom)

  const isUploading = uploadingFiles.length > 0
  return (
    <div className="hc-mb-xl">
      {data?.submissionLock === false && (
        <>
          <div className="small-width">
            <h2> Task List </h2>
            <div>
              {data?.tasksByModuleFlowId?.nodes && (
                <List className="hc-ma-none hc-pa-none">
                  {data.tasksByModuleFlowId.nodes.map((task) => (
                    <List.Item
                      className="hc-mt-sm hc-pa-none"
                      key={task.flowId}
                    >
                      <TaskCompleteCheckbox taskRef={task} />
                    </List.Item>
                  ))}
                </List>
              )}
              {data?.tasksByModuleFlowId?.nodes?.length === 0 && (
                <div className="error-color"> No tasks.</div>
              )}
            </div>
          </div>
          <div className="small-width">
            <h2 className="hc-mb-sm"> Deliverables </h2>
            <DeliverableFileUploadTable
              moduleRef={data}
              onUpload={refreshUploadCheck}
            />
          </div>
          <div className="small-width hc-mb-xl">
            <div className="upload-container hc-pa-sm">
              <GalleryAssetFilesDisplay
                assetId={data?.galleryAssetDeliverableId}
                enableDeletes={
                  (data?.flow?.assignmentStatus === 'READY_FOR_REVIEW' ||
                    data?.flow?.assignmentStatus === 'IN_PROGRESS') &&
                  !user.isReadOnly
                }
                displayCreatedBy
                createdBy={`${data?.flow?.assignedTo?.firstName} ${data?.flow?.assignedTo?.lastName}`}
                showUploadingFiles
              />
            </div>
          </div>
        </>
      )}
      {data?.moduleSubmissionsByModuleFlowId?.nodes &&
        data?.submissionLock === false && (
          <div className="small-width hc-pb-xl">
            <h2 className="hc-mb-sm"> Previous attempts </h2>
            <SubmittedAssetFilesDisplay
              moduleRef={data}
              firstExpanded={false}
            />
          </div>
        )}
      {data?.submissionLock === false && (
        <div className="submit-sticky hc-pt-normal hc-pb-normal">
          <div className="deliver-button-container">
            {isUploading && (
              <div className="hc-mt-xs hc-ml-sm hc-pr-dense sub-text">
                Files are being scanned. This process can take up to 8 minutes.
                Please wait and then verify they were uploaded successfully.
              </div>
            )}
            <SubmitButton
              moduleRef={data}
              disabled={
                !data?.galleryAssetDeliverableId ||
                data?.flow?.assignmentStatus !== 'IN_PROGRESS' ||
                (data?.tasksByModuleFlowId.nodes &&
                  data.tasksByModuleFlowId.nodes.find(
                    (task) => !task.complete,
                  ) !== undefined) ||
                areDropFilesPresent ||
                isFetching ||
                user.isReadOnly ||
                isUploading
              }
            />
          </div>
        </div>
      )}
      {data?.submissionLock && (
        <div className="small-width">
          <h2 style={{ textAlign: 'center' }}>
            Submissions are currently locked by the reviewer
          </h2>
        </div>
      )}
    </div>
  )
}
