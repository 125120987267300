import {
  Button,
  Card,
  GridContainer,
  GridItem,
} from '@enterprise-ui/canvas-ui-react'
import { useFragment } from 'react-relay'
import { graphql } from 'relay-runtime'
import './UserModal.scss'
import { getModuleProgramTypeText } from '../../util/moduleUtils'
import { useEnv } from '@praxis/component-runtime-env'
import { GwtEnv } from '../../types'
import {
  getCurrentDateAndYear,
  getGwtFormattedDate,
} from '../../util/dateUtils'
import { faAward } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UserCertifications$key } from './__generated__/UserCertifications.graphql'

interface UserCertificationsProps {
  userRef: UserCertifications$key | null | undefined
}

export const UserCertifications = (props: UserCertificationsProps) => {
  const env = useEnv<GwtEnv>()

  const data = useFragment(
    graphql`
      fragment UserCertifications on GwtUser {
        fullName
        certifications {
          nodes {
            assignedTo {
              fullName
            }
            name
            completedAt
            module {
              programType
              softwareType
            }
          }
        }
      }
    `,
    props.userRef || null,
  )

  if (data?.certifications.nodes.length === 0) {
    return (
      <div className="user-assignments">
        <div className="container bold-text hc-ta-center">
          No certifications found.
        </div>
      </div>
    )
  }

  return (
    <div className="user-assignments">
      <div className="container">
        {data?.certifications.nodes.map((flow: any) => {
          return (
            <Card className="card hc-mb-md" key={flow.rowId}>
              <GridContainer align="center">
                <GridItem xs={2}>
                  <FontAwesomeIcon
                    icon={faAward}
                    color="green"
                    size="xl"
                    className="hc-ml-lg"
                  />
                </GridItem>

                <GridItem xs={7}>
                  <div>
                    <span className="hc-mr-sm hc-mb-sm bold-text">
                      {flow.name}
                    </span>
                    <span>
                      {getModuleProgramTypeText(flow.module?.programType)}
                    </span>
                  </div>
                  <div className="certification-text">
                    {`Completed on ${getGwtFormattedDate(flow.completedAt)}`}
                  </div>
                </GridItem>

                <GridItem xs={3}>
                  <Button
                    type="secondary"
                    onClick={() => {
                      window.location.href = `${
                        env.baseGwtApiUrl
                      }/certifications?name=${flow.assignedTo
                        ?.fullName}&date=${getCurrentDateAndYear(
                        flow.completedAt,
                      )}
                      &software=${flow.module?.softwareType}`
                    }}
                    className="status-chip hc-mr-lg right"
                  >
                    Download certificate
                  </Button>
                </GridItem>
              </GridContainer>
            </Card>
          )
        })}
      </div>
    </div>
  )
}
