/**
 * @generated SignedSource<<78ed4a15b8d9fd50b72d71d8f27deea0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserPriority_gwtUser$data = {
  readonly userPriority: number;
  readonly " $fragmentType": "UserPriority_gwtUser";
};
export type UserPriority_gwtUser$key = {
  readonly " $data"?: UserPriority_gwtUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserPriority_gwtUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserPriority_gwtUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userPriority",
      "storageKey": null
    }
  ],
  "type": "GwtUser",
  "abstractKey": null
};

(node as any).hash = "2dd5c06e031e1fef01bd3aea855d98b2";

export default node;
