import './FeedbackOptions.scss'
import classNames from 'classnames'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip, Button } from '@enterprise-ui/canvas-ui-react'
import { useFragment } from 'react-relay'
import { graphql } from 'relay-runtime'
import { DropdownSelect } from '../../../GenericComponents/DropdownSelect/DropdownSelect'
import { useGwtUser, useGwtMutation } from '../../../hooks'
import { usePublishGlobalNotification } from '../../../hooks/useNotifications'
import { FeedbackOptionsMutation } from './__generated__/FeedbackOptionsMutation.graphql'
import { FeedbackOptions_feedback$key } from './__generated__/FeedbackOptions_feedback.graphql'

interface FeedbackOptionsProps {
  feedbackRef: FeedbackOptions_feedback$key | null
  className?: string
}

export const FeedbackOptions = ({
  feedbackRef = null,
  className,
}: FeedbackOptionsProps) => {
  const currentUser = useGwtUser()

  const data = useFragment(
    graphql`
      fragment FeedbackOptions_feedback on Feedback {
        rowId
        archivedAt
        claimedBy {
          fullName
          rowId
        }
        resolved
      }
    `,
    feedbackRef,
  )

  const { claim, resolve, archive } = useFeedbackMutations(data?.rowId)

  return (
    <div className={classNames('feedback-options', className)}>
      <div className="options">
        {currentUser.userType === 'ADMIN' && (
          <DropdownSelect
            shouldSelect={false}
            options={[
              {
                label: data?.claimedBy ? 'Unclaim' : 'Claim',
                disabled:
                  data?.claimedBy !== null &&
                  data?.claimedBy !== undefined &&
                  data?.claimedBy?.rowId !== currentUser.rowId,
                className: 'hc-pl-dense',
                onSelect: () => {
                  if (data?.claimedBy) {
                    claim(null)
                  } else {
                    claim(currentUser.rowId)
                  }
                },
              },
              {
                label: data?.resolved ? 'Unresolve' : 'Resolve',
                className: 'hc-pl-dense',
                onSelect: () => {
                  resolve(!data?.resolved)
                },
              },
              {
                label: data?.archivedAt ? 'Unarchive' : 'Archive',
                className: 'hc-pl-dense',
                onSelect: () => {
                  if (data?.archivedAt) {
                    archive(null)
                  } else {
                    archive(new Date())
                  }
                },
              },
            ]}
          >
            <Tooltip content="Claim/Resolve/Archive" location="left">
              <Button iconOnly>
                <FontAwesomeIcon icon={faEllipsisV} />
              </Button>
            </Tooltip>
          </DropdownSelect>
        )}
      </div>
    </div>
  )
}

const useFeedbackMutations = (feedbackId: string) => {
  const { publishToLocal } = usePublishGlobalNotification()

  const mutation = useGwtMutation<FeedbackOptionsMutation>(graphql`
    mutation FeedbackOptionsMutation($input: UpdateFeedbackInput!) {
      updateFeedback(input: $input) {
        feedback {
          archivedAt
          claimedBy {
            fullName
            rowId
          }
          resolved
        }
      }
    }
  `)

  const archive = (date: Date | null) => {
    mutation.commit(
      {
        input: {
          patch: {
            archivedAt: date,
          },
          rowId: feedbackId,
        },
      },
      () => {
        publishToLocal('FEEDBACK_OPTIONS_NOTIFICATION')
      },
    )
  }

  const claim = (userId: string | null) => {
    mutation.commit(
      {
        input: {
          patch: {
            claimedById: userId,
          },
          rowId: feedbackId,
        },
      },
      () => {
        publishToLocal('FEEDBACK_OPTIONS_NOTIFICATION')
      },
    )
  }

  const resolve = (resolved: boolean) => {
    mutation.commit(
      {
        input: {
          patch: {
            resolved,
          },
          rowId: feedbackId,
        },
      },
      () => {
        publishToLocal('FEEDBACK_OPTIONS_NOTIFICATION')
      },
    )
  }

  return { archive, claim, resolve }
}
