import { useDraggable } from '@dnd-kit/core'
import React from 'react'

interface DraggableProps {
  id: string
  key: React.Key
  children: React.ReactElement<any>
  data?: Record<string, any>
  disabled?: boolean
}

export const Draggable = ({
  children,
  id,
  key,
  data,
  disabled,
  ...otherProps
}: DraggableProps) => {
  const { attributes, listeners, setNodeRef } = useDraggable({
    id,
    data,
    disabled,
  })

  return React.cloneElement(children, {
    ...otherProps,
    ...listeners,
    ...attributes,
    key,
    ref: setNodeRef,
  })
}
