import { useCallback } from 'react'
import { graphql } from 'relay-runtime'
import { useGwtMutation } from '.'
import { UUID } from '../react-app-env'
import { useUpdateAssetTimestampMutation } from './__generated__/useUpdateAssetTimestampMutation.graphql'

export const useUpdateAssetTimestamp = () => {
  const { commit } = useGwtMutation<useUpdateAssetTimestampMutation>(graphql`
    mutation useUpdateAssetTimestampMutation($assetId: UUID!) {
      assetImageModified(input: { assetId: $assetId }) {
        clientMutationId
      }
    }
  `)

  const publishAssetUpdate = useCallback(
    (assetId: UUID) => {
      // commit the mutation, fire and forget this one
      commit({ assetId })
    },
    [commit],
  )

  return { publishAssetUpdate }
}
