import React, { useEffect, useState } from 'react'
import {
  Button,
  Form,
  Grid,
  Modal,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { graphql, useFragment } from 'react-relay'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useGwtMutation } from '../../hooks'
import {
  AssignTemplateCardPopupMutation,
  AssignTemplateCardPopupMutation$variables,
} from './__generated__/AssignTemplateCardPopupMutation.graphql'
import { useAtom } from 'jotai'
import { getNotificationInputObject } from '../../util/relayUtils'
import { globalNotificationAtom } from '../../state/atoms'
import { AssignTemplateCardPopupModuleFragment$key } from './__generated__/AssignTemplateCardPopupModuleFragment.graphql'
import dayjs from 'dayjs'
import { ControlledUserSelect } from '../../controlledComponents/ControlledUserSelect'
import {
  getControlledUserSelectDefaultUnnassigned,
  getControlledUserSelectObjectOrDefault,
} from '../../util/userUtils'
import { getFlowDuration } from '../../util/flowUtils'
import './AssignTemplateCard.scss'

interface AssignTemplateCardPopupProps {
  moduleRef?: AssignTemplateCardPopupModuleFragment$key | null | undefined
  isModalOpen: boolean
  closeModal: () => void
}

export const AssignTemplateCardPopup = ({
  moduleRef,
  isModalOpen,
  closeModal,
}: AssignTemplateCardPopupProps) => {
  type Inputs = {
    add: string
  }

  const moduleData = useFragment(
    graphql`
      fragment AssignTemplateCardPopupModuleFragment on Module {
        flow {
          name
          rowId
          dueAt
          createdAt
          durationSeconds
          flowReviewers(first: 1) {
            nodes {
              reviewer {
                firstName
                lastName
                company {
                  name
                }
                rowId
              }
            }
          }
        }
        attempts
      }
    `,
    moduleRef || null,
  )

  const { commit, isInFlight } =
    useGwtMutation<AssignTemplateCardPopupMutation>(graphql`
      mutation AssignTemplateCardPopupMutation(
        $moduleFlowId: UUID!
        $assignTo: UUID!
        $reviewBy: UUID
        $dueAtDate: Datetime!
        $numberOfAttempts: Int!
        $notificationInput: NotificationInput!
      ) {
        createNotification(input: { notification: $notificationInput }) {
          clientMutationId
        }
        assignModule(
          input: {
            moduleFlowId: $moduleFlowId
            assignedTo: $assignTo
            reviewedBy: $reviewBy
            dueAtDate: $dueAtDate
            numberOfAttempts: $numberOfAttempts
          }
        ) {
          clientMutationId
        }
      }
    `)

  const formMethods = useForm()

  const {
    register,
    handleSubmit,
    watch,
    reset,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = formMethods

  const [, publishNotification] = useAtom(globalNotificationAtom)
  const makeToast = ToastProvider.useToaster()

  const attempts: string | undefined = watch('attempts', '')
  const moduleDuration: number = watch('module_duration', 0)
  const assignTo = watch('assignTo', '')
  const reviewBy = watch('reviewBy', '')

  const dueAtDate: Date = dayjs
    .utc(Date.now())
    .add(moduleDuration, 'week')
    .endOf('days')
    .toDate()

  const validate = (): boolean => {
    let validated = true
    if (!assignTo) {
      setError('assignTo', { message: 'Please select an assignee' })
      validated = false
    }

    if (!reviewBy) {
      setError('reviewBy', { message: 'Please select a reviewer' })
      validated = false
    }

    return validated
  }

  const onSubmit: SubmitHandler<Inputs> = () => {
    if (!validate()) {
      return
    }
    // validate and set errors

    const mutation: AssignTemplateCardPopupMutation$variables =
      {} as AssignTemplateCardPopupMutation$variables
    mutation.moduleFlowId = moduleData?.flow?.rowId
    mutation.assignTo = assignTo
    if (reviewBy === 'unassigned') {
      mutation.reviewBy = null
    } else {
      mutation.reviewBy = reviewBy
    }
    mutation.dueAtDate = dueAtDate
    mutation.numberOfAttempts = Number(attempts)

    mutation.notificationInput = getNotificationInputObject(
      'ASSIGN_TEMPLATE_NOTIFICATION',
    )

    commit(
      mutation,
      () => {
        publishNotification({
          name: 'ASSIGN_TEMPLATE_NOTIFICATION',
          id: 'local',
          gwt_user_id: 'local',
          data: null,
        })
        closeModal()
        reset()
        makeToast({
          type: 'success',
          heading: 'Template assigned',
          message: 'Template was successfully assigned.',
        })
      },
      () => {
        makeToast({
          type: 'error',
          heading: 'Template assignment failed',
          message: 'Template was unsuccessfully assigned.',
        })
      },
    )
  }

  const clearModal = () => {
    closeModal()
    reset()
  }

  const [initialModuleDuration, setInitialModuleDuration] = useState(0)
  // load in the module data
  useEffect(() => {
    if (!moduleData?.flow) return

    if (isModalOpen) {
      // get the current duration
      let moduleDuration = getFlowDuration(
        moduleData.flow.durationSeconds,
        moduleData.flow.createdAt,
        moduleData.flow.dueAt,
      )
      setInitialModuleDuration(moduleDuration)
      setValue('module_duration', moduleDuration)

      setValue('attempts', moduleData?.attempts)
      setValue(
        'reviewBy',
        moduleData?.flow?.flowReviewers?.nodes?.[0]?.reviewer?.rowId ||
          'unassigned',
      )
    }
  }, [moduleData?.attempts, moduleData?.flow, setValue, isModalOpen])

  const parseAttempts = (val: string) => {
    let ret = -1
    try {
      ret = parseInt(val)
    } catch (err) {
      console.error(err)
    }
    return ret
  }
  return (
    <>
      {isModalOpen && (
        <Modal
          headingText={`Assign ${moduleData?.flow?.name}`}
          isVisible={isModalOpen}
          onRefuse={clearModal}
          className="align-left assign-modal"
        >
          <FormProvider {...formMethods}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <Grid.Container className="hc-pl-lg hc-pr-lg hc-pb-md hc-pt-dense">
                  <Grid.Item xs={12}>
                    <ControlledUserSelect
                      label="Assign to"
                      required
                      id="assign_to_select"
                      defaultValue={getControlledUserSelectDefaultUnnassigned()}
                      {...register('assignTo')}
                    />
                  </Grid.Item>
                  <Grid.Item xs={12} className="hc-pb-md">
                    <ControlledUserSelect
                      label="Reviewed by"
                      id="review_by_select"
                      defaultValue={getControlledUserSelectObjectOrDefault(
                        moduleData?.flow?.flowReviewers.nodes[0]?.reviewer
                          ?.rowId,
                        moduleData?.flow?.flowReviewers.nodes[0]?.reviewer
                          ?.firstName,
                        moduleData?.flow?.flowReviewers.nodes[0]?.reviewer
                          ?.lastName,
                        moduleData?.flow?.flowReviewers.nodes[0]?.reviewer
                          ?.company?.name,
                      )}
                      defaultUnassigned={
                        getControlledUserSelectDefaultUnnassigned
                      }
                      {...register('reviewBy')}
                      userTypes={{ in: ['ADMIN', 'REVIEWER'] }}
                    />
                  </Grid.Item>
                  <Grid.Item xs={7} className="hc-pr-md">
                    <Form.Field
                      error={!!errors.module_duration}
                      errorText={errors.module_duration?.message}
                      disableFieldInfo={!errors.module_duration}
                      label="Duration"
                      id="module_duration"
                      type="select"
                      rightContent={
                        moduleDuration !== initialModuleDuration && (
                          <span
                            className="text-button"
                            onClick={() => {
                              setValue('module_duration', initialModuleDuration)
                            }}
                          >
                            {'Reset'}
                          </span>
                        )
                      }
                      onUpdate={(event: any, value: any) => {
                        if (errors.module_duration) {
                          clearErrors('module_duration')
                        }
                        setValue('module_duration', value)
                      }}
                      value={moduleDuration}
                      options={[
                        {
                          value: 0,
                          label: '',
                          disabled: true,
                        },
                        {
                          value: 1,
                          label: '1 week (7 calendar days)',
                          disabled: false,
                        },
                        {
                          value: 2,
                          label: '2 weeks (14 calendar days)',
                          disabled: false,
                        },
                        {
                          value: 3,
                          label: '3 weeks (21 calendar days)',
                          disabled: false,
                        },
                        {
                          value: 4,
                          label: '4 weeks (28 calendar days)',
                          disabled: false,
                        },
                        {
                          value: 5,
                          label: '5 weeks (35 calendar days)',
                          disabled: false,
                        },
                        {
                          value: 6,
                          label: '6 weeks (42 calendar days)',
                          disabled: false,
                        },
                      ]}
                      {...register('module_duration')}
                    />
                  </Grid.Item>
                  <Grid.Item xs={5}>
                    <Form.Field
                      error={!!errors.attempts}
                      errorText={errors.attempts?.message}
                      disableFieldInfo={!errors.attempts}
                      type="number"
                      min="0"
                      value={attempts}
                      id="attempt_number"
                      label="Number of attempts"
                      rightContent={
                        parseAttempts(attempts || '-1') !==
                          moduleData?.attempts && (
                          <span
                            className="text-button"
                            onClick={() => {
                              setValue('attempts', moduleData?.attempts)
                            }}
                          >
                            {'Reset'}
                          </span>
                        )
                      }
                      onUpdate={(event: any, value: any) => {
                        if (errors.attempts) {
                          clearErrors('attempts')
                        }
                        setValue('attempts', value)
                      }}
                      {...register('attempts')}
                    />
                  </Grid.Item>
                </Grid.Container>
              </div>
              <div className="hc-pt-none hc-mr-lg hc-pb-lg">
                <Grid.Container spacing="dense" direction="row-reverse">
                  <Grid.Item>
                    <Button
                      disabled={isInFlight}
                      type="submit"
                      id="assign-confirm"
                    >
                      Confirm
                    </Button>
                  </Grid.Item>
                  <Grid.Item>
                    <Button type="secondary" onClick={clearModal}>
                      Cancel
                    </Button>
                  </Grid.Item>
                </Grid.Container>
              </div>
            </Form>
          </FormProvider>
        </Modal>
      )}
    </>
  )
}
