// Do not edit.
import { ShaderStore } from "core/Engines/shaderStore";
import "core/Shaders/ShadersInclude/helperFunctions";
import "core/Shaders/ShadersInclude/lightFragmentDeclaration";
import "core/Shaders/ShadersInclude/lightUboDeclaration";
import "core/Shaders/ShadersInclude/lightsFragmentFunctions";
import "core/Shaders/ShadersInclude/shadowsFragmentFunctions";
import "core/Shaders/ShadersInclude/clipPlaneFragmentDeclaration";
import "core/Shaders/ShadersInclude/fogFragmentDeclaration";
import "core/Shaders/ShadersInclude/clipPlaneFragment";
import "core/Shaders/ShadersInclude/lightFragment";
import "core/Shaders/ShadersInclude/fogFragment";
import "core/Shaders/ShadersInclude/imageProcessingCompatibility";

const name = "shadowOnlyPixelShader";
const shader = `precision highp float;uniform vec4 vEyePosition;uniform float alpha;uniform vec3 shadowColor;varying vec3 vPositionW;#ifdef NORMAL
varying vec3 vNormalW;#endif
#include<helperFunctions>
#include<__decl__lightFragment>[0..maxSimultaneousLights]
#include<lightsFragmentFunctions>
#include<shadowsFragmentFunctions>
#include<clipPlaneFragmentDeclaration>
#include<fogFragmentDeclaration>
#define CUSTOM_FRAGMENT_DEFINITIONS
void main(void) {#define CUSTOM_FRAGMENT_MAIN_BEGIN
#include<clipPlaneFragment>
vec3 viewDirectionW=normalize(vEyePosition.xyz-vPositionW);#ifdef NORMAL
vec3 normalW=normalize(vNormalW);#else
vec3 normalW=vec3(1.0,1.0,1.0);#endif
vec3 diffuseBase=vec3(0.,0.,0.);lightingInfo info;float shadow=1.;float glossiness=0.;#include<lightFragment>[0..1]
vec4 color=vec4(shadowColor,(1.0-clamp(shadow,0.,1.))*alpha);#include<fogFragment>
gl_FragColor=color;#include<imageProcessingCompatibility>
#define CUSTOM_FRAGMENT_MAIN_END
}`;
// Sideeffect
ShaderStore.ShadersStore[name] = shader;
/** @hidden */
export const shadowOnlyPixelShader = { name, shader };
