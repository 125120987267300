import { useAtom } from 'jotai'
import { useCallback } from 'react'
import { useGwtUser } from '.'
import { NotificationUpdate } from '../globalComponents/NotificationSubscription'
import { UUID } from '../react-app-env'
import { getOrCreateGlobalRefetchAtom } from '../state/atoms'

export type SubscriptionVariables = {
  component: COMPONENT
  uniqueComponentId: UUID
  onNotification: OnNotificationType
  includeSelfNotifications?: boolean
}

export type OnNotificationType =
  | ((notification: NotificationUpdate) => boolean)
  | Array<NOTIFICATION>

/**
 *
 * @param component Component that is making this subscription
 * @param onNotification callback for subscribing to a notification if you want fine grained control (for example validating a variable in the payload) otherwise you can just use an array of notifications for basic subscription
 * @param includeSelfNotifications notify the user who made the query
 * @param uniqueComponentString String appended to the component name to create a unique key.  You will most likely want to set this to a unique id that you can access on page load (like the flow id for many components).
 */
export const useSubscribeToNotification = ({
  component,
  uniqueComponentId,
  onNotification,
  includeSelfNotifications = false,
}: SubscriptionVariables) => {
  const user = useGwtUser()

  const shouldUpdate = useCallback(
    (notification: NotificationUpdate): boolean => {
      if (
        !includeSelfNotifications &&
        notification.gwt_user_id === user.rowId
      ) {
        return false
      }

      if (onNotification instanceof Function) {
        return onNotification(notification)
      } else {
        return onNotification.includes(notification.name)
      }
    },
    [includeSelfNotifications, onNotification, user.rowId],
  )

  const atom = getOrCreateGlobalRefetchAtom(
    component,
    uniqueComponentId,
    shouldUpdate,
  )

  const [subscription, setSubscription] = useAtom(atom)

  const onRefresh = useCallback(() => {
    setSubscription({ ...subscription, lastUpdated: subscription.current })
  }, [setSubscription, subscription])

  return { subscription, onRefresh }
}

export const NOTIFICATIONS = [
  'TEMPLATE_CREATE_NOTIFICATION',
  'TEMPLATE_EDIT_NOTIFICATION',
  'MODULE_EDIT_NOTIFICATION',
  'ASSIGN_TEMPLATE_NOTIFICATION',
  'COPY_TEMPLATE_NOTIFICATION',
  'ARCHIVE_ASSIGNMENT_NOTIFICATION',
  'UNARCHIVE_ASSIGNMENT_NOTIFICATION',
  'ARCHIVE_TEMPLATE_NOTIFICATION',
  'ARCHIVE_PROFILE_NOTIFICATION',
  'FAVORITE_TEMPLATE_NOTIFICATION',
  'ADMIN_DASHBOARD_FLOW_COMMENT_NOTIFICATION',
  'ADMIN_DASHBOARD_FLOW_COMMENT_DELETE_NOTIFICATION',
  'ADMIN_DASHBOARD_UNDO_FLOW_COMMENT_DELETE_NOTIFICATION',
  'ASSIGNEE_MODULE_COMMENT_ADD_NOTIFICATION',
  'ASSIGNEE_MODULE_COMMENT_REPLY_NOTIFICATION',
  'ASSIGNEE_MODULE_COMMENT_EDIT_NOTIFICATION',
  'ASSIGNEE_MODULE_COMMENT_DELETE_NOTIFICATION',
  'ASSIGNEE_MODULE_SUBMIT_NOTIFICATION',
  'REVIEWER_MODULE_BUTTON_NOTIFICATION',
  'REVIEWER_MODULE_START_NEXT_ATTEMPT_NOTIFICATION',
  'REVIEWER_DASHBOARD_FLOW_COMMENT_NOTIFICATION',
  'REVIEWER_DASHBOARD_FLOW_COMMENT_DELETE_NOTIFICATION',
  'REVIEWER_DASHBOARD_UNDO_FLOW_COMMENT_DELETE_NOTIFICATION',
  'GALLERY_ASSET_INVALIDATED_NOTIFICATION',
  'LAST_SEEN_COMMENT_UPDATED_NOTIFICATION',
  'COMPANY_CREATE_NOTIFICATION',
  'COMPANY_EDIT_NOTIFICATION',
  'USER_PROFILE_CREATE_NOTIFICATION',
  'USER_PROFILE_EDIT_NOTIFICATION',
  'CREATE_FEEDBACK_NOTIFICATION',
  'FEEDBACK_OPTIONS_NOTIFICATION',
] as const

export type NOTIFICATION = (typeof NOTIFICATIONS)[number]

export const COMPONENTS = [
  'ADMIN_TEMPLATE_CARD',
  'ADMIN_STATUS_CARD',
  'ADMIN_DISCUSSION_CARD',
  'ADMIN_RECENT_ACTIVITY_CARD',
  'REVIEWER_STATUS_CARD',
  'REVIEWER_TEMPLATE_CARD',
  'REVIEWER_DISCUSSION_CARD',
  'REVIEWER_OVERVIEW_TAB',
  'ASSIGNEE_COMMENTS_TAB',
  'ASSIGNEE_MODULE_OVERVIEW',
  'ASSIGNEE_DISCUSSION_CARD',
  'ASSIGNEE_TODO_CARD',
  'ASSIGNEE_HISTORY_CARD',
  'ASSIGNEE_OVERVIEW_TAB',
  'GLOBAL_COMPONENT',
  'INSIGHTS_DASHBOARD',
  'ASSIGNMENTS_TABLE',
  'TEMPLATES_TABLE',
  'GROUP_TEMPLATES_TABLE',
  'GROUP_TEMPLATES_MODULES_TABLE',
  'PROFILES_TABLE',
  'COMPANIES_PROFILES_TABLE',
  'COMPANY_USER_TABLE',
  'FEEDBACK_TABLE',
  'USER_SELECT',
] as const

export type COMPONENT = (typeof COMPONENTS)[number]
