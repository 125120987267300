import { NOTIFICATION } from '../hooks'
import { UUID } from '../react-app-env'
import { NotificationInput } from '../views/Comments/__generated__/CommentAddMutation.graphql'

export const getCommentCacheKeyFromUUID = (uuid: UUID): string => {
  return Buffer.from(JSON.stringify(['comments', uuid])).toString('base64')
}

export const getConnectionId = (
  relayId: string,
  fieldNameWithFilters: string,
): string => {
  return `client:${relayId}:${fieldNameWithFilters}`
}

export const getNotificationInputObject = (
  name: NOTIFICATION,
  data?: Record<string, unknown>,
): any => {
  const notificationInput = {} as NotificationInput
  notificationInput.name = name
  notificationInput.data = data ? JSON.stringify(data) : null

  return notificationInput
}

export const getActivityInputObject = (
  verb: string,
  object: string,
  flowId?: string,
  linkLocation?: string,
): any => ({
  linkLocation,
  verb,
  object,
  flowId,
})

// EXAMPLE OF UPDATING A COMMENT CUSTOM IN THE CACHE... WE PROBABLY WONT HAVE TO EVER DO THIS
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~~~~~~~~~~~~ ~~~~~~~~~~~~~~~~~~~~~~~~~~~
// updater: (store) => {
//   let comment = store
//     .getRootField('createFlowComment')
//     .getLinkedRecord('flowComment')
//     .getLinkedRecord('comment')

//   let connectionId = getConnectionId(
//     getCommentCacheKeyFromUUID(vars.responseTo),
//     `commentsByResponseTo(orderBy:"CREATED_AT_ASC")`
//   )

//   let connection = store.get(connectionId)
//   let nodes = connection?.getLinkedRecords('nodes') as RecordProxy[]
//   connection?.setLinkedRecords([...nodes, comment], 'nodes')
// },
