import { graphql, useFragment } from 'react-relay'
import { Link, LinkProps } from 'react-router-dom'
import { useGwtUser } from '../../hooks'
import { FlowLink_flow$key } from './__generated__/FlowLink_flow.graphql'

interface FlowLinkProps extends Omit<LinkProps, 'to'> {
  flowRef: FlowLink_flow$key | null
  isComment?: boolean
}

export const FlowLink = ({
  isComment = false,
  flowRef,
  ...props
}: FlowLinkProps) => {
  const data = useFragment(
    graphql`
      fragment FlowLink_flow on Flow {
        rowId
        linkedType
      }
    `,
    flowRef,
  )

  const user = useGwtUser()

  // no data or unknown and we don't know where to go
  if (!data || data.linkedType === 'UNKNOWN') {
    return <></>
  }

  if (data.linkedType === 'MODULE') {
    if (isComment) {
      if (user.userType === 'EXTERNAL_USER') {
        return (
          <Link {...props} to={`/assignee/modules/${data.rowId}#comments`} />
        )
      } else {
        return (
          <Link {...props} to={`/reviewer/modules/${data.rowId}#comments`} />
        )
      }
    }
    if (user.userType === 'EXTERNAL_USER') {
      return <Link {...props} to={`/assignee/modules/${data.rowId}`} />
    } else {
      return <Link {...props} to={`/reviewer/modules/${data.rowId}`} />
    }
  }

  if (data.linkedType === 'TEMPLATE') {
    return <Link {...props} to={`/templates/${data.rowId}`} />
  }

  return <></>
}
