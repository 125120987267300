import { Grid, Card, Divider } from '@enterprise-ui/canvas-ui-react'
import React, { SyntheticEvent, useEffect, useState } from 'react'
import '../Admin/Admin.scss'
import { graphql, usePaginationFragment } from 'react-relay'
import {
  FlowFilter,
  ReviewerTemplateCardPaginationQuery,
} from './__generated__/ReviewerTemplateCardPaginationQuery.graphql'
import { ReviewerTemplateCardPaginationFragment_templateFlows$key } from './__generated__/ReviewerTemplateCardPaginationFragment_templateFlows.graphql'
import { useSubscriptionRefreshQuery } from '../../hooks/useRefresh'
import { usePaginateDataLength } from '../../hooks/usePaginateDataLength'
import { useGwtUser } from '../../hooks'
import { Link } from 'react-router-dom'
import classNames from 'classnames'
import { TemplateFavorite } from '../Dashboards/Shared/TemplateFavorite'

interface ReviewerTemplateCardProps {
  className?: string | undefined
  small?: boolean | undefined
}

export const ReviewerTemplateCard = (props: ReviewerTemplateCardProps) => {
  const user = useGwtUser()
  const [filter] = useState<FlowFilter>({
    flowFavorites: {
      some: {
        gwtUserId: {
          equalTo: user.rowId,
        },
      },
    },
    flowType: { equalTo: 'BLUEPRINT' },
  })

  const { data, isLoadingNext, hasNext, loadNext } =
    useReviewerTemplateCardQuery(filter)

  const scrollEvent = (e: SyntheticEvent) => {
    if (isLoadingNext || !hasNext) {
      return
    }
    const target = e.target as HTMLTextAreaElement
    const scrollLeft = target.scrollHeight - target.scrollTop
    if (scrollLeft / target.clientHeight < 2) {
      loadNext(30)
    }
  }

  return (
    <Card
      className={classNames('admin', 'templated-card', {
        cardLayout: !props.small,
        cardLayoutSm: props.small,
      })}
    >
      <div className="cardHeader flex hc-pa-lg">
        <Grid.Container
          align="center"
          spacing="none"
          className="header-container hc-pr-none"
        >
          <Grid.Item className="hc-pr-none">
            <div className="card-header-text">Favorited templates</div>
          </Grid.Item>
          <Grid.Item className="hc-pr-none align-right flex-grow">
            <Link
              to="/templates-table"
              className="hc-fs-sm text-link hc-ta-right"
            >
              View all templates
            </Link>
          </Grid.Item>
        </Grid.Container>
      </div>
      <Divider />
      <div onScroll={scrollEvent} className="template-card-scroll">
        {data.flows?.edges.length === 0 && (
          <div className="no-templates">
            Go to “View all templates” to add templates to list.
          </div>
        )}
        {data &&
          data.flows?.edges.map((page) => {
            return (
              <div key={page.node.rowId}>
                <div key={page.node.rowId}>
                  <Grid.Container
                    align="center"
                    spacing="none"
                    className="hc-pa-md"
                    justify="space-around"
                  >
                    <TemplateFavorite flowRef={page.node} />
                  </Grid.Container>
                </div>
                <Divider />
              </div>
            )
          })}
      </div>
    </Card>
  )
}

const useReviewerTemplateCardQuery = (
  flowFilter: FlowFilter | undefined = undefined,
) => {
  const [dataLength, setDataLength] = useState(20)
  const user = useGwtUser()

  const pageData =
    useSubscriptionRefreshQuery<ReviewerTemplateCardPaginationQuery>(
      graphql`
        query ReviewerTemplateCardPaginationQuery(
          $count: Int!
          $flowFilter: FlowFilter
          $gwtUserId: UUID!
        ) {
          ...ReviewerTemplateCardPaginationFragment_templateFlows
            @arguments(
              count: $count
              flowFilter: $flowFilter
              gwtUserId: $gwtUserId
            )
        }
      `,
      { count: dataLength || 20, flowFilter, gwtUserId: user.rowId },
      {
        component: 'ADMIN_TEMPLATE_CARD',
        uniqueComponentId: '',
        onNotification: [
          'TEMPLATE_CREATE_NOTIFICATION',
          'COPY_TEMPLATE_NOTIFICATION',
          'ARCHIVE_TEMPLATE_NOTIFICATION',
          'FAVORITE_TEMPLATE_NOTIFICATION',
        ],
      },
    )
  const paginationFragmentHook = usePaginationFragment<
    ReviewerTemplateCardPaginationQuery,
    ReviewerTemplateCardPaginationFragment_templateFlows$key
  >(
    graphql`
      fragment ReviewerTemplateCardPaginationFragment_templateFlows on Query
      @argumentDefinitions(
        count: { type: "Int" }
        flowFilter: { type: "FlowFilter" }
        gwtUserId: { type: "UUID" }
        cursor: { type: "Cursor" }
      )
      @refetchable(
        queryName: "ReviewerTemplateCardTemplateFlowsPaginationQuery"
      ) {
        flows(
          first: $count
          after: $cursor
          filter: $flowFilter
          orderBy: CREATED_AT_DESC
        ) @connection(key: "ReviewerTemplateCard_flows") {
          edges {
            node {
              rowId
              ...TemplateFavorite_flow @arguments(gwtUserId: $gwtUserId)
            }
          }
          totalCount
        }
      }
    `,
    pageData,
  )

  const paginateLength = usePaginateDataLength(
    paginationFragmentHook.data.flows?.edges,
    20,
  )

  useEffect(() => {
    setDataLength(paginateLength)
  }, [paginateLength])

  return { ...paginationFragmentHook, pageData }
}
