import {
  Button,
  ButtonProps,
  Tooltip,
  TooltipProps,
} from '@enterprise-ui/canvas-ui-react'
import { ReactNode } from 'react'
import { capitalize } from 'lodash'

interface PropTypes {
  content: string
  location?: TooltipProps['location']
  tooltip?: Omit<TooltipProps, 'content' | 'location'>
  button?: Omit<ButtonProps, 'onClick'>
  onClick?: ButtonProps['onClick']
  children: ReactNode
  classname?: string
}

export const TooltipButton = ({
  content,
  location = 'top',
  tooltip,
  button = {},
  onClick,
  children,
  classname,
}: PropTypes) => (
  <Tooltip
    location={location}
    content={capitalize(content)}
    {...tooltip}
    className={classname}
  >
    <Button
      size="dense"
      iconOnly
      onClick={onClick}
      aria-label={`click to ${content.toLowerCase()}`}
      {...button}
    >
      {children}
    </Button>
  </Tooltip>
)
