import { Divider, Layout, Tabs } from '@enterprise-ui/canvas-ui-react'
import { useAtom } from 'jotai'
import { useEffect } from 'react'
import { graphql } from 'react-relay'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { useSubscriptionRefreshQuery } from '../../hooks/useRefresh'
import { SuspenseRingLoader } from '../../loadingComponents/SuspenseRingLoader'
import { UUID } from '../../react-app-env'
import { titleAtom } from '../../state/atoms'
import { useGwtUser } from '../../hooks'
import { CommentsTab } from './CommentsTab'
import { DeliverTab } from './DeliverTab'
import './Module.scss'
import { OverviewTab } from './OverviewTab'
import { ModuleOverviewQuery } from './__generated__/ModuleOverviewQuery.graphql'
import { Link } from 'react-router-dom'

export const ModuleOverview = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { moduleId } = useParams()
  const user = useGwtUser()

  const [, setTitle] = useAtom(titleAtom)

  useEffect(() => {
    setTitle('Assignee')
  })

  useEffect(() => {
    if (!location.hash) {
      navigate(`${location.pathname}#overview`, { replace: true })
    }
  }, [location, navigate])

  const tab = location.hash.replace('#', '')

  const data = useModuleOverviewQuery(moduleId)

  let reviewerOverview = `/reviewer/modules/${moduleId}#overview`

  return (
    <Layout.Body className="hc-pa-none" includeRail>
      <div className="module">
        <div className="small-width">
          {user.userType !== 'EXTERNAL_USER' ? (
            <div>
              <Link
                to={reviewerOverview}
                style={{
                  color: '#4974D4',
                  fontSize: '14px',
                  fontWeight: 'bold',
                  paddingRight: '30%',
                }}
                data-testid="Reviewer-View-Link"
              >
                Reviewer View
              </Link>
            </div>
          ) : (
            ''
          )}
          <div className="module-title-text">
            {data.module?.flow && data.module.flow.name}
          </div>
          <div>
            <Tabs
              activeTab={tab}
              defaultTab="Overview"
              className="hc-mt-md"
              justified
              onTabSelect={(event: any, tab: any) => {
                navigate(`${location.pathname}#${tab.name}`)
              }}
            >
              <Tabs.Item data-testid="overview-tab" name="overview">
                Overview
              </Tabs.Item>
              <Tabs.Item data-testid="comments-tab" name="comments">
                Comments
              </Tabs.Item>
              <Tabs.Item data-testid="submit-tab" name="deliver">
                Submit
              </Tabs.Item>
            </Tabs>
          </div>
        </div>
        <Divider className="divider hc-mb-xl" />
        {tab === 'overview' && (
          <SuspenseRingLoader>
            <OverviewTab moduleRef={data.module} />
          </SuspenseRingLoader>
        )}
        {tab === 'comments' && (
          <SuspenseRingLoader>
            <CommentsTab moduleRef={data.module} id={moduleId} />
          </SuspenseRingLoader>
        )}
        {tab === 'deliver' && (
          <SuspenseRingLoader>
            <DeliverTab moduleRef={data.module} />
          </SuspenseRingLoader>
        )}
      </div>
    </Layout.Body>
  )
}

const useModuleOverviewQuery = (moduleFlowId: UUID | undefined) => {
  const data = useSubscriptionRefreshQuery<ModuleOverviewQuery>(
    graphql`
      query ModuleOverviewQuery($flowId: UUID!, $skip: Boolean!) {
        module(flowId: $flowId) @skip(if: $skip) {
          flow {
            name
          }
          ...CommentsTab_moduleComments
          ...OverviewTab_module
          ...DeliverTab_moduleDeliverableAsset
        }
      }
    `,
    { flowId: moduleFlowId || '', skip: !moduleFlowId },
    {
      component: 'ASSIGNEE_OVERVIEW_TAB',
      uniqueComponentId: `${moduleFlowId}`,
      onNotification: (noti) => {
        if (
          noti.name === 'ASSIGNEE_MODULE_COMMENT_ADD_NOTIFICATION' ||
          noti.name === 'ASSIGNEE_MODULE_COMMENT_REPLY_NOTIFICATION'
        ) {
          return noti.data?.flowId === moduleFlowId
        }
        if (
          noti.name === 'ASSIGNEE_MODULE_COMMENT_EDIT_NOTIFICATION' ||
          noti.name === 'ASSIGNEE_MODULE_COMMENT_DELETE_NOTIFICATION'
        ) {
          return true
        }
        return false
      },
    },
  )

  return data
}
