import { Button, Card, Grid, Layout } from '@enterprise-ui/canvas-ui-react'

interface Error {
  name: string
  message: string
  stack?: string
}
interface ErrorInfo {
  componentStack: string
}

interface ErrorProps {
  error?: Error
  info?: ErrorInfo
}
export const ErrorContent = (props: ErrorProps) => (
  <>
    <Layout.Body>
      <Card>
        <Grid.Container>
          <Grid.Item>
            <h1>Hey, there is an error that occurred.</h1>
            <p className="hc-mt-lg">Error: {props.error?.message}</p>
            <Button className="hc-mt-md hc-mb-xl">Send Error Report</Button>
          </Grid.Item>
        </Grid.Container>
      </Card>
    </Layout.Body>
    <Layout.Rail>
      <Card>
        <Grid.Container>
          <Grid.Item>
            <p>Try refreshing the page</p>
          </Grid.Item>
        </Grid.Container>
      </Card>
    </Layout.Rail>
  </>
)
