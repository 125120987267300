import React, { Suspense, useState } from 'react'
import { RingLoader } from 'react-spinners'
import { LoaderSizeProps } from 'react-spinners/interfaces'
import './Loading.scss'

interface SuspenseRingLoaderProps extends LoaderSizeProps {
  children: React.ReactNode
  size?: number
  speedMultiplier?: number
}

export const SuspenseRingLoader = ({
  children,
  size = 150,
  speedMultiplier = 1,
  ...props
}: SuspenseRingLoaderProps) => {
  const [showLoader, setShowLoader] = useState<boolean>(false)

  setTimeout(() => setShowLoader(true), 1500)

  const fallbackComponent = showLoader ? (
    <div className="center-loading-spinner">
      <RingLoader size={size} speedMultiplier={speedMultiplier} {...props} />
    </div>
  ) : (
    <></>
  )
  return <Suspense children={children} fallback={fallbackComponent} />
}
