/**
 * @generated SignedSource<<98a2801d5a1597f2379241c3e37fbcc6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentCardResponseFragment$data = {
  readonly createdAt: Date | null;
  readonly gwtUser: {
    readonly " $fragmentSpreads": FragmentRefs<"UserAvatar_gwtUser">;
  } | null;
  readonly rowId: UUID;
  readonly " $fragmentSpreads": FragmentRefs<"CommentOptions_comment" | "EditableComment_editable">;
  readonly " $fragmentType": "CommentCardResponseFragment";
};
export type CommentCardResponseFragment$key = {
  readonly " $data"?: CommentCardResponseFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentCardResponseFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentCardResponseFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentOptions_comment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditableComment_editable"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "createdAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "GwtUser",
      "kind": "LinkedField",
      "name": "gwtUser",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserAvatar_gwtUser"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Comment",
  "abstractKey": null
};

(node as any).hash = "cd24aa961688e0ead34c2737fad8657a";

export default node;
