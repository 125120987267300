import {
  ProgramType,
  SoftwareType,
} from '../views/Admin/__generated__/TemplateCreatePageMutation.graphql'

export const getModuleProgramTypeText = (
  programType: ProgramType | undefined | null,
) => {
  switch (programType) {
    case 'A_A_3_D_ACCREDITATION':
      return 'A&A 3D Accreditation'
    case '_3_D_STYLING':
      return '3D Styling'
    case 'ACCESSORIES_3_D_ACCREDITATION':
      return 'Accessories 3D Accreditation'
    case 'SOFT_HOME_3_D_ACCREDITATION':
      return 'Soft Home 3D Accreditation'
    default:
      return 'Unknown'
  }
}

export const getModuleSoftwareTypeText = (
  programType: SoftwareType | undefined,
) => {
  switch (programType) {
    case 'BROWZWEAR':
      return 'Browzwear'
    case 'CLO':
      return 'Clo'
    default:
      return 'Unknown'
  }
}
