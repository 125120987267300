/**
 * @generated SignedSource<<a26bfddd7e0bea11c957beea107fecff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type OverviewTab_module$data = {
  readonly attempts: number;
  readonly flow: {
    readonly assignedAt: Date | null;
    readonly description: string;
    readonly dueAt: Date | null;
    readonly name: string;
    readonly rowId: UUID;
    readonly " $fragmentSpreads": FragmentRefs<"StatusChip_flow">;
  } | null;
  readonly galleryAssetComponentsId: UUID | null;
  readonly tasksByModuleFlowId: {
    readonly nodes: ReadonlyArray<{
      readonly flow: {
        readonly name: string;
        readonly rowId: UUID;
      } | null;
      readonly priority: number;
    }>;
  };
  readonly " $fragmentType": "OverviewTab_module";
};
export type OverviewTab_module$key = {
  readonly " $data"?: OverviewTab_module$data;
  readonly " $fragmentSpreads": FragmentRefs<"OverviewTab_module">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "OverviewTab_module",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Flow",
      "kind": "LinkedField",
      "name": "flow",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "assignedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dueAt",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "StatusChip_flow"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "PRIORITY_ASC"
        }
      ],
      "concreteType": "TasksConnection",
      "kind": "LinkedField",
      "name": "tasksByModuleFlowId",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Task",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "priority",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Flow",
              "kind": "LinkedField",
              "name": "flow",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "tasksByModuleFlowId(orderBy:\"PRIORITY_ASC\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "galleryAssetComponentsId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "attempts",
      "storageKey": null
    }
  ],
  "type": "Module",
  "abstractKey": null
};
})();

(node as any).hash = "f5de349948d2b4fc50820661bd7b832a";

export default node;
