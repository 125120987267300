/**
 * @generated SignedSource<<378d5ce09f4df85c75ab272ab4296383>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TaskCompleteCheckbox_task$data = {
  readonly complete: boolean;
  readonly flow: {
    readonly name: string;
  } | null;
  readonly flowId: UUID;
  readonly priority: number;
  readonly " $fragmentType": "TaskCompleteCheckbox_task";
};
export type TaskCompleteCheckbox_task$key = {
  readonly " $data"?: TaskCompleteCheckbox_task$data;
  readonly " $fragmentSpreads": FragmentRefs<"TaskCompleteCheckbox_task">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TaskCompleteCheckbox_task",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "flowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "complete",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "priority",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Flow",
      "kind": "LinkedField",
      "name": "flow",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Task",
  "abstractKey": null
};

(node as any).hash = "6bdf238df069298b36fe00c10d3ca4d8";

export default node;
