import { useState, useEffect } from 'react'
import { graphql, usePaginationFragment } from 'react-relay'
import { usePaginateDataLength } from '../../hooks/usePaginateDataLength'
import { NOTIFICATIONS } from '../../hooks'
import { useSubscriptionRefreshQuery } from '../../hooks/useRefresh'
import { GroupTemplateCard } from '../Templates/GroupTemplateCard/GroupTemplateCard'
import {
  GroupTemplatesTablePaginationQuery,
  ModuleGroupFilter,
  ModuleGroupsOrderBy,
} from './__generated__/GroupTemplatesTablePaginationQuery.graphql'
import './GroupTemplateTable.scss'
import { GroupTemplatesTablePaginationFragment_groupModules$key } from './__generated__/GroupTemplatesTablePaginationFragment_groupModules.graphql'
import { GridContainer, GridItem, Layout } from '@enterprise-ui/canvas-ui-react'
import {
  documentScrollPercentAtom,
  moduleGroupSearchAtom,
} from '../../state/atoms'
import { useAtomValue } from 'jotai'
import { merge } from 'lodash'

const PAGE_SIZE = 24

export const GroupTemplatesTable = () => {
  const { data, isLoadingNext, hasNext, loadNext } =
    useGroupTemplatesTableQuery(
      {
        flow: {
          ownerId: null,
          linkedType: { equalTo: 'MODULE_GROUP' },
        },
      },
      ['FLOW_BY_FLOW_ID__UPDATED_AT_DESC'],
    )

  const documentScrollPercent = useAtomValue(documentScrollPercentAtom)

  useEffect(() => {
    if (hasNext && !isLoadingNext && documentScrollPercent > 50) {
      loadNext(PAGE_SIZE)
    }
  }, [documentScrollPercent, hasNext, isLoadingNext, loadNext])

  return (
    <Layout.Body includeRail>
      <GridContainer>
        {data?.moduleGroups?.edges?.map(({ node }) => (
          <GridItem xs={3} key={node.flow?.rowId}>
            <GroupTemplateCard groupModuleRef={node} />
          </GridItem>
        ))}
      </GridContainer>
    </Layout.Body>
  )
}

const useGroupTemplatesTableQuery = (
  moduleGroupsFilter: ModuleGroupFilter | undefined = undefined,
  moduleGroupsOrderBy: [ModuleGroupsOrderBy] | undefined = undefined,
) => {
  const [dataLength, setDataLength] = useState(PAGE_SIZE)
  const moduleGroupSearch = useAtomValue(moduleGroupSearchAtom)

  let filter = merge({}, moduleGroupsFilter, moduleGroupSearch)

  const pageData =
    useSubscriptionRefreshQuery<GroupTemplatesTablePaginationQuery>(
      graphql`
        query GroupTemplatesTablePaginationQuery(
          $moduleGroupsFilter: ModuleGroupFilter
          $moduleGroupsOrderBy: [ModuleGroupsOrderBy!]
          $count: Int!
        ) {
          ...GroupTemplatesTablePaginationFragment_groupModules
            @arguments(
              moduleGroupsFilter: $moduleGroupsFilter
              moduleGroupsOrderBy: $moduleGroupsOrderBy
              count: $count
            )
        }
      `,
      {
        moduleGroupsFilter: filter,
        moduleGroupsOrderBy,
        count: dataLength,
      },
      {
        component: 'GROUP_TEMPLATES_TABLE',
        uniqueComponentId: '',
        onNotification: [...NOTIFICATIONS],
        includeSelfNotifications: true,
      },
    )

  const paginationFragmentHook = usePaginationFragment<
    GroupTemplatesTablePaginationQuery,
    GroupTemplatesTablePaginationFragment_groupModules$key
  >(
    graphql`
      fragment GroupTemplatesTablePaginationFragment_groupModules on Query
      @argumentDefinitions(
        moduleGroupsFilter: { type: "ModuleGroupFilter" }
        moduleGroupsOrderBy: { type: "[ModuleGroupsOrderBy!]" }
        count: { type: "Int" }
        cursor: { type: "Cursor" }
      )
      @refetchable(
        queryName: "GroupTemplatesTablePaginationQuery_Refetchable"
      ) {
        moduleGroups(
          filter: $moduleGroupsFilter
          orderBy: $moduleGroupsOrderBy
          first: $count
          after: $cursor
        ) @connection(key: "GroupTemplatesTable_moduleGroups") {
          edges {
            node {
              ...GroupTemplateCard_moduleGroup
              flow {
                rowId
                name
                updatedAt
                durationSeconds
              }
            }
          }
          totalCount
        }
      }
    `,
    pageData,
  )

  const paginateLength = usePaginateDataLength(
    paginationFragmentHook?.data?.moduleGroups?.edges,
    PAGE_SIZE,
  )

  useEffect(() => {
    if (paginateLength) {
      setDataLength(paginateLength)
    }
  }, [paginateLength])

  return { ...paginationFragmentHook, pageData }
}
