import classNames from 'classnames'
import './AssentStickyFooter.scss'

interface AssentStickyFooterProps
  extends React.HTMLAttributes<HTMLDivElement> {}

export const AssentStickyFooter = (props: AssentStickyFooterProps) => {
  const className = classNames('assent-sticky-footer', props.className)

  return <div {...props} className={className} />
}
