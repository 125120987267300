/**
 * @generated SignedSource<<c0517d198f2f0d52a223e94a5c875525>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type AssignmentOptions_module$data = {
  readonly flow: {
    readonly archivedAt: Date | null;
    readonly name: string;
    readonly rowId: UUID;
  } | null;
  readonly " $fragmentType": "AssignmentOptions_module";
};
export type AssignmentOptions_module$key = {
  readonly " $data"?: AssignmentOptions_module$data;
  readonly " $fragmentSpreads": FragmentRefs<"AssignmentOptions_module">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssignmentOptions_module",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Flow",
      "kind": "LinkedField",
      "name": "flow",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rowId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "archivedAt",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Module",
  "abstractKey": null
};

(node as any).hash = "7c64942f1daa6f22e53d1254e59a0577";

export default node;
