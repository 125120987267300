/**
 * @generated SignedSource<<9cf8d5d99afcc142b9444fcb1dde09ba>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentReplyFragment$data = {
  readonly commentId: UUID;
  readonly flow: {
    readonly assignedToId: UUID | null;
    readonly name: string;
  } | null;
  readonly flowId: UUID;
  readonly " $fragmentType": "CommentReplyFragment";
};
export type CommentReplyFragment$key = {
  readonly " $data"?: CommentReplyFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentReplyFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentReplyFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Flow",
      "kind": "LinkedField",
      "name": "flow",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "assignedToId",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "flowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commentId",
      "storageKey": null
    }
  ],
  "type": "FlowComment",
  "abstractKey": null
};

(node as any).hash = "884815cab64d5cc908feefd6076720ed";

export default node;
