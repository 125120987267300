import { PolFileUploadStatus } from '../hooks/__generated__/useNotificationsMutation.graphql'

export const getFormattedUploadStatus = (
  uploadStatus: PolFileUploadStatus,
): string => {
  switch (uploadStatus) {
    case 'ERROR':
      return 'Error'
    case 'NOT_STARTED':
      return 'Not started'
    case 'SCANNING':
      return 'Scanning'
    case 'WAITING_FOR_JOB':
      return 'Waiting to scan'
    case 'SUCCESS':
      return 'Success'
    default:
      return 'Uknown status'
  }
}
