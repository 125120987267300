import { GridContainer, GridItem, List } from '@enterprise-ui/canvas-ui-react'
import { useFragment } from 'react-relay'
import { graphql } from 'relay-runtime'
import { SunEditorDisplay } from '../../GenericComponents/RichTextEditor/SunEditorDisplay'
import { getFormattedDate } from '../../util/dataSelectors'
import { StatusChip } from '../Flows/StatusChip'
import { GalleryAssetFilesDisplay } from '../Gallery/GalleryAssetFilesDisplay'
import './Module.scss'
import { OverviewTab_module$key } from './__generated__/OverviewTab_module.graphql'

interface OverviewTabProps {
  moduleRef: OverviewTab_module$key | null | undefined
}

export const OverviewTab = (props: OverviewTabProps) => {
  const data = useFragment(
    graphql`
      fragment OverviewTab_module on Module {
        flow {
          rowId
          name
          description
          assignedAt
          dueAt
          ...StatusChip_flow
        }
        tasksByModuleFlowId(orderBy: PRIORITY_ASC) {
          nodes {
            priority
            flow {
              rowId
              name
            }
          }
        }
        galleryAssetComponentsId
        attempts
      }
    `,
    props.moduleRef || null,
  )

  return (
    <div>
      <div className="small-width">
        <GridContainer spacing="none" className="hc-mt-md hc-mb-xl">
          <GridItem>
            <h2>Details</h2>
          </GridItem>
        </GridContainer>
        <GridContainer spacing="none" className="hc-mt-md hc-mb-xl">
          <GridItem xs={3}>
            <h4>Date assigned</h4>
            {getFormattedDate(data?.flow?.assignedAt)}
          </GridItem>
          <GridItem xs={3}>
            <h4>Due date</h4>
            {getFormattedDate(data?.flow?.dueAt)}
          </GridItem>
          <GridItem xs={3}>
            <h4>Number of attempts</h4>
            {data?.attempts}
          </GridItem>
          <GridItem xs={3}>
            <h4>Status</h4>
            <StatusChip className="status-chip" flowRef={data?.flow} />
          </GridItem>
        </GridContainer>
      </div>
      <div className="small-width hc-mb-xl">
        <h2> Description </h2>
        <div className="hc-mt-md">
          <SunEditorDisplay initialValue={data?.flow?.description} />
        </div>
      </div>
      <div className="small-width hc-mb-xl">
        <h2> Task List </h2>
        <div className="hc-mt-md">
          {data?.tasksByModuleFlowId && (
            <List className="hc-ma-none hc-pa-none">
              {data.tasksByModuleFlowId.nodes.map((task, i) => (
                <List.Item
                  className="hc-mt-sm hc-pa-none"
                  key={task.flow?.rowId}
                >
                  {`${i + 1}. ${task.flow?.name}`}
                </List.Item>
              ))}
            </List>
          )}
          {data?.tasksByModuleFlowId.nodes.length === 0 && (
            <div className="error-color">No tasks.</div>
          )}
        </div>
      </div>
      <div className="small-width" style={{ paddingBottom: '20px' }}>
        <h2 className="hc-mb-md"> Components </h2>
        <GalleryAssetFilesDisplay
          displayCreatedBy={false}
          assetId={data?.galleryAssetComponentsId}
          enableDeletes={false}
        />
      </div>
    </div>
  )
}
