import {
  Button,
  Form,
  Grid,
  Layout,
  Spinner,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { graphql, useLazyLoadQuery } from 'react-relay'
import { FormProvider, useForm } from 'react-hook-form'
import { ControlledFormField } from '../../../controlledComponents/ControlledFormField'
import { useGwtMutation } from '../../../hooks'
import { FC } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import './CompanyCreatePage.scss'
import { CompanyCreatePageQuery } from './__generated__/CompanyCreatePageQuery.graphql'
import { CompanyCreatePageNewProfileMutation } from './__generated__/CompanyCreatePageNewProfileMutation.graphql'
import { CompanyCreatePageUpdateProfileMutation } from './__generated__/CompanyCreatePageUpdateProfileMutation.graphql'
import { usePublishGlobalNotification } from '../../../hooks/useNotifications'

type CompanyCreatePageFormType = {
  name: string
  description: string
}

interface CompanyCreatePageProps {
  test?: boolean
}

export const CompanyCreatePage: FC<CompanyCreatePageProps> = () => {
  const navigate = useNavigate()
  const { companyId } = useParams()

  const { publishToLocal } = usePublishGlobalNotification()

  const { company } = useLazyLoadQuery<CompanyCreatePageQuery>(
    graphql`
      query CompanyCreatePageQuery($rowId: UUID!, $skip: Boolean!) {
        company(rowId: $rowId) @skip(if: $skip) {
          name
          description
        }
      }
    `,
    { rowId: companyId, skip: !companyId },
  )

  const formMethods = useForm({
    defaultValues: {
      name: company?.name || '',
      description: company?.description || '',
    },
  })

  const {
    handleSubmit,
    formState: { isDirty },
  } = formMethods

  const makeToast = ToastProvider.useToaster()

  const createMutation = useGwtMutation<CompanyCreatePageNewProfileMutation>(
    graphql`
      mutation CompanyCreatePageNewProfileMutation(
        $input: CreateCompanyInput!
      ) {
        createCompany(input: $input) {
          company {
            rowId
            name
            description
          }
        }
      }
    `,
  )

  const updateMutation = useGwtMutation<CompanyCreatePageUpdateProfileMutation>(
    graphql`
      mutation CompanyCreatePageUpdateProfileMutation(
        $input: UpdateCompanyInput!
      ) {
        updateCompany(input: $input) {
          company {
            rowId
            name
            description
          }
        }
      }
    `,
  )

  const submitCompanyCreate = (data: CompanyCreatePageFormType) => {
    if (createMutation.isInFlight || !isDirty) return

    createMutation.commit(
      {
        input: {
          company: {
            ...data,
          },
        },
      },
      () => {
        makeToast({
          type: 'success',
          heading: 'Company creation',
          message: 'Successfully created new company.',
        })
        publishToLocal('COMPANY_CREATE_NOTIFICATION')
        navigate(-1)
      },
      () => {
        makeToast({
          type: 'error',
          heading: 'Company creation',
          message: 'There was an issue creating the company, please try again.',
        })
      },
    )
  }

  const submitCompanyUpdate = (data: CompanyCreatePageFormType) => {
    if (updateMutation.isInFlight || !isDirty) return

    updateMutation.commit(
      {
        input: {
          patch: {
            ...data,
          },
          rowId: companyId,
        },
      },
      () => {
        makeToast({
          type: 'success',
          heading: 'Company edit',
          message: 'Successfully edited company profile.',
        })
        publishToLocal('COMPANY_EDIT_NOTIFICATION')
        navigate(-1)
      },
      () => {
        makeToast({
          type: 'error',
          heading: 'Company edit',
          message:
            'There was an issue saving company changes, please try again.',
        })
      },
    )
  }

  const isInFlight = updateMutation.isInFlight || createMutation.isInFlight

  return (
    <Layout.Body className="company-create-page" includeRail>
      <FormProvider {...formMethods}>
        <Form
          onSubmit={
            companyId
              ? handleSubmit(submitCompanyUpdate)
              : handleSubmit(submitCompanyCreate)
          }
        >
          <Grid.Container className="small-width hc-pl-expanded hc-pr-expanded hc-pt-2x hc-pb-expanded">
            <Grid.Item xs={2} className="hc-pr-lg" />
            <Grid.Item xs={8} className="hc-pr-lg">
              <div className="hc-ta-left">
                <h1>{companyId ? 'Edit company' : 'Create new company'}</h1>
              </div>
              <div className="hc-mt-xl">
                <ControlledFormField
                  className="hc-mb-xl"
                  label="Name"
                  placeholder="Enter name"
                  required
                  name="name"
                />
                <ControlledFormField
                  className="hc-mb-xl"
                  label="Description"
                  placeholder="Enter company description"
                  required
                  name="description"
                />
              </div>
              <div className="buttons hc-mt-md">
                <Button
                  className="hc-mr-md button-width"
                  disabled={isInFlight}
                  onClick={() => navigate(-1)}
                >
                  Cancel
                </Button>
                <Button
                  className="button-width"
                  type="submit"
                  disabled={isInFlight || !isDirty}
                >
                  {companyId ? 'Update company' : 'Create company'}
                  {isInFlight && <Spinner className="hc-ml-sm" />}
                </Button>
              </div>
            </Grid.Item>
            <Grid.Item xs={2} />
          </Grid.Container>
        </Form>
      </FormProvider>
    </Layout.Body>
  )
}
