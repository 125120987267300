import React from 'react'
import { useGlobalCarousel } from '../hooks/useGlobalCarousel'

import Lightbox from 'react-image-lightbox'
import 'react-image-lightbox/style.css'
export const GlobalImageGallery = () => {
  const { imageAtom, hideCarousel } = useGlobalCarousel()

  if (imageAtom.enabled) {
    return (
      <Lightbox
        mainSrc={imageAtom.images[0].src}
        onCloseRequest={() => hideCarousel()}
        imageTitle={imageAtom.images[0].title}
      />
    )
  }

  return null
}
