import { Input, InputProps } from '@enterprise-ui/canvas-ui-react'
import { useState } from 'react'
import { useDebouncedCallback } from 'use-debounce'
import { v4 as uuidv4 } from 'uuid'

interface TextInputDebounceProps extends InputProps {
  delay: number
  onUpdate: (inputChange: string) => void
}

export const TextInputDebounce = ({
  delay,
  onUpdate,
  ...props
}: TextInputDebounceProps) => {
  const [text, setText] = useState('')
  const debounced = useDebouncedCallback((value) => {
    onUpdate(value)
  }, delay)

  return (
    <Input.Text
      {...props}
      id={uuidv4()}
      value={text}
      onChange={(e: any) => {
        setText(e.target.value)
        debounced(e.target.value)
      }}
    />
  )
}
