import { graphql, useSubscription } from 'react-relay'
import { useMemo } from 'react'
import {
  NotificationSubscription,
  NotificationSubscription$data,
} from './__generated__/NotificationSubscription.graphql'
import { useAtom } from 'jotai'
import { globalNotificationAtom } from '../state/atoms'
import { NOTIFICATION } from '../hooks'
import { UUID } from '../react-app-env'

/**
 * Global component that manages the notifications from the GWT API
 * This could be made into a hook but this really should only be instantiated
 * once ever so I am putting it into a global component instead
 */
const subscription = graphql`
  subscription NotificationSubscription {
    notification {
      id
      name
      gwt_user_id
      data
    }
  }
`
export type NotificationUpdate = {
  id: UUID
  name: NOTIFICATION
  data: any | null
  gwt_user_id: UUID
}

const convertResponseToNotificationUpdate = (
  notification: NotificationSubscription$data,
): NotificationUpdate | null => {
  try {
    const noti = notification.notification

    if (!noti) return null

    const json = noti.data ? JSON.parse(noti.data) : null

    const updateName: NOTIFICATION = noti.name as NOTIFICATION

    const update: NotificationUpdate = {
      id: noti.id,
      gwt_user_id: noti.gwt_user_id,
      name: updateName,
      data: json,
    }

    return update
  } catch (err) {
    return null
  }
}

export const NotificationSubscriptionComponent = () => {
  // IMPORTANT: your config should be memoized.
  // Otherwise, useSubscription will re-render too frequently.
  const [, publishNotification] = useAtom(globalNotificationAtom)

  const config = useMemo(
    () => ({
      variables: {},
      onNext: (response: NotificationSubscription$data | null | undefined) => {
        if (response) {
          const update = convertResponseToNotificationUpdate(response)
          if (update) {
            publishNotification(update)
          }
        }
      },
      subscription,
    }),
    [publishNotification],
  )

  useSubscription<NotificationSubscription>(config)

  return <></>
}
