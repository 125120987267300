import { Button, Layout } from '@enterprise-ui/canvas-ui-react'
import { useState } from 'react'
import { useBanner } from '../../hooks/useBanner'
import './BannerNotification.scss'

export const BannerNotification = () => {
  const [hideBanner, setHideBanner] = useState(false)
  const { bannerState } = useBanner()

  if (hideBanner || !bannerState) {
    return <></>
  }

  return (
    <>
      <div
        className="C-Layout__notificationBackground banner-z-index"
        style={{ backgroundColor: bannerState.bannerBackgroundColor }}
      />
      <Layout.Notification
        error
        className="align-left banner-z-index"
        style={{ color: bannerState.bannerTextColor }}
      >
        <span>{bannerState.notificationText}</span>
        <Button
          size="dense"
          className="hc-ml-lg"
          onClick={() => {
            setHideBanner(true)
          }}
        >
          Hide Banner
        </Button>
      </Layout.Notification>
    </>
  )
}
