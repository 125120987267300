import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import utc from 'dayjs/plugin/utc'

dayjs.extend(relativeTime)
dayjs.extend(utc)

export const getTimeFromCreatedAtString = (
  createdAt: Date | null | undefined,
) => {
  if (!createdAt) {
    return ''
  }

  return dayjs.utc(createdAt).fromNow()
}

export const getGwtFormattedDate = (date: Date | null | undefined) => {
  if (!date) {
    return 'unknown'
  }
  const dayDate = dayjs.utc(date)

  return dayDate.format('MM/DD/YY')
}

export const getGwtFormattedTime = (date: Date | null | undefined) => {
  if (!date) {
    return 'unknown'
  }
  const dayDate = dayjs.utc(date)

  return dayDate.format('h:mm A')
}

export const getCurrentDateAndYear = (
  date: Date | null | undefined,
): string => {
  if (!date) {
    return 'unknown'
  }
  return dayjs.utc(date).format('MMMM, YYYY')
}

export const getDistanceFromNow = (date: Date | null | undefined) => {
  if (!date) {
    return 'unknown'
  }

  if (dayjs.utc(date).diff(dayjs.utc(Date.now())) > 0) {
    return 'now'
  }

  return dayjs.utc(date).fromNow()
}
