/**
 * @generated SignedSource<<02ab9573b0ddf98974d49362e5a35102>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserProfilePageQuery$variables = {
  rowId: UUID;
};
export type UserProfilePageQuery$data = {
  readonly gwtUser: {
    readonly createdAt: Date | null;
    readonly email: string;
    readonly fullName: string;
    readonly pronouns: string | null;
    readonly " $fragmentSpreads": FragmentRefs<"UserAssignments" | "UserAvatar_gwtUser" | "UserCertifications" | "UserHistory" | "UserInfo" | "UserPriority_gwtUser" | "UserStatusChip_gwtUser">;
  } | null;
};
export type UserProfilePageQuery = {
  response: UserProfilePageQuery$data;
  variables: UserProfilePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "rowId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "rowId",
    "variableName": "rowId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pronouns",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "programType",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserProfilePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GwtUser",
        "kind": "LinkedField",
        "name": "gwtUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserAvatar_gwtUser"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserHistory"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserInfo"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserStatusChip_gwtUser"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserAssignments"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserCertifications"
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserPriority_gwtUser"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserProfilePageQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "GwtUser",
        "kind": "LinkedField",
        "name": "gwtUser",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/),
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "firstName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lastName",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "lanId",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "orderBy",
                "value": "CREATED_AT_DESC"
              }
            ],
            "concreteType": "ActivitiesConnection",
            "kind": "LinkedField",
            "name": "activities",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Activity",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v7/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "verb",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "linkLocation",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "object",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "activities(orderBy:\"CREATED_AT_DESC\")"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userType",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "Company",
            "kind": "LinkedField",
            "name": "company",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "jobTitle",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "programs",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "status",
            "storageKey": null
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "condition",
                "value": {
                  "linkedType": "MODULE"
                }
              },
              {
                "kind": "Literal",
                "name": "includeArchived",
                "value": "YES"
              }
            ],
            "concreteType": "FlowsConnection",
            "kind": "LinkedField",
            "name": "flowsByAssignedToId",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Flow",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dueAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "assignmentStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "assignmentDueAtStatus",
                    "storageKey": null
                  },
                  (v7/*: any*/),
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Module",
                    "kind": "LinkedField",
                    "name": "module",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "flowId",
                        "storageKey": null
                      },
                      (v9/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "flowsByAssignedToId(condition:{\"linkedType\":\"MODULE\"},includeArchived:\"YES\")"
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "FlowsConnection",
            "kind": "LinkedField",
            "name": "certifications",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Flow",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "GwtUser",
                    "kind": "LinkedField",
                    "name": "assignedTo",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "completedAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Module",
                    "kind": "LinkedField",
                    "name": "module",
                    "plural": false,
                    "selections": [
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "softwareType",
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "userPriority",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ee03e05a0e9e8de21afd8fd1a96cb73b",
    "id": null,
    "metadata": {},
    "name": "UserProfilePageQuery",
    "operationKind": "query",
    "text": "query UserProfilePageQuery(\n  $rowId: UUID!\n) {\n  gwtUser(rowId: $rowId) {\n    createdAt\n    email\n    fullName\n    pronouns\n    ...UserAvatar_gwtUser\n    ...UserHistory\n    ...UserInfo\n    ...UserStatusChip_gwtUser\n    ...UserAssignments\n    ...UserCertifications\n    ...UserPriority_gwtUser\n    id\n  }\n}\n\nfragment StatusChip_flow on Flow {\n  dueAt\n  assignmentStatus\n  assignmentDueAtStatus\n}\n\nfragment UserAssignments on GwtUser {\n  flowsByAssignedToId(condition: {linkedType: MODULE}, includeArchived: YES) {\n    nodes {\n      ...StatusChip_flow\n      rowId\n      assignmentDueAtStatus\n      assignmentStatus\n      name\n      module {\n        flowId\n        programType\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment UserAvatar_gwtUser on GwtUser {\n  id\n  firstName\n  lastName\n  lanId\n}\n\nfragment UserCertifications on GwtUser {\n  fullName\n  certifications {\n    nodes {\n      assignedTo {\n        fullName\n        id\n      }\n      name\n      completedAt\n      module {\n        programType\n        softwareType\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment UserHistory on GwtUser {\n  firstName\n  lastName\n  activities(orderBy: CREATED_AT_DESC) {\n    nodes {\n      rowId\n      verb\n      linkLocation\n      object\n      createdAt\n      id\n    }\n  }\n}\n\nfragment UserInfo on GwtUser {\n  userType\n  company {\n    rowId\n    name\n    id\n  }\n  jobTitle\n  ...UserProgramsDisplay\n}\n\nfragment UserPriority_gwtUser on GwtUser {\n  userPriority\n}\n\nfragment UserProgramsDisplay on GwtUser {\n  programs\n}\n\nfragment UserStatusChip_gwtUser on GwtUser {\n  status\n}\n"
  }
};
})();

(node as any).hash = "e8d2e4354bef244117772b2936bd7e87";

export default node;
