import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAtom } from 'jotai'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import { faPlusCircle, faTimes } from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  Form,
  Grid,
  Heading,
  Input,
  Layout,
  Spinner,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import {
  FormProvider,
  SubmitHandler,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import {
  RichTextEditorContent,
  ControlledRichTextEditor,
} from '../../controlledComponents/ControlledRichTextEditor'
import { FileUploadTable } from '../Files/FileUploadTable/FileUploadTable'
import { ControlledFormField } from '../../controlledComponents/ControlledFormField'
import { UUID } from '../../react-app-env'
import { globalNotificationAtom } from '../../state/atoms'
import { GwtMutationResponse } from '../../hooks/useGwtMutation'
import {
  getActivityInputObject,
  getNotificationInputObject,
} from '../../util/relayUtils'

import './ModuleEditForm.scss'
import { SuspenseRingLoader } from '../../loadingComponents/SuspenseRingLoader'
import { GalleryAssetFilesDisplay } from '../Gallery/GalleryAssetFilesDisplay'

import {
  ModuleEditPageMutation$variables,
  ProgramType,
  SoftwareType,
} from './Overview/__generated__/ModuleEditPageMutation.graphql'
import { activityTypes } from '../../constants'

interface FormValues {
  name: string
  assignTo?: string
  duration?: string
  attempts?: number
  description: RichTextEditorContent
  tasks: {
    value: string
  }[]
  softwareType?: SoftwareType | null
  programType?: ProgramType | null
  galleryAssetComponentsId: UUID
  rowId: UUID
  versionNumber: number
}

interface PropTypes {
  defaultValues?: FormValues
  defaultReviewer?: {
    value: string
    label: string
  }
  commit: GwtMutationResponse<any>['commit']
  isInFlight: boolean
}

export const ModuleEditForm = ({
  defaultValues,
  defaultReviewer,
  commit,
  isInFlight,
}: PropTypes) => {
  const [createdAssetId, setCreatedAssetId] = useState<UUID | undefined>(
    defaultValues?.galleryAssetComponentsId,
  )

  const [initiateFileUpload, setInitiateFileUpload] = useState(false)
  const [, publishNotification] = useAtom(globalNotificationAtom)
  const makeToast = ToastProvider.useToaster()
  const location = useLocation()
  const navigate = useNavigate()
  const locationState = location.state as { prevUrl: string }
  const homeDashboard = locationState?.prevUrl || '/assignments'

  const formMethods = useForm<FormValues>({
    defaultValues: {
      tasks: [
        {
          value: '',
        },
      ],
      name: '',
      duration: '',
      attempts: undefined,
      softwareType: undefined,
      programType: undefined,
      description: { textContent: '', htmlContent: '' },
      ...defaultValues,
      assignTo: defaultReviewer?.value || 'unassigned',
    },
  })

  const { control, handleSubmit } = formMethods

  const {
    fields: taskFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'tasks',
  })

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    let durationOfModule = data.duration ? parseInt(data.duration) * 604800 : 0
    const mutation: ModuleEditPageMutation$variables = {
      attempts: data.attempts || 0,
      durationSeconds: durationOfModule,
      description: data.description.htmlContent,
      dueAt: data.duration
        ? dayjs.utc(Date()).add(parseInt(data.duration), 'week').toDate()
        : undefined,
      softwareType: data.softwareType,
      programType: data.programType,
      name: data.name,
      activity: getActivityInputObject(
        activityTypes.EDITED_A_MODULE,
        data.name || '',
        data.rowId || '',
      ),
      notificationInput: getNotificationInputObject('MODULE_EDIT_NOTIFICATION'),
      rowId: data.rowId,
      reviewers:
        data.assignTo !== 'unassigned'
          ? [{ reviewerId: data.assignTo }]
          : undefined,
      tasks: data.tasks
        .filter((f) => f.value)
        .map((field, index) => {
          return {
            flowToFlowId: { create: { name: field.value } },
            priority: index,
          }
        }),
      versionNumber: (data.versionNumber || 0) + 1,
    }
    commit?.(
      mutation,
      ({ createModule }) => {
        if (createModule) {
          setCreatedAssetId(createModule.module?.galleryAssetComponentsId)
        }
        setInitiateFileUpload(true)
      },
      () => {
        makeToast({
          type: 'error',
          heading: 'Module edit failed.',
          message: 'Changes to module were unsuccessfully saved',
        })
      },
    )
  }

  return (
    <Layout.Body className="template" includeRail>
      <FormProvider {...formMethods}>
        <Form className="formLayout" onSubmit={handleSubmit(onSubmit)}>
          <Grid.Container>
            <Grid.Item xs={12}>
              <Heading className="hc-ml-none">
                Edit {defaultValues?.name}
              </Heading>
            </Grid.Item>
            <Grid.Item xs={6}>
              <ControlledFormField
                disabled
                options={[
                  {
                    disabled: true,
                    value: `${defaultValues?.name}`,
                    label: '',
                  },
                ]}
                label="Name of template"
                name="name"
              />
            </Grid.Item>
            <Grid.Item xs={6}>
              <ControlledFormField
                type="select"
                label="Duration"
                name="duration"
                options={[
                  {
                    disabled: false,
                    label: '',
                    value: '',
                  },
                  {
                    disabled: false,
                    label: '1 week (7 calendar days)',
                    value: '1',
                  },
                  {
                    disabled: false,
                    label: '2 weeks (14 calendar days)',
                    value: '2',
                  },
                  {
                    disabled: false,
                    label: '3 weeks (21 calendar days)',
                    value: '3',
                  },
                  {
                    disabled: false,
                    label: '4 weeks (28 calendar days)',
                    value: '4',
                  },
                  {
                    disabled: false,
                    label: '5 weeks (35 calendar days)',
                    value: '5',
                  },
                  {
                    disabled: false,
                    label: '6 weeks (42 calendar days)',
                    value: '6',
                  },
                ]}
              />
            </Grid.Item>
            <Grid.Item xs={6} className="hc-pb-sm">
              <ControlledFormField
                type="number"
                label="Number of attempts"
                name="attempts"
              />
            </Grid.Item>
            <Grid.Item xs={6} className="hc-pb-sm">
              <ControlledFormField
                required
                type="select"
                label="Software"
                options={[
                  {
                    disabled: false,
                    label: '',
                    value: '',
                  },
                  {
                    disabled: false,
                    label: 'Browzwear',
                    value: 'BROWZWEAR',
                  },
                  {
                    disabled: false,
                    label: 'Clo',
                    value: 'CLO',
                  },
                ]}
                name="softwareType"
              />
            </Grid.Item>
            <Grid.Item xs={12} className="hc-pb-lg">
              <ControlledRichTextEditor
                label="Description"
                name="description"
                requiredMessage="Please enter a description."
              />
            </Grid.Item>

            {taskFields.map((task: any, index) => (
              <div className="tasks" key={task.id}>
                <span className={index === 0 ? 'displayTask' : 'displayNone'}>
                  Tasks
                </span>
                <Grid.Container justify="flex-start" align="center">
                  <Grid.Item xs={1}>
                    <span>{`${index + 1}.`}</span>
                  </Grid.Item>
                  <Grid.Item xs={10}>
                    <ControlledFormField
                      className="hc-mt-md"
                      type="text"
                      name={`tasks[${index}].value`}
                      defaultValue={task.value}
                    />
                  </Grid.Item>
                  <Grid.Item xs={1}>
                    <Button
                      type="secondary"
                      iconOnly
                      aria-label="remove-task"
                      onClick={() => remove(index)}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </Button>
                  </Grid.Item>
                </Grid.Container>
              </div>
            ))}
            <Grid.Container className="hc-pl-normal">
              <Grid.Item xs={3} className="hc-mt-sm">
                <Button type="secondary" onClick={() => append({ value: '' })}>
                  <FontAwesomeIcon icon={faPlusCircle} />
                  &nbsp; Add task
                </Button>
              </Grid.Item>
              <Grid.Item xs={9} />
              <Input.Label className="hc-pl-normal hc-pt-lg hc-pb-xs">
                Components
              </Input.Label>

              <SuspenseRingLoader>
                <div className="hc-mb-md hc-pl-normal hc-pr-expanded">
                  <GalleryAssetFilesDisplay
                    displayCreatedBy={false}
                    assetId={createdAssetId}
                    enableDeletes={true}
                  />
                </div>
              </SuspenseRingLoader>

              <Grid.Item xs={12} className="hc-pr-expanded">
                <FileUploadTable
                  assetId={createdAssetId}
                  initiateUpload={initiateFileUpload}
                  showButtonsAfterInitiate
                  onAllFilesUploaded={() => {
                    // publish the notification for all listeners
                    publishNotification({
                      name: 'MODULE_EDIT_NOTIFICATION',
                      id: 'local',
                      gwt_user_id: 'local',
                      data: null,
                    })

                    makeToast({
                      type: 'success',
                      heading: 'Module edited',
                      message: 'Module was successfully changed.',
                    })

                    navigate(homeDashboard)
                  }}
                />
              </Grid.Item>
            </Grid.Container>

            <Grid.Container
              align="center"
              direction="row-reverse"
              className="hc-pt-normal hc-pr-normal"
            >
              <Grid.Item xs={3}>
                {isInFlight ? (
                  <Button type="submit" fullWidth>
                    <Spinner size="inline" />
                  </Button>
                ) : (
                  <Button type="submit" fullWidth>
                    Save
                  </Button>
                )}
              </Grid.Item>
              <Grid.Item xs={3}>
                <Button
                  type="secondary"
                  fullWidth
                  onClick={() => navigate(homeDashboard)}
                >
                  Cancel
                </Button>
              </Grid.Item>
            </Grid.Container>
          </Grid.Container>
        </Form>
      </FormProvider>
    </Layout.Body>
  )
}
