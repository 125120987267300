import useGwtUser from './useGwtUser'
import { useCallback } from 'react'
import { UserType } from './__generated__/useGwtUser_userTypeQuery.graphql'

export interface ProtectedString {
  val: string
  allowed: Array<UserType>
}

export const useAuthHelper = () => {
  const user = useGwtUser()

  const protectArray = useCallback(
    (
      arry: Array<{ [others: string]: any; allowed: Array<UserType> }>,
    ): Array<any> => {
      return arry.filter((val) => val.allowed.includes(user.userType))
    },
    [user.userType],
  )

  const protectStringArray = useCallback(
    (arry: Array<ProtectedString>): Array<string> => {
      return arry
        .filter((val) => val.allowed.includes(user.userType))
        .map((res) => res.val)
    },
    [user.userType],
  )

  return {
    protectArray,
    protectStringArray,
  }
}
