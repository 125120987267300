import { graphql, useFragment } from 'react-relay'
import { Link, LinkProps } from 'react-router-dom'
import { UserLink_user$key } from './__generated__/UserLink_user.graphql'

interface UserLinkProps extends Omit<LinkProps, 'to'> {
  userRef: UserLink_user$key | null | undefined
}

export const UserLink = ({ userRef, ...props }: UserLinkProps) => {
  const data = useFragment(
    graphql`
      fragment UserLink_user on GwtUser {
        rowId
      }
    `,
    userRef || null,
  )

  if (!data) return null

  return <Link {...props} to={`?userId=${data.rowId}`} />
}
