import { useGwtUser } from '../hooks'
import { ReactNode } from 'react'
import { Navigate } from 'react-router-dom'
import { UserType } from '../hooks/__generated__/useGwtUser_userTypeQuery.graphql'

interface PropTypes {
  allowed: Array<UserType>
  children: ReactNode
  shouldRedirect?: boolean
  unauthorizedRoute?: string
}

export const ProtectedComponent = ({
  children,
  allowed = [],
  shouldRedirect = false,
  unauthorizedRoute = '/',
}: PropTypes) => {
  const { userType } = useGwtUser()

  const authorized = allowed.includes(userType)
  if (!authorized) {
    if (shouldRedirect) {
      return <Navigate to={unauthorizedRoute} replace />
    }
    return null
  }
  return <>{children}</>
}
