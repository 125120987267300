import { ReactNode } from 'react'
import { RelayEnvironmentProvider } from 'react-relay'
import useGwtRelayEnvironment from '../hooks/useGwtRelayEnvironment'

export interface PropTypes {
  children: ReactNode
}

export const GwtRelayEnvironmentProvider = ({ children }: PropTypes) => {
  const gwtRelayEnvironment = useGwtRelayEnvironment()

  return (
    <RelayEnvironmentProvider environment={gwtRelayEnvironment}>
      {children}
    </RelayEnvironmentProvider>
  )
}
