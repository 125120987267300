import { Timeline } from '@enterprise-ui/canvas-ui-react'
import { useFragment } from 'react-relay'
import { graphql } from 'relay-runtime'
import { UserHistory$key } from './__generated__/UserHistory.graphql'
import { Link } from 'react-router-dom'
import { getGwtFormattedDate } from '../../util/dateUtils'
import './UserModal.scss'

interface UserHistoryProps {
  userRef: UserHistory$key | null | undefined
}

export const UserHistory = (props: UserHistoryProps) => {
  const data = useFragment(
    graphql`
      fragment UserHistory on GwtUser {
        firstName
        lastName
        activities(orderBy: CREATED_AT_DESC) {
          nodes {
            rowId
            verb
            linkLocation
            object
            createdAt
          }
        }
      }
    `,
    props.userRef || null,
  )

  if (data?.activities?.nodes.length === 0) {
    return (
      <div className="user-assignments">
        <div className="container bold-text hc-ta-center">
          No history found.
        </div>
      </div>
    )
  }

  return (
    <div className="user-history">
      <Timeline className="hc-pa-none">
        {data?.activities?.nodes.map((node) => (
          <>
            <Timeline.Flag>{getGwtFormattedDate(node.createdAt)}</Timeline.Flag>
            <Timeline.Item
              className={node.linkLocation ? 'time-item-hover' : ''}
              key={node.rowId}
            >
              {node.linkLocation && (
                <Link to={node.linkLocation}>
                  <p className="hc-mt-dense">{`${data.firstName} ${data.lastName} ${node.verb} ${node.object}`}</p>
                </Link>
              )}
              {!node.linkLocation && (
                <p className="hc-mt-dense">{`${data.firstName} ${data.lastName} ${node.verb} ${node.object}`}</p>
              )}
            </Timeline.Item>
          </>
        ))}
      </Timeline>
    </div>
  )
}
