import { Button, Form, Grid, Spinner } from '@enterprise-ui/canvas-ui-react'
import { graphql, useFragment } from 'react-relay'
import './Comment.scss'
import { useForm, SubmitHandler } from 'react-hook-form'
import { useGwtMutation, useGwtUser } from '../../hooks'
import { CommentReplyFragment$key } from './__generated__/CommentReplyFragment.graphql'
import {
  CommentReplyMutation,
  CommentReplyMutation$variables,
} from './__generated__/CommentReplyMutation.graphql'
import { useLocation } from 'react-router-dom'
import {
  getActivityInputObject,
  getNotificationInputObject,
} from '../../util/relayUtils'
import { activityTypes } from '../../constants'

interface CommentCardReplyProps {
  flowCommentRef: CommentReplyFragment$key | null
}

type Inputs = {
  reply: string
}

export const CommentReply = (props: CommentCardReplyProps) => {
  const flowCommentData = useFragment(
    graphql`
      fragment CommentReplyFragment on FlowComment {
        flow {
          name
          assignedToId
        }
        flowId
        commentId
      }
    `,
    props.flowCommentRef || null,
  )

  const { commit, isInFlight } = useGwtMutation<CommentReplyMutation>(graphql`
    mutation CommentReplyMutation(
      $commentText: String!
      $gwtUserId: UUID!
      $responseTo: UUID!
      $flowId: UUID!
      $activity: ActivityInput!
      $notificationInput: NotificationInput!
    ) {
      createActivity(input: { activity: $activity }) {
        clientMutationId
      }
      createNotification(input: { notification: $notificationInput }) {
        clientMutationId
      }
      createFlowComment(
        input: {
          flowComment: {
            commentToCommentId: {
              create: {
                commentText: $commentText
                gwtUserId: $gwtUserId
                responseTo: $responseTo
              }
            }
            flowId: $flowId
          }
        }
      ) {
        flowComment {
          flow {
            module {
              ...HistoryTab_module
            }
          }
          comment {
            gwtUserId
            ...CommentCardResponseFragment
            commentByResponseTo {
              commentsByResponseTo(orderBy: CREATED_AT_ASC) {
                nodes {
                  id
                }
              }
            }
          }
        }
      }
    }
  `)

  const { register, handleSubmit, watch, reset } = useForm()
  const input: string | undefined = watch('reply', '')

  const user = useGwtUser()
  const location = useLocation()

  const onSubmit: SubmitHandler<Inputs> = (data) => {
    if (!flowCommentData || data.reply.length === 0) {
      return
    }
    const mutation = {} as CommentReplyMutation$variables
    mutation.commentText = data.reply
    mutation.gwtUserId = user.rowId
    mutation.flowId = flowCommentData.flowId
    mutation.responseTo = flowCommentData.commentId

    mutation.activity = getActivityInputObject(
      activityTypes.RESPONDED_TO_A_COMMENT,
      flowCommentData.flow?.name || 'unknown',
      flowCommentData.flowId,
      `${location.pathname}${location.hash}`,
    )

    mutation.notificationInput = getNotificationInputObject(
      'ASSIGNEE_MODULE_COMMENT_REPLY_NOTIFICATION',
      {
        flowId: flowCommentData.flowId,
        assignedToId: flowCommentData.flow?.assignedToId,
      },
    )

    commit(mutation, () => {
      reset()
    })
  }

  return (
    <Form
      className="comment-reply-form hc-mt-md"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid.Container spacing="none">
        <Grid.Item xs={12}>
          <Form.Field
            type="textarea"
            size="dense"
            disableFieldInfo
            errorText="Please enter some text"
            placeholder="Reply..."
            id="demo_01"
            data-testid="replyBox"
            value={input}
            disabled={user.isReadOnly}
            {...register('reply')}
          />
        </Grid.Item>
        {input && input.length > 0 && (
          <Grid.Item className="hc-mt-sm align-right" xs={12}>
            {!isInFlight && (
              <Button
                className="hc-pa-sm"
                size="dense"
                type="submit"
                disabled={isInFlight}
              >
                Reply
              </Button>
            )}
            {isInFlight && <Spinner />}
          </Grid.Item>
        )}
      </Grid.Container>
    </Form>
  )
}
