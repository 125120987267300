import { graphql } from 'react-relay'

import {
  PolFileUploadCondition,
  useUploadCheck_Query,
} from './__generated__/useUploadCheck_Query.graphql'
import { useRefreshQuery } from './useRefresh'
import useGwtUser from './useGwtUser'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useQueryClient } from 'react-query'
import { useSetAtom } from 'jotai'
import { uploadsAtom } from '../state/atoms'
import useGwtMutation from './useGwtMutation'
import { useUploadCheck_RetryMutation } from './__generated__/useUploadCheck_RetryMutation.graphql'
import { useUploadCheck_CancelMutation } from './__generated__/useUploadCheck_CancelMutation.graphql'

export default function useUploadCheck(
  galleryAssetId: string,
  refreshRate: number = 15000,
) {
  const user = useGwtUser()
  const queryClient = useQueryClient()

  const [uploadInProgress, setUploadInProgress] = useState(false)
  const setUploadsData = useSetAtom(uploadsAtom)

  const uploadCondition: PolFileUploadCondition = useMemo(() => {
    return {
      gwtUserId: user.rowId,
      galleryAssetId,
    }
  }, [galleryAssetId, user.rowId])

  const { data, refresh } = useRefreshQuery<useUploadCheck_Query>(
    graphql`
      query useUploadCheck_Query($condition: PolFileUploadCondition) {
        polFileUploads(condition: $condition) {
          nodes {
            galleryAssetId
            fileName
            tossUrl
            uploadError
            uploadStatus
            rowId
          }
        }
      }
    `,
    { condition: uploadCondition },
  )

  const retryMutation = useGwtMutation<useUploadCheck_RetryMutation>(graphql`
    mutation useUploadCheck_RetryMutation($input: PolUploadInput!) {
      retryPolUpload(input: $input) {
        clientMutationId
      }
    }
  `)

  const cancelMutation = useGwtMutation<useUploadCheck_CancelMutation>(graphql`
    mutation useUploadCheck_CancelMutation($input: PolUploadInput!) {
      cancelPolUpload(input: $input) {
        clientMutationId
      }
    }
  `)

  // refresh
  useEffect(() => {
    const interval = setInterval(() => {
      refresh({ condition: uploadCondition })
    }, refreshRate)

    return () => clearInterval(interval)
  })

  const refreshUploadCheck = useCallback(() => {
    refresh({ condition: uploadCondition })
  }, [refresh, uploadCondition])

  const isUploading = (data?.polFileUploads?.nodes?.length || 0) > 0

  useEffect(() => {
    if (!uploadInProgress && isUploading) {
      setUploadInProgress(true)
    }
  }, [data, isUploading, uploadInProgress])

  useEffect(() => {
    if (uploadInProgress && !isUploading) {
      queryClient.invalidateQueries(['getAsset', galleryAssetId])
      setUploadInProgress(false)
    }
  }, [galleryAssetId, isUploading, queryClient, uploadInProgress])

  useEffect(() => {
    setUploadsData((data.polFileUploads?.nodes || []) as any)
  }, [data, setUploadsData])

  return {
    cancelMutation,
    retryMutation,
    isUploading,
    refreshUploadCheck,
  }
}
