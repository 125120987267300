import './Comment.scss'
import { Button, Form, Grid } from '@enterprise-ui/canvas-ui-react'
import { useFragment } from 'react-relay'
import { graphql } from 'relay-runtime'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useAtom } from 'jotai'
import { editCommentAtom } from '../../state/atoms'
import { EditableComment_editable$key } from './__generated__/EditableComment_editable.graphql'
import { ControlledFormField } from '../../controlledComponents/ControlledFormField'
import { useGwtMutation } from '../../hooks'
import { EditableCommentMutation } from './__generated__/EditableCommentMutation.graphql'
import { usePublishGlobalNotification } from '../../hooks/useNotifications'

interface EditableCommentProps {
  commentRef: EditableComment_editable$key | null
  classNames: string
}

type Inputs = {
  edit: string
}

export const EditableComment = ({
  commentRef = null,
  classNames = '',
}: EditableCommentProps) => {
  const data = useFragment(
    graphql`
      fragment EditableComment_editable on Comment {
        rowId
        gwtUserId
        commentText
      }
    `,
    commentRef,
  )

  const formMethods = useForm({
    defaultValues: {
      edit: data?.commentText,
    },
  })

  const { publishToLocal } = usePublishGlobalNotification()

  const { handleSubmit, reset } = formMethods

  const [editingComment, setEditingComment] = useAtom(editCommentAtom)

  const { commit, isInFlight } = useCommentMutations(data?.rowId)

  const onSubmit: SubmitHandler<Inputs> = (inputData) => {
    if (inputData.edit.length === 0) {
      return
    }

    commit(
      {
        input: {
          patch: {
            commentText: inputData.edit,
          },
          rowId: data?.rowId,
        },
      },
      () => {
        publishToLocal('ASSIGNEE_MODULE_COMMENT_EDIT_NOTIFICATION')
        setEditingComment('')
        reset()
      },
    )
  }

  if (editingComment === data?.rowId) {
    return (
      <FormProvider {...formMethods}>
        <Form
          className="comment-reply-form hc-mt-md"
          onSubmit={handleSubmit(onSubmit)}
        >
          <Grid.Container spacing="none">
            <Grid.Item xs={12}>
              <ControlledFormField
                required
                name="edit"
                type="textarea"
                defaultValue={data.commentText}
              />
            </Grid.Item>
            <Grid.Item className="align-right" xs={12}>
              <Button
                size="dense"
                className="hc-mr-sm hc-pa-sm"
                type="submit"
                disabled={isInFlight}
              >
                Update
              </Button>
              <Button
                size="dense"
                className="hc-pa-sm"
                onClick={() => {
                  setEditingComment('')
                }}
              >
                Cancel
              </Button>
            </Grid.Item>
          </Grid.Container>
        </Form>
      </FormProvider>
    )
  }

  return <div className={classNames}>{data?.commentText}</div>
}

const useCommentMutations = (commentId: string) => {
  const { commit, isInFlight } = useGwtMutation<EditableCommentMutation>(
    graphql`
      mutation EditableCommentMutation($input: UpdateCommentInput!) {
        updateComment(input: $input) {
          comment {
            rowId
          }
        }
      }
    `,
  )

  return { commit, isInFlight }
}
