/**
 * @generated SignedSource<<d36f2cf9c5ecfc2b6b0a62d2cb5c9779>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type TemplatesTablePaginationFragment_modules$data = {
  readonly modules: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly attempts: number;
        readonly flow: {
          readonly createdAt: Date | null;
          readonly dueAt: Date | null;
          readonly durationSeconds: number;
          readonly lastModifiedBy: {
            readonly " $fragmentSpreads": FragmentRefs<"UserLink_user" | "UserModal_user">;
          } | null;
          readonly name: string;
          readonly owner: {
            readonly " $fragmentSpreads": FragmentRefs<"UserLink_user" | "UserModal_user">;
          } | null;
          readonly rowId: UUID;
          readonly updatedAt: Date | null;
        } | null;
        readonly versionNumber: BigFloat;
        readonly " $fragmentSpreads": FragmentRefs<"TemplateOptions_module">;
      };
    }>;
    readonly totalCount: number;
  } | null;
  readonly " $fragmentType": "TemplatesTablePaginationFragment_modules";
};
export type TemplatesTablePaginationFragment_modules$key = {
  readonly " $data"?: TemplatesTablePaginationFragment_modules$data;
  readonly " $fragmentSpreads": FragmentRefs<"TemplatesTablePaginationFragment_modules">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "modules"
],
v1 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "UserModal_user"
  },
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "UserLink_user"
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "gwtUserId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "moduleFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "modulesOrderBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./TemplatesTablePaginationQuery_Refetchable.graphql')
    }
  },
  "name": "TemplatesTablePaginationFragment_modules",
  "selections": [
    {
      "alias": "modules",
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "moduleFilter"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "modulesOrderBy"
        }
      ],
      "concreteType": "ModulesConnection",
      "kind": "LinkedField",
      "name": "__TemplatesTable_modules_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ModulesEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Module",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "gwtUserId",
                      "variableName": "gwtUserId"
                    }
                  ],
                  "kind": "FragmentSpread",
                  "name": "TemplateOptions_module"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "attempts",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "versionNumber",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Flow",
                  "kind": "LinkedField",
                  "name": "flow",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "durationSeconds",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "GwtUser",
                      "kind": "LinkedField",
                      "name": "owner",
                      "plural": false,
                      "selections": (v1/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "rowId",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "updatedAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "createdAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "dueAt",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "GwtUser",
                      "kind": "LinkedField",
                      "name": "lastModifiedBy",
                      "plural": false,
                      "selections": (v1/*: any*/),
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "a7b2c018eb556e788f788cf0c86d60c4";

export default node;
