/**
 * @generated SignedSource<<5e78f0821a6cce753b45decd9dd3076f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentCardFragment$data = {
  readonly comment: {
    readonly commentText: string;
    readonly commentsByResponseTo: {
      readonly nodes: ReadonlyArray<{
        readonly " $fragmentSpreads": FragmentRefs<"CommentCardResponseFragment">;
      }>;
    };
    readonly createdAt: Date | null;
    readonly gwtUser: {
      readonly " $fragmentSpreads": FragmentRefs<"UserAvatar_gwtUser">;
    } | null;
    readonly rowId: UUID;
    readonly " $fragmentSpreads": FragmentRefs<"CommentOptions_comment" | "EditableComment_editable">;
  } | null;
  readonly flowId: UUID;
  readonly " $fragmentSpreads": FragmentRefs<"CommentReplyFragment">;
  readonly " $fragmentType": "CommentCardFragment";
};
export type CommentCardFragment$key = {
  readonly " $data"?: CommentCardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentCardFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommentCardFragment",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommentReplyFragment"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "flowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Comment",
      "kind": "LinkedField",
      "name": "comment",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EditableComment_editable"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CommentOptions_comment"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rowId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "orderBy",
              "value": "CREATED_AT_ASC"
            }
          ],
          "concreteType": "CommentsConnection",
          "kind": "LinkedField",
          "name": "commentsByResponseTo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Comment",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "CommentCardResponseFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "commentsByResponseTo(orderBy:\"CREATED_AT_ASC\")"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "createdAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "commentText",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GwtUser",
          "kind": "LinkedField",
          "name": "gwtUser",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "UserAvatar_gwtUser"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "FlowComment",
  "abstractKey": null
};

(node as any).hash = "d397b6390029d7026e6617d9a2d88608";

export default node;
