/**
 * @generated SignedSource<<803da3ce215d9ff6bab95b9737574245>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AssignmentDueAtStatus = "DUE_SOON" | "NONE" | "OVERDUE" | "%future added value";
export type AssignmentStatus = "ACCEPTED" | "CANCELED" | "IN_PROGRESS" | "NONE" | "NOT_STARTED" | "READY_FOR_REVIEW" | "REJECTED" | "UNDER_REVIEW" | "%future added value";
export type FeedbackLevel = "CRITICAL" | "MAJOR" | "MINOR" | "SUGGESTION" | "%future added value";
export type FlowType = "BLUEPRINT" | "NONE" | "%future added value";
export type PolFileUploadStatus = "ERROR" | "NOT_STARTED" | "SCANNING" | "SUCCESS" | "WAITING_FOR_JOB" | "%future added value";
export type ProgramType = "ACCESSORIES_3_D_ACCREDITATION" | "A_A_3_D_ACCREDITATION" | "NONE" | "SOFT_HOME_3_D_ACCREDITATION" | "_3_D_STYLING" | "%future added value";
export type SoftwareType = "BROWZWEAR" | "CLO" | "NONE" | "OPTITEX" | "%future added value";
export type SubmissionStatus = "ACCEPTED" | "READY_FOR_REVIEW" | "REJECTED" | "%future added value";
export type UserStatus = "ACTIVE" | "DEACTIVATED" | "SUSPENDED" | "%future added value";
export type UserType = "ADMIN" | "BLOCKED" | "EXTERNAL_USER" | "INTERNAL_USER" | "REVIEWER" | "%future added value";
export type WorkflowType = "ACCREDITATION" | "%future added value";
export type UpdateGwtUserInput = {
  clientMutationId?: string | null;
  patch: GwtUserPatch;
  rowId: UUID;
};
export type GwtUserPatch = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email?: string | null;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName?: string | null;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName?: string | null;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId?: string | null;
  lastName?: string | null;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId?: UUID | null;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type GwtUserCompanyIdFkeyInput = {
  connectById?: CompanyNodeIdConnect | null;
  connectByRowId?: CompanyCompanyPkeyConnect | null;
  create?: GwtUserCompanyIdFkeyCompanyCreateInput | null;
  deleteById?: CompanyNodeIdDelete | null;
  deleteByRowId?: CompanyCompanyPkeyDelete | null;
  updateById?: GwtUserOnGwtUserForGwtUserCompanyIdFkeyNodeIdUpdate | null;
  updateByRowId?: CompanyOnGwtUserForGwtUserCompanyIdFkeyUsingCompanyPkeyUpdate | null;
};
export type CompanyCompanyPkeyConnect = {
  rowId: UUID;
};
export type CompanyNodeIdConnect = {
  id: string;
};
export type CompanyCompanyPkeyDelete = {
  rowId: UUID;
};
export type CompanyNodeIdDelete = {
  id: string;
};
export type CompanyOnGwtUserForGwtUserCompanyIdFkeyUsingCompanyPkeyUpdate = {
  patch: updateCompanyOnGwtUserForGwtUserCompanyIdFkeyPatch;
  rowId: UUID;
};
export type updateCompanyOnGwtUserForGwtUserCompanyIdFkeyPatch = {
  archivedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  gwtUserToOwnerId?: CompanyOwnerIdFkeyInput | null;
  gwtUsersUsingRowId?: GwtUserCompanyIdFkeyInverseInput | null;
  name?: string | null;
  ownerId?: UUID | null;
  rowId?: UUID | null;
  updatedAt?: Date | null;
};
export type CompanyOwnerIdFkeyInput = {
  connectById?: GwtUserNodeIdConnect | null;
  connectByRowId?: GwtUserGwtUserPkeyConnect | null;
  create?: CompanyOwnerIdFkeyGwtUserCreateInput | null;
  deleteById?: GwtUserNodeIdDelete | null;
  deleteByRowId?: GwtUserGwtUserPkeyDelete | null;
  updateById?: CompanyOnCompanyForCompanyOwnerIdFkeyNodeIdUpdate | null;
  updateByRowId?: GwtUserOnCompanyForCompanyOwnerIdFkeyUsingGwtUserPkeyUpdate | null;
};
export type GwtUserGwtUserPkeyConnect = {
  rowId: UUID;
};
export type GwtUserNodeIdConnect = {
  id: string;
};
export type GwtUserGwtUserPkeyDelete = {
  rowId: UUID;
};
export type GwtUserNodeIdDelete = {
  id: string;
};
export type GwtUserOnCompanyForCompanyOwnerIdFkeyUsingGwtUserPkeyUpdate = {
  patch: updateGwtUserOnCompanyForCompanyOwnerIdFkeyPatch;
  rowId: UUID;
};
export type updateGwtUserOnCompanyForCompanyOwnerIdFkeyPatch = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email?: string | null;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName?: string | null;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName?: string | null;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId?: string | null;
  lastName?: string | null;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId?: UUID | null;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type CommentGwtUserIdFkeyInverseInput = {
  connectById?: ReadonlyArray<CommentNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<CommentCommentPkeyConnect> | null;
  create?: ReadonlyArray<CommentGwtUserIdFkeyCommentCreateInput> | null;
  deleteById?: ReadonlyArray<CommentNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<CommentCommentPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<GwtUserOnCommentForCommentGwtUserIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<CommentOnCommentForCommentGwtUserIdFkeyUsingCommentPkeyUpdate> | null;
};
export type CommentCommentPkeyConnect = {
  rowId: UUID;
};
export type CommentNodeIdConnect = {
  id: string;
};
export type CommentCommentPkeyDelete = {
  rowId: UUID;
};
export type CommentNodeIdDelete = {
  id: string;
};
export type CommentOnCommentForCommentGwtUserIdFkeyUsingCommentPkeyUpdate = {
  patch: updateCommentOnCommentForCommentGwtUserIdFkeyPatch;
  rowId: UUID;
};
export type updateCommentOnCommentForCommentGwtUserIdFkeyPatch = {
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentCommentIdFkeyInverseInput | null;
  commentText?: string | null;
  commentToResponseTo?: CommentResponseToFkeyInput | null;
  commentViewedsUsingRowId?: CommentViewedCommentIdFkeyInverseInput | null;
  createdAt?: Date | null;
  flowCommentsUsingRowId?: FlowCommentCommentIdFkeyInverseInput | null;
  gwtUserToGwtUserId?: CommentGwtUserIdFkeyInput | null;
  responseTo?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentCommentIdFkeyInverseInput | null;
  rowId?: UUID | null;
  updatedAt?: Date | null;
};
export type CommentGwtUserIdFkeyInput = {
  connectById?: GwtUserNodeIdConnect | null;
  connectByRowId?: GwtUserGwtUserPkeyConnect | null;
  create?: CommentGwtUserIdFkeyGwtUserCreateInput | null;
  deleteById?: GwtUserNodeIdDelete | null;
  deleteByRowId?: GwtUserGwtUserPkeyDelete | null;
  updateById?: CommentOnCommentForCommentGwtUserIdFkeyNodeIdUpdate | null;
  updateByRowId?: GwtUserOnCommentForCommentGwtUserIdFkeyUsingGwtUserPkeyUpdate | null;
};
export type GwtUserOnCommentForCommentGwtUserIdFkeyUsingGwtUserPkeyUpdate = {
  patch: updateGwtUserOnCommentForCommentGwtUserIdFkeyPatch;
  rowId: UUID;
};
export type updateGwtUserOnCommentForCommentGwtUserIdFkeyPatch = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email?: string | null;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName?: string | null;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName?: string | null;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId?: string | null;
  lastName?: string | null;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId?: UUID | null;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type ActivityGwtUserIdFkeyInverseInput = {
  connectById?: ReadonlyArray<ActivityNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<ActivityActivityPkeyConnect> | null;
  create?: ReadonlyArray<ActivityGwtUserIdFkeyActivityCreateInput> | null;
  deleteById?: ReadonlyArray<ActivityNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<ActivityActivityPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<GwtUserOnActivityForActivityGwtUserIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<ActivityOnActivityForActivityGwtUserIdFkeyUsingActivityPkeyUpdate> | null;
};
export type ActivityActivityPkeyConnect = {
  rowId: UUID;
};
export type ActivityNodeIdConnect = {
  id: string;
};
export type ActivityActivityPkeyDelete = {
  rowId: UUID;
};
export type ActivityNodeIdDelete = {
  id: string;
};
export type ActivityOnActivityForActivityGwtUserIdFkeyUsingActivityPkeyUpdate = {
  patch: updateActivityOnActivityForActivityGwtUserIdFkeyPatch;
  rowId: UUID;
};
export type updateActivityOnActivityForActivityGwtUserIdFkeyPatch = {
  createdAt?: Date | null;
  flowId?: UUID | null;
  flowToFlowId?: ActivityFlowIdFkInput | null;
  gwtUserToGwtUserId?: ActivityGwtUserIdFkeyInput | null;
  linkLocation?: string | null;
  object?: string | null;
  rowId?: UUID | null;
  verb?: string | null;
  workflowType?: WorkflowType | null;
};
export type ActivityGwtUserIdFkeyInput = {
  connectById?: GwtUserNodeIdConnect | null;
  connectByRowId?: GwtUserGwtUserPkeyConnect | null;
  create?: ActivityGwtUserIdFkeyGwtUserCreateInput | null;
  deleteById?: GwtUserNodeIdDelete | null;
  deleteByRowId?: GwtUserGwtUserPkeyDelete | null;
  updateById?: ActivityOnActivityForActivityGwtUserIdFkeyNodeIdUpdate | null;
  updateByRowId?: GwtUserOnActivityForActivityGwtUserIdFkeyUsingGwtUserPkeyUpdate | null;
};
export type GwtUserOnActivityForActivityGwtUserIdFkeyUsingGwtUserPkeyUpdate = {
  patch: updateGwtUserOnActivityForActivityGwtUserIdFkeyPatch;
  rowId: UUID;
};
export type updateGwtUserOnActivityForActivityGwtUserIdFkeyPatch = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email?: string | null;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName?: string | null;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName?: string | null;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId?: string | null;
  lastName?: string | null;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId?: UUID | null;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type NotificationsGwtUserIdFkeyInverseInput = {
  connectById?: ReadonlyArray<NotificationNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<NotificationNotificationsPkeyConnect> | null;
  create?: ReadonlyArray<NotificationsGwtUserIdFkeyNotificationsCreateInput> | null;
  deleteById?: ReadonlyArray<NotificationNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<NotificationNotificationsPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<GwtUserOnNotificationForNotificationsGwtUserIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<NotificationOnNotificationForNotificationsGwtUserIdFkeyUsingNotificationsPkeyUpdate> | null;
};
export type NotificationNotificationsPkeyConnect = {
  rowId: UUID;
};
export type NotificationNodeIdConnect = {
  id: string;
};
export type NotificationNotificationsPkeyDelete = {
  rowId: UUID;
};
export type NotificationNodeIdDelete = {
  id: string;
};
export type NotificationOnNotificationForNotificationsGwtUserIdFkeyUsingNotificationsPkeyUpdate = {
  patch: updateNotificationOnNotificationForNotificationsGwtUserIdFkeyPatch;
  rowId: UUID;
};
export type updateNotificationOnNotificationForNotificationsGwtUserIdFkeyPatch = {
  data?: any | null;
  gwtUserToGwtUserId?: NotificationsGwtUserIdFkeyInput | null;
  name?: string | null;
  rowId?: UUID | null;
};
export type NotificationsGwtUserIdFkeyInput = {
  connectById?: GwtUserNodeIdConnect | null;
  connectByRowId?: GwtUserGwtUserPkeyConnect | null;
  create?: NotificationsGwtUserIdFkeyGwtUserCreateInput | null;
  deleteById?: GwtUserNodeIdDelete | null;
  deleteByRowId?: GwtUserGwtUserPkeyDelete | null;
  updateById?: NotificationOnNotificationForNotificationsGwtUserIdFkeyNodeIdUpdate | null;
  updateByRowId?: GwtUserOnNotificationForNotificationsGwtUserIdFkeyUsingGwtUserPkeyUpdate | null;
};
export type GwtUserOnNotificationForNotificationsGwtUserIdFkeyUsingGwtUserPkeyUpdate = {
  patch: updateGwtUserOnNotificationForNotificationsGwtUserIdFkeyPatch;
  rowId: UUID;
};
export type updateGwtUserOnNotificationForNotificationsGwtUserIdFkeyPatch = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email?: string | null;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName?: string | null;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName?: string | null;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId?: string | null;
  lastName?: string | null;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId?: UUID | null;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type FlowAssignedToIdFkeyInverseInput = {
  connectById?: ReadonlyArray<FlowNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<FlowFlowPkeyConnect> | null;
  create?: ReadonlyArray<FlowAssignedToIdFkeyFlowCreateInput> | null;
  deleteById?: ReadonlyArray<FlowNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<FlowFlowPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<GwtUserOnFlowForFlowAssignedToIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<FlowOnFlowForFlowAssignedToIdFkeyUsingFlowPkeyUpdate> | null;
};
export type FlowFlowPkeyConnect = {
  rowId: UUID;
};
export type FlowNodeIdConnect = {
  id: string;
};
export type FlowFlowPkeyDelete = {
  rowId: UUID;
};
export type FlowNodeIdDelete = {
  id: string;
};
export type FlowOnFlowForFlowAssignedToIdFkeyUsingFlowPkeyUpdate = {
  patch: updateFlowOnFlowForFlowAssignedToIdFkeyPatch;
  rowId: UUID;
};
export type updateFlowOnFlowForFlowAssignedToIdFkeyPatch = {
  activitiesUsingRowId?: ActivityFlowIdFkInverseInput | null;
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentFlowIdFkeyInverseInput | null;
  assignedAt?: Date | null;
  assignedById?: UUID | null;
  assignmentDueAtStatus?: AssignmentDueAtStatus | null;
  assignmentStatus?: AssignmentStatus | null;
  completedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  dueAt?: Date | null;
  durationSeconds?: number | null;
  flowCommentsUsingRowId?: FlowCommentFlowIdFkeyInverseInput | null;
  flowFavoritesUsingRowId?: FlowFavoriteFlowIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerFlowIdFkeyInverseInput | null;
  flowType?: FlowType | null;
  gwtUserToAssignedById?: FlowAssignedByIdFkeyInput | null;
  gwtUserToAssignedToId?: FlowAssignedToIdFkeyInput | null;
  gwtUserToLastModifiedById?: FlowLastModifiedByIdFkeyInput | null;
  gwtUserToOwnerId?: FlowOwnerIdFkeyInput | null;
  lastModifiedById?: UUID | null;
  moduleGroupUsingRowId?: ModuleGroupFlowIdFkeyInverseInput | null;
  moduleUsingRowId?: ModuleFlowIdFkeyInverseInput | null;
  name?: string | null;
  ownerId?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentFlowIdFkeyInverseInput | null;
  rowId?: UUID | null;
  startedAt?: Date | null;
  statusChangedAt?: Date | null;
  taskUsingRowId?: TaskFlowIdFkeyInverseInput | null;
  templateUsingRowId?: TemplateFlowIdFkeyInverseInput | null;
  updatedAt?: Date | null;
  workflowType?: WorkflowType | null;
};
export type FlowAssignedToIdFkeyInput = {
  connectById?: GwtUserNodeIdConnect | null;
  connectByRowId?: GwtUserGwtUserPkeyConnect | null;
  create?: FlowAssignedToIdFkeyGwtUserCreateInput | null;
  deleteById?: GwtUserNodeIdDelete | null;
  deleteByRowId?: GwtUserGwtUserPkeyDelete | null;
  updateById?: FlowOnFlowForFlowAssignedToIdFkeyNodeIdUpdate | null;
  updateByRowId?: GwtUserOnFlowForFlowAssignedToIdFkeyUsingGwtUserPkeyUpdate | null;
};
export type GwtUserOnFlowForFlowAssignedToIdFkeyUsingGwtUserPkeyUpdate = {
  patch: updateGwtUserOnFlowForFlowAssignedToIdFkeyPatch;
  rowId: UUID;
};
export type updateGwtUserOnFlowForFlowAssignedToIdFkeyPatch = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email?: string | null;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName?: string | null;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName?: string | null;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId?: string | null;
  lastName?: string | null;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId?: UUID | null;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type FlowAssignedByIdFkeyInverseInput = {
  connectById?: ReadonlyArray<FlowNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<FlowFlowPkeyConnect> | null;
  create?: ReadonlyArray<FlowAssignedByIdFkeyFlowCreateInput> | null;
  deleteById?: ReadonlyArray<FlowNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<FlowFlowPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<GwtUserOnFlowForFlowAssignedByIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<FlowOnFlowForFlowAssignedByIdFkeyUsingFlowPkeyUpdate> | null;
};
export type FlowOnFlowForFlowAssignedByIdFkeyUsingFlowPkeyUpdate = {
  patch: updateFlowOnFlowForFlowAssignedByIdFkeyPatch;
  rowId: UUID;
};
export type updateFlowOnFlowForFlowAssignedByIdFkeyPatch = {
  activitiesUsingRowId?: ActivityFlowIdFkInverseInput | null;
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentFlowIdFkeyInverseInput | null;
  assignedAt?: Date | null;
  assignedToId?: UUID | null;
  assignmentDueAtStatus?: AssignmentDueAtStatus | null;
  assignmentStatus?: AssignmentStatus | null;
  completedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  dueAt?: Date | null;
  durationSeconds?: number | null;
  flowCommentsUsingRowId?: FlowCommentFlowIdFkeyInverseInput | null;
  flowFavoritesUsingRowId?: FlowFavoriteFlowIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerFlowIdFkeyInverseInput | null;
  flowType?: FlowType | null;
  gwtUserToAssignedById?: FlowAssignedByIdFkeyInput | null;
  gwtUserToAssignedToId?: FlowAssignedToIdFkeyInput | null;
  gwtUserToLastModifiedById?: FlowLastModifiedByIdFkeyInput | null;
  gwtUserToOwnerId?: FlowOwnerIdFkeyInput | null;
  lastModifiedById?: UUID | null;
  moduleGroupUsingRowId?: ModuleGroupFlowIdFkeyInverseInput | null;
  moduleUsingRowId?: ModuleFlowIdFkeyInverseInput | null;
  name?: string | null;
  ownerId?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentFlowIdFkeyInverseInput | null;
  rowId?: UUID | null;
  startedAt?: Date | null;
  statusChangedAt?: Date | null;
  taskUsingRowId?: TaskFlowIdFkeyInverseInput | null;
  templateUsingRowId?: TemplateFlowIdFkeyInverseInput | null;
  updatedAt?: Date | null;
  workflowType?: WorkflowType | null;
};
export type FlowAssignedByIdFkeyInput = {
  connectById?: GwtUserNodeIdConnect | null;
  connectByRowId?: GwtUserGwtUserPkeyConnect | null;
  create?: FlowAssignedByIdFkeyGwtUserCreateInput | null;
  deleteById?: GwtUserNodeIdDelete | null;
  deleteByRowId?: GwtUserGwtUserPkeyDelete | null;
  updateById?: FlowOnFlowForFlowAssignedByIdFkeyNodeIdUpdate | null;
  updateByRowId?: GwtUserOnFlowForFlowAssignedByIdFkeyUsingGwtUserPkeyUpdate | null;
};
export type GwtUserOnFlowForFlowAssignedByIdFkeyUsingGwtUserPkeyUpdate = {
  patch: updateGwtUserOnFlowForFlowAssignedByIdFkeyPatch;
  rowId: UUID;
};
export type updateGwtUserOnFlowForFlowAssignedByIdFkeyPatch = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email?: string | null;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName?: string | null;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName?: string | null;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId?: string | null;
  lastName?: string | null;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId?: UUID | null;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type FlowOwnerIdFkeyInverseInput = {
  connectById?: ReadonlyArray<FlowNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<FlowFlowPkeyConnect> | null;
  create?: ReadonlyArray<FlowOwnerIdFkeyFlowCreateInput> | null;
  deleteById?: ReadonlyArray<FlowNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<FlowFlowPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<GwtUserOnFlowForFlowOwnerIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<FlowOnFlowForFlowOwnerIdFkeyUsingFlowPkeyUpdate> | null;
};
export type FlowOnFlowForFlowOwnerIdFkeyUsingFlowPkeyUpdate = {
  patch: updateFlowOnFlowForFlowOwnerIdFkeyPatch;
  rowId: UUID;
};
export type updateFlowOnFlowForFlowOwnerIdFkeyPatch = {
  activitiesUsingRowId?: ActivityFlowIdFkInverseInput | null;
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentFlowIdFkeyInverseInput | null;
  assignedAt?: Date | null;
  assignedById?: UUID | null;
  assignedToId?: UUID | null;
  assignmentDueAtStatus?: AssignmentDueAtStatus | null;
  assignmentStatus?: AssignmentStatus | null;
  completedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  dueAt?: Date | null;
  durationSeconds?: number | null;
  flowCommentsUsingRowId?: FlowCommentFlowIdFkeyInverseInput | null;
  flowFavoritesUsingRowId?: FlowFavoriteFlowIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerFlowIdFkeyInverseInput | null;
  flowType?: FlowType | null;
  gwtUserToAssignedById?: FlowAssignedByIdFkeyInput | null;
  gwtUserToAssignedToId?: FlowAssignedToIdFkeyInput | null;
  gwtUserToLastModifiedById?: FlowLastModifiedByIdFkeyInput | null;
  gwtUserToOwnerId?: FlowOwnerIdFkeyInput | null;
  lastModifiedById?: UUID | null;
  moduleGroupUsingRowId?: ModuleGroupFlowIdFkeyInverseInput | null;
  moduleUsingRowId?: ModuleFlowIdFkeyInverseInput | null;
  name?: string | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentFlowIdFkeyInverseInput | null;
  rowId?: UUID | null;
  startedAt?: Date | null;
  statusChangedAt?: Date | null;
  taskUsingRowId?: TaskFlowIdFkeyInverseInput | null;
  templateUsingRowId?: TemplateFlowIdFkeyInverseInput | null;
  updatedAt?: Date | null;
  workflowType?: WorkflowType | null;
};
export type FlowOwnerIdFkeyInput = {
  connectById?: GwtUserNodeIdConnect | null;
  connectByRowId?: GwtUserGwtUserPkeyConnect | null;
  create?: FlowOwnerIdFkeyGwtUserCreateInput | null;
  deleteById?: GwtUserNodeIdDelete | null;
  deleteByRowId?: GwtUserGwtUserPkeyDelete | null;
  updateById?: FlowOnFlowForFlowOwnerIdFkeyNodeIdUpdate | null;
  updateByRowId?: GwtUserOnFlowForFlowOwnerIdFkeyUsingGwtUserPkeyUpdate | null;
};
export type GwtUserOnFlowForFlowOwnerIdFkeyUsingGwtUserPkeyUpdate = {
  patch: updateGwtUserOnFlowForFlowOwnerIdFkeyPatch;
  rowId: UUID;
};
export type updateGwtUserOnFlowForFlowOwnerIdFkeyPatch = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email?: string | null;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName?: string | null;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName?: string | null;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId?: string | null;
  lastName?: string | null;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId?: UUID | null;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type FlowLastModifiedByIdFkeyInverseInput = {
  connectById?: ReadonlyArray<FlowNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<FlowFlowPkeyConnect> | null;
  create?: ReadonlyArray<FlowLastModifiedByIdFkeyFlowCreateInput> | null;
  deleteById?: ReadonlyArray<FlowNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<FlowFlowPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<GwtUserOnFlowForFlowLastModifiedByIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<FlowOnFlowForFlowLastModifiedByIdFkeyUsingFlowPkeyUpdate> | null;
};
export type FlowOnFlowForFlowLastModifiedByIdFkeyUsingFlowPkeyUpdate = {
  patch: updateFlowOnFlowForFlowLastModifiedByIdFkeyPatch;
  rowId: UUID;
};
export type updateFlowOnFlowForFlowLastModifiedByIdFkeyPatch = {
  activitiesUsingRowId?: ActivityFlowIdFkInverseInput | null;
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentFlowIdFkeyInverseInput | null;
  assignedAt?: Date | null;
  assignedById?: UUID | null;
  assignedToId?: UUID | null;
  assignmentDueAtStatus?: AssignmentDueAtStatus | null;
  assignmentStatus?: AssignmentStatus | null;
  completedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  dueAt?: Date | null;
  durationSeconds?: number | null;
  flowCommentsUsingRowId?: FlowCommentFlowIdFkeyInverseInput | null;
  flowFavoritesUsingRowId?: FlowFavoriteFlowIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerFlowIdFkeyInverseInput | null;
  flowType?: FlowType | null;
  gwtUserToAssignedById?: FlowAssignedByIdFkeyInput | null;
  gwtUserToAssignedToId?: FlowAssignedToIdFkeyInput | null;
  gwtUserToLastModifiedById?: FlowLastModifiedByIdFkeyInput | null;
  gwtUserToOwnerId?: FlowOwnerIdFkeyInput | null;
  moduleGroupUsingRowId?: ModuleGroupFlowIdFkeyInverseInput | null;
  moduleUsingRowId?: ModuleFlowIdFkeyInverseInput | null;
  name?: string | null;
  ownerId?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentFlowIdFkeyInverseInput | null;
  rowId?: UUID | null;
  startedAt?: Date | null;
  statusChangedAt?: Date | null;
  taskUsingRowId?: TaskFlowIdFkeyInverseInput | null;
  templateUsingRowId?: TemplateFlowIdFkeyInverseInput | null;
  updatedAt?: Date | null;
  workflowType?: WorkflowType | null;
};
export type FlowLastModifiedByIdFkeyInput = {
  connectById?: GwtUserNodeIdConnect | null;
  connectByRowId?: GwtUserGwtUserPkeyConnect | null;
  create?: FlowLastModifiedByIdFkeyGwtUserCreateInput | null;
  deleteById?: GwtUserNodeIdDelete | null;
  deleteByRowId?: GwtUserGwtUserPkeyDelete | null;
  updateById?: FlowOnFlowForFlowLastModifiedByIdFkeyNodeIdUpdate | null;
  updateByRowId?: GwtUserOnFlowForFlowLastModifiedByIdFkeyUsingGwtUserPkeyUpdate | null;
};
export type GwtUserOnFlowForFlowLastModifiedByIdFkeyUsingGwtUserPkeyUpdate = {
  patch: updateGwtUserOnFlowForFlowLastModifiedByIdFkeyPatch;
  rowId: UUID;
};
export type updateGwtUserOnFlowForFlowLastModifiedByIdFkeyPatch = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email?: string | null;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName?: string | null;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName?: string | null;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId?: string | null;
  lastName?: string | null;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId?: UUID | null;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type ModuleSubmissionAcceptedByIdFkeyInverseInput = {
  connectById?: ReadonlyArray<ModuleSubmissionNodeIdConnect> | null;
  connectByModuleFlowIdAndGalleryAssetSubmissionId?: ReadonlyArray<ModuleSubmissionModuleSubmissionPkeyConnect> | null;
  create?: ReadonlyArray<ModuleSubmissionAcceptedByIdFkeyModuleSubmissionCreateInput> | null;
  deleteById?: ReadonlyArray<ModuleSubmissionNodeIdDelete> | null;
  deleteByModuleFlowIdAndGalleryAssetSubmissionId?: ReadonlyArray<ModuleSubmissionModuleSubmissionPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<GwtUserOnModuleSubmissionForModuleSubmissionAcceptedByIdFkeyNodeIdUpdate> | null;
  updateByModuleFlowIdAndGalleryAssetSubmissionId?: ReadonlyArray<ModuleSubmissionOnModuleSubmissionForModuleSubmissionAcceptedByIdFkeyUsingModuleSubmissionPkeyUpdate> | null;
};
export type ModuleSubmissionModuleSubmissionPkeyConnect = {
  galleryAssetSubmissionId: UUID;
  moduleFlowId: UUID;
};
export type ModuleSubmissionNodeIdConnect = {
  id: string;
};
export type ModuleSubmissionModuleSubmissionPkeyDelete = {
  galleryAssetSubmissionId: UUID;
  moduleFlowId: UUID;
};
export type ModuleSubmissionNodeIdDelete = {
  id: string;
};
export type ModuleSubmissionOnModuleSubmissionForModuleSubmissionAcceptedByIdFkeyUsingModuleSubmissionPkeyUpdate = {
  galleryAssetSubmissionId: UUID;
  moduleFlowId: UUID;
  patch: updateModuleSubmissionOnModuleSubmissionForModuleSubmissionAcceptedByIdFkeyPatch;
};
export type updateModuleSubmissionOnModuleSubmissionForModuleSubmissionAcceptedByIdFkeyPatch = {
  attempt?: number | null;
  createdAt?: Date | null;
  galleryAssetSubmissionId?: UUID | null;
  gwtUserToAcceptedById?: ModuleSubmissionAcceptedByIdFkeyInput | null;
  moduleFlowId?: UUID | null;
  moduleToModuleFlowId?: ModuleSubmissionModuleFlowIdFkeyInput | null;
  submissionStatus?: SubmissionStatus | null;
};
export type ModuleSubmissionModuleFlowIdFkeyInput = {
  connectByFlowId?: ModuleModulePkeyConnect | null;
  connectById?: ModuleNodeIdConnect | null;
  create?: ModuleSubmissionModuleFlowIdFkeyModuleCreateInput | null;
  deleteByFlowId?: ModuleModulePkeyDelete | null;
  deleteById?: ModuleNodeIdDelete | null;
  update?: FlowPatch | null;
  updateByFlowId?: ModuleOnModuleSubmissionForModuleSubmissionModuleFlowIdFkeyUsingModulePkeyUpdate | null;
  updateById?: ModuleSubmissionOnModuleSubmissionForModuleSubmissionModuleFlowIdFkeyNodeIdUpdate | null;
};
export type ModuleModulePkeyConnect = {
  flowId: UUID;
};
export type ModuleNodeIdConnect = {
  id: string;
};
export type ModuleModulePkeyDelete = {
  flowId: UUID;
};
export type ModuleNodeIdDelete = {
  id: string;
};
export type ModuleOnModuleSubmissionForModuleSubmissionModuleFlowIdFkeyUsingModulePkeyUpdate = {
  flowId: UUID;
  patch: updateModuleOnModuleSubmissionForModuleSubmissionModuleFlowIdFkeyPatch;
};
export type updateModuleOnModuleSubmissionForModuleSubmissionModuleFlowIdFkeyPatch = {
  attempts?: number | null;
  autoStartNextModuleFlowId?: UUID | null;
  currentAttempt?: number | null;
  flowId?: UUID | null;
  flowToFlowId?: ModuleFlowIdFkeyInput | null;
  galleryAssetComponentsId?: UUID | null;
  galleryAssetDeliverableId?: UUID | null;
  galleryCollectionSubmissionsId?: UUID | null;
  groupModulePreviousModuleFlowId?: UUID | null;
  moduleGroupId?: UUID | null;
  moduleGroupLinksUsingFlowId?: ModuleGroupLinksModuleFlowIdFkeyInverseInput | null;
  moduleGroupToModuleGroupId?: ModuleModuleGroupIdFkeyInput | null;
  moduleSubmissionsUsingFlowId?: ModuleSubmissionModuleFlowIdFkeyInverseInput | null;
  moduleToAutoStartNextModuleFlowId?: ModuleAutoStartNextModuleFlowIdFkeyInput | null;
  moduleToGroupModulePreviousModuleFlowId?: ModuleGroupModulePreviousModuleFlowIdFkeyInput | null;
  programType?: ProgramType | null;
  softwareType?: SoftwareType | null;
  submissionLock?: boolean | null;
  tasksUsingFlowId?: TaskModuleFlowIdFkeyInverseInput | null;
  templateFlowId?: UUID | null;
  templateToTemplateFlowId?: ModuleTemplateFlowIdFkeyInput | null;
  versionNumber?: BigFloat | null;
};
export type ModuleFlowIdFkeyInput = {
  connectById?: FlowNodeIdConnect | null;
  connectByRowId?: FlowFlowPkeyConnect | null;
  create?: ModuleFlowIdFkeyFlowCreateInput | null;
  deleteById?: FlowNodeIdDelete | null;
  deleteByRowId?: FlowFlowPkeyDelete | null;
  update?: FlowPatch | null;
  updateById?: ModuleOnModuleForModuleFlowIdFkeyNodeIdUpdate | null;
  updateByRowId?: FlowOnModuleForModuleFlowIdFkeyUsingFlowPkeyUpdate | null;
};
export type FlowOnModuleForModuleFlowIdFkeyUsingFlowPkeyUpdate = {
  patch: updateFlowOnModuleForModuleFlowIdFkeyPatch;
  rowId: UUID;
};
export type updateFlowOnModuleForModuleFlowIdFkeyPatch = {
  activitiesUsingRowId?: ActivityFlowIdFkInverseInput | null;
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentFlowIdFkeyInverseInput | null;
  assignedAt?: Date | null;
  assignedById?: UUID | null;
  assignedToId?: UUID | null;
  assignmentDueAtStatus?: AssignmentDueAtStatus | null;
  assignmentStatus?: AssignmentStatus | null;
  completedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  dueAt?: Date | null;
  durationSeconds?: number | null;
  flowCommentsUsingRowId?: FlowCommentFlowIdFkeyInverseInput | null;
  flowFavoritesUsingRowId?: FlowFavoriteFlowIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerFlowIdFkeyInverseInput | null;
  flowType?: FlowType | null;
  gwtUserToAssignedById?: FlowAssignedByIdFkeyInput | null;
  gwtUserToAssignedToId?: FlowAssignedToIdFkeyInput | null;
  gwtUserToLastModifiedById?: FlowLastModifiedByIdFkeyInput | null;
  gwtUserToOwnerId?: FlowOwnerIdFkeyInput | null;
  lastModifiedById?: UUID | null;
  moduleGroupUsingRowId?: ModuleGroupFlowIdFkeyInverseInput | null;
  moduleUsingRowId?: ModuleFlowIdFkeyInverseInput | null;
  name?: string | null;
  ownerId?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentFlowIdFkeyInverseInput | null;
  rowId?: UUID | null;
  startedAt?: Date | null;
  statusChangedAt?: Date | null;
  taskUsingRowId?: TaskFlowIdFkeyInverseInput | null;
  templateUsingRowId?: TemplateFlowIdFkeyInverseInput | null;
  updatedAt?: Date | null;
  workflowType?: WorkflowType | null;
};
export type ActivityFlowIdFkInverseInput = {
  connectById?: ReadonlyArray<ActivityNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<ActivityActivityPkeyConnect> | null;
  create?: ReadonlyArray<ActivityFlowIdFkActivityCreateInput> | null;
  deleteById?: ReadonlyArray<ActivityNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<ActivityActivityPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<FlowOnActivityForActivityFlowIdFkNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<ActivityOnActivityForActivityFlowIdFkUsingActivityPkeyUpdate> | null;
};
export type ActivityOnActivityForActivityFlowIdFkUsingActivityPkeyUpdate = {
  patch: updateActivityOnActivityForActivityFlowIdFkPatch;
  rowId: UUID;
};
export type updateActivityOnActivityForActivityFlowIdFkPatch = {
  createdAt?: Date | null;
  flowToFlowId?: ActivityFlowIdFkInput | null;
  gwtUserId?: UUID | null;
  gwtUserToGwtUserId?: ActivityGwtUserIdFkeyInput | null;
  linkLocation?: string | null;
  object?: string | null;
  rowId?: UUID | null;
  verb?: string | null;
  workflowType?: WorkflowType | null;
};
export type ActivityFlowIdFkInput = {
  connectById?: FlowNodeIdConnect | null;
  connectByRowId?: FlowFlowPkeyConnect | null;
  create?: ActivityFlowIdFkFlowCreateInput | null;
  deleteById?: FlowNodeIdDelete | null;
  deleteByRowId?: FlowFlowPkeyDelete | null;
  updateById?: ActivityOnActivityForActivityFlowIdFkNodeIdUpdate | null;
  updateByRowId?: FlowOnActivityForActivityFlowIdFkUsingFlowPkeyUpdate | null;
};
export type FlowOnActivityForActivityFlowIdFkUsingFlowPkeyUpdate = {
  patch: updateFlowOnActivityForActivityFlowIdFkPatch;
  rowId: UUID;
};
export type updateFlowOnActivityForActivityFlowIdFkPatch = {
  activitiesUsingRowId?: ActivityFlowIdFkInverseInput | null;
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentFlowIdFkeyInverseInput | null;
  assignedAt?: Date | null;
  assignedById?: UUID | null;
  assignedToId?: UUID | null;
  assignmentDueAtStatus?: AssignmentDueAtStatus | null;
  assignmentStatus?: AssignmentStatus | null;
  completedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  dueAt?: Date | null;
  durationSeconds?: number | null;
  flowCommentsUsingRowId?: FlowCommentFlowIdFkeyInverseInput | null;
  flowFavoritesUsingRowId?: FlowFavoriteFlowIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerFlowIdFkeyInverseInput | null;
  flowType?: FlowType | null;
  gwtUserToAssignedById?: FlowAssignedByIdFkeyInput | null;
  gwtUserToAssignedToId?: FlowAssignedToIdFkeyInput | null;
  gwtUserToLastModifiedById?: FlowLastModifiedByIdFkeyInput | null;
  gwtUserToOwnerId?: FlowOwnerIdFkeyInput | null;
  lastModifiedById?: UUID | null;
  moduleGroupUsingRowId?: ModuleGroupFlowIdFkeyInverseInput | null;
  moduleUsingRowId?: ModuleFlowIdFkeyInverseInput | null;
  name?: string | null;
  ownerId?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentFlowIdFkeyInverseInput | null;
  rowId?: UUID | null;
  startedAt?: Date | null;
  statusChangedAt?: Date | null;
  taskUsingRowId?: TaskFlowIdFkeyInverseInput | null;
  templateUsingRowId?: TemplateFlowIdFkeyInverseInput | null;
  updatedAt?: Date | null;
  workflowType?: WorkflowType | null;
};
export type TemplateFlowIdFkeyInverseInput = {
  connectByFlowId?: TemplateTemplatePkeyConnect | null;
  connectById?: TemplateNodeIdConnect | null;
  create?: ReadonlyArray<TemplateFlowIdFkeyTemplateCreateInput> | null;
  deleteByFlowId?: TemplateTemplatePkeyDelete | null;
  deleteById?: TemplateNodeIdDelete | null;
  deleteOthers?: boolean | null;
  updateByFlowId?: TemplateOnTemplateForTemplateFlowIdFkeyUsingTemplatePkeyUpdate | null;
  updateById?: FlowOnTemplateForTemplateFlowIdFkeyNodeIdUpdate | null;
};
export type TemplateTemplatePkeyConnect = {
  flowId: UUID;
};
export type TemplateNodeIdConnect = {
  id: string;
};
export type TemplateTemplatePkeyDelete = {
  flowId: UUID;
};
export type TemplateNodeIdDelete = {
  id: string;
};
export type TemplateOnTemplateForTemplateFlowIdFkeyUsingTemplatePkeyUpdate = {
  flowId: UUID;
  patch: updateTemplateOnTemplateForTemplateFlowIdFkeyPatch;
};
export type updateTemplateOnTemplateForTemplateFlowIdFkeyPatch = {
  flowToFlowId?: TemplateFlowIdFkeyInput | null;
  modulesUsingFlowId?: ModuleTemplateFlowIdFkeyInverseInput | null;
  tasksUsingFlowId?: TaskTemplateFlowIdFkeyInverseInput | null;
};
export type TemplateFlowIdFkeyInput = {
  connectById?: FlowNodeIdConnect | null;
  connectByRowId?: FlowFlowPkeyConnect | null;
  create?: TemplateFlowIdFkeyFlowCreateInput | null;
  deleteById?: FlowNodeIdDelete | null;
  deleteByRowId?: FlowFlowPkeyDelete | null;
  update?: FlowPatch | null;
  updateById?: TemplateOnTemplateForTemplateFlowIdFkeyNodeIdUpdate | null;
  updateByRowId?: FlowOnTemplateForTemplateFlowIdFkeyUsingFlowPkeyUpdate | null;
};
export type FlowOnTemplateForTemplateFlowIdFkeyUsingFlowPkeyUpdate = {
  patch: updateFlowOnTemplateForTemplateFlowIdFkeyPatch;
  rowId: UUID;
};
export type updateFlowOnTemplateForTemplateFlowIdFkeyPatch = {
  activitiesUsingRowId?: ActivityFlowIdFkInverseInput | null;
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentFlowIdFkeyInverseInput | null;
  assignedAt?: Date | null;
  assignedById?: UUID | null;
  assignedToId?: UUID | null;
  assignmentDueAtStatus?: AssignmentDueAtStatus | null;
  assignmentStatus?: AssignmentStatus | null;
  completedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  dueAt?: Date | null;
  durationSeconds?: number | null;
  flowCommentsUsingRowId?: FlowCommentFlowIdFkeyInverseInput | null;
  flowFavoritesUsingRowId?: FlowFavoriteFlowIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerFlowIdFkeyInverseInput | null;
  flowType?: FlowType | null;
  gwtUserToAssignedById?: FlowAssignedByIdFkeyInput | null;
  gwtUserToAssignedToId?: FlowAssignedToIdFkeyInput | null;
  gwtUserToLastModifiedById?: FlowLastModifiedByIdFkeyInput | null;
  gwtUserToOwnerId?: FlowOwnerIdFkeyInput | null;
  lastModifiedById?: UUID | null;
  moduleGroupUsingRowId?: ModuleGroupFlowIdFkeyInverseInput | null;
  moduleUsingRowId?: ModuleFlowIdFkeyInverseInput | null;
  name?: string | null;
  ownerId?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentFlowIdFkeyInverseInput | null;
  rowId?: UUID | null;
  startedAt?: Date | null;
  statusChangedAt?: Date | null;
  taskUsingRowId?: TaskFlowIdFkeyInverseInput | null;
  templateUsingRowId?: TemplateFlowIdFkeyInverseInput | null;
  updatedAt?: Date | null;
  workflowType?: WorkflowType | null;
};
export type ModuleFlowIdFkeyInverseInput = {
  connectByFlowId?: ModuleModulePkeyConnect | null;
  connectById?: ModuleNodeIdConnect | null;
  create?: ReadonlyArray<ModuleFlowIdFkeyModuleCreateInput> | null;
  deleteByFlowId?: ModuleModulePkeyDelete | null;
  deleteById?: ModuleNodeIdDelete | null;
  deleteOthers?: boolean | null;
  updateByFlowId?: ModuleOnModuleForModuleFlowIdFkeyUsingModulePkeyUpdate | null;
  updateById?: FlowOnModuleForModuleFlowIdFkeyNodeIdUpdate | null;
};
export type ModuleOnModuleForModuleFlowIdFkeyUsingModulePkeyUpdate = {
  flowId: UUID;
  patch: updateModuleOnModuleForModuleFlowIdFkeyPatch;
};
export type updateModuleOnModuleForModuleFlowIdFkeyPatch = {
  attempts?: number | null;
  autoStartNextModuleFlowId?: UUID | null;
  currentAttempt?: number | null;
  flowToFlowId?: ModuleFlowIdFkeyInput | null;
  galleryAssetComponentsId?: UUID | null;
  galleryAssetDeliverableId?: UUID | null;
  galleryCollectionSubmissionsId?: UUID | null;
  groupModulePreviousModuleFlowId?: UUID | null;
  moduleGroupId?: UUID | null;
  moduleGroupLinksUsingFlowId?: ModuleGroupLinksModuleFlowIdFkeyInverseInput | null;
  moduleGroupToModuleGroupId?: ModuleModuleGroupIdFkeyInput | null;
  moduleSubmissionsUsingFlowId?: ModuleSubmissionModuleFlowIdFkeyInverseInput | null;
  moduleToAutoStartNextModuleFlowId?: ModuleAutoStartNextModuleFlowIdFkeyInput | null;
  moduleToGroupModulePreviousModuleFlowId?: ModuleGroupModulePreviousModuleFlowIdFkeyInput | null;
  programType?: ProgramType | null;
  softwareType?: SoftwareType | null;
  submissionLock?: boolean | null;
  tasksUsingFlowId?: TaskModuleFlowIdFkeyInverseInput | null;
  templateFlowId?: UUID | null;
  templateToTemplateFlowId?: ModuleTemplateFlowIdFkeyInput | null;
  versionNumber?: BigFloat | null;
};
export type ModuleTemplateFlowIdFkeyInput = {
  connectByFlowId?: TemplateTemplatePkeyConnect | null;
  connectById?: TemplateNodeIdConnect | null;
  create?: ModuleTemplateFlowIdFkeyTemplateCreateInput | null;
  deleteByFlowId?: TemplateTemplatePkeyDelete | null;
  deleteById?: TemplateNodeIdDelete | null;
  update?: FlowPatch | null;
  updateByFlowId?: TemplateOnModuleForModuleTemplateFlowIdFkeyUsingTemplatePkeyUpdate | null;
  updateById?: ModuleOnModuleForModuleTemplateFlowIdFkeyNodeIdUpdate | null;
};
export type TemplateOnModuleForModuleTemplateFlowIdFkeyUsingTemplatePkeyUpdate = {
  flowId: UUID;
  patch: updateTemplateOnModuleForModuleTemplateFlowIdFkeyPatch;
};
export type updateTemplateOnModuleForModuleTemplateFlowIdFkeyPatch = {
  flowId?: UUID | null;
  flowToFlowId?: TemplateFlowIdFkeyInput | null;
  modulesUsingFlowId?: ModuleTemplateFlowIdFkeyInverseInput | null;
  tasksUsingFlowId?: TaskTemplateFlowIdFkeyInverseInput | null;
};
export type ModuleTemplateFlowIdFkeyInverseInput = {
  connectByFlowId?: ReadonlyArray<ModuleModulePkeyConnect> | null;
  connectById?: ReadonlyArray<ModuleNodeIdConnect> | null;
  create?: ReadonlyArray<ModuleTemplateFlowIdFkeyModuleCreateInput> | null;
  deleteByFlowId?: ReadonlyArray<ModuleModulePkeyDelete> | null;
  deleteById?: ReadonlyArray<ModuleNodeIdDelete> | null;
  deleteOthers?: boolean | null;
  updateByFlowId?: ReadonlyArray<ModuleOnModuleForModuleTemplateFlowIdFkeyUsingModulePkeyUpdate> | null;
  updateById?: ReadonlyArray<TemplateOnModuleForModuleTemplateFlowIdFkeyNodeIdUpdate> | null;
};
export type ModuleOnModuleForModuleTemplateFlowIdFkeyUsingModulePkeyUpdate = {
  flowId: UUID;
  patch: updateModuleOnModuleForModuleTemplateFlowIdFkeyPatch;
};
export type updateModuleOnModuleForModuleTemplateFlowIdFkeyPatch = {
  attempts?: number | null;
  autoStartNextModuleFlowId?: UUID | null;
  currentAttempt?: number | null;
  flowId?: UUID | null;
  flowToFlowId?: ModuleFlowIdFkeyInput | null;
  galleryAssetComponentsId?: UUID | null;
  galleryAssetDeliverableId?: UUID | null;
  galleryCollectionSubmissionsId?: UUID | null;
  groupModulePreviousModuleFlowId?: UUID | null;
  moduleGroupId?: UUID | null;
  moduleGroupLinksUsingFlowId?: ModuleGroupLinksModuleFlowIdFkeyInverseInput | null;
  moduleGroupToModuleGroupId?: ModuleModuleGroupIdFkeyInput | null;
  moduleSubmissionsUsingFlowId?: ModuleSubmissionModuleFlowIdFkeyInverseInput | null;
  moduleToAutoStartNextModuleFlowId?: ModuleAutoStartNextModuleFlowIdFkeyInput | null;
  moduleToGroupModulePreviousModuleFlowId?: ModuleGroupModulePreviousModuleFlowIdFkeyInput | null;
  programType?: ProgramType | null;
  softwareType?: SoftwareType | null;
  submissionLock?: boolean | null;
  tasksUsingFlowId?: TaskModuleFlowIdFkeyInverseInput | null;
  templateToTemplateFlowId?: ModuleTemplateFlowIdFkeyInput | null;
  versionNumber?: BigFloat | null;
};
export type ModuleAutoStartNextModuleFlowIdFkeyInput = {
  connectByFlowId?: ModuleModulePkeyConnect | null;
  connectById?: ModuleNodeIdConnect | null;
  create?: ModuleAutoStartNextModuleFlowIdFkeyModuleCreateInput | null;
  deleteByFlowId?: ModuleModulePkeyDelete | null;
  deleteById?: ModuleNodeIdDelete | null;
  update?: FlowPatch | null;
  updateByFlowId?: ModuleOnModuleForModuleAutoStartNextModuleFlowIdFkeyUsingModulePkeyUpdate | null;
  updateById?: ModuleOnModuleForModuleAutoStartNextModuleFlowIdFkeyNodeIdUpdate | null;
};
export type ModuleOnModuleForModuleAutoStartNextModuleFlowIdFkeyUsingModulePkeyUpdate = {
  flowId: UUID;
  patch: updateModuleOnModuleForModuleAutoStartNextModuleFlowIdFkeyPatch;
};
export type updateModuleOnModuleForModuleAutoStartNextModuleFlowIdFkeyPatch = {
  attempts?: number | null;
  currentAttempt?: number | null;
  flowId?: UUID | null;
  flowToFlowId?: ModuleFlowIdFkeyInput | null;
  galleryAssetComponentsId?: UUID | null;
  galleryAssetDeliverableId?: UUID | null;
  galleryCollectionSubmissionsId?: UUID | null;
  groupModulePreviousModuleFlowId?: UUID | null;
  moduleGroupId?: UUID | null;
  moduleGroupLinksUsingFlowId?: ModuleGroupLinksModuleFlowIdFkeyInverseInput | null;
  moduleGroupToModuleGroupId?: ModuleModuleGroupIdFkeyInput | null;
  moduleSubmissionsUsingFlowId?: ModuleSubmissionModuleFlowIdFkeyInverseInput | null;
  moduleToAutoStartNextModuleFlowId?: ModuleAutoStartNextModuleFlowIdFkeyInput | null;
  moduleToGroupModulePreviousModuleFlowId?: ModuleGroupModulePreviousModuleFlowIdFkeyInput | null;
  programType?: ProgramType | null;
  softwareType?: SoftwareType | null;
  submissionLock?: boolean | null;
  tasksUsingFlowId?: TaskModuleFlowIdFkeyInverseInput | null;
  templateFlowId?: UUID | null;
  templateToTemplateFlowId?: ModuleTemplateFlowIdFkeyInput | null;
  versionNumber?: BigFloat | null;
};
export type ModuleGroupModulePreviousModuleFlowIdFkeyInput = {
  connectByFlowId?: ModuleModulePkeyConnect | null;
  connectById?: ModuleNodeIdConnect | null;
  create?: ModuleGroupModulePreviousModuleFlowIdFkeyModuleCreateInput | null;
  deleteByFlowId?: ModuleModulePkeyDelete | null;
  deleteById?: ModuleNodeIdDelete | null;
  update?: FlowPatch | null;
  updateByFlowId?: ModuleOnModuleForModuleGroupModulePreviousModuleFlowIdFkeyUsingModulePkeyUpdate | null;
  updateById?: ModuleOnModuleForModuleGroupModulePreviousModuleFlowIdFkeyNodeIdUpdate | null;
};
export type ModuleOnModuleForModuleGroupModulePreviousModuleFlowIdFkeyUsingModulePkeyUpdate = {
  flowId: UUID;
  patch: updateModuleOnModuleForModuleGroupModulePreviousModuleFlowIdFkeyPatch;
};
export type updateModuleOnModuleForModuleGroupModulePreviousModuleFlowIdFkeyPatch = {
  attempts?: number | null;
  autoStartNextModuleFlowId?: UUID | null;
  currentAttempt?: number | null;
  flowId?: UUID | null;
  flowToFlowId?: ModuleFlowIdFkeyInput | null;
  galleryAssetComponentsId?: UUID | null;
  galleryAssetDeliverableId?: UUID | null;
  galleryCollectionSubmissionsId?: UUID | null;
  moduleGroupId?: UUID | null;
  moduleGroupLinksUsingFlowId?: ModuleGroupLinksModuleFlowIdFkeyInverseInput | null;
  moduleGroupToModuleGroupId?: ModuleModuleGroupIdFkeyInput | null;
  moduleSubmissionsUsingFlowId?: ModuleSubmissionModuleFlowIdFkeyInverseInput | null;
  moduleToAutoStartNextModuleFlowId?: ModuleAutoStartNextModuleFlowIdFkeyInput | null;
  moduleToGroupModulePreviousModuleFlowId?: ModuleGroupModulePreviousModuleFlowIdFkeyInput | null;
  programType?: ProgramType | null;
  softwareType?: SoftwareType | null;
  submissionLock?: boolean | null;
  tasksUsingFlowId?: TaskModuleFlowIdFkeyInverseInput | null;
  templateFlowId?: UUID | null;
  templateToTemplateFlowId?: ModuleTemplateFlowIdFkeyInput | null;
  versionNumber?: BigFloat | null;
};
export type ModuleModuleGroupIdFkeyInput = {
  connectByFlowId?: ModuleGroupModuleGroupPkeyConnect | null;
  connectById?: ModuleGroupNodeIdConnect | null;
  create?: ModuleModuleGroupIdFkeyModuleGroupCreateInput | null;
  deleteByFlowId?: ModuleGroupModuleGroupPkeyDelete | null;
  deleteById?: ModuleGroupNodeIdDelete | null;
  updateByFlowId?: ModuleGroupOnModuleForModuleModuleGroupIdFkeyUsingModuleGroupPkeyUpdate | null;
  updateById?: ModuleOnModuleForModuleModuleGroupIdFkeyNodeIdUpdate | null;
};
export type ModuleGroupModuleGroupPkeyConnect = {
  flowId: UUID;
};
export type ModuleGroupNodeIdConnect = {
  id: string;
};
export type ModuleGroupModuleGroupPkeyDelete = {
  flowId: UUID;
};
export type ModuleGroupNodeIdDelete = {
  id: string;
};
export type ModuleGroupOnModuleForModuleModuleGroupIdFkeyUsingModuleGroupPkeyUpdate = {
  flowId: UUID;
  patch: updateModuleGroupOnModuleForModuleModuleGroupIdFkeyPatch;
};
export type updateModuleGroupOnModuleForModuleModuleGroupIdFkeyPatch = {
  archivedAt?: Date | null;
  flowId?: UUID | null;
  flowToFlowId?: ModuleGroupFlowIdFkeyInput | null;
  moduleGroupLinksUsingFlowId?: ModuleGroupLinksModuleGroupFlowIdFkeyInverseInput | null;
  modulesUsingFlowId?: ModuleModuleGroupIdFkeyInverseInput | null;
  shuffle?: boolean | null;
  versionNumber?: BigFloat | null;
};
export type ModuleGroupFlowIdFkeyInput = {
  connectById?: FlowNodeIdConnect | null;
  connectByRowId?: FlowFlowPkeyConnect | null;
  create?: ModuleGroupFlowIdFkeyFlowCreateInput | null;
  deleteById?: FlowNodeIdDelete | null;
  deleteByRowId?: FlowFlowPkeyDelete | null;
  update?: FlowPatch | null;
  updateById?: ModuleGroupOnModuleGroupForModuleGroupFlowIdFkeyNodeIdUpdate | null;
  updateByRowId?: FlowOnModuleGroupForModuleGroupFlowIdFkeyUsingFlowPkeyUpdate | null;
};
export type FlowOnModuleGroupForModuleGroupFlowIdFkeyUsingFlowPkeyUpdate = {
  patch: updateFlowOnModuleGroupForModuleGroupFlowIdFkeyPatch;
  rowId: UUID;
};
export type updateFlowOnModuleGroupForModuleGroupFlowIdFkeyPatch = {
  activitiesUsingRowId?: ActivityFlowIdFkInverseInput | null;
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentFlowIdFkeyInverseInput | null;
  assignedAt?: Date | null;
  assignedById?: UUID | null;
  assignedToId?: UUID | null;
  assignmentDueAtStatus?: AssignmentDueAtStatus | null;
  assignmentStatus?: AssignmentStatus | null;
  completedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  dueAt?: Date | null;
  durationSeconds?: number | null;
  flowCommentsUsingRowId?: FlowCommentFlowIdFkeyInverseInput | null;
  flowFavoritesUsingRowId?: FlowFavoriteFlowIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerFlowIdFkeyInverseInput | null;
  flowType?: FlowType | null;
  gwtUserToAssignedById?: FlowAssignedByIdFkeyInput | null;
  gwtUserToAssignedToId?: FlowAssignedToIdFkeyInput | null;
  gwtUserToLastModifiedById?: FlowLastModifiedByIdFkeyInput | null;
  gwtUserToOwnerId?: FlowOwnerIdFkeyInput | null;
  lastModifiedById?: UUID | null;
  moduleGroupUsingRowId?: ModuleGroupFlowIdFkeyInverseInput | null;
  moduleUsingRowId?: ModuleFlowIdFkeyInverseInput | null;
  name?: string | null;
  ownerId?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentFlowIdFkeyInverseInput | null;
  rowId?: UUID | null;
  startedAt?: Date | null;
  statusChangedAt?: Date | null;
  taskUsingRowId?: TaskFlowIdFkeyInverseInput | null;
  templateUsingRowId?: TemplateFlowIdFkeyInverseInput | null;
  updatedAt?: Date | null;
  workflowType?: WorkflowType | null;
};
export type TaskFlowIdFkeyInverseInput = {
  connectByFlowId?: TaskTaskPkeyConnect | null;
  connectById?: TaskNodeIdConnect | null;
  create?: ReadonlyArray<TaskFlowIdFkeyTaskCreateInput> | null;
  deleteByFlowId?: TaskTaskPkeyDelete | null;
  deleteById?: TaskNodeIdDelete | null;
  deleteOthers?: boolean | null;
  updateByFlowId?: TaskOnTaskForTaskFlowIdFkeyUsingTaskPkeyUpdate | null;
  updateById?: FlowOnTaskForTaskFlowIdFkeyNodeIdUpdate | null;
};
export type TaskTaskPkeyConnect = {
  flowId: UUID;
};
export type TaskNodeIdConnect = {
  id: string;
};
export type TaskTaskPkeyDelete = {
  flowId: UUID;
};
export type TaskNodeIdDelete = {
  id: string;
};
export type TaskOnTaskForTaskFlowIdFkeyUsingTaskPkeyUpdate = {
  flowId: UUID;
  patch: updateTaskOnTaskForTaskFlowIdFkeyPatch;
};
export type updateTaskOnTaskForTaskFlowIdFkeyPatch = {
  complete?: boolean | null;
  flowToFlowId?: TaskFlowIdFkeyInput | null;
  moduleFlowId?: UUID | null;
  moduleToModuleFlowId?: TaskModuleFlowIdFkeyInput | null;
  priority?: number | null;
  templateFlowId?: UUID | null;
  templateToTemplateFlowId?: TaskTemplateFlowIdFkeyInput | null;
};
export type TaskFlowIdFkeyInput = {
  connectById?: FlowNodeIdConnect | null;
  connectByRowId?: FlowFlowPkeyConnect | null;
  create?: TaskFlowIdFkeyFlowCreateInput | null;
  deleteById?: FlowNodeIdDelete | null;
  deleteByRowId?: FlowFlowPkeyDelete | null;
  update?: FlowPatch | null;
  updateById?: TaskOnTaskForTaskFlowIdFkeyNodeIdUpdate | null;
  updateByRowId?: FlowOnTaskForTaskFlowIdFkeyUsingFlowPkeyUpdate | null;
};
export type FlowOnTaskForTaskFlowIdFkeyUsingFlowPkeyUpdate = {
  patch: updateFlowOnTaskForTaskFlowIdFkeyPatch;
  rowId: UUID;
};
export type updateFlowOnTaskForTaskFlowIdFkeyPatch = {
  activitiesUsingRowId?: ActivityFlowIdFkInverseInput | null;
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentFlowIdFkeyInverseInput | null;
  assignedAt?: Date | null;
  assignedById?: UUID | null;
  assignedToId?: UUID | null;
  assignmentDueAtStatus?: AssignmentDueAtStatus | null;
  assignmentStatus?: AssignmentStatus | null;
  completedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  dueAt?: Date | null;
  durationSeconds?: number | null;
  flowCommentsUsingRowId?: FlowCommentFlowIdFkeyInverseInput | null;
  flowFavoritesUsingRowId?: FlowFavoriteFlowIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerFlowIdFkeyInverseInput | null;
  flowType?: FlowType | null;
  gwtUserToAssignedById?: FlowAssignedByIdFkeyInput | null;
  gwtUserToAssignedToId?: FlowAssignedToIdFkeyInput | null;
  gwtUserToLastModifiedById?: FlowLastModifiedByIdFkeyInput | null;
  gwtUserToOwnerId?: FlowOwnerIdFkeyInput | null;
  lastModifiedById?: UUID | null;
  moduleGroupUsingRowId?: ModuleGroupFlowIdFkeyInverseInput | null;
  moduleUsingRowId?: ModuleFlowIdFkeyInverseInput | null;
  name?: string | null;
  ownerId?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentFlowIdFkeyInverseInput | null;
  rowId?: UUID | null;
  startedAt?: Date | null;
  statusChangedAt?: Date | null;
  taskUsingRowId?: TaskFlowIdFkeyInverseInput | null;
  templateUsingRowId?: TemplateFlowIdFkeyInverseInput | null;
  updatedAt?: Date | null;
  workflowType?: WorkflowType | null;
};
export type FlowReviewerFlowIdFkeyInverseInput = {
  connectByFlowIdAndReviewerId?: ReadonlyArray<FlowReviewerFlowReviewerPkeyConnect> | null;
  connectById?: ReadonlyArray<FlowReviewerNodeIdConnect> | null;
  create?: ReadonlyArray<FlowReviewerFlowIdFkeyFlowReviewerCreateInput> | null;
  deleteByFlowIdAndReviewerId?: ReadonlyArray<FlowReviewerFlowReviewerPkeyDelete> | null;
  deleteById?: ReadonlyArray<FlowReviewerNodeIdDelete> | null;
  deleteOthers?: boolean | null;
  updateByFlowIdAndReviewerId?: ReadonlyArray<FlowReviewerOnFlowReviewerForFlowReviewerFlowIdFkeyUsingFlowReviewerPkeyUpdate> | null;
  updateById?: ReadonlyArray<FlowOnFlowReviewerForFlowReviewerFlowIdFkeyNodeIdUpdate> | null;
};
export type FlowReviewerFlowReviewerPkeyConnect = {
  flowId: UUID;
  reviewerId: UUID;
};
export type FlowReviewerNodeIdConnect = {
  id: string;
};
export type FlowReviewerFlowReviewerPkeyDelete = {
  flowId: UUID;
  reviewerId: UUID;
};
export type FlowReviewerNodeIdDelete = {
  id: string;
};
export type FlowReviewerOnFlowReviewerForFlowReviewerFlowIdFkeyUsingFlowReviewerPkeyUpdate = {
  flowId: UUID;
  patch: updateFlowReviewerOnFlowReviewerForFlowReviewerFlowIdFkeyPatch;
  reviewerId: UUID;
};
export type updateFlowReviewerOnFlowReviewerForFlowReviewerFlowIdFkeyPatch = {
  flowToFlowId?: FlowReviewerFlowIdFkeyInput | null;
  gwtUserToReviewerId?: FlowReviewerReviewerIdFkeyInput | null;
  reviewerId?: UUID | null;
};
export type FlowReviewerFlowIdFkeyInput = {
  connectById?: FlowNodeIdConnect | null;
  connectByRowId?: FlowFlowPkeyConnect | null;
  create?: FlowReviewerFlowIdFkeyFlowCreateInput | null;
  deleteById?: FlowNodeIdDelete | null;
  deleteByRowId?: FlowFlowPkeyDelete | null;
  update?: FlowPatch | null;
  updateById?: FlowReviewerOnFlowReviewerForFlowReviewerFlowIdFkeyNodeIdUpdate | null;
  updateByRowId?: FlowOnFlowReviewerForFlowReviewerFlowIdFkeyUsingFlowPkeyUpdate | null;
};
export type FlowOnFlowReviewerForFlowReviewerFlowIdFkeyUsingFlowPkeyUpdate = {
  patch: updateFlowOnFlowReviewerForFlowReviewerFlowIdFkeyPatch;
  rowId: UUID;
};
export type updateFlowOnFlowReviewerForFlowReviewerFlowIdFkeyPatch = {
  activitiesUsingRowId?: ActivityFlowIdFkInverseInput | null;
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentFlowIdFkeyInverseInput | null;
  assignedAt?: Date | null;
  assignedById?: UUID | null;
  assignedToId?: UUID | null;
  assignmentDueAtStatus?: AssignmentDueAtStatus | null;
  assignmentStatus?: AssignmentStatus | null;
  completedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  dueAt?: Date | null;
  durationSeconds?: number | null;
  flowCommentsUsingRowId?: FlowCommentFlowIdFkeyInverseInput | null;
  flowFavoritesUsingRowId?: FlowFavoriteFlowIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerFlowIdFkeyInverseInput | null;
  flowType?: FlowType | null;
  gwtUserToAssignedById?: FlowAssignedByIdFkeyInput | null;
  gwtUserToAssignedToId?: FlowAssignedToIdFkeyInput | null;
  gwtUserToLastModifiedById?: FlowLastModifiedByIdFkeyInput | null;
  gwtUserToOwnerId?: FlowOwnerIdFkeyInput | null;
  lastModifiedById?: UUID | null;
  moduleGroupUsingRowId?: ModuleGroupFlowIdFkeyInverseInput | null;
  moduleUsingRowId?: ModuleFlowIdFkeyInverseInput | null;
  name?: string | null;
  ownerId?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentFlowIdFkeyInverseInput | null;
  rowId?: UUID | null;
  startedAt?: Date | null;
  statusChangedAt?: Date | null;
  taskUsingRowId?: TaskFlowIdFkeyInverseInput | null;
  templateUsingRowId?: TemplateFlowIdFkeyInverseInput | null;
  updatedAt?: Date | null;
  workflowType?: WorkflowType | null;
};
export type FlowCommentFlowIdFkeyInverseInput = {
  connectByFlowIdAndCommentId?: ReadonlyArray<FlowCommentFlowCommentPkeyConnect> | null;
  connectById?: ReadonlyArray<FlowCommentNodeIdConnect> | null;
  create?: ReadonlyArray<FlowCommentFlowIdFkeyFlowCommentCreateInput> | null;
  deleteByFlowIdAndCommentId?: ReadonlyArray<FlowCommentFlowCommentPkeyDelete> | null;
  deleteById?: ReadonlyArray<FlowCommentNodeIdDelete> | null;
  deleteOthers?: boolean | null;
  updateByFlowIdAndCommentId?: ReadonlyArray<FlowCommentOnFlowCommentForFlowCommentFlowIdFkeyUsingFlowCommentPkeyUpdate> | null;
  updateById?: ReadonlyArray<FlowOnFlowCommentForFlowCommentFlowIdFkeyNodeIdUpdate> | null;
};
export type FlowCommentFlowCommentPkeyConnect = {
  commentId: UUID;
  flowId: UUID;
};
export type FlowCommentNodeIdConnect = {
  id: string;
};
export type FlowCommentFlowCommentPkeyDelete = {
  commentId: UUID;
  flowId: UUID;
};
export type FlowCommentNodeIdDelete = {
  id: string;
};
export type FlowCommentOnFlowCommentForFlowCommentFlowIdFkeyUsingFlowCommentPkeyUpdate = {
  commentId: UUID;
  flowId: UUID;
  patch: updateFlowCommentOnFlowCommentForFlowCommentFlowIdFkeyPatch;
};
export type updateFlowCommentOnFlowCommentForFlowCommentFlowIdFkeyPatch = {
  commentId?: UUID | null;
  commentToCommentId?: FlowCommentCommentIdFkeyInput | null;
  flowToFlowId?: FlowCommentFlowIdFkeyInput | null;
};
export type FlowCommentFlowIdFkeyInput = {
  connectById?: FlowNodeIdConnect | null;
  connectByRowId?: FlowFlowPkeyConnect | null;
  create?: FlowCommentFlowIdFkeyFlowCreateInput | null;
  deleteById?: FlowNodeIdDelete | null;
  deleteByRowId?: FlowFlowPkeyDelete | null;
  update?: FlowPatch | null;
  updateById?: FlowCommentOnFlowCommentForFlowCommentFlowIdFkeyNodeIdUpdate | null;
  updateByRowId?: FlowOnFlowCommentForFlowCommentFlowIdFkeyUsingFlowPkeyUpdate | null;
};
export type FlowOnFlowCommentForFlowCommentFlowIdFkeyUsingFlowPkeyUpdate = {
  patch: updateFlowOnFlowCommentForFlowCommentFlowIdFkeyPatch;
  rowId: UUID;
};
export type updateFlowOnFlowCommentForFlowCommentFlowIdFkeyPatch = {
  activitiesUsingRowId?: ActivityFlowIdFkInverseInput | null;
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentFlowIdFkeyInverseInput | null;
  assignedAt?: Date | null;
  assignedById?: UUID | null;
  assignedToId?: UUID | null;
  assignmentDueAtStatus?: AssignmentDueAtStatus | null;
  assignmentStatus?: AssignmentStatus | null;
  completedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  dueAt?: Date | null;
  durationSeconds?: number | null;
  flowCommentsUsingRowId?: FlowCommentFlowIdFkeyInverseInput | null;
  flowFavoritesUsingRowId?: FlowFavoriteFlowIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerFlowIdFkeyInverseInput | null;
  flowType?: FlowType | null;
  gwtUserToAssignedById?: FlowAssignedByIdFkeyInput | null;
  gwtUserToAssignedToId?: FlowAssignedToIdFkeyInput | null;
  gwtUserToLastModifiedById?: FlowLastModifiedByIdFkeyInput | null;
  gwtUserToOwnerId?: FlowOwnerIdFkeyInput | null;
  lastModifiedById?: UUID | null;
  moduleGroupUsingRowId?: ModuleGroupFlowIdFkeyInverseInput | null;
  moduleUsingRowId?: ModuleFlowIdFkeyInverseInput | null;
  name?: string | null;
  ownerId?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentFlowIdFkeyInverseInput | null;
  rowId?: UUID | null;
  startedAt?: Date | null;
  statusChangedAt?: Date | null;
  taskUsingRowId?: TaskFlowIdFkeyInverseInput | null;
  templateUsingRowId?: TemplateFlowIdFkeyInverseInput | null;
  updatedAt?: Date | null;
  workflowType?: WorkflowType | null;
};
export type AdminDashboardFlowCommentFlowIdFkeyInverseInput = {
  connectByFlowIdAndCommentId?: ReadonlyArray<AdminDashboardFlowCommentAdminDashboardFlowCommentPkeyConnect> | null;
  connectById?: ReadonlyArray<AdminDashboardFlowCommentNodeIdConnect> | null;
  create?: ReadonlyArray<AdminDashboardFlowCommentFlowIdFkeyAdminDashboardFlowCommentCreateInput> | null;
  deleteByFlowIdAndCommentId?: ReadonlyArray<AdminDashboardFlowCommentAdminDashboardFlowCommentPkeyDelete> | null;
  deleteById?: ReadonlyArray<AdminDashboardFlowCommentNodeIdDelete> | null;
  deleteOthers?: boolean | null;
  updateByFlowIdAndCommentId?: ReadonlyArray<AdminDashboardFlowCommentOnAdminDashboardFlowCommentForAdminDashboardFlowCommentFlowIdFkeyUsingAdminDashboardFlowCommentPkeyUpdate> | null;
  updateById?: ReadonlyArray<FlowOnAdminDashboardFlowCommentForAdminDashboardFlowCommentFlowIdFkeyNodeIdUpdate> | null;
};
export type AdminDashboardFlowCommentAdminDashboardFlowCommentPkeyConnect = {
  commentId: UUID;
  flowId: UUID;
};
export type AdminDashboardFlowCommentNodeIdConnect = {
  id: string;
};
export type AdminDashboardFlowCommentAdminDashboardFlowCommentPkeyDelete = {
  commentId: UUID;
  flowId: UUID;
};
export type AdminDashboardFlowCommentNodeIdDelete = {
  id: string;
};
export type AdminDashboardFlowCommentOnAdminDashboardFlowCommentForAdminDashboardFlowCommentFlowIdFkeyUsingAdminDashboardFlowCommentPkeyUpdate = {
  commentId: UUID;
  flowId: UUID;
  patch: updateAdminDashboardFlowCommentOnAdminDashboardFlowCommentForAdminDashboardFlowCommentFlowIdFkeyPatch;
};
export type updateAdminDashboardFlowCommentOnAdminDashboardFlowCommentForAdminDashboardFlowCommentFlowIdFkeyPatch = {
  commentId?: UUID | null;
  commentToCommentId?: AdminDashboardFlowCommentCommentIdFkeyInput | null;
  flowToFlowId?: AdminDashboardFlowCommentFlowIdFkeyInput | null;
};
export type AdminDashboardFlowCommentFlowIdFkeyInput = {
  connectById?: FlowNodeIdConnect | null;
  connectByRowId?: FlowFlowPkeyConnect | null;
  create?: AdminDashboardFlowCommentFlowIdFkeyFlowCreateInput | null;
  deleteById?: FlowNodeIdDelete | null;
  deleteByRowId?: FlowFlowPkeyDelete | null;
  update?: FlowPatch | null;
  updateById?: AdminDashboardFlowCommentOnAdminDashboardFlowCommentForAdminDashboardFlowCommentFlowIdFkeyNodeIdUpdate | null;
  updateByRowId?: FlowOnAdminDashboardFlowCommentForAdminDashboardFlowCommentFlowIdFkeyUsingFlowPkeyUpdate | null;
};
export type FlowOnAdminDashboardFlowCommentForAdminDashboardFlowCommentFlowIdFkeyUsingFlowPkeyUpdate = {
  patch: updateFlowOnAdminDashboardFlowCommentForAdminDashboardFlowCommentFlowIdFkeyPatch;
  rowId: UUID;
};
export type updateFlowOnAdminDashboardFlowCommentForAdminDashboardFlowCommentFlowIdFkeyPatch = {
  activitiesUsingRowId?: ActivityFlowIdFkInverseInput | null;
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentFlowIdFkeyInverseInput | null;
  assignedAt?: Date | null;
  assignedById?: UUID | null;
  assignedToId?: UUID | null;
  assignmentDueAtStatus?: AssignmentDueAtStatus | null;
  assignmentStatus?: AssignmentStatus | null;
  completedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  dueAt?: Date | null;
  durationSeconds?: number | null;
  flowCommentsUsingRowId?: FlowCommentFlowIdFkeyInverseInput | null;
  flowFavoritesUsingRowId?: FlowFavoriteFlowIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerFlowIdFkeyInverseInput | null;
  flowType?: FlowType | null;
  gwtUserToAssignedById?: FlowAssignedByIdFkeyInput | null;
  gwtUserToAssignedToId?: FlowAssignedToIdFkeyInput | null;
  gwtUserToLastModifiedById?: FlowLastModifiedByIdFkeyInput | null;
  gwtUserToOwnerId?: FlowOwnerIdFkeyInput | null;
  lastModifiedById?: UUID | null;
  moduleGroupUsingRowId?: ModuleGroupFlowIdFkeyInverseInput | null;
  moduleUsingRowId?: ModuleFlowIdFkeyInverseInput | null;
  name?: string | null;
  ownerId?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentFlowIdFkeyInverseInput | null;
  rowId?: UUID | null;
  startedAt?: Date | null;
  statusChangedAt?: Date | null;
  taskUsingRowId?: TaskFlowIdFkeyInverseInput | null;
  templateUsingRowId?: TemplateFlowIdFkeyInverseInput | null;
  updatedAt?: Date | null;
  workflowType?: WorkflowType | null;
};
export type ReviewerDashboardFlowCommentFlowIdFkeyInverseInput = {
  connectByFlowIdAndCommentId?: ReadonlyArray<ReviewerDashboardFlowCommentReviewerDashboardFlowCommentPkeyConnect> | null;
  connectById?: ReadonlyArray<ReviewerDashboardFlowCommentNodeIdConnect> | null;
  create?: ReadonlyArray<ReviewerDashboardFlowCommentFlowIdFkeyReviewerDashboardFlowCommentCreateInput> | null;
  deleteByFlowIdAndCommentId?: ReadonlyArray<ReviewerDashboardFlowCommentReviewerDashboardFlowCommentPkeyDelete> | null;
  deleteById?: ReadonlyArray<ReviewerDashboardFlowCommentNodeIdDelete> | null;
  deleteOthers?: boolean | null;
  updateByFlowIdAndCommentId?: ReadonlyArray<ReviewerDashboardFlowCommentOnReviewerDashboardFlowCommentForReviewerDashboardFlowCommentFlowIdFkeyUsingReviewerDashboardFlowCommentPkeyUpdate> | null;
  updateById?: ReadonlyArray<FlowOnReviewerDashboardFlowCommentForReviewerDashboardFlowCommentFlowIdFkeyNodeIdUpdate> | null;
};
export type ReviewerDashboardFlowCommentReviewerDashboardFlowCommentPkeyConnect = {
  commentId: UUID;
  flowId: UUID;
};
export type ReviewerDashboardFlowCommentNodeIdConnect = {
  id: string;
};
export type ReviewerDashboardFlowCommentReviewerDashboardFlowCommentPkeyDelete = {
  commentId: UUID;
  flowId: UUID;
};
export type ReviewerDashboardFlowCommentNodeIdDelete = {
  id: string;
};
export type ReviewerDashboardFlowCommentOnReviewerDashboardFlowCommentForReviewerDashboardFlowCommentFlowIdFkeyUsingReviewerDashboardFlowCommentPkeyUpdate = {
  commentId: UUID;
  flowId: UUID;
  patch: updateReviewerDashboardFlowCommentOnReviewerDashboardFlowCommentForReviewerDashboardFlowCommentFlowIdFkeyPatch;
};
export type updateReviewerDashboardFlowCommentOnReviewerDashboardFlowCommentForReviewerDashboardFlowCommentFlowIdFkeyPatch = {
  commentId?: UUID | null;
  commentToCommentId?: ReviewerDashboardFlowCommentCommentIdFkeyInput | null;
  flowToFlowId?: ReviewerDashboardFlowCommentFlowIdFkeyInput | null;
};
export type ReviewerDashboardFlowCommentFlowIdFkeyInput = {
  connectById?: FlowNodeIdConnect | null;
  connectByRowId?: FlowFlowPkeyConnect | null;
  create?: ReviewerDashboardFlowCommentFlowIdFkeyFlowCreateInput | null;
  deleteById?: FlowNodeIdDelete | null;
  deleteByRowId?: FlowFlowPkeyDelete | null;
  update?: FlowPatch | null;
  updateById?: ReviewerDashboardFlowCommentOnReviewerDashboardFlowCommentForReviewerDashboardFlowCommentFlowIdFkeyNodeIdUpdate | null;
  updateByRowId?: FlowOnReviewerDashboardFlowCommentForReviewerDashboardFlowCommentFlowIdFkeyUsingFlowPkeyUpdate | null;
};
export type FlowOnReviewerDashboardFlowCommentForReviewerDashboardFlowCommentFlowIdFkeyUsingFlowPkeyUpdate = {
  patch: updateFlowOnReviewerDashboardFlowCommentForReviewerDashboardFlowCommentFlowIdFkeyPatch;
  rowId: UUID;
};
export type updateFlowOnReviewerDashboardFlowCommentForReviewerDashboardFlowCommentFlowIdFkeyPatch = {
  activitiesUsingRowId?: ActivityFlowIdFkInverseInput | null;
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentFlowIdFkeyInverseInput | null;
  assignedAt?: Date | null;
  assignedById?: UUID | null;
  assignedToId?: UUID | null;
  assignmentDueAtStatus?: AssignmentDueAtStatus | null;
  assignmentStatus?: AssignmentStatus | null;
  completedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  dueAt?: Date | null;
  durationSeconds?: number | null;
  flowCommentsUsingRowId?: FlowCommentFlowIdFkeyInverseInput | null;
  flowFavoritesUsingRowId?: FlowFavoriteFlowIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerFlowIdFkeyInverseInput | null;
  flowType?: FlowType | null;
  gwtUserToAssignedById?: FlowAssignedByIdFkeyInput | null;
  gwtUserToAssignedToId?: FlowAssignedToIdFkeyInput | null;
  gwtUserToLastModifiedById?: FlowLastModifiedByIdFkeyInput | null;
  gwtUserToOwnerId?: FlowOwnerIdFkeyInput | null;
  lastModifiedById?: UUID | null;
  moduleGroupUsingRowId?: ModuleGroupFlowIdFkeyInverseInput | null;
  moduleUsingRowId?: ModuleFlowIdFkeyInverseInput | null;
  name?: string | null;
  ownerId?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentFlowIdFkeyInverseInput | null;
  rowId?: UUID | null;
  startedAt?: Date | null;
  statusChangedAt?: Date | null;
  taskUsingRowId?: TaskFlowIdFkeyInverseInput | null;
  templateUsingRowId?: TemplateFlowIdFkeyInverseInput | null;
  updatedAt?: Date | null;
  workflowType?: WorkflowType | null;
};
export type FlowFavoriteFlowIdFkeyInverseInput = {
  connectByFlowIdAndGwtUserId?: ReadonlyArray<FlowFavoriteFlowFavoritePkeyConnect> | null;
  connectById?: ReadonlyArray<FlowFavoriteNodeIdConnect> | null;
  create?: ReadonlyArray<FlowFavoriteFlowIdFkeyFlowFavoriteCreateInput> | null;
  deleteByFlowIdAndGwtUserId?: ReadonlyArray<FlowFavoriteFlowFavoritePkeyDelete> | null;
  deleteById?: ReadonlyArray<FlowFavoriteNodeIdDelete> | null;
  deleteOthers?: boolean | null;
  updateByFlowIdAndGwtUserId?: ReadonlyArray<FlowFavoriteOnFlowFavoriteForFlowFavoriteFlowIdFkeyUsingFlowFavoritePkeyUpdate> | null;
  updateById?: ReadonlyArray<FlowOnFlowFavoriteForFlowFavoriteFlowIdFkeyNodeIdUpdate> | null;
};
export type FlowFavoriteFlowFavoritePkeyConnect = {
  flowId: UUID;
  gwtUserId: UUID;
};
export type FlowFavoriteNodeIdConnect = {
  id: string;
};
export type FlowFavoriteFlowFavoritePkeyDelete = {
  flowId: UUID;
  gwtUserId: UUID;
};
export type FlowFavoriteNodeIdDelete = {
  id: string;
};
export type FlowFavoriteOnFlowFavoriteForFlowFavoriteFlowIdFkeyUsingFlowFavoritePkeyUpdate = {
  flowId: UUID;
  gwtUserId: UUID;
  patch: updateFlowFavoriteOnFlowFavoriteForFlowFavoriteFlowIdFkeyPatch;
};
export type updateFlowFavoriteOnFlowFavoriteForFlowFavoriteFlowIdFkeyPatch = {
  createdAt?: Date | null;
  flowToFlowId?: FlowFavoriteFlowIdFkeyInput | null;
  gwtUserId?: UUID | null;
  gwtUserToGwtUserId?: FlowFavoriteGwtUserIdFkeyInput | null;
};
export type FlowFavoriteFlowIdFkeyInput = {
  connectById?: FlowNodeIdConnect | null;
  connectByRowId?: FlowFlowPkeyConnect | null;
  create?: FlowFavoriteFlowIdFkeyFlowCreateInput | null;
  deleteById?: FlowNodeIdDelete | null;
  deleteByRowId?: FlowFlowPkeyDelete | null;
  update?: FlowPatch | null;
  updateById?: FlowFavoriteOnFlowFavoriteForFlowFavoriteFlowIdFkeyNodeIdUpdate | null;
  updateByRowId?: FlowOnFlowFavoriteForFlowFavoriteFlowIdFkeyUsingFlowPkeyUpdate | null;
};
export type FlowOnFlowFavoriteForFlowFavoriteFlowIdFkeyUsingFlowPkeyUpdate = {
  patch: updateFlowOnFlowFavoriteForFlowFavoriteFlowIdFkeyPatch;
  rowId: UUID;
};
export type updateFlowOnFlowFavoriteForFlowFavoriteFlowIdFkeyPatch = {
  activitiesUsingRowId?: ActivityFlowIdFkInverseInput | null;
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentFlowIdFkeyInverseInput | null;
  assignedAt?: Date | null;
  assignedById?: UUID | null;
  assignedToId?: UUID | null;
  assignmentDueAtStatus?: AssignmentDueAtStatus | null;
  assignmentStatus?: AssignmentStatus | null;
  completedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  dueAt?: Date | null;
  durationSeconds?: number | null;
  flowCommentsUsingRowId?: FlowCommentFlowIdFkeyInverseInput | null;
  flowFavoritesUsingRowId?: FlowFavoriteFlowIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerFlowIdFkeyInverseInput | null;
  flowType?: FlowType | null;
  gwtUserToAssignedById?: FlowAssignedByIdFkeyInput | null;
  gwtUserToAssignedToId?: FlowAssignedToIdFkeyInput | null;
  gwtUserToLastModifiedById?: FlowLastModifiedByIdFkeyInput | null;
  gwtUserToOwnerId?: FlowOwnerIdFkeyInput | null;
  lastModifiedById?: UUID | null;
  moduleGroupUsingRowId?: ModuleGroupFlowIdFkeyInverseInput | null;
  moduleUsingRowId?: ModuleFlowIdFkeyInverseInput | null;
  name?: string | null;
  ownerId?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentFlowIdFkeyInverseInput | null;
  rowId?: UUID | null;
  startedAt?: Date | null;
  statusChangedAt?: Date | null;
  taskUsingRowId?: TaskFlowIdFkeyInverseInput | null;
  templateUsingRowId?: TemplateFlowIdFkeyInverseInput | null;
  updatedAt?: Date | null;
  workflowType?: WorkflowType | null;
};
export type ModuleGroupFlowIdFkeyInverseInput = {
  connectByFlowId?: ModuleGroupModuleGroupPkeyConnect | null;
  connectById?: ModuleGroupNodeIdConnect | null;
  create?: ReadonlyArray<ModuleGroupFlowIdFkeyModuleGroupCreateInput> | null;
  deleteByFlowId?: ModuleGroupModuleGroupPkeyDelete | null;
  deleteById?: ModuleGroupNodeIdDelete | null;
  deleteOthers?: boolean | null;
  updateByFlowId?: ModuleGroupOnModuleGroupForModuleGroupFlowIdFkeyUsingModuleGroupPkeyUpdate | null;
  updateById?: FlowOnModuleGroupForModuleGroupFlowIdFkeyNodeIdUpdate | null;
};
export type ModuleGroupOnModuleGroupForModuleGroupFlowIdFkeyUsingModuleGroupPkeyUpdate = {
  flowId: UUID;
  patch: updateModuleGroupOnModuleGroupForModuleGroupFlowIdFkeyPatch;
};
export type updateModuleGroupOnModuleGroupForModuleGroupFlowIdFkeyPatch = {
  archivedAt?: Date | null;
  flowToFlowId?: ModuleGroupFlowIdFkeyInput | null;
  moduleGroupLinksUsingFlowId?: ModuleGroupLinksModuleGroupFlowIdFkeyInverseInput | null;
  modulesUsingFlowId?: ModuleModuleGroupIdFkeyInverseInput | null;
  shuffle?: boolean | null;
  versionNumber?: BigFloat | null;
};
export type ModuleModuleGroupIdFkeyInverseInput = {
  connectByFlowId?: ReadonlyArray<ModuleModulePkeyConnect> | null;
  connectById?: ReadonlyArray<ModuleNodeIdConnect> | null;
  create?: ReadonlyArray<ModuleModuleGroupIdFkeyModuleCreateInput> | null;
  deleteByFlowId?: ReadonlyArray<ModuleModulePkeyDelete> | null;
  deleteById?: ReadonlyArray<ModuleNodeIdDelete> | null;
  deleteOthers?: boolean | null;
  updateByFlowId?: ReadonlyArray<ModuleOnModuleForModuleModuleGroupIdFkeyUsingModulePkeyUpdate> | null;
  updateById?: ReadonlyArray<ModuleGroupOnModuleForModuleModuleGroupIdFkeyNodeIdUpdate> | null;
};
export type ModuleOnModuleForModuleModuleGroupIdFkeyUsingModulePkeyUpdate = {
  flowId: UUID;
  patch: updateModuleOnModuleForModuleModuleGroupIdFkeyPatch;
};
export type updateModuleOnModuleForModuleModuleGroupIdFkeyPatch = {
  attempts?: number | null;
  autoStartNextModuleFlowId?: UUID | null;
  currentAttempt?: number | null;
  flowId?: UUID | null;
  flowToFlowId?: ModuleFlowIdFkeyInput | null;
  galleryAssetComponentsId?: UUID | null;
  galleryAssetDeliverableId?: UUID | null;
  galleryCollectionSubmissionsId?: UUID | null;
  groupModulePreviousModuleFlowId?: UUID | null;
  moduleGroupLinksUsingFlowId?: ModuleGroupLinksModuleFlowIdFkeyInverseInput | null;
  moduleGroupToModuleGroupId?: ModuleModuleGroupIdFkeyInput | null;
  moduleSubmissionsUsingFlowId?: ModuleSubmissionModuleFlowIdFkeyInverseInput | null;
  moduleToAutoStartNextModuleFlowId?: ModuleAutoStartNextModuleFlowIdFkeyInput | null;
  moduleToGroupModulePreviousModuleFlowId?: ModuleGroupModulePreviousModuleFlowIdFkeyInput | null;
  programType?: ProgramType | null;
  softwareType?: SoftwareType | null;
  submissionLock?: boolean | null;
  tasksUsingFlowId?: TaskModuleFlowIdFkeyInverseInput | null;
  templateFlowId?: UUID | null;
  templateToTemplateFlowId?: ModuleTemplateFlowIdFkeyInput | null;
  versionNumber?: BigFloat | null;
};
export type TaskModuleFlowIdFkeyInverseInput = {
  connectByFlowId?: ReadonlyArray<TaskTaskPkeyConnect> | null;
  connectById?: ReadonlyArray<TaskNodeIdConnect> | null;
  create?: ReadonlyArray<TaskModuleFlowIdFkeyTaskCreateInput> | null;
  deleteByFlowId?: ReadonlyArray<TaskTaskPkeyDelete> | null;
  deleteById?: ReadonlyArray<TaskNodeIdDelete> | null;
  deleteOthers?: boolean | null;
  updateByFlowId?: ReadonlyArray<TaskOnTaskForTaskModuleFlowIdFkeyUsingTaskPkeyUpdate> | null;
  updateById?: ReadonlyArray<ModuleOnTaskForTaskModuleFlowIdFkeyNodeIdUpdate> | null;
};
export type TaskOnTaskForTaskModuleFlowIdFkeyUsingTaskPkeyUpdate = {
  flowId: UUID;
  patch: updateTaskOnTaskForTaskModuleFlowIdFkeyPatch;
};
export type updateTaskOnTaskForTaskModuleFlowIdFkeyPatch = {
  complete?: boolean | null;
  flowId?: UUID | null;
  flowToFlowId?: TaskFlowIdFkeyInput | null;
  moduleToModuleFlowId?: TaskModuleFlowIdFkeyInput | null;
  priority?: number | null;
  templateFlowId?: UUID | null;
  templateToTemplateFlowId?: TaskTemplateFlowIdFkeyInput | null;
};
export type TaskTemplateFlowIdFkeyInput = {
  connectByFlowId?: TemplateTemplatePkeyConnect | null;
  connectById?: TemplateNodeIdConnect | null;
  create?: TaskTemplateFlowIdFkeyTemplateCreateInput | null;
  deleteByFlowId?: TemplateTemplatePkeyDelete | null;
  deleteById?: TemplateNodeIdDelete | null;
  update?: FlowPatch | null;
  updateByFlowId?: TemplateOnTaskForTaskTemplateFlowIdFkeyUsingTemplatePkeyUpdate | null;
  updateById?: TaskOnTaskForTaskTemplateFlowIdFkeyNodeIdUpdate | null;
};
export type TemplateOnTaskForTaskTemplateFlowIdFkeyUsingTemplatePkeyUpdate = {
  flowId: UUID;
  patch: updateTemplateOnTaskForTaskTemplateFlowIdFkeyPatch;
};
export type updateTemplateOnTaskForTaskTemplateFlowIdFkeyPatch = {
  flowId?: UUID | null;
  flowToFlowId?: TemplateFlowIdFkeyInput | null;
  modulesUsingFlowId?: ModuleTemplateFlowIdFkeyInverseInput | null;
  tasksUsingFlowId?: TaskTemplateFlowIdFkeyInverseInput | null;
};
export type TaskTemplateFlowIdFkeyInverseInput = {
  connectByFlowId?: ReadonlyArray<TaskTaskPkeyConnect> | null;
  connectById?: ReadonlyArray<TaskNodeIdConnect> | null;
  create?: ReadonlyArray<TaskTemplateFlowIdFkeyTaskCreateInput> | null;
  deleteByFlowId?: ReadonlyArray<TaskTaskPkeyDelete> | null;
  deleteById?: ReadonlyArray<TaskNodeIdDelete> | null;
  deleteOthers?: boolean | null;
  updateByFlowId?: ReadonlyArray<TaskOnTaskForTaskTemplateFlowIdFkeyUsingTaskPkeyUpdate> | null;
  updateById?: ReadonlyArray<TemplateOnTaskForTaskTemplateFlowIdFkeyNodeIdUpdate> | null;
};
export type TaskOnTaskForTaskTemplateFlowIdFkeyUsingTaskPkeyUpdate = {
  flowId: UUID;
  patch: updateTaskOnTaskForTaskTemplateFlowIdFkeyPatch;
};
export type updateTaskOnTaskForTaskTemplateFlowIdFkeyPatch = {
  complete?: boolean | null;
  flowId?: UUID | null;
  flowToFlowId?: TaskFlowIdFkeyInput | null;
  moduleFlowId?: UUID | null;
  moduleToModuleFlowId?: TaskModuleFlowIdFkeyInput | null;
  priority?: number | null;
  templateToTemplateFlowId?: TaskTemplateFlowIdFkeyInput | null;
};
export type TaskModuleFlowIdFkeyInput = {
  connectByFlowId?: ModuleModulePkeyConnect | null;
  connectById?: ModuleNodeIdConnect | null;
  create?: TaskModuleFlowIdFkeyModuleCreateInput | null;
  deleteByFlowId?: ModuleModulePkeyDelete | null;
  deleteById?: ModuleNodeIdDelete | null;
  update?: FlowPatch | null;
  updateByFlowId?: ModuleOnTaskForTaskModuleFlowIdFkeyUsingModulePkeyUpdate | null;
  updateById?: TaskOnTaskForTaskModuleFlowIdFkeyNodeIdUpdate | null;
};
export type ModuleOnTaskForTaskModuleFlowIdFkeyUsingModulePkeyUpdate = {
  flowId: UUID;
  patch: updateModuleOnTaskForTaskModuleFlowIdFkeyPatch;
};
export type updateModuleOnTaskForTaskModuleFlowIdFkeyPatch = {
  attempts?: number | null;
  autoStartNextModuleFlowId?: UUID | null;
  currentAttempt?: number | null;
  flowId?: UUID | null;
  flowToFlowId?: ModuleFlowIdFkeyInput | null;
  galleryAssetComponentsId?: UUID | null;
  galleryAssetDeliverableId?: UUID | null;
  galleryCollectionSubmissionsId?: UUID | null;
  groupModulePreviousModuleFlowId?: UUID | null;
  moduleGroupId?: UUID | null;
  moduleGroupLinksUsingFlowId?: ModuleGroupLinksModuleFlowIdFkeyInverseInput | null;
  moduleGroupToModuleGroupId?: ModuleModuleGroupIdFkeyInput | null;
  moduleSubmissionsUsingFlowId?: ModuleSubmissionModuleFlowIdFkeyInverseInput | null;
  moduleToAutoStartNextModuleFlowId?: ModuleAutoStartNextModuleFlowIdFkeyInput | null;
  moduleToGroupModulePreviousModuleFlowId?: ModuleGroupModulePreviousModuleFlowIdFkeyInput | null;
  programType?: ProgramType | null;
  softwareType?: SoftwareType | null;
  submissionLock?: boolean | null;
  tasksUsingFlowId?: TaskModuleFlowIdFkeyInverseInput | null;
  templateFlowId?: UUID | null;
  templateToTemplateFlowId?: ModuleTemplateFlowIdFkeyInput | null;
  versionNumber?: BigFloat | null;
};
export type ModuleSubmissionModuleFlowIdFkeyInverseInput = {
  connectById?: ReadonlyArray<ModuleSubmissionNodeIdConnect> | null;
  connectByModuleFlowIdAndGalleryAssetSubmissionId?: ReadonlyArray<ModuleSubmissionModuleSubmissionPkeyConnect> | null;
  create?: ReadonlyArray<ModuleSubmissionModuleFlowIdFkeyModuleSubmissionCreateInput> | null;
  deleteById?: ReadonlyArray<ModuleSubmissionNodeIdDelete> | null;
  deleteByModuleFlowIdAndGalleryAssetSubmissionId?: ReadonlyArray<ModuleSubmissionModuleSubmissionPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<ModuleOnModuleSubmissionForModuleSubmissionModuleFlowIdFkeyNodeIdUpdate> | null;
  updateByModuleFlowIdAndGalleryAssetSubmissionId?: ReadonlyArray<ModuleSubmissionOnModuleSubmissionForModuleSubmissionModuleFlowIdFkeyUsingModuleSubmissionPkeyUpdate> | null;
};
export type ModuleSubmissionOnModuleSubmissionForModuleSubmissionModuleFlowIdFkeyUsingModuleSubmissionPkeyUpdate = {
  galleryAssetSubmissionId: UUID;
  moduleFlowId: UUID;
  patch: updateModuleSubmissionOnModuleSubmissionForModuleSubmissionModuleFlowIdFkeyPatch;
};
export type updateModuleSubmissionOnModuleSubmissionForModuleSubmissionModuleFlowIdFkeyPatch = {
  acceptedById?: UUID | null;
  attempt?: number | null;
  createdAt?: Date | null;
  galleryAssetSubmissionId?: UUID | null;
  gwtUserToAcceptedById?: ModuleSubmissionAcceptedByIdFkeyInput | null;
  moduleToModuleFlowId?: ModuleSubmissionModuleFlowIdFkeyInput | null;
  submissionStatus?: SubmissionStatus | null;
};
export type ModuleSubmissionAcceptedByIdFkeyInput = {
  connectById?: GwtUserNodeIdConnect | null;
  connectByRowId?: GwtUserGwtUserPkeyConnect | null;
  create?: ModuleSubmissionAcceptedByIdFkeyGwtUserCreateInput | null;
  deleteById?: GwtUserNodeIdDelete | null;
  deleteByRowId?: GwtUserGwtUserPkeyDelete | null;
  updateById?: ModuleSubmissionOnModuleSubmissionForModuleSubmissionAcceptedByIdFkeyNodeIdUpdate | null;
  updateByRowId?: GwtUserOnModuleSubmissionForModuleSubmissionAcceptedByIdFkeyUsingGwtUserPkeyUpdate | null;
};
export type GwtUserOnModuleSubmissionForModuleSubmissionAcceptedByIdFkeyUsingGwtUserPkeyUpdate = {
  patch: updateGwtUserOnModuleSubmissionForModuleSubmissionAcceptedByIdFkeyPatch;
  rowId: UUID;
};
export type updateGwtUserOnModuleSubmissionForModuleSubmissionAcceptedByIdFkeyPatch = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email?: string | null;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName?: string | null;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName?: string | null;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId?: string | null;
  lastName?: string | null;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId?: UUID | null;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type FlowReviewerReviewerIdFkeyInverseInput = {
  connectByFlowIdAndReviewerId?: ReadonlyArray<FlowReviewerFlowReviewerPkeyConnect> | null;
  connectById?: ReadonlyArray<FlowReviewerNodeIdConnect> | null;
  create?: ReadonlyArray<FlowReviewerReviewerIdFkeyFlowReviewerCreateInput> | null;
  deleteByFlowIdAndReviewerId?: ReadonlyArray<FlowReviewerFlowReviewerPkeyDelete> | null;
  deleteById?: ReadonlyArray<FlowReviewerNodeIdDelete> | null;
  deleteOthers?: boolean | null;
  updateByFlowIdAndReviewerId?: ReadonlyArray<FlowReviewerOnFlowReviewerForFlowReviewerReviewerIdFkeyUsingFlowReviewerPkeyUpdate> | null;
  updateById?: ReadonlyArray<GwtUserOnFlowReviewerForFlowReviewerReviewerIdFkeyNodeIdUpdate> | null;
};
export type FlowReviewerOnFlowReviewerForFlowReviewerReviewerIdFkeyUsingFlowReviewerPkeyUpdate = {
  flowId: UUID;
  patch: updateFlowReviewerOnFlowReviewerForFlowReviewerReviewerIdFkeyPatch;
  reviewerId: UUID;
};
export type updateFlowReviewerOnFlowReviewerForFlowReviewerReviewerIdFkeyPatch = {
  flowId?: UUID | null;
  flowToFlowId?: FlowReviewerFlowIdFkeyInput | null;
  gwtUserToReviewerId?: FlowReviewerReviewerIdFkeyInput | null;
};
export type FlowReviewerReviewerIdFkeyInput = {
  connectById?: GwtUserNodeIdConnect | null;
  connectByRowId?: GwtUserGwtUserPkeyConnect | null;
  create?: FlowReviewerReviewerIdFkeyGwtUserCreateInput | null;
  deleteById?: GwtUserNodeIdDelete | null;
  deleteByRowId?: GwtUserGwtUserPkeyDelete | null;
  updateById?: FlowReviewerOnFlowReviewerForFlowReviewerReviewerIdFkeyNodeIdUpdate | null;
  updateByRowId?: GwtUserOnFlowReviewerForFlowReviewerReviewerIdFkeyUsingGwtUserPkeyUpdate | null;
};
export type GwtUserOnFlowReviewerForFlowReviewerReviewerIdFkeyUsingGwtUserPkeyUpdate = {
  patch: updateGwtUserOnFlowReviewerForFlowReviewerReviewerIdFkeyPatch;
  rowId: UUID;
};
export type updateGwtUserOnFlowReviewerForFlowReviewerReviewerIdFkeyPatch = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email?: string | null;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName?: string | null;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName?: string | null;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId?: string | null;
  lastName?: string | null;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId?: UUID | null;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type FlowFavoriteGwtUserIdFkeyInverseInput = {
  connectByFlowIdAndGwtUserId?: ReadonlyArray<FlowFavoriteFlowFavoritePkeyConnect> | null;
  connectById?: ReadonlyArray<FlowFavoriteNodeIdConnect> | null;
  create?: ReadonlyArray<FlowFavoriteGwtUserIdFkeyFlowFavoriteCreateInput> | null;
  deleteByFlowIdAndGwtUserId?: ReadonlyArray<FlowFavoriteFlowFavoritePkeyDelete> | null;
  deleteById?: ReadonlyArray<FlowFavoriteNodeIdDelete> | null;
  deleteOthers?: boolean | null;
  updateByFlowIdAndGwtUserId?: ReadonlyArray<FlowFavoriteOnFlowFavoriteForFlowFavoriteGwtUserIdFkeyUsingFlowFavoritePkeyUpdate> | null;
  updateById?: ReadonlyArray<GwtUserOnFlowFavoriteForFlowFavoriteGwtUserIdFkeyNodeIdUpdate> | null;
};
export type FlowFavoriteOnFlowFavoriteForFlowFavoriteGwtUserIdFkeyUsingFlowFavoritePkeyUpdate = {
  flowId: UUID;
  gwtUserId: UUID;
  patch: updateFlowFavoriteOnFlowFavoriteForFlowFavoriteGwtUserIdFkeyPatch;
};
export type updateFlowFavoriteOnFlowFavoriteForFlowFavoriteGwtUserIdFkeyPatch = {
  createdAt?: Date | null;
  flowId?: UUID | null;
  flowToFlowId?: FlowFavoriteFlowIdFkeyInput | null;
  gwtUserToGwtUserId?: FlowFavoriteGwtUserIdFkeyInput | null;
};
export type FlowFavoriteGwtUserIdFkeyInput = {
  connectById?: GwtUserNodeIdConnect | null;
  connectByRowId?: GwtUserGwtUserPkeyConnect | null;
  create?: FlowFavoriteGwtUserIdFkeyGwtUserCreateInput | null;
  deleteById?: GwtUserNodeIdDelete | null;
  deleteByRowId?: GwtUserGwtUserPkeyDelete | null;
  updateById?: FlowFavoriteOnFlowFavoriteForFlowFavoriteGwtUserIdFkeyNodeIdUpdate | null;
  updateByRowId?: GwtUserOnFlowFavoriteForFlowFavoriteGwtUserIdFkeyUsingGwtUserPkeyUpdate | null;
};
export type GwtUserOnFlowFavoriteForFlowFavoriteGwtUserIdFkeyUsingGwtUserPkeyUpdate = {
  patch: updateGwtUserOnFlowFavoriteForFlowFavoriteGwtUserIdFkeyPatch;
  rowId: UUID;
};
export type updateGwtUserOnFlowFavoriteForFlowFavoriteGwtUserIdFkeyPatch = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email?: string | null;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName?: string | null;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName?: string | null;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId?: string | null;
  lastName?: string | null;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId?: UUID | null;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type CommentViewedGwtUserIdFkeyInverseInput = {
  connectByGwtUserIdAndCommentId?: ReadonlyArray<CommentViewedFlowLastViewedPkeyConnect> | null;
  connectById?: ReadonlyArray<CommentViewedNodeIdConnect> | null;
  create?: ReadonlyArray<CommentViewedGwtUserIdFkeyCommentViewedCreateInput> | null;
  deleteByGwtUserIdAndCommentId?: ReadonlyArray<CommentViewedFlowLastViewedPkeyDelete> | null;
  deleteById?: ReadonlyArray<CommentViewedNodeIdDelete> | null;
  deleteOthers?: boolean | null;
  updateByGwtUserIdAndCommentId?: ReadonlyArray<CommentViewedOnCommentViewedForCommentViewedGwtUserIdFkeyUsingFlowLastViewedPkeyUpdate> | null;
  updateById?: ReadonlyArray<GwtUserOnCommentViewedForCommentViewedGwtUserIdFkeyNodeIdUpdate> | null;
};
export type CommentViewedFlowLastViewedPkeyConnect = {
  commentId: UUID;
  gwtUserId: UUID;
};
export type CommentViewedNodeIdConnect = {
  id: string;
};
export type CommentViewedFlowLastViewedPkeyDelete = {
  commentId: UUID;
  gwtUserId: UUID;
};
export type CommentViewedNodeIdDelete = {
  id: string;
};
export type CommentViewedOnCommentViewedForCommentViewedGwtUserIdFkeyUsingFlowLastViewedPkeyUpdate = {
  commentId: UUID;
  gwtUserId: UUID;
  patch: updateCommentViewedOnCommentViewedForCommentViewedGwtUserIdFkeyPatch;
};
export type updateCommentViewedOnCommentViewedForCommentViewedGwtUserIdFkeyPatch = {
  commentId?: UUID | null;
  commentToCommentId?: CommentViewedCommentIdFkeyInput | null;
  gwtUserToGwtUserId?: CommentViewedGwtUserIdFkeyInput | null;
};
export type CommentViewedGwtUserIdFkeyInput = {
  connectById?: GwtUserNodeIdConnect | null;
  connectByRowId?: GwtUserGwtUserPkeyConnect | null;
  create?: CommentViewedGwtUserIdFkeyGwtUserCreateInput | null;
  deleteById?: GwtUserNodeIdDelete | null;
  deleteByRowId?: GwtUserGwtUserPkeyDelete | null;
  updateById?: CommentViewedOnCommentViewedForCommentViewedGwtUserIdFkeyNodeIdUpdate | null;
  updateByRowId?: GwtUserOnCommentViewedForCommentViewedGwtUserIdFkeyUsingGwtUserPkeyUpdate | null;
};
export type GwtUserOnCommentViewedForCommentViewedGwtUserIdFkeyUsingGwtUserPkeyUpdate = {
  patch: updateGwtUserOnCommentViewedForCommentViewedGwtUserIdFkeyPatch;
  rowId: UUID;
};
export type updateGwtUserOnCommentViewedForCommentViewedGwtUserIdFkeyPatch = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email?: string | null;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName?: string | null;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName?: string | null;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId?: string | null;
  lastName?: string | null;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId?: UUID | null;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type CompanyOwnerIdFkeyInverseInput = {
  connectById?: ReadonlyArray<CompanyNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<CompanyCompanyPkeyConnect> | null;
  create?: ReadonlyArray<CompanyOwnerIdFkeyCompanyCreateInput> | null;
  deleteById?: ReadonlyArray<CompanyNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<CompanyCompanyPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<GwtUserOnCompanyForCompanyOwnerIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<CompanyOnCompanyForCompanyOwnerIdFkeyUsingCompanyPkeyUpdate> | null;
};
export type CompanyOnCompanyForCompanyOwnerIdFkeyUsingCompanyPkeyUpdate = {
  patch: updateCompanyOnCompanyForCompanyOwnerIdFkeyPatch;
  rowId: UUID;
};
export type updateCompanyOnCompanyForCompanyOwnerIdFkeyPatch = {
  archivedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  gwtUserToOwnerId?: CompanyOwnerIdFkeyInput | null;
  gwtUsersUsingRowId?: GwtUserCompanyIdFkeyInverseInput | null;
  name?: string | null;
  rowId?: UUID | null;
  updatedAt?: Date | null;
};
export type GwtUserCompanyIdFkeyInverseInput = {
  connectById?: ReadonlyArray<GwtUserNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<GwtUserGwtUserPkeyConnect> | null;
  create?: ReadonlyArray<GwtUserCompanyIdFkeyGwtUserCreateInput> | null;
  deleteById?: ReadonlyArray<GwtUserNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<GwtUserGwtUserPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<CompanyOnGwtUserForGwtUserCompanyIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<GwtUserOnGwtUserForGwtUserCompanyIdFkeyUsingGwtUserPkeyUpdate> | null;
};
export type GwtUserOnGwtUserForGwtUserCompanyIdFkeyUsingGwtUserPkeyUpdate = {
  patch: updateGwtUserOnGwtUserForGwtUserCompanyIdFkeyPatch;
  rowId: UUID;
};
export type updateGwtUserOnGwtUserForGwtUserCompanyIdFkeyPatch = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email?: string | null;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName?: string | null;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName?: string | null;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId?: string | null;
  lastName?: string | null;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId?: UUID | null;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type PolFileUploadGwtUserIdFkeyInverseInput = {
  connectByGwtUserIdAndFileNameAndGalleryAssetId?: ReadonlyArray<PolFileUploadPolFileUploadPkeyConnect> | null;
  connectById?: ReadonlyArray<PolFileUploadNodeIdConnect> | null;
  create?: ReadonlyArray<PolFileUploadGwtUserIdFkeyPolFileUploadCreateInput> | null;
  deleteByGwtUserIdAndFileNameAndGalleryAssetId?: ReadonlyArray<PolFileUploadPolFileUploadPkeyDelete> | null;
  deleteById?: ReadonlyArray<PolFileUploadNodeIdDelete> | null;
  deleteOthers?: boolean | null;
  updateByGwtUserIdAndFileNameAndGalleryAssetId?: ReadonlyArray<PolFileUploadOnPolFileUploadForPolFileUploadGwtUserIdFkeyUsingPolFileUploadPkeyUpdate> | null;
  updateById?: ReadonlyArray<GwtUserOnPolFileUploadForPolFileUploadGwtUserIdFkeyNodeIdUpdate> | null;
};
export type PolFileUploadPolFileUploadPkeyConnect = {
  fileName: string;
  galleryAssetId: UUID;
  gwtUserId: UUID;
};
export type PolFileUploadNodeIdConnect = {
  id: string;
};
export type PolFileUploadPolFileUploadPkeyDelete = {
  fileName: string;
  galleryAssetId: UUID;
  gwtUserId: UUID;
};
export type PolFileUploadNodeIdDelete = {
  id: string;
};
export type PolFileUploadOnPolFileUploadForPolFileUploadGwtUserIdFkeyUsingPolFileUploadPkeyUpdate = {
  fileName: string;
  galleryAssetId: UUID;
  gwtUserId: UUID;
  patch: updatePolFileUploadOnPolFileUploadForPolFileUploadGwtUserIdFkeyPatch;
};
export type updatePolFileUploadOnPolFileUploadForPolFileUploadGwtUserIdFkeyPatch = {
  fileName?: string | null;
  fileSize?: BigInt | null;
  galleryAssetId?: UUID | null;
  gwtUserToGwtUserId?: PolFileUploadGwtUserIdFkeyInput | null;
  rowId?: UUID | null;
  tossKey?: string | null;
  tossUrl?: string | null;
  uploadError?: string | null;
  uploadStatus?: PolFileUploadStatus | null;
};
export type PolFileUploadGwtUserIdFkeyInput = {
  connectById?: GwtUserNodeIdConnect | null;
  connectByRowId?: GwtUserGwtUserPkeyConnect | null;
  create?: PolFileUploadGwtUserIdFkeyGwtUserCreateInput | null;
  deleteById?: GwtUserNodeIdDelete | null;
  deleteByRowId?: GwtUserGwtUserPkeyDelete | null;
  updateById?: PolFileUploadOnPolFileUploadForPolFileUploadGwtUserIdFkeyNodeIdUpdate | null;
  updateByRowId?: GwtUserOnPolFileUploadForPolFileUploadGwtUserIdFkeyUsingGwtUserPkeyUpdate | null;
};
export type GwtUserOnPolFileUploadForPolFileUploadGwtUserIdFkeyUsingGwtUserPkeyUpdate = {
  patch: updateGwtUserOnPolFileUploadForPolFileUploadGwtUserIdFkeyPatch;
  rowId: UUID;
};
export type updateGwtUserOnPolFileUploadForPolFileUploadGwtUserIdFkeyPatch = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email?: string | null;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName?: string | null;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName?: string | null;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId?: string | null;
  lastName?: string | null;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId?: UUID | null;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type FeedbackGwtUserIdFkeyInverseInput = {
  connectById?: ReadonlyArray<FeedbackNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<FeedbackFeedbackPkeyConnect> | null;
  create?: ReadonlyArray<FeedbackGwtUserIdFkeyFeedbackCreateInput> | null;
  deleteById?: ReadonlyArray<FeedbackNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<FeedbackFeedbackPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<GwtUserOnFeedbackForFeedbackGwtUserIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<FeedbackOnFeedbackForFeedbackGwtUserIdFkeyUsingFeedbackPkeyUpdate> | null;
};
export type FeedbackFeedbackPkeyConnect = {
  rowId: UUID;
};
export type FeedbackNodeIdConnect = {
  id: string;
};
export type FeedbackFeedbackPkeyDelete = {
  rowId: UUID;
};
export type FeedbackNodeIdDelete = {
  id: string;
};
export type FeedbackOnFeedbackForFeedbackGwtUserIdFkeyUsingFeedbackPkeyUpdate = {
  patch: updateFeedbackOnFeedbackForFeedbackGwtUserIdFkeyPatch;
  rowId: UUID;
};
export type updateFeedbackOnFeedbackForFeedbackGwtUserIdFkeyPatch = {
  archivedAt?: Date | null;
  claimedById?: UUID | null;
  createdAt?: Date | null;
  feedback?: string | null;
  feedbackLevel?: FeedbackLevel | null;
  gwtUserToClaimedById?: FeedbackClaimedByIdFkeyInput | null;
  gwtUserToGwtUserId?: FeedbackGwtUserIdFkeyInput | null;
  resolved?: boolean | null;
  rowId?: UUID | null;
  updatedAt?: Date | null;
};
export type FeedbackGwtUserIdFkeyInput = {
  connectById?: GwtUserNodeIdConnect | null;
  connectByRowId?: GwtUserGwtUserPkeyConnect | null;
  create?: FeedbackGwtUserIdFkeyGwtUserCreateInput | null;
  deleteById?: GwtUserNodeIdDelete | null;
  deleteByRowId?: GwtUserGwtUserPkeyDelete | null;
  updateById?: FeedbackOnFeedbackForFeedbackGwtUserIdFkeyNodeIdUpdate | null;
  updateByRowId?: GwtUserOnFeedbackForFeedbackGwtUserIdFkeyUsingGwtUserPkeyUpdate | null;
};
export type GwtUserOnFeedbackForFeedbackGwtUserIdFkeyUsingGwtUserPkeyUpdate = {
  patch: updateGwtUserOnFeedbackForFeedbackGwtUserIdFkeyPatch;
  rowId: UUID;
};
export type updateGwtUserOnFeedbackForFeedbackGwtUserIdFkeyPatch = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email?: string | null;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName?: string | null;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName?: string | null;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId?: string | null;
  lastName?: string | null;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId?: UUID | null;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type FeedbackClaimedByIdFkeyInverseInput = {
  connectById?: ReadonlyArray<FeedbackNodeIdConnect> | null;
  connectByRowId?: ReadonlyArray<FeedbackFeedbackPkeyConnect> | null;
  create?: ReadonlyArray<FeedbackClaimedByIdFkeyFeedbackCreateInput> | null;
  deleteById?: ReadonlyArray<FeedbackNodeIdDelete> | null;
  deleteByRowId?: ReadonlyArray<FeedbackFeedbackPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<GwtUserOnFeedbackForFeedbackClaimedByIdFkeyNodeIdUpdate> | null;
  updateByRowId?: ReadonlyArray<FeedbackOnFeedbackForFeedbackClaimedByIdFkeyUsingFeedbackPkeyUpdate> | null;
};
export type FeedbackOnFeedbackForFeedbackClaimedByIdFkeyUsingFeedbackPkeyUpdate = {
  patch: updateFeedbackOnFeedbackForFeedbackClaimedByIdFkeyPatch;
  rowId: UUID;
};
export type updateFeedbackOnFeedbackForFeedbackClaimedByIdFkeyPatch = {
  archivedAt?: Date | null;
  createdAt?: Date | null;
  feedback?: string | null;
  feedbackLevel?: FeedbackLevel | null;
  gwtUserId?: UUID | null;
  gwtUserToClaimedById?: FeedbackClaimedByIdFkeyInput | null;
  gwtUserToGwtUserId?: FeedbackGwtUserIdFkeyInput | null;
  resolved?: boolean | null;
  rowId?: UUID | null;
  updatedAt?: Date | null;
};
export type FeedbackClaimedByIdFkeyInput = {
  connectById?: GwtUserNodeIdConnect | null;
  connectByRowId?: GwtUserGwtUserPkeyConnect | null;
  create?: FeedbackClaimedByIdFkeyGwtUserCreateInput | null;
  deleteById?: GwtUserNodeIdDelete | null;
  deleteByRowId?: GwtUserGwtUserPkeyDelete | null;
  updateById?: FeedbackOnFeedbackForFeedbackClaimedByIdFkeyNodeIdUpdate | null;
  updateByRowId?: GwtUserOnFeedbackForFeedbackClaimedByIdFkeyUsingGwtUserPkeyUpdate | null;
};
export type GwtUserOnFeedbackForFeedbackClaimedByIdFkeyUsingGwtUserPkeyUpdate = {
  patch: updateGwtUserOnFeedbackForFeedbackClaimedByIdFkeyPatch;
  rowId: UUID;
};
export type updateGwtUserOnFeedbackForFeedbackClaimedByIdFkeyPatch = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email?: string | null;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName?: string | null;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName?: string | null;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId?: string | null;
  lastName?: string | null;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId?: UUID | null;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type FeedbackOnFeedbackForFeedbackClaimedByIdFkeyNodeIdUpdate = {
  id: string;
  patch: GwtUserPatch;
};
export type FeedbackClaimedByIdFkeyGwtUserCreateInput = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email: string;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName: string;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName: string;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId: string;
  lastName: string;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId: UUID;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type GwtUserOnFeedbackForFeedbackClaimedByIdFkeyNodeIdUpdate = {
  id: string;
  patch: FeedbackPatch;
};
export type FeedbackPatch = {
  archivedAt?: Date | null;
  claimedById?: UUID | null;
  createdAt?: Date | null;
  feedback?: string | null;
  feedbackLevel?: FeedbackLevel | null;
  gwtUserId?: UUID | null;
  gwtUserToClaimedById?: FeedbackClaimedByIdFkeyInput | null;
  gwtUserToGwtUserId?: FeedbackGwtUserIdFkeyInput | null;
  resolved?: boolean | null;
  rowId?: UUID | null;
  updatedAt?: Date | null;
};
export type FeedbackClaimedByIdFkeyFeedbackCreateInput = {
  archivedAt?: Date | null;
  createdAt?: Date | null;
  feedback: string;
  feedbackLevel: FeedbackLevel;
  gwtUserId?: UUID | null;
  gwtUserToClaimedById?: FeedbackClaimedByIdFkeyInput | null;
  gwtUserToGwtUserId?: FeedbackGwtUserIdFkeyInput | null;
  resolved?: boolean | null;
  rowId?: UUID | null;
  updatedAt?: Date | null;
};
export type FeedbackOnFeedbackForFeedbackGwtUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: GwtUserPatch;
};
export type FeedbackGwtUserIdFkeyGwtUserCreateInput = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email: string;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName: string;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName: string;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId: string;
  lastName: string;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId: UUID;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type GwtUserOnFeedbackForFeedbackGwtUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: FeedbackPatch;
};
export type FeedbackGwtUserIdFkeyFeedbackCreateInput = {
  archivedAt?: Date | null;
  claimedById?: UUID | null;
  createdAt?: Date | null;
  feedback: string;
  feedbackLevel: FeedbackLevel;
  gwtUserToClaimedById?: FeedbackClaimedByIdFkeyInput | null;
  gwtUserToGwtUserId?: FeedbackGwtUserIdFkeyInput | null;
  resolved?: boolean | null;
  rowId?: UUID | null;
  updatedAt?: Date | null;
};
export type PolFileUploadOnPolFileUploadForPolFileUploadGwtUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: GwtUserPatch;
};
export type PolFileUploadGwtUserIdFkeyGwtUserCreateInput = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email: string;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName: string;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName: string;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId: string;
  lastName: string;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId: UUID;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type GwtUserOnPolFileUploadForPolFileUploadGwtUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: PolFileUploadPatch;
};
export type PolFileUploadPatch = {
  fileName?: string | null;
  fileSize?: BigInt | null;
  galleryAssetId?: UUID | null;
  gwtUserId?: UUID | null;
  gwtUserToGwtUserId?: PolFileUploadGwtUserIdFkeyInput | null;
  rowId?: UUID | null;
  tossKey?: string | null;
  tossUrl?: string | null;
  uploadError?: string | null;
  uploadStatus?: PolFileUploadStatus | null;
};
export type PolFileUploadGwtUserIdFkeyPolFileUploadCreateInput = {
  fileName: string;
  fileSize: BigInt;
  galleryAssetId: UUID;
  gwtUserToGwtUserId?: PolFileUploadGwtUserIdFkeyInput | null;
  rowId?: UUID | null;
  tossKey?: string | null;
  tossUrl?: string | null;
  uploadError?: string | null;
  uploadStatus?: PolFileUploadStatus | null;
};
export type CompanyOnGwtUserForGwtUserCompanyIdFkeyNodeIdUpdate = {
  id: string;
  patch: GwtUserPatch;
};
export type GwtUserCompanyIdFkeyGwtUserCreateInput = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email: string;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName: string;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName: string;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId: string;
  lastName: string;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId: UUID;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type GwtUserOnCompanyForCompanyOwnerIdFkeyNodeIdUpdate = {
  id: string;
  patch: CompanyPatch;
};
export type CompanyPatch = {
  archivedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  gwtUserToOwnerId?: CompanyOwnerIdFkeyInput | null;
  gwtUsersUsingRowId?: GwtUserCompanyIdFkeyInverseInput | null;
  name?: string | null;
  ownerId?: UUID | null;
  rowId?: UUID | null;
  updatedAt?: Date | null;
};
export type CompanyOwnerIdFkeyCompanyCreateInput = {
  archivedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  gwtUserToOwnerId?: CompanyOwnerIdFkeyInput | null;
  gwtUsersUsingRowId?: GwtUserCompanyIdFkeyInverseInput | null;
  name?: string | null;
  rowId?: UUID | null;
  updatedAt?: Date | null;
};
export type CommentViewedOnCommentViewedForCommentViewedGwtUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: GwtUserPatch;
};
export type CommentViewedGwtUserIdFkeyGwtUserCreateInput = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email: string;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName: string;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName: string;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId: string;
  lastName: string;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId: UUID;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type CommentViewedCommentIdFkeyInput = {
  connectById?: CommentNodeIdConnect | null;
  connectByRowId?: CommentCommentPkeyConnect | null;
  create?: CommentViewedCommentIdFkeyCommentCreateInput | null;
  deleteById?: CommentNodeIdDelete | null;
  deleteByRowId?: CommentCommentPkeyDelete | null;
  updateById?: CommentViewedOnCommentViewedForCommentViewedCommentIdFkeyNodeIdUpdate | null;
  updateByRowId?: CommentOnCommentViewedForCommentViewedCommentIdFkeyUsingCommentPkeyUpdate | null;
};
export type CommentOnCommentViewedForCommentViewedCommentIdFkeyUsingCommentPkeyUpdate = {
  patch: updateCommentOnCommentViewedForCommentViewedCommentIdFkeyPatch;
  rowId: UUID;
};
export type updateCommentOnCommentViewedForCommentViewedCommentIdFkeyPatch = {
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentCommentIdFkeyInverseInput | null;
  commentText?: string | null;
  commentToResponseTo?: CommentResponseToFkeyInput | null;
  commentViewedsUsingRowId?: CommentViewedCommentIdFkeyInverseInput | null;
  createdAt?: Date | null;
  flowCommentsUsingRowId?: FlowCommentCommentIdFkeyInverseInput | null;
  gwtUserId?: UUID | null;
  gwtUserToGwtUserId?: CommentGwtUserIdFkeyInput | null;
  responseTo?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentCommentIdFkeyInverseInput | null;
  rowId?: UUID | null;
  updatedAt?: Date | null;
};
export type CommentResponseToFkeyInput = {
  connectById?: CommentNodeIdConnect | null;
  connectByRowId?: CommentCommentPkeyConnect | null;
  create?: CommentResponseToFkeyCommentCreateInput | null;
  deleteById?: CommentNodeIdDelete | null;
  deleteByRowId?: CommentCommentPkeyDelete | null;
  updateById?: CommentOnCommentForCommentResponseToFkeyNodeIdUpdate | null;
  updateByRowId?: CommentOnCommentForCommentResponseToFkeyUsingCommentPkeyUpdate | null;
};
export type CommentOnCommentForCommentResponseToFkeyUsingCommentPkeyUpdate = {
  patch: updateCommentOnCommentForCommentResponseToFkeyPatch;
  rowId: UUID;
};
export type updateCommentOnCommentForCommentResponseToFkeyPatch = {
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentCommentIdFkeyInverseInput | null;
  commentText?: string | null;
  commentToResponseTo?: CommentResponseToFkeyInput | null;
  commentViewedsUsingRowId?: CommentViewedCommentIdFkeyInverseInput | null;
  createdAt?: Date | null;
  flowCommentsUsingRowId?: FlowCommentCommentIdFkeyInverseInput | null;
  gwtUserId?: UUID | null;
  gwtUserToGwtUserId?: CommentGwtUserIdFkeyInput | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentCommentIdFkeyInverseInput | null;
  rowId?: UUID | null;
  updatedAt?: Date | null;
};
export type FlowCommentCommentIdFkeyInverseInput = {
  connectByFlowIdAndCommentId?: ReadonlyArray<FlowCommentFlowCommentPkeyConnect> | null;
  connectById?: ReadonlyArray<FlowCommentNodeIdConnect> | null;
  create?: ReadonlyArray<FlowCommentCommentIdFkeyFlowCommentCreateInput> | null;
  deleteByFlowIdAndCommentId?: ReadonlyArray<FlowCommentFlowCommentPkeyDelete> | null;
  deleteById?: ReadonlyArray<FlowCommentNodeIdDelete> | null;
  deleteOthers?: boolean | null;
  updateByFlowIdAndCommentId?: ReadonlyArray<FlowCommentOnFlowCommentForFlowCommentCommentIdFkeyUsingFlowCommentPkeyUpdate> | null;
  updateById?: ReadonlyArray<CommentOnFlowCommentForFlowCommentCommentIdFkeyNodeIdUpdate> | null;
};
export type FlowCommentOnFlowCommentForFlowCommentCommentIdFkeyUsingFlowCommentPkeyUpdate = {
  commentId: UUID;
  flowId: UUID;
  patch: updateFlowCommentOnFlowCommentForFlowCommentCommentIdFkeyPatch;
};
export type updateFlowCommentOnFlowCommentForFlowCommentCommentIdFkeyPatch = {
  commentToCommentId?: FlowCommentCommentIdFkeyInput | null;
  flowId?: UUID | null;
  flowToFlowId?: FlowCommentFlowIdFkeyInput | null;
};
export type FlowCommentCommentIdFkeyInput = {
  connectById?: CommentNodeIdConnect | null;
  connectByRowId?: CommentCommentPkeyConnect | null;
  create?: FlowCommentCommentIdFkeyCommentCreateInput | null;
  deleteById?: CommentNodeIdDelete | null;
  deleteByRowId?: CommentCommentPkeyDelete | null;
  updateById?: FlowCommentOnFlowCommentForFlowCommentCommentIdFkeyNodeIdUpdate | null;
  updateByRowId?: CommentOnFlowCommentForFlowCommentCommentIdFkeyUsingCommentPkeyUpdate | null;
};
export type CommentOnFlowCommentForFlowCommentCommentIdFkeyUsingCommentPkeyUpdate = {
  patch: updateCommentOnFlowCommentForFlowCommentCommentIdFkeyPatch;
  rowId: UUID;
};
export type updateCommentOnFlowCommentForFlowCommentCommentIdFkeyPatch = {
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentCommentIdFkeyInverseInput | null;
  commentText?: string | null;
  commentToResponseTo?: CommentResponseToFkeyInput | null;
  commentViewedsUsingRowId?: CommentViewedCommentIdFkeyInverseInput | null;
  createdAt?: Date | null;
  flowCommentsUsingRowId?: FlowCommentCommentIdFkeyInverseInput | null;
  gwtUserId?: UUID | null;
  gwtUserToGwtUserId?: CommentGwtUserIdFkeyInput | null;
  responseTo?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentCommentIdFkeyInverseInput | null;
  rowId?: UUID | null;
  updatedAt?: Date | null;
};
export type AdminDashboardFlowCommentCommentIdFkeyInverseInput = {
  connectByFlowIdAndCommentId?: ReadonlyArray<AdminDashboardFlowCommentAdminDashboardFlowCommentPkeyConnect> | null;
  connectById?: ReadonlyArray<AdminDashboardFlowCommentNodeIdConnect> | null;
  create?: ReadonlyArray<AdminDashboardFlowCommentCommentIdFkeyAdminDashboardFlowCommentCreateInput> | null;
  deleteByFlowIdAndCommentId?: ReadonlyArray<AdminDashboardFlowCommentAdminDashboardFlowCommentPkeyDelete> | null;
  deleteById?: ReadonlyArray<AdminDashboardFlowCommentNodeIdDelete> | null;
  deleteOthers?: boolean | null;
  updateByFlowIdAndCommentId?: ReadonlyArray<AdminDashboardFlowCommentOnAdminDashboardFlowCommentForAdminDashboardFlowCommentCommentIdFkeyUsingAdminDashboardFlowCommentPkeyUpdate> | null;
  updateById?: ReadonlyArray<CommentOnAdminDashboardFlowCommentForAdminDashboardFlowCommentCommentIdFkeyNodeIdUpdate> | null;
};
export type AdminDashboardFlowCommentOnAdminDashboardFlowCommentForAdminDashboardFlowCommentCommentIdFkeyUsingAdminDashboardFlowCommentPkeyUpdate = {
  commentId: UUID;
  flowId: UUID;
  patch: updateAdminDashboardFlowCommentOnAdminDashboardFlowCommentForAdminDashboardFlowCommentCommentIdFkeyPatch;
};
export type updateAdminDashboardFlowCommentOnAdminDashboardFlowCommentForAdminDashboardFlowCommentCommentIdFkeyPatch = {
  commentToCommentId?: AdminDashboardFlowCommentCommentIdFkeyInput | null;
  flowId?: UUID | null;
  flowToFlowId?: AdminDashboardFlowCommentFlowIdFkeyInput | null;
};
export type AdminDashboardFlowCommentCommentIdFkeyInput = {
  connectById?: CommentNodeIdConnect | null;
  connectByRowId?: CommentCommentPkeyConnect | null;
  create?: AdminDashboardFlowCommentCommentIdFkeyCommentCreateInput | null;
  deleteById?: CommentNodeIdDelete | null;
  deleteByRowId?: CommentCommentPkeyDelete | null;
  updateById?: AdminDashboardFlowCommentOnAdminDashboardFlowCommentForAdminDashboardFlowCommentCommentIdFkeyNodeIdUpdate | null;
  updateByRowId?: CommentOnAdminDashboardFlowCommentForAdminDashboardFlowCommentCommentIdFkeyUsingCommentPkeyUpdate | null;
};
export type CommentOnAdminDashboardFlowCommentForAdminDashboardFlowCommentCommentIdFkeyUsingCommentPkeyUpdate = {
  patch: updateCommentOnAdminDashboardFlowCommentForAdminDashboardFlowCommentCommentIdFkeyPatch;
  rowId: UUID;
};
export type updateCommentOnAdminDashboardFlowCommentForAdminDashboardFlowCommentCommentIdFkeyPatch = {
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentCommentIdFkeyInverseInput | null;
  commentText?: string | null;
  commentToResponseTo?: CommentResponseToFkeyInput | null;
  commentViewedsUsingRowId?: CommentViewedCommentIdFkeyInverseInput | null;
  createdAt?: Date | null;
  flowCommentsUsingRowId?: FlowCommentCommentIdFkeyInverseInput | null;
  gwtUserId?: UUID | null;
  gwtUserToGwtUserId?: CommentGwtUserIdFkeyInput | null;
  responseTo?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentCommentIdFkeyInverseInput | null;
  rowId?: UUID | null;
  updatedAt?: Date | null;
};
export type ReviewerDashboardFlowCommentCommentIdFkeyInverseInput = {
  connectByFlowIdAndCommentId?: ReadonlyArray<ReviewerDashboardFlowCommentReviewerDashboardFlowCommentPkeyConnect> | null;
  connectById?: ReadonlyArray<ReviewerDashboardFlowCommentNodeIdConnect> | null;
  create?: ReadonlyArray<ReviewerDashboardFlowCommentCommentIdFkeyReviewerDashboardFlowCommentCreateInput> | null;
  deleteByFlowIdAndCommentId?: ReadonlyArray<ReviewerDashboardFlowCommentReviewerDashboardFlowCommentPkeyDelete> | null;
  deleteById?: ReadonlyArray<ReviewerDashboardFlowCommentNodeIdDelete> | null;
  deleteOthers?: boolean | null;
  updateByFlowIdAndCommentId?: ReadonlyArray<ReviewerDashboardFlowCommentOnReviewerDashboardFlowCommentForReviewerDashboardFlowCommentCommentIdFkeyUsingReviewerDashboardFlowCommentPkeyUpdate> | null;
  updateById?: ReadonlyArray<CommentOnReviewerDashboardFlowCommentForReviewerDashboardFlowCommentCommentIdFkeyNodeIdUpdate> | null;
};
export type ReviewerDashboardFlowCommentOnReviewerDashboardFlowCommentForReviewerDashboardFlowCommentCommentIdFkeyUsingReviewerDashboardFlowCommentPkeyUpdate = {
  commentId: UUID;
  flowId: UUID;
  patch: updateReviewerDashboardFlowCommentOnReviewerDashboardFlowCommentForReviewerDashboardFlowCommentCommentIdFkeyPatch;
};
export type updateReviewerDashboardFlowCommentOnReviewerDashboardFlowCommentForReviewerDashboardFlowCommentCommentIdFkeyPatch = {
  commentToCommentId?: ReviewerDashboardFlowCommentCommentIdFkeyInput | null;
  flowId?: UUID | null;
  flowToFlowId?: ReviewerDashboardFlowCommentFlowIdFkeyInput | null;
};
export type ReviewerDashboardFlowCommentCommentIdFkeyInput = {
  connectById?: CommentNodeIdConnect | null;
  connectByRowId?: CommentCommentPkeyConnect | null;
  create?: ReviewerDashboardFlowCommentCommentIdFkeyCommentCreateInput | null;
  deleteById?: CommentNodeIdDelete | null;
  deleteByRowId?: CommentCommentPkeyDelete | null;
  updateById?: ReviewerDashboardFlowCommentOnReviewerDashboardFlowCommentForReviewerDashboardFlowCommentCommentIdFkeyNodeIdUpdate | null;
  updateByRowId?: CommentOnReviewerDashboardFlowCommentForReviewerDashboardFlowCommentCommentIdFkeyUsingCommentPkeyUpdate | null;
};
export type CommentOnReviewerDashboardFlowCommentForReviewerDashboardFlowCommentCommentIdFkeyUsingCommentPkeyUpdate = {
  patch: updateCommentOnReviewerDashboardFlowCommentForReviewerDashboardFlowCommentCommentIdFkeyPatch;
  rowId: UUID;
};
export type updateCommentOnReviewerDashboardFlowCommentForReviewerDashboardFlowCommentCommentIdFkeyPatch = {
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentCommentIdFkeyInverseInput | null;
  commentText?: string | null;
  commentToResponseTo?: CommentResponseToFkeyInput | null;
  commentViewedsUsingRowId?: CommentViewedCommentIdFkeyInverseInput | null;
  createdAt?: Date | null;
  flowCommentsUsingRowId?: FlowCommentCommentIdFkeyInverseInput | null;
  gwtUserId?: UUID | null;
  gwtUserToGwtUserId?: CommentGwtUserIdFkeyInput | null;
  responseTo?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentCommentIdFkeyInverseInput | null;
  rowId?: UUID | null;
  updatedAt?: Date | null;
};
export type CommentViewedCommentIdFkeyInverseInput = {
  connectByGwtUserIdAndCommentId?: ReadonlyArray<CommentViewedFlowLastViewedPkeyConnect> | null;
  connectById?: ReadonlyArray<CommentViewedNodeIdConnect> | null;
  create?: ReadonlyArray<CommentViewedCommentIdFkeyCommentViewedCreateInput> | null;
  deleteByGwtUserIdAndCommentId?: ReadonlyArray<CommentViewedFlowLastViewedPkeyDelete> | null;
  deleteById?: ReadonlyArray<CommentViewedNodeIdDelete> | null;
  deleteOthers?: boolean | null;
  updateByGwtUserIdAndCommentId?: ReadonlyArray<CommentViewedOnCommentViewedForCommentViewedCommentIdFkeyUsingFlowLastViewedPkeyUpdate> | null;
  updateById?: ReadonlyArray<CommentOnCommentViewedForCommentViewedCommentIdFkeyNodeIdUpdate> | null;
};
export type CommentViewedOnCommentViewedForCommentViewedCommentIdFkeyUsingFlowLastViewedPkeyUpdate = {
  commentId: UUID;
  gwtUserId: UUID;
  patch: updateCommentViewedOnCommentViewedForCommentViewedCommentIdFkeyPatch;
};
export type updateCommentViewedOnCommentViewedForCommentViewedCommentIdFkeyPatch = {
  commentToCommentId?: CommentViewedCommentIdFkeyInput | null;
  gwtUserId?: UUID | null;
  gwtUserToGwtUserId?: CommentViewedGwtUserIdFkeyInput | null;
};
export type CommentOnCommentViewedForCommentViewedCommentIdFkeyNodeIdUpdate = {
  id: string;
  patch: CommentViewedPatch;
};
export type CommentViewedPatch = {
  commentId?: UUID | null;
  commentToCommentId?: CommentViewedCommentIdFkeyInput | null;
  gwtUserId?: UUID | null;
  gwtUserToGwtUserId?: CommentViewedGwtUserIdFkeyInput | null;
};
export type CommentViewedCommentIdFkeyCommentViewedCreateInput = {
  commentToCommentId?: CommentViewedCommentIdFkeyInput | null;
  gwtUserId?: UUID | null;
  gwtUserToGwtUserId?: CommentViewedGwtUserIdFkeyInput | null;
};
export type ReviewerDashboardFlowCommentOnReviewerDashboardFlowCommentForReviewerDashboardFlowCommentCommentIdFkeyNodeIdUpdate = {
  id: string;
  patch: CommentPatch;
};
export type CommentPatch = {
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentCommentIdFkeyInverseInput | null;
  commentText?: string | null;
  commentToResponseTo?: CommentResponseToFkeyInput | null;
  commentViewedsUsingRowId?: CommentViewedCommentIdFkeyInverseInput | null;
  createdAt?: Date | null;
  flowCommentsUsingRowId?: FlowCommentCommentIdFkeyInverseInput | null;
  gwtUserId?: UUID | null;
  gwtUserToGwtUserId?: CommentGwtUserIdFkeyInput | null;
  responseTo?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentCommentIdFkeyInverseInput | null;
  rowId?: UUID | null;
  updatedAt?: Date | null;
};
export type ReviewerDashboardFlowCommentCommentIdFkeyCommentCreateInput = {
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentCommentIdFkeyInverseInput | null;
  commentText: string;
  commentToResponseTo?: CommentResponseToFkeyInput | null;
  commentViewedsUsingRowId?: CommentViewedCommentIdFkeyInverseInput | null;
  createdAt?: Date | null;
  flowCommentsUsingRowId?: FlowCommentCommentIdFkeyInverseInput | null;
  gwtUserId?: UUID | null;
  gwtUserToGwtUserId?: CommentGwtUserIdFkeyInput | null;
  responseTo?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentCommentIdFkeyInverseInput | null;
  rowId?: UUID | null;
  updatedAt?: Date | null;
};
export type CommentOnReviewerDashboardFlowCommentForReviewerDashboardFlowCommentCommentIdFkeyNodeIdUpdate = {
  id: string;
  patch: ReviewerDashboardFlowCommentPatch;
};
export type ReviewerDashboardFlowCommentPatch = {
  commentId?: UUID | null;
  commentToCommentId?: ReviewerDashboardFlowCommentCommentIdFkeyInput | null;
  flowId?: UUID | null;
  flowToFlowId?: ReviewerDashboardFlowCommentFlowIdFkeyInput | null;
};
export type ReviewerDashboardFlowCommentCommentIdFkeyReviewerDashboardFlowCommentCreateInput = {
  commentToCommentId?: ReviewerDashboardFlowCommentCommentIdFkeyInput | null;
  flowId?: UUID | null;
  flowToFlowId?: ReviewerDashboardFlowCommentFlowIdFkeyInput | null;
};
export type AdminDashboardFlowCommentOnAdminDashboardFlowCommentForAdminDashboardFlowCommentCommentIdFkeyNodeIdUpdate = {
  id: string;
  patch: CommentPatch;
};
export type AdminDashboardFlowCommentCommentIdFkeyCommentCreateInput = {
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentCommentIdFkeyInverseInput | null;
  commentText: string;
  commentToResponseTo?: CommentResponseToFkeyInput | null;
  commentViewedsUsingRowId?: CommentViewedCommentIdFkeyInverseInput | null;
  createdAt?: Date | null;
  flowCommentsUsingRowId?: FlowCommentCommentIdFkeyInverseInput | null;
  gwtUserId?: UUID | null;
  gwtUserToGwtUserId?: CommentGwtUserIdFkeyInput | null;
  responseTo?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentCommentIdFkeyInverseInput | null;
  rowId?: UUID | null;
  updatedAt?: Date | null;
};
export type CommentOnAdminDashboardFlowCommentForAdminDashboardFlowCommentCommentIdFkeyNodeIdUpdate = {
  id: string;
  patch: AdminDashboardFlowCommentPatch;
};
export type AdminDashboardFlowCommentPatch = {
  commentId?: UUID | null;
  commentToCommentId?: AdminDashboardFlowCommentCommentIdFkeyInput | null;
  flowId?: UUID | null;
  flowToFlowId?: AdminDashboardFlowCommentFlowIdFkeyInput | null;
};
export type AdminDashboardFlowCommentCommentIdFkeyAdminDashboardFlowCommentCreateInput = {
  commentToCommentId?: AdminDashboardFlowCommentCommentIdFkeyInput | null;
  flowId?: UUID | null;
  flowToFlowId?: AdminDashboardFlowCommentFlowIdFkeyInput | null;
};
export type FlowCommentOnFlowCommentForFlowCommentCommentIdFkeyNodeIdUpdate = {
  id: string;
  patch: CommentPatch;
};
export type FlowCommentCommentIdFkeyCommentCreateInput = {
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentCommentIdFkeyInverseInput | null;
  commentText: string;
  commentToResponseTo?: CommentResponseToFkeyInput | null;
  commentViewedsUsingRowId?: CommentViewedCommentIdFkeyInverseInput | null;
  createdAt?: Date | null;
  flowCommentsUsingRowId?: FlowCommentCommentIdFkeyInverseInput | null;
  gwtUserId?: UUID | null;
  gwtUserToGwtUserId?: CommentGwtUserIdFkeyInput | null;
  responseTo?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentCommentIdFkeyInverseInput | null;
  rowId?: UUID | null;
  updatedAt?: Date | null;
};
export type CommentOnFlowCommentForFlowCommentCommentIdFkeyNodeIdUpdate = {
  id: string;
  patch: FlowCommentPatch;
};
export type FlowCommentPatch = {
  commentId?: UUID | null;
  commentToCommentId?: FlowCommentCommentIdFkeyInput | null;
  flowId?: UUID | null;
  flowToFlowId?: FlowCommentFlowIdFkeyInput | null;
};
export type FlowCommentCommentIdFkeyFlowCommentCreateInput = {
  commentToCommentId?: FlowCommentCommentIdFkeyInput | null;
  flowId?: UUID | null;
  flowToFlowId?: FlowCommentFlowIdFkeyInput | null;
};
export type CommentOnCommentForCommentResponseToFkeyNodeIdUpdate = {
  id: string;
  patch: CommentPatch;
};
export type CommentResponseToFkeyCommentCreateInput = {
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentCommentIdFkeyInverseInput | null;
  commentText: string;
  commentToResponseTo?: CommentResponseToFkeyInput | null;
  commentViewedsUsingRowId?: CommentViewedCommentIdFkeyInverseInput | null;
  createdAt?: Date | null;
  flowCommentsUsingRowId?: FlowCommentCommentIdFkeyInverseInput | null;
  gwtUserId?: UUID | null;
  gwtUserToGwtUserId?: CommentGwtUserIdFkeyInput | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentCommentIdFkeyInverseInput | null;
  rowId?: UUID | null;
  updatedAt?: Date | null;
};
export type CommentViewedOnCommentViewedForCommentViewedCommentIdFkeyNodeIdUpdate = {
  id: string;
  patch: CommentPatch;
};
export type CommentViewedCommentIdFkeyCommentCreateInput = {
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentCommentIdFkeyInverseInput | null;
  commentText: string;
  commentToResponseTo?: CommentResponseToFkeyInput | null;
  commentViewedsUsingRowId?: CommentViewedCommentIdFkeyInverseInput | null;
  createdAt?: Date | null;
  flowCommentsUsingRowId?: FlowCommentCommentIdFkeyInverseInput | null;
  gwtUserId?: UUID | null;
  gwtUserToGwtUserId?: CommentGwtUserIdFkeyInput | null;
  responseTo?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentCommentIdFkeyInverseInput | null;
  rowId?: UUID | null;
  updatedAt?: Date | null;
};
export type GwtUserOnCommentViewedForCommentViewedGwtUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: CommentViewedPatch;
};
export type CommentViewedGwtUserIdFkeyCommentViewedCreateInput = {
  commentId?: UUID | null;
  commentToCommentId?: CommentViewedCommentIdFkeyInput | null;
  gwtUserToGwtUserId?: CommentViewedGwtUserIdFkeyInput | null;
};
export type FlowFavoriteOnFlowFavoriteForFlowFavoriteGwtUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: GwtUserPatch;
};
export type FlowFavoriteGwtUserIdFkeyGwtUserCreateInput = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email: string;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName: string;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName: string;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId: string;
  lastName: string;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId: UUID;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type GwtUserOnFlowFavoriteForFlowFavoriteGwtUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: FlowFavoritePatch;
};
export type FlowFavoritePatch = {
  createdAt?: Date | null;
  flowId?: UUID | null;
  flowToFlowId?: FlowFavoriteFlowIdFkeyInput | null;
  gwtUserId?: UUID | null;
  gwtUserToGwtUserId?: FlowFavoriteGwtUserIdFkeyInput | null;
};
export type FlowFavoriteGwtUserIdFkeyFlowFavoriteCreateInput = {
  createdAt?: Date | null;
  flowId?: UUID | null;
  flowToFlowId?: FlowFavoriteFlowIdFkeyInput | null;
  gwtUserToGwtUserId?: FlowFavoriteGwtUserIdFkeyInput | null;
};
export type FlowReviewerOnFlowReviewerForFlowReviewerReviewerIdFkeyNodeIdUpdate = {
  id: string;
  patch: GwtUserPatch;
};
export type FlowReviewerReviewerIdFkeyGwtUserCreateInput = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email: string;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName: string;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName: string;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId: string;
  lastName: string;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId: UUID;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type GwtUserOnFlowReviewerForFlowReviewerReviewerIdFkeyNodeIdUpdate = {
  id: string;
  patch: FlowReviewerPatch;
};
export type FlowReviewerPatch = {
  flowId?: UUID | null;
  flowToFlowId?: FlowReviewerFlowIdFkeyInput | null;
  gwtUserToReviewerId?: FlowReviewerReviewerIdFkeyInput | null;
  reviewerId?: UUID | null;
};
export type FlowReviewerReviewerIdFkeyFlowReviewerCreateInput = {
  flowId?: UUID | null;
  flowToFlowId?: FlowReviewerFlowIdFkeyInput | null;
  gwtUserToReviewerId?: FlowReviewerReviewerIdFkeyInput | null;
};
export type ModuleSubmissionOnModuleSubmissionForModuleSubmissionAcceptedByIdFkeyNodeIdUpdate = {
  id: string;
  patch: GwtUserPatch;
};
export type ModuleSubmissionAcceptedByIdFkeyGwtUserCreateInput = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email: string;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName: string;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName: string;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId: string;
  lastName: string;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId: UUID;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type ModuleOnModuleSubmissionForModuleSubmissionModuleFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: ModuleSubmissionPatch;
};
export type ModuleSubmissionPatch = {
  acceptedById?: UUID | null;
  attempt?: number | null;
  createdAt?: Date | null;
  galleryAssetSubmissionId?: UUID | null;
  gwtUserToAcceptedById?: ModuleSubmissionAcceptedByIdFkeyInput | null;
  moduleFlowId?: UUID | null;
  moduleToModuleFlowId?: ModuleSubmissionModuleFlowIdFkeyInput | null;
  submissionStatus?: SubmissionStatus | null;
};
export type ModuleSubmissionModuleFlowIdFkeyModuleSubmissionCreateInput = {
  acceptedById?: UUID | null;
  attempt: number;
  createdAt?: Date | null;
  galleryAssetSubmissionId: UUID;
  gwtUserToAcceptedById?: ModuleSubmissionAcceptedByIdFkeyInput | null;
  moduleToModuleFlowId?: ModuleSubmissionModuleFlowIdFkeyInput | null;
  submissionStatus?: SubmissionStatus | null;
};
export type ModuleGroupLinksModuleFlowIdFkeyInverseInput = {
  connectById?: ReadonlyArray<ModuleGroupLinkNodeIdConnect> | null;
  connectByModuleGroupFlowIdAndModuleFlowId?: ReadonlyArray<ModuleGroupLinkModuleGroupLinksPkeyConnect> | null;
  create?: ReadonlyArray<ModuleGroupLinksModuleFlowIdFkeyModuleGroupLinksCreateInput> | null;
  deleteById?: ReadonlyArray<ModuleGroupLinkNodeIdDelete> | null;
  deleteByModuleGroupFlowIdAndModuleFlowId?: ReadonlyArray<ModuleGroupLinkModuleGroupLinksPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<ModuleOnModuleGroupLinkForModuleGroupLinksModuleFlowIdFkeyNodeIdUpdate> | null;
  updateByModuleGroupFlowIdAndModuleFlowId?: ReadonlyArray<ModuleGroupLinkOnModuleGroupLinkForModuleGroupLinksModuleFlowIdFkeyUsingModuleGroupLinksPkeyUpdate> | null;
};
export type ModuleGroupLinkModuleGroupLinksPkeyConnect = {
  moduleFlowId: UUID;
  moduleGroupFlowId: UUID;
};
export type ModuleGroupLinkNodeIdConnect = {
  id: string;
};
export type ModuleGroupLinkModuleGroupLinksPkeyDelete = {
  moduleFlowId: UUID;
  moduleGroupFlowId: UUID;
};
export type ModuleGroupLinkNodeIdDelete = {
  id: string;
};
export type ModuleGroupLinkOnModuleGroupLinkForModuleGroupLinksModuleFlowIdFkeyUsingModuleGroupLinksPkeyUpdate = {
  moduleFlowId: UUID;
  moduleGroupFlowId: UUID;
  patch: updateModuleGroupLinkOnModuleGroupLinkForModuleGroupLinksModuleFlowIdFkeyPatch;
};
export type updateModuleGroupLinkOnModuleGroupLinkForModuleGroupLinksModuleFlowIdFkeyPatch = {
  groupIndex?: number | null;
  moduleGroupFlowId?: UUID | null;
  moduleGroupToModuleGroupFlowId?: ModuleGroupLinksModuleGroupFlowIdFkeyInput | null;
  moduleToModuleFlowId?: ModuleGroupLinksModuleFlowIdFkeyInput | null;
};
export type ModuleGroupLinksModuleGroupFlowIdFkeyInput = {
  connectByFlowId?: ModuleGroupModuleGroupPkeyConnect | null;
  connectById?: ModuleGroupNodeIdConnect | null;
  create?: ModuleGroupLinksModuleGroupFlowIdFkeyModuleGroupCreateInput | null;
  deleteByFlowId?: ModuleGroupModuleGroupPkeyDelete | null;
  deleteById?: ModuleGroupNodeIdDelete | null;
  update?: FlowPatch | null;
  updateByFlowId?: ModuleGroupOnModuleGroupLinkForModuleGroupLinksModuleGroupFlowIdFkeyUsingModuleGroupPkeyUpdate | null;
  updateById?: ModuleGroupLinkOnModuleGroupLinkForModuleGroupLinksModuleGroupFlowIdFkeyNodeIdUpdate | null;
};
export type ModuleGroupOnModuleGroupLinkForModuleGroupLinksModuleGroupFlowIdFkeyUsingModuleGroupPkeyUpdate = {
  flowId: UUID;
  patch: updateModuleGroupOnModuleGroupLinkForModuleGroupLinksModuleGroupFlowIdFkeyPatch;
};
export type updateModuleGroupOnModuleGroupLinkForModuleGroupLinksModuleGroupFlowIdFkeyPatch = {
  archivedAt?: Date | null;
  flowId?: UUID | null;
  flowToFlowId?: ModuleGroupFlowIdFkeyInput | null;
  moduleGroupLinksUsingFlowId?: ModuleGroupLinksModuleGroupFlowIdFkeyInverseInput | null;
  modulesUsingFlowId?: ModuleModuleGroupIdFkeyInverseInput | null;
  shuffle?: boolean | null;
  versionNumber?: BigFloat | null;
};
export type ModuleGroupLinksModuleGroupFlowIdFkeyInverseInput = {
  connectById?: ReadonlyArray<ModuleGroupLinkNodeIdConnect> | null;
  connectByModuleGroupFlowIdAndModuleFlowId?: ReadonlyArray<ModuleGroupLinkModuleGroupLinksPkeyConnect> | null;
  create?: ReadonlyArray<ModuleGroupLinksModuleGroupFlowIdFkeyModuleGroupLinksCreateInput> | null;
  deleteById?: ReadonlyArray<ModuleGroupLinkNodeIdDelete> | null;
  deleteByModuleGroupFlowIdAndModuleFlowId?: ReadonlyArray<ModuleGroupLinkModuleGroupLinksPkeyDelete> | null;
  deleteOthers?: boolean | null;
  updateById?: ReadonlyArray<ModuleGroupOnModuleGroupLinkForModuleGroupLinksModuleGroupFlowIdFkeyNodeIdUpdate> | null;
  updateByModuleGroupFlowIdAndModuleFlowId?: ReadonlyArray<ModuleGroupLinkOnModuleGroupLinkForModuleGroupLinksModuleGroupFlowIdFkeyUsingModuleGroupLinksPkeyUpdate> | null;
};
export type ModuleGroupLinkOnModuleGroupLinkForModuleGroupLinksModuleGroupFlowIdFkeyUsingModuleGroupLinksPkeyUpdate = {
  moduleFlowId: UUID;
  moduleGroupFlowId: UUID;
  patch: updateModuleGroupLinkOnModuleGroupLinkForModuleGroupLinksModuleGroupFlowIdFkeyPatch;
};
export type updateModuleGroupLinkOnModuleGroupLinkForModuleGroupLinksModuleGroupFlowIdFkeyPatch = {
  groupIndex?: number | null;
  moduleFlowId?: UUID | null;
  moduleGroupToModuleGroupFlowId?: ModuleGroupLinksModuleGroupFlowIdFkeyInput | null;
  moduleToModuleFlowId?: ModuleGroupLinksModuleFlowIdFkeyInput | null;
};
export type ModuleGroupLinksModuleFlowIdFkeyInput = {
  connectByFlowId?: ModuleModulePkeyConnect | null;
  connectById?: ModuleNodeIdConnect | null;
  create?: ModuleGroupLinksModuleFlowIdFkeyModuleCreateInput | null;
  deleteByFlowId?: ModuleModulePkeyDelete | null;
  deleteById?: ModuleNodeIdDelete | null;
  update?: FlowPatch | null;
  updateByFlowId?: ModuleOnModuleGroupLinkForModuleGroupLinksModuleFlowIdFkeyUsingModulePkeyUpdate | null;
  updateById?: ModuleGroupLinkOnModuleGroupLinkForModuleGroupLinksModuleFlowIdFkeyNodeIdUpdate | null;
};
export type ModuleOnModuleGroupLinkForModuleGroupLinksModuleFlowIdFkeyUsingModulePkeyUpdate = {
  flowId: UUID;
  patch: updateModuleOnModuleGroupLinkForModuleGroupLinksModuleFlowIdFkeyPatch;
};
export type updateModuleOnModuleGroupLinkForModuleGroupLinksModuleFlowIdFkeyPatch = {
  attempts?: number | null;
  autoStartNextModuleFlowId?: UUID | null;
  currentAttempt?: number | null;
  flowId?: UUID | null;
  flowToFlowId?: ModuleFlowIdFkeyInput | null;
  galleryAssetComponentsId?: UUID | null;
  galleryAssetDeliverableId?: UUID | null;
  galleryCollectionSubmissionsId?: UUID | null;
  groupModulePreviousModuleFlowId?: UUID | null;
  moduleGroupId?: UUID | null;
  moduleGroupLinksUsingFlowId?: ModuleGroupLinksModuleFlowIdFkeyInverseInput | null;
  moduleGroupToModuleGroupId?: ModuleModuleGroupIdFkeyInput | null;
  moduleSubmissionsUsingFlowId?: ModuleSubmissionModuleFlowIdFkeyInverseInput | null;
  moduleToAutoStartNextModuleFlowId?: ModuleAutoStartNextModuleFlowIdFkeyInput | null;
  moduleToGroupModulePreviousModuleFlowId?: ModuleGroupModulePreviousModuleFlowIdFkeyInput | null;
  programType?: ProgramType | null;
  softwareType?: SoftwareType | null;
  submissionLock?: boolean | null;
  tasksUsingFlowId?: TaskModuleFlowIdFkeyInverseInput | null;
  templateFlowId?: UUID | null;
  templateToTemplateFlowId?: ModuleTemplateFlowIdFkeyInput | null;
  versionNumber?: BigFloat | null;
};
export type ModuleGroupLinkOnModuleGroupLinkForModuleGroupLinksModuleFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: ModulePatch;
};
export type ModulePatch = {
  attempts?: number | null;
  autoStartNextModuleFlowId?: UUID | null;
  currentAttempt?: number | null;
  flowId?: UUID | null;
  flowToFlowId?: ModuleFlowIdFkeyInput | null;
  galleryAssetComponentsId?: UUID | null;
  galleryAssetDeliverableId?: UUID | null;
  galleryCollectionSubmissionsId?: UUID | null;
  groupModulePreviousModuleFlowId?: UUID | null;
  moduleGroupId?: UUID | null;
  moduleGroupLinksUsingFlowId?: ModuleGroupLinksModuleFlowIdFkeyInverseInput | null;
  moduleGroupToModuleGroupId?: ModuleModuleGroupIdFkeyInput | null;
  moduleSubmissionsUsingFlowId?: ModuleSubmissionModuleFlowIdFkeyInverseInput | null;
  moduleToAutoStartNextModuleFlowId?: ModuleAutoStartNextModuleFlowIdFkeyInput | null;
  moduleToGroupModulePreviousModuleFlowId?: ModuleGroupModulePreviousModuleFlowIdFkeyInput | null;
  programType?: ProgramType | null;
  softwareType?: SoftwareType | null;
  submissionLock?: boolean | null;
  tasksUsingFlowId?: TaskModuleFlowIdFkeyInverseInput | null;
  templateFlowId?: UUID | null;
  templateToTemplateFlowId?: ModuleTemplateFlowIdFkeyInput | null;
  versionNumber?: BigFloat | null;
};
export type ModuleGroupLinksModuleFlowIdFkeyModuleCreateInput = {
  attempts?: number | null;
  autoStartNextModuleFlowId?: UUID | null;
  currentAttempt?: number | null;
  flowId?: UUID | null;
  flowToFlowId?: ModuleFlowIdFkeyInput | null;
  galleryAssetComponentsId?: UUID | null;
  galleryAssetDeliverableId?: UUID | null;
  galleryCollectionSubmissionsId?: UUID | null;
  groupModulePreviousModuleFlowId?: UUID | null;
  moduleGroupId?: UUID | null;
  moduleGroupLinksUsingFlowId?: ModuleGroupLinksModuleFlowIdFkeyInverseInput | null;
  moduleGroupToModuleGroupId?: ModuleModuleGroupIdFkeyInput | null;
  moduleSubmissionsUsingFlowId?: ModuleSubmissionModuleFlowIdFkeyInverseInput | null;
  moduleToAutoStartNextModuleFlowId?: ModuleAutoStartNextModuleFlowIdFkeyInput | null;
  moduleToGroupModulePreviousModuleFlowId?: ModuleGroupModulePreviousModuleFlowIdFkeyInput | null;
  programType?: ProgramType | null;
  softwareType?: SoftwareType | null;
  submissionLock?: boolean | null;
  tasksUsingFlowId?: TaskModuleFlowIdFkeyInverseInput | null;
  templateFlowId?: UUID | null;
  templateToTemplateFlowId?: ModuleTemplateFlowIdFkeyInput | null;
  versionNumber?: BigFloat | null;
};
export type FlowPatch = {
  activitiesUsingRowId?: ActivityFlowIdFkInverseInput | null;
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentFlowIdFkeyInverseInput | null;
  assignedAt?: Date | null;
  assignedById?: UUID | null;
  assignedToId?: UUID | null;
  assignmentDueAtStatus?: AssignmentDueAtStatus | null;
  assignmentStatus?: AssignmentStatus | null;
  completedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  dueAt?: Date | null;
  durationSeconds?: number | null;
  flowCommentsUsingRowId?: FlowCommentFlowIdFkeyInverseInput | null;
  flowFavoritesUsingRowId?: FlowFavoriteFlowIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerFlowIdFkeyInverseInput | null;
  flowType?: FlowType | null;
  gwtUserToAssignedById?: FlowAssignedByIdFkeyInput | null;
  gwtUserToAssignedToId?: FlowAssignedToIdFkeyInput | null;
  gwtUserToLastModifiedById?: FlowLastModifiedByIdFkeyInput | null;
  gwtUserToOwnerId?: FlowOwnerIdFkeyInput | null;
  lastModifiedById?: UUID | null;
  moduleGroupUsingRowId?: ModuleGroupFlowIdFkeyInverseInput | null;
  moduleUsingRowId?: ModuleFlowIdFkeyInverseInput | null;
  name?: string | null;
  ownerId?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentFlowIdFkeyInverseInput | null;
  rowId?: UUID | null;
  startedAt?: Date | null;
  statusChangedAt?: Date | null;
  taskUsingRowId?: TaskFlowIdFkeyInverseInput | null;
  templateUsingRowId?: TemplateFlowIdFkeyInverseInput | null;
  updatedAt?: Date | null;
  workflowType?: WorkflowType | null;
};
export type ModuleGroupOnModuleGroupLinkForModuleGroupLinksModuleGroupFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: ModuleGroupLinkPatch;
};
export type ModuleGroupLinkPatch = {
  groupIndex?: number | null;
  moduleFlowId?: UUID | null;
  moduleGroupFlowId?: UUID | null;
  moduleGroupToModuleGroupFlowId?: ModuleGroupLinksModuleGroupFlowIdFkeyInput | null;
  moduleToModuleFlowId?: ModuleGroupLinksModuleFlowIdFkeyInput | null;
};
export type ModuleGroupLinksModuleGroupFlowIdFkeyModuleGroupLinksCreateInput = {
  groupIndex: number;
  moduleFlowId?: UUID | null;
  moduleGroupToModuleGroupFlowId?: ModuleGroupLinksModuleGroupFlowIdFkeyInput | null;
  moduleToModuleFlowId?: ModuleGroupLinksModuleFlowIdFkeyInput | null;
};
export type ModuleGroupLinkOnModuleGroupLinkForModuleGroupLinksModuleGroupFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: ModuleGroupPatch;
};
export type ModuleGroupPatch = {
  archivedAt?: Date | null;
  flowId?: UUID | null;
  flowToFlowId?: ModuleGroupFlowIdFkeyInput | null;
  moduleGroupLinksUsingFlowId?: ModuleGroupLinksModuleGroupFlowIdFkeyInverseInput | null;
  modulesUsingFlowId?: ModuleModuleGroupIdFkeyInverseInput | null;
  shuffle?: boolean | null;
  versionNumber?: BigFloat | null;
};
export type ModuleGroupLinksModuleGroupFlowIdFkeyModuleGroupCreateInput = {
  archivedAt?: Date | null;
  flowId?: UUID | null;
  flowToFlowId?: ModuleGroupFlowIdFkeyInput | null;
  moduleGroupLinksUsingFlowId?: ModuleGroupLinksModuleGroupFlowIdFkeyInverseInput | null;
  modulesUsingFlowId?: ModuleModuleGroupIdFkeyInverseInput | null;
  shuffle?: boolean | null;
  versionNumber?: BigFloat | null;
};
export type ModuleOnModuleGroupLinkForModuleGroupLinksModuleFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: ModuleGroupLinkPatch;
};
export type ModuleGroupLinksModuleFlowIdFkeyModuleGroupLinksCreateInput = {
  groupIndex: number;
  moduleGroupFlowId?: UUID | null;
  moduleGroupToModuleGroupFlowId?: ModuleGroupLinksModuleGroupFlowIdFkeyInput | null;
  moduleToModuleFlowId?: ModuleGroupLinksModuleFlowIdFkeyInput | null;
};
export type TaskOnTaskForTaskModuleFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: ModulePatch;
};
export type TaskModuleFlowIdFkeyModuleCreateInput = {
  attempts?: number | null;
  autoStartNextModuleFlowId?: UUID | null;
  currentAttempt?: number | null;
  flowId?: UUID | null;
  flowToFlowId?: ModuleFlowIdFkeyInput | null;
  galleryAssetComponentsId?: UUID | null;
  galleryAssetDeliverableId?: UUID | null;
  galleryCollectionSubmissionsId?: UUID | null;
  groupModulePreviousModuleFlowId?: UUID | null;
  moduleGroupId?: UUID | null;
  moduleGroupLinksUsingFlowId?: ModuleGroupLinksModuleFlowIdFkeyInverseInput | null;
  moduleGroupToModuleGroupId?: ModuleModuleGroupIdFkeyInput | null;
  moduleSubmissionsUsingFlowId?: ModuleSubmissionModuleFlowIdFkeyInverseInput | null;
  moduleToAutoStartNextModuleFlowId?: ModuleAutoStartNextModuleFlowIdFkeyInput | null;
  moduleToGroupModulePreviousModuleFlowId?: ModuleGroupModulePreviousModuleFlowIdFkeyInput | null;
  programType?: ProgramType | null;
  softwareType?: SoftwareType | null;
  submissionLock?: boolean | null;
  tasksUsingFlowId?: TaskModuleFlowIdFkeyInverseInput | null;
  templateFlowId?: UUID | null;
  templateToTemplateFlowId?: ModuleTemplateFlowIdFkeyInput | null;
  versionNumber?: BigFloat | null;
};
export type TemplateOnTaskForTaskTemplateFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: TaskPatch;
};
export type TaskPatch = {
  complete?: boolean | null;
  flowId?: UUID | null;
  flowToFlowId?: TaskFlowIdFkeyInput | null;
  moduleFlowId?: UUID | null;
  moduleToModuleFlowId?: TaskModuleFlowIdFkeyInput | null;
  priority?: number | null;
  templateFlowId?: UUID | null;
  templateToTemplateFlowId?: TaskTemplateFlowIdFkeyInput | null;
};
export type TaskTemplateFlowIdFkeyTaskCreateInput = {
  complete?: boolean | null;
  flowId?: UUID | null;
  flowToFlowId?: TaskFlowIdFkeyInput | null;
  moduleFlowId?: UUID | null;
  moduleToModuleFlowId?: TaskModuleFlowIdFkeyInput | null;
  priority?: number | null;
  templateToTemplateFlowId?: TaskTemplateFlowIdFkeyInput | null;
};
export type TaskOnTaskForTaskTemplateFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: TemplatePatch;
};
export type TemplatePatch = {
  flowId?: UUID | null;
  flowToFlowId?: TemplateFlowIdFkeyInput | null;
  modulesUsingFlowId?: ModuleTemplateFlowIdFkeyInverseInput | null;
  tasksUsingFlowId?: TaskTemplateFlowIdFkeyInverseInput | null;
};
export type TaskTemplateFlowIdFkeyTemplateCreateInput = {
  flowId?: UUID | null;
  flowToFlowId?: TemplateFlowIdFkeyInput | null;
  modulesUsingFlowId?: ModuleTemplateFlowIdFkeyInverseInput | null;
  tasksUsingFlowId?: TaskTemplateFlowIdFkeyInverseInput | null;
};
export type ModuleOnTaskForTaskModuleFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: TaskPatch;
};
export type TaskModuleFlowIdFkeyTaskCreateInput = {
  complete?: boolean | null;
  flowId?: UUID | null;
  flowToFlowId?: TaskFlowIdFkeyInput | null;
  moduleToModuleFlowId?: TaskModuleFlowIdFkeyInput | null;
  priority?: number | null;
  templateFlowId?: UUID | null;
  templateToTemplateFlowId?: TaskTemplateFlowIdFkeyInput | null;
};
export type ModuleGroupOnModuleForModuleModuleGroupIdFkeyNodeIdUpdate = {
  id: string;
  patch: ModulePatch;
};
export type ModuleModuleGroupIdFkeyModuleCreateInput = {
  attempts?: number | null;
  autoStartNextModuleFlowId?: UUID | null;
  currentAttempt?: number | null;
  flowId?: UUID | null;
  flowToFlowId?: ModuleFlowIdFkeyInput | null;
  galleryAssetComponentsId?: UUID | null;
  galleryAssetDeliverableId?: UUID | null;
  galleryCollectionSubmissionsId?: UUID | null;
  groupModulePreviousModuleFlowId?: UUID | null;
  moduleGroupLinksUsingFlowId?: ModuleGroupLinksModuleFlowIdFkeyInverseInput | null;
  moduleGroupToModuleGroupId?: ModuleModuleGroupIdFkeyInput | null;
  moduleSubmissionsUsingFlowId?: ModuleSubmissionModuleFlowIdFkeyInverseInput | null;
  moduleToAutoStartNextModuleFlowId?: ModuleAutoStartNextModuleFlowIdFkeyInput | null;
  moduleToGroupModulePreviousModuleFlowId?: ModuleGroupModulePreviousModuleFlowIdFkeyInput | null;
  programType?: ProgramType | null;
  softwareType?: SoftwareType | null;
  submissionLock?: boolean | null;
  tasksUsingFlowId?: TaskModuleFlowIdFkeyInverseInput | null;
  templateFlowId?: UUID | null;
  templateToTemplateFlowId?: ModuleTemplateFlowIdFkeyInput | null;
  versionNumber?: BigFloat | null;
};
export type FlowOnModuleGroupForModuleGroupFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: ModuleGroupPatch;
};
export type ModuleGroupFlowIdFkeyModuleGroupCreateInput = {
  archivedAt?: Date | null;
  flowToFlowId?: ModuleGroupFlowIdFkeyInput | null;
  moduleGroupLinksUsingFlowId?: ModuleGroupLinksModuleGroupFlowIdFkeyInverseInput | null;
  modulesUsingFlowId?: ModuleModuleGroupIdFkeyInverseInput | null;
  shuffle?: boolean | null;
  versionNumber?: BigFloat | null;
};
export type FlowFavoriteOnFlowFavoriteForFlowFavoriteFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: FlowPatch;
};
export type FlowFavoriteFlowIdFkeyFlowCreateInput = {
  activitiesUsingRowId?: ActivityFlowIdFkInverseInput | null;
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentFlowIdFkeyInverseInput | null;
  assignedAt?: Date | null;
  assignedById?: UUID | null;
  assignedToId?: UUID | null;
  assignmentDueAtStatus?: AssignmentDueAtStatus | null;
  assignmentStatus?: AssignmentStatus | null;
  completedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  dueAt?: Date | null;
  durationSeconds?: number | null;
  flowCommentsUsingRowId?: FlowCommentFlowIdFkeyInverseInput | null;
  flowFavoritesUsingRowId?: FlowFavoriteFlowIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerFlowIdFkeyInverseInput | null;
  flowType?: FlowType | null;
  gwtUserToAssignedById?: FlowAssignedByIdFkeyInput | null;
  gwtUserToAssignedToId?: FlowAssignedToIdFkeyInput | null;
  gwtUserToLastModifiedById?: FlowLastModifiedByIdFkeyInput | null;
  gwtUserToOwnerId?: FlowOwnerIdFkeyInput | null;
  lastModifiedById?: UUID | null;
  moduleGroupUsingRowId?: ModuleGroupFlowIdFkeyInverseInput | null;
  moduleUsingRowId?: ModuleFlowIdFkeyInverseInput | null;
  name?: string | null;
  ownerId?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentFlowIdFkeyInverseInput | null;
  rowId?: UUID | null;
  startedAt?: Date | null;
  statusChangedAt?: Date | null;
  taskUsingRowId?: TaskFlowIdFkeyInverseInput | null;
  templateUsingRowId?: TemplateFlowIdFkeyInverseInput | null;
  updatedAt?: Date | null;
  workflowType?: WorkflowType | null;
};
export type FlowOnFlowFavoriteForFlowFavoriteFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: FlowFavoritePatch;
};
export type FlowFavoriteFlowIdFkeyFlowFavoriteCreateInput = {
  createdAt?: Date | null;
  flowToFlowId?: FlowFavoriteFlowIdFkeyInput | null;
  gwtUserId?: UUID | null;
  gwtUserToGwtUserId?: FlowFavoriteGwtUserIdFkeyInput | null;
};
export type ReviewerDashboardFlowCommentOnReviewerDashboardFlowCommentForReviewerDashboardFlowCommentFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: FlowPatch;
};
export type ReviewerDashboardFlowCommentFlowIdFkeyFlowCreateInput = {
  activitiesUsingRowId?: ActivityFlowIdFkInverseInput | null;
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentFlowIdFkeyInverseInput | null;
  assignedAt?: Date | null;
  assignedById?: UUID | null;
  assignedToId?: UUID | null;
  assignmentDueAtStatus?: AssignmentDueAtStatus | null;
  assignmentStatus?: AssignmentStatus | null;
  completedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  dueAt?: Date | null;
  durationSeconds?: number | null;
  flowCommentsUsingRowId?: FlowCommentFlowIdFkeyInverseInput | null;
  flowFavoritesUsingRowId?: FlowFavoriteFlowIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerFlowIdFkeyInverseInput | null;
  flowType?: FlowType | null;
  gwtUserToAssignedById?: FlowAssignedByIdFkeyInput | null;
  gwtUserToAssignedToId?: FlowAssignedToIdFkeyInput | null;
  gwtUserToLastModifiedById?: FlowLastModifiedByIdFkeyInput | null;
  gwtUserToOwnerId?: FlowOwnerIdFkeyInput | null;
  lastModifiedById?: UUID | null;
  moduleGroupUsingRowId?: ModuleGroupFlowIdFkeyInverseInput | null;
  moduleUsingRowId?: ModuleFlowIdFkeyInverseInput | null;
  name?: string | null;
  ownerId?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentFlowIdFkeyInverseInput | null;
  rowId?: UUID | null;
  startedAt?: Date | null;
  statusChangedAt?: Date | null;
  taskUsingRowId?: TaskFlowIdFkeyInverseInput | null;
  templateUsingRowId?: TemplateFlowIdFkeyInverseInput | null;
  updatedAt?: Date | null;
  workflowType?: WorkflowType | null;
};
export type FlowOnReviewerDashboardFlowCommentForReviewerDashboardFlowCommentFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: ReviewerDashboardFlowCommentPatch;
};
export type ReviewerDashboardFlowCommentFlowIdFkeyReviewerDashboardFlowCommentCreateInput = {
  commentId?: UUID | null;
  commentToCommentId?: ReviewerDashboardFlowCommentCommentIdFkeyInput | null;
  flowToFlowId?: ReviewerDashboardFlowCommentFlowIdFkeyInput | null;
};
export type AdminDashboardFlowCommentOnAdminDashboardFlowCommentForAdminDashboardFlowCommentFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: FlowPatch;
};
export type AdminDashboardFlowCommentFlowIdFkeyFlowCreateInput = {
  activitiesUsingRowId?: ActivityFlowIdFkInverseInput | null;
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentFlowIdFkeyInverseInput | null;
  assignedAt?: Date | null;
  assignedById?: UUID | null;
  assignedToId?: UUID | null;
  assignmentDueAtStatus?: AssignmentDueAtStatus | null;
  assignmentStatus?: AssignmentStatus | null;
  completedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  dueAt?: Date | null;
  durationSeconds?: number | null;
  flowCommentsUsingRowId?: FlowCommentFlowIdFkeyInverseInput | null;
  flowFavoritesUsingRowId?: FlowFavoriteFlowIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerFlowIdFkeyInverseInput | null;
  flowType?: FlowType | null;
  gwtUserToAssignedById?: FlowAssignedByIdFkeyInput | null;
  gwtUserToAssignedToId?: FlowAssignedToIdFkeyInput | null;
  gwtUserToLastModifiedById?: FlowLastModifiedByIdFkeyInput | null;
  gwtUserToOwnerId?: FlowOwnerIdFkeyInput | null;
  lastModifiedById?: UUID | null;
  moduleGroupUsingRowId?: ModuleGroupFlowIdFkeyInverseInput | null;
  moduleUsingRowId?: ModuleFlowIdFkeyInverseInput | null;
  name?: string | null;
  ownerId?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentFlowIdFkeyInverseInput | null;
  rowId?: UUID | null;
  startedAt?: Date | null;
  statusChangedAt?: Date | null;
  taskUsingRowId?: TaskFlowIdFkeyInverseInput | null;
  templateUsingRowId?: TemplateFlowIdFkeyInverseInput | null;
  updatedAt?: Date | null;
  workflowType?: WorkflowType | null;
};
export type FlowOnAdminDashboardFlowCommentForAdminDashboardFlowCommentFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: AdminDashboardFlowCommentPatch;
};
export type AdminDashboardFlowCommentFlowIdFkeyAdminDashboardFlowCommentCreateInput = {
  commentId?: UUID | null;
  commentToCommentId?: AdminDashboardFlowCommentCommentIdFkeyInput | null;
  flowToFlowId?: AdminDashboardFlowCommentFlowIdFkeyInput | null;
};
export type FlowCommentOnFlowCommentForFlowCommentFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: FlowPatch;
};
export type FlowCommentFlowIdFkeyFlowCreateInput = {
  activitiesUsingRowId?: ActivityFlowIdFkInverseInput | null;
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentFlowIdFkeyInverseInput | null;
  assignedAt?: Date | null;
  assignedById?: UUID | null;
  assignedToId?: UUID | null;
  assignmentDueAtStatus?: AssignmentDueAtStatus | null;
  assignmentStatus?: AssignmentStatus | null;
  completedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  dueAt?: Date | null;
  durationSeconds?: number | null;
  flowCommentsUsingRowId?: FlowCommentFlowIdFkeyInverseInput | null;
  flowFavoritesUsingRowId?: FlowFavoriteFlowIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerFlowIdFkeyInverseInput | null;
  flowType?: FlowType | null;
  gwtUserToAssignedById?: FlowAssignedByIdFkeyInput | null;
  gwtUserToAssignedToId?: FlowAssignedToIdFkeyInput | null;
  gwtUserToLastModifiedById?: FlowLastModifiedByIdFkeyInput | null;
  gwtUserToOwnerId?: FlowOwnerIdFkeyInput | null;
  lastModifiedById?: UUID | null;
  moduleGroupUsingRowId?: ModuleGroupFlowIdFkeyInverseInput | null;
  moduleUsingRowId?: ModuleFlowIdFkeyInverseInput | null;
  name?: string | null;
  ownerId?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentFlowIdFkeyInverseInput | null;
  rowId?: UUID | null;
  startedAt?: Date | null;
  statusChangedAt?: Date | null;
  taskUsingRowId?: TaskFlowIdFkeyInverseInput | null;
  templateUsingRowId?: TemplateFlowIdFkeyInverseInput | null;
  updatedAt?: Date | null;
  workflowType?: WorkflowType | null;
};
export type FlowOnFlowCommentForFlowCommentFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: FlowCommentPatch;
};
export type FlowCommentFlowIdFkeyFlowCommentCreateInput = {
  commentId?: UUID | null;
  commentToCommentId?: FlowCommentCommentIdFkeyInput | null;
  flowToFlowId?: FlowCommentFlowIdFkeyInput | null;
};
export type FlowReviewerOnFlowReviewerForFlowReviewerFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: FlowPatch;
};
export type FlowReviewerFlowIdFkeyFlowCreateInput = {
  activitiesUsingRowId?: ActivityFlowIdFkInverseInput | null;
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentFlowIdFkeyInverseInput | null;
  assignedAt?: Date | null;
  assignedById?: UUID | null;
  assignedToId?: UUID | null;
  assignmentDueAtStatus?: AssignmentDueAtStatus | null;
  assignmentStatus?: AssignmentStatus | null;
  completedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  dueAt?: Date | null;
  durationSeconds?: number | null;
  flowCommentsUsingRowId?: FlowCommentFlowIdFkeyInverseInput | null;
  flowFavoritesUsingRowId?: FlowFavoriteFlowIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerFlowIdFkeyInverseInput | null;
  flowType?: FlowType | null;
  gwtUserToAssignedById?: FlowAssignedByIdFkeyInput | null;
  gwtUserToAssignedToId?: FlowAssignedToIdFkeyInput | null;
  gwtUserToLastModifiedById?: FlowLastModifiedByIdFkeyInput | null;
  gwtUserToOwnerId?: FlowOwnerIdFkeyInput | null;
  lastModifiedById?: UUID | null;
  moduleGroupUsingRowId?: ModuleGroupFlowIdFkeyInverseInput | null;
  moduleUsingRowId?: ModuleFlowIdFkeyInverseInput | null;
  name?: string | null;
  ownerId?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentFlowIdFkeyInverseInput | null;
  rowId?: UUID | null;
  startedAt?: Date | null;
  statusChangedAt?: Date | null;
  taskUsingRowId?: TaskFlowIdFkeyInverseInput | null;
  templateUsingRowId?: TemplateFlowIdFkeyInverseInput | null;
  updatedAt?: Date | null;
  workflowType?: WorkflowType | null;
};
export type FlowOnFlowReviewerForFlowReviewerFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: FlowReviewerPatch;
};
export type FlowReviewerFlowIdFkeyFlowReviewerCreateInput = {
  flowToFlowId?: FlowReviewerFlowIdFkeyInput | null;
  gwtUserToReviewerId?: FlowReviewerReviewerIdFkeyInput | null;
  reviewerId?: UUID | null;
};
export type TaskOnTaskForTaskFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: FlowPatch;
};
export type TaskFlowIdFkeyFlowCreateInput = {
  activitiesUsingRowId?: ActivityFlowIdFkInverseInput | null;
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentFlowIdFkeyInverseInput | null;
  assignedAt?: Date | null;
  assignedById?: UUID | null;
  assignedToId?: UUID | null;
  assignmentDueAtStatus?: AssignmentDueAtStatus | null;
  assignmentStatus?: AssignmentStatus | null;
  completedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  dueAt?: Date | null;
  durationSeconds?: number | null;
  flowCommentsUsingRowId?: FlowCommentFlowIdFkeyInverseInput | null;
  flowFavoritesUsingRowId?: FlowFavoriteFlowIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerFlowIdFkeyInverseInput | null;
  flowType?: FlowType | null;
  gwtUserToAssignedById?: FlowAssignedByIdFkeyInput | null;
  gwtUserToAssignedToId?: FlowAssignedToIdFkeyInput | null;
  gwtUserToLastModifiedById?: FlowLastModifiedByIdFkeyInput | null;
  gwtUserToOwnerId?: FlowOwnerIdFkeyInput | null;
  lastModifiedById?: UUID | null;
  moduleGroupUsingRowId?: ModuleGroupFlowIdFkeyInverseInput | null;
  moduleUsingRowId?: ModuleFlowIdFkeyInverseInput | null;
  name?: string | null;
  ownerId?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentFlowIdFkeyInverseInput | null;
  rowId?: UUID | null;
  startedAt?: Date | null;
  statusChangedAt?: Date | null;
  taskUsingRowId?: TaskFlowIdFkeyInverseInput | null;
  templateUsingRowId?: TemplateFlowIdFkeyInverseInput | null;
  updatedAt?: Date | null;
  workflowType?: WorkflowType | null;
};
export type FlowOnTaskForTaskFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: TaskPatch;
};
export type TaskFlowIdFkeyTaskCreateInput = {
  complete?: boolean | null;
  flowToFlowId?: TaskFlowIdFkeyInput | null;
  moduleFlowId?: UUID | null;
  moduleToModuleFlowId?: TaskModuleFlowIdFkeyInput | null;
  priority?: number | null;
  templateFlowId?: UUID | null;
  templateToTemplateFlowId?: TaskTemplateFlowIdFkeyInput | null;
};
export type ModuleGroupOnModuleGroupForModuleGroupFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: FlowPatch;
};
export type ModuleGroupFlowIdFkeyFlowCreateInput = {
  activitiesUsingRowId?: ActivityFlowIdFkInverseInput | null;
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentFlowIdFkeyInverseInput | null;
  assignedAt?: Date | null;
  assignedById?: UUID | null;
  assignedToId?: UUID | null;
  assignmentDueAtStatus?: AssignmentDueAtStatus | null;
  assignmentStatus?: AssignmentStatus | null;
  completedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  dueAt?: Date | null;
  durationSeconds?: number | null;
  flowCommentsUsingRowId?: FlowCommentFlowIdFkeyInverseInput | null;
  flowFavoritesUsingRowId?: FlowFavoriteFlowIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerFlowIdFkeyInverseInput | null;
  flowType?: FlowType | null;
  gwtUserToAssignedById?: FlowAssignedByIdFkeyInput | null;
  gwtUserToAssignedToId?: FlowAssignedToIdFkeyInput | null;
  gwtUserToLastModifiedById?: FlowLastModifiedByIdFkeyInput | null;
  gwtUserToOwnerId?: FlowOwnerIdFkeyInput | null;
  lastModifiedById?: UUID | null;
  moduleGroupUsingRowId?: ModuleGroupFlowIdFkeyInverseInput | null;
  moduleUsingRowId?: ModuleFlowIdFkeyInverseInput | null;
  name?: string | null;
  ownerId?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentFlowIdFkeyInverseInput | null;
  rowId?: UUID | null;
  startedAt?: Date | null;
  statusChangedAt?: Date | null;
  taskUsingRowId?: TaskFlowIdFkeyInverseInput | null;
  templateUsingRowId?: TemplateFlowIdFkeyInverseInput | null;
  updatedAt?: Date | null;
  workflowType?: WorkflowType | null;
};
export type ModuleOnModuleForModuleModuleGroupIdFkeyNodeIdUpdate = {
  id: string;
  patch: ModuleGroupPatch;
};
export type ModuleModuleGroupIdFkeyModuleGroupCreateInput = {
  archivedAt?: Date | null;
  flowId?: UUID | null;
  flowToFlowId?: ModuleGroupFlowIdFkeyInput | null;
  moduleGroupLinksUsingFlowId?: ModuleGroupLinksModuleGroupFlowIdFkeyInverseInput | null;
  modulesUsingFlowId?: ModuleModuleGroupIdFkeyInverseInput | null;
  shuffle?: boolean | null;
  versionNumber?: BigFloat | null;
};
export type ModuleOnModuleForModuleGroupModulePreviousModuleFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: ModulePatch;
};
export type ModuleGroupModulePreviousModuleFlowIdFkeyModuleCreateInput = {
  attempts?: number | null;
  autoStartNextModuleFlowId?: UUID | null;
  currentAttempt?: number | null;
  flowId?: UUID | null;
  flowToFlowId?: ModuleFlowIdFkeyInput | null;
  galleryAssetComponentsId?: UUID | null;
  galleryAssetDeliverableId?: UUID | null;
  galleryCollectionSubmissionsId?: UUID | null;
  moduleGroupId?: UUID | null;
  moduleGroupLinksUsingFlowId?: ModuleGroupLinksModuleFlowIdFkeyInverseInput | null;
  moduleGroupToModuleGroupId?: ModuleModuleGroupIdFkeyInput | null;
  moduleSubmissionsUsingFlowId?: ModuleSubmissionModuleFlowIdFkeyInverseInput | null;
  moduleToAutoStartNextModuleFlowId?: ModuleAutoStartNextModuleFlowIdFkeyInput | null;
  moduleToGroupModulePreviousModuleFlowId?: ModuleGroupModulePreviousModuleFlowIdFkeyInput | null;
  programType?: ProgramType | null;
  softwareType?: SoftwareType | null;
  submissionLock?: boolean | null;
  tasksUsingFlowId?: TaskModuleFlowIdFkeyInverseInput | null;
  templateFlowId?: UUID | null;
  templateToTemplateFlowId?: ModuleTemplateFlowIdFkeyInput | null;
  versionNumber?: BigFloat | null;
};
export type ModuleOnModuleForModuleAutoStartNextModuleFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: ModulePatch;
};
export type ModuleAutoStartNextModuleFlowIdFkeyModuleCreateInput = {
  attempts?: number | null;
  currentAttempt?: number | null;
  flowId?: UUID | null;
  flowToFlowId?: ModuleFlowIdFkeyInput | null;
  galleryAssetComponentsId?: UUID | null;
  galleryAssetDeliverableId?: UUID | null;
  galleryCollectionSubmissionsId?: UUID | null;
  groupModulePreviousModuleFlowId?: UUID | null;
  moduleGroupId?: UUID | null;
  moduleGroupLinksUsingFlowId?: ModuleGroupLinksModuleFlowIdFkeyInverseInput | null;
  moduleGroupToModuleGroupId?: ModuleModuleGroupIdFkeyInput | null;
  moduleSubmissionsUsingFlowId?: ModuleSubmissionModuleFlowIdFkeyInverseInput | null;
  moduleToAutoStartNextModuleFlowId?: ModuleAutoStartNextModuleFlowIdFkeyInput | null;
  moduleToGroupModulePreviousModuleFlowId?: ModuleGroupModulePreviousModuleFlowIdFkeyInput | null;
  programType?: ProgramType | null;
  softwareType?: SoftwareType | null;
  submissionLock?: boolean | null;
  tasksUsingFlowId?: TaskModuleFlowIdFkeyInverseInput | null;
  templateFlowId?: UUID | null;
  templateToTemplateFlowId?: ModuleTemplateFlowIdFkeyInput | null;
  versionNumber?: BigFloat | null;
};
export type TemplateOnModuleForModuleTemplateFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: ModulePatch;
};
export type ModuleTemplateFlowIdFkeyModuleCreateInput = {
  attempts?: number | null;
  autoStartNextModuleFlowId?: UUID | null;
  currentAttempt?: number | null;
  flowId?: UUID | null;
  flowToFlowId?: ModuleFlowIdFkeyInput | null;
  galleryAssetComponentsId?: UUID | null;
  galleryAssetDeliverableId?: UUID | null;
  galleryCollectionSubmissionsId?: UUID | null;
  groupModulePreviousModuleFlowId?: UUID | null;
  moduleGroupId?: UUID | null;
  moduleGroupLinksUsingFlowId?: ModuleGroupLinksModuleFlowIdFkeyInverseInput | null;
  moduleGroupToModuleGroupId?: ModuleModuleGroupIdFkeyInput | null;
  moduleSubmissionsUsingFlowId?: ModuleSubmissionModuleFlowIdFkeyInverseInput | null;
  moduleToAutoStartNextModuleFlowId?: ModuleAutoStartNextModuleFlowIdFkeyInput | null;
  moduleToGroupModulePreviousModuleFlowId?: ModuleGroupModulePreviousModuleFlowIdFkeyInput | null;
  programType?: ProgramType | null;
  softwareType?: SoftwareType | null;
  submissionLock?: boolean | null;
  tasksUsingFlowId?: TaskModuleFlowIdFkeyInverseInput | null;
  templateToTemplateFlowId?: ModuleTemplateFlowIdFkeyInput | null;
  versionNumber?: BigFloat | null;
};
export type ModuleOnModuleForModuleTemplateFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: TemplatePatch;
};
export type ModuleTemplateFlowIdFkeyTemplateCreateInput = {
  flowId?: UUID | null;
  flowToFlowId?: TemplateFlowIdFkeyInput | null;
  modulesUsingFlowId?: ModuleTemplateFlowIdFkeyInverseInput | null;
  tasksUsingFlowId?: TaskTemplateFlowIdFkeyInverseInput | null;
};
export type FlowOnModuleForModuleFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: ModulePatch;
};
export type ModuleFlowIdFkeyModuleCreateInput = {
  attempts?: number | null;
  autoStartNextModuleFlowId?: UUID | null;
  currentAttempt?: number | null;
  flowToFlowId?: ModuleFlowIdFkeyInput | null;
  galleryAssetComponentsId?: UUID | null;
  galleryAssetDeliverableId?: UUID | null;
  galleryCollectionSubmissionsId?: UUID | null;
  groupModulePreviousModuleFlowId?: UUID | null;
  moduleGroupId?: UUID | null;
  moduleGroupLinksUsingFlowId?: ModuleGroupLinksModuleFlowIdFkeyInverseInput | null;
  moduleGroupToModuleGroupId?: ModuleModuleGroupIdFkeyInput | null;
  moduleSubmissionsUsingFlowId?: ModuleSubmissionModuleFlowIdFkeyInverseInput | null;
  moduleToAutoStartNextModuleFlowId?: ModuleAutoStartNextModuleFlowIdFkeyInput | null;
  moduleToGroupModulePreviousModuleFlowId?: ModuleGroupModulePreviousModuleFlowIdFkeyInput | null;
  programType?: ProgramType | null;
  softwareType?: SoftwareType | null;
  submissionLock?: boolean | null;
  tasksUsingFlowId?: TaskModuleFlowIdFkeyInverseInput | null;
  templateFlowId?: UUID | null;
  templateToTemplateFlowId?: ModuleTemplateFlowIdFkeyInput | null;
  versionNumber?: BigFloat | null;
};
export type TemplateOnTemplateForTemplateFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: FlowPatch;
};
export type TemplateFlowIdFkeyFlowCreateInput = {
  activitiesUsingRowId?: ActivityFlowIdFkInverseInput | null;
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentFlowIdFkeyInverseInput | null;
  assignedAt?: Date | null;
  assignedById?: UUID | null;
  assignedToId?: UUID | null;
  assignmentDueAtStatus?: AssignmentDueAtStatus | null;
  assignmentStatus?: AssignmentStatus | null;
  completedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  dueAt?: Date | null;
  durationSeconds?: number | null;
  flowCommentsUsingRowId?: FlowCommentFlowIdFkeyInverseInput | null;
  flowFavoritesUsingRowId?: FlowFavoriteFlowIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerFlowIdFkeyInverseInput | null;
  flowType?: FlowType | null;
  gwtUserToAssignedById?: FlowAssignedByIdFkeyInput | null;
  gwtUserToAssignedToId?: FlowAssignedToIdFkeyInput | null;
  gwtUserToLastModifiedById?: FlowLastModifiedByIdFkeyInput | null;
  gwtUserToOwnerId?: FlowOwnerIdFkeyInput | null;
  lastModifiedById?: UUID | null;
  moduleGroupUsingRowId?: ModuleGroupFlowIdFkeyInverseInput | null;
  moduleUsingRowId?: ModuleFlowIdFkeyInverseInput | null;
  name?: string | null;
  ownerId?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentFlowIdFkeyInverseInput | null;
  rowId?: UUID | null;
  startedAt?: Date | null;
  statusChangedAt?: Date | null;
  taskUsingRowId?: TaskFlowIdFkeyInverseInput | null;
  templateUsingRowId?: TemplateFlowIdFkeyInverseInput | null;
  updatedAt?: Date | null;
  workflowType?: WorkflowType | null;
};
export type FlowOnTemplateForTemplateFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: TemplatePatch;
};
export type TemplateFlowIdFkeyTemplateCreateInput = {
  flowToFlowId?: TemplateFlowIdFkeyInput | null;
  modulesUsingFlowId?: ModuleTemplateFlowIdFkeyInverseInput | null;
  tasksUsingFlowId?: TaskTemplateFlowIdFkeyInverseInput | null;
};
export type ActivityOnActivityForActivityFlowIdFkNodeIdUpdate = {
  id: string;
  patch: FlowPatch;
};
export type ActivityFlowIdFkFlowCreateInput = {
  activitiesUsingRowId?: ActivityFlowIdFkInverseInput | null;
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentFlowIdFkeyInverseInput | null;
  assignedAt?: Date | null;
  assignedById?: UUID | null;
  assignedToId?: UUID | null;
  assignmentDueAtStatus?: AssignmentDueAtStatus | null;
  assignmentStatus?: AssignmentStatus | null;
  completedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  dueAt?: Date | null;
  durationSeconds?: number | null;
  flowCommentsUsingRowId?: FlowCommentFlowIdFkeyInverseInput | null;
  flowFavoritesUsingRowId?: FlowFavoriteFlowIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerFlowIdFkeyInverseInput | null;
  flowType?: FlowType | null;
  gwtUserToAssignedById?: FlowAssignedByIdFkeyInput | null;
  gwtUserToAssignedToId?: FlowAssignedToIdFkeyInput | null;
  gwtUserToLastModifiedById?: FlowLastModifiedByIdFkeyInput | null;
  gwtUserToOwnerId?: FlowOwnerIdFkeyInput | null;
  lastModifiedById?: UUID | null;
  moduleGroupUsingRowId?: ModuleGroupFlowIdFkeyInverseInput | null;
  moduleUsingRowId?: ModuleFlowIdFkeyInverseInput | null;
  name?: string | null;
  ownerId?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentFlowIdFkeyInverseInput | null;
  rowId?: UUID | null;
  startedAt?: Date | null;
  statusChangedAt?: Date | null;
  taskUsingRowId?: TaskFlowIdFkeyInverseInput | null;
  templateUsingRowId?: TemplateFlowIdFkeyInverseInput | null;
  updatedAt?: Date | null;
  workflowType?: WorkflowType | null;
};
export type FlowOnActivityForActivityFlowIdFkNodeIdUpdate = {
  id: string;
  patch: ActivityPatch;
};
export type ActivityPatch = {
  createdAt?: Date | null;
  flowId?: UUID | null;
  flowToFlowId?: ActivityFlowIdFkInput | null;
  gwtUserId?: UUID | null;
  gwtUserToGwtUserId?: ActivityGwtUserIdFkeyInput | null;
  linkLocation?: string | null;
  object?: string | null;
  rowId?: UUID | null;
  verb?: string | null;
  workflowType?: WorkflowType | null;
};
export type ActivityFlowIdFkActivityCreateInput = {
  createdAt?: Date | null;
  flowToFlowId?: ActivityFlowIdFkInput | null;
  gwtUserId?: UUID | null;
  gwtUserToGwtUserId?: ActivityGwtUserIdFkeyInput | null;
  linkLocation?: string | null;
  object: string;
  rowId?: UUID | null;
  verb: string;
  workflowType?: WorkflowType | null;
};
export type ModuleOnModuleForModuleFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: FlowPatch;
};
export type ModuleFlowIdFkeyFlowCreateInput = {
  activitiesUsingRowId?: ActivityFlowIdFkInverseInput | null;
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentFlowIdFkeyInverseInput | null;
  assignedAt?: Date | null;
  assignedById?: UUID | null;
  assignedToId?: UUID | null;
  assignmentDueAtStatus?: AssignmentDueAtStatus | null;
  assignmentStatus?: AssignmentStatus | null;
  completedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  dueAt?: Date | null;
  durationSeconds?: number | null;
  flowCommentsUsingRowId?: FlowCommentFlowIdFkeyInverseInput | null;
  flowFavoritesUsingRowId?: FlowFavoriteFlowIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerFlowIdFkeyInverseInput | null;
  flowType?: FlowType | null;
  gwtUserToAssignedById?: FlowAssignedByIdFkeyInput | null;
  gwtUserToAssignedToId?: FlowAssignedToIdFkeyInput | null;
  gwtUserToLastModifiedById?: FlowLastModifiedByIdFkeyInput | null;
  gwtUserToOwnerId?: FlowOwnerIdFkeyInput | null;
  lastModifiedById?: UUID | null;
  moduleGroupUsingRowId?: ModuleGroupFlowIdFkeyInverseInput | null;
  moduleUsingRowId?: ModuleFlowIdFkeyInverseInput | null;
  name?: string | null;
  ownerId?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentFlowIdFkeyInverseInput | null;
  rowId?: UUID | null;
  startedAt?: Date | null;
  statusChangedAt?: Date | null;
  taskUsingRowId?: TaskFlowIdFkeyInverseInput | null;
  templateUsingRowId?: TemplateFlowIdFkeyInverseInput | null;
  updatedAt?: Date | null;
  workflowType?: WorkflowType | null;
};
export type ModuleSubmissionOnModuleSubmissionForModuleSubmissionModuleFlowIdFkeyNodeIdUpdate = {
  id: string;
  patch: ModulePatch;
};
export type ModuleSubmissionModuleFlowIdFkeyModuleCreateInput = {
  attempts?: number | null;
  autoStartNextModuleFlowId?: UUID | null;
  currentAttempt?: number | null;
  flowId?: UUID | null;
  flowToFlowId?: ModuleFlowIdFkeyInput | null;
  galleryAssetComponentsId?: UUID | null;
  galleryAssetDeliverableId?: UUID | null;
  galleryCollectionSubmissionsId?: UUID | null;
  groupModulePreviousModuleFlowId?: UUID | null;
  moduleGroupId?: UUID | null;
  moduleGroupLinksUsingFlowId?: ModuleGroupLinksModuleFlowIdFkeyInverseInput | null;
  moduleGroupToModuleGroupId?: ModuleModuleGroupIdFkeyInput | null;
  moduleSubmissionsUsingFlowId?: ModuleSubmissionModuleFlowIdFkeyInverseInput | null;
  moduleToAutoStartNextModuleFlowId?: ModuleAutoStartNextModuleFlowIdFkeyInput | null;
  moduleToGroupModulePreviousModuleFlowId?: ModuleGroupModulePreviousModuleFlowIdFkeyInput | null;
  programType?: ProgramType | null;
  softwareType?: SoftwareType | null;
  submissionLock?: boolean | null;
  tasksUsingFlowId?: TaskModuleFlowIdFkeyInverseInput | null;
  templateFlowId?: UUID | null;
  templateToTemplateFlowId?: ModuleTemplateFlowIdFkeyInput | null;
  versionNumber?: BigFloat | null;
};
export type GwtUserOnModuleSubmissionForModuleSubmissionAcceptedByIdFkeyNodeIdUpdate = {
  id: string;
  patch: ModuleSubmissionPatch;
};
export type ModuleSubmissionAcceptedByIdFkeyModuleSubmissionCreateInput = {
  attempt: number;
  createdAt?: Date | null;
  galleryAssetSubmissionId: UUID;
  gwtUserToAcceptedById?: ModuleSubmissionAcceptedByIdFkeyInput | null;
  moduleFlowId?: UUID | null;
  moduleToModuleFlowId?: ModuleSubmissionModuleFlowIdFkeyInput | null;
  submissionStatus?: SubmissionStatus | null;
};
export type FlowOnFlowForFlowLastModifiedByIdFkeyNodeIdUpdate = {
  id: string;
  patch: GwtUserPatch;
};
export type FlowLastModifiedByIdFkeyGwtUserCreateInput = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email: string;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName: string;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName: string;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId: string;
  lastName: string;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId: UUID;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type GwtUserOnFlowForFlowLastModifiedByIdFkeyNodeIdUpdate = {
  id: string;
  patch: FlowPatch;
};
export type FlowLastModifiedByIdFkeyFlowCreateInput = {
  activitiesUsingRowId?: ActivityFlowIdFkInverseInput | null;
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentFlowIdFkeyInverseInput | null;
  assignedAt?: Date | null;
  assignedById?: UUID | null;
  assignedToId?: UUID | null;
  assignmentDueAtStatus?: AssignmentDueAtStatus | null;
  assignmentStatus?: AssignmentStatus | null;
  completedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  dueAt?: Date | null;
  durationSeconds?: number | null;
  flowCommentsUsingRowId?: FlowCommentFlowIdFkeyInverseInput | null;
  flowFavoritesUsingRowId?: FlowFavoriteFlowIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerFlowIdFkeyInverseInput | null;
  flowType?: FlowType | null;
  gwtUserToAssignedById?: FlowAssignedByIdFkeyInput | null;
  gwtUserToAssignedToId?: FlowAssignedToIdFkeyInput | null;
  gwtUserToLastModifiedById?: FlowLastModifiedByIdFkeyInput | null;
  gwtUserToOwnerId?: FlowOwnerIdFkeyInput | null;
  moduleGroupUsingRowId?: ModuleGroupFlowIdFkeyInverseInput | null;
  moduleUsingRowId?: ModuleFlowIdFkeyInverseInput | null;
  name?: string | null;
  ownerId?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentFlowIdFkeyInverseInput | null;
  rowId?: UUID | null;
  startedAt?: Date | null;
  statusChangedAt?: Date | null;
  taskUsingRowId?: TaskFlowIdFkeyInverseInput | null;
  templateUsingRowId?: TemplateFlowIdFkeyInverseInput | null;
  updatedAt?: Date | null;
  workflowType?: WorkflowType | null;
};
export type FlowOnFlowForFlowOwnerIdFkeyNodeIdUpdate = {
  id: string;
  patch: GwtUserPatch;
};
export type FlowOwnerIdFkeyGwtUserCreateInput = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email: string;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName: string;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName: string;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId: string;
  lastName: string;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId: UUID;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type GwtUserOnFlowForFlowOwnerIdFkeyNodeIdUpdate = {
  id: string;
  patch: FlowPatch;
};
export type FlowOwnerIdFkeyFlowCreateInput = {
  activitiesUsingRowId?: ActivityFlowIdFkInverseInput | null;
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentFlowIdFkeyInverseInput | null;
  assignedAt?: Date | null;
  assignedById?: UUID | null;
  assignedToId?: UUID | null;
  assignmentDueAtStatus?: AssignmentDueAtStatus | null;
  assignmentStatus?: AssignmentStatus | null;
  completedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  dueAt?: Date | null;
  durationSeconds?: number | null;
  flowCommentsUsingRowId?: FlowCommentFlowIdFkeyInverseInput | null;
  flowFavoritesUsingRowId?: FlowFavoriteFlowIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerFlowIdFkeyInverseInput | null;
  flowType?: FlowType | null;
  gwtUserToAssignedById?: FlowAssignedByIdFkeyInput | null;
  gwtUserToAssignedToId?: FlowAssignedToIdFkeyInput | null;
  gwtUserToLastModifiedById?: FlowLastModifiedByIdFkeyInput | null;
  gwtUserToOwnerId?: FlowOwnerIdFkeyInput | null;
  lastModifiedById?: UUID | null;
  moduleGroupUsingRowId?: ModuleGroupFlowIdFkeyInverseInput | null;
  moduleUsingRowId?: ModuleFlowIdFkeyInverseInput | null;
  name?: string | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentFlowIdFkeyInverseInput | null;
  rowId?: UUID | null;
  startedAt?: Date | null;
  statusChangedAt?: Date | null;
  taskUsingRowId?: TaskFlowIdFkeyInverseInput | null;
  templateUsingRowId?: TemplateFlowIdFkeyInverseInput | null;
  updatedAt?: Date | null;
  workflowType?: WorkflowType | null;
};
export type FlowOnFlowForFlowAssignedByIdFkeyNodeIdUpdate = {
  id: string;
  patch: GwtUserPatch;
};
export type FlowAssignedByIdFkeyGwtUserCreateInput = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email: string;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName: string;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName: string;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId: string;
  lastName: string;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId: UUID;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type GwtUserOnFlowForFlowAssignedByIdFkeyNodeIdUpdate = {
  id: string;
  patch: FlowPatch;
};
export type FlowAssignedByIdFkeyFlowCreateInput = {
  activitiesUsingRowId?: ActivityFlowIdFkInverseInput | null;
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentFlowIdFkeyInverseInput | null;
  assignedAt?: Date | null;
  assignedToId?: UUID | null;
  assignmentDueAtStatus?: AssignmentDueAtStatus | null;
  assignmentStatus?: AssignmentStatus | null;
  completedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  dueAt?: Date | null;
  durationSeconds?: number | null;
  flowCommentsUsingRowId?: FlowCommentFlowIdFkeyInverseInput | null;
  flowFavoritesUsingRowId?: FlowFavoriteFlowIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerFlowIdFkeyInverseInput | null;
  flowType?: FlowType | null;
  gwtUserToAssignedById?: FlowAssignedByIdFkeyInput | null;
  gwtUserToAssignedToId?: FlowAssignedToIdFkeyInput | null;
  gwtUserToLastModifiedById?: FlowLastModifiedByIdFkeyInput | null;
  gwtUserToOwnerId?: FlowOwnerIdFkeyInput | null;
  lastModifiedById?: UUID | null;
  moduleGroupUsingRowId?: ModuleGroupFlowIdFkeyInverseInput | null;
  moduleUsingRowId?: ModuleFlowIdFkeyInverseInput | null;
  name?: string | null;
  ownerId?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentFlowIdFkeyInverseInput | null;
  rowId?: UUID | null;
  startedAt?: Date | null;
  statusChangedAt?: Date | null;
  taskUsingRowId?: TaskFlowIdFkeyInverseInput | null;
  templateUsingRowId?: TemplateFlowIdFkeyInverseInput | null;
  updatedAt?: Date | null;
  workflowType?: WorkflowType | null;
};
export type FlowOnFlowForFlowAssignedToIdFkeyNodeIdUpdate = {
  id: string;
  patch: GwtUserPatch;
};
export type FlowAssignedToIdFkeyGwtUserCreateInput = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email: string;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName: string;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName: string;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId: string;
  lastName: string;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId: UUID;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type GwtUserOnFlowForFlowAssignedToIdFkeyNodeIdUpdate = {
  id: string;
  patch: FlowPatch;
};
export type FlowAssignedToIdFkeyFlowCreateInput = {
  activitiesUsingRowId?: ActivityFlowIdFkInverseInput | null;
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentFlowIdFkeyInverseInput | null;
  assignedAt?: Date | null;
  assignedById?: UUID | null;
  assignmentDueAtStatus?: AssignmentDueAtStatus | null;
  assignmentStatus?: AssignmentStatus | null;
  completedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  dueAt?: Date | null;
  durationSeconds?: number | null;
  flowCommentsUsingRowId?: FlowCommentFlowIdFkeyInverseInput | null;
  flowFavoritesUsingRowId?: FlowFavoriteFlowIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerFlowIdFkeyInverseInput | null;
  flowType?: FlowType | null;
  gwtUserToAssignedById?: FlowAssignedByIdFkeyInput | null;
  gwtUserToAssignedToId?: FlowAssignedToIdFkeyInput | null;
  gwtUserToLastModifiedById?: FlowLastModifiedByIdFkeyInput | null;
  gwtUserToOwnerId?: FlowOwnerIdFkeyInput | null;
  lastModifiedById?: UUID | null;
  moduleGroupUsingRowId?: ModuleGroupFlowIdFkeyInverseInput | null;
  moduleUsingRowId?: ModuleFlowIdFkeyInverseInput | null;
  name?: string | null;
  ownerId?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentFlowIdFkeyInverseInput | null;
  rowId?: UUID | null;
  startedAt?: Date | null;
  statusChangedAt?: Date | null;
  taskUsingRowId?: TaskFlowIdFkeyInverseInput | null;
  templateUsingRowId?: TemplateFlowIdFkeyInverseInput | null;
  updatedAt?: Date | null;
  workflowType?: WorkflowType | null;
};
export type NotificationOnNotificationForNotificationsGwtUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: GwtUserPatch;
};
export type NotificationsGwtUserIdFkeyGwtUserCreateInput = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email: string;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName: string;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName: string;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId: string;
  lastName: string;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId: UUID;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type GwtUserOnNotificationForNotificationsGwtUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: NotificationPatch;
};
export type NotificationPatch = {
  data?: any | null;
  gwtUserId?: UUID | null;
  gwtUserToGwtUserId?: NotificationsGwtUserIdFkeyInput | null;
  name?: string | null;
  rowId?: UUID | null;
};
export type NotificationsGwtUserIdFkeyNotificationsCreateInput = {
  data?: any | null;
  gwtUserToGwtUserId?: NotificationsGwtUserIdFkeyInput | null;
  name: string;
  rowId?: UUID | null;
};
export type ActivityOnActivityForActivityGwtUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: GwtUserPatch;
};
export type ActivityGwtUserIdFkeyGwtUserCreateInput = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email: string;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName: string;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName: string;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId: string;
  lastName: string;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId: UUID;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type GwtUserOnActivityForActivityGwtUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: ActivityPatch;
};
export type ActivityGwtUserIdFkeyActivityCreateInput = {
  createdAt?: Date | null;
  flowId?: UUID | null;
  flowToFlowId?: ActivityFlowIdFkInput | null;
  gwtUserToGwtUserId?: ActivityGwtUserIdFkeyInput | null;
  linkLocation?: string | null;
  object: string;
  rowId?: UUID | null;
  verb: string;
  workflowType?: WorkflowType | null;
};
export type CommentOnCommentForCommentGwtUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: GwtUserPatch;
};
export type CommentGwtUserIdFkeyGwtUserCreateInput = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email: string;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName: string;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName: string;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId: string;
  lastName: string;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId: UUID;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type GwtUserOnCommentForCommentGwtUserIdFkeyNodeIdUpdate = {
  id: string;
  patch: CommentPatch;
};
export type CommentGwtUserIdFkeyCommentCreateInput = {
  adminDashboardFlowCommentsUsingRowId?: AdminDashboardFlowCommentCommentIdFkeyInverseInput | null;
  commentText: string;
  commentToResponseTo?: CommentResponseToFkeyInput | null;
  commentViewedsUsingRowId?: CommentViewedCommentIdFkeyInverseInput | null;
  createdAt?: Date | null;
  flowCommentsUsingRowId?: FlowCommentCommentIdFkeyInverseInput | null;
  gwtUserToGwtUserId?: CommentGwtUserIdFkeyInput | null;
  responseTo?: UUID | null;
  reviewerDashboardFlowCommentsUsingRowId?: ReviewerDashboardFlowCommentCommentIdFkeyInverseInput | null;
  rowId?: UUID | null;
  updatedAt?: Date | null;
};
export type CompanyOnCompanyForCompanyOwnerIdFkeyNodeIdUpdate = {
  id: string;
  patch: GwtUserPatch;
};
export type CompanyOwnerIdFkeyGwtUserCreateInput = {
  activitiesUsingRowId?: ActivityGwtUserIdFkeyInverseInput | null;
  archivedAt?: Date | null;
  commentViewedsUsingRowId?: CommentViewedGwtUserIdFkeyInverseInput | null;
  commentsUsingRowId?: CommentGwtUserIdFkeyInverseInput | null;
  companiesUsingRowId?: CompanyOwnerIdFkeyInverseInput | null;
  companyId?: UUID | null;
  companyToCompanyId?: GwtUserCompanyIdFkeyInput | null;
  createdAt?: Date | null;
  email: string;
  emailBulk?: boolean | null;
  emailBulkTime?: Date | null;
  emailModuleNotifications?: ReadonlyArray<string | null> | null;
  emailOnAllAssignmentStatusChanges?: boolean | null;
  emailOnCommentAdded?: boolean | null;
  emailOnFeedbackProvided?: boolean | null;
  feedbacksToClaimedByIdUsingRowId?: FeedbackClaimedByIdFkeyInverseInput | null;
  feedbacksToGwtUserIdUsingRowId?: FeedbackGwtUserIdFkeyInverseInput | null;
  firstName: string;
  flowFavoritesUsingRowId?: FlowFavoriteGwtUserIdFkeyInverseInput | null;
  flowReviewersUsingRowId?: FlowReviewerReviewerIdFkeyInverseInput | null;
  flowsToAssignedByIdUsingRowId?: FlowAssignedByIdFkeyInverseInput | null;
  flowsToAssignedToIdUsingRowId?: FlowAssignedToIdFkeyInverseInput | null;
  flowsToLastModifiedByIdUsingRowId?: FlowLastModifiedByIdFkeyInverseInput | null;
  flowsToOwnerIdUsingRowId?: FlowOwnerIdFkeyInverseInput | null;
  fullName: string;
  isSystemUser?: boolean | null;
  jobTitle?: string | null;
  lanId: string;
  lastName: string;
  moduleSubmissionsUsingRowId?: ModuleSubmissionAcceptedByIdFkeyInverseInput | null;
  notificationsUsingRowId?: NotificationsGwtUserIdFkeyInverseInput | null;
  partnersonline?: boolean | null;
  polFileUploadsUsingRowId?: PolFileUploadGwtUserIdFkeyInverseInput | null;
  programs?: ReadonlyArray<ProgramType | null> | null;
  pronouns?: string | null;
  rowId: UUID;
  status?: UserStatus | null;
  updatedAt?: Date | null;
  userPriority?: number | null;
  userType?: UserType | null;
};
export type GwtUserOnGwtUserForGwtUserCompanyIdFkeyNodeIdUpdate = {
  id: string;
  patch: CompanyPatch;
};
export type GwtUserCompanyIdFkeyCompanyCreateInput = {
  archivedAt?: Date | null;
  createdAt?: Date | null;
  description?: string | null;
  gwtUserToOwnerId?: CompanyOwnerIdFkeyInput | null;
  gwtUsersUsingRowId?: GwtUserCompanyIdFkeyInverseInput | null;
  name?: string | null;
  ownerId?: UUID | null;
  rowId?: UUID | null;
  updatedAt?: Date | null;
};
export type CreateProfilePageUpdateProfileMutation$variables = {
  input: UpdateGwtUserInput;
};
export type CreateProfilePageUpdateProfileMutation$data = {
  readonly updateGwtUser: {
    readonly gwtUser: {
      readonly company: {
        readonly name: string;
        readonly rowId: UUID;
      } | null;
      readonly email: string;
      readonly firstName: string;
      readonly jobTitle: string | null;
      readonly lastName: string;
      readonly programs: ReadonlyArray<ProgramType | null>;
      readonly pronouns: string | null;
      readonly status: UserStatus;
      readonly userPriority: number;
      readonly userType: UserType;
    } | null;
  } | null;
};
export type CreateProfilePageUpdateProfileMutation = {
  response: CreateProfilePageUpdateProfileMutation$data;
  variables: CreateProfilePageUpdateProfileMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "firstName",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lastName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "jobTitle",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userPriority",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pronouns",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "programs",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "userType",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CreateProfilePageUpdateProfileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateGwtUserPayload",
        "kind": "LinkedField",
        "name": "updateGwtUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GwtUser",
            "kind": "LinkedField",
            "name": "gwtUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CreateProfilePageUpdateProfileMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UpdateGwtUserPayload",
        "kind": "LinkedField",
        "name": "updateGwtUser",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "GwtUser",
            "kind": "LinkedField",
            "name": "gwtUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Company",
                "kind": "LinkedField",
                "name": "company",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              },
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f9f987e3059c2ddc485d8bb6bdfe2d5b",
    "id": null,
    "metadata": {},
    "name": "CreateProfilePageUpdateProfileMutation",
    "operationKind": "mutation",
    "text": "mutation CreateProfilePageUpdateProfileMutation(\n  $input: UpdateGwtUserInput!\n) {\n  updateGwtUser(input: $input) {\n    gwtUser {\n      firstName\n      lastName\n      jobTitle\n      userPriority\n      company {\n        rowId\n        name\n        id\n      }\n      pronouns\n      email\n      programs\n      status\n      userType\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "36606cf445c25bdaae1b05730b1afee4";

export default node;
