import { Timeline } from '@enterprise-ui/canvas-ui-react'
import { useFragment } from 'react-relay'
import { graphql } from 'relay-runtime'
import { HistoryTab_module$key } from './__generated__/HistoryTab_module.graphql'
import { Link } from 'react-router-dom'
import { getGwtFormattedDate } from '../../util/dateUtils'

interface HistoryTabProps {
  moduleRef: HistoryTab_module$key | null | undefined
  id: string | undefined
}

export const HistoryTab = (props: HistoryTabProps) => {
  const data = useFragment(
    graphql`
      fragment HistoryTab_module on Module {
        flow {
          activities(orderBy: CREATED_AT_DESC) {
            nodes {
              rowId
              verb
              linkLocation
              object
              createdAt
              gwtUser {
                firstName
                lastName
              }
            }
          }
        }
      }
    `,
    props.moduleRef || null,
  )

  return (
    <div>
      <div className="small-width">
        <Timeline className="hc-pa-none">
          {data?.flow?.activities?.nodes.map((node) => (
            <>
              <Timeline.Flag>
                {getGwtFormattedDate(node.createdAt)}
              </Timeline.Flag>
              <Timeline.Item
                className={node.linkLocation ? 'time-item-hover' : ''}
                key={node.rowId}
              >
                {node.linkLocation && (
                  <Link to={node.linkLocation}>
                    <p className="hc-mt-dense">{`${node.gwtUser?.firstName} ${node.gwtUser?.lastName} ${node.verb} ${node.object}`}</p>
                  </Link>
                )}
                {!node.linkLocation && (
                  <p className="hc-mt-dense">{`${node.gwtUser?.firstName} ${node.gwtUser?.lastName} ${node.verb} ${node.object}`}</p>
                )}
              </Timeline.Item>
            </>
          ))}
        </Timeline>
      </div>
    </div>
  )
}
