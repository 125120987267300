import { useAuth } from '@praxis/component-auth'
import React, { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { useGwtRestApi } from '../hooks/useGwtRestApi'
import { decode } from 'jsonwebtoken'
import { clearLocalStorage } from '../util/authUtil'
import Cookies from 'js-cookie'

interface GwtAuthCookieProps {
  children: React.ReactNode
}

export const GwtAuthCookie = ({ children }: GwtAuthCookieProps) => {
  const auth = useAuth()
  const { login } = useGwtRestApi()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (auth.session?.accessToken) {
      queryClient.invalidateQueries(['login'])
      login()
    } else {
      // verify url is root
      if (window.location.pathname !== '/') {
        window.location.href = '/'
      }
    }
  }, [auth.session?.accessToken, queryClient, login])

  useEffect(() => {
    // check for session change (default every 2 seconds)
    const intervalId = runInterval(checkCookie)

    return () => {
      clearInterval(intervalId)
    }
  }, [])

  return <>{children}</>
}

const checkCookie = () => {
  let loggedInUser = getIdTokenUser(Cookies.get('id2Token'))
  let sessionJson = getCookieSessionJson(Cookies.get('SSOSESSIONINFO'))

  // console.debug('Logged in User: ' + loggedInUser)
  // console.debug('Session user: ' + sessionJson?.user)
  if (sessionJson && !sessionJson?.login_status) {
    // the session is logged out! Clear everything and relog!
    clearLocalStorage()
    window.location.href = '/'
  }
  if (loggedInUser !== undefined && sessionJson?.user !== undefined) {
    // verify the user is the same one from cookie set by assent!
    if (loggedInUser !== String(sessionJson?.user)) {
      clearLocalStorage()
      window.location.href = '/'
    }
  }
}

const getIdTokenUser = (cookie: any): string | undefined => {
  if (cookie) {
    let decoded_token = decode(cookie)
    if (decoded_token) {
      return decoded_token['username']
    }
  }
}

const getCookieSessionJson = (cookie: any): any => {
  if (cookie) {
    const SSOSESSIONINFOcoded = window.atob(cookie)
    return JSON.parse(SSOSESSIONINFOcoded)
  }
}

const runInterval = (callback: () => void, interval = 2000) => {
  return setInterval(() => {
    callback()
  }, interval)
}
