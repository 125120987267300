/**
 * @generated SignedSource<<e1fb2456e3ac5dea0f533d2a12557113>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type AssignmentStatus = "ACCEPTED" | "CANCELED" | "IN_PROGRESS" | "NONE" | "NOT_STARTED" | "READY_FOR_REVIEW" | "REJECTED" | "UNDER_REVIEW" | "%future added value";
export type SubmissionStatus = "ACCEPTED" | "READY_FOR_REVIEW" | "REJECTED" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type ReviewerTab_module$data = {
  readonly attempts: number;
  readonly flow: {
    readonly assignedAt: Date | null;
    readonly assignedBy: {
      readonly firstName: string;
      readonly lastName: string;
    } | null;
    readonly assignedTo: {
      readonly firstName: string;
      readonly lastName: string;
    } | null;
    readonly assignmentStatus: AssignmentStatus;
    readonly description: string;
    readonly dueAt: Date | null;
    readonly flowReviewers: {
      readonly nodes: ReadonlyArray<{
        readonly reviewer: {
          readonly firstName: string;
          readonly lastName: string;
        } | null;
      }>;
    };
    readonly name: string;
    readonly rowId: UUID;
    readonly " $fragmentSpreads": FragmentRefs<"StatusChip_flow">;
  } | null;
  readonly galleryAssetComponentsId: UUID | null;
  readonly moduleSubmissionsByModuleFlowId: {
    readonly nodes: ReadonlyArray<{
      readonly id: string;
      readonly submissionStatus: SubmissionStatus;
    }>;
  };
  readonly tasksByModuleFlowId: {
    readonly nodes: ReadonlyArray<{
      readonly flow: {
        readonly name: string;
      } | null;
    }>;
  };
  readonly " $fragmentSpreads": FragmentRefs<"ReviewerButton_Fragment" | "StartNextAttemptButton_Fragment" | "StartReviewingButton_Fragment" | "SubmittedAssetFilesDisplay_Fragment">;
  readonly " $fragmentType": "ReviewerTab_module";
};
export type ReviewerTab_module$key = {
  readonly " $data"?: ReviewerTab_module$data;
  readonly " $fragmentSpreads": FragmentRefs<"ReviewerTab_module">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "firstName",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "lastName",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReviewerTab_module",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SubmittedAssetFilesDisplay_Fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ReviewerButton_Fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StartReviewingButton_Fragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StartNextAttemptButton_Fragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Flow",
      "kind": "LinkedField",
      "name": "flow",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "StatusChip_flow"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rowId",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "assignedAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GwtUser",
          "kind": "LinkedField",
          "name": "assignedBy",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "GwtUser",
          "kind": "LinkedField",
          "name": "assignedTo",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "FlowReviewersConnection",
          "kind": "LinkedField",
          "name": "flowReviewers",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "FlowReviewer",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GwtUser",
                  "kind": "LinkedField",
                  "name": "reviewer",
                  "plural": false,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dueAt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "assignmentStatus",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "PRIORITY_ASC"
        }
      ],
      "concreteType": "TasksConnection",
      "kind": "LinkedField",
      "name": "tasksByModuleFlowId",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Task",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Flow",
              "kind": "LinkedField",
              "name": "flow",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "tasksByModuleFlowId(orderBy:\"PRIORITY_ASC\")"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "orderBy",
          "value": "ATTEMPT_DESC"
        }
      ],
      "concreteType": "ModuleSubmissionsConnection",
      "kind": "LinkedField",
      "name": "moduleSubmissionsByModuleFlowId",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ModuleSubmission",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "submissionStatus",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "moduleSubmissionsByModuleFlowId(orderBy:\"ATTEMPT_DESC\")"
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "galleryAssetComponentsId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "attempts",
      "storageKey": null
    }
  ],
  "type": "Module",
  "abstractKey": null
};
})();

(node as any).hash = "148d9c56c026d6cc87b786d75b31c86d";

export default node;
