/**
 * @generated SignedSource<<9ca70296202b4ea56cebb05790c71e56>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type DeliverableFileUploadTableMutation$variables = {
  moduleFlowId: UUID;
};
export type DeliverableFileUploadTableMutation$data = {
  readonly initializeModuleDeliverableAsset: {
    readonly module: {
      readonly flowId: UUID;
      readonly galleryAssetDeliverableId: UUID | null;
    } | null;
  } | null;
};
export type DeliverableFileUploadTableMutation = {
  response: DeliverableFileUploadTableMutation$data;
  variables: DeliverableFileUploadTableMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "moduleFlowId"
  }
],
v1 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "moduleFlowId",
        "variableName": "moduleFlowId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "flowId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "galleryAssetDeliverableId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DeliverableFileUploadTableMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InitializeModuleDeliverableAssetPayload",
        "kind": "LinkedField",
        "name": "initializeModuleDeliverableAsset",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Module",
            "kind": "LinkedField",
            "name": "module",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DeliverableFileUploadTableMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "InitializeModuleDeliverableAssetPayload",
        "kind": "LinkedField",
        "name": "initializeModuleDeliverableAsset",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Module",
            "kind": "LinkedField",
            "name": "module",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8ce02fb4b5a1006d1a34cadb5b07776b",
    "id": null,
    "metadata": {},
    "name": "DeliverableFileUploadTableMutation",
    "operationKind": "mutation",
    "text": "mutation DeliverableFileUploadTableMutation(\n  $moduleFlowId: UUID!\n) {\n  initializeModuleDeliverableAsset(input: {moduleFlowId: $moduleFlowId}) {\n    module {\n      flowId\n      galleryAssetDeliverableId\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "92fb8df7d1c22db1718bfcfd71dbcf38";

export default node;
