/**
 * @generated SignedSource<<a0be4a60ec04aa00e8b9aba0863fcdf1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CommentsTab_moduleComments$data = {
  readonly flow: {
    readonly flowComments: {
      readonly nodes: ReadonlyArray<{
        readonly commentId: UUID;
        readonly " $fragmentSpreads": FragmentRefs<"CommentCardFragment">;
      }>;
    };
    readonly rowId: UUID;
    readonly " $fragmentSpreads": FragmentRefs<"CommentAddFragment">;
  } | null;
  readonly id: string;
  readonly " $fragmentType": "CommentsTab_moduleComments";
};
export type CommentsTab_moduleComments$key = {
  readonly " $data"?: CommentsTab_moduleComments$data;
  readonly " $fragmentSpreads": FragmentRefs<"CommentsTab_moduleComments">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./FlowCommentsRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "CommentsTab_moduleComments",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Flow",
      "kind": "LinkedField",
      "name": "flow",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rowId",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "CommentAddFragment"
        },
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "filter",
              "value": {
                "comment": {
                  "responseTo": {
                    "isNull": true
                  }
                }
              }
            },
            {
              "kind": "Literal",
              "name": "orderBy",
              "value": "COMMENT_BY_COMMENT_ID__CREATED_AT_DESC"
            }
          ],
          "concreteType": "FlowCommentsConnection",
          "kind": "LinkedField",
          "name": "flowComments",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "FlowComment",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "commentId",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "CommentCardFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "flowComments(filter:{\"comment\":{\"responseTo\":{\"isNull\":true}}},orderBy:\"COMMENT_BY_COMMENT_ID__CREATED_AT_DESC\")"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Module",
  "abstractKey": null
};

(node as any).hash = "c86b6655a6b1781da9a336952820e2ba";

export default node;
