import { Button, Grid, Modal, Tooltip } from '@enterprise-ui/canvas-ui-react'
import { graphql, useFragment } from 'react-relay'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useGwtMutation } from '../../../hooks'
import { DropdownSelect } from '../../../GenericComponents/DropdownSelect/DropdownSelect'
import { useState } from 'react'
import { usePublishGlobalNotification } from '../../../hooks/useNotifications'
import { useNavigate } from 'react-router-dom'
import './UserOptions.scss'
import classNames from 'classnames'
import { UserOptions_gwtUser$key } from './__generated__/UserOptions_gwtUser.graphql'
import { UserOptionsMutation } from './__generated__/UserOptionsMutation.graphql'
import { useAuthHelper } from '../../../hooks/useAuthHelper'

interface UserOptionsProps {
  userRef: UserOptions_gwtUser$key | null
  className?: string
}

export const UserOptions = ({
  userRef = null,
  className,
}: UserOptionsProps) => {
  const navigate = useNavigate()

  const { protectArray, protectStringArray } = useAuthHelper()

  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const { publishToLocal } = usePublishGlobalNotification()

  const data = useFragment(
    graphql`
      fragment UserOptions_gwtUser on GwtUser {
        rowId
      }
    `,
    userRef,
  )

  const { commit } = useGwtMutation<UserOptionsMutation>(graphql`
    mutation UserOptionsMutation($input: UpdateGwtUserInput!) {
      updateGwtUser(input: $input) {
        gwtUser {
          archivedAt
        }
      }
    }
  `)

  return (
    <div className={classNames('user-options', className)}>
      <div className="options">
        <DropdownSelect
          shouldSelect={false}
          options={protectArray([
            {
              label: 'View profile',
              className: 'hc-pl-dense',
              onSelect: () => {
                navigate(`/users/${data?.rowId}`)
              },
              allowed: ['ADMIN', 'REVIEWER'],
            },
            {
              label: 'Edit profile',
              className: 'hc-pl-dense',
              onSelect: () => {
                navigate(`/user/edit/${data?.rowId}`)
              },
              allowed: ['ADMIN'],
            },
            {
              label: 'Delete profile',
              className: 'error-color hc-pl-dense',
              onSelect: () => {
                setDeleteModalOpen(true)
              },
              allowed: ['ADMIN'],
            },
          ])}
        >
          <Tooltip
            content={protectStringArray([
              { val: 'View', allowed: ['ADMIN', 'REVIEWER'] },
              { val: 'Edit', allowed: ['ADMIN'] },
              { val: 'Delete', allowed: ['ADMIN'] },
            ])}
            location="left"
          >
            <Button type="secondary" iconOnly>
              <FontAwesomeIcon icon={faEllipsisV} />
            </Button>
          </Tooltip>
        </DropdownSelect>
      </div>
      {deleteModalOpen && (
        <Modal
          isVisible={deleteModalOpen}
          headingText="Delete profile"
          className="align-left hc-pt-lg"
          onRefuse={() => setDeleteModalOpen(false)}
        >
          <div className="hc-fs-md hc-pa-normal">
            This profile will be permanently deleted. You will no longer be able
            to view it on your list or access it from the Profiles page
            <Grid.Container>
              <Grid.Item xs={7} />
              <Grid.Item xs={5} className="hc-ta-right">
                <Button
                  className="hc-mr-normal"
                  onClick={() => {
                    setDeleteModalOpen(false)
                  }}
                >
                  Cancel
                </Button>
                <Button
                  type="destructive"
                  onClick={() => {
                    commit(
                      {
                        input: {
                          patch: {
                            archivedAt: new Date(),
                          },
                          rowId: data?.rowId,
                        },
                      },
                      () => {
                        publishToLocal('ARCHIVE_PROFILE_NOTIFICATION', {
                          rowId: data?.rowId,
                        })
                      },
                    )
                    setDeleteModalOpen(false)
                  }}
                >
                  Delete
                </Button>
              </Grid.Item>
            </Grid.Container>
          </div>
        </Modal>
      )}
    </div>
  )
}
