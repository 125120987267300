import { useCallback, useState } from 'react'
import {
  PieChart,
  Pie,
  Cell,
  Sector,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'

interface StatusPieChartProps {
  data: { name: string; value: any }[]
}

export const StatusPieChart = ({ data }: StatusPieChartProps) => {
  const [activeIndex, setActiveIndex] = useState(-1)
  const onPieEnter = useCallback(
    (_: any, index: number) => {
      setActiveIndex(index)
    },
    [setActiveIndex],
  )

  const CalculateTotalStatus = () => {
    let total = 0
    for (let i = 0; i < data.length; i++) {
      total += data[i].value
    }
    return total
  }

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const totalStatuses = CalculateTotalStatus()
      const percentage = (payload[0].value / totalStatuses) * 100
      return (
        <div className="custom-tooltip">
          <span>{`${payload[0].name}: ${percentage.toFixed(2)}%`}</span>
        </div>
      )
    }

    return null
  }

  return (
    <div style={{ width: '100%', height: 200, textAlign: 'center' }}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={data}
            cx={'50%'}
            cy={'50%'}
            fill="#8884d8"
            dataKey="value"
            onMouseEnter={onPieEnter}
            onMouseLeave={() => {
              setActiveIndex(-1)
            }}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={CalculateColor(entry.name)}
                stroke=""
              />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
          {/* <Legend
            layout="vertical"
            align="right"
            verticalAlign="middle"
          ></Legend> */}
        </PieChart>
      </ResponsiveContainer>
    </div>
  )
}

const CalculateColor = (statusName: string) => {
  switch (statusName) {
    case 'Assigned': {
      return '#0088FE'
    }
    case 'Approved': {
      return '#42ff94'
    }
    case 'Rejected': {
      return '#ff424f'
    }
    case 'Ready for review': {
      return '#00C49F'
    }
    case 'Overdue': {
      return '#FF8042'
    }
    case 'Due soon': {
      return '#FFBB28'
    }
    default: {
      return '#d642ff'
    }
  }
}

const renderActiveShape = (props: any) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  )
}
