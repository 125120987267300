/**
 * @generated SignedSource<<5d1e6cd17cef89e0cd9ca4235137c40a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UserOptions_gwtUser$data = {
  readonly rowId: UUID;
  readonly " $fragmentType": "UserOptions_gwtUser";
};
export type UserOptions_gwtUser$key = {
  readonly " $data"?: UserOptions_gwtUser$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserOptions_gwtUser">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserOptions_gwtUser",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    }
  ],
  "type": "GwtUser",
  "abstractKey": null
};

(node as any).hash = "57fa4d5ffdd41725c5fa3b4aeb584bdf";

export default node;
