/**
 * @generated SignedSource<<6f013b7e657b399e97efccea161860d4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type FeedbackLevel = "CRITICAL" | "MAJOR" | "MINOR" | "SUGGESTION" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type FeedbackTablePaginationFragment_feedback$data = {
  readonly feedbacks: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly claimedBy: {
          readonly fullName: string;
          readonly rowId: UUID;
        } | null;
        readonly createdAt: Date | null;
        readonly feedback: string;
        readonly feedbackLevel: FeedbackLevel;
        readonly gwtUser: {
          readonly fullName: string;
          readonly rowId: UUID;
          readonly " $fragmentSpreads": FragmentRefs<"UserAvatar_gwtUser">;
        } | null;
        readonly resolved: boolean;
        readonly rowId: UUID;
        readonly " $fragmentSpreads": FragmentRefs<"FeedbackOptions_feedback">;
      };
    }>;
    readonly totalCount: number;
  } | null;
  readonly " $fragmentType": "FeedbackTablePaginationFragment_feedback";
};
export type FeedbackTablePaginationFragment_feedback$key = {
  readonly " $data"?: FeedbackTablePaginationFragment_feedback$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeedbackTablePaginationFragment_feedback">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "feedbacks"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fullName",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "feedbackFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "feedbacksOrderBy"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includeArchived"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./FeedbackTablePaginationQuery_Refetchable.graphql')
    }
  },
  "name": "FeedbackTablePaginationFragment_feedback",
  "selections": [
    {
      "alias": "feedbacks",
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "feedbackFilter"
        },
        {
          "kind": "Variable",
          "name": "includeArchived",
          "variableName": "includeArchived"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "feedbacksOrderBy"
        }
      ],
      "concreteType": "FeedbacksConnection",
      "kind": "LinkedField",
      "name": "__FeedbackTable_feedbacks_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FeedbacksEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Feedback",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "FeedbackOptions_feedback"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "resolved",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GwtUser",
                  "kind": "LinkedField",
                  "name": "gwtUser",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "UserAvatar_gwtUser"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GwtUser",
                  "kind": "LinkedField",
                  "name": "claimedBy",
                  "plural": false,
                  "selections": [
                    (v1/*: any*/),
                    (v2/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "feedback",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "feedbackLevel",
                  "storageKey": null
                },
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "7d18db7df61c0b4c6c9a5d1b37de0a31";

export default node;
