import { useCallback, useState } from 'react'
import {
  PieChart,
  Pie,
  Cell,
  Sector,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'

interface CompletionRatePieChartProps {
  data: { name: string; value: any }[]
}

export const CompletionRatePieChart = ({
  data,
}: CompletionRatePieChartProps) => {
  const [activeIndex, setActiveIndex] = useState(-1)
  const onPieEnter = useCallback(
    (_: any, index: number) => {
      setActiveIndex(index)
    },
    [setActiveIndex],
  )

  const CalculateCompletionRate = () => {
    let total = 0
    for (let i = 0; i < data.length; i++) {
      total += data[i].value
    }
    let totalAccepted = 0
    for (let i = 0; i < data.length; i++) {
      if (data[i].name === 'Approved') {
        totalAccepted += 1
      }
    }
    const newData = [
      { name: 'Approved', value: totalAccepted },
      { name: 'Not Approved', value: total - totalAccepted },
    ]
    return newData
  }
  const displayedData = CalculateCompletionRate()
  const CalculateTotal = () => {
    let total = 0
    for (let i = 0; i < data.length; i++) {
      total += data[i].value
    }
    return total
  }
  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      const percentage = (payload[0].value / CalculateTotal()) * 100
      return (
        <div className="custom-tooltip">
          <span>{`${payload[0].name}: ${percentage.toFixed(2)}%`}</span>
        </div>
      )
    }
    return null
  }

  return (
    <div style={{ width: '100%', height: 200, textAlign: 'center' }}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={displayedData}
            cx={'50%'}
            cy={'50%'}
            fill="#8884d8"
            dataKey="value"
            onMouseEnter={onPieEnter}
            onMouseLeave={() => {
              setActiveIndex(-1)
            }}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
                stroke=""
              />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  )
}
const COLORS = [
  '#42ff94',
  '#ff424f',
  '#00C49F',
  '#FFBB28',
  '#FF8042',
  '#d642ff',
  '#0088FE',
]
const renderActiveShape = (props: any) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  )
}
