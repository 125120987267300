import { startCase } from 'lodash'

export const getRequiredMessage = ({
  name,
  label,
  required,
  requiredMessage,
}: {
  name: string
  label?: string
  required: boolean
  requiredMessage?: string
}) =>
  required
    ? requiredMessage || `${startCase(label || name)} is required`
    : false
