import { Modal } from '@enterprise-ui/canvas-ui-react'
import { Feedback } from './Feedback'
import { useAtom } from 'jotai'
import { feedbackAtom } from '../../state/atoms'

export const FeedbackModal = () => {
  const [feedback, setFeedback] = useAtom(feedbackAtom)
  const handleRefuseModal = () =>
    setFeedback({ visible: false, type: feedback.type })

  return (
    <>
      {feedback.visible && (
        <Modal
          className="feedback-modal"
          isVisible={feedback.visible}
          onRefuse={handleRefuseModal}
          headingText={
            feedback.type === 'FEEDBACK' ? 'Submit feedback' : 'Submit issue'
          }
        >
          <Feedback />
        </Modal>
      )}
    </>
  )
}
