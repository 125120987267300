import EnterpriseIcon, { ArrowsVerticalIcon } from '@enterprise-ui/icons'
import { useEffect } from 'react'
import { useState } from 'react'
import { Table, TableHeaderProps } from '@enterprise-ui/canvas-ui-react'
import './TableSortHeader.scss'

interface SortProps<OrderBy> extends TableHeaderProps {
  selectedOrderBy?: OrderBy
  sortUpFilter: OrderBy
  sortDownFilter: OrderBy
  onSort: (orderBy: OrderBy) => void
}

export const TableSortHeader = <OrderBy,>({
  selectedOrderBy,
  sortUpFilter,
  sortDownFilter,
  onSort,
  children,
  ...props
}: SortProps<OrderBy>) => {
  const [sortUpState, setSortUpState] = useState(false)
  const [sortDownState, setSortDownState] = useState(false)

  const updateSort = () => {
    if (!sortUpState && !sortDownState) {
      setSortUpState(true)
      onSort(sortUpFilter)
    } else if (sortUpState) {
      setSortUpState(false)
      setSortDownState(true)
      onSort(sortDownFilter)
    } else if (sortDownState) {
      setSortDownState(false)
    }
  }

  useEffect(() => {
    if (
      selectedOrderBy !== sortUpFilter &&
      selectedOrderBy !== sortDownFilter
    ) {
      setSortUpState(false)
      setSortDownState(false)
    }
  }, [selectedOrderBy, sortUpFilter, sortDownFilter])

  return (
    <Table.Header
      {...props}
      className="table-sort-heading"
      onClick={updateSort}
    >
      {children}
      <div className="fa-layers fa-fw sort-style">
        <EnterpriseIcon
          // size="sm"
          icon={ArrowsVerticalIcon}
          color={sortUpState ? 'black' : 'grey'}
          className="hc-mb-min sort-icon"
        />
      </div>
    </Table.Header>
  )
}
