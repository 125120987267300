import {
  Grid,
  Card,
  Divider,
  Tooltip,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { SyntheticEvent, useEffect, useState } from 'react'
import { UserAvatar } from '../Users/UserAvatar'
import { FlowLink } from '../Flows/FlowLink'
import { graphql, usePaginationFragment } from 'react-relay'
import { ReviewerDiscussionCardPaginationQuery } from './__generated__/ReviewerDiscussionCardPaginationQuery.graphql'
import { useSubscriptionRefreshQuery } from '../../hooks/useRefresh'
import { usePaginateDataLength } from '../../hooks/usePaginateDataLength'
import { ReviewerDiscussionCardPaginationFragment_reviewerDashboardFlowComments$key } from './__generated__/ReviewerDiscussionCardPaginationFragment_reviewerDashboardFlowComments.graphql'
import { useGwtMutation, useGwtUser } from '../../hooks'
import { getTimeFromCreatedAtString } from '../../util/dateUtils'
import {
  deletedCommentFlowIdAtom,
  deletedCommentIdAtom,
  globalNotificationAtom,
} from '../../state/atoms'
import { TimedSpinButton } from '../../GenericComponents/TimedSpinButton/TimedSpinButton'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import {
  ReviewerDiscussionCardMutation,
  ReviewerDiscussionCardMutation$variables,
} from './__generated__/ReviewerDiscussionCardMutation.graphql'
import { getNotificationInputObject } from '../../util/relayUtils'
import { useAtom } from 'jotai'
import {
  ReviewerDiscussionCardUndoDeleteMutation,
  ReviewerDiscussionCardUndoDeleteMutation$variables,
} from './__generated__/ReviewerDiscussionCardUndoDeleteMutation.graphql'
import classNames from 'classnames'

export const ReviewerDiscussionCard = () => {
  const { data, loadNext, hasNext, isLoadingNext } =
    useReviewerDiscussionCardQuery()

  const scrollEvent = (e: SyntheticEvent) => {
    if (isLoadingNext || !hasNext) {
      return
    }
    const target = e.target as HTMLTextAreaElement
    const scrollLeft = target.scrollHeight - target.scrollTop
    if (scrollLeft / target.clientHeight < 2) {
      loadNext(20)
    }
  }

  const notificationCount = data?.reviewerDashboardFlowComments?.edges.filter(
    (comment) => comment.node.comment?.unread,
  ).length

  const deleteComment = useDeleteReviewerDiscussionCardFlowComment()

  return (
    <Card className="cardLayout">
      <div className="cardHeader flex hc-pa-lg">
        <Grid.Container spacing="none" align="center" className="flex-grow">
          <Grid.Item>
            <div className="card-header-text">Discussion</div>
          </Grid.Item>
          <Grid.Item className="hc-ml-sm">
            {!!notificationCount && (
              <div className="comment-notification-badge">
                {notificationCount}{' '}
              </div>
            )}
          </Grid.Item>
        </Grid.Container>
      </div>
      <Divider />
      <div onScroll={scrollEvent} className="comments flex-grow">
        {data &&
          data.reviewerDashboardFlowComments?.edges.map((page) => {
            const flow_comment = page.node
            return (
              <div key={flow_comment.comment?.rowId}>
                <div
                  className={classNames(
                    'hc-pt-lg',
                    'hc-pl-lg',
                    'hc-pb-lg',
                    'hc-pr-lg',
                    {
                      'comment-notification-border':
                        flow_comment.comment?.unread,
                    },
                  )}
                >
                  <Grid.Container spacing="none">
                    <Grid.Item xs={1}>
                      <UserAvatar
                        userRef={flow_comment.comment?.gwtUser}
                        showName={false}
                        aria-label={''}
                        children={undefined}
                      />
                    </Grid.Item>
                    <Grid.Item className="comment-container" xs={9}>
                      <div className="hc-ml-xs">
                        <span className="user-name-text">
                          {flow_comment.comment?.gwtUser?.fullName}
                        </span>
                        <span className="sub-text hc-ml-xs">
                          {getTimeFromCreatedAtString(
                            flow_comment.comment?.createdAt,
                          )}
                        </span>

                        {
                          <FlowLink isComment flowRef={flow_comment.flow}>
                            <p className="newline comment-hover dashboard-comment">
                              {flow_comment.comment?.commentText}
                            </p>
                          </FlowLink>
                        }
                      </div>
                    </Grid.Item>
                    <Grid.Item
                      xs={2}
                      className="self-center flex-grow align-right"
                    >
                      <Tooltip content="Remove comment" location="left">
                        <TimedSpinButton
                          iconOnly
                          type="default"
                          size="dense"
                          aria-label="remove-comment"
                          onClick={() => {
                            deleteComment(
                              flow_comment.comment?.rowId,
                              flow_comment.flow?.rowId,
                            )
                          }}
                          className="comment-delete-button"
                        >
                          <FontAwesomeIcon icon={faTimes} color="grey" />
                        </TimedSpinButton>
                      </Tooltip>
                    </Grid.Item>
                  </Grid.Container>
                </div>
                <Divider />
              </div>
            )
          })}
      </div>
    </Card>
  )
}

export const useReviewerDiscussionCardQuery = () => {
  const [dataLength, setDataLength] = useState(20)
  const user = useGwtUser()

  const pageData =
    useSubscriptionRefreshQuery<ReviewerDiscussionCardPaginationQuery>(
      graphql`
        query ReviewerDiscussionCardPaginationQuery(
          $count: Int!
          $userId: UUID
        ) {
          ...ReviewerDiscussionCardPaginationFragment_reviewerDashboardFlowComments
            @arguments(count: $count, userId: $userId)
        }
      `,
      { count: dataLength || 20, userId: user.rowId },
      {
        component: 'REVIEWER_DISCUSSION_CARD',
        uniqueComponentId: '',
        onNotification: [
          'REVIEWER_DASHBOARD_FLOW_COMMENT_NOTIFICATION',
          'REVIEWER_DASHBOARD_FLOW_COMMENT_DELETE_NOTIFICATION',
          'REVIEWER_DASHBOARD_UNDO_FLOW_COMMENT_DELETE_NOTIFICATION',
          'LAST_SEEN_COMMENT_UPDATED_NOTIFICATION',
        ],
        includeSelfNotifications: true,
      },
    )

  const paginationFragment = usePaginationFragment<
    ReviewerDiscussionCardPaginationQuery,
    ReviewerDiscussionCardPaginationFragment_reviewerDashboardFlowComments$key
  >(
    graphql`
      fragment ReviewerDiscussionCardPaginationFragment_reviewerDashboardFlowComments on Query
      @argumentDefinitions(
        count: { type: "Int" }
        cursor: { type: "Cursor" }
        userId: { type: "UUID" }
      )
      @refetchable(
        queryName: "ReviewerDiscussionCardFlowCommentsPaginationQuery"
      ) {
        reviewerDashboardFlowComments(
          orderBy: UNREAD_COMMENTS_CREATED_AT_DESC
          first: $count
          after: $cursor
          filter: {
            flow: {
              flowReviewers: { some: { reviewerId: { equalTo: $userId } } }
            }
          }
        )
          @connection(
            key: "ReviewerDiscussionCardPaginationFragment_reviewerDashboardFlowComments"
          ) {
          edges {
            node {
              comment {
                rowId
                unread
                createdAt
                commentText
                gwtUser {
                  fullName
                  ...UserAvatar_gwtUser
                }
              }
              flow {
                rowId
                name
                ...FlowLink_flow
              }
            }
          }
        }
      }
    `,
    pageData,
  )

  const paginateLength = usePaginateDataLength(
    paginationFragment?.data?.reviewerDashboardFlowComments?.edges,
    20,
  )

  useEffect(() => {
    setDataLength(paginateLength)
  }, [paginateLength])

  return { ...paginationFragment, pageData }
}

const useDeleteReviewerDiscussionCardFlowComment = () => {
  const [, setRecentDeletedCommentId] = useAtom(deletedCommentIdAtom)
  const [, setRecentDeletedCommentFlowId] = useAtom(deletedCommentFlowIdAtom)
  const makeToast = ToastProvider.useToaster()
  const [, publishNotification] = useAtom(globalNotificationAtom)
  const { undoDeleteComment } = useUndoReviewerDiscussionCardDelete()

  const { commit } = useGwtMutation<ReviewerDiscussionCardMutation>(graphql`
    mutation ReviewerDiscussionCardMutation(
      $flowId: UUID!
      $commentId: UUID!
      $notificationInput: NotificationInput!
    ) {
      createNotification(input: { notification: $notificationInput }) {
        clientMutationId
      }
      deleteReviewerDashboardFlowComment(
        input: { flowId: $flowId, commentId: $commentId }
      ) {
        clientMutationId
        deletedReviewerDashboardFlowCommentId
      }
    }
  `)

  // Handles the deletion of a comment in the Discussion Board
  return (commentId: string | undefined, moduleFlowId: string | undefined) => {
    const mutation: ReviewerDiscussionCardMutation$variables =
      {} as ReviewerDiscussionCardMutation$variables
    mutation.commentId = commentId
    mutation.flowId = moduleFlowId
    mutation.notificationInput = getNotificationInputObject(
      'REVIEWER_DASHBOARD_FLOW_COMMENT_DELETE_NOTIFICATION',
    )
    commit(
      mutation,
      () => {
        makeToast({
          autoHideDuration: 10000,
          type: 'success',
          heading: 'Comment deleted',
          message: 'Comment was successfully deleted.',
          links: [
            {
              text: 'undo',
              onClick: () => {
                undoDeleteComment(commentId, moduleFlowId)
              },
            },
          ],
        })
        publishNotification({
          id: 'local',
          gwt_user_id: 'local',
          name: 'REVIEWER_DASHBOARD_FLOW_COMMENT_DELETE_NOTIFICATION',
          data: null,
        })
      },
      () => {
        makeToast({
          type: 'error',
          heading: 'Comment delete failed',
          message: 'Common was unsuccessfully deleted.',
        })
      },
    )
    setRecentDeletedCommentFlowId(`${moduleFlowId}`)
    setRecentDeletedCommentId(`${commentId}`)
  }
}

const useUndoReviewerDiscussionCardDelete = () => {
  const [, publishNotification] = useAtom(globalNotificationAtom)
  const makeToast = ToastProvider.useToaster()

  const { commit } = useGwtMutation<ReviewerDiscussionCardUndoDeleteMutation>(
    graphql`
      mutation ReviewerDiscussionCardUndoDeleteMutation(
        $flowId: UUID!
        $commentId: UUID!
        $notificationInput: NotificationInput!
      ) {
        createNotification(input: { notification: $notificationInput }) {
          clientMutationId
        }
        createReviewerDashboardFlowComment(
          input: {
            reviewerDashboardFlowComment: {
              flowId: $flowId
              commentId: $commentId
            }
          }
        ) {
          clientMutationId
        }
      }
    `,
  )

  const undoDeleteComment = (
    commentId: string | undefined,
    moduleFlowId: string | undefined,
  ) => {
    const mutation = {} as ReviewerDiscussionCardUndoDeleteMutation$variables
    mutation.commentId = commentId
    mutation.flowId = moduleFlowId
    mutation.notificationInput = getNotificationInputObject(
      'REVIEWER_DASHBOARD_UNDO_FLOW_COMMENT_DELETE_NOTIFICATION',
    )
    commit(
      mutation,
      () => {
        publishNotification({
          id: 'local',
          gwt_user_id: 'local',
          name: 'REVIEWER_DASHBOARD_UNDO_FLOW_COMMENT_DELETE_NOTIFICATION',
          data: null,
        })
      },
      () => {
        makeToast({
          type: 'error',
          heading: 'Comment delete failed.',
          message: 'Common was unsuccessfully deleted',
        })
      },
    )
  }

  return { undoDeleteComment }
}
