// WARNING: Canvas v7 - Component "Container" has been deprecated. Please remove this import.
import { Grid, Layout, GridContainer } from '@enterprise-ui/canvas-ui-react'
import './Admin.scss'
import { AdminDiscussionCard } from './AdminDiscussionCard'
import { AdminTemplateCard } from './AdminTemplateCard'
import { StatusCard } from './StatusCard'
import { SuspenseRingLoader } from '../../loadingComponents/SuspenseRingLoader'
import { useEffect } from 'react'
import { useAtom } from 'jotai'
import { titleAtom } from '../../state/atoms'

export const AdminDashboard = () => {
  const [, setTitle] = useAtom(titleAtom)

  useEffect(() => {
    setTitle('Admin')
  })

  return (
    <Layout.Body includeRail>
      <GridContainer className="hc-pl-expanded hc-pr-expanded hc-pt-2x hc-pb-2x admin">
        <Grid.Item xs={4} className="hc-pr-lg">
          <SuspenseRingLoader>
            <StatusCard />
          </SuspenseRingLoader>
        </Grid.Item>
        <Grid.Item xs={4} className="hc-pr-lg">
          <SuspenseRingLoader>
            <AdminDiscussionCard />
          </SuspenseRingLoader>
        </Grid.Item>
        <Grid.Item xs={4}>
          <div className="hc-pa-none cardLayout">
            <SuspenseRingLoader>
              <AdminTemplateCard />
            </SuspenseRingLoader>
          </div>
        </Grid.Item>
      </GridContainer>
    </Layout.Body>
  )
}
