/**
 * @generated SignedSource<<f92c423e80cb4274d32d93d14762f8cb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HistoryCardPaginationQuery$variables = {
  userId: UUID;
};
export type HistoryCardPaginationQuery$data = {
  readonly modules: {
    readonly nodes: ReadonlyArray<{
      readonly flow: {
        readonly completedAt: Date | null;
        readonly name: string;
        readonly rowId: UUID;
        readonly " $fragmentSpreads": FragmentRefs<"FlowLink_flow" | "StatusChip_flow">;
      } | null;
    }>;
  } | null;
};
export type HistoryCardPaginationQuery = {
  response: HistoryCardPaginationQuery$data;
  variables: HistoryCardPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "fields": [
              {
                "kind": "Variable",
                "name": "equalTo",
                "variableName": "userId"
              }
            ],
            "kind": "ObjectValue",
            "name": "assignedToId"
          },
          {
            "kind": "Literal",
            "name": "assignmentStatus",
            "value": {
              "in": [
                "ACCEPTED"
              ]
            }
          }
        ],
        "kind": "ObjectValue",
        "name": "flow"
      }
    ],
    "kind": "ObjectValue",
    "name": "filter"
  },
  {
    "kind": "Literal",
    "name": "orderBy",
    "value": "FLOW_BY_FLOW_ID__COMPLETED_AT_DESC"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completedAt",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HistoryCardPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ModulesConnection",
        "kind": "LinkedField",
        "name": "modules",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Module",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Flow",
                "kind": "LinkedField",
                "name": "flow",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "FlowLink_flow"
                  },
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "StatusChip_flow"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HistoryCardPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ModulesConnection",
        "kind": "LinkedField",
        "name": "modules",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Module",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Flow",
                "kind": "LinkedField",
                "name": "flow",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "linkedType",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "dueAt",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "assignmentStatus",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "assignmentDueAtStatus",
                    "storageKey": null
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f676b911befa8df038306fae4b671b58",
    "id": null,
    "metadata": {},
    "name": "HistoryCardPaginationQuery",
    "operationKind": "query",
    "text": "query HistoryCardPaginationQuery(\n  $userId: UUID!\n) {\n  modules(orderBy: FLOW_BY_FLOW_ID__COMPLETED_AT_DESC, filter: {flow: {assignmentStatus: {in: [ACCEPTED]}, assignedToId: {equalTo: $userId}}}) {\n    nodes {\n      flow {\n        rowId\n        completedAt\n        name\n        ...FlowLink_flow\n        ...StatusChip_flow\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment FlowLink_flow on Flow {\n  rowId\n  linkedType\n}\n\nfragment StatusChip_flow on Flow {\n  dueAt\n  assignmentStatus\n  assignmentDueAtStatus\n}\n"
  }
};
})();

(node as any).hash = "fab3a1ca244f8380eb99770e47eeb66c";

export default node;
