import {
  GraphQLTaggedNode,
  useLazyLoadQuery,
  useRefetchableFragment,
} from 'react-relay'
import {
  FetchPolicy,
  fetchQuery,
  OperationType,
  VariablesOf,
} from 'relay-runtime'
import { KeyType } from 'react-relay/relay-hooks/helpers'
import { useCallback, useEffect, useState } from 'react'
import useGwtRelayEnvironment from './useGwtRelayEnvironment'
import { useSubscribeToNotification } from '.'
import { SubscriptionVariables } from './useSubscriptions'
import { isEqual } from 'lodash'

export function useRefreshFragment<
  TQuery extends OperationType,
  TKey extends KeyType,
>(
  fragmentInput: GraphQLTaggedNode,
  fragmentRef: TKey | null,
  refreshQueryInput: GraphQLTaggedNode,
) {
  const [data, refetch] = useRefetchableFragment<TQuery, TKey>(
    fragmentInput,
    fragmentRef,
  )

  const environment = useGwtRelayEnvironment()

  const [isRefreshing, setIsRefreshing] = useState(false)

  const refresh = useCallback(() => {
    if (isRefreshing || !data || !data['id']) {
      return
    }
    setIsRefreshing(true)

    // fetchQuery will fetch the query and write
    // the data to the Relay store. This will ensure
    // that when we re-render, the data is already
    // cached and we don't suspend

    fetchQuery(environment, refreshQueryInput, { id: data?.['id'] }).subscribe({
      complete: () => {
        setIsRefreshing(false)
        // *After* the query has been fetched, we call
        // refetch again to re-render with the updated data.
        // At this point the data for the query should
        // be cached, so we use the 'store-only'
        // fetchPolicy to avoid suspending.
        refetch({}, { fetchPolicy: 'store-only' })
      },
      error: () => {
        setIsRefreshing(false)
      },
    })
  }, [data, environment, isRefreshing, refetch, refreshQueryInput])

  return { data, refresh, refetch }
}

export function useRefreshQuery<TQuery extends OperationType>(
  queryInput: GraphQLTaggedNode,
  variables: VariablesOf<TQuery>,
) {
  const [queryArgs, setQueryArgs] = useState({
    options: { fetchKey: 0, fetchPolicy: 'store-or-network' as FetchPolicy },
    variables,
  })
  const [isRefreshing, setIsRefreshing] = useState(false)

  const data = useLazyLoadQuery<TQuery>(
    queryInput,
    queryArgs?.variables ? queryArgs?.variables : variables,
    queryArgs?.options
      ? queryArgs?.options
      : { fetchKey: 0, fetchPolicy: 'store-or-network' },
  )

  const environment = useGwtRelayEnvironment()

  const refresh = useCallback(
    (newRefreshVars: VariablesOf<TQuery>) => {
      if (isRefreshing) {
        return
      }
      setIsRefreshing(true)

      // fetchQuery will fetch the query and write
      // the data to the Relay store. This will ensure
      // that when we re-render, the data is already
      // cached and we don't suspend

      fetchQuery(environment, queryInput, newRefreshVars).subscribe({
        complete: () => {
          setIsRefreshing(false)
          // *After* the query has been fetched, we call
          // refetch again to re-render with the updated data.
          // At this point the data for the query should
          // be cached, so we use the 'store-only'
          // fetchPolicy to avoid suspending.
          setQueryArgs((prev) => ({
            options: {
              fetchKey: (prev?.options?.fetchKey ?? 0) + 1,
              fetchPolicy: 'store-only',
            },
            variables: newRefreshVars,
          }))
        },
        error: () => {
          setIsRefreshing(false)
        },
      })
    },
    [environment, isRefreshing, queryInput],
  )

  return { data, refresh, isRefreshing }
}

export function useSubscriptionRefreshQueryWithIsRefreshing<
  TQuery extends OperationType,
>(
  queryInput: GraphQLTaggedNode,
  variables: VariablesOf<TQuery>,
  subscriptionInput: SubscriptionVariables,
) {
  const { data, refresh, isRefreshing } = useRefreshQuery(queryInput, variables)

  const [previousVariables, setPreviousVariables] = useState(variables)

  const { subscription, onRefresh } =
    useSubscribeToNotification(subscriptionInput)

  useEffect(() => {
    let variablesChanged = false
    if (variables && previousVariables) {
      variablesChanged = !isEqual(
        { ...variables, count: 0 },
        {
          ...previousVariables,
          count: 0,
        },
      )
    }

    if (subscription.current !== subscription.lastUpdated) {
      // refreshing because of a subscription notification
      console.debug('NOTIFICATION REFRESH')
      refresh(variables)
      setPreviousVariables(variables)
      onRefresh()
    } else if (variablesChanged) {
      // refreshing because variables have changed
      console.debug('VARIABLE REFRESH')
      refresh(variables)
      setPreviousVariables(variables)
      onRefresh()
    }
  }, [onRefresh, previousVariables, refresh, subscription, variables])

  return { data, isRefreshing }
}

export function useSubscriptionRefreshQuery<TQuery extends OperationType>(
  queryInput: GraphQLTaggedNode,
  variables: VariablesOf<TQuery>,
  subscriptionInput: SubscriptionVariables,
) {
  const { data, refresh } = useRefreshQuery(queryInput, variables)

  const [previousVariables, setPreviousVariables] = useState(variables)

  const { subscription, onRefresh } =
    useSubscribeToNotification(subscriptionInput)

  useEffect(() => {
    let variablesChanged = false
    if (variables && previousVariables) {
      variablesChanged = !isEqual(
        { ...variables, count: 0 },
        {
          ...previousVariables,
          count: 0,
        },
      )
    }

    if (subscription.current !== subscription.lastUpdated) {
      // refreshing because of a subscription notification
      console.debug('NOTIFICATION REFRESH')
      refresh(variables)
      setPreviousVariables(variables)
      onRefresh()
    } else if (variablesChanged) {
      // refreshing because variables have changed
      console.debug('VARIABLE REFRESH')
      refresh(variables)
      setPreviousVariables(variables)
      onRefresh()
    }
  }, [onRefresh, previousVariables, refresh, subscription, variables])

  return data
}

export function useSubscriptionRefreshFragment<
  TQuery extends OperationType,
  TKey extends KeyType,
>(
  fragmentInput: GraphQLTaggedNode,
  fragmentRef: TKey | null,
  refreshQueryInput: GraphQLTaggedNode,
  subscriptionInput: SubscriptionVariables,
) {
  const { data, refresh } = useRefreshFragment<TQuery, TKey>(
    fragmentInput,
    fragmentRef,
    refreshQueryInput,
  )

  const { subscription, onRefresh } =
    useSubscribeToNotification(subscriptionInput)

  useEffect(() => {
    if (subscription.current !== subscription.lastUpdated) {
      refresh()
      onRefresh()
    }
  }, [onRefresh, refresh, subscription])

  return data
}
