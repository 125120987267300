/**
 * @generated SignedSource<<2e1e666ad063fbdac0d99b67ff6ef7c4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type HistoryTab_module$data = {
  readonly flow: {
    readonly activities: {
      readonly nodes: ReadonlyArray<{
        readonly createdAt: Date | null;
        readonly gwtUser: {
          readonly firstName: string;
          readonly lastName: string;
        } | null;
        readonly linkLocation: string | null;
        readonly object: string;
        readonly rowId: UUID;
        readonly verb: string;
      }>;
    };
  } | null;
  readonly " $fragmentType": "HistoryTab_module";
};
export type HistoryTab_module$key = {
  readonly " $data"?: HistoryTab_module$data;
  readonly " $fragmentSpreads": FragmentRefs<"HistoryTab_module">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HistoryTab_module",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Flow",
      "kind": "LinkedField",
      "name": "flow",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "orderBy",
              "value": "CREATED_AT_DESC"
            }
          ],
          "concreteType": "ActivitiesConnection",
          "kind": "LinkedField",
          "name": "activities",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Activity",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "rowId",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "verb",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "linkLocation",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "object",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "createdAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GwtUser",
                  "kind": "LinkedField",
                  "name": "gwtUser",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "firstName",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "lastName",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "activities(orderBy:\"CREATED_AT_DESC\")"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Module",
  "abstractKey": null
};

(node as any).hash = "74bcc5279ab95535dcc6acd4ff0a6c09";

export default node;
