/**
 * @generated SignedSource<<3c43904a63c8f7babe6823badaf8a2b0>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type GlobalFeedbackUpdater__feedbackQuery$variables = {};
export type GlobalFeedbackUpdater__feedbackQuery$data = {
  readonly feedbacks: {
    readonly nodes: ReadonlyArray<{
      readonly rowId: UUID;
    }>;
  };
};
export type GlobalFeedbackUpdater__feedbackQuery = {
  response: GlobalFeedbackUpdater__feedbackQuery$data;
  variables: GlobalFeedbackUpdater__feedbackQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GlobalFeedbackUpdater__feedbackQuery",
    "selections": [
      {
        "kind": "RequiredField",
        "field": {
          "alias": null,
          "args": null,
          "concreteType": "FeedbacksConnection",
          "kind": "LinkedField",
          "name": "feedbacks",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Feedback",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        "action": "THROW",
        "path": "feedbacks"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GlobalFeedbackUpdater__feedbackQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "FeedbacksConnection",
        "kind": "LinkedField",
        "name": "feedbacks",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Feedback",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d602bc9b5f888d67d5ccd4346e33d6a0",
    "id": null,
    "metadata": {},
    "name": "GlobalFeedbackUpdater__feedbackQuery",
    "operationKind": "query",
    "text": "query GlobalFeedbackUpdater__feedbackQuery {\n  feedbacks {\n    nodes {\n      rowId\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "bd4b9553afbc76503511acaafc36c7ec";

export default node;
