import { AssignmentStatus } from '../hooks/__generated__/useNotificationsMutation.graphql'
import { AssignmentDueAtStatus } from '../views/Comments/__generated__/CommentAddMutation.graphql'

const millisecondsInAWeek = 604799290

export const getAssignmentStatusTextNoDate = (
  assignmentStatus:
    | AssignmentStatus
    | undefined
    | 'OVERDUE'
    | 'DUE_SOON'
    | 'UNASSIGNED',
) => {
  switch (assignmentStatus) {
    case 'ACCEPTED':
      return 'Approved'
    case 'REJECTED':
      return 'Rejected'
    case 'READY_FOR_REVIEW':
      return 'Ready for review'
    case 'IN_PROGRESS':
      return 'Assigned'
    case 'UNDER_REVIEW':
      return 'Under review'
    case 'OVERDUE':
      return 'Overdue'
    case 'DUE_SOON':
      return 'Due soon'
    case 'UNASSIGNED':
      return 'Unassigned'
    case 'CANCELED':
      return 'Canceled'
    default:
      return 'Unknown'
  }
}

export const getAssignmentStatusText = (
  assignmentStatus?: AssignmentStatus | null,
  assignmentDueAtStatus?: AssignmentDueAtStatus | null,
) => {
  if (assignmentStatus === 'ACCEPTED') {
    return 'Approved'
  } else if (assignmentStatus === 'REJECTED') {
    return 'Rejected'
  } else if (assignmentStatus === 'READY_FOR_REVIEW') {
    return 'Ready for review'
  } else if (assignmentStatus === 'IN_PROGRESS') {
    if (assignmentDueAtStatus === 'OVERDUE') {
      return 'Overdue'
    } else if (assignmentDueAtStatus === 'DUE_SOON') {
      return 'Due soon'
    } else {
      return 'Assigned'
    }
  } else if (assignmentStatus === 'UNDER_REVIEW') {
    return 'Under review'
  } else if (assignmentStatus === 'NOT_STARTED') {
    return 'Not started'
  } else {
    return 'Unknown'
  }
}

export const getColorCoatingCssClass = (
  assignmentStatus: AssignmentStatus | undefined | null,
  assignmentDueAtStatus: AssignmentDueAtStatus | undefined | null,
) => {
  //Add section for assigned to you assignments
  if (assignmentStatus === 'READY_FOR_REVIEW') {
    return 'status-text-submitted'
  }

  if (assignmentStatus === 'ACCEPTED') {
    return 'status-text-accepted'
  }

  if (assignmentStatus === 'REJECTED') {
    return 'status-text-rejected'
  }

  if (assignmentStatus === 'IN_PROGRESS') {
    if (assignmentDueAtStatus === 'OVERDUE') {
      return 'status-text-errored'
    }

    if (assignmentDueAtStatus === 'DUE_SOON') {
      return 'status-text-alert'
    }
  }

  // default return is normal status text
  return 'status-text-normal'
}

export const getFlowBlueprintDuration = (
  createdAt: Date | string | null | undefined,
  dueAt: Date | string | null | undefined,
) => {
  if (!dueAt || !createdAt) {
    return 'Unknown'
  }

  const originalModuleDuration = Math.round(
    (new Date(dueAt).valueOf() - new Date(createdAt).valueOf()) /
      millisecondsInAWeek,
  )

  return originalModuleDuration
}

export const getFlowDurationInWeeks = (
  flowDurationInSeconds: number | null | undefined,
) => {
  if (!flowDurationInSeconds) {
    return 'Unknown'
  }
  return Math.round(flowDurationInSeconds / (60 * 60 * 24 * 7))
}

export const getFlowDurationInSeconds = (flowDurationInWeeks: number) => {
  return flowDurationInWeeks * 7 * 24 * 60 * 60
}

export const getFlowDuration = (
  flowDurationInSeconds: number | null | undefined,
  createdAt: Date | string | null | undefined,
  dueAt: Date | string | null | undefined,
) => {
  let duration
  if (flowDurationInSeconds && flowDurationInSeconds !== 0) {
    duration = getFlowDurationInWeeks(flowDurationInSeconds)
  } else {
    duration = getFlowBlueprintDuration(createdAt, dueAt)
  }

  if (typeof duration === 'string') {
    return 0
  }

  return duration
}
