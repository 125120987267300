import { useAtom } from 'jotai'
import { useCallback } from 'react'
import { bannerAtom } from '../state/atoms'

export type BannerColor = 'INFO' | 'IMPORTANT'

export function useBanner() {
  const [bannerState, setBannerState] = useAtom(bannerAtom)

  const getBackgroundColor = (bannerColor: BannerColor): string => {
    switch (bannerColor) {
      case 'INFO':
        return '#E2BA0C'
      case 'IMPORTANT':
        return '#B85300'
      default:
        return '#E2BA0C'
    }
  }

  const getTextColor = (bannerColor: BannerColor): string => {
    switch (bannerColor) {
      case 'INFO':
        return 'black'
      case 'IMPORTANT':
        return 'white'
      default:
        return 'black'
    }
  }

  const setBanner = useCallback(
    (bannerText: string, color: BannerColor) => {
      setBannerState({
        notificationText: bannerText,
        bannerBackgroundColor: getBackgroundColor(color),
        bannerTextColor: getTextColor(color),
      })
    },
    [setBannerState],
  )

  const clearBanner = useCallback(() => {
    setBannerState(undefined)
  }, [setBannerState])

  return {
    setBanner,
    clearBanner,
    bannerState,
  }
}
