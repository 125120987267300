import { useEnv } from '@praxis/component-runtime-env'
import { GraphQLError } from 'graphql'
import {
  GraphQLResponse,
  Observable,
  RequestParameters,
  Variables,
} from 'relay-runtime'
import { createClient } from 'graphql-ws'
import { GwtEnv } from '../types'

const useSubscriptionClient = () => {
  const env = useEnv<GwtEnv>()

  const apiBaseWsUrl = env.baseGwtApiWsUrl

  // cookies are required for websocket authentication. id2Token is the default for go-proxy
  // document.cookie = 'id2Token=' + accessToken + '; path=/'
  // cookie should be set from the API
  return createClient({
    url: apiBaseWsUrl,
  })
}

export const useSubscribe = () => {
  const subscriptionsClient = useSubscriptionClient()

  return (operation: RequestParameters, variables: Variables) => {
    return Observable.create<GraphQLResponse>((sink) => {
      if (!operation.text) {
        return sink.error(new Error('Operation text cannot be empty'))
      }
      return subscriptionsClient.subscribe(
        {
          operationName: operation.name,
          query: operation.text,
          variables,
        },
        {
          ...sink,
          error: (err) => {
            if (err instanceof Error) {
              return sink.error(err)
            }

            if (err instanceof CloseEvent) {
              return sink.error(
                // reason will be available on clean closes
                new Error(
                  `Socket closed with event ${err.code} ${err.reason || ''}`,
                ),
              )
            }

            return sink.error(
              new Error(
                (err as GraphQLError[])
                  .map(({ message }) => message)
                  .join(', '),
              ),
            )
          },
        },
      )
    })
  }
}
