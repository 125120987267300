import { Input } from '@enterprise-ui/canvas-ui-react'
import { graphql, useFragment } from 'react-relay'
import { useGwtMutation } from '../../hooks'
import { TaskCompleteCheckboxMutation } from './__generated__/TaskCompleteCheckboxMutation.graphql'
import { TaskCompleteCheckbox_task$key } from './__generated__/TaskCompleteCheckbox_task.graphql'

interface TaskCompleteCheckboxProps {
  taskRef: TaskCompleteCheckbox_task$key | null | undefined
}

export const TaskCompleteCheckbox = ({
  taskRef,
}: TaskCompleteCheckboxProps) => {
  const data = useFragment(
    graphql`
      fragment TaskCompleteCheckbox_task on Task {
        flowId
        complete
        priority
        flow {
          name
        }
      }
    `,
    taskRef || null,
  )

  const { commit } = useGwtMutation<TaskCompleteCheckboxMutation>(graphql`
    mutation TaskCompleteCheckboxMutation($flowId: UUID!, $complete: Boolean!) {
      updateTask(input: { patch: { complete: $complete }, flowId: $flowId }) {
        task {
          complete
        }
      }
    }
  `)

  if (!data) {
    return <></>
  }

  return (
    <Input.Checkbox
      id={data.flowId}
      label={data.flow?.name}
      checked={data.complete}
      onChange={() => {
        commit({ flowId: data.flowId, complete: !data.complete })
      }}
    />
  )
}
