import { useAssentFileUpload } from './useAssentFileUpload'

export const useFileUpload = () => {
  const assentUpload = useAssentFileUpload()

  let uploadFileToAsset = assentUpload.uploadFileToAsset

  // if (user.userType === 'EXTERNAL_USER') {
  //   uploadFileToAsset = assentUpload.uploadFileToAsset
  // } else {
  //   uploadFileToAsset = galleryUpload.uploadFileToAsset
  // }

  return { uploadFileToAsset }
}
