import React, { useEffect } from 'react'
import {
  Button,
  Form,
  Grid,
  Modal,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import { graphql, useFragment } from 'react-relay'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import {
  AssignGroupTemplateMutation,
  AssignGroupTemplateMutation$variables,
} from './__generated__/AssignGroupTemplateMutation.graphql'
import { useAtom } from 'jotai'
import './AssignGroupTemplate.scss'
import { AssignGroupTemplate$key } from './__generated__/AssignGroupTemplate.graphql'
import { getFlowDurationInWeeks } from '../../../util/flowUtils'
import { ControlledUserSelect } from '../../../controlledComponents/ControlledUserSelect'
import { useGwtMutation } from '../../../hooks'
import { globalNotificationAtom } from '../../../state/atoms'
import { getControlledUserSelectDefaultUnnassigned } from '../../../util/userUtils'

interface AssignGroupTemplateProps {
  moduleGroupRef?: AssignGroupTemplate$key | null | undefined
  isModalOpen: boolean
  closeModal: () => void
}

export const AssignGroupTemplate = ({
  moduleGroupRef,
  isModalOpen,
  closeModal,
}: AssignGroupTemplateProps) => {
  type Inputs = {
    add: string
  }

  const groupData = useFragment(
    graphql`
      fragment AssignGroupTemplate on ModuleGroup {
        moduleGroupLinksByModuleGroupFlowId {
          nodes {
            moduleFlow {
              flow {
                name
                durationSeconds
                flowReviewers {
                  nodes {
                    reviewer {
                      fullName
                    }
                  }
                }
              }
            }
          }
        }
        flow {
          name
          rowId
        }
      }
    `,
    moduleGroupRef || null,
  )

  const { commit, isInFlight } = useGwtMutation<AssignGroupTemplateMutation>(
    graphql`
      mutation AssignGroupTemplateMutation(
        $moduleGroupFlowId: UUID!
        $assignTo: UUID!
      ) {
        assignModuleGroup(
          input: {
            moduleGroupToAssignFlowId: $moduleGroupFlowId
            assignedTo: $assignTo
          }
        ) {
          clientMutationId
        }
      }
    `,
  )

  const formMethods = useForm()

  const { register, handleSubmit, watch, reset, setValue, setError } =
    formMethods

  const [, publishNotification] = useAtom(globalNotificationAtom)
  const makeToast = ToastProvider.useToaster()

  const assignTo = watch('assignTo', '')

  const validate = (): boolean => {
    let validated = true
    if (!assignTo) {
      setError('assignTo', { message: 'Please select an assignee' })
      validated = false
    }

    return validated
  }

  const onSubmit: SubmitHandler<Inputs> = () => {
    if (!validate()) {
      return
    }
    // validate and set errors

    const mutation: AssignGroupTemplateMutation$variables =
      {} as AssignGroupTemplateMutation$variables
    mutation.moduleGroupFlowId = groupData?.flow?.rowId
    mutation.assignTo = assignTo

    commit(
      mutation,
      () => {
        publishNotification({
          name: 'ASSIGN_TEMPLATE_NOTIFICATION',
          id: 'local',
          gwt_user_id: 'local',
          data: null,
        })
        closeModal()
        reset()
        makeToast({
          type: 'success',
          heading: 'Template assigned',
          message: 'Template was successfully assigned.',
        })
      },
      () => {
        makeToast({
          type: 'error',
          heading: 'Template assignment failed',
          message: 'Template was unsuccessfully assigned.',
        })
      },
    )
  }

  const clearModal = () => {
    closeModal()
    reset()
  }

  useEffect(() => {
    if (groupData?.moduleGroupLinksByModuleGroupFlowId?.nodes) {
      let reviewerList: string[] = []
      let totalDurationSeconds = 0

      groupData.moduleGroupLinksByModuleGroupFlowId.nodes.forEach((link) => {
        totalDurationSeconds += link.moduleFlow?.flow?.durationSeconds || 0

        if (!link.moduleFlow?.flow?.flowReviewers) {
          reviewerList.push('Unassigned')
        }

        link.moduleFlow?.flow?.flowReviewers?.nodes?.forEach((reviewerNode) => {
          if (reviewerNode.reviewer?.fullName) {
            reviewerList.push(reviewerNode.reviewer?.fullName)
          }
        })
      })

      setValue(
        'module_duration',
        `${getFlowDurationInWeeks(totalDurationSeconds)} weeks`,
      )
      setValue('reviewBy', [...new Set(reviewerList)].join(', '))
    }
  }, [groupData?.moduleGroupLinksByModuleGroupFlowId?.nodes, setValue])

  return (
    <>
      {isModalOpen && (
        <Modal
          headingText={`Assign ${groupData?.flow?.name}`}
          isVisible={isModalOpen}
          onRefuse={clearModal}
          className="align-left assign-modal"
        >
          <FormProvider {...formMethods}>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <div>
                <Grid.Container className="hc-ph-lg hc-pb-md hc-pt-dense">
                  <Grid.Item xs={12} className="hc-mb-md">
                    <ControlledUserSelect
                      label="Assign to"
                      required
                      id="assign_to_select"
                      defaultValue={getControlledUserSelectDefaultUnnassigned()}
                      {...register('assignTo')}
                    />
                  </Grid.Item>
                  <Grid.Item xs={12}>
                    <Form.Field
                      disabled
                      label="Reviewed by"
                      id="review_by_select"
                      {...register('reviewBy')}
                      userTypes={{ in: ['ADMIN', 'REVIEWER'] }}
                    />
                  </Grid.Item>
                  <Grid.Item xs={12} className="hc-pr-md">
                    <Form.Field
                      label="Duration"
                      disabled
                      id="module_duration"
                      type="text"
                      {...register('module_duration')}
                    />
                  </Grid.Item>
                </Grid.Container>
              </div>
              <div className="hc-pt-none hc-mr-lg hc-pb-lg">
                <Grid.Container spacing="dense" direction="row-reverse">
                  <Grid.Item>
                    <Button
                      disabled={isInFlight}
                      type="submit"
                      id="assign-confirm"
                    >
                      Confirm
                    </Button>
                  </Grid.Item>
                  <Grid.Item>
                    <Button type="secondary" onClick={clearModal}>
                      Cancel
                    </Button>
                  </Grid.Item>
                </Grid.Container>
              </div>
            </Form>
          </FormProvider>
        </Modal>
      )}
    </>
  )
}
