import {
  ButtonProps,
  Spinner,
  Tooltip,
  Button,
} from '@enterprise-ui/canvas-ui-react'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import usePolUpload from '../../../hooks/usePolUpload'
import { UploadFile } from '../../../state/atoms'
import React from 'react'
import { useGwtUser } from '../../../hooks'

interface PolCancelButtonProps extends Omit<ButtonProps, 'onClick'> {
  file: UploadFile | undefined
  tooltipLocation?:
    | 'left'
    | 'none'
    | 'top'
    | 'bottom'
    | 'right'
    | 'top-left'
    | 'top-right'
    | 'bottom-left'
    | 'bottom-right'
    | undefined
}

export const PolCancelButton = ({
  file,
  tooltipLocation = 'left',
  ...props
}: PolCancelButtonProps) => {
  const { cancelMutation, commitCancel } = usePolUpload()
  const user = useGwtUser()

  return (
    <Tooltip
      location={tooltipLocation}
      content="Cancel this upload (Note: It may be in the middle of uploading so the uploaded file may still show up.)"
    >
      <Button
        disabled={
          cancelMutation.isInFlight || file?.uploadStatus === 'SCANNING'
        }
        onClick={() => {
          commitCancel(
            { polFileUploadId: file?.rowId },
            {
              galleryAssetId: file?.galleryAssetId,
              gwtUserId: user.rowId,
            },
          )
        }}
        {...props}
      >
        {cancelMutation.isInFlight ? (
          <Spinner size="dense" />
        ) : (
          <FontAwesomeIcon icon={faTrash} />
        )}
      </Button>
    </Tooltip>
  )
}
