import React, { useCallback, useState } from 'react'
import {
  PieChart,
  Pie,
  Cell,
  Sector,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'

interface TemplatesPieChartProps {
  data: { name: string; value: any }[]
}

export const TemplatesPieChart = ({ data }: TemplatesPieChartProps) => {
  const [activeIndex, setActiveIndex] = useState(-1)
  const onPieEnter = useCallback(
    (_: any, index: number) => {
      setActiveIndex(index)
    },
    [setActiveIndex],
  )

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <div className="custom-tooltip">
            <span>{`Template: ${payload[0].name}`}</span>
            <br />
            <span>{`Count: ${payload[0].value}`}</span>
          </div>
        </div>
      )
    }

    return null
  }

  return (
    <div style={{ width: '100%', height: 200, textAlign: 'center' }}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            activeIndex={activeIndex}
            activeShape={renderActiveShape}
            data={data}
            cx={'50%'}
            cy={'50%'}
            fill="#8884d8"
            dataKey="value"
            onMouseEnter={onPieEnter}
            onMouseLeave={() => {
              setActiveIndex(-1)
            }}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
                stroke=""
              />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
        </PieChart>
      </ResponsiveContainer>
    </div>
  )
}
const COLORS = [
  '#FFBB28',
  '#FF8042',
  '#ff424f',
  '#d642ff',
  '#0088FE',
  '#00C49F',
  '#42ff94',
]
const renderActiveShape = (props: any) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } = props

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  )
}
