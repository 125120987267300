/**
 * @generated SignedSource<<e1e84a8af3216d5cd8445349ae33b294>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type PolUploadInput = {
  clientMutationId?: string | null;
  polFileUploadId: UUID;
};
export type useUploadCheck_CancelMutation$variables = {
  input: PolUploadInput;
};
export type useUploadCheck_CancelMutation$data = {
  readonly cancelPolUpload: {
    readonly clientMutationId: string | null;
  } | null;
};
export type useUploadCheck_CancelMutation = {
  response: useUploadCheck_CancelMutation$data;
  variables: useUploadCheck_CancelMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "PolUploadPayload",
    "kind": "LinkedField",
    "name": "cancelPolUpload",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUploadCheck_CancelMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUploadCheck_CancelMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b454f881e4b26b9cb3118e68bb970695",
    "id": null,
    "metadata": {},
    "name": "useUploadCheck_CancelMutation",
    "operationKind": "mutation",
    "text": "mutation useUploadCheck_CancelMutation(\n  $input: PolUploadInput!\n) {\n  cancelPolUpload(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();

(node as any).hash = "cc1e52c1e9cfed0a0d9fd638fa2dad2d";

export default node;
