import {
  Button,
  ButtonProps,
  Spinner,
  ToastProvider,
} from '@enterprise-ui/canvas-ui-react'
import React from 'react'
import { graphql, useFragment } from 'react-relay'
import { UpdateFlowInput } from './__generated__/ReviewerButtonMutation.graphql'

import { useGwtMutation } from '../../../hooks'
import {
  getActivityInputObject,
  getNotificationInputObject,
} from '../../../util/relayUtils'
import { getAssignmentStatusText } from '../../../util/flowUtils'
import { activityTypes } from '../../../constants'
import {
  FlowPatch,
  StartReviewingButtonMutation,
  StartReviewingButtonMutation$variables,
} from './__generated__/StartReviewingButtonMutation.graphql'
import { StartReviewingButton_Fragment$key } from './__generated__/StartReviewingButton_Fragment.graphql'

interface StartReviewingButtonProps extends ButtonProps {
  moduleRef: StartReviewingButton_Fragment$key | null | undefined
}

export const StartReviewingButton = ({
  moduleRef = null,
  ...props
}: StartReviewingButtonProps) => {
  const makeToast = ToastProvider.useToaster()
  const data = useFragment(
    graphql`
      fragment StartReviewingButton_Fragment on Module {
        flowId
        currentAttempt
        flow {
          name
          assignmentStatus
          assignmentDueAtStatus
          assignedToId
        }
        moduleSubmissionsByModuleFlowId(orderBy: ATTEMPT_DESC) {
          nodes {
            id
            submissionStatus
            attempt
          }
        }
      }
    `,
    moduleRef,
  )

  const { commit, isInFlight } = useGwtMutation<StartReviewingButtonMutation>(
    graphql`
      mutation StartReviewingButtonMutation(
        $input: UpdateFlowInput!
        $activity: ActivityInput!
        $notificationInput: NotificationInput!
      ) {
        createNotification(input: { notification: $notificationInput }) {
          clientMutationId
        }
        createActivity(input: { activity: $activity }) {
          clientMutationId
        }
        updateFlow(input: $input) {
          flow {
            id
            assignmentStatus
            module {
              ...HistoryTab_module
            }
          }
        }
      }
    `,
  )

  const handleComplete = () => {
    makeToast({
      type: 'success',
      heading: 'Module under review',
      message: 'Successfully set module to under review.',
    })
  }

  const handleError = () => {
    makeToast({
      type: 'error',
      heading: 'Error',
      message:
        'There was an error updating module to be under review.  Please try again.',
    })
  }

  const submit = () => {
    if (!data || !data.flow || !readyForReview) return
    const mutation = {} as StartReviewingButtonMutation$variables

    mutation.input = {} as UpdateFlowInput
    mutation.input.rowId = data.flowId

    mutation.input.patch = {} as FlowPatch
    mutation.input.patch.assignmentStatus = 'UNDER_REVIEW'

    mutation.activity = getActivityInputObject(
      activityTypes.setAssignmentStatus('UNDER REVIEW'),
      data.flow.name,
      data.flowId,
      `/reviewer/modules/${data.flowId}`,
    )

    mutation.notificationInput = getNotificationInputObject(
      'REVIEWER_MODULE_BUTTON_NOTIFICATION',
    )

    commit(mutation, handleComplete, handleError)
  }

  if (isInFlight) {
    return <Spinner className="hc-ml-md hc-mr-md" />
  }

  const readyForReview = data?.flow?.assignmentStatus === 'READY_FOR_REVIEW'

  return (
    <Button
      {...props}
      type="primary"
      disabled={!data?.flowId || isInFlight || !readyForReview}
      onClick={submit}
    >
      {readyForReview
        ? 'Start reviewing'
        : getAssignmentStatusText(
            data?.flow?.assignmentStatus,
            data?.flow?.assignmentDueAtStatus,
          )}
    </Button>
  )
}
