import {
  Grid,
  Card,
  Divider,
  Button,
  Tooltip,
} from '@enterprise-ui/canvas-ui-react'
import { SyntheticEvent, useEffect, useState } from 'react'
import './Reviewer.scss'
import { UserAvatar } from '../Users/UserAvatar'
import { graphql, usePaginationFragment } from 'react-relay'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFilter, faSortAmountDown } from '@fortawesome/free-solid-svg-icons'
import { DropdownSelect } from '../../GenericComponents/DropdownSelect/DropdownSelect'
import { getGwtFormattedDate } from '../../util/dateUtils'
import { useSubscriptionRefreshQuery } from '../../hooks/useRefresh'
import { usePaginateDataLength } from '../../hooks/usePaginateDataLength'
import {
  FlowFilter,
  FlowsOrderBy,
  ReviewerStatusCardPaginationQuery,
} from './__generated__/ReviewerStatusCardPaginationQuery.graphql'
import { ReviewerStatusCardPaginationFragment_flows$key } from './__generated__/ReviewerStatusCardPaginationFragment_flows.graphql'
import { TextInputDebounce } from '../../GenericComponents/TextInputDebounce/TextInputDebounce'
import { ReassignReviewerPopup } from '../Admin/ReassignReviewerPopup'
import { useGwtUser } from '../../hooks'
import { StatusChip } from '../Flows/StatusChip'
import {
  getAssignmentStatusText,
  getColorCoatingCssClass,
} from '../../util/flowUtils'
import { getUniqueId } from '../../util/idUtil'
import { UserPriority } from '../Users/UserPriority/UserPriority'
import { FlowLink } from '../Flows/FlowLink'

export const ReviewerStatusCard = () => {
  const user = useGwtUser()
  const userId = user.rowId

  const [filter, setFilter] = useState<FlowFilter>({
    flowType: { notEqualTo: 'BLUEPRINT' },
    workflowType: { equalTo: 'ACCREDITATION' },
    assignedToExists: true,
    flowReviewers: { some: { reviewerId: { equalTo: userId } } },
    or: [
      { name: { likeInsensitive: null } },
      { assignedTo: { fullName: { likeInsensitive: null } } },
      {
        flowReviewers: {
          some: { reviewer: { fullName: { likeInsensitive: null } } },
        },
      },
    ],
    not: {
      and: [
        { assignmentStatus: { equalTo: 'REJECTED' } },
        { module: { isFinalAttempt: { equalTo: true } } },
      ],
    },
    assignmentStatus: { notEqualTo: 'ACCEPTED' },
  })

  const [defaultFilter, setDefaultFilter] = useState<FlowFilter>({
    flowType: { notEqualTo: 'BLUEPRINT' },
    workflowType: { equalTo: 'ACCREDITATION' },
    assignedToExists: true,
    or: [
      { name: { likeInsensitive: null } },
      { assignedTo: { fullName: { likeInsensitive: null } } },
      {
        flowReviewers: {
          some: { reviewer: { fullName: { likeInsensitive: null } } },
        },
      },
    ],
    not: {
      and: [
        { assignmentStatus: { equalTo: 'REJECTED' } },
        { module: { isFinalAttempt: { equalTo: true } } },
      ],
    },
    assignmentStatus: { notEqualTo: 'ACCEPTED' },
  })

  const [orderBy, setOrderBy] = useState<FlowsOrderBy[]>([
    'GWT_USER_BY_ASSIGNED_TO_ID__USER_PRIORITY_DESC',
    'ASSIGNMENT_DUE_AT_STATUS_ASC',
    'ASSIGNMENT_STATUS_DESC',
  ])

  const { data, isLoadingNext, hasNext, loadNext } = useReviewerStatusCardQuery(
    filter,
    orderBy,
  )

  const scrollEvent = (e: SyntheticEvent) => {
    if (isLoadingNext || !hasNext) {
      return
    }
    const target = e.target as HTMLTextAreaElement
    const scrollLeft = target.scrollHeight - target.scrollTop
    if (scrollLeft / target.clientHeight < 2) {
      loadNext(30)
    }
  }

  return (
    <Card className="cardLayout status-card">
      <div className="cardHeader hc-pa-lg flex">
        <Grid.Container
          align="center"
          className="header-container flex-grow"
          spacing="none"
        >
          <Grid.Item>
            <div className="card-header-text hc-pr-none">Active</div>
          </Grid.Item>
          <Grid.Item className="align-left hc-pl-dense">
            <TextInputDebounce
              delay={500}
              placeholder="Search"
              onUpdate={(debounced) => {
                if (debounced.length > 0) {
                  const update = `%${debounced}%`

                  setDefaultFilter({
                    ...defaultFilter,
                    or: [
                      { name: { likeInsensitive: update } },
                      { assignedTo: { fullName: { likeInsensitive: update } } },
                      {
                        flowReviewers: {
                          some: {
                            reviewer: { fullName: { likeInsensitive: update } },
                          },
                        },
                      },
                    ],
                  })
                  setFilter({
                    ...filter,
                    or: [
                      { name: { likeInsensitive: update } },
                      { assignedTo: { fullName: { likeInsensitive: update } } },
                      {
                        flowReviewers: {
                          some: {
                            reviewer: { fullName: { likeInsensitive: update } },
                          },
                        },
                      },
                    ],
                  })
                } else {
                  setDefaultFilter({
                    ...defaultFilter,
                    or: [
                      { name: { likeInsensitive: null } },
                      { assignedTo: { fullName: { likeInsensitive: null } } },
                      {
                        flowReviewers: {
                          some: {
                            reviewer: { fullName: { likeInsensitive: null } },
                          },
                        },
                      },
                    ],
                  })
                  setFilter({
                    ...filter,
                    or: [
                      { name: { likeInsensitive: null } },
                      { assignedTo: { fullName: { likeInsensitive: null } } },
                      {
                        flowReviewers: {
                          some: {
                            reviewer: { fullName: { likeInsensitive: null } },
                          },
                        },
                      },
                    ],
                  })
                }
              }}
            />
          </Grid.Item>
          <Grid.Item className="flex-row flex-grow justify-end">
            <DropdownSelect
              defaultValue="Assigned to you"
              options={[
                {
                  label: 'All',
                  onSelect: () => {
                    setFilter({
                      ...defaultFilter,
                    })
                  },
                },
                {
                  label: 'Assigned to you',
                  onSelect: () => {
                    setFilter({
                      ...defaultFilter,
                      flowReviewers: {
                        some: { reviewerId: { equalTo: userId } },
                      },
                    })
                  },
                },
                {
                  label: getAssignmentStatusText('READY_FOR_REVIEW'),
                  onSelect: () => {
                    setFilter({
                      ...defaultFilter,
                      assignmentStatus: { equalTo: 'READY_FOR_REVIEW' },
                    })
                  },
                },
                {
                  label: getAssignmentStatusText('IN_PROGRESS'),
                  onSelect: () => {
                    setFilter({
                      ...defaultFilter,
                      assignmentStatus: { equalTo: 'IN_PROGRESS' },
                    })
                  },
                },
                {
                  label: getAssignmentStatusText('IN_PROGRESS', 'OVERDUE'),
                  onSelect: () => {
                    setFilter({
                      ...defaultFilter,
                      assignmentStatus: { equalTo: 'IN_PROGRESS' },
                      assignmentDueAtStatus: { equalTo: 'OVERDUE' },
                    })
                  },
                },
                {
                  label: getAssignmentStatusText('IN_PROGRESS', 'DUE_SOON'),
                  onSelect: () => {
                    setFilter({
                      ...defaultFilter,
                      assignmentStatus: { equalTo: 'IN_PROGRESS' },
                      assignmentDueAtStatus: { equalTo: 'DUE_SOON' },
                    })
                  },
                },
                {
                  label: 'Unassigned',
                  onSelect: () => {
                    setFilter({
                      ...defaultFilter,
                      flowReviewersExist: false,
                    })
                  },
                },
              ]}
            >
              <Tooltip content="Filter" location="left">
                <Button
                  type="secondary"
                  size="dense"
                  iconOnly
                  aria-label="filter-status"
                  className="hc-ml-dense"
                >
                  <FontAwesomeIcon icon={faFilter} />
                </Button>
              </Tooltip>
            </DropdownSelect>
            <DropdownSelect
              defaultValue="Priority (High-Low)"
              options={[
                {
                  label: 'Priority (High-Low)',
                  onSelect: () => {
                    setOrderBy([
                      'GWT_USER_BY_ASSIGNED_TO_ID__USER_PRIORITY_DESC',
                      'ASSIGNMENT_DUE_AT_STATUS_ASC',
                      'ASSIGNMENT_STATUS_DESC',
                    ])
                  },
                },
                {
                  label: 'Due date',
                  onSelect: () => {
                    setOrderBy([
                      'DUE_AT_ASC',
                      'ASSIGNMENT_DUE_AT_STATUS_ASC',
                      'ASSIGNMENT_STATUS_DESC',
                    ])
                  },
                },
                {
                  label: 'Username (A-Z)',
                  onSelect: () => {
                    setOrderBy([
                      'GWT_USER_BY_ASSIGNED_TO_ID__FIRST_NAME_ASC',
                      'ASSIGNMENT_DUE_AT_STATUS_ASC',
                      'ASSIGNMENT_STATUS_DESC',
                    ])
                  },
                },
                {
                  label: 'Template name (A-Z)',
                  onSelect: () => {
                    setOrderBy([
                      'NAME_ASC',
                      'ASSIGNMENT_DUE_AT_STATUS_ASC',
                      'ASSIGNMENT_STATUS_DESC',
                    ])
                  },
                },
                {
                  label: 'Status',
                  onSelect: () => {
                    setOrderBy([
                      'ASSIGNMENT_DUE_AT_STATUS_ASC',
                      'ASSIGNMENT_DUE_AT_STATUS_ASC',
                      'ASSIGNMENT_STATUS_DESC',
                    ])
                  },
                },
                {
                  label: 'Priority',
                  onSelect: () => {
                    setOrderBy([
                      'GWT_USER_BY_ASSIGNED_TO_ID__USER_PRIORITY_ASC',
                      'DUE_AT_ASC',
                    ])
                  },
                },
              ]}
            >
              <Tooltip content="Sort" location="left">
                <Button
                  type="secondary"
                  size="dense"
                  iconOnly
                  aria-label="sort-status"
                  className="hc-ml-dense"
                >
                  <FontAwesomeIcon icon={faSortAmountDown} />
                </Button>
              </Tooltip>
            </DropdownSelect>
          </Grid.Item>
        </Grid.Container>
      </div>
      <Divider />
      <div onScroll={scrollEvent} className="statuses">
        {data &&
          data.flows?.edges.map((page) => {
            const flow = page.node

            return (
              <FlowLink flowRef={flow} id={flow.rowId} key={flow.rowId}>
                <Grid.Container
                  spacing="none"
                  align="center"
                  className="card-hover"
                >
                  <Grid.Item
                    xs={5}
                    className="hc-pl-lg hc-pt-lg hc-pb-lg hc-pr-md"
                  >
                    <UserAvatar
                      userRef={flow.assignedTo}
                      showAvatar={false}
                      spanClassName={`${getColorCoatingCssClass(
                        flow.assignmentStatus,
                        flow.assignmentDueAtStatus,
                      )} bold-text`}
                      className="comment-avatar"
                      aria-label={''}
                      children={undefined}
                    />
                    <div className="template-name-text">{flow.name}</div>
                    <div className="template-name-text">
                      Priority: <UserPriority userRef={flow.assignedTo} />
                    </div>
                  </Grid.Item>
                  <Grid.Item
                    xs={4}
                    className="hc-pt-lg hc-pb-lg hc-pr-sm"
                    padding="dense"
                  >
                    <div>
                      <span className="narrow-text"> Due </span>
                      <span className="date-text">
                        {' '}
                        {getGwtFormattedDate(flow.dueAt)}{' '}
                      </span>
                    </div>
                    <div>
                      <div>
                        {flow.flowReviewers.nodes.map((node) => {
                          return (
                            <div key={getUniqueId()}>
                              <Tooltip
                                content="Reassign template"
                                location="bottom"
                              >
                                <ReassignReviewerPopup
                                  moduleFlowId={flow.rowId}
                                  spanClassName="hc-ma-none hc-pa-none reviewer-name-text reassign-reviewer-btn"
                                  ReviewerUserRef={node.reviewer}
                                  AssignToUserRef={flow.assignedTo}
                                />
                              </Tooltip>
                            </div>
                          )
                        })}
                        {flow.flowReviewers.nodes.length === 0 && (
                          <div>
                            <Tooltip
                              content="Reassign template"
                              location="bottom"
                            >
                              <ReassignReviewerPopup
                                isUnassigned={true}
                                moduleFlowId={flow.rowId}
                                spanClassName="hc-ma-none hc-pa-none reviewer-name-text reassign-reviewer-btn reviewer-name-text-unassigned"
                                ReviewerUserRef={null}
                                AssignToUserRef={flow.assignedTo}
                              />
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    </div>
                  </Grid.Item>
                  <Grid.Item xs={3} align="right" className="hc-pr-lg">
                    <StatusChip flowRef={flow} className="pointer" />
                  </Grid.Item>
                </Grid.Container>
                <Divider />
              </FlowLink>
            )
          })}
      </div>
    </Card>
  )
}

const useReviewerStatusCardQuery = (
  flowFilter: FlowFilter | undefined = undefined,
  flowsOrderBy: FlowsOrderBy[] = [
    'DUE_AT_ASC',
    'ASSIGNMENT_DUE_AT_STATUS_ASC',
    'ASSIGNMENT_STATUS_DESC',
  ],
) => {
  const [dataLength, setDataLength] = useState(20)

  const pageData =
    useSubscriptionRefreshQuery<ReviewerStatusCardPaginationQuery>(
      graphql`
        query ReviewerStatusCardPaginationQuery(
          $flowFilter: FlowFilter
          $flowsOrderBy: [FlowsOrderBy!]
          $count: Int!
        ) {
          ...ReviewerStatusCardPaginationFragment_flows
            @arguments(
              flowFilter: $flowFilter
              flowsOrderBy: $flowsOrderBy
              count: $count
            )
        }
      `,
      { flowFilter, flowsOrderBy, count: dataLength || 20 },
      {
        component: 'REVIEWER_STATUS_CARD',
        uniqueComponentId: '',
        onNotification: [
          'ASSIGN_TEMPLATE_NOTIFICATION',
          'REVIEWER_MODULE_BUTTON_NOTIFICATION',
          'ASSIGNEE_MODULE_SUBMIT_NOTIFICATION',
          'REVIEWER_MODULE_START_NEXT_ATTEMPT_NOTIFICATION',
          'ARCHIVE_ASSIGNMENT_NOTIFICATION',
          'UNARCHIVE_ASSIGNMENT_NOTIFICATION',
        ],
      },
    )

  const paginationFragmentHook = usePaginationFragment<
    ReviewerStatusCardPaginationQuery,
    ReviewerStatusCardPaginationFragment_flows$key
  >(
    graphql`
      fragment ReviewerStatusCardPaginationFragment_flows on Query
      @argumentDefinitions(
        flowsOrderBy: { type: "[FlowsOrderBy!]" }
        flowFilter: { type: "FlowFilter" }
        count: { type: "Int" }
        cursor: { type: "Cursor" }
      )
      @refetchable(queryName: "ReviewerStatusCardPaginationQuery_Refetchable") {
        flows(
          filter: $flowFilter
          orderBy: $flowsOrderBy
          first: $count
          after: $cursor
        ) @connection(key: "ReviewerStatusCard_flows") {
          edges {
            node {
              name
              rowId
              ...FlowLink_flow
              assignedTo {
                ...UserPriority_gwtUser
                ...UserAvatar_gwtUser
                ...ReassignReviewerPopupFragment_AssignToAvatar
              }
              flowReviewers {
                nodes {
                  reviewer {
                    ...UserAvatar_gwtUser
                    ...ReassignReviewerPopupFragment_ReviewerAvatar
                  }
                }
              }
              ...StatusChip_flow
              dueAt
              assignmentStatus
              assignmentDueAtStatus
            }
          }
          totalCount
        }
      }
    `,
    pageData,
  )

  const paginateLength = usePaginateDataLength(
    paginationFragmentHook?.data?.flows?.edges,
    20,
  )

  useEffect(() => {
    if (paginateLength) {
      setDataLength(paginateLength)
    }
  }, [paginateLength])

  return { ...paginationFragmentHook, pageData }
}
