/**
 * @generated SignedSource<<ec1abda43d41416faaffa5297ddd13da>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type DeliverableFileUploadTable_galleryAsset$data = {
  readonly flowId: UUID;
  readonly galleryAssetDeliverableId: UUID | null;
  readonly " $fragmentType": "DeliverableFileUploadTable_galleryAsset";
};
export type DeliverableFileUploadTable_galleryAsset$key = {
  readonly " $data"?: DeliverableFileUploadTable_galleryAsset$data;
  readonly " $fragmentSpreads": FragmentRefs<"DeliverableFileUploadTable_galleryAsset">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DeliverableFileUploadTable_galleryAsset",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "flowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "galleryAssetDeliverableId",
      "storageKey": null
    }
  ],
  "type": "Module",
  "abstractKey": null
};

(node as any).hash = "bb6324c152f37dd679cad67e32511465";

export default node;
