import { graphql } from 'react-relay'

import useGwtMutation from './useGwtMutation'
import { usePolUpload_cancelMutation } from './__generated__/usePolUpload_cancelMutation.graphql'
import { usePolUpload_retryMutation } from './__generated__/usePolUpload_retryMutation.graphql'
import { useSetAtom } from 'jotai'
import { uploadsAtom } from '../state/atoms'
import { useCallback } from 'react'
import { PolUploadInput } from './__generated__/useUploadCheck_RetryMutation.graphql'
import { PolFileUploadCondition } from './__generated__/useUploadCheck_Query.graphql'

export default function usePolUpload() {
  const setUploadsData = useSetAtom(uploadsAtom)

  const retryMutation = useGwtMutation<usePolUpload_retryMutation>(graphql`
    mutation usePolUpload_retryMutation(
      $input: PolUploadInput!
      $condition: PolFileUploadCondition
    ) {
      retryPolUpload(input: $input) {
        query {
          polFileUploads(condition: $condition) {
            nodes {
              galleryAssetId
              fileName
              tossUrl
              uploadError
              uploadStatus
              rowId
            }
          }
        }
        clientMutationId
      }
    }
  `)

  const commitRetry = useCallback(
    (input: PolUploadInput, condition: PolFileUploadCondition) => {
      retryMutation.commit({ input, condition }, (response) => {
        setUploadsData(
          (response.retryPolUpload?.query?.polFileUploads?.nodes || []) as any,
        )
      })
    },
    [retryMutation, setUploadsData],
  )

  const cancelMutation = useGwtMutation<usePolUpload_cancelMutation>(graphql`
    mutation usePolUpload_cancelMutation(
      $input: PolUploadInput!
      $condition: PolFileUploadCondition
    ) {
      cancelPolUpload(input: $input) {
        query {
          polFileUploads(condition: $condition) {
            nodes {
              galleryAssetId
              fileName
              tossUrl
              uploadError
              uploadStatus
              rowId
            }
          }
        }
        clientMutationId
      }
    }
  `)

  const commitCancel = useCallback(
    (input: PolUploadInput, condition: PolFileUploadCondition) => {
      cancelMutation.commit({ input, condition }, (response) => {
        setUploadsData(
          (response.cancelPolUpload?.query?.polFileUploads?.nodes || []) as any,
        )
      })
    },
    [cancelMutation, setUploadsData],
  )

  return {
    cancelMutation,
    retryMutation,
    commitCancel,
    commitRetry,
  }
}
