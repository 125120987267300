import { graphql, useFragment } from 'react-relay'
import { UserPriority_gwtUser$key } from './__generated__/UserPriority_gwtUser.graphql'
import { faSignal } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getUserPriorityFromValue } from '../../../util/userUtils'

interface UserPriorityProps extends React.ComponentPropsWithoutRef<'span'> {
  userRef: UserPriority_gwtUser$key | null | undefined
}

const getColorFill = (priority: number): string => {
  if (priority === 0) {
    return '#262626'
  }

  if (priority === 1) {
    return '#616161'
  }

  if (priority === 2) {
    return '#887323'
  }

  if (priority === 3) {
    return '#A35B25'
  }

  if (priority === 4) {
    return '#BC261A'
  }

  return '#000000'
}

export const UserPriority = ({
  userRef = null,
  ...props
}: UserPriorityProps) => {
  const data = useFragment(
    graphql`
      fragment UserPriority_gwtUser on GwtUser {
        userPriority
      }
    `,
    userRef,
  )

  if (!data) {
    return <></>
  }

  return (
    <span {...props}>
      <FontAwesomeIcon
        icon={faSignal}
        color={getColorFill(data.userPriority)}
      />
      <span
        className="hc-ml-min"
        style={{ color: getColorFill(data.userPriority) }}
      >
        {getUserPriorityFromValue(data.userPriority)}
      </span>
    </span>
  )
}
