/**
 * @generated SignedSource<<f02c51cf0b005840b630b1e41ebfbe98>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type PolFileUploadStatus = "ERROR" | "NOT_STARTED" | "SCANNING" | "SUCCESS" | "WAITING_FOR_JOB" | "%future added value";
export type PolFileUploadCondition = {
  fileName?: string | null;
  fileSize?: BigInt | null;
  galleryAssetId?: UUID | null;
  gwtUserId?: UUID | null;
  rowId?: UUID | null;
  tossKey?: string | null;
  tossUrl?: string | null;
  uploadError?: string | null;
  uploadStatus?: PolFileUploadStatus | null;
};
export type useUploadCheck_Query$variables = {
  condition?: PolFileUploadCondition | null;
};
export type useUploadCheck_Query$data = {
  readonly polFileUploads: {
    readonly nodes: ReadonlyArray<{
      readonly fileName: string;
      readonly galleryAssetId: UUID;
      readonly rowId: UUID;
      readonly tossUrl: string;
      readonly uploadError: string;
      readonly uploadStatus: PolFileUploadStatus;
    }>;
  } | null;
};
export type useUploadCheck_Query = {
  response: useUploadCheck_Query$data;
  variables: useUploadCheck_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "condition"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "condition",
    "variableName": "condition"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "galleryAssetId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "fileName",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tossUrl",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uploadError",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "uploadStatus",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useUploadCheck_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PolFileUploadsConnection",
        "kind": "LinkedField",
        "name": "polFileUploads",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PolFileUpload",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useUploadCheck_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "PolFileUploadsConnection",
        "kind": "LinkedField",
        "name": "polFileUploads",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "PolFileUpload",
            "kind": "LinkedField",
            "name": "nodes",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "bd06dce3e0360c95c30f1b61d9ace358",
    "id": null,
    "metadata": {},
    "name": "useUploadCheck_Query",
    "operationKind": "query",
    "text": "query useUploadCheck_Query(\n  $condition: PolFileUploadCondition\n) {\n  polFileUploads(condition: $condition) {\n    nodes {\n      galleryAssetId\n      fileName\n      tossUrl\n      uploadError\n      uploadStatus\n      rowId\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "6859bae717abbb7b751310f4069c5c00";

export default node;
