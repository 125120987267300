/**
 * @generated SignedSource<<d78edd5232be357237dbdb6014ed31cd>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type UpcomingCardFragment$data = {
  readonly upcomingFlows: {
    readonly nodes: ReadonlyArray<{
      readonly module: {
        readonly flowId: UUID;
        readonly " $fragmentSpreads": FragmentRefs<"UpcomingModuleFragment">;
      } | null;
      readonly rowId: UUID;
    }>;
  } | null;
  readonly " $fragmentType": "UpcomingCardFragment";
};
export type UpcomingCardFragment$key = {
  readonly " $data"?: UpcomingCardFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"UpcomingCardFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "assignedToId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "UpcomingCardFragment",
  "selections": [
    {
      "alias": "upcomingFlows",
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "assignedToId",
              "variableName": "assignedToId"
            }
          ],
          "kind": "ObjectValue",
          "name": "condition"
        },
        {
          "kind": "Literal",
          "name": "filter",
          "value": {
            "assignmentStatus": {
              "equalTo": "NOT_STARTED"
            }
          }
        }
      ],
      "concreteType": "FlowsConnection",
      "kind": "LinkedField",
      "name": "flows",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Flow",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "rowId",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Module",
              "kind": "LinkedField",
              "name": "module",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "flowId",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "UpcomingModuleFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

(node as any).hash = "d3df6b2095ae0104fb2660735b787550";

export default node;
