import { graphql, useFragment } from 'react-relay'
import { CommentAdd } from '../Comments/CommentAdd'
import { CommentCard } from '../Comments/CommentCard'
import './Module.scss'
import { CommentsTab_moduleComments$key } from './__generated__/CommentsTab_moduleComments.graphql'
import { useAtom } from 'jotai'
import { commentBoxAtom } from '../../state/atoms'

interface CommentsTabProps {
  moduleRef: CommentsTab_moduleComments$key | null | undefined
  id: string | undefined
}

export const CommentsTab = (props: CommentsTabProps) => {
  const [isCommentBoxOpen] = useAtom(commentBoxAtom)
  const data = useFragment(
    graphql`
      fragment CommentsTab_moduleComments on Module
      @refetchable(queryName: "FlowCommentsRefetchQuery") {
        flow {
          rowId
          ...CommentAddFragment
          flowComments(
            filter: { comment: { responseTo: { isNull: true } } }
            orderBy: COMMENT_BY_COMMENT_ID__CREATED_AT_DESC
          ) {
            nodes {
              commentId
              ...CommentCardFragment
            }
          }
        }
      }
    `,
    props.moduleRef || null,
  )

  return (
    <div>
      <div className="small-width">
        <CommentAdd flowRef={data?.flow} />
        {data &&
          data.flow?.flowComments.nodes.map((commentNode) => {
            return (
              <CommentCard
                flowCommentRef={commentNode}
                key={commentNode.commentId}
              />
            )
          })}
        {data &&
          data.flow?.flowComments.nodes.length === 0 &&
          !isCommentBoxOpen && (
            <span className="commentPlaceholderText">
              Be the first to start the conversation!
            </span>
          )}
      </div>
    </div>
  )
}
