import { Button } from '@enterprise-ui/canvas-ui-react'
import EnterpriseIcon, {
  LockLockedIcon,
  LockUnlockedIcon,
} from '@enterprise-ui/icons'
import { useState } from 'react'
import { useGwtMutation } from '../../../hooks'
import { graphql } from 'relay-runtime'
import {
  SubmissionLockMutation,
  SubmissionLockMutation$variables,
  UpdateModuleInput,
  ModulePatch,
} from './__generated__/SubmissionLockMutation.graphql'

interface SubmissionLockProps {
  lockRef: boolean | undefined
  flowRef: string | undefined
}

export const SubmissionLock = ({ lockRef, flowRef }: SubmissionLockProps) => {
  const [submissionLock, setSubmissionLock] = useState(lockRef)

  const { commit } = useGwtMutation<SubmissionLockMutation>(graphql`
    mutation SubmissionLockMutation($input: UpdateModuleInput!) {
      updateModule(input: $input) {
        module {
          submissionLock
        }
      }
    }
  `)

  const toggleLock = () => {
    if (submissionLock) {
      setSubmissionLock(false)
      updateSubmissionLock(false)
    } else {
      setSubmissionLock(true)
      updateSubmissionLock(true)
    }
  }

  const updateSubmissionLock = (lock: boolean | undefined) => {
    submissionLock ? setSubmissionLock(false) : setSubmissionLock(true)
    const mutation = {} as SubmissionLockMutation$variables
    const variables = {} as UpdateModuleInput
    variables.patch = {} as ModulePatch
    variables.patch.submissionLock = lock
    variables.flowId = flowRef

    mutation.input = variables

    commit(mutation)
  }

  return (
    <Button
      type="secondary"
      iconOnly
      size="normal"
      aria-label="lock/unlock submissions"
      checked={submissionLock}
      onClick={() => {
        toggleLock()
      }}
    >
      <EnterpriseIcon
        icon={submissionLock ? LockLockedIcon : LockUnlockedIcon}
        color="#4772CF"
      />
    </Button>
  )
}
