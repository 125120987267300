/**
 * @generated SignedSource<<a62f496a09365b9ab8b9f801bf736008>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type UserType = "ADMIN" | "BLOCKED" | "EXTERNAL_USER" | "INTERNAL_USER" | "REVIEWER" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type UserInfo$data = {
  readonly company: {
    readonly name: string;
    readonly rowId: UUID;
  } | null;
  readonly jobTitle: string | null;
  readonly userType: UserType;
  readonly " $fragmentSpreads": FragmentRefs<"UserProgramsDisplay">;
  readonly " $fragmentType": "UserInfo";
};
export type UserInfo$key = {
  readonly " $data"?: UserInfo$data;
  readonly " $fragmentSpreads": FragmentRefs<"UserInfo">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserInfo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userType",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Company",
      "kind": "LinkedField",
      "name": "company",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rowId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "jobTitle",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserProgramsDisplay"
    }
  ],
  "type": "GwtUser",
  "abstractKey": null
};

(node as any).hash = "ac3ec291e38932bbdb9a3f86cb23d019";

export default node;
