import { Input } from '@enterprise-ui/canvas-ui-react'
import { useFormContext } from 'react-hook-form'
import { ErrorMessage } from '@hookform/error-message'

interface PropTypes {
  name: string
}

export const ControlledErrorMessage = ({ name }: PropTypes) => {
  const {
    formState: { errors },
  } = useFormContext()
  return (
    <ErrorMessage
      name={name}
      errors={errors}
      render={({ message }) => <Input.Info error>{message}</Input.Info>}
    />
  )
}
