/**
 * @generated SignedSource<<d675ed259279e874176a9403b09b37db>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, GraphQLSubscription } from 'relay-runtime';
export type NotificationSubscription$variables = {};
export type NotificationSubscription$data = {
  readonly notification: {
    readonly data: string | null;
    readonly gwt_user_id: UUID | null;
    readonly id: UUID | null;
    readonly name: string | null;
  } | null;
};
export type NotificationSubscription = {
  response: NotificationSubscription$data;
  variables: NotificationSubscription$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "NotificationSubscriptionPayload",
    "kind": "LinkedField",
    "name": "notification",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "gwt_user_id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "data",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationSubscription",
    "selections": (v0/*: any*/),
    "type": "Subscription",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "NotificationSubscription",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "c87c5351bba5049eac98fdd1acd29f57",
    "id": null,
    "metadata": {},
    "name": "NotificationSubscription",
    "operationKind": "subscription",
    "text": "subscription NotificationSubscription {\n  notification {\n    id\n    name\n    gwt_user_id\n    data\n  }\n}\n"
  }
};
})();

(node as any).hash = "3826d2f2f424d0b4d72da56888e975d1";

export default node;
