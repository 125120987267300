/**
 * @generated SignedSource<<a8170ff337cbc77eed792f1f2edce5b5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EditableComment_editable$data = {
  readonly commentText: string;
  readonly gwtUserId: UUID;
  readonly rowId: UUID;
  readonly " $fragmentType": "EditableComment_editable";
};
export type EditableComment_editable$key = {
  readonly " $data"?: EditableComment_editable$data;
  readonly " $fragmentSpreads": FragmentRefs<"EditableComment_editable">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditableComment_editable",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "rowId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "gwtUserId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "commentText",
      "storageKey": null
    }
  ],
  "type": "Comment",
  "abstractKey": null
};

(node as any).hash = "5a1fcd09535754797fa6aa3d210a8bf9";

export default node;
