import './Comment.scss'
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip, Button, Grid, Modal } from '@enterprise-ui/canvas-ui-react'
import { useFragment } from 'react-relay'
import { graphql } from 'relay-runtime'
import { DropdownSelect } from '../../GenericComponents/DropdownSelect/DropdownSelect'
import { useGwtUser, useGwtMutation } from '../../hooks'
import { usePublishGlobalNotification } from '../../hooks/useNotifications'
import { CommentOptionsMutation } from './__generated__/CommentOptionsMutation.graphql'
import { CommentOptions_comment$key } from './__generated__/CommentOptions_comment.graphql'
import { useSetAtom } from 'jotai'
import { editCommentAtom } from '../../state/atoms'
import { useState } from 'react'

interface CommentOptionsProps {
  commentRef: CommentOptions_comment$key | null
  flowId: string
}

export const CommentOptions = ({
  commentRef = null,
  flowId,
}: CommentOptionsProps) => {
  const currentUser = useGwtUser()
  const { publishToLocal } = usePublishGlobalNotification()

  const setEditingComment = useSetAtom(editCommentAtom)
  const [deleteModalOpen, setDeleteModalOpen] = useState(false)

  const deleteMutation = useCommentMutations()

  const data = useFragment(
    graphql`
      fragment CommentOptions_comment on Comment {
        rowId
        gwtUserId
        commentText
        responseTo
      }
    `,
    commentRef,
  )

  return (
    <div className="comment-options">
      {deleteModalOpen && (
        <Modal
          headingText="Delete Comment"
          isVisible={deleteModalOpen}
          onRefuse={() => {
            setDeleteModalOpen(false)
          }}
        >
          <div className="hc-pa-normal">
            <Grid.Container spacing="none">
              <Grid.Item xs={12}>
                Please confirm you wish to delete this comment. Any responses to
                this comment will also be deleted upon removal.
              </Grid.Item>
            </Grid.Container>
          </div>
          <div className="hc-pt-dense hc-pb-normal hc-pr-normal">
            <Grid.Container spacing="dense" direction="row-reverse">
              <Grid.Item>
                <Button
                  type="submit"
                  disabled={deleteMutation.isInFlight}
                  onClick={() => {
                    deleteMutation.commit(
                      {
                        input: {
                          commentId: data?.rowId,
                          flowId,
                        },
                      },
                      () => {
                        publishToLocal(
                          'ASSIGNEE_MODULE_COMMENT_DELETE_NOTIFICATION',
                        )
                        setDeleteModalOpen(false)
                      },
                    )
                  }}
                >
                  Confirm
                </Button>
              </Grid.Item>
              <Grid.Item>
                <Button
                  onClick={() => {
                    setDeleteModalOpen(false)
                  }}
                >
                  Cancel
                </Button>
              </Grid.Item>
            </Grid.Container>
          </div>
        </Modal>
      )}
      <DropdownSelect
        shouldSelect={false}
        options={[
          {
            label: 'Edit',
            disabled: data?.gwtUserId !== currentUser.rowId,
            className: 'hc-pl-dense',
            onSelect: () => {
              setEditingComment(data?.rowId)
            },
          },
          {
            label: 'Delete',
            disabled: data?.gwtUserId !== currentUser.rowId,
            className: 'hc-pl-dense error-color',
            onSelect: () => {
              setDeleteModalOpen(true)
            },
          },
        ]}
      >
        <Tooltip content="Edit/Delete" location="bottom">
          <Button iconOnly>
            <FontAwesomeIcon icon={faEllipsisV} />
          </Button>
        </Tooltip>
      </DropdownSelect>
    </div>
  )
}

const useCommentMutations = () => {
  const mutation = useGwtMutation<CommentOptionsMutation>(graphql`
    mutation CommentOptionsMutation($input: DeleteFlowCommentInput!) {
      deleteFlowComment(input: $input) {
        comment {
          rowId
        }
      }
    }
  `)

  return mutation
}
