/**
 * @generated SignedSource<<a00ff54b07e00d5baa47f1547f98e750>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FlowCommentsRefetchQuery$variables = {
  id: string;
};
export type FlowCommentsRefetchQuery$data = {
  readonly node: {
    readonly " $fragmentSpreads": FragmentRefs<"CommentsTab_moduleComments">;
  } | null;
};
export type FlowCommentsRefetchQuery = {
  response: FlowCommentsRefetchQuery$data;
  variables: FlowCommentsRefetchQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "assignedToId",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "gwtUserId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "commentText",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "responseTo",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "createdAt",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "GwtUser",
  "kind": "LinkedField",
  "name": "gwtUser",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "firstName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lastName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "lanId",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "FlowCommentsRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "CommentsTab_moduleComments"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "FlowCommentsRefetchQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Flow",
                "kind": "LinkedField",
                "name": "flow",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "filter",
                        "value": {
                          "comment": {
                            "responseTo": {
                              "isNull": true
                            }
                          }
                        }
                      },
                      {
                        "kind": "Literal",
                        "name": "orderBy",
                        "value": "COMMENT_BY_COMMENT_ID__CREATED_AT_DESC"
                      }
                    ],
                    "concreteType": "FlowCommentsConnection",
                    "kind": "LinkedField",
                    "name": "flowComments",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "FlowComment",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "commentId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Flow",
                            "kind": "LinkedField",
                            "name": "flow",
                            "plural": false,
                            "selections": [
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "flowId",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Comment",
                            "kind": "LinkedField",
                            "name": "comment",
                            "plural": false,
                            "selections": [
                              (v3/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              (v8/*: any*/),
                              {
                                "alias": null,
                                "args": [
                                  {
                                    "kind": "Literal",
                                    "name": "orderBy",
                                    "value": "CREATED_AT_ASC"
                                  }
                                ],
                                "concreteType": "CommentsConnection",
                                "kind": "LinkedField",
                                "name": "commentsByResponseTo",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Comment",
                                    "kind": "LinkedField",
                                    "name": "nodes",
                                    "plural": true,
                                    "selections": [
                                      (v3/*: any*/),
                                      (v6/*: any*/),
                                      (v7/*: any*/),
                                      (v8/*: any*/),
                                      (v9/*: any*/),
                                      (v10/*: any*/),
                                      (v2/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": "commentsByResponseTo(orderBy:\"CREATED_AT_ASC\")"
                              },
                              (v9/*: any*/),
                              (v10/*: any*/),
                              (v2/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "flowComments(filter:{\"comment\":{\"responseTo\":{\"isNull\":true}}},orderBy:\"COMMENT_BY_COMMENT_ID__CREATED_AT_DESC\")"
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "Module",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "aa8a28af67b8543928ae61f167e9c91e",
    "id": null,
    "metadata": {},
    "name": "FlowCommentsRefetchQuery",
    "operationKind": "query",
    "text": "query FlowCommentsRefetchQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...CommentsTab_moduleComments\n    id\n  }\n}\n\nfragment CommentAddFragment on Flow {\n  rowId\n  name\n  assignedToId\n}\n\nfragment CommentCardFragment on FlowComment {\n  ...CommentReplyFragment\n  flowId\n  comment {\n    ...EditableComment_editable\n    ...CommentOptions_comment\n    rowId\n    commentsByResponseTo(orderBy: CREATED_AT_ASC) {\n      nodes {\n        ...CommentCardResponseFragment\n        id\n      }\n    }\n    createdAt\n    commentText\n    gwtUser {\n      ...UserAvatar_gwtUser\n      id\n    }\n    id\n  }\n}\n\nfragment CommentCardResponseFragment on Comment {\n  ...CommentOptions_comment\n  ...EditableComment_editable\n  createdAt\n  rowId\n  gwtUser {\n    ...UserAvatar_gwtUser\n    id\n  }\n}\n\nfragment CommentOptions_comment on Comment {\n  rowId\n  gwtUserId\n  commentText\n  responseTo\n}\n\nfragment CommentReplyFragment on FlowComment {\n  flow {\n    name\n    assignedToId\n    id\n  }\n  flowId\n  commentId\n}\n\nfragment CommentsTab_moduleComments on Module {\n  flow {\n    rowId\n    ...CommentAddFragment\n    flowComments(filter: {comment: {responseTo: {isNull: true}}}, orderBy: COMMENT_BY_COMMENT_ID__CREATED_AT_DESC) {\n      nodes {\n        commentId\n        ...CommentCardFragment\n        id\n      }\n    }\n    id\n  }\n  id\n}\n\nfragment EditableComment_editable on Comment {\n  rowId\n  gwtUserId\n  commentText\n}\n\nfragment UserAvatar_gwtUser on GwtUser {\n  id\n  firstName\n  lastName\n  lanId\n}\n"
  }
};
})();

(node as any).hash = "c86b6655a6b1781da9a336952820e2ba";

export default node;
