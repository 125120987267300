import {
  Tooltip,
  BarChart,
  XAxis,
  YAxis,
  Bar,
  ResponsiveContainer,
  CartesianGrid,
} from 'recharts'
import { map } from 'lodash'

interface SoftwaresCompletedBarChartProps {
  data: { name: string; assignmentStatus: string }[] | undefined
}

export const SoftwaresCompletedBarChart = ({
  data,
}: SoftwaresCompletedBarChartProps) => {
  // Counts names in data and returns it in typescript object of form {NAME: COUNT, NAME2: COUNT, etc...}

  const countNamesInData = (
    data: { name: string; assignmentStatus: string }[] | undefined,
  ): Record<string, number> => {
    const objectNames = data?.map((software) => {
      return software.name
    })
    const objectNameCounts = {}
    objectNames?.forEach(function (x) {
      objectNameCounts[x] = (objectNameCounts[x] || 0) + 1
    })
    return objectNameCounts
  }

  const createSoftwaresCompletedBarData = () => {
    //Count duplicate software names in data that has all modules
    const softwareNameCounts = countNamesInData(data)

    //Count duplicate software names in data that only has accepted modules
    const acceptedSoftwaresData = data?.filter(
      (module) => module.assignmentStatus === 'ACCEPTED',
    )
    const acceptedSoftwareNameCounts = countNamesInData(acceptedSoftwaresData)

    // Creates list of objects that contain type of software
    // # of accepted modules that have this software type
    // # of not accepted modules that have this software type
    return map(softwareNameCounts, (count, softwareName) => {
      const acceptedSoftwares = acceptedSoftwareNameCounts[softwareName] || 0
      return {
        name: softwareName,
        acceptedSoftwares,
        notAcceptedSoftwares: count - acceptedSoftwares,
      }
    })
  }

  const CustomTooltip = ({ active, payload }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="custom-bar-graph-tooltip">
          <div>{`${payload[0].payload.name}`}</div>
          <div>{`Accepted Templates w/ Software Type ${payload[0].payload.name}: ${payload[0].value} `}</div>
          <div>{`Not Accepted Templates w/ Software Type ${payload[0].payload.name}: ${payload[1].value} `}</div>
        </div>
      )
    }

    return null
  }

  const SoftwaresProgressBarData = createSoftwaresCompletedBarData()
  const chartHeight = SoftwaresProgressBarData.length * 80

  return (
    <div>
      <ResponsiveContainer height={chartHeight} width="100%">
        <BarChart
          layout="vertical"
          width={600}
          height={250}
          data={SoftwaresProgressBarData}
          margin={{
            top: 5,
            right: 5,
            left: 27,
            bottom: 5,
          }}
          maxBarSize={40}
        >
          <CartesianGrid horizontal={false} />
          <XAxis
            type="number"
            axisLine={false}
            allowDecimals={false}
            tickCount={3}
          />
          <YAxis
            type="category"
            tickLine={true}
            axisLine={false}
            dataKey="name"
            tick={{ fontSize: 12 }}
          />
          <Tooltip content={<CustomTooltip />} />
          <Bar dataKey="acceptedSoftwares" stackId="a" fill="#42ff94" />
          <Bar dataKey="notAcceptedSoftwares" stackId="a" fill="#ff424f" />
        </BarChart>
      </ResponsiveContainer>
    </div>
  )
}
