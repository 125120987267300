import { useSetAtom } from 'jotai'
import { TextInputDebounce } from '../../GenericComponents/TextInputDebounce/TextInputDebounce'
import { moduleGroupSearchAtom } from '../../state/atoms'

export const GroupTemplatesSearch = () => {
  const setSearchFilter = useSetAtom(moduleGroupSearchAtom)

  const updateSearchFilter = (debounced: string) => {
    if (debounced.length > 0) {
      const update = `%${debounced}%`
      setSearchFilter({
        flow: {
          name: { likeInsensitive: update },
        },
      })
    } else {
      setSearchFilter({
        flow: {
          name: { likeInsensitive: null },
        },
      })
    }
  }

  return (
    <TextInputDebounce
      delay={500}
      placeholder="Search"
      onUpdate={updateSearchFilter}
    />
  )
}
