import { useAtom } from 'jotai'
import { useEffect } from 'react'
import { graphql } from 'relay-runtime'
import { useRefreshQuery } from '../hooks/useRefresh'
import { feedbackCurrentAtom } from '../state/atoms'
import { GlobalFeedbackUpdater__feedbackQuery } from './__generated__/GlobalFeedbackUpdater__feedbackQuery.graphql'

export const GlobalFeedbackUpdater = () => {
  const [, setCurrentFeedback] = useAtom(feedbackCurrentAtom)
  const {
    data: {
      feedbacks: { nodes: feedbacks },
    },
    refresh,
  } = useRefreshQuery<GlobalFeedbackUpdater__feedbackQuery>(
    graphql`
      query GlobalFeedbackUpdater__feedbackQuery {
        feedbacks @required(action: THROW) {
          nodes {
            rowId
          }
        }
      }
    `,
    {},
  )

  useEffect(() => {
    const interval = setInterval(() => {
      refresh({})
    }, 600000)

    return () => clearInterval(interval)
  })

  useEffect(() => {
    setCurrentFeedback(feedbacks.map(({ rowId }) => rowId))
  }, [feedbacks, setCurrentFeedback])

  return <></>
}
