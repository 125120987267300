// Do not edit.
import { ShaderStore } from "core/Engines/shaderStore";
import "core/Shaders/ShadersInclude/bonesDeclaration";
import "core/Shaders/ShadersInclude/bakedVertexAnimationDeclaration";
import "core/Shaders/ShadersInclude/instancesDeclaration";
import "core/Shaders/ShadersInclude/clipPlaneVertexDeclaration";
import "core/Shaders/ShadersInclude/fogVertexDeclaration";
import "core/Shaders/ShadersInclude/lightFragmentDeclaration";
import "core/Shaders/ShadersInclude/lightUboDeclaration";
import "core/Shaders/ShadersInclude/instancesVertex";
import "core/Shaders/ShadersInclude/bonesVertex";
import "core/Shaders/ShadersInclude/bakedVertexAnimation";
import "core/Shaders/ShadersInclude/clipPlaneVertex";
import "core/Shaders/ShadersInclude/fogVertex";
import "core/Shaders/ShadersInclude/shadowsVertex";
import "core/Shaders/ShadersInclude/vertexColorMixing";

const name = "cellVertexShader";
const shader = `precision highp float;attribute vec3 position;#ifdef NORMAL
attribute vec3 normal;#endif
#ifdef UV1
attribute vec2 uv;#endif
#ifdef UV2
attribute vec2 uv2;#endif
#ifdef VERTEXCOLOR
attribute vec4 color;#endif
#include<bonesDeclaration>
#include<bakedVertexAnimationDeclaration>
#include<instancesDeclaration>
uniform mat4 view;uniform mat4 viewProjection;#ifdef DIFFUSE
varying vec2 vDiffuseUV;uniform mat4 diffuseMatrix;uniform vec2 vDiffuseInfos;#endif
#ifdef POINTSIZE
uniform float pointSize;#endif
varying vec3 vPositionW;#ifdef NORMAL
varying vec3 vNormalW;#endif
#ifdef VERTEXCOLOR
varying vec4 vColor;#endif
#include<clipPlaneVertexDeclaration>
#include<fogVertexDeclaration>
#include<__decl__lightFragment>[0..maxSimultaneousLights]
#define CUSTOM_VERTEX_DEFINITIONS
void main(void) {#define CUSTOM_VERTEX_MAIN_BEGIN
#include<instancesVertex>
#include<bonesVertex>
#include<bakedVertexAnimation>
vec4 worldPos=finalWorld*vec4(position,1.0);gl_Position=viewProjection*worldPos;vPositionW=vec3(worldPos);#ifdef NORMAL
vNormalW=normalize(vec3(finalWorld*vec4(normal,0.0)));#endif
#ifndef UV1
vec2 uv=vec2(0.,0.);#endif
#ifndef UV2
vec2 uv2=vec2(0.,0.);#endif
#ifdef DIFFUSE
if (vDiffuseInfos.x==0.){vDiffuseUV=vec2(diffuseMatrix*vec4(uv,1.0,0.0));}else{vDiffuseUV=vec2(diffuseMatrix*vec4(uv2,1.0,0.0));}#endif
#include<clipPlaneVertex>
#include<fogVertex>
#include<shadowsVertex>[0..maxSimultaneousLights]
#include<vertexColorMixing>
#if defined(POINTSIZE) && !defined(WEBGPU)
gl_PointSize=pointSize;#endif
#define CUSTOM_VERTEX_MAIN_END
}`;
// Sideeffect
ShaderStore.ShadersStore[name] = shader;
/** @hidden */
export const cellVertexShader = { name, shader };
