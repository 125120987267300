/**
 * @generated SignedSource<<ea79970510ab87e3f5fc21cf51aadec2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type AssignmentDueAtStatus = "DUE_SOON" | "NONE" | "OVERDUE" | "%future added value";
export type AssignmentStatus = "ACCEPTED" | "CANCELED" | "IN_PROGRESS" | "NONE" | "NOT_STARTED" | "READY_FOR_REVIEW" | "REJECTED" | "UNDER_REVIEW" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type StatusCardPaginationFragment_flows$data = {
  readonly flows: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly assignedTo: {
          readonly " $fragmentSpreads": FragmentRefs<"ReassignReviewerPopupFragment_AssignToAvatar" | "UserAvatar_gwtUser" | "UserPriority_gwtUser">;
        } | null;
        readonly assignmentDueAtStatus: AssignmentDueAtStatus | null;
        readonly assignmentStatus: AssignmentStatus;
        readonly dueAt: Date | null;
        readonly flowReviewers: {
          readonly nodes: ReadonlyArray<{
            readonly reviewer: {
              readonly rowId: UUID;
              readonly " $fragmentSpreads": FragmentRefs<"ReassignReviewerPopupFragment_ReviewerAvatar" | "UserAvatar_gwtUser">;
            } | null;
          }>;
        };
        readonly name: string;
        readonly rowId: UUID;
        readonly " $fragmentSpreads": FragmentRefs<"FlowLink_flow" | "StatusChip_flow">;
      };
    }>;
    readonly totalCount: number;
  } | null;
  readonly " $fragmentType": "StatusCardPaginationFragment_flows";
};
export type StatusCardPaginationFragment_flows$key = {
  readonly " $data"?: StatusCardPaginationFragment_flows$data;
  readonly " $fragmentSpreads": FragmentRefs<"StatusCardPaginationFragment_flows">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "flows"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v2 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "UserAvatar_gwtUser"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "flowFilter"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "flowsOrderBy"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./StatusCardPaginationQuery_Refetchable.graphql')
    }
  },
  "name": "StatusCardPaginationFragment_flows",
  "selections": [
    {
      "alias": "flows",
      "args": [
        {
          "kind": "Variable",
          "name": "filter",
          "variableName": "flowFilter"
        },
        {
          "kind": "Variable",
          "name": "orderBy",
          "variableName": "flowsOrderBy"
        }
      ],
      "concreteType": "FlowsConnection",
      "kind": "LinkedField",
      "name": "__StatusCard_flows_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "FlowsEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Flow",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                },
                (v1/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "FlowLink_flow"
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "GwtUser",
                  "kind": "LinkedField",
                  "name": "assignedTo",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "UserPriority_gwtUser"
                    },
                    (v2/*: any*/),
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "ReassignReviewerPopupFragment_AssignToAvatar"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "FlowReviewersConnection",
                  "kind": "LinkedField",
                  "name": "flowReviewers",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "FlowReviewer",
                      "kind": "LinkedField",
                      "name": "nodes",
                      "plural": true,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "GwtUser",
                          "kind": "LinkedField",
                          "name": "reviewer",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            (v2/*: any*/),
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "ReassignReviewerPopupFragment_ReviewerAvatar"
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "dueAt",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "assignmentStatus",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "assignmentDueAtStatus",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "StatusChip_flow"
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "e71e4a81cb5aa57a303255b40788865b";

export default node;
